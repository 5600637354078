import { useUserProfileBasicEdit } from '../../../../useHooks/componentHooks/useUserProfileBasicEdit/useUserProfileBasicEdit'
import './DesktopBasicEdit.scss'
import { IDesktopBasicEdit } from './interfaces/IDesktopBasicEditProps'
import arrowLeft from '../../../../assets/arrowLeft.svg'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders the basic edit page for desktop ie: email, mobile  */
const DesktopBasicEdit = (props: IDesktopBasicEdit) => {
    const { openEditPage, setOpenEditPage, index } = props
    const { userInformation, handleEmailOnChange, saveEnabled, newEmail, handleSave, handlePhoneOnChange, newPhone, message, type,
        phoneKeyDown, handleCancel, newPhoneRef, phoneOnBlur, phoneInputError, emailInputError, setSaveEnabled } = useUserProfileBasicEdit(setOpenEditPage)

    return (
        <div className="desktop-basic-edit-wrap">
            {message !== "" && <ToastMessageDB type={type} message={message} />}
            <div className="basic-header-wrap">
                <div className="basic-edit-header">
                    <img className="back-image" src={arrowLeft} alt="back arrow" onClick={() => { handleCancel(); setOpenEditPage(""); setSaveEnabled(false) }} />
                    <span className="header-text regular" onClick={() => { handleCancel(); setOpenEditPage(""); setSaveEnabled(false) }}>Contact information</span>
                </div>
            </div>
            <div className="data-page-wrap">
                <div className="data-card-wrap">
                    {openEditPage === "Email" &&
                        <>
                            <span className="title-text h3">Change email address</span>
                            <span className="title-text small">To make changes, enter your new email address and select 'Save.'</span>
                            <div className="bottom-border" />
                            <div className="current-new-data-wrap">
                                <div className="current-data-stack">
                                    <span className="title-text boldSmall">Current email address</span>
                                    <span className="title-text regular cls_mask">{userInformation.contactInformation.emails[0].emailAddress}</span>
                                </div>
                                <label className={emailInputError ? "label-text error" : "label-text"}>New email address
                                    <input
                                        className={emailInputError ? "input-box error regular" : "input-box regular"}
                                        type="text"
                                        value={newEmail}
                                        onChange={(e: any) => handleEmailOnChange(e)}
                                    />
                                    {emailInputError && <span className="error-text mobileboldextraSmall">Please provide a valid email address</span>}
                                </label>
                            </div>
                            <div className="bottom-border" />
                            <div className="button-wrap">
                                <button
                                    className="Button btn-secondary"
                                    onClick={() => handleCancel()}>
                                    Cancel</button>
                                <Button
                                    title="Save"
                                    isDisabled={!saveEnabled}
                                    className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                                    id={TIMER_IDS.USER_EMAIL}
                                    onClick={() => handleSave("Email")}
                                />
                            </div>
                        </>
                    }
                    {openEditPage === "Mobile" &&
                        <>
                            <span className="title-text h3">Change mobile number</span>
                            <span className="title-text small">To make changes, enter your new mobile number and select 'Save.'</span>
                            <div className="bottom-border" />
                            <div className="current-new-data-wrap">
                                <div className="current-data-stack">
                                    <span className="title-text margin boldSmall">Current mobile number</span>
                                    <span className="title-text regular cls_mask">{userInformation.contactInformation.phones[index].number}</span>
                                </div>
                                <label className={phoneInputError ? "label-text error" : "label-text"}>New mobile number
                                    <input
                                        className={phoneInputError ? "input-box error regular" : "input-box regular"}
                                        type="text"
                                        value={newPhone}
                                        onChange={(e: any) => handlePhoneOnChange(e)}
                                        maxLength={12}
                                        onKeyDown={(e: any) => phoneKeyDown(e)}
                                        ref={newPhoneRef}
                                        id="phone-input"
                                        onBlur={() => phoneOnBlur()}
                                        inputMode='numeric'
                                    />
                                    {phoneInputError && <span className="error-text mobileboldextraSmall">Please provide a valid 10-digit mobile number</span>}
                                </label>
                            </div>
                            <div className="bottom-border" />
                            <div className="button-wrap">
                                <button
                                    className="Button btn-secondary"
                                    onClick={() => handleCancel()}>
                                    Cancel</button>
                                <Button
                                    title="Save"
                                    isDisabled={!saveEnabled}
                                    className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                                    id={TIMER_IDS.USER_PHONE}
                                    onClick={() => handleSave("Mobile", index)}
                                />
                            </div>
                        </>
                    }
                    {openEditPage === "Alternate" &&
                        <>
                            <span className="title-text h3">Change alternate number</span>
                            <div className="bottom-border" />
                            <div className="current-new-data-wrap">
                                <div className="current-data-stack">
                                    <span className="title-text margin boldSmall">Current alternate number</span>
                                    <span className="title-text regular cls_mask">{userInformation.contactInformation.phones[index]?.number}</span>
                                </div>
                                <label className={phoneInputError ? "label-text error" : "label-text"}>New alternate number
                                    <input
                                        className={phoneInputError ? "input-box error regular" : "input-box regular"}
                                        type="text"
                                        value={newPhone}
                                        onChange={(e: any) => handlePhoneOnChange(e)}
                                        maxLength={12}
                                        onKeyDown={(e: any) => phoneKeyDown(e)}
                                        ref={newPhoneRef}
                                        id="phone-input"
                                        onBlur={() => phoneOnBlur()}
                                        inputMode='numeric'
                                    />
                                    {phoneInputError && <span className="error-text mobileboldextraSmall">Please provide a valid 10-digit mobile number</span>}
                                </label>
                            </div>
                            <div className="bottom-border" />
                            <div className="bottom-row-wrap">
                                <div className="left-side" onClick={() => handleSave("Remove", index)}>
                                    <img src={redTrashCan} alt="delete" />
                                    <span className="red-text regular">Remove alternate number</span>
                                </div>
                                <div className="button-wrap">
                                    <button
                                        className="Button btn-secondary"
                                        onClick={() => handleCancel()}>
                                        Cancel</button>
                                    <Button
                                        title="Save"
                                        isDisabled={!saveEnabled}
                                        className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                                        id={TIMER_IDS.EDIT_PHONE}
                                        onClick={() => { handleSave("Alternate", index) }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    {openEditPage === "Add" &&
                        <>
                            <span className="title-text h3">Add alternate number</span>
                            <span className="title-text small">Enter your alternate number and select 'Save.'</span>
                            <div className="bottom-border" />
                            <label className={phoneInputError ? "label-text error" : "label-text"}>Alternate number
                                <input
                                    className={phoneInputError ? "input-box error regular" : "input-box regular"}
                                    type="text"
                                    value={newPhone}
                                    onChange={(e: any) => handlePhoneOnChange(e)}
                                    maxLength={12}
                                    onKeyDown={(e: any) => phoneKeyDown(e)}
                                    ref={newPhoneRef}
                                    id="phone-input"
                                    onBlur={() => phoneOnBlur()}
                                    inputMode='numeric'
                                />
                                {phoneInputError && <span className="error-text mobileboldextraSmall">Please provide a valid 10-digit mobile number</span>}
                            </label>
                            <div className="bottom-border" />
                            <div className="button-wrap">
                                <button
                                    className="Button btn-secondary"
                                    onClick={() => handleCancel()}>
                                    Cancel</button>
                                <Button
                                    title="Save"
                                    isDisabled={!saveEnabled}
                                    className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                                    id={TIMER_IDS.USER_PHONE}
                                    onClick={() => handleSave("Add", index)}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default DesktopBasicEdit