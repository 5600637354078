import React, { useState, useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { useHistory } from "react-router"

/**A custom use hook for the close account flow found in Grace Period */
export const useCloseAccount = (
    transferAccount: {id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""},
    setTransferAccount: React.Dispatch<React.SetStateAction<{id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""}>>,
    hasSavings: boolean
) => {
    const { windowState } = useWindowState()
    const linkedExternalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [linkedAccounts, setLinkedAccounts] = useState<Array<{id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""}>>([])
    const [foundSavings, setFoundSavings] = useState<boolean>(false)
    const foundInternalAccount = useRef<boolean>(false)
    // const [linkedFullAccounts, setLinkedFullAccounts] = useState<Array<{label: string, value: string}>>([])
    const linkExternalClicked = useRef<boolean>(false)
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const [showLimitModal, setShowLimitModal] = useState<boolean>(false)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const [savingsPageClosed, setSavingsPageClosed] = useState<boolean>(hasSavings)
    const [enableSave, setEnableSave] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [openSavings, setOpenSavings] = useState<boolean>(false)
    // const [hasSavings, setHasSavings] = useState<boolean>(false)

    /**Build the linked accounts array for the accounts dropdown selection */
    const buildLinkedAccounts = () => {
        let tempAccounts: Array<{title: string, lastFour: string, id: string, accountType: "external" | "internal" | ""}> = []
        let addAccount = {id: "", title: "CREDIT ONE ACCOUNTS", lastFour: "", accountType: "" as "external" | "internal" | ""}
        tempAccounts.push(addAccount)
        for(let i = 0; i < accountInformation.length; i = i + 1){
            if(accountInformation[i].accountType === "Savings" && !accountInformation[i].accountClosed){
                if(transferAccount?.id === "" && !foundInternalAccount.current){
                    foundInternalAccount.current = true
                    setTransferAccount({id: accountInformation[i].id, title: accountInformation[i].accountTitle, lastFour: accountInformation[i].accountNumber, accountType: "internal"})
                }
                let addAccount = {id: accountInformation[i].id, title: accountInformation[i].accountTitle, lastFour: accountInformation[i].accountNumber, accountType: "internal" as "external" | "internal" | ""}
                tempAccounts.push(addAccount)
            }
        }
        addAccount = {id: "", title: "EXTERNAL ACCOUNTS", lastFour: "", accountType: "" as "external" | "internal" | ""}
        tempAccounts.push(addAccount)
        // console.log(linkedExternalAccounts);
        for(let i = 0; i < linkedExternalAccounts.length; i = i + 1){
            if(linkedExternalAccounts[i].status === "Linked"){
                if(i === 0 && transferAccount.id === "" && !foundInternalAccount.current){
                    setTransferAccount({id: linkedExternalAccounts[i].id, title: linkedExternalAccounts[i].accountNickName, lastFour: "", accountType: "external"})
                }
                let addAccount = {id: linkedExternalAccounts[i].id, title: linkedExternalAccounts[i].accountNickName, lastFour: "", accountType: "external" as "external" | "internal" | "" }
                tempAccounts.push(addAccount)
            }
        }
        if(tempAccounts.length){
            setLinkedAccounts(tempAccounts)
        }
    }

    /**Build out the linked accounts array for the drop down once accountINformation and linked external accounts are here */
    useEffect(() => {
        if(accountInformation.length && linkedExternalAccounts.length){
            buildLinkedAccounts()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation, linkedExternalAccounts])

    /**On render check the account information array for a savings account */
    useEffect(() => {
        let savingsAccountFound = false
        for(let i = 0; i < accountInformation.length; i = i + 1){
            if(accountInformation[i].accountType === "Savings" && !accountInformation[i].accountClosed){
                /**Uncomment this later when we are not forcing the open savings account page to open */
                savingsAccountFound = true
                setFoundSavings(true)
                setSavingsPageClosed(true)
                // setHasSavings(true)
            }
        }

        if(!savingsAccountFound){
            setSavingsPageClosed(false)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation])   
    
    /**Handles the onClick event for the Continue button */
    const handleContinue = () => {
        // const tempAccount = linkedFullAccounts.filter((account: any) => account.value.slice(account.value.length - 4, account.value.length) === transferAccount.slice(transferAccount.length - 4, transferAccount.length))
        // const tempSelectedAccount: {label: string, value: string, type: "Add" | "Withdraw" | ""}  = {label: tempAccount[0].label, value: tempAccount[0].value, type: "Withdraw"}
        // setTransferAccount(tempSelectedAccount)
        // const payload = {transferAccount: tempAccount[0].label, transferAmount: selectedAccount.balance, transferType: "Withdraw" }
        // dispatch(updateGraceSettingsOnTransferFunds(payload))
    }

    /**check to make sure the transfer to account is populated before enabling the continue button */
    useEffect(() => {
        if(transferAccount && transferAccount?.id !== ""){
            setEnableSave(true)
        }
        else{
            setEnableSave(false)
        }

    }, [transferAccount])

    /**handles the link external account button click */
    const handleLinkExternalAccount = () => {
        if(userInformation.externalAccounts.length > 2){
            setShowLimitModal(true)
          }
          else{
              linkExternalClicked.current = true
              dispatch(setCheckInternalToken(true))
              dispatch(setOTPPassed(false))
          }      
    }

    /**monitors the success of the otpModal and sets the openEditPage state to open once the OTP passes */
    useEffect(() => {
        if(OTPPassed && linkExternalClicked.current){
            linkExternalClicked.current = false
            dispatch(setOTPPassed(false))
            history.push("/linkexternalaccount")
        }   
          
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**make sure the checkInternalToken key is set to false on render */
    useEffect(() => {
        dispatch(setCheckInternalToken(false))
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { linkedAccounts, foundSavings, setFoundSavings, selectedAccount, handleContinue, windowState, savingsPageClosed, 
             setSavingsPageClosed, enableSave, showLimitModal, setShowLimitModal, handleLinkExternalAccount, openSavings, setOpenSavings, hasSavings }

}