import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import ChangeCD from '../components/ChangeCD/ChangeCD'
import GraceConfirmTermChange from '../components/GraceConfirmTermChange/GraceConfirmTermChange'
import DesktopGraceLanding from '../components/GraceLandingPage/DesktopGraceLandingPage/DesktopGraceLanding'
import DesktopGraceTransferFunds from '../components/GraceTransferFunds/DesktopGraceTransferFunds/DesktopGraceTrasnferFunds'
import { IDesktopGracePeriodProps } from '../interfaces/IDesktopGracePeriodProps'
import './DesktopGracePeriod.scss'
import CancelTransfersModal from '../components/CancelTransferModal/CancelTransfersModal'
import CloseAccount from '../components/CloseAccount/CloseAccount'
import { selectedAccount } from '../../../slices/selectedAccountSlice/selectedAccountSlice'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import CurrentBalanceHeader from '../../../shared/digitalbank/CurrentBalanceHeader/CurrentBalanceHeader'

/**Renders the Grace Period landing page for Desktop version */
const DesktopGracePeriod = (props: IDesktopGracePeriodProps) => {
    const { changeCD, setChangeCD, fundTransfer, setFundTransfer, closeAccount, setCloseAccount ,chosenTerm, setChosenTerm,
            showTermChange, setShowTermChange, transferType, setTransferType, openCancelFunds, setOpenCancelFunds, 
            transferAccount, setTransferAccount, showPhoneNumber, setShowPhoneNumber, hasSavings } = props

    return (
        <div className='grace-period-wrap'>
            <div className="header-background">
                <div className="grace-header-wrap account-header">
                    <AccountHeader returnMessage="Maturity Plan" setDisplayDetails={false}/>
                    <CurrentBalanceHeader />
                </div>
            </div>
            <div className="grace-page-wrap">
                {(!changeCD && !fundTransfer && !showTermChange && !closeAccount) &&
                    <DesktopGraceLanding 
                        setChangeCD={setChangeCD}
                        setFundTransfer={setFundTransfer}
                        setCloseAccount={setCloseAccount}
                        transferType={transferType}
                        setOpenCancelFunds={setOpenCancelFunds}
                        showPhoneNumber={showPhoneNumber}
                        setShowPhoneNumber={setShowPhoneNumber}
                    />
                }
                {changeCD &&
                    <ChangeCD 
                        chosenTerm={chosenTerm}
                        setChosenTerm={setChosenTerm}
                        setShowTermChange={setShowTermChange}
                        setChangeCD={setChangeCD}
                    />
                }
                {showTermChange &&
                    <GraceConfirmTermChange 
                        chosenTerm={chosenTerm}
                        setShowTermChange={setShowTermChange}
                        setChangeCD={setChangeCD}
                        fundTransfer={fundTransfer}
                        setFundTransfer={setFundTransfer}
                        transferType={transferType}
                        closeAccount={closeAccount}
                        setCloseAccount={setCloseAccount}
                        transferAccount={transferAccount}
                        setChosenTerm={setChosenTerm}
                    />
                }
                {(fundTransfer && !showTermChange) &&
                    <DesktopGraceTransferFunds 
                        transferType={transferType}
                        setTransferType={setTransferType}
                        setShowTermChange={setShowTermChange}
                        setFundTransfer={setFundTransfer}
                        transferAccount={transferAccount}
                        setTransferAccount={setTransferAccount}
                    />
                }
                {openCancelFunds && 
                    <CancelTransfersModal 
                        setOpenCancelFunds={setOpenCancelFunds}
                    />
                }
                {(closeAccount && !showTermChange) &&
                    <CloseAccount 
                        setCloseAccount={setCloseAccount}
                        setShowTermChange={setShowTermChange}
                        setTransferAccount={setTransferAccount}
                        transferAccount={transferAccount}
                        hasSavings={hasSavings}
                    />
                }
            </div>
        </div>
    )
}

export default DesktopGracePeriod