import { useContactInformation } from '../../../../useHooks/componentHooks/useContactInformation/useContactInformation'
import MobileHeaderBox from '../MobileHeaderBox/MobileHeaderBox'
import { IMobileContactPageProps } from './interfaces/IMobileContactPageProps'
import './MobileContactPage.scss'
import blueEditPencil from '../../../../assets/blueEditPencil.svg'
import bluePlus from '../../../../assets/bluePlus.svg'
import { IAddress } from '../../../../api/User/interfaces'
import { IPhone } from '../../../../api/User/interfaces'
import MobileBasicEdit from '../MobileBasicEdit/MobileBasicEdit'
import MobileModifyAddresses from '../MobileModifyAddresses/MobileModifyAddresses'
import MaxAddressModal from '../MaxAddressModal/MaxAddressModal'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import { useEffect } from 'react'
import { useUserProfileContext } from '../../Context/useUserProfileContext'

/**Renders the contact information page for mobile showing email/phone/address */
const MobileContactPage = (props: IMobileContactPageProps) => {
    const { setCurrentTab, addressAction, setAddressAction, openEditPage, setOpenEditPage } = props
    const { userInformation, arrayIndex, setArrayIndex, showMaxAddressModal, handleAddNewAddress, setShowMaxAddressModal, handleEditClick, message, type } = useContactInformation(setOpenEditPage, addressAction, setAddressAction)
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)

    const { setUsingSuggestion } = useUserProfileContext()

    /**
     * Sets the state controling whether or not to show the suggestion address box or the manual address entry.
     * Edit or EditMain means editing an exisiting address and should start with manual mode.
     * Add means adding a new address and should start in suggestion mode.
     */
    useEffect(() => {
        if(addressAction === "Edit" || addressAction === "EditMain"){
            setUsingSuggestion(false)
        }
        else{
            setUsingSuggestion(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressAction])

    return (
        <>
            {openEditPage === "" ?
                <div className="mobile-contact-page-wrapper">
                    {gotUserContactInfo !== "Loading" ?
                        <>
                            <MobileHeaderBox
                                setCurrentTab={setCurrentTab}
                                title="Contact information"
                            />
                            {showMaxAddressModal &&
                                <MaxAddressModal
                                    setShowMaxAddressModal={setShowMaxAddressModal}
                                />
                            }
                            {message !== "" && <ToastMessageDB type={type} message={message} />}
                            <div className="info-card-wrap">
                                <span className="card-title mobileBoldRegular">Email address</span>
                                <div className="bottom-border" />
                                <div className="data-row" onClick={() => handleEditClick("Email")}>
                                    <div className="left-side">
                                        <span className="label-text mobileBoldSmall">Email</span>
                                        <span className="value-text regular cls_mask">{userInformation.contactInformation.emails[0]?.emailAddress}</span>
                                    </div>
                                    <img className="edit-image" src={blueEditPencil} alt="edit email" />
                                </div>
                            </div>
                            <div className="info-card-wrap">
                                <span className="card-title mobileBoldRegular">Phone number</span>
                                <span className="card-sub-title small">We’ll text notifications to your mobile number if you’ve opted in.</span>
                                <div className="bottom-border" />
                                {userInformation.contactInformation.phones.map((phone: IPhone, index: number) => {
                                    return (
                                        <div key={phone.id} className={index !== userInformation.contactInformation.phones.length - 1 ? "data-row gap" : "data-row"} onClick={() => { handleEditClick(phone.type); setArrayIndex(index) }}>
                                            <div className="left-side">
                                                <span className="label-text mobileBoldSmall">{phone.type} number</span>
                                                <span className="value-text regular cls_mask">{phone.number}</span>
                                            </div>
                                            <img className="edit-image" src={blueEditPencil} alt="edit phone number" />
                                        </div>
                                    )
                                })}
                                <div className="bottom-border" />
                                <div className="add-value-wrap">
                                    <img className="add-image" src={bluePlus} alt="blue plus sign" />
                                    <span className="link-text regular" onClick={() => handleEditClick("Add")}>Add alternate number</span>
                                </div>
                            </div>
                            <div className="info-card-wrap">
                                <span className="card-title mobileBoldRegular">Addresses</span>
                                <span className="card-sub-title small">We are required by law to obtain your home street address. You can add a separate mailing address if you wish.</span>
                                <div className="bottom-border" />
                                {userInformation.contactInformation.addresses.map((address: IAddress, index: number) => {
                                    return (
                                        <div key={address.id} className="data-row margin">
                                            <div className="left-side">
                                                {index === 0 ?
                                                    <span className="label-text mobileBoldSmall">Home street address</span>
                                                    :
                                                    <span className="label-text mobileBoldSmall">Address {index}</span>
                                                }
                                                <span className="value-text regular cls_mask">{address.addressLine1}</span>
                                                {(address.addressLine2 && address.addressLine2 !== "") && <span className="value-text regular cls_mask">{address.addressLine2}</span>}
                                                <span className="value-text regular cls_mask">{address.city}, {address.state} {address.zipCode}</span>
                                                {address.isMailing && <span className="mailing-pill mobile-h6">Mailing</span>}
                                            </div>
                                            <img className="edit-image" src={blueEditPencil} alt="edit address" onClick={() => { handleEditClick("Addresses"); setAddressAction(index === 0 ? "EditMain" : "Edit"); setArrayIndex(index) }} />
                                        </div>
                                    )
                                })}
                                <div className="bottom-border" />
                                <div className="add-value-wrap" >
                                    <img className="add-image" src={bluePlus} alt="blue plus sign" onClick={() => { handleAddNewAddress() }} />
                                    <span className="link-text regular" onClick={() => { handleAddNewAddress() }}>Add mailing address</span>
                                </div>
                            </div>
                        </>
                        :
                        <div className="loading-state-wrapper">
                            <div className="loading-header-wrap">
                                <div className="top-box" />
                                <div className="bottom-box" />
                            </div>
                            <div className="loading-box" />
                            <div className="loading-box" />
                            <div className="loading-box" />
                        </div>
                    }
                </div>
                :
                <>
                    {openEditPage !== "Addresses" ?
                        <MobileBasicEdit
                            openEditPage={openEditPage}
                            setOpenEditPage={setOpenEditPage}
                            index={arrayIndex}
                        />
                        :
                        <MobileModifyAddresses
                            setOpenEditPage={setOpenEditPage}
                            index={arrayIndex}
                            addressAction={addressAction}
                            setAddressAction={setAddressAction}
                        />
                    }
                </>
            }
        </>
    )
}

export default MobileContactPage