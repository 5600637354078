import { useEffect, useState } from "react"
import { getAccountNumber_API } from "../../api/getAccountInformation"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
// import { getAccountNumberAsync } from "../../slices/selectedAccountSlice/selectedAccountSlice"

/**
 * custom use hook for the display account component.  the hook will toggle betweeen showing the full account
 * and hiding all but the last four digits.
 * @returns showAccount : boolean
 */
export const useDisplayAccount = (showDropDown: boolean) => {
    const [showAccount, setShowAccount] = useState<boolean>(false)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [accountNumber, setAccountNumber] = useState<string>("")
    const [success, setSuccess] = useState<boolean>(false)

    /**Get the account number when retrieve account number is clicked */
    const getAccountNumber = async () => {
        let tempAccount = await getAccountNumber_API(selectedAccount.id)
        try{
            setAccountNumber(tempAccount.data.accountNumber)
            setSuccess(true)
        }
        catch{
            console.error()
        }
    }

    /**Get the account number when the account number "eye" icon is clicked */
    useEffect(() => {
        if(showAccount){
            getAccountNumber()
        }
        else{
            setAccountNumber("")
            setSuccess(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAccount])

    /**If selected account changes then set showAccount to false and clear the accountNumber field */
    useEffect(() => {
        setShowAccount(false)
        setAccountNumber("")
    }, [selectedAccount])

    /**Monitors the drop down, if the more details drop down is closed then we will hide the account number */
    useEffect(() => {
        if(!showDropDown){
            setShowAccount(false)
            setAccountNumber("")
        }
    }, [showDropDown])

    return { showAccount, setShowAccount, accountNumber, success }
}