/* eslint-disable @typescript-eslint/no-unused-vars */
import './MobileBeneficiarySummary.scss';
import { useBeneficiarySummary } from '../../../../useHooks/componentHooks/useBeneficiarySummary/useBeneficiarySummary';
import { PieChart } from '../../../PieChart/PieChart';
import { IBeneficiary } from '../../../../api/getBeneficiaries';
import { COLOR_ARRAY } from '../../../../utils/globalVariables';
import { IMobileBeneficiarySumaryProps } from './interfaces/IMobileBeneficiarySummaryProps';
import { AccountHeader } from '../../../AccountHeader/AccountHeader';
import { SetupBeneficies } from '../../../SetupBeneficies/SetupBeneficies';
import warning from '../../../../assets/warning.svg'
import elipses from '../../../../assets/elipses.svg'
import bluePencil from '../../../../assets/bluePencil.svg'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import { format } from 'path';

/**
 * Displays the beneficiaries and Pie Chart from the beneficiaries landing page mobile version.
 * From here beneficiaries can be added or edited.
 */
const MobileBeneficiarySummary = (props: IMobileBeneficiarySumaryProps) => {
  const {
    setEditBeneficiary,
    showAlertMessage,
    setShowAlertMessage,
    setEditProfile,
    setAddBeneficiary,
    setSelectedBeneficiary,
    form,
    dispatchForm,
    handleOnClick,
    setDeleteBeneficiary
  } = props;
  const { selectedAccount, history, showAlertBox, isBeneficiariesLoaded, handleMenuOnClick, setOpenMenu, openMenu } = useBeneficiarySummary(
    showAlertMessage,
    setShowAlertMessage
  );

  let length: string = "";

  const displayBenificiaryType = (beneficaryType: string, beneficaryName: string, length: string) => {
    return(
    <>
      {beneficaryType === 'Person' && (
        <span className={`member-name ${length} semiboldRegular cls_mask`}>{beneficaryName}</span>
      )}
      {(beneficaryType === 'Charity' || beneficaryType === "Non Profit") && (
        <span className={`member-name ${length} semiboldRegular cls_mask`}>{beneficaryName}</span>
      )}
      {beneficaryType === 'Trust' && (
        <span className={`member-name ${length} semiboldRegular cls_mask`}>{beneficaryName}</span>
      )}
      <span className="member-type mobileX-Small">{beneficaryType}</span>
    </>
    )
  }
  
  return (
    <>
      {isBeneficiariesLoaded !== "Loading" ?
        <div className="mobile-beneficiary-summary">
          <AccountHeader
            returnMessage={'Account Settings'}
            setDisplayDetails={false}
          />
          {isBeneficiariesLoaded === "Success" ?
            <>
              <div className="card-wrapper">
                {selectedAccount.beneficiaries.length ? (
                  <div className="beneficiary-details-wrapper">
                    <div className="title-wrap">
                      <div className="title-lockup">
                        <span className="Title mobileh4">Beneficiaries</span>
                        <span className="Title-Message mobileRegular">Add or manage beneficiaries and allocation amounts.</span>
                      </div>
                    </div>
                    <div className="header-columns">
                      <span className='table-header-text mobileX-Small'>Beneficiary</span>
                      <span className='table-header-text mobileX-Small right'>Allocation</span>
                    </div>
                    {selectedAccount.beneficiaries.map(
                      (beneficiary: IBeneficiary, index: number) => {

                        if (selectedAccount.beneficiaries.length === 1) {
                          length = 'one'
                        }
                        return (
                          <div key={index}>
                            {!beneficiary.isDeleted &&
                              <div className="display-mobile-beneficiaries">
                                <div className="row-wrapper">
                                  <div className="left-side">
                                  {selectedAccount.beneficiaries.length > 1 ? 
                                    <div className="dot" style={{ backgroundColor: COLOR_ARRAY[index] }} />
                                    :
                                    <span></span>
                                  }
                                    <div className={`name-type-stack ${length}`}>
                                      {displayBenificiaryType(beneficiary.type, beneficiary.name, length)}
                                    </div>
                                  </div>
                                  <div className="right-side">
                                    <span className="member-percentage mobileBoldSmall">
                                      {beneficiary.percentage}%
                                    </span>
                                    <img
                                      className="elipses"
                                      src={elipses}
                                      alt="Open menu"
                                      onClick={(e) => {
                                        if (!beneficiary.hasOwnProperty('isDeleted')) {
                                          return;
                                        }
                                        setOpenMenu(index)
                                        handleMenuOnClick(e);
                                        handleOnClick(beneficiary)
                                      }}
                                    />
                                  </div>
                                </div>
                                {index < selectedAccount.beneficiaries.length - 1 && <div className="bottom-border" />}
                              </div>
                            }
                          </div>
                        );
                      }
                    )}
                    <div className="pie-wrapper">
                      {selectedAccount.beneficiaries.length > 1 && (
                        <div className="pie-chart">
                          <PieChart beneficiaries={selectedAccount.beneficiaries} />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <SetupBeneficies
                    setAddBeneficiaries={setAddBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}
                  />
                )}
              </div>

              {selectedAccount.beneficiaries.length !== 0 && (
                <div className="button-wrapper" style={{ justifyContent: selectedAccount.beneficiaries.length > 1 ? 'space-between' : 'center' }}>
                  {selectedAccount.beneficiaries.length > 1 ?
                    <button
                      className="Button btn-secondary boldRegular"
                      onClick={() => setEditBeneficiary(true)}
                    >
                      Adjust allocations
                    </button>
                    : null}
                  <button
                    className={selectedAccount.beneficiaries.length < 10 ? selectedAccount.beneficiaries.length === 1 ? "Button btn-primary boldRegular add-beneficiary" : "Button btn-primary boldRegular" : "Button btn-primary inactive boldRegular"}
                    onClick={() => { setAddBeneficiary(true); form.resetForm(); form.enableAllInputs(); dispatchForm() }}
                    disabled={selectedAccount.beneficiaries.length >= 10}
                  >
                    Add beneficiary
                  </button>
                </div>
              )}
              {openMenu !== undefined &&
                <div className="pop-up-menu-wrap">
                  <div className="pop-up-menu">
                    <div className="header-row">
                      <span className="beneficiary-name semibold">{selectedAccount.beneficiaries[openMenu].name}</span>
                      <span className="cancel-click regular" onClick={() => setOpenMenu(undefined)}>Cancel</span>
                    </div>
                    <div className="menu-option border" onClick={() => { setEditProfile(true); setOpenMenu(undefined) }}>
                      <img className="option-image" src={bluePencil} alt="edit profile" />
                      <span className="edit-text regular">Edit Profile</span>
                    </div>
                    <div className="menu-option" onClick={() => { setDeleteBeneficiary(true); setOpenMenu(undefined) }}>
                      <img className="option-image" src={redTrashCan} alt="delete" />
                      <span className="delete-text regular">Remove</span>
                    </div>
                  </div>
                </div>
              }
            </>
            :
            <div className="mobile-beneficiary-page-api-error">
              <div className="error-state-card-wrapper">
                <span className="h3">Beneficiaries</span>
                <span className="sub-text small">Add or manage beneficiaries and allocation amounts.</span>
                <div className="data-wrapper">
                  <img src={warning} alt="warning" />
                  <span className="top-text mobileh4">Unable to load data</span>
                  <span className="bottom-text regular">Please wait a moment and try again</span>
                </div>
              </div>
            </div>
          }
        </div>
        :
        <div className="mobile-beneficiary-loading-state">
          <AccountHeader
            returnMessage={'Account Settings'}
            setDisplayDetails={false}
          />
          <div className="loading-card">
            <div className="loading-box" />
          </div>
        </div>
      }
    </>
  );
};

export default MobileBeneficiarySummary;
