import './ContactPage.scss'
import { IContactPageProps } from './interfaces/IContactPageProps'
import headphones from '../../../assets/headphones.svg'
import mailbox from '../../../assets/mailbox.svg'
import plusSign from '../../../assets/plusSign.svg'
import { useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import ProductsModal from '../../../components/ProductsModal/ProductsModal'
import { getSAMLURL } from '../../../shared/config/getSAMLUrl'
import { useOpenNewAccount } from '../../../Context/OpenNewAccountContext/useOpenNewAccountContext'

/**Renders the contact page found by clikcing the Help button or ? on the header */
const ContactPage = (props: IContactPageProps) => {
    const { windowState } = props
    const [showRatesModal, setShowRatesModal] = useState<boolean>(false)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation)
    const newDate = new Date()
    const year = newDate.getFullYear()
    const { checkExternalAccounts } = useOpenNewAccount()

    return (
        <div className="contact-page-wrap">
            {windowState !== "Mobile" ?
                <>
                    <div className="contact-header-wrap">
                        <span className="header-text h3">Help center</span>
                    </div>
                    <div className="content-page-wrap">
                        <div className="content-wrap">
                            <div className="content-header-wrap">
                                <span className="content-header-text h2">Contact us</span>
                                <span className="content-sub-header regular">Let us help you—contact us for personalized support.</span>
                            </div>
                            <div className="card-wrap">
                                <div className="card">
                                    <img className="image" src={headphones} alt="headphones" />
                                    <span className="title h4">Phone number</span>
                                    <a className="blue-text regular" href={"tel:18776281610"}>1-877-628-1610</a>
                                    <span className="message regular">Need assistance? Our U.S.-based Senior Account Specialists focus solely on jumbo deposit products and are available Mon.-Fri. 7 a.m.-5 p.m. Pacific Time.</span>
                                </div>
                                <div className="card">
                                    <img className="image" src={mailbox} alt="mailbox" />
                                    <span className="title h4">Mailing address</span>
                                    <span className="data regular">Credit One Bank</span>
                                    <span className="data regular">P.O. Box 98873</span>
                                    <span className="data regular">Las Vegas, NV 89193-8873</span>
                                </div>
                            </div>
                            <div className='questions-wrap'>
                                <span className="questions-text h4">Have questions?</span>
                                <span className="link-text regular" onClick={() => window.open("https://www.creditonebank.com/deposits-faqs", "_blank")}>Visit our frequently asked questions</span>
                            </div>
                            <div className="new-account-button">
                                <button className="Button btn-openaccount" onClick={checkExternalAccounts}>
                                    <img src={plusSign} alt="Plus Symbol" />
                                    <span className="new-account-text regular">Open a new account</span>
                                </button>
                            </div>
                            <span className="saving-wrap regular">Ready to start saving more?&nbsp;<span className="linked-text" onClick={() => setShowRatesModal(true)}>Check out our current rates.</span></span>
                            {showRatesModal &&
                                <ProductsModal
                                    setShowModal={setShowRatesModal}
                                    CDProductList={accountInformation.CDProductList}
                                    bumpProductList={accountInformation.BumpProductList}
                                    savingsProductList={accountInformation.SavingsProductList}
                                    showSavingsProducts={true}
                                    origin='help'
                                />
                            }
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="contact-header-wrap mobile">
                        <span className="header-text h4">Help center</span>
                    </div>
                    <div className="content-page-wrap mobile">
                        <div className="content-wrap mobile">
                            <div className="content-header-wrap">
                                <span className="content-header-text h3">Contact us</span>
                                <span className="content-sub-header small">Let us help you—contact us for personalized support.</span>
                            </div>
                            <div className="card-wrap mobile">
                                <div className="card mobile">
                                    <img className="image" src={headphones} alt="headphones" />
                                    <span className="title boldLarge mobile">Phone number</span>
                                    <a className="blue-text regular" href={"tel:18776281610"}>1-877-628-1610</a>
                                    <span className="message regular">Need assistance? Our U.S.-based Senior Account Specialists focus solely on jumbo deposit products and are available Mon.-Fri. 7 a.m.-5 p.m. Pacific Time.</span>
                                </div>
                                <div className="card mobile">
                                    <img className="image" src={mailbox} alt="mailbox" />
                                    <span className="title boldLarge mobile">Mailing address</span>
                                    <span className="data regular">Credit One Bank</span>
                                    <span className="data regular">P.O. Box 98873</span>
                                    <span className="data regular">Las Vegas, NV 89193-8873</span>
                                </div>
                            </div>
                            <div className='questions-wrap'>
                                <span className="questions-text boldLarge">Have questions?</span>
                                <span className="link-text regular" onClick={() => window.open("https://www.creditonebank.com/deposits-faqs", "_blank")}>Visit our frequently asked questions</span>
                            </div>
                            <div className="new-account-button">
                                <button className="Button btn-openaccount" onClick={checkExternalAccounts}>
                                    <img src={plusSign} alt="Plus Symbol" />
                                    <span className="new-account-text boldRegular">Open a new account</span>
                                </button>
                            </div>
                            <div className="save-wrap-mobile">
                                <span className="saving-wrap regular mobile">Ready to start saving more?</span>
                                <span className="linked-text mobile regular" onClick={() => setShowRatesModal(true)}>Check out our current rates.</span>
                            </div>
                            <div className="footer-wrap">
                                <div className="row">
                                    <span className="Link-Text x-small" onClick={() => window.open("https://www.creditonebank.com/content/dam/creditonebank/corporate-transactional/pdf/Deposit_Agreement_Disclosures.pdf", "_blank")}>Deposit Agreement</span>
                                    <span className="Link-Text x-small" onClick={() => window.open("https://www.creditonebank.com/privacy", "_blank")}>Privacy</span>
                                    <span className="Link-Text x-small" onClick={() => window.open("https://www.creditonebank.com/terms-of-use", "_blank")} >Terms of Use</span>
                                </div>
                                <div className="row">
                                    <span className="Link-Text x-small" onClick={() => window.open("https://www.creditonebank.com/security", "_blank")}>Security & Fraud</span>
                                    <span className="Link-Text x-small" onClick={() => window.open("https://www.creditonebank.com/deposits-faqs", "_blank")}>FAQs</span>
                                </div>
                                <div className="copywrite-wrap">
                                    <span className="copywrite-text x-small">{`© ${year} Credit One Bank, N.A.`}</span>
                                    <span className="copywrite-text x-small">All Rights Reserved. Member FDIC.</span>
                                </div>
                            </div>
                            {showRatesModal &&
                                <ProductsModal
                                    setShowModal={setShowRatesModal}
                                    CDProductList={accountInformation.CDProductList}
                                    bumpProductList={accountInformation.BumpProductList}
                                    savingsProductList={accountInformation.SavingsProductList}
                                    showSavingsProducts={true}
                                    origin='help'
                                />
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ContactPage