// import { useAccountDetails } from '../../../../../useHooks/pageHooks/useAccountDetails';
import emptyTransactions from '../../../../../assets/emptyTransactions.svg';
import { TransactionTable } from '../../../../../components/TransactionTable/TransactionTable';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import { useAppSelector } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
import warning from '../../../../../assets/warning.svg'

/**
 * The Mobile Account Details CD Tranactions Table.
 * @param props 
 * @returns 
 */
export const AccountDetailsCDTransactionTable = (props: any) => {
  const { selectedAccount, transactions, history, selectedTransaction, displayDetails, setDisplayDetails, didTransactionsLoad } = props;
  const { windowState } = useWindowState();
  const transactionsLoaded = useAppSelector((state: RootState) => state.transactions.transactionsLoaded);
  
  if (transactionsLoaded === 'Failed') {
    return <FailedToLoadTransactionWrapper />
  }

  if (windowState === 'Mobile') {
    return (
      <>
        {transactionsLoaded !== "Loading" ?
          <div className="encapsulation-card">
            <div className="Transaction-History-Wrapper">
              <span className="Transaction-History h4">
                Recent transactions
              </span>
              <div data-testid="view-all" className="Vew-All-Wrapper" onClick={() => history.push(`/account/${selectedAccount.id}/transactions`)}>
                <span className="View-All-Text semiboldRegular">
                  View all
                </span>
              </div>
            </div>
            <div className="transaction-table">
              {transactions.length > 0 ?
                <TransactionTable
                  transactions={transactions}
                  startIndex={0}
                  endIndex={5}
                  selectedTransaction={selectedTransaction}
                  displayDetails={displayDetails}
                  setDisplayDetails={setDisplayDetails}
                  isSortable={false}
                />
                :
                <>
                  {selectedAccount.isTransactionsLoaded &&
                    <div className="empty-state-wrap">
                      <img className="empty-image" src={emptyTransactions} alt="no transactions" />
                      <span className="text mobileh4">No transactions to display</span>
                      <span className="text small">No transactions to display here yet. Future transactions will appear here</span>
                    </div>
                  }
                </>
              }
            </div>
          </div>
          :
          <div className="loading-box-wrapper">
            <div className="loading-box" />
          </div>
        }
      </>
    )
  }


  return (
    <>
      {transactionsLoaded !== "Loading" ?
        <div className="outter-transaction-wrap">
          <div className="Transaction-History-Title-Wrapper">
            <div className="header-wrap">
              <div className="Transaction-History-Title h4">
                Recent transactions
              </div>
              <div data-testid="view-all" className="Vew-All-Wrapper" onClick={() => history.push(`/account/${selectedAccount.id}/transactions`)}>
                <span className="View-All-Text boldRegular">View all</span>
              </div>
            </div>
            <div className="Transaction-Table">
              {transactions.length > 0 ?
                <TransactionTable
                  transactions={transactions}
                  startIndex={0}
                  endIndex={5}
                  selectedTransaction={selectedTransaction}
                  displayDetails={displayDetails}
                  setDisplayDetails={setDisplayDetails}
                  isSortable={false}
                />
                :
                <>
                  {selectedAccount.isTransactionsLoaded &&
                    <div className="empty-state-wrap">
                      <img className="empty-image" src={emptyTransactions} alt="empty transactions state" />
                      <span className="text h4">No transactions to display</span>
                      <span className="text regular">No transactions to display here yet. Future transactions will appear here</span>
                    </div>
                  }
                </>
              }
            </div>
          </div>
        </div>
        :
        <div className="loading-box-wrapper">
          <div className="loading-box" />
        </div>
      }
    </>
  )
}


const FailedToLoadTransactionsDesktop = () => {
  return <div className="outter-transaction-wrap">
    <div className="Transaction-History-Title-Wrapper">
      <div className="header-wrap">
        <div className="Transaction-History-Title h4">
          Recent Transactions
        </div>
      </div>
      <div className="card-error-state" style={{ height: 'auto' }}>
        <div className="image-wrap" style={{ margin: 0 }}>
          <img className="error-image" src={warning} alt="error" />
          <span className="sub-title-text boldButtonLarge">Unable to load data</span>
          <span className="text regular">Unable to load data. Please wait a moment and try again</span>
        </div>
      </div>
    </div>
  </div>
}


const FailedToLoadTransactionsMobile = () => {
  return <div className="encapsulation-card">
    <div className="Transaction-History-Wrapper">
      <span className="Transaction-History h4">
        Recent Transactions
      </span>

    </div>
    <div className="transaction-table">
      <div className="card-error-state" style={{ height: 'auto' }}>
        <div className="image-wrap" style={{ margin: 0 }}>
          <img className="error-image" src={warning} alt="error" />
          <span className="sub-title-text boldButtonLarge">Unable to load data</span>
          <span className="text regular">Unable to load data. Please wait a moment and try again</span>
        </div>
      </div>
    </div>
  </div>
}

const FailedToLoadTransactionWrapper = () => {
  const { windowState } = useWindowState();

  if (windowState === 'Mobile') {
    return <FailedToLoadTransactionsMobile />
  }

  return <FailedToLoadTransactionsDesktop />
}




