import { ENV, getEnvironment } from './getEnvironment'

/**
 * Loads the scripts for adobe analytics
 */
export const loadAdobeScript = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  let script = document.createElement('script')
  script.async = true
  if (getEnvironment() === ENV.PROD) {
    script.src = 'https://assets.adobedtm.com/b598760f96d3/2db516e41000/launch-fab76cfc7ce0.min.js'
  }
  else {
    script.src = 'https://assets.adobedtm.com/b598760f96d3/2db516e41000/launch-849563205164-staging.min.js';
  }

  document.head.appendChild(script)
}