import './DesktopVerifyExternalAccount.scss'
import arrowLeft from '../../../assets/arrowLeft.svg'
import verificationBank from '../../../assets/verificationBank.svg'
// import bankStatement from '../../../assets/bankStatement.svg'
import imgBankStatementDesktop from '../../../assets/imgBankStatementDesktop.svg'
import { useVerifyExternalAccount } from '../../../useHooks/pageHooks/useVerifyExternalAccount'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import greenCheckmark from '../../../assets/greenCheckmark.svg'
import redExclamationCircle from '../../../assets/redExclamationCircle.svg'
import FailedToLink from '../components/FailedToLink/FailedToLink'
import iconBank from '../../../assets/iconBank.svg'
import { Button, TIMER_IDS } from '../../../components/Button/Button'

/**Renders the desktop version of the VerifyExternalAccount page */
const DesktopVerifyExternalAccount = () => {
    const { history, enableVerify, handleAccountKeyDown, handleValueOne, handleValueTwo, handleVerifyDeposits, type, message,
        showSuccess, showError, selectedExternalAccount, handleReturn, showLocked, valueOne, valueTwo } = useVerifyExternalAccount()

    return (
        <div className="desktop-verify-ext-account-wrapper">
            {message !== "" && <ToastMessageDB type={type} message={message} />}
            <div className="header-wrap">
                <div className="nav-wrap">
                    <img className="chevron" src={arrowLeft} alt="white left facing chevron" onClick={() => history.push("/user/profile/external-accounts")} />
                    <span className="header-text regular" onClick={() => history.push("/user/profile/external-accounts")}>Manage external accounts</span>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="data-card">
                    {!showSuccess ?
                        <>
                            {!showLocked ?
                                <>
                                    <span className="title-text h2">Verify your account</span>
                                    <div className="content-wrap">
                                        <div className="left-side">
                                            <img className="image" src={verificationBank} alt="bank" />
                                            <span className="info-text regular">We've made two small desposits to your bank account. Enter the deposit amounts to verify your account.</span>
                                            {showError &&
                                                <div className="error-wrapper">
                                                    <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                                                    <div className="message-stack">
                                                        <span className="error-title boldLarge">Unable to verify</span>
                                                        <span className="error-text regular">The amounts you entered don't match the deposits we sent to your bank account. Please try again.</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="input-row">
                                                <label className="input-wrap">
                                                    <div className="label-row">
                                                        <span className={showError ? "label-text small error" : "label-text small"}>Deposit amount&nbsp;<span className="red-star">*</span></span>
                                                    </div>
                                                    <div className={showError ? "input-symbol-wrap regular error" : "input-symbol-wrap regular"}>{valueOne.length > 0 && "$"}
                                                        <input
                                                            className='input-box regular'
                                                            type="number"
                                                            min=".01"
                                                            max=".99"
                                                            required
                                                            onKeyDown={(e: any) => handleAccountKeyDown(e)}
                                                            onChange={(e: any) => handleValueOne(e)}
                                                            placeholder="$0.00"
                                                        />
                                                    </div>
                                                </label>
                                                <label className="input-wrap">
                                                    <div className="label-row">
                                                        <span className={showError ? "label-text small error" : "label-text small"}>Deposit amount&nbsp;<span className="red-star">*</span></span>
                                                    </div>
                                                    <div className={showError ? "input-symbol-wrap regular error" : "input-symbol-wrap regular"}>{valueTwo.length > 0 && "$"}
                                                        <input
                                                            className='input-box regular'
                                                            type="number"
                                                            min={.01}
                                                            max={.99}
                                                            required
                                                            onKeyDown={(e: any) => handleAccountKeyDown(e)}
                                                            onChange={(e: any) => handleValueTwo(e)}
                                                            placeholder="$0.00"
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="right-side">
                                            <img className={`bank-statement`} src={imgBankStatementDesktop} alt="bank statement" />
                                            <div className="icon-header-wrap">
                                                <img src={iconBank} alt="blue colored bank sketch" />
                                                <span className={`nickname semiboldRegular`}>{selectedExternalAccount?.accountNickName} {selectedExternalAccount?.lastFour}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-wrapper">
                                        <button className="Button btn-secondary" onClick={() => history.push("/user/profile/external-accounts")}>Cancel</button>
                                        <Button 
                                            title="Verify deposits"
                                            isDisabled={!enableVerify}
                                            className={enableVerify ? "Button btn-primary" : "Button btn-primary inactive"}
                                            id={TIMER_IDS.MICROTRANSACTION_VALIDATE}
                                            onClick={() => handleVerifyDeposits()}
                                        />
                                    </div>
                                </>
                                :
                                <FailedToLink />
                            }
                        </>
                        :
                        <div className="validate-success-wrap">
                            <img className="success-image" src={greenCheckmark} alt="green circle with white checkmark" />
                            <span className="success-title bold-x-large">Thanks for verifying your account!</span>
                            <span className="success-text regular">Your account&nbsp;
                                <span className="semiboldRegular">{selectedExternalAccount.accountNickName} {selectedExternalAccount.lastFour}</span>
                                &nbsp;has been linked and is ready to use.
                            </span>
                            <div className="success-button-wrapper">
                                <button className="Button btn-primary" onClick={() => { handleReturn(); history.push("/user/profile/external-accounts") }}>Return to external accounts</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DesktopVerifyExternalAccount