import { IMobileHeaderBoxProps } from './interfaces/IMobileHeaderBoxProps'
import './MobileHeaderBox.scss'
import arrowLeft from '../../../../assets/arrowLeft.svg'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'

/**Renders the header box for the mobile user information flows, show nav back to profile landing and page title */
const MobileHeaderBox = (props: IMobileHeaderBoxProps) => {
    const { title, setCurrentTab } = props
    const history = useHistory();
    const [previousPage, setPreviousPage] = useState<string>();
    
    /**
     * Sets the state of previousPage, depending on if there is a `page` state pushed in from history.push
     * This is used to dynamically set the text of the back button on certain pages
     */
        useEffect (() => {
            if (history.location.state !== undefined && history.location.state !== null) {
                const STATE: {page: string} = history.location.state as {page: string}
                setPreviousPage(STATE.page)
            };
        //eslint disabling on the next line because we only want to run this useEffect whenever history.location.state is updated. Which should only be once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [history.location.state]);

    return (
        <div className="header-box-wrap">
            <div className="top-row">
                <img className="image" src={arrowLeft} alt="back arrow" onClick={() => history.goBack()}/>
                <span className="return-text regular" onClick={() => history.goBack()}>{previousPage || "Profile"}</span>
            </div>
            <span className="title-text semiboldLarge">{title}</span>
        </div>
    )
}

export default MobileHeaderBox