import { ITransactionHistoryData } from "../api/getTransactionHistory";

/**Handles the filtering of the custom date picker */
export const filterCustomDates = (fromDate: number, toDate: number, transactions: Array<ITransactionHistoryData>) => {
    let filteredArray = transactions.filter((transaction: ITransactionHistoryData) => {
        let transactionDate: any = ""
        if(transaction.effectiveDate.includes("T")){
            transactionDate = new Date(transaction.effectiveDate).getTime()
        }
        else{
            const [ year, month, day ] = transaction.effectiveDate.split("-")
            transactionDate = new Date(year, month - 1, day, 0, 0, 0).getTime()
        }
        if(transactionDate >= fromDate && transactionDate <= toDate){
            return transaction
        }
        return false;
    })
    
    return filteredArray
}

