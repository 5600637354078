import './DesktopHeader.scss'
import c1bLogo from '../../../../assets/c1bLogo.svg'
import plusCircle from '../../../../assets/plusCircle.svg'
import questionMark from '../../../../assets/questionMark.svg'
import arrowDownWhite from '../../../../assets/arrowDownWhite.svg'
import DesktopDropdown from '../DesktopDropdown'
import { useHeader } from '../../../../useHooks/componentHooks/headerComponentHook'
import { getSAMLURL } from '../../../../shared/config/getSAMLUrl'
import { useOpenNewAccount } from '../../../../Context/OpenNewAccountContext/useOpenNewAccountContext'

/**
 * The DesktopHeader component displays the desktop header view for digital bank.  When the screens pixel size is 800 or greater
 * this component will be called from the Headers wrapper component.
 * @param props currently no props are being used
 * @returns desktop header view
 */
const DesktopHeader = () => {
    const { userName, history, initialClick, setInitialClick, showMenu, setShowMenu, gotUserContactInfo, isAccountInformationLoaded } = useHeader()
    const { checkExternalAccounts } = useOpenNewAccount()

    return (
        <div>
            <div className="Header-Wrapper">
                <div className="Left-Side">
                    <img src={c1bLogo} alt="Credit One Bank Logo" className="Icon" style={{cursor: "pointer"}} onClick={() => history.push("/")}/>
                    <div className="right">
                        {((isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") && (gotUserContactInfo === "Success" || gotUserContactInfo === "Loading")) &&
                            <>
                                <img src={plusCircle} alt="Open ew Account" className="Icon Plus-Circle" onClick={checkExternalAccounts}/>
                                <span className="Text boldSmall" onClick={checkExternalAccounts}>Open a new account</span>
                                {/* <a href={getSAMLURL()} style={{ textDecoration: 'none' }} target='_blank' rel="noreferrer" className="Text boldSmall" >Open a new account</a> */}
                            </>
                        }
                        <img data-testid="help-icon" src={questionMark} alt="Help" className="Icon Question-Mark" onClick={() => history.push('/knowledge-base')} />
                        <span className="Text Help-Text boldSmall" onClick={() => history.push('/knowledge-base')}>Help</span>
                    </div>
                </div>
                {(gotUserContactInfo === "Success") &&
                    <div className="Customer-Dropdown" onClick={() => { setShowMenu(!showMenu); setInitialClick(true) }}>
                        {/* <img src={personCircle} alt="User Info" className="Person-Circle" /> */}
                        <span className="User-Name boldSmall">{userName}</span>
                        <img src={arrowDownWhite} alt="Drop down arrow" className="Drop-Down-Arrow" />
                    </div>
                }
            </div>
            {initialClick &&
                <div className={showMenu ? "Open-Dropdown" : "Close-Dropdown"}>
                    <DesktopDropdown showMenu={showMenu} setShowMenu={setShowMenu} />
                </div>
            }
        </div>
    )
}

export default DesktopHeader