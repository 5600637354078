import './Headers.scss';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import { useWindowState } from '../../Context/AccountContext/useWindowState';
import { useRef } from 'react';

/** 
 * Headers is a wrapper component used to get screen size, user information, and to display the correct header component
 * DesktopHeader is called when the screen size is 800 pixels or larger
 * MobileHeader is called when the screen size is less than 800 pixels
 */
const Headers = () => {
  const { windowState } = useWindowState()

  return (
    <div className="Headers">
      {(windowState === "Desktop" || windowState === "Hybrid") ? <DesktopHeader /> : <MobileHeader />}
    </div>
  );
};

export default Headers
