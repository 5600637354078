import { useWindowState } from '../../Context/AccountContext/useWindowState';
import './Footer.scss'; 

/** 
 * Creates the footer for the desktop version.
 */ 
export const Footer = () => {  
  /**
   * Uncomment the useWindowState hook if a mobile version footer is added
   */
  const { windowState } = useWindowState();
  const newDate = new Date() 
  const year = newDate.getFullYear()


  return ( 
    <div className={`Footer-Wrapper ${windowState}`}> 
    {windowState === "Desktop" ?
      <div className="Nav-Bar">
        <div className="Left-Side">
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/content/dam/creditonebank/corporate-transactional/pdf/Deposit_Agreement_Disclosures.pdf", "_blank")}>Deposit Agreement</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/privacy", "_blank")}>Privacy</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/terms-of-use", "_blank")} >Terms of Use</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/security", "_blank")}>Security & Fraud</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/deposits-faqs", "_blank")}>FAQs</span>
        </div>
        <span className="Copyright-Text small">{`© ${year} Credit One Bank, N.A. All Rights Reserved. Member FDIC.`}</span>
      </div>
      :
      <div className="hybrid-wrapper">
        <div className={`first-row ${windowState}`}>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/content/dam/creditonebank/corporate-transactional/pdf/Deposit_Agreement_Disclosures.pdf", "_blank")}>Deposit Agreement</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/privacy", "_blank")}>Privacy</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/terms-of-use", "_blank")} >Terms of Use</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/security", "_blank")}>Security & Fraud</span>
          <span className="Link-Text small" onClick={() => window.open("https://www.creditonebank.com/deposits-faqs", "_blank")}>FAQs</span>
        </div>
        <span className="Copyright-Text hybridSmall">{`© ${year} Credit One Bank, N.A. All Rights Reserved. Member FDIC.`}</span>
      </div>
    }
    </div> 
  ); 
}; 
