import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import './FailedToLink.scss'
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'
import { useHistory } from 'react-router-dom'


/**Renders a message informing the user that the linked external account process has failed and deletes the linked account */
const FailedToLink = () => {
    const history = useHistory()
    const { windowState } = useWindowState()

    return (
        <div className={`failed-to-link-wrap ${windowState}`}>
            <img className={`failed-image ${windowState}`} src={redExclamationCircle} alt="red circle with exclaimation mark in center" />
            <span className="failed-title-text h4">Sorry, we couldn't verify your account</span>
            <span className="failed-message-text regular">You've reached the maximum number of attempts to verify the deposit amounts sent to this bank account, so we are unable to link this bank account.</span>
            <div className="failed-button-wrapper">
                <button className={`Button btn-primary ${windowState}`} onClick={() => history.push("/user/profile/external-accounts")}>Return to external accounts</button>
            </div>
        </div>
    )
}

export default FailedToLink