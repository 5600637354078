import { convertDateTommddyyyy, isUTCFormat } from '../../../../utils/DateUtils';
import { Pill } from '../../../Pill/Pill';
import './MobileTransactionTable.scss';
import { ITransactionHistoryData } from '../../../../api/getTransactionHistory';
import { IMobileTransactionTableProps } from './interfaces/IMobileTransactionTableProps';
import { TransactionDetails } from '../../../TransactionDetails/TransactionDetails';
import { displayCurrency } from '../../../../utils/displayCurrency';
import warning from '../../../../assets/warning.svg'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { updateOnTransactionDetails } from '../../../../slices/transactions/transactionsSlice';
import lightGreyRightArrow from '../../../../assets/lightGreyRightArrow.svg'
import { setSelectedTransaction } from '../../../../slices/selectedTransaction/selectedTransactionSlice';
import appendZeroToDay from '../../../../utils/appendZeroToDay';

/**
 * Mobile Transaction table for Overview Page
 * @param transactionHistory - history to display
 * @param startIndex - (optional) start index of transaction array for display
 * @param endIndex - (optional) end index of transaction array for display
 */
export const MobileTransactionTable = (props: IMobileTransactionTableProps) => {
  const {
    transactionHistory,
    startIndex,
    endIndex,
    setDisplayDetails,
    displayDetails,
    selectedTransaction
  } = props;
  const transactionsLoaded = useAppSelector((state: RootState) => state.transactions.transactionsLoaded)
  const dispatch = useAppDispatch()

  return (
    <div>
      <>
        {transactionsLoaded === "Success" ?
          <>
            {displayDetails && selectedTransaction ? (
              <TransactionDetails
                setDisplayDetails={setDisplayDetails}
                selectedTransaction={selectedTransaction}
                displayDetails={displayDetails}
              />
            ) : (
              <div className="mobile-transaction-preview">
                <table>
                  <tbody>
                    {(transactionHistory && transactionHistory.length > 0) && transactionHistory
                      .slice(startIndex, endIndex)
                      .map((e: ITransactionHistoryData, index: number) => {
                        return (
                          <tr
                            key={e.id + e.currentBalance}
                            className={index + 1 !== endIndex ? "row" : "row last"}
                            onClick={() => {
                              setDisplayDetails(true);
                              dispatch(setSelectedTransaction({ transaction: e, index: index }))
                              dispatch(updateOnTransactionDetails(true))
                            }}
                          >
                            <td className="left-side">
                              <div className="description mobileRegular">
                                {e.description}
                              </div>
                              <div className="date mobileRegular">
                                {appendZeroToDay((isUTCFormat(e?.effectiveDate) ? convertDateTommddyyyy(new Date(e?.effectiveDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})) : convertDateTommddyyyy(e?.effectiveDate)) , ' ')}
                              </div>
                            </td>
                            <td className="right-side">
                              <div className="amount-status-stack">
                                <div className="amount semiboldRegular">
                                  {e.type === "Debit" ? <span className="debit"> {displayCurrency(e?.amount)}</span> : displayCurrency(e.amount)}
                                </div>
                                {e?.isAmountHold && <Pill title="On Hold" />}
                              </div>
                              <img
                                data-testid="details-chevron"
                                src={lightGreyRightArrow}
                                alt="arrow"
                                className="arrow-image"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </>
          :
          <div className="transaction-loading mobile" />
        }
      </>
    </div>
  );
};
