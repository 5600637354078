import { IAccountAgreements, IAccountNotices, ITaxDocuments } from "../api/getAccountInformation"
import { IStatements } from "../api/getStatements"
import { IDocument } from "../testUtils/utils/interfaces/IMockAPIInterfaces"

/**Populates the account statements */
export const populateStatements = (statements: Array<IDocument>) => {
    const statementsArray: Array<IStatements> = []
    for(let i = 0; i < statements.length; i = i + 1){
        let tempArray: IStatements = {
            id: statements[i].documentId,
            date: statements[i].date
        }
        statementsArray.push(tempArray)
    }
    return statementsArray
}

/**Populates the tax documents */
export const populateTaxDocuments = (statements: Array<IDocument>) => {
    const taxDocumentsArray: Array<ITaxDocuments> = []
    for(let i = 0; i < statements.length; i = i + 1){
        let tempDocument: ITaxDocuments = {
            id: statements[i].documentId,
            date: statements[i].date,
            title: statements[i].documentTitle as string
        }
        taxDocumentsArray.push(tempDocument)
    }

    return taxDocumentsArray
}


/**Populates the account notices & other docuemnts */
export const populateAccountNotices = (statements: Array<IDocument>) => {
    const noticesArray: Array<IAccountNotices> = []
    for(let i = 0; i < statements.length; i = i + 1){
        let tempDocument: IAccountNotices = {
            id: statements[i].documentId,
            date: statements[i].date,
            title: ""
        }
        noticesArray.push(tempDocument)
    }

    return noticesArray
}

/**Populates the account agreements */
export const populateAccountAgreements = (statements: Array<any>) => {
    const agreementsArray: Array<IAccountAgreements> = []
    for(let i = 0; i <statements.length; i = i + 1){
        let tempDocument: IAccountAgreements = {
            id: statements[i].docId,
            date: "",
            title: statements[i].docName
        }
        agreementsArray.push(tempDocument)
    }

    return agreementsArray
}