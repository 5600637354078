import { useEffect, useRef, useState } from "react"
import { IAddress, IPhone } from "../../../api/User/interfaces"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useUserProfileContext } from "../../../Pages/UserProfile/Context/useUserProfileContext"

/**
 * custom hook used for the contact information tab on the user profile page
 */
export const useContactInformation = (setOpenEditPage: any, addressAction: "" | "Edit" | "EditMain" | "Add" | "Remove", setAddressAction: any) => {
    useScrollToTop()
    const {arrayIndex, setArrayIndex, initializeFormFields } = useUserProfileContext()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    // const [arrayIndex, setArrayIndex] = useState<number>(0)
    const [selectedAddress, setSelectedAddress] = useState<IAddress>({
        id: "",
        addressType: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        isMailing: false,
        isResidential: false
    })
    const [selectedPhone, setSelectedPhone] = useState<IPhone>({
        id: "",
        type: "Mobile",
        number: ""
    })
    const [showMaxAddressModal, setShowMaxAddressModal] = useState<boolean>(false)
    const openOTPModal = useAppSelector((state: RootState) => state.userInformation.openOTPModal)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const dispatch = useAppDispatch()
    const editTypeRef = useRef<"Email" | "Mobile" | "Alternate" | "Add" | "Addresses" | "">("")
    const editButtonClickedRef = useRef<boolean>(false)
    const {message, type, resetToast } = useToastMessage()

    /**when the message (Toast Message) is not an empty string we reset it to an empty string*/
    useEffect(() => {
        if(message !== ""){
            resetToast()
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    /**Handle add new address onClick event */
    const handleAddNewAddress = () => {
        if(userInformation.contactInformation.addresses.length < 5){
            // setOpenEditPage("Addresses"); 
            setAddressAction("Add")
            handleEditClick("Addresses")
        }
        else{
            setShowMaxAddressModal(true)
        }
    }

    /**handles the onClick for the edit buttons */
    const handleEditClick = async (editType: "Email" | "Mobile" | "Alternate" | "Add" | "Addresses") => {
      
        dispatch(setOTPPassed(false))
        editTypeRef.current = editType
        editButtonClickedRef.current = true
        dispatch(setCheckInternalToken(true))
    }

    /**monitors the success of the otpModal and sets the openEditPage state to open once the OTP passes */
    useEffect(() => {
        if(OTPPassed && editButtonClickedRef.current){
            if(editTypeRef.current === "Addresses"){
                initializeFormFields(addressAction)
            }
            editButtonClickedRef.current = false
            dispatch(setOTPPassed(false))
            setOpenEditPage(editTypeRef.current)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    return { userInformation,showTooltip, setShowTooltip, arrayIndex, setArrayIndex, selectedAddress, setSelectedAddress, selectedPhone, setSelectedPhone, 
            showMaxAddressModal, handleAddNewAddress, setShowMaxAddressModal, handleEditClick, openOTPModal, OTPPassed, message, type }
}