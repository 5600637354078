import { useManageDevices } from '../../../../useHooks/componentHooks/useManageDevices/useManageDevices'
import './DesktopManageDevices.scss'
import { IDesktopManageDevicesProps } from './interfaces/IDesktopManageDevicesProps'
import arrowLeft from '../../../../assets/arrowLeft.svg'
import { ITrustedDevice } from '../../../../api/User/interfaces'
import { convertDateWithTime } from '../../../../utils/DateUtils'

/**Renders the manage devices page for desktop found in Security Settings of the user profile flow */
const DesktopManageDevices = (props: IDesktopManageDevicesProps) => {
    const { setManageDevices } = props
    const { userInformation, selectedDevice, changeName, setChangeName, deleteDevice, setDeleteDevice,
        handleRemoveDevice } = useManageDevices()

    return (
        <div className="desktop-manage-devices-wrap">
            <div className="change-header">
                <div className="text-wrap">
                    <img className="header-image" src={arrowLeft} alt="back arrow" onClick={() => setManageDevices(false)} />
                    <span className="header-text regular" onClick={() => setManageDevices(false)}>Security settings</span>
                </div>
            </div>
            <div className="devices-card-wrapper">
                <div className="data-wrap">
                    {/* {!changeName ? */}
                    <>
                        <span className="header-text h2">Sign-in history</span>
                        <div className="bottom-border" />
                        <div className="table-headers">
                            <span className="column-size-one semibold">Devices</span>
                            {/* <span className="column-size-one semibold">Device name</span> */}
                            <span className="column-size-two semibold">IP address</span>
                            <span className="column-size-three semibold">Last sign-in</span>
                        </div>
                        {userInformation.trustedDevices.map((device: ITrustedDevice, index: number) => {
                            return (
                                <div key={device.id} className="device-row-wrap">
                                    <span className="column-size-one regular">{device.device}</span>
                                    {/* <span className="column-size-one regular">{device.deviceNickname}</span> */}
                                    <span className="column-size-two regular cls_mask">{device.ip}</span>
                                    <div className="column-size-three right-side regular">
                                        <span className="regular">{convertDateWithTime(device.lastSignIn)}</span>
                                        <div className="right">
                                            {/*device.currentlyUsing ?
                                                    <span className="current-device-pill mobile-h6">Current device</span>
                                                    :
                                                    <div />
                                                */}
                                            {/* <div className="image-wrap">
                                                <img className="elipses" src={elipses} alt="elipses" onClick={() => {setOpenMenu(true); setSelectedIndex(index)}}/>
                                                {(openMenu && selectedIndex === index) &&
                                                    <div className="menu-wrap">
                                                        <div className="menu-row" onClick={() => {setChangeName(true); setSelectedDevice(device); setOpenMenu(false)}}>
                                                            <img src={bluePencil} alt="edit" />
                                                            <span className="blue-text regular">Change device name</span>
                                                        </div>
                                                        <div className="border-bottom" />
                                                        <div className="menu-row" onClick={() => {setDeleteDevice(true); setSelectedDevice(device); setOpenMenu(false)}}>
                                                            <img src={redTrashCan} alt="delete" />
                                                            <span className="red-text regular">Delete</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* deleteDevice &&
                                <div className="delete-modal-wrap">
                                    <div className="modal-wrap">
                                        <img src={deletedDevice} alt="delete device" />
                                        <span className="title-text h4">Remove {selectedDevice.deviceNickname} as a trusted device ?</span>
                                        <span className="modal-text regular">This action cannot be undone.</span>
                                        <div className="button-wrap">
                                            <button className="Button btn-secondary cancel" onClick={() => setDeleteDevice(false)}>Cancel</button>
                                            <button className="Button btn-primary remove" onClick={() => handleRemoveDevice()}>Remove device</button>
                                        </div>
                                    </div>
                                </div>
                            */}
                    </>
                    {/* : */}
                    {/* <ChangeDeviceName
                            setManageDevices={setChangeName}
                            selectedDevice={selectedDevice}
                        /> */}
                    {/* } */}
                </div>
            </div>
        </div>
    )
}

export default DesktopManageDevices