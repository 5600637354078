import "./CancelTransferModal.scss"
import { ICancelTransferModalProps } from "./interfaces/ICancelTransferModalProps"
import dollarCoin from '../../../../assets/dollarCoin.svg'
import { useCancelTransferModal } from "../../../../useHooks/componentHooks/useCancelTransferModal/useCancelTransferModal"
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'
import { Button, TIMER_IDS } from "../../../../components/Button/Button"

/**Renders the modal to cancel a transfer */
const CancelTransferModal = (props: ICancelTransferModalProps) => {
    const { selectedTransfer, transferHistory, setTransferHistory, setOpenModal, setOpenTransferDetails, screen, transferErrored, setTransferErrored, handleClose, handleCloseModal } = props
    const { handleTransferCancel, handleCloseButtonModalClick, newTransferArrayRef } = useCancelTransferModal(selectedTransfer, transferHistory, setTransferHistory, setOpenModal, setTransferErrored, transferErrored, setOpenTransferDetails)

    return (
        <div className="cancel-modal-wrapper">

            <div className={screen !== "Mobile" ? "cancel-modal-details-wrap" : "cancel-modal-details-wrap mobile"}>
                {!transferErrored ?
                    <>
                        <img src={dollarCoin} alt="dollar coin" />
                        <span className="header-text h4">Cancel transfer?</span>
                        <span className="sub-header-text regular">You cannot undo this action.</span>
                        {screen !== "Mobile" ?
                            <div className="button-wrap">
                                <button className="Button btn-secondary" onClick={() => setOpenModal(false)}>Keep transfer</button>
                                <Button 
                                    title="Cancel transfer"
                                    className="Button btn-primary"
                                    id={TIMER_IDS.TRANSFER_CANCEL}
                                    onClick={() => handleTransferCancel()}
                                />
                            </div>
                            :
                            <div className="button-wrap mobile">
                                <Button 
                                    title="Cancel transfer"
                                    className="Button btn-primary mobile"
                                    id={TIMER_IDS.TRANSFER_CANCEL}
                                    onClick={() => handleTransferCancel()}
                                />
                                <button className="Button btn-secondary mobile" onClick={() => setOpenModal(false)}>Keep transfer</button>
                            </div>
                        }
                    </>
                    :
                    <>
                        <img src={redExclamationCircle} alt="warning circle" />
                        <span className="header-text h4">This transfer cannot be canceled</span>
                        <span className="sub-header-text regular">This transfer is already complete.</span>
                        {screen !== "Mobile" ?
                            <div className="button-wrap">
                                {newTransferArrayRef.current.length > 0 ?
                                    <button className="Button btn-primary" onClick={() => { handleClose(setTransferHistory); handleCloseModal() }}>Close</button>
                                    :
                                    <button className="Button btn-primary" onClick={() => { handleCloseButtonModalClick(); handleClose(setTransferHistory); handleCloseModal() }}>Close</button>
                                }
                            </div>
                            :
                            <div className="button-wrap mobile">
                                {newTransferArrayRef.current.length > 0 ?
                                    <button className="Button btn-primary mobile" onClick={() => { handleClose(setTransferHistory); handleCloseModal() }}>Close</button>
                                    :
                                    <button className="Button btn-primary mobile" onClick={() => { handleCloseButtonModalClick(); handleClose(setTransferHistory); handleCloseModal() }}>Close</button>
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default CancelTransferModal