/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { buildTransactionHistoryData, ITransactionHistoryData } from '../../../api/getTransactionHistory';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { useWindowState } from '../../../Context/AccountContext/useWindowState';
import { clearTransactionsArray, getTransactionsAsync, updateTransactionArray } from '../../../slices/transactions/transactionsSlice';

/**
 * use hook for transaction table
 * @returns transactionHistory, selectedTransaction, setSelectedTransaction, displayDetails, setDisplayDetails
 */
export const useTransactionTable = (transactions: Array<ITransactionHistoryData>, setFilteredTransactions: any) => {
  const { windowState } = useWindowState(); //! Hard coded for demonstration only. Will be changed to selectedAccount once merged
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  const dispatch = useAppDispatch()
  const [displayDetails, setDisplayDetails] = useState<boolean>(false);
  const prevTransactions = useRef(transactions)

  /**
   * Calls getAccountTransactionArray function to populate transactionHistory
   * and set the state for the current account's transaction history.
   */
  useEffect(() => {
    //if(selectedAccount.accountNumber !== ""){
      // getAccountTransactionArray()
    //}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount])

  return {
    displayDetails, setDisplayDetails, windowState
  };
};
