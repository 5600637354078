// import Input from '../../../../../components/Input'
import { RadioButton } from '../../../../../components/RadioButton/RadioButton'
// import { SelectDropdown } from '../../../../../components/SelectDropdown/SelectDropdown'
import { useGraceTransferFunds } from '../../../../../useHooks/componentHooks/useGraceTransferFunds/useGraceTransferFunds'
import { IGraceTransferFundsProps } from '../interfaces/IGraceTransferProps'
import './MobileGraceTransferFunds.scss'
import arrowLeftBlue from '../../../../../assets/arrowLeftBlue.svg'
import bankBlue from '../../../../../assets/bankBlue.svg'
import blueSettings from '../../../../../assets/blueSettings.svg'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import TransfersDropDown from '../../../../../components/TransfersDropDown/TransfersDropDown'
import MaxAccountsModal from '../../../../UserProfile/components/MaxAccountsModal/MaxAccountsModal'

/**Renders the Grace Transfer Funds page for the mobile version */
const MobileGraceTransferFunds = (props: IGraceTransferFundsProps) => {
    const { transferType, setTransferType, firstStepCompleted, setFirstStepCompleted, setShowTermChange, setFundTransfer, transferAccount, setTransferAccount } = props
    const { selectedAccount, handleRadioOnClick, addFunds, withdrawFunds, amountInputRef, handleAmountOnChange, linkedAccounts, history, handleOnContinue, enableContinue,
        enableFirstStep, handleCancel, addInputError, withdrawInputError, transferAmount, isAmountErrored, handleOnKeyDown, handleLinkExternalAccount, showLimitModal, setShowLimitModal } = useGraceTransferFunds(setTransferType, setShowTermChange, setFundTransfer, transferAccount, setTransferAccount)

    return (
        <div className="mobile-grace-fund-transfer-wrap">
            {showLimitModal &&
                <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
            }
            {!firstStepCompleted ?
                <div className="step-header">
                    <span className="cancel-button regular" onClick={() => handleCancel()}>Cancel</span>
                </div>
                :
                <div className="step-header two">
                    <div className="left-side">
                        <img src={arrowLeftBlue} alt="back button" />
                        <span className="back-text regular" onClick={() => { if (setFirstStepCompleted) { setFirstStepCompleted(false) } }}>Back</span>
                    </div>
                    <span className="cancel-button regular" onClick={() => { if (setFirstStepCompleted) { setFirstStepCompleted(false); handleCancel() } }}>Cancel</span>
                </div>
            }
            {!firstStepCompleted ?
                <div className="step-card-wrap">
                    <div className="header-wrap">
                        <span className="header-text small">Add or withdraw funds</span>
                        <span className="sub-header-text h4">Would you like to add or withdraw funds?</span>
                    </div>
                    <div className="radio-button-wrap">
                        <div className={addFunds ? "radio-button-selection selected" : "radio-button-selection"}>
                            <RadioButton
                                value={"Add funds"}
                                name="transfer funds"
                                className="radio"
                                onChange={() => handleRadioOnClick("Add")}
                                checked={addFunds}
                                textAlign="Right"
                            ><span>Add funds</span>
                            </RadioButton>
                        </div>
                        <div className={withdrawFunds ? "radio-button-selection selected" : "radio-button-selection"}>
                            <RadioButton
                                value={"Withdraw funds"}
                                name="transfer funds"
                                className="radio"
                                onChange={() => handleRadioOnClick("Withdraw")}
                                checked={withdrawFunds}
                                textAlign="Right"
                            ><span>Withdraw funds</span>
                            </RadioButton>
                        </div>
                    </div>
                    <span className="funds-message small">Fund transfer will be initiated immediately.</span>
                </div>
                :
                <div className="step-card-wrap">
                    <div className="header-wrap">
                        <span className="header-text small">Add or withdraw funds</span>
                        <span className="sub-header-text h4">Please enter your fund transfer details</span>
                    </div>
                    <label className="label-wrap">Amount
                        <div className={isAmountErrored ? "input-box-wrap error" : "input-box-wrap"}>
                            {transferAmount && transferAmount > 0 && <span className="regular">$</span>}
                            <input
                                data-testid="grace-amount-input"
                                className="input-box regular"
                                ref={amountInputRef}
                                onChange={(e: any) => handleAmountOnChange(e)}
                                type="number"
                                placeholder={"Enter amount"}
                                value={amountInputRef.current}
                                onKeyDown={(e: any) => handleOnKeyDown(e)}
                            />
                        </div>
                        {(withdrawFunds && !withdrawInputError) &&
                            <span className="available-balance small">Available balance to withdraw: {selectedAccount.balance - 100000 <= 0 ? displayCurrency(0) : displayCurrency(selectedAccount.balance - 100000)}</span>
                        }
                        {(addFunds && !addInputError) &&
                            <span className="available-balance small">You can add up to: {displayCurrency(1000000 - selectedAccount.balance)}</span>
                        }
                        {addInputError && <span className="error-text mobileSemiboldError">Entered Value Exceeds {displayCurrency(1000000 - selectedAccount.balance)}</span>}
                        {withdrawInputError && <span className="error-text mobileSemiboldError">Entered Value Exceeds: {selectedAccount.balance - 100000 <= 0 ? displayCurrency(0) : displayCurrency(selectedAccount.balance - 100000)}</span>}
                    </label>
                    <div className="dropdown-wrapper">
                        <TransfersDropDown
                            label={addFunds ? 'Transfer from' : 'Transfer to'}
                            isRequired={false}
                            defaultValue="Select an Account"
                            selectedOption={transferAccount}
                            setSelectedOption={setTransferAccount}
                            selectOptions={linkedAccounts}
                            testId='grace-transfer-account'
                        />
                    </div>
                    <div className="options-wrap">
                        <div className="option" onClick={() => handleLinkExternalAccount()}>
                            <img src={bankBlue} alt="link external account" />
                            <span className="option-text regular">Link external account</span>
                        </div>
                        <div className="option" onClick={() => history.push("/user/profile/external-accounts")}>
                            <img src={blueSettings} alt="manage external accounts" />
                            <span className="option-text regular">Manage external accounts</span>
                        </div>
                    </div>
                </div>
            }
            {!firstStepCompleted ?
                <div className="button-wrap">
                    <button className={enableFirstStep ? "Button btn-primary" : "Button btn-primary inactive"} onClick={() => { if (enableFirstStep && setFirstStepCompleted) { setFirstStepCompleted(true) } }}>Continue</button>
                </div>
                :
                <div className="button-wrap">
                    <button className="Button btn-primary" onClick={() => { if (enableContinue) { handleOnContinue(); if (setFirstStepCompleted) { setFirstStepCompleted(false) } } }}>Continue</button>
                </div>
            }
        </div>
    )
}

export default MobileGraceTransferFunds