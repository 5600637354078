import React, { useState, useEffect, useRef } from "react";
import { ITransferHistory } from "../../../api/transfers";
import { sortTransfers } from "../../../utils/sortTransfers";

/**A custom usehook holding the logic for the Transfer History table component found in the Transfer tab */
export const useTransferHistory = (
        transferHistory: Array<ITransferHistory>, 
        setTransferHistory: React.Dispatch<React.SetStateAction<Array<ITransferHistory>>>,
        totalTransfers: number,
        selectedTransfer: ITransferHistory
    ) => {
    const [startIndex, setStartIndex] = useState<number>(0)
    const [endIndex, setEndIndex] = useState<number>(10)
    const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(10);
    const [dateSort, setDateSort] = useState<"Up" | "Down" | "Default">("Up")
    const [amountSort, setAmountSort] = useState<"Up" | "Down" | "Default">("Default")
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openTransferDetails, setOpenTransferDetails] = useState<boolean>(false)
    const initialRenderRef = useRef<boolean>(false)
    // const [transferErrored, setTransferErrored] = useState<boolean>(false)

    /**after initial render set the initialRenderRef to true */
    useEffect(() => {
        setTimeout(() =>{
            initialRenderRef.current = true
        }, 500)
    }, [])

    /**
     * Re-sort after the filter has been applied, we are sorting with the filteredTransactionsRef this time due to
     * scheduling issues that the filtered transactions state has not yet been updated, but a ref updates instantly.
     */
    const sortTransferHistory = (column: "Date" | "Amount", direction: "Up" | "Down") => {
        let tempTransactionArray = transferHistory
        if(column === "Date"){
            if(direction === "Up"){
                tempTransactionArray = sortTransfers("Date", "Up", tempTransactionArray, setDateSort, setAmountSort)
            }
            else {
                tempTransactionArray = sortTransfers("Date", "Down", tempTransactionArray, setDateSort, setAmountSort)
            }
        }
        else{
            if(direction === "Up"){
                tempTransactionArray = sortTransfers("Amount", "Up", tempTransactionArray, setDateSort, setAmountSort)
            }
            else{
                tempTransactionArray = sortTransfers("Amount", "Down", tempTransactionArray, setDateSort, setAmountSort)
            }
        }
        setTransferHistory(tempTransactionArray)
    }

    /**useEffect resets the transfer errored state after a timeout */
    // useEffect(() => {
    //     if(transferErrored){
    //         setTimeout(() => {
    //             setTransferErrored(false)
    //         }, 5000)
    //     }

    // }, [transferErrored])

    // //!USE EFFECT MANAGES PAGINATION COMPONENT CHANGES AND CREATES A NEW CALL WITH START AND END PARAMTERS, ASSIGNS RESPONSE TO TRANSFER HISTORY
    // useEffect(() => {
    //     if(initialRenderRef.current){
    //         getTransfers()
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [startIndex, endIndex, dateSort, amountSort, numberOfItemsToShow])

    // /**Service call to get the new set of transfers */
    // const getTransfers = async () => {
    //     if(dateSort !== "Default"){
    //         try{
    //             let response = await getTransferHistory(startIndex + numberOfItemsToShow  / numberOfItemsToShow, numberOfItemsToShow, "date", dateSort === "Down" ? "ASC" : "DESC")
    //             setTransferHistory(populateTransfers(response.data.transferOrderHistory, ""))
    //         }
    //         catch{
    //             console.log("FAILED TO GET THE TRANSFERS")
    //         }
    //     }
    //     if(amountSort !== "Default"){
    //         try{
    //             let response = await getTransferHistory(startIndex + numberOfItemsToShow < totalTransfers ? Math.ceil(startIndex / numberOfItemsToShow) + 1 : 0, numberOfItemsToShow, "amount", amountSort === "Down" ? "ASC" : "DESC")
    //             setTransferHistory(populateTransfers(response.data.transferOrderHistory, ""))
    //         }
    //         catch{
    //             console.log("FAILED TO GET THE TRANSFERS")
    //         }
    //     }
    // }

    /**Handles the close button onClick event found in the cancel transfer error modal */
    const handleCloseModal = () => {
        console.log("INSIDE OF HANDLE CLOSE MODAL FROM TRANSFER HISTORY COMPONENT")
        setOpenModal(false)
    }

    return { startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow, setNumberOfItemsToShow, sortTransferHistory,
             dateSort, setDateSort, amountSort, setAmountSort, openModal, setOpenModal, openTransferDetails, setOpenTransferDetails,
             handleCloseModal }
}