
import { useRef, ChangeEvent } from 'react';

/**
 * Returns an array of indices where - exists in the `value`
 * @param value - The value to look at.
 * @example
 * ```js
 *  const DASHES_ARRAY = getDashes('XXX-XX-XXXX') //returns [3,6]
 * ```
 */
const getDashes = (value: string) => {
  return value.split("").reduce((t: Array<number>, e, i) => {
    if (e === "-") {
      t.push(i);
    }
    return t;
  }, []);
};

const RE2 = RegExp

/**
 * Returns the number of dashes in the `value`
 * @param value - The string to look at.
 * ```js
 * const NUM_OF_DASHES - getNumberOfDashes('123-45-6543') //returns 2;
 * ```
 */
const getNumberOfDashes = (value: string) => value.split("").filter((e) => e === "-").length;

/**
 * Returns where the `ref's` `selectionStart` is. If `undefined` returns 0
 * @param ref - The `ref` to look at.
 */
const getSelection = (ref: React.MutableRefObject<HTMLInputElement>) => ref.current.selectionStart || 0;

/**
 * Returns if the value is a 0-9
 * @param value - The value to look at.
 */
const isNumber = (value: string) => new RE2(/[0-9]/).test(value);

/**
 * Returns if the char isn't a digit and has a length === 1
 * @param char - The char to look 
 * @returns 
 */
const isAlpha = (char: string) => char.length === 1 && new RE2(/[^0-9]/).test(char);

/**
 * Returns if the `ref` has a range selected
 * @param ref - The `ref` to look at.
 */
const doesInputHaveSelectionRange = (ref: React.MutableRefObject<HTMLInputElement>) => ref.current.selectionStart !== ref.current.selectionEnd;

/**
 * Used to handle all of the functionality for a numbered input that contains dashes
 * @param props - 
 * @returns 
 */
export const useBaseDashedNumberInput = (props: any) => {
  const { control, form, dispatchControlChanges, placeholder } = props;

  const placeholderRef = getDashes(placeholder);
  const inputRef = useRef(document.createElement('input'));
  const isBackspace = useRef(false);

  /**
   * Returns the new cursor position for the input and the new value to be added to the input.
   * @param value - The input's current value.
   */
  const getSelectionAndValue = (value: string) => {
    const NEW_VALUE = value.replace(/[^0-9]/gi, '').split("");
    const CURRENT_SELECTION = getSelection(inputRef);
    const NUM_OF_DASHES = getNumberOfDashes(value)
    let nextSelection = CURRENT_SELECTION;

    placeholderRef.forEach((e, i) => {
      if (NEW_VALUE.length > e) {
        NEW_VALUE.splice(e, 0, "-");

        if (!isBackspace.current && CURRENT_SELECTION > e) {
          if (NUM_OF_DASHES === i) {
            nextSelection += 1;
          }

          if (NUM_OF_DASHES === i + 1 && CURRENT_SELECTION === e + 1) {
            nextSelection += 1;
          }
        }
      }
    });

    return {
      nextSelection,
      newInputValue: NEW_VALUE.join('')
    }
  }

  /**
   * Handles the `onChange` from the input.
   * @param event - The `onChange` event
   */
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;

    const { nextSelection, newInputValue } = getSelectionAndValue(value);

    inputRef.current.value = newInputValue;
    inputRef.current.setSelectionRange(nextSelection, nextSelection);

    form.setValue(control.name, newInputValue);
    dispatchControlChanges();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //We need to know if a backspace has pressed for the getSelectionAndValue function in the handleOnChange
    isBackspace.current = event.key === 'Backspace';

    //* If the key is alpha and not a command, just ignore it 
    if (isAlpha(event.key) && !event.ctrlKey) {
      event.preventDefault();
    }

    if (isNumber(event.key)) {
      //* If the value of the input is >= placeholder.length and there is no selection, ignore it.
      if (
        inputRef.current.value.length >= placeholder.length &&
        !doesInputHaveSelectionRange(inputRef)
      ) {
        event.preventDefault();
      }
    }
  };

  /**
   * Handles the `onBlur` function from the input.
   */
  const handleOnBlur = () => {
    form.runValidations(control.name);
    dispatchControlChanges();
  }

  return {
    inputRef,
    handleOnChange,
    handleKeyDown,
    handleOnBlur
  }
}