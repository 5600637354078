import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { getUserSMSSettings, updateSmsNotificationSettings } from "../../../slices/userInformation/userInformationSlice"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { updateSMSSettings_API } from "../../../api/User/smsSettings"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"

/**custom use hook for the Notification Settings component found as a tab on the User Profile page */
export const useNotificationSettings = () => {
    useScrollToTop()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const [toggleOne, setToggleOne] = useState<boolean>(userInformation.smsEnrolled === "Yes" ? true : false)
    const dispatch = useAppDispatch()
    const [showModal, setShowModal] = useState<boolean>(false)
    const calledForSMSRef = useRef<boolean>(false)
    const { type, message, setToastMessage, resetToast } = useToastMessage()
    const { windowState } = useWindowState()
    const toggleClickedRef = useRef<boolean>(false)

    /**Handles the toggle click event for text notifications */
    const handleToggleClickOne = async (toggleStatus: any) => {
        if(!toggleClickedRef.current){
            toggleClickedRef.current = true
            try{
                await updateSMSSettings_API(toggleStatus)
                if(windowState === "Mobile"){
                    setShowModal(true)
                }
                setToggleOne(toggleStatus)
                dispatch(updateSmsNotificationSettings(toggleStatus))
            }
            catch{
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
            }
            toggleClickedRef.current = false
        }
    }

    /**on render call to get the users notification settings */
    useEffect(() => {
        if(!calledForSMSRef.current && userInformation.gotSMS === "Loading"){
            calledForSMSRef.current = true
            callForUsersSMSSettings()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation])

    /**make an API call to get the users SMS settings */
    const callForUsersSMSSettings = async () => {
        try{
            const response = await dispatch(getUserSMSSettings())
            setToggleOne(response.payload.subscription_status === "opt_Out" ? false : true)
        }
        catch{
            console.log("UNABLE TO GET THE USERS SMS SETTINGS")
        }
    }

    return { userInformation, handleToggleClickOne, toggleOne, showModal, setShowModal, message, type }
}