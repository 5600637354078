/**Gets the current date and formats as mm/dd/yyyy */
export const getTodaysDate = () :string => {
    const date = new Date().toLocaleDateString()
    let [month, day, year] = date.split("/")
    if(+month < 10){
        month = `0${month}`
    }
    if(+day < 10){
        day = `0${day}`
    }
    return `${year}-${month}-${day}`
}