import { useRef, useState, useEffect } from 'react';
import { useWindowState } from '../../../../Context/AccountContext/useWindowState';
import { setCustomPickerArrayDates } from '../../../../utils/setCustomPickerArrayDates';
import { useTransactionHistoryContext } from '../../context/TransactionHistoryContext';
import { useCustomDateFilterContext } from './context/CustomDateFilterContext';
import { useCalendarRef } from './useCalendarRef';

export const MONTHS = [
  { month: "Jan", days: 31 },
  { month: "Feb", days: 28 },
  { month: "Mar", days: 31 },
  { month: "Apr", days: 30 },
  { month: "May", days: 31 },
  { month: "Jun", days: 30 },
  { month: "Jul", days: 31 },
  { month: "Aug", days: 31 },
  { month: "Sep", days: 30 },
  { month: "Oct", days: 31 },
  { month: "Nov", days: 30 },
  { month: "Dec", days: 31 }
]

/**
 * Pads a date with 0 if the number is a single digit.
 * @param date - The date to pad.
 */
const padDate = (date: number | string) => date.toString().padStart(2, '0');


export const useCustomDatePicker = () => {
  const { windowState } = useWindowState();
  const { setIsDatePickerOpen, setBoxSelection, boxSelection, setLocalFromDate, setLocalToDate } = useCustomDateFilterContext();

  const { dateFilter: { toDate, fromDate, dateRange } } = useTransactionHistoryContext();
  var date = new Date();
  const firstDayOfMonth = useRef<string>(String(new Date(date.getFullYear(), date.getMonth(), 1)));
  const lastDayOfMonth = useRef<string>(String(new Date(date.getFullYear(), date.getMonth() + 1, 0)))
  const lastDayOfPreviousMonth = useRef<string>(String(new Date(date.getFullYear(), date.getMonth(), 0)))
  const initialMonth = useRef<any>(new Date().getMonth())
  const initialYear = useRef<any>(new Date().getFullYear())
  const currentDate = useRef<number>(new Date().getDate())
  const currentMonth = useRef<any>(new Date().getMonth())
  const currentYear = useRef<any>(new Date().getFullYear())
  const [displayMonth, setDisplayMonth] = useState<string>(MONTHS[currentMonth.current].month)
  const [dayArray, setDayArray] = useState<Array<{ day: number, outside: boolean }>>([])
  const calendarRef = useCalendarRef();

  /**On render if we are using mobile then immediately open custom date picker */
  useEffect(() => {
    if (windowState === "Mobile") {
      setIsDatePickerOpen(true)
      setBoxSelection("From")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**Get the first month prepared for display */
  useEffect(() => {
    if (firstDayOfMonth && lastDayOfMonth && lastDayOfPreviousMonth) {
      let tempArray = setCustomPickerArrayDates(firstDayOfMonth.current.split(" ")[0], MONTHS, currentMonth, lastDayOfMonth.current.split(" ")[0], lastDayOfPreviousMonth.current.split(" ")[2])
      setDayArray(tempArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDayOfMonth, lastDayOfMonth, lastDayOfPreviousMonth])

  useEffect(() => {
    if (boxSelection === 'To' && toDate.ref.get().year) {
      setNewSelectedDate(toDate.ref.get().year, toDate.ref.get().month - 1);
    }

    if (boxSelection === 'From' && fromDate.ref.get().year) {
      setNewSelectedDate(fromDate.ref.get().year, fromDate.ref.get().month - 1);
    }
  }, [boxSelection])


  /**
    * Returns if the currently selected day should be enabled and available to be selected.
    * @param day - The day of month that is selected.
    */
  const shouldDayBeEnabled = (day: number) => {
    const NEW_DATE = new Date(`${currentYear.current}-${currentMonth.current + 1}-${padDate(day)}`)
    const FROM_DATE = new Date(`${fromDate.ref.get().year}-${fromDate.ref.get().month}-${padDate(fromDate.ref.get().day)}`)
    const TO_DATE = new Date(`${toDate.ref.get().year}-${toDate.ref.get().month}-${padDate(toDate.ref.get().day)}`)
    if (boxSelection === 'To') {

      if (!fromDate.ref.get().year) {
        return true;
      }

      return NEW_DATE >= FROM_DATE
    }

    if (boxSelection === 'From') {
      if (!toDate.ref.get().year) {
        return true;
      }
      return NEW_DATE <= TO_DATE
    }
  }

  const getAdjustedDate = (day: number) => {
    return {
      month: padDate(currentMonth.current + 1),
      date: padDate(day),
      year: currentYear.current
    }
  }

  /**handles processing when a user clicks a date in the calendar */
  const handleDateClick = (selectedDate: { day: number, outside: boolean }) => {
    if (!shouldDayBeEnabled(selectedDate.day)) {
      return;
    }

    if (boxSelection === "To") {

      const { month, date, year } = getAdjustedDate(selectedDate.day);
      setLocalToDate({ month: +month, day: +date, year: year });
      toDate.ref.set({ month: +month, day: +date, year: year });

      if (windowState !== 'Mobile') {
        setIsDatePickerOpen(false)
      }

    }
    else {
      const { month, date, year } = getAdjustedDate(selectedDate.day);
      fromDate.ref.set({ month: +month, day: +date, year: year });
      setLocalFromDate({ month: +month, day: +date, year: year })


      setBoxSelection("To")
    }

    dateRange.ref.set('Custom date range');

  }

  const setNewSelectedDate = (year: number, month: number) => {
    currentMonth.current = month;
    currentYear.current = year;
    const firstDayOfMonth = String(new Date(currentYear.current, currentMonth.current, 1));
    const lastDayOfMonth = String(new Date(currentYear.current, currentMonth.current + 1, 0))
    const lastDayOfPreviousMonth = String(new Date(currentYear.current, currentMonth.current, 0))
    if (MONTHS[currentMonth.current].month === "Feb") {
      if (currentYear.current % 4 === 0 && currentYear.current % 100 !== 0) {
        MONTHS[1].days = 29
      }
      else {
        MONTHS[1].days = 28
      }
    }
    let tempArray = setCustomPickerArrayDates(firstDayOfMonth.split(" ")[0], MONTHS, currentMonth, lastDayOfMonth.split(" ")[0], lastDayOfPreviousMonth.split(" ")[2])
    setDisplayMonth(MONTHS[month].month)
    setDayArray(tempArray)
  }

  /**handles adjust the calendar when the single left arrow is clicked */
  const handleSingleLeftArrow = () => {
    if (MONTHS[currentMonth.current].month === "Jan") {
      setNewSelectedDate(currentYear.current - 1, MONTHS.length - 1);
    }
    else {
      setNewSelectedDate(currentYear.current, currentMonth.current - 1);
    }
  }

  /**handles adjusting the calendar when the double left arrow is clicked */
  const handleDoubleLeftArrow = () => {
    setNewSelectedDate(currentYear.current - 1, currentMonth.current);
  }

  /**handles adjusting the calendar when the single right arrow is clicked */
  const handleSingleRightArrow = () => {
    if (MONTHS[currentMonth.current].month === "Dec") {
      setNewSelectedDate(currentYear.current + 1, 0);
    }
    else {
      setNewSelectedDate(currentYear.current, currentMonth.current + 1);
    }
  }

  /**handles adjusting the calendar when the double right arrow is clicked */
  const handleDoubleRightArrow = () => {
    setNewSelectedDate(currentYear.current + 1, currentMonth.current);
  }

  /**Check to see if the current date is a selected date */
  const checkForSelectedDate = (day: any) => {
    const FROM_DATE = fromDate.ref.get();
    const TO_DATE = toDate.ref.get();
    if ((FROM_DATE.day === day.day && FROM_DATE.month - 1 === currentMonth.current && FROM_DATE.year === currentYear.current && !day.outside) ||
      (TO_DATE.day === day.day && TO_DATE.month - 1 === currentMonth.current && TO_DATE.year === currentYear.current && !day.outside)) {
      return true
    }
    else {
      return false
    }
  }


  return {
    windowState,
    calendarRef,
    handleDateClick,
    handleDoubleLeftArrow,
    handleDoubleRightArrow,
    handleSingleLeftArrow,
    handleSingleRightArrow,
    shouldDayBeEnabled,
    checkForSelectedDate,
    displayMonth,
    currentYear,
    initialYear,
    initialMonth,
    currentMonth,
    dayArray,
    toDate,
    fromDate,
    currentDate
  }
}