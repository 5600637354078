import { useEffect, useRef } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { deleteExternalAccount_API } from "../../../api/User/LinkExternalAccounts"
import { IExternalAccounts } from "../../../api/User/interfaces"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { removeExternalAccountByIndex } from "../../../slices/userInformation/userInformationSlice"
import { getEventPath } from "../../../utils/getEventPath"

/**a custom use hook holding the logic for the Delete External Account component */
export const useDeleteExternalAccount = (setOpenDeleteModal: any, setShowDetails: any) => {
    const { windowState } = useWindowState()
    const { setToastMessage, resetToast } = useToastMessage()
    const externalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const dispatch = useAppDispatch()
    const deleteClickedRef = useRef<boolean>(false)

    /**handles the  Remove account button onClick event*/
    const handleRemove = async (externalAccount: IExternalAccounts | undefined) => {
        if(externalAccount && !deleteClickedRef.current){
            deleteClickedRef.current = true
            try{
                await deleteExternalAccount_API(externalAccount.id)
                setToastMessage("SUCCESS", "Account removed")
                resetToast()
                const index = externalAccounts.findIndex((account: IExternalAccounts) => account.id === externalAccount.id)
                if(index >= 0){
                    dispatch(removeExternalAccountByIndex(index))
                }
                setOpenDeleteModal(false)
                setShowDetails(false)
                deleteClickedRef.current= false
            }
            catch{
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
                deleteClickedRef.current = false
            }
        }
    }

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-details")) === -1){
                document.body.style.overflow = "auto";
                setOpenDeleteModal(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**lock the scroll bar when the modal is open */
    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {document.body.style.overflow = "auto"}
    }, [])

    return { windowState, handleRemove }
}