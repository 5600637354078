import { useState } from "react"
import { useTransactionHistoryContext } from '../../../Pages/TransactionHistory/context/TransactionHistoryContext';
import { TDateSelection } from '../../../Pages/TransactionHistory/context/TransactionHistory.types';

/**custom use hook for the mobile date range selection of the mobile transaction page filter */
export const useMobileDateRangeSelection = (setDateRangeSelected: React.Dispatch<React.SetStateAction<boolean>>) => {
    const { dateFilter: { dateRange, customDate, toDate, fromDate } } = useTransactionHistoryContext();

    const [chosenDate, setChosenDate] = useState<TDateSelection>(dateRange.ref.get())
    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
    const monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    /**Handles the save buton press for the custom date selector */
    const handleCustomSave = () => {
        if (chosenDate === "Custom date range") {
            const { year: FROM_YEAR, month: FROM_MONTH, day: FROM_DAY } = fromDate.ref.get();
            const { year: TO_YEAR, month: TO_MONTH, day: TO_DAY } = toDate.ref.get();

            customDate.ref.set(`${monthsArray[FROM_MONTH - 1]} ${FROM_DAY}, ${FROM_YEAR} - ${monthsArray[TO_MONTH - 1]} ${TO_DAY}, ${TO_YEAR}`)
        }
        setDateRangeSelected(false)
        dateRange.ref.set(chosenDate);
    }

    /**Clears the custom filter */
    const handleDateSelection = (selection: TDateSelection) => {
        if (selection === "Custom date range") {
            setOpenDatePicker(true)
            setChosenDate(selection)
        }
        else {
            setChosenDate(selection)
            customDate.ref.set('');
        }

        dateRange.ref.set(selection);
    }

    return { chosenDate, setChosenDate, openDatePicker, setOpenDatePicker, handleCustomSave, handleDateSelection }
}