import { useContactInformation } from '../../../useHooks/componentHooks/useContactInformation/useContactInformation'
import './DesktopContactInformation.scss'
import { IDesktopContactInformationProps } from './interfaces/IDesktopContactInformationProps'
import bluePencil from '../../../assets/bluePencil.svg'
import bluePlus from '../../../assets/bluePlus.svg'
import DesktopUserProfileEditAddress from '../../../Pages/UserProfile/components/DesktopUserProfileEditAddress/DesktopUserProfileEditAddress'
import { IAddress, IPhone } from '../../../api/User/interfaces'
import DesktopBasicEdit from '../../../Pages/UserProfile/components/DesktopBasicEdit/DesktopBasicEdit'
import MaxAddressModal from '../../../Pages/UserProfile/components/MaxAddressModal/MaxAddressModal'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { useEffect } from 'react'
import { useUserProfileContext } from '../../../Pages/UserProfile/Context/useUserProfileContext'

/**
 * Renders the desktop version of the contact information tab on the user profile page
*/
const DesktopContactInformation = (props: IDesktopContactInformationProps) => {
    const { openEditPage, setOpenEditPage, addressAction, setAddressAction } = props
    const { userInformation, arrayIndex, setArrayIndex, showMaxAddressModal, handleAddNewAddress, setShowMaxAddressModal,
        handleEditClick, message, type } = useContactInformation(setOpenEditPage, addressAction, setAddressAction)
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)
    const { setUsingSuggestion } = useUserProfileContext()

    /**
     * Sets the state controling whether or not to show the suggestion address box or the manual address entry.
     * Edit or EditMain means editing an exisiting address and should start with manual mode.
     * Add means adding a new address and should start in suggestion mode.
     */
    useEffect(() => {
        if(addressAction === "Edit" || addressAction === "EditMain"){
            setUsingSuggestion(false)
        }
        else{
            setUsingSuggestion(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressAction])

    return (
        <>
            {openEditPage === "" &&
                <>
                    {message !== "" && <ToastMessageDB type={type} message={message} />}
                    <div className="user-contact-page-wrap">
                        <div className="contact-information-wrapper">
                            {showMaxAddressModal &&
                                <MaxAddressModal
                                    setShowMaxAddressModal={setShowMaxAddressModal}
                                />
                            }
                            {gotUserContactInfo !== "Loading" ?
                                <div className="bottom-card">
                                    <div className="email-section">
                                        <div className="left-side">
                                            <div className="left">
                                                <span className="email-address boldRegular">Email address</span>
                                            </div>
                                            <div className="right">
                                                <span className="email regular cls_mask">{userInformation.contactInformation.emails[0]?.emailAddress}</span>
                                            </div>
                                        </div>
                                        <div className="right-side">
                                            <img className="edit-pencil" src={bluePencil} alt="edit email" onClick={() => handleEditClick("Email")} />
                                            <span data-testid="edit-email-text" className="edit-text regular" onClick={() => handleEditClick("Email")} >Edit</span>
                                        </div>
                                    </div>
                                    <div className="phone-section">
                                        <div className="left-side">
                                            <div className="left">
                                                <span className="phone-number boldRegular">Phone number</span>
                                                <span className="phone-message small">We’ll text notifications to your mobile number if you’ve opted in.</span>
                                            </div>
                                        </div>
                                        <div className="column">
                                            {userInformation.contactInformation.phones.map((phone: IPhone, index: number) => {
                                                return (
                                                    <div key={index} className="row-wrapper">
                                                        <div className="column-wrapper">
                                                            {phone.type === "Mobile" ?
                                                                <span className="mobile-text boldSmall">{phone.type} number</span>
                                                                :
                                                                <span className="mobile-text boldSmall">{phone.type} number {index}</span>
                                                            }
                                                            <span className="mobile-number regular cls_mask">{phone.number}</span>
                                                            {index === userInformation.contactInformation.phones.length - 1 &&
                                                                <div className="add-number">
                                                                    <img data-testid="add-phone" className="blue-plus-icon" src={bluePlus} alt="blue plus sign" onClick={() => handleEditClick("Add")} />
                                                                    <span className="add-text small" onClick={() => handleEditClick("Add")}>Add alternate number</span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="right-side">
                                                            <img className="edit-pencil" src={bluePencil} alt="edit phone number" onClick={() => { handleEditClick(phone.type); setArrayIndex(index) }} />
                                                            {phone.type === "Mobile" ?
                                                                <span data-testid="edit-primary-phone" className="edit-text regular" onClick={() => { handleEditClick("Mobile"); setArrayIndex(index) }}>Edit</span>
                                                                :
                                                                <span data-testid="edit-remove-phone" className="edit-text regular" onClick={() => { handleEditClick("Alternate"); setArrayIndex(index) }}>Edit / remove</span>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="address-section">
                                        <div className="left-side">
                                            <div className="top-row">
                                                <span className="addresses-text boldRegular">Addresses</span>
                                                <div className="image-wrapper">
                                                    {/* <img className="information-image" src={greyInfo} alt="tool tip" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} />
                                            {showTooltip &&
                                                <Tooltip anchorPoint={"top-left"} width="250px" headerMessage="Residential/Mailing Address" bodyMessage="All of your automated banking correspondence, such as tax documents, will be sent to your mailing address."/>
                                            } */}
                                                </div>
                                            </div>
                                            <span className="address-info-text small">We are required by law to obtain your home street address. You can add a separate mailing address if you wish.</span>
                                        </div>
                                        <div className="right-side">
                                            {userInformation.contactInformation.addresses.map((address: IAddress, index: number) => {
                                                return (
                                                    <div key={index} className="addresses">
                                                        {index === 0 ?
                                                            <div className="address">
                                                                <span className="type-text boldSmall">Home street address</span>
                                                                <span className="street-text regular cls_mask">{address.addressLine1}</span>
                                                                <span className="street-text regular cls_mask">{address.addressLine2}</span>
                                                                <span className="city-state-zip regular cls_mask">{address.city}, {address.state} {" "} {address.zipCode}</span>
                                                                {address.isMailing &&
                                                                    <div className="pill-wrapper">
                                                                        <span className="mailing-pill mobile-h6">Mailing</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="address">
                                                                <span className="type-text boldSmall">Address {index}</span>
                                                                <span className="street-text regular cls_mask">{address.addressLine1}</span>
                                                                <span className="street-text regular cls_mask">{address.addressLine2}</span>
                                                                <span className="city-state-zip regular cls_mask">{address.city}, {address.state} {" "} {address.zipCode}</span>
                                                                {address.isMailing &&
                                                                    <div className="pill-wrapper">
                                                                        <span className="mailing-pill mobile-h6">Mailing</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="right">
                                                            <img className="edit-pencil" src={bluePencil} alt="edit address" onClick={() => { handleEditClick("Addresses"); setArrayIndex(index); setAddressAction(index === 0 ? "EditMain" : "Edit") }} />
                                                            {index === 0 ?
                                                                <span data-testid="edit-primary-address" className="edit-text regular" onClick={() => { handleEditClick("Addresses"); setArrayIndex(index); setAddressAction("EditMain") }}>Edit</span>
                                                                :
                                                                <span data-testid="edit-alternate-address" className="edit-text regular" onClick={() => { handleEditClick("Addresses"); setArrayIndex(index); setAddressAction("Edit") }}>Edit / remove</span>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="add-address">
                                                <img src={bluePlus} alt="blue plus sign" onClick={() => { handleAddNewAddress() }} />
                                                <span className="add-mailing-text small" onClick={() => { handleAddNewAddress() }}>Add mailing address</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="loading-box" />
                            }
                        </div>
                    </div>
                </>
            }
            <>
                {openEditPage !== "Addresses" && openEditPage !== "" &&
                    <DesktopBasicEdit
                        openEditPage={openEditPage}
                        setOpenEditPage={setOpenEditPage}
                        index={arrayIndex}
                    />
                }
                {openEditPage === "Addresses" &&
                    <DesktopUserProfileEditAddress
                        setOpenEditPage={setOpenEditPage}
                        index={arrayIndex}
                        addressAction={addressAction}
                        setAddressAction={setAddressAction}
                    />
                }
            </>
        </>
    )
}

export default DesktopContactInformation