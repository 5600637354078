import { ITransactionHistoryData } from "../api/getTransactionHistory"

/**The utitlity function is used ot sort transaction in ascending or descending order */
export const sortTransactions = (column: string, direction: string, filteredTransactions: Array<ITransactionHistoryData>, setDateSort: any, setAmountSort: any) => {
    let tempTransactionArray = JSON.parse(JSON.stringify(filteredTransactions))
    switch(column){
      case "Date": {
        if(direction === "Up"){
          setDateSort("Up")
          setAmountSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransactionHistoryData, b:ITransactionHistoryData) => {
            if(a.effectiveDate > b.effectiveDate){
              return -1
            }
            if(a.effectiveDate < b.effectiveDate){
              return 1
            }
            return 0
          })
          // a.effectiveDate > b.effectiveDate ? -1 : 1)
        }
        else if(direction === "Down"){
          setDateSort("Down")
          setAmountSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransactionHistoryData, b:ITransactionHistoryData) => {
            if(a.effectiveDate > b.effectiveDate){
              return 1
            }
            if(a.effectiveDate < b.effectiveDate){
              return -1
            }
            return 0
          })
          // a.effectiveDate > b.effectiveDate ? 1 : -1)
        }
        break
      }
      case "Amount": {
        if(direction === "Up"){
          setAmountSort("Up")
          setDateSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransactionHistoryData, b:ITransactionHistoryData) => {
            if(+a.amount > +b.amount){
              return -1
            }
            if(+a.amount < +b.amount){
              return 1
            }
            return 0
          })
          // a.amount > b.amount ? -1 : 1)
        }
        else if(direction === "Down"){
          setAmountSort("Down")
          setDateSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransactionHistoryData, b:ITransactionHistoryData) => {
            if(+a.amount > +b.amount){
              return 1
            }
            if(+a.amount < +b.amount){
              return -1
            }
            return 0
          })
          // a.amount > b.amount ? 1 : -1)
        }
        break
      }
    }
    return tempTransactionArray
}