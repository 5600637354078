import { useEffect } from 'react'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import CreateTransferLanding from '../components/CreateTransferLanding/CreateTransferLanding'
import TransferConfirmation from '../components/TransferConfirmation/TransferConfirmation'
import DesktopTransferHistory from '../components/TransferHistory/DesktopTransferHistory/DesktopTransferHistory'
import TransferReview from '../components/TransferReview/TransferReview'
import { ITransfersProps } from '../interfaces/ITransfersProps'
import './DesktopTransfers.scss'
import warning from '../../../assets/warning.svg'

/**Renders the desktop version of the transfers landing page */
const DesktopTransfers = (props: ITransfersProps) => {
    const { selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions,
        transferAmount, setTransferAmount, transferDate, setTransferDate, topCardIndex, setTopCardIndex, transferHistory, setTransferHistory,
        selectedTransfer, setSelectedTransfer, scheduledTransfers, setScheduledTransfers, totalTransfers, handleClose, selectedTransferRef,
        gotTransferHistory, gotScheduledTransfers, transferErrored, setTransferErrored } = props
    const { message, resetToast } = useToastMessage()

    /**clear the toast message if necessary */
    useEffect(() => {
        if (message !== "") {
            resetToast()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return (
        <div className="desktop-transfers-landing">

            <div className="header-wrap">
                <p className="header-text h4">Transfers</p>
            </div>
            <div className="card-wraps">
                <TransferCards
                    topCardIndex={topCardIndex}
                    selectedFromAccount={selectedFromAccount}
                    setSelectedFromAccount={setSelectedFromAccount}
                    selectedToAccount={selectedToAccount}
                    setSelectedToAccount={setSelectedToAccount}
                    selectionOptions={selectionOptions}
                    transferAmount={transferAmount}
                    setTransferAmount={setTransferAmount}
                    setTransferDate={setTransferDate}
                    setTopCardIndex={setTopCardIndex}
                    transferDate={transferDate}
                    scheduledTransfers={scheduledTransfers}
                    setScheduledTransfers={setScheduledTransfers}
                />


                {gotScheduledTransfers === 'Loading' && <div className="loading-box" />}

                {gotScheduledTransfers === 'Success' &&
                    <>
                        {scheduledTransfers.length > 0 &&
                            <div className="transfers-card" id="1">
                                <DesktopTransferHistory
                                    transferHistory={scheduledTransfers}
                                    setTransferHistory={setScheduledTransfers}
                                    selectedTransfer={selectedTransfer}
                                    setSelectedTransfer={setSelectedTransfer}
                                    title="Scheduled transfers"
                                    totalTransfers={totalTransfers}
                                    handleClose={handleClose}
                                    selectedTransferRef={selectedTransferRef}
                                    key={1}
                                    transferErrored={transferErrored}
                                    setTransferErrored={setTransferErrored}
                                />
                            </div>
                        }

                    </>

                }
                {gotScheduledTransfers === 'Failed' && <TransferHistoryErrorCard name="Scheduled transfers" />}







                {gotTransferHistory === 'Loading' && <div className="loading-box" />}
                {gotTransferHistory === "Success" &&

                    <div className="transfers-card" id="2">
                        <DesktopTransferHistory
                            transferHistory={transferHistory}
                            setTransferHistory={setTransferHistory}
                            selectedTransfer={selectedTransfer}
                            setSelectedTransfer={setSelectedTransfer}
                            title="Transfer history"
                            totalTransfers={totalTransfers}
                            handleClose={handleClose}
                            selectedTransferRef={selectedTransferRef}
                            key={2}
                        />
                    </div>
                }
                {gotTransferHistory === "Failed" && <TransferHistoryErrorCard name="Transfer History" />}
            </div >
        </div >
    )
}

export default DesktopTransfers

export const TransferHistoryErrorCard = (props: any) => {
    const { name } = props;
    return <div className="transfers-card"><div className="transfer-error-wrapper">
        <span className="title-text h2">{name}</span>
        <div className="error-stack">
            <img className="erorr-image" src={warning} alt="unable to load data" />
            <span className="header-text h4">Unable to load data</span>
            <span className="sub-text regular">Please wait a moment and try again.</span>
        </div>
    </div>
    </div>
}


export const TransferCards = (props: any) => {

    const { topCardIndex,
        selectedFromAccount,
        setSelectedFromAccount,
        selectedToAccount,
        setSelectedToAccount,
        selectionOptions,
        transferAmount,
        setTransferAmount,
        setTransferDate,
        setTopCardIndex,
        transferDate,
        scheduledTransfers,
        setScheduledTransfers
    } = props
    return <div className="transfers-card" >
        {topCardIndex === 0 &&
            <CreateTransferLanding
                selectedFromAccount={selectedFromAccount}
                setSelectedFromAccount={setSelectedFromAccount}
                selectedToAccount={selectedToAccount}
                setSelectedToAccount={setSelectedToAccount}
                selectionOptions={selectionOptions}
                transferAmount={transferAmount}
                setTransferAmount={setTransferAmount}
                transferDate={transferDate}
                setTransferDate={setTransferDate}
                setTopCardIndex={setTopCardIndex}
                topCardIndex={topCardIndex}
                fromDetails={false}
            />
        }
        {topCardIndex === 1 &&
            <TransferReview
                selectedFromAccount={selectedFromAccount}
                selectedToAccount={selectedToAccount}
                transferAmount={transferAmount}
                transferDate={transferDate}
                topCardIndex={topCardIndex}
                setTopCardIndex={setTopCardIndex}
                scheduledTransfers={scheduledTransfers}
                setScheduledTransfers={setScheduledTransfers}
            />
        }
        {topCardIndex === 2 &&
            <TransferConfirmation
                selectedFromAccount={selectedFromAccount}
                setSelectedFromAccount={setSelectedFromAccount}
                selectedToAccount={selectedToAccount}
                setSelectedToAccount={setSelectedToAccount}
                transferAmount={transferAmount}
                setTransferAmount={setTransferAmount}
                transferDate={transferDate}
                setTransferDate={setTransferDate}
                setTopCardIndex={setTopCardIndex}
                scheduledTransfers={scheduledTransfers}
            />
        }
    </div>

}