// import { ILinkedExternalAccounts } from '../../../../api/User/interfaces'
import { useManageExternalAccounts } from '../../../../useHooks/componentHooks/useMangeExternalAccounts/useManageExternalAccounts'
import DesktopProfileHeader from '../DesktopProfileHeader/DesktopProfileHeader'
import './DesktopManageExternalAccounts.scss'
import horizontalDots from '../../../../assets/horizontalDots.svg'
import bluePlus from '../../../../assets/bluePlus.svg'
import { IManageExternalAccountsProps } from './interfaces/IManageExternalAccountsProps'
import blueEditPencil from '../../../../assets/blueEditPencil.svg'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import arrowLeft from '../../../../assets/arrowLeft.svg'
import DeleteExternalAccount from '../DeleteExternalAccount/DeleteExternalAccount'
import { IExternalAccounts } from '../../../../api/User/interfaces'
import MaxAccountsModal from '../MaxAccountsModal/MaxAccountsModal'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import warning from '../../../../assets/warning.svg'
import emptyTransactions from '../../../../assets/emptyTransactions.svg'
import { useHistory } from 'react-router-dom'
import { selectedLinkAccount } from '../../../../slices/userInformation/userInformationSlice'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'
import { checkExternalAccounts } from '../../../../utils/populateUserDetails'
import informationIconLight from '../../../../assets/informationIconLight.svg'
import Tooltip from '../../../../components/Tooltip/Tooltip'


/**
 * Renders the desktop version of the Manage External Accounts component found as a tab on the User Profile page
 */
const DesktopManageExternalAccounts = (props: IManageExternalAccountsProps) => {
    const history = useHistory()
    const { currentTab, setCurrentTab, availableTabs } = props
    const { userInformation, openMenu, setOpenMenu, selectedExternalAccount, setSelectedExternalAccount, openEditName, setOpenEditName,
        openDeleteModal, setOpenDeleteModal, handleNickname, enableSave, newNickname, setNewNickname, handleSave, handleLinkExternalAccount,
        showLimitModal, setShowLimitModal, handleEditClick, type, message, dispatch, setShowDetails, showTooltip, setShowTooltip, tooltipIndexRef } = useManageExternalAccounts()
    const gotExternalAccounts = useAppSelector((state: RootState) => state.userInformation.gotExternalAccounts)

    return (
        <div className="desktop-manage-external-accounts">
            {message !== "" && <ToastMessageDB message={message} type={type} />}
            {!openEditName ?
                <DesktopProfileHeader
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    availableTabs={availableTabs}
                />
                :
                <div className="header-wrap">
                    <div className="nav-header">
                        <img className="image" src={arrowLeft} alt="left facing chevron" onClick={() => setOpenEditName(false)} />
                        <span className="nav-text regular" onClick={() => setOpenEditName(false)}>Manage external accounts</span>
                    </div>
                </div>
            }
            {gotExternalAccounts !== "Loading" ?
                <div className="card-page-wrap">
                    <div className="top-card">
                        {gotExternalAccounts === "Success" ?
                            <>
                                {!openEditName ?
                                    <>
                                        {userInformation.externalAccounts.length > 0 ?
                                            <>
                                                <div className="header-row">
                                                    <span className="bank-column semibold">Account</span>
                                                    <span className="routing-column semibold">Routing number</span>
                                                    <span className="status-column semibold">Status</span>
                                                </div>
                                                {userInformation.externalAccounts.map((account: IExternalAccounts, index: number) => {
                                                    return (
                                                        <div key={account.id}>
                                                            <div className="row-wrapper">
                                                                <div className="bank-column">
                                                                    <span className="nickname-text regular">{account.accountNickName}</span>
                                                                    <span className="column-text small">{account.description} ****{account.lastFour}</span>
                                                                </div>
                                                                <div className="routing-column">
                                                                    <span className="routing-column-data regular">{account.routingNumber}</span>
                                                                </div>
                                                                <div className="status-column">
                                                                    {account.status === "Verify now" ?
                                                                        <span className="pill-wrap verify smallBoldButton" onClick={() => { dispatch(selectedLinkAccount(account)); history.push(`/${account.id}/verifyexternalaccount`) }}>{account.status}</span>
                                                                        :
                                                                        <div className="status-wrap">
                                                                            <span className={account.status === "Linked" ? "pill-wrap mobile-h6" : "pill-wrap processing mobile-h6"}>{account.status}</span>
                                                                            {account.status === "Processing" &&
                                                                            <>
                                                                                <img src={informationIconLight} alt="tooltip" onMouseOver={() => {setShowTooltip(true); tooltipIndexRef.current = index}} onMouseOut={() => {setShowTooltip(false); tooltipIndexRef.current = null}} />
                                                                                {(showTooltip && tooltipIndexRef.current === index) &&
                                                                                    <div className="tooltip-wrapper">
                                                                                        <Tooltip 
                                                                                            anchorPoint='top-right'
                                                                                            headerMessage='What’s next?'
                                                                                            bodyMessage='We need to do a quick review of your account before it can be linked. This typically takes up to 1-2 business days.'
                                                                                            width= "240px"
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="elipse-wrap">
                                                                    <img className="elipses" src={horizontalDots} alt="menu" onClick={() => { setOpenMenu(true); setSelectedExternalAccount(account) }} />
                                                                    {(openMenu && account.id === selectedExternalAccount?.id) &&
                                                                        <div className="menu-wrap">
                                                                            <div className="menu-row-wrap" onClick={() => { setOpenMenu(false); setNewNickname(account.accountNickName); handleEditClick("Nickname") }}>
                                                                                <img src={blueEditPencil} alt="blue pencil" />
                                                                                <span className="blue-text regular">Edit nickname</span>
                                                                            </div>
                                                                            {((checkExternalAccounts(userInformation.externalAccounts) || selectedExternalAccount.status === "Verify now") && selectedExternalAccount.status !== "Processing") ? <>
                                                                                <div className="border" />
                                                                                <div className="menu-row-wrap" onClick={() => { setOpenMenu(false); handleEditClick("Remove") }}>
                                                                                    <img src={redTrashCan} alt="red trashcan" />
                                                                                    <span className="red-text regular">Remove account</span>
                                                                                </div>
                                                                            </>
                                                                                : null}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="border-line" />
                                                        </div>
                                                    )
                                                })}
                                            </>
                                            :
                                            <div className="message-wrapper">
                                                <img src={emptyTransactions} alt="empty account list" />
                                                <span className="message-title h4">No external accounts</span>
                                            </div>
                                        }
                                        <div className="link-new-account" >
                                            <img className="add-symbol" src={bluePlus} alt="add account" onClick={() => handleLinkExternalAccount()} />
                                            <span className="add-text regular" onClick={() => handleLinkExternalAccount()}>Link external account</span>
                                        </div>
                                        {showLimitModal &&
                                            <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
                                        }
                                    </>
                                    :
                                    <>
                                        <span className="header-text h2">Edit account nickname</span>
                                        <div className="edit-border" />
                                        <label className="label-wrap">Nickname
                                            <input
                                                className="input-box regular"
                                                type="text"
                                                onChange={(e: any) => handleNickname(e)}
                                                value={newNickname}
                                                minLength={1}
                                                maxLength={40}
                                            />
                                        </label>
                                        <div className="edit-border" />
                                        <div className="button-wrap">
                                            <button className="Button btn-secondary" onClick={() => setOpenEditName(false)}>Cancel</button>
                                            <Button 
                                                title="Save"
                                                isDisabled={!enableSave}
                                                className={enableSave ? "Button btn-primary" : "Button btn-primary inactive"}
                                                id={TIMER_IDS.NICKNAME_EXTERNAL}
                                                onClick={() => handleSave()}
                                            />
                                        </div>
                                    </>
                                }
                            </>
                            :
                            <div className="message-wrapper">
                                <img className="message-image" src={warning} alt="could not retrieve data" />
                                <span className="message-title h4" >Unable to load data</span>
                                <span className="message-text regular">Please wait a moment and try again.</span>
                            </div>
                        }
                    </div >
                </div >
                :
                <div className="loading-wrapper">
                    <div className="loading-box" />
                </div>
            }
            {openDeleteModal &&
                <DeleteExternalAccount
                    setOpenDeleteModal={setOpenDeleteModal}
                    selectedExternalAccount={selectedExternalAccount}
                    setSelectedExternalAccount={setSelectedExternalAccount}
                    setShowDetails={setShowDetails}
                />
            }
        </div >
    )
}

export default DesktopManageExternalAccounts