import axios, { AxiosResponse } from 'axios';
import { bffBaseUrl } from '../utils/globalVariables';
import { IGetYodleeVerifiedAccountsHeaders, IVerifiedAccount } from './getYodleeVerifiedAccounts';
import { LINKING_STATUS } from './models/verified-accounts';
import { IEDPErrorInterface, edpErrorReporting_API } from './edpErrorReporting';

export enum YodleeCreationStatus {
  CREATED = 'Verified',
  FAILED = 'FAILED',
  NOT_CREATED = "Pending_Review",
  PENDING_VERIFICATION = "Pending",
  FRAUD = "Fraud"
}

export interface ICreateYodleeAccountsBody {
  providerAccountId: string;
  accounts: Array<IVerifiedAccount>
  // accounts: Array<IVerifiedAccount['externalAccountId']>;
}

export interface ICreateYodleeAccount {
  externalAccountId: IVerifiedAccount['externalAccountId'];
  bankName: IVerifiedAccount['bankName'];
  accountNumberLastFourDigit: IVerifiedAccount['accountNumberLastFourDigit'];
  status: YodleeCreationStatus
}

/**
 * `POST` to create new external accounts from Yodlee
 * @param body - All of the accounts to be created
 * @param headers - The `factorId` for MFA
 * @returns 
 */
export const postCreateYodleeAccount_API = async (body: ICreateYodleeAccountsBody, headers: IGetYodleeVerifiedAccountsHeaders) => {
  const responseArray: Array<ICreateYodleeAccount> = []
  let RESPONSE: any
  for (let i = 0; i < body.accounts.length; i = i + 1) {
    try {
      RESPONSE = await axios.post(`${bffBaseUrl}/customers/externalaccounts`,
      {
        accountType: body.accounts[i].accountType.toLowerCase() === "checking" ? "DDA" : "SDA",
        routingNumber: body.accounts[i].routingNumber,
        accountNumber: body.accounts[i].accountNumberLastFourDigit,
        channel: "Web",
        accountTitle: body.accounts[i].bankName,
        acctNickName: body.accounts[i].accountNickName,
        externalAccountId: body.accounts[i].externalAccountId,
        status: body.accounts[i].status,
        verificationMethod: "Yodlee"
      },
      { headers: headers }
      )
      responseArray.push(RESPONSE.data)
    }
    catch (err: any) {
      let response = {
        bankName: body.accounts[i].bankName,
        accountNumberLastFourDigit: body.accounts[i].accountNumberLastFourDigit,
        accountType: body.accounts[i].accountType.toLowerCase() === "checking" ? "DDA" : "SDA",
        routingNumber: body.accounts[i].routingNumber,
        accountTitle: body.accounts[i].bankName,
        acctNickName: body.accounts[i].accountNickName,
        externalAccountId: body.accounts[i].externalAccountId,
        status: YodleeCreationStatus.FAILED,
        channel: "Web",
        verificationMethod: "Yodlee"
      }
      responseArray.push(response)
      console.log("ERROR POSTING THE NEW ACCOUNT")
      let errorReturn: any = err.response ? JSON.parse(JSON.stringify(err.response)) : ""
      let edpErrorObject: IEDPErrorInterface = {
          category: "External_Account",
          errorType: "API_error",
          errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""},{message: "EXTERNAL ACCOUNT LIMIT"}],
          timeStamp: new Date().toISOString(),
          additionalDetails: {
            externalAccounts: [{
              acctNo: body.accounts[i].accountNumberLastFourDigit,
              acctId: body.accounts[i].externalAccountId,
              routingNo: body.accounts[i].routingNumber,
              bankName: body.accounts[i].accountNickName !== "" ? body.accounts[i].accountNickName : body.accounts[i].bankName || "",
              errorCode: errorReturn.status || "",
              verificationMethod: "YODLEE"
            }]    
          }
      }
      try{
          edpErrorReporting_API(edpErrorObject)
      }
      catch{}
    }
  }
  const transformedResponse = transformResponseData(responseArray)
  return Promise.resolve(transformedResponse)
}

/**
 * Transforms the incoming data from the `postCreateYodleeAccount_API` function
 * @param data - The data from the response
 */
const transformResponseData = (data: Array<any>) => {
  const ACCOUNTS: any = []

  for (let i = 0; i < data.length; i++) {
    const CURR = data[i];

    ACCOUNTS.push({
      externalAccountId: CURR.externalAccountId,
      bankName: CURR.bankAccountName || CURR.bankName,
      accountNumberLastFourDigit: CURR.accountNumber || CURR.accountNumberLastFourDigit,
      status: CURR.status
    })
  }


  return ACCOUNTS;
}