import { useHistory } from "react-router-dom"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useAccountSettings } from "../../useHooks/pageHooks/useAccountSettings"
import DesktopAccountSettings from "./DesktopAccountSettings/DesktopAccountSettings"
import MobileAccountSettings from "./MobileAccountSettings/MobileAccountSettings"

/**
 * This is the wrapper component for the account settings page.  It determines whether to display
 * the desktop or mobile version
 */
const AccountSettingsWrapper = () => {
    const { windowState, selectedAccount } = useAccountSettings()

    const history = useHistory();

    if(selectedAccount.accountClosed || selectedAccount.pendingGraceClosure) {
        history.push(`/accounts/${selectedAccount.id}`);
    }

    return(
        <>
            {windowState !== "Mobile" ? 
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopAccountSettings />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopAccountSettings />
                    </HybridLayout>
                }
                </>
                :
                <MobileAccountSettings />
            }
        </>
    )
}

export default AccountSettingsWrapper