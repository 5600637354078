import './DesktopOverview.scss'
import AccountOverviewTop from './components/AccountOverviewTop/AccountOverviewTop'
import OverviewMiddle from './components/OverviewMiddle/OverviewMiddle'
import { IDesktopOverviewProps } from './interfaces/IDesktopOverviewProps'

/**
 * Renders the desktop overview component.  It is broken into three parts, Overview Top,
 * Middle, and DropDown which are also found in this components folder.  
 */
const DesktopOverview = (props: IDesktopOverviewProps) => {
    const { selectedAccount, showDropDown, setShowDropDown, initialClick, setInitialClick, show, setShow } = props

    return(
        <div className="desktop-overview-wrapper">
            <AccountOverviewTop selectedAccount={selectedAccount} />
            <OverviewMiddle 
                selectedAccount={selectedAccount}
                showDropDown={showDropDown}
                setShowDropDown={setShowDropDown}
                initialClick={initialClick}
                setInitialClick={setInitialClick}
                show={show}
                setShow={setShow}
            />
        </div>
    )
}

export default DesktopOverview