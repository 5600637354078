import { useGraceConfirmTermChange } from '../../../../useHooks/componentHooks/useGraceConfirmTermChange/useGraceConfirmTermChange'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import './GraceConfirmTermChange.scss'
import { IGraceConfirmChangeProps } from './interfaces/IGraceConfirmChangeProps'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { displayCurrency } from '../../../../utils/displayCurrency'
import chartArrowRight from '../../../../assets/chartArrowRight.svg'
import chartArrowDown from '../../../../assets/chartArrowDown.svg'
import accountBadge from '../../../../assets/accountBadge.svg'


/**Renders the confirm term cd term change found in the grace period flow */
const GraceConfirmTermChange = (props: IGraceConfirmChangeProps) => {
    const { windowState } = useWindowState()
    const { chosenTerm, setChosenTerm, setShowTermChange, setChangeCD, fundTransfer, setFundTransfer, transferType, closeAccount, setCloseAccount, transferAccount } = props
    const { handleConfirmUpdate, selectedAccount, handleBack, handleOnCancel } = useGraceConfirmTermChange(fundTransfer, setFundTransfer, closeAccount, setCloseAccount, transferAccount, setChosenTerm, transferType)

    return (
        <>
            {windowState === "Mobile" &&
                <div className="mobile-confirm-header-wrap">
                    <div className="left-side" onClick={() => { setShowTermChange(false); handleBack(); if (!fundTransfer && !closeAccount) { setChangeCD(true) } }}>
                        <img src={arrowLeftBlue} alt="go back" />
                        <span className="back-text regular">Back</span>
                    </div>
                    <span className="cancel-text regular" onClick={() => { setShowTermChange(false); setFundTransfer(false); setChangeCD(false); setCloseAccount(false); handleOnCancel() }}>Cancel</span>
                </div>
            }
            <div className={windowState !== "Mobile" ? "grace-confirm-term-wrap" : "grace-confirm-term-wrap mobile"}>
                {!closeAccount ?
                    <div className="header-wrap">
                        <span className="header-text small">{!fundTransfer ? "Change term" : "Add or withdraw funds"}</span>
                        <span className={windowState !== "Mobile" ? "sub-header-text h3" : "sub-hedaer-text h4"}>{!fundTransfer ? "Review & confirm changes" : "Review & confirm transfer"}</span>
                    </div>
                    :
                    <div className="header-wrap">
                        <span className="header-text small">Close your CD</span>
                        <span className={windowState !== "Mobile" ? "sub-header-text h3" : "sub-hedaer-text h4"}>Review & confirm closure</span>
                    </div>
                }
                <div className={`details-card ${windowState}`}>
                    {!closeAccount ?
                        <span className={windowState !== "Mobile" ? "title-text h4" : "title-text boldLarge"}>{!fundTransfer ? "Term change" : transferType === "Add" ? "Add funds" : "Withdraw funds"}</span>
                        :
                        <span className={windowState !== "Mobile" ? "title-text h4" : "title-text boldLarge"}>Close your CD</span>
                    }
                    <div className="options-wrap">
                        {!fundTransfer &&
                            <div className="info-wrap">
                                <span className="option boldSmall">Date effective</span>
                                {/* <span className="value regular">{convertDateTommddyyyy(new Date(selectedAccount.graceStartDate))}</span> */}
                                <span className="value regular">{convertDateTommddyyyy(new Date(selectedAccount.graceStartDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
                            </div>
                        }
                        {!closeAccount ?
                            <>
                                {fundTransfer ?
                                    <>
                                        <div className={`fund-transfer-account-wrap ${windowState}`}>
                                            <div className={`account-card ${windowState}`}>
                                                <span className="card-title boldSmall">Transfer from</span>
                                                <div className="info-card">
                                                    <img src={accountBadge} alt="" />
                                                    <div className="info-stack">
                                                        <span className="account-title regular cls_mask">{transferType === "Add" ? `${transferAccount.title}` : `${selectedAccount.description}`}</span>
                                                        <span className="account-values small cls_mask">{transferType === "Add" ? `${transferAccount.accountType} ${transferAccount.lastFour}` : `${selectedAccount.accountType} ${selectedAccount.accountNumber}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {windowState !== "Mobile" ?
                                                <img src={chartArrowRight} alt="right facing arrow" />
                                                :
                                                <img src={chartArrowDown} alt="downward facing arrow" />
                                            }
                                            <div className={`account-card ${windowState}`}>
                                                <span className="card-title boldSmall">Transfer to</span>
                                                <div className="info-card">
                                                    <img src={accountBadge} alt="" />
                                                    <div className="info-stack">
                                                        <span className="account-title regular cls_mask">{transferType === "Add" ? `${selectedAccount.description}` : `${transferAccount.title}`}</span>
                                                        <span className="account-values small cls_mask">{transferType === "Add" ? `${selectedAccount.accountType} ${selectedAccount.accountNumber}` : `${transferAccount.accountType} ${transferAccount.lastFour}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-wrap gap">
                                            <span className="option boldSmall">Amount</span>
                                            <span className="value regular cls_mask">{displayCurrency(selectedAccount.gracePeriodFundTransfer.transferAmount as number)}</span>
                                        </div>
                                        <div className="info-wrap">
                                            <span className="option boldSmall">Transfer date</span>
                                            <span className="value regular cls_mask">{convertDateTommddyyyy(new Date().toLocaleDateString())}</span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="info-wrap">
                                            <span className="option boldSmall">New term</span>
                                            <span className="value regular cls_mask">{chosenTerm.group} {chosenTerm.name?.toLowerCase()}</span>
                                        </div>
                                        <div className="info-wrap">
                                            <span className="option boldSmall">New APY</span>
                                            <span className="value regular cls_mask">{chosenTerm.apy?.substring(0, 4)}%</span>
                                        </div>
                                    </>
                                }
                            </>
                            :
                            <div className="info-wrap">
                                <span className="option boldSmall">Transfer balance to</span>
                                <span className="value regular cls_mask">{transferAccount.title} {transferAccount.lastFour}</span>
                            </div>
                        }
                    </div>
                </div>
                {windowState !== "Mobile" && <div className="border-line" />}
                {windowState !== "Mobile" ?
                    <div className="button-wrapper">
                        <div className="left-side" onClick={() => { setShowTermChange(false); handleBack(); if (!fundTransfer && !closeAccount) { setChangeCD(true) } }}>
                            <img src={arrowLeftBlue} alt="go back" />
                            <span className="back-button regular">Back</span>
                        </div>
                        <div className="right-side">
                            <button className="Button btn-secondary" onClick={() => { setShowTermChange(false); setFundTransfer(false); setChangeCD(false); setCloseAccount(false); handleOnCancel() }}>Cancel</button>
                            <button className="Button btn-primary" onClick={() => { handleConfirmUpdate(chosenTerm, setShowTermChange) }}>{!closeAccount ? "Confirm changes" : "Confirm closure"}</button>
                        </div>
                    </div>
                    :
                    <div className="mobile-continue-button-wrap">
                        <button className="Button btn-primary" onClick={() => { handleConfirmUpdate(chosenTerm, setShowTermChange) }}>{!closeAccount ? "Confirm changes" : "Confirm closure"}</button>
                    </div>
                }
            </div>
        </>
    )
}

export default GraceConfirmTermChange