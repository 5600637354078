import { createId } from '../utils'
import { IControl, TControlType } from './IControl.interface'

export const Control = (name: string, initialValue: any, label: string, type: TControlType, options: { [key: string]: any } = {}): IControl => {
  options = {
    validations: [],
    errorMessages: {},
    ...options
  }
  return {
    name,
    type,
    id: createId(),
    label,
    initialValue,
    value: initialValue,
    validations: options.validations,
    isRequired: !!options.validations.some((e: any) => e.name === 'IS_REQUIRED'),
    errorMessages: options.errorMessages,
    errors: [],
    isBlurred: false,
    isTouched: false,
    isDirty: false,
    isDisabled: false,
    isValid: !options.validations.some((e: any) => e.name === 'IS_REQUIRED'),
    placeholder: options.placeholder,
    selectOptions: options.selectedOptions || []
  }
}