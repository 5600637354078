import { IControl } from '../Form/Form/Control/IControl.interface';

/**
 * Checks if required fields are filled out
 * @param requiredRefs array of refs that are required
 * @returns if required fields are filled
 */
export const isFormFilled = (requiredRefs: Array<React.MutableRefObject<any>>) => {
    let isFilled = true;
    for (let i = 0; i < requiredRefs.length; i++) {
      if (!requiredRefs[i].current) {
        isFilled = false;
      }
    }
   return isFilled
  };

/**
 * Checks if the form to add a Person beneficiary is completely filled.
 * @param controls - The control from the form
 * @returns - If the form is filled
 */
const isPersonFormFilled = (controls: Array<IControl>) => {
  let isFilled = true;
  for(let i = 0; i < controls.length; i = i + 1){
    switch(controls[i].name){
      case "charityName":
      case "trustEstablishedDate":
      case "tin":
      case "trustName":
        break;
      default: 
        if((controls[i].isRequired && controls[i].value === "") || controls[i].errors.length){
          isFilled = false
        }
    }
  }

  return isFilled;
}

/**
 * Checks if the form to add a Trust beneficiary is completely filled.
 * @param controls - The control from the form
 * @returns - If the form is filled
 */
const isTrustFormFilled = (controls: Array<IControl>) => {
  let isFilled = true;

  for(let i = 0; i < controls.length; i = i + 1){
    switch(controls[i].name){
      case "firstName":
      case "middleName":
      case "lastName":
      case "dateOfBirth":
      case "ssn":
      case "email":
      case "relationship":
      case "charityName":
        break;
      default:
        if((controls[i].isRequired && controls[i].value === "") || controls[i].errors.length){
          isFilled = false
        }    
    }
  }

  return isFilled;
}

/**
 * Checks if the form to add a Charity/Non Profit beneficiary is completely filled.
 * @param controls - The control from the form
 * @returns - If the form is filled
 */
const isCharityFormFilled = (controls: Array<IControl>) => {
  let isFilled = true;

  for(let i = 0; i < controls.length; i = i + 1){
    switch(controls[i].name){
      case "firstName":
      case "middleName":
      case "lastName":
      case "dateOfBirth":
      case "ssn":
      case "relationship":
      case "trustEstablishedDate":
      case "trustName":
        break;
      default:
        if((controls[i].isRequired && controls[i].value === "") || controls[i].errors.length){
          isFilled = false
        }    
    }
  }

  return isFilled;
}

/**THe function is used to check for errors through all of the controls on the form.  Parameters are the form conrols and the type of beneficiary. */
export const checkControlValues = (controls: any, type: "Person" | "Trust" | "Charity" | "Non Profit" | "" = "") => {
  let isFilled = true;
  const control: any = Object.values(controls).map((control) => { return control })

  switch(type) {
    case 'Person':
      isFilled = isPersonFormFilled(control);
      break;
    case 'Trust':
      isFilled = isTrustFormFilled(control);
      break;
    case 'Charity':
    case 'Non Profit':
      isFilled = isCharityFormFilled(control);
      break;
    default:
      isFilled = false;
  }

  return isFilled;
}

/**Checks the control input values for the add owner control form */
export const checkAddOwnerControlValues = (controls: any) => {
  let isFilled = true;
  const control: any = Object.values(controls).map((control) => { return control })
  for(let i = 0; i < control.length; i = i + 1){
    if((control[i].isRequired && control[i].value === "") || control[i].errors.length){
      isFilled = false
    }    
  }

  return isFilled
}
