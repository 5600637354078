import './OverviewComponent.scss'
import DesktopOverview from './components/DesktopOverview/DesktopOverview';
import MobileOverview from './components/MobileOverview/MobileOverview';
import { useOverviewComponent } from '../../useHooks/componentHooks/useOverviewComponent/useOverviewComponent';

/** 
 * OverviewComponent gets the width of the screen and pixels and then renders
 * the desktop or mobile version of the Overview Component on the Account Details
 * Page.  Desktop = 800 pixels or greater, Mobile = less than 800 pixels.
 */ 
export const OverviewComponent = () => {
  const { selectedAccount, windowState, showDropDown, setShowDropDown, initialClick, setInitialClick, show, setShow,
          onTransactionDetails } = useOverviewComponent()

  return ( 
    <div className={windowState !== "Mobile" ? "overview-component" : "overview-component mobile"}> 
        {windowState !== "Mobile" ? 
          <DesktopOverview 
            selectedAccount={selectedAccount}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            initialClick={initialClick}
            setInitialClick={setInitialClick}
            show={show}
            setShow={setShow}
          /> 
          : 
          <MobileOverview 
            selectedAccount={selectedAccount}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            initialClick={initialClick}
            setInitialClick={setInitialClick}
            show={show}
            setShow={setShow}
            onTransactionDetails={onTransactionDetails}
          />
        }
    </div> 
  ); 
}; 
