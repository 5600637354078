/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEmailInput } from '../../Form/useHooks/useEmailInput';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import './EmailInput.scss'
import { IEmailInputProps } from './interfaces/IEmailInputProps'

/**Renders the Email Input compoent */
const EmailInput = (props: IEmailInputProps) => {
    const { control, style = {}, className, form, dispatchControlChanges } = props;
    const { onChangeHandler, handleOnBlur } = useEmailInput(form, control, dispatchControlChanges)

    return (
        <div className={`email-input-wrap ${className}`}>
            <InputLabel control={control}>
                <input 
                    data-testid={control.name}
                    type="email" 
                    placeholder={control.isDisabled ? '' : control.placeholder} 
                    className={control.errors.length > 0 ? "pass error regular" : "pass regular"} 
                    onChange={(e: any) => onChangeHandler(e)} 
                    onBlur={() => handleOnBlur()}
                    disabled={control.isDisabled}
                    value={control.value}
                />
            </InputLabel>
            {control.errors.length > 0 && <InputError controlName={control.name} errorArray={control.errors} getErrorMessage={form.getErrorMessage} />}
        </div>
    )
}

export default EmailInput