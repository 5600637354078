import axios, { AxiosPromise } from 'axios';
import { bffBaseUrl, token } from '../utils/globalVariables';
import { NotificationsResponse } from './models';

export const getAllNotifications_API = async (): Promise<NotificationsResponse> => {
  return new Promise((resolve, reject) => {
    let interval: any;
    let didRun = false;
    interval = setInterval(async () => {
      if (token() && !didRun) {
        didRun = true;
        try {
          const RESPONSE: NotificationsResponse = await axios.get(
            `${bffBaseUrl}/notifications/customer`,
            {
              headers: {
                Authorization: 'Bearer ' + token()
              }
            }
          );
          resolve(RESPONSE);
        } catch (err) {
          reject(err);
        }

        clearInterval(interval);
      }
    }, 100);
  });
};
