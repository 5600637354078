import { useUnsavedChanges } from '../../../../../../useHooks/componentHooks/useUnsavedChanges/useUnsavedChanges'
import { IMobileUnsavedChangesProps } from './interfaces/IMobileUnsavedChangesProps'
import warningCircle from '../../../../../../assets/warningCircle.svg'
import './MobileUnsavedChanges.scss'

const MobileUnsavedChanges = (props: IMobileUnsavedChangesProps) => {
    const { unsavedChanges, setUnsavedChanges, setEditBeneficiary, onReset } = props
    const { handleContinue, handleCancel } = useUnsavedChanges(unsavedChanges)

    return (
        <div className="mobile-unsaved-changes-modal">
            <div className="unsaved-changes-modal-wrapper">
                <img className="symbol" src={warningCircle} alt="warning symbol" />
                <span className="top-text boldButtonLarge">Unsaved Changes</span>
                <span className="bottom-text mobileRegular">You have unsaved changes that will be lost if you decide to continue. Are you sure you want to continue?</span>
                <button className="continue-button mobileButtonBold" onClick={(e) => { setUnsavedChanges(false); setEditBeneficiary(false); onReset(e); handleContinue(e); }}>Continue</button>
                <button className="mobile-unsaved-cancel-button mobileButtonBold" onClick={() => { setUnsavedChanges(false); handleCancel() }}>Cancel</button>
            </div>
        </div>
    )
}

export default MobileUnsavedChanges