import { useDisplayAccount } from '../../../../useHooks/componentHooks/useDisplayAccount'
import './DisplayAccount.scss'
import { IDisplayAccountProps } from './interfaces/IDisplayAccountProps'
import eye from '../../../../assets/eye.svg'
import closedEye from '../../../../assets/closedEye.svg'

/**
 * The component controls whether to hide or display the account numnber
 * @param props showAccount = boolean, selectedAccount = IAccountInformation
 */
const DisplayAccount = (props: IDisplayAccountProps) => {
    const { selectedAccount, showDropDown } = props
    const { showAccount, setShowAccount, accountNumber, success } = useDisplayAccount(showDropDown)

    return(
        <>
            {showAccount && success ? 
                <div className="show-value-wrap">
                    {showAccount && <span className="display-account-value semiboldRegular cls_mask">{accountNumber}</span>}
                    <img data-testid="hide-account" className="eye-icon closed" src={closedEye} alt="show icon" onClick={() => setShowAccount(!showAccount)}/>
                </div>
                :
                <div className="show-value-wrap">
                    <span className="display-account-value semiboldRegular">****{selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length-4, selectedAccount.accountNumber.length)}</span>
                    <img data-testid="show-account" className="eye-icon" src={eye} alt="show icon" onClick={() => setShowAccount(!showAccount)}/>
                </div> 

            }
        </>
    )
}

export default DisplayAccount