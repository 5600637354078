/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useRef, useState } from "react";
import { IWindowSize, IWindowSizeEventListener } from "./interfaces/IWindowResizeInterfaces";


let windowResizeEventListener: any = null;
let windowResizeListeners: Array<IWindowSizeEventListener> = [];

/**
 * Adds a new window resize event listener.
 * @param id - The id of the new event listener
 * @param callback - The callback of the new event listener
 */
// const addWindowResizeListener = (id: string, callback: IWindowSizeEventListener['callback']) => {
//     const INDEX = windowResizeListeners.findIndex(e => e.id === id);
//     /**
//      * Add or update this window resize listener
//      */
//     if (INDEX === -1) {
//         windowResizeListeners.push({ id, callback })
//     }
//     else {
//         windowResizeListeners[INDEX] = { id, callback };
//     }
//     if (!windowResizeEventListener) {
//         windowResizeEventListener = window.addEventListener('resize', callObservers);
//     }
// }

// /**
//  * Removes a window resize listener
//  * @param id - The id of the listener to remove.
//  */
// const removeWindowResizeListener = (id: string) => {
//     const INDEX = windowResizeListeners.findIndex((e) => e.id === id);
//     if (INDEX !== -1) {
//         windowResizeListeners.splice(INDEX, 1);
//     }
// }

/**
 * Calls all of the window resize observers after the window.onresize event happens.
 * @param event - The window.onresize event
 */
// const callObservers = (event: any) => {
//     if (!windowResizeListeners.length) {
//         window.removeEventListener('resize', callObservers);
//         windowResizeEventListener = null;
//     }
//     const { target: { innerHeight, innerWidth } } = event;
//     windowResizeListeners.forEach((e) => e.callback({ height: innerHeight, width: innerWidth }))
// }

/**
 * Returns a `boolean` for if the debounce window has occurred for the `useWindowResize` hook.
 * @param currentWindowSize - What the window's size currently is.
 * @param stateWindowSize - What the `useWindowResize`'s current state values are.
 * @param debounce - The amount of debounce (in px) to check for.
 * @returns 
 */
// const didWindowResizeDebounceHappen = (currentWindowSize: IWindowSize, stateWindowSize: IWindowSize, debounce: number) => {
//     const DEBOUNCE_WIDTH = Math.abs(currentWindowSize.width - stateWindowSize.width);
//     const DEBOUNCE_HEIGHT = Math.abs(currentWindowSize.height - stateWindowSize.height);
//     if (DEBOUNCE_HEIGHT > debounce || DEBOUNCE_WIDTH > debounce) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }

const getInitialSize = () => {
    if (window.innerWidth < 768) {
        return "Mobile"
    }
    else if (window.innerWidth >= 768 && window.innerWidth < 1080) {
        return "Hybrid"
    }
    else if (window.innerWidth >= 1080) {
        return "Desktop"
    }
    return ""
}

/**
 * A custom usehook that checks the size of the screen and allows us to convert between desktop and mobile modes.
 * The switching point between these two modes is set at 800.  Adjust this number will adjust
 * the mode switching point.
 * @returns the status of isDesktop which is true or false dependent on the total pixel width of the screen
 */
export const useWindowResize = () => {
    const [windowState, setWindowState] = useState<"Desktop" | "Hybrid" | "Mobile" | "">(getInitialSize())
    const updateSize = () => {
        if (window.innerWidth < 768) {
            setWindowState("Mobile")
        }
        else if (window.innerWidth >= 768 && window.innerWidth < 1080) {
            setWindowState("Hybrid")
        }
        else if (window.innerWidth >= 1080) {
            setWindowState("Desktop")
        }
    }

    useEffect(() => {
        window.addEventListener("resize", updateSize)
        return () => window.removeEventListener("resize", updateSize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return windowState
}
