import { ITransactionHistoryData } from "../api/getTransactionHistory";

/**Filters by the entered amount */
export const filterByAmount = (minAmount: number, maxAmount: number, transactions: Array<ITransactionHistoryData>) => {
    let amountFiltered = transactions.filter((transaction: ITransactionHistoryData) => {
        if(transaction.amount >= minAmount && transaction.amount <= maxAmount){
            return transaction
        }
        return false;
    })

    return amountFiltered
}