import { useState } from 'react';
import { useTransactionHistoryContext } from '../../context/TransactionHistoryContext';
import { useWindowState } from '../../../../Context/AccountContext/useWindowState';
import customCalendar from '../../../../assets/customCalendar.svg'
import { useCustomDateFilterContext } from './context/CustomDateFilterContext';

const useFromDateInput = (type: 'To' | 'From') => {
  const { boxSelection, localToDate, localFromDate } = useCustomDateFilterContext();
  const chosenDate = type === 'From' ? localFromDate : localToDate;
  const { windowState } = useWindowState();

  /**
   * Gets the `classname` for the input container
   * @returns 
   */
  const getInputClassnames = () => {
    let CLASSES = ['date-input-wrap'];

    if (boxSelection === type) {
      CLASSES.push('current');
    }

    if ((boxSelection === type || chosenDate.day > 0)) {
      CLASSES.push('selected');
    }

    if (windowState === 'Mobile') {
      CLASSES.push('mobile');
    }

    return CLASSES.join(' ');
  }

  return {
    chosenDate,
    windowState,
    getInputClassnames,
  }
}

export const DateFilterInput = (props: { type: 'To' | 'From' }) => {
  const { boxSelection, setIsDatePickerOpen, setBoxSelection } = useCustomDateFilterContext();
  const { chosenDate, windowState, getInputClassnames } = useFromDateInput(props.type);

  return <div className="input-box-column">
    <span className="label-text small">{props.type}</span>
    <div className={getInputClassnames()} onClick={() => { setIsDatePickerOpen(true); setBoxSelection(props.type) }}>
      {chosenDate.day !== 0 ?
        <>
          <img src={customCalendar} alt="custom calendar" />
          <span className={boxSelection === props.type ? "from-text selected regular" : "from-text regular"}>{chosenDate.month}/{chosenDate.day}/{chosenDate.year}</span>
        </>
        :
        <>
          {windowState !== "Mobile" ?
            <>
              <img src={customCalendar} alt="custom calendar" />
              <span className={boxSelection === props.type ? "from-text unselected regular" : "from-text unselected regular"}>Select Date</span>
            </>
            :
            <span className={boxSelection === props.type ? "from-text selected regular" : "from-text regular"}>mm/dd/yyyy</span>
          }
        </>
      }
    </div>
  </div>
}