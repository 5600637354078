import { IMemoProps } from './interfaces/IMemoProps';
import bluePencil from '../../../../assets/bluePencil.svg';
import { Button } from '../../../Button/Button';
import { useMemoComponent } from '../../../../useHooks/componentHooks/useMemoComponent/useMemoComponent';
import { useWindowState } from '../../../../Context/AccountContext/useWindowState';
import './Memo.scss'
/**
 * Memo Component
 * CSS is handled in the parent so that this component cleaner
 * @param selectedTransaction - Transaction that is selected for the memo
 */
export const Memo = (props: IMemoProps) => {
  const { windowState } = useWindowState();
  const { selectedTransaction, displayDetails, isMemoSelected} = props;
  const {
    editMemo,
    setEditMemo,
    memoInputRef,
    characterCount,
    setCharacterCount,
    handleCancel,
    handleSave,
  } = useMemoComponent(selectedTransaction, displayDetails);

  return (
    <div className="Memo">
      <div className="Memo-Row">
        <span className={windowState !== "Mobile" ? "Memo-Label boldRegular" : "Memo-Label mobileBoldSmall"}>Memo</span>
        {/* {windowState !== "Desktop" && (
          <>
            {selectedTransaction.memo && !editMemo && (
              <div className="Memo-Message regular">
                {selectedTransaction.memo}
              </div>
            )}
          </>
        )} */}
        <span
          className="Memo-Link semiboldRegular"
          onClick={() => {
            setEditMemo(true);
          }}
        >
          {!editMemo && (
            <>
              {selectedTransaction.memo ? (
                <>
                  <img src={bluePencil} className="Pencil" alt="Pencil" onClick={() => {if(isMemoSelected){isMemoSelected.current = true}}}/>
                  <span className="Memo-Link-Text" onClick={() => {if(isMemoSelected){isMemoSelected.current = true}}}>Edit</span>
                </>
              ) : (
                <>
                  <img src={bluePencil} className="Pencil" alt="Pencil" onClick={() => {if(isMemoSelected){isMemoSelected.current = true}}}/>
                  <span className="Memo-Link-Text" onClick={() => {if(isMemoSelected){isMemoSelected.current = true}}}>Add memo </span>
                </>
              )}
            </>
          )}
        </span>
      </div>
      {/* {windowState === "Desktop" && (
        <> */}
          {selectedTransaction.memo && !editMemo && (
            <div className="Memo-Message regular">
              {selectedTransaction.memo}
            </div>
          )}
        {/* </>
      )} */}

      {editMemo && (
        <>
          <div className="Text-Area" ref={isMemoSelected}>
            <textarea
              ref={memoInputRef}
              onChange={(e) => {
                setCharacterCount(e.target.value.length);
              }}
              className="Input-Memo regular"
              maxLength={200}
              placeholder="Input text here"
              defaultValue={selectedTransaction.memo}
            ></textarea>
            <div className="Character-Count x-small">{`${characterCount}/200`}</div>
          </div>
          <div className="Buttons">
            <Button
              className="Button btn-secondary"
              title="Cancel"
              onClick={handleCancel}
            />
            <Button
              className="Button btn-primary"
              title="Save Changes"
              onClick={handleSave}
            />
          </div>
        </>
      )}
    </div>
  );
};
