import { IProductsModalProps } from './interfaces/IProductsModalProps';
import './ProductsModal.scss';
import close from '../../assets/close.svg';
import { IProduct } from '../../api/getAccountInformation';
import { useEffect } from 'react';
import { getEventPath } from '../../utils/getEventPath';
import { useWindowState } from '../../Context/AccountContext/useWindowState';
import DesktopErrorAPICall from '../ErrorAPICall/DesktopErrorAPICall/DesktopErrorAPICall';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { ButtonDB } from '../../shared/digitalbank/Button/Button';
import { Button } from '../Button/Button';
import { getSAMLURL } from '../../shared/config/getSAMLUrl';
import { useOpenNewAccount } from '../../Context/OpenNewAccountContext/useOpenNewAccountContext';

/**Renders modal showing the list of products, used on the Account Overview Page and the CD Change component in the Maturity Settings flow */
const ProductsModal = (props: IProductsModalProps) => {
  const { windowState } = useWindowState();
  const {
    setShowModal,
    CDProductList,
    bumpProductList,
    savingsProductList,
    showSavingsProducts,
    origin
  } = props;
  const gotProductList = useAppSelector(
    (state: RootState) => state.accountInformation.gotProductList
  );

  /**Close the modal if a click outside of the modal is detected */
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const path = getEventPath(event);
      if (
        Array.from(path).findIndex(
          (e: any) =>
            e.classList &&
            Array.from(e.classList).includes('products-card-wrapper')
        ) === -1
      ) {
        document.body.style.overflow = 'auto';
        setShowModal(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.body.style.overflow = 'auto';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowState]);

  /**Locks the scroll bar for the page on render */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <div
      className={
        windowState !== 'Mobile'
          ? 'product-modal-wrap'
          : 'product-modal-wrap mobile'
      }
    >
      {windowState !== 'Mobile' ? (
        <div className="products-card-wrapper">
          <div className="header-row">
            <span className="header-text h2">Today's rates</span>
            <img
              className="close"
              src={close}
              alt="close"
              onClick={() => {
                setShowModal(false);
                document.body.style.overflow = 'auto';
              }}
            />
          </div>
          <ProductsPricingDesktop
            setShowModal={setShowModal}
            CDProductList={CDProductList}
            bumpProductList={bumpProductList}
            showSavingsProducts={showSavingsProducts}
            savingsProductList={savingsProductList}
            gotProductList={gotProductList}
            origin={origin}
          />
        </div>
      ) : (
        <div className="products-card-wrapper mobile">
          <div className="header-row mobile">
            <span className="header-text semiboldRegular mobile">
              Today's rates
            </span>
            <span
              className="done-button regular"
              onClick={() => {
                setShowModal(false);
                document.body.style.overflow = 'auto';
              }}
            >
              Done
            </span>
          </div>
          <ProductsPricingMobile
            setShowModal={setShowModal}
            CDProductList={CDProductList}
            bumpProductList={bumpProductList}
            showSavingsProducts={showSavingsProducts}
            savingsProductList={savingsProductList}
            gotProductList={gotProductList}
            origin={origin}
          />
        </div>
      )}
    </div>
  );
};

export default ProductsModal;

const ProductsPricingDesktop = (props: any) => {
  const {
    setShowModal,
    CDProductList,
    bumpProductList,
    showSavingsProducts,
    savingsProductList,
    gotProductList,
    origin
  } = props;
  const { checkExternalAccounts } = useOpenNewAccount()

  if (
    (CDProductList.length === 0 &&
      bumpProductList.length === 0 &&
      savingsProductList?.length === 0) ||
    gotProductList === 'Failed'
  ) {
    return (
      <DesktopErrorAPICall
        onClick={() => {
          setShowModal(false);
          document.body.style.overflow = 'auto';
        }}
      />
    );
  }

  //setting string of month to be capatalized
  const month = "months"

  return (
    <>
      <div className="products-wrap">
        <div className="product-list">
          <span className="column-header h4">Jumbo CD</span>
          <div className="data-headers">
            <span className="data-header-text mobileSemibold">Term</span>
            <span className="data-header-text mobileSemibold">APY</span>
          </div>
          <div className="show-products">
            {CDProductList.map((product: IProduct) => {
              return (
                <div key={product.code} className="product-row">
                  <span className="data-value regular">{product.name.split(" ")[0]} {month}</span>
                  <span className="data-value regular">
                    {parseFloat(product.apy).toFixed(2)}%
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="products-right">
          <div className="product-list bump">
            <span className="column-header h4">Jumbo Bump-Up CD</span>
            <div className="data-headers">
              <span className="data-header-text mobileSemibold">Term</span>
              <span className="data-header-text mobileSemibold">APY</span>
            </div>
            <div className="show-products">
              {bumpProductList.map((product: IProduct) => {
                return (
                  <div key={product.code} className="product-row">
                    <span className="data-value regular">{product.name.split(" ")[0]} {month}</span>
                    <span className="data-value regular">
                      {parseFloat(product.apy).toFixed(2)}%
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          {showSavingsProducts &&
            savingsProductList &&
            savingsProductList.length > 0 && (
              <div className="product-list bump">
                <span className="column-header h4">
                  Jumbo High Yield Savings Account
                </span>
                <div className="show-products">
                  {savingsProductList &&
                    savingsProductList.map((product: IProduct) => {
                      return (
                        <div
                          key={product.code}
                          className="product-row savings desktop"
                        >
                          {product.group === 'HYSA' && (
                            <>
                              <span className="data-value regular">APY</span>
                              <span className="data-value regular">
                                {parseFloat(product.apy).toFixed(2)}%
                              </span>
                            </>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
        </div>
      </div>
      <span className="disclaimer-text regular">
        *The annual percentage yields (APYs) shown here are for reference only
        and are not guaranteed. Rates are subject to change and may be different
        at the time your CD renews.
      </span>
      {origin !== "maturity" && 
            <Button
            sx={{
              width: '50%',
              alignSelf: 'center'
            }}
            className="Button btn-primary"
            title="Open a new account"
            onClick={checkExternalAccounts}
          />
      }
    </>
  );
};

const ProductsPricingMobile = (props: any) => {
  const {
    setShowModal,
    CDProductList,
    bumpProductList,
    showSavingsProducts,
    savingsProductList,
    gotProductList,
    origin
  } = props;
  const { checkExternalAccounts } = useOpenNewAccount()

  //setting string to dictate verbage of month in today's rates
  const month = " months"

  if (
    (CDProductList.length === 0 &&
      bumpProductList.length === 0 &&
      savingsProductList?.length === 0) ||
    gotProductList === 'Failed'
  ) {
    return (
      <DesktopErrorAPICall
        onClick={() => {
          setShowModal(false);
          document.body.style.overflow = 'auto';
        }}
      />
    );
  }
  return (
    <>
      <span className="column-header boldLarge">Jumbo CD</span>
      <div className="product-list">
        <div className="data-headers">
          <span className="data-header-text mobileSemibold">Term</span>
          <span className="data-header-text mobileSemibold">APY</span>
        </div>
        <div className="show-products">
          {CDProductList.map((product: IProduct) => {
            return (
              <div key={product.code} className="product-row">
                <span className="data-value regular">{product.name.split(' ')[0] + month}</span>
                <span className="data-value regular">
                  {parseFloat(product.apy).toFixed(2)}%
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <span className="column-header boldLarge">Jumbo Bump-Up CD</span>
      <div className="product-list">
        <div className="data-headers">
          <span className="data-header-text mobileSemibold">Term</span>
          <span className="data-header-text mobileSemibold">APY</span>
        </div>
        <div className="show-products">
          {bumpProductList.map((product: IProduct) => {
            return (
              <div key={product.code} className="product-row">
                <span className="data-value regular">{product.name.split(' ')[0] + month}</span>
                <span className="data-value regular">
                  {parseFloat(product.apy).toFixed(2)}%
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {showSavingsProducts &&
        savingsProductList &&
        savingsProductList.length > 0 && (
          <>
            <span className="column-header boldLarge">
              Jumbo High Yield Savings Account
            </span>
            <div className="product-list">
              <div className="show-products">
                {savingsProductList.map((product: IProduct) => {
                  return (
                    <div key={product.code} className="product-row savings">
                      {product.group === 'HYSA' && (
                        <>
                          <span className="data-value regular">APY</span>
                          <span className="data-value regular">
                            {parseFloat(product.apy).toFixed(2)}%
                          </span>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      <span className="disclaimer-text regular">
        *The annual percentage yields (APYs) shown here are for reference only
        and are not guaranteed. Rates are subject to change and may be different
        at the time your CD renews.
      </span>
      {origin !== "maturity" &&
            <Button
            className="Button btn-primary"
            title="Open a new account"
            onClick={checkExternalAccounts}
          />
      }
    </>
  );
};
