import axios, { AxiosPromise } from "axios"
import { bffBaseUrl, token } from "../utils/globalVariables"
import { BeneficiaryListResponse, GetBeneficiaryResponse } from './models'

/**
 * interface for beneficiaries 
 */
export interface IBeneficiary {
    id?: string
    name: string
    firstName?: string
    middleName?: string
    lastName?: string
    percentage: number
    tin?: string
    dateOfBirth?: any
    address1: string
    address2?: string
    city: string
    state: string
    zipCode: string
    relationship?: string
    type: "Person" | "Trust" | "Charity" | "Non Profit" | ""
    charityName?: string,
    phoneNumber?: string,
    email?: string,
    trustName?: string
    trustEstablishedDate?: any
    isDeleted: boolean
    ssn?: string
}

/**
 * get api call for beneficiaries
 * @param accountId string
 * @returns array of beneficiaries
 */
export const getBeneficiaries_API = (accountId: string): AxiosPromise<BeneficiaryListResponse> => {
    return axios.get(`${bffBaseUrl}/accounts/${accountId}/beneficiaries`, {
        headers: {
            Authorization: `Bearer ` + token
        }
    })
};

/**
 * get API call for individual beneificiary details
 */
export const getBeneficiaryDetails_API = (accountId: string, beneficiaryId: string, type: string): AxiosPromise<GetBeneficiaryResponse> => {
    let NEW_TYPE = type;

    if (NEW_TYPE === "Charity") {
        NEW_TYPE = "Non Profit"
    }

    return axios.get(`${bffBaseUrl}/accounts/${accountId}/beneficiaries/${beneficiaryId}?beneficiaryType=${NEW_TYPE}`)
}

/**Post API call to add a new 'Person' type beneficiary */
export const postNewBeneficiaryPerson_API = (accountId: string, beneficiary: IBeneficiary) => {
    if (beneficiary.state !== "") {
        return axios.post(`${bffBaseUrl}/accounts/${accountId}/beneficiaries`,
            {
                firstName: beneficiary.firstName,
                middleName: beneficiary.middleName,
                lastName: beneficiary.lastName,
                birthDate: beneficiary.dateOfBirth,
                taxIdentificationNumber: beneficiary.tin,
                addressLine1: beneficiary.address1,
                addressLine2: beneficiary.address2,
                city: beneficiary.city,
                state: beneficiary.state,
                relationship: beneficiary.relationship,
                phoneNumber: beneficiary.phoneNumber,
                emailAddress: beneficiary.email,
                postCode: beneficiary.zipCode,
                type: "Person"
            },
            {
                headers: {
                    Authorization: `Bearer ` + token,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    return axios.post(`${bffBaseUrl}/accounts/${accountId}/beneficiaries`,
        {
            firstName: beneficiary.firstName,
            middleName: beneficiary.middleName,
            lastName: beneficiary.lastName,
            birthDate: beneficiary.dateOfBirth,
            taxIdentificationNumber: beneficiary.tin,
            addressLine1: beneficiary.address1,
            addressLine2: beneficiary.address2,
            city: beneficiary.city,
            relationship: beneficiary.relationship,
            phoneNumber: beneficiary.phoneNumber,
            emailAddress: beneficiary.email,
            postCode: beneficiary.zipCode,
            type: "Person"
        },
        {
            headers: {
                Authorization: `Bearer ` + token,
                'Content-Type': 'application/json'
            }
        }
    )
}

/**Post API call to add a new "Organization" type beneficiary */
export const postNewBeneficiaryOrg_API = (accountId: string, beneficiary: IBeneficiary) => {
    return axios.post(`${bffBaseUrl}/accounts/${accountId}/beneficiaries/organizations`,
        {
            name: beneficiary.name,
            establishedDate: beneficiary.trustEstablishedDate,
            taxIdentificationNumber: beneficiary.tin,
            addressLine1: beneficiary.address1,
            addressLine2: beneficiary.address2,
            city: beneficiary.city,
            state: beneficiary.state,
            postCode: beneficiary.zipCode,
            phoneNumber: beneficiary.phoneNumber,
            emailAddress: beneficiary.type === "Trust" ? "" : beneficiary.email,
            allocationPercentage: 0,
            isCharity: (beneficiary.type === "Charity" || beneficiary.type === "Non Profit") ? true : false,
            type: (beneficiary.type === "Charity" || beneficiary.type === "Non Profit") ? "Non Profit" : "Trust"
        },
        {
            headers: {
                Authorization: `Bearer ` + token,
                'Content-Type': 'application/json'
            }
        }
    )
}

/**Patch API call to update all beneficiary alloations */
export const patchBeneficiaryAllocations_API = async (accountId: string, beneficiaries: Array<IBeneficiary>) => {
    let allocationArray: Array<{ id: string, percentage: number, isDeleted: boolean }> = []
    for (let i = 0; i < beneficiaries.length; i = i + 1) {
        let temp = { id: beneficiaries[i].id as string, percentage: beneficiaries[i].percentage, isDeleted: beneficiaries[i].isDeleted }
        allocationArray.push(temp)
    }
    return axios.put(`${bffBaseUrl}/accounts/${accountId}/beneficiaries`,
        {
            beneficiaries: allocationArray
        },
        {
            headers: {
                Authorization: `Bearer ` + token,
                'Content-Type': 'application/json'
            }
        }
    )
}

/**Patch a beneficiary details for a person type */
export const patchBeneficiaryDetails_API = (accountId: string, beneficiary: IBeneficiary) => {
    let object: any = {}
    if (beneficiary.type === "Person") {
        if (beneficiary.state !== "") {
            object = {
                firstName: beneficiary.firstName,
                middleName: beneficiary.middleName,
                lastName: beneficiary.lastName,
                taxIdentificationNumber: beneficiary.tin,
                birthDate: beneficiary.dateOfBirth,
                relationship: beneficiary.relationship,
                addressLine1: beneficiary.address1,
                addressLine2: beneficiary.address2,
                city: beneficiary.city,
                state: beneficiary.state,
                postCode: beneficiary.zipCode,
                phoneNumber: beneficiary.phoneNumber,
                emailAddress: beneficiary.email,
                type: "Person",
            }
        }
        else {
            object = {
                firstName: beneficiary.firstName,
                middleName: beneficiary.middleName,
                lastName: beneficiary.lastName,
                taxIdentificationNumber: beneficiary.tin,
                birthDate: beneficiary.dateOfBirth,
                relationship: beneficiary.relationship,
                addressLine1: beneficiary.address1,
                addressLine2: beneficiary.address2,
                city: beneficiary.city,
                postCode: beneficiary.zipCode,
                phoneNumber: beneficiary.phoneNumber,
                emailAddress: beneficiary.email,
                type: "Person",
            }
        }
        return axios.patch(`${bffBaseUrl}/accounts/${accountId}/beneficiaries/person/${beneficiary.id}`,
            object,
            {
                headers: {
                    Authorization: `Bearer ` + token,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    else if (beneficiary.type === "Trust") {
        if (beneficiary.state !== "") {
            object = {
                name: beneficiary.name,
                establishedDate: beneficiary.trustEstablishedDate,
                taxIdentificationNumber: beneficiary.tin,
                addressLine1: beneficiary.address1,
                addressLine2: beneficiary.address2,
                city: beneficiary.city,
                state: beneficiary.state,
                postCode: beneficiary.zipCode,
                phoneNumber: beneficiary.phoneNumber,
                type: "Trust",
            }
        }
        else {
            object = {
                name: beneficiary.name,
                establishedDate: beneficiary.trustEstablishedDate,
                taxIdentificationNumber: beneficiary.tin,
                addressLine1: beneficiary.address1,
                addressLine2: beneficiary.address2,
                city: beneficiary.city,
                postCode: beneficiary.zipCode,
                phoneNumber: beneficiary.phoneNumber,
                type: "Trust",
            }
        }
        return axios.patch(`${bffBaseUrl}/accounts/${accountId}/beneficiaries/organization/${beneficiary.id}`,
            object,
            {
                headers: {
                    Authorization: `Bearer ` + token,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    else if (beneficiary.type === "Charity" || beneficiary.type === "Non Profit") {
        if (beneficiary.state !== "") {
            object = {
                name: beneficiary.charityName,
                taxIdentificationNumber: beneficiary.tin,
                addressLine1: beneficiary.address1,
                addressLine2: beneficiary.address2,
                city: beneficiary.city,
                state: beneficiary.state,
                postCode: beneficiary.zipCode,
                phoneNumber: beneficiary.phoneNumber,
                emailAddress: beneficiary.email,
                type: "Non Profit",
            }
        }
        else {
            object = {
                name: beneficiary.charityName,
                taxIdentificationNumber: beneficiary.tin,
                addressLine1: beneficiary.address1,
                addressLine2: beneficiary.address2,
                city: beneficiary.city,
                postCode: beneficiary.zipCode,
                phoneNumber: beneficiary.phoneNumber,
                emailAddress: beneficiary.email,
                type: "Non Profit",
            }
        }
        return axios.patch(`${bffBaseUrl}/accounts/${accountId}/beneficiaries/organization/${beneficiary.id}`,
            object,
            {
                headers: {
                    Authorization: `Bearer ` + token,
                    'Content-Type': 'application/json'
                }
            }
        )
    }
    // return axios.patch(`${bffBaseUrl}/accounts/${accountId}/beneficiaries/${beneficiary.id}`)
}

