import axios from 'axios';
import { useState } from 'react';
import InputLabel from '../../components/InputLabel/InputLabel';
import '../../components/SelectInput/SelectInput.scss';
import lightGreyChevron from '../../assets/lightGreyChevron.svg';
import './LoquateAutoComplete.scss';
import { ILoquateRetreiveFullAddress, ILoquateAddressResponse, ILoquateMatchAddress, ILoquateVerifyRequestAddress } from './LoquateAutoComplete.types';
import { IFormContext, useFormControl } from '../../Form/useForm/useForm';
import { IControl } from '../../Form/Form/Control/IControl.interface';
import { useWindowState } from '../../Context/AccountContext/useWindowState';
import InputError from '../../components/InputError/InputError';
import { useTextInput } from '../../Form/useHooks/useTextInput';
import RXInput from '../../components/RXInput/RXInput';
import { CONFIG } from '../../utils/globalVariables';
import searchGlass from '../../assets/searchGlass.svg'
import blueSearchGlass from '../../assets/blueSearchGlass.svg'
import cancelCircle from '../../assets/cancelCircle.svg'

const LOQUATE_BASE_URL = 'https://api.addressy.com/Capture/Interactive';


/**
 * Returns the results based on the `text` from Loquate.
 * @param text - The address to search for.
 * @returns 
 */
const getLoquateAPI = (text: string) => {
  return axios.get(`${LOQUATE_BASE_URL}/Find/v1.1/json3.ws?Key=${CONFIG.LOQUATE_API_KEY}&Countries=US,GU,AS,MP,PR,VI,UM&isMiddleware=on&Language=en&Text=${encodeURIComponent(text)}`)
}

/**
 * Returns the results for a `container` from Loquate
 * @param containerId - The id of the container to get the results for.
 * @returns 
 */
const getLoquateContainer = (containerId: string) => {
  return axios.get(`${LOQUATE_BASE_URL}/Find/v1.1/json3.ws?Key=${CONFIG.LOQUATE_API_KEY}&Countries=US,GU,AS,MP,PR,VI,UM&isMiddleware=on&Language=en&Container=${encodeURIComponent(containerId)}`)
}

/**
 * Returns the API for the Full Address response from Loquate
 * @param containerId 
 * @returns 
 */
const getLoquateFullAddress = (containerId: string) => {
  return axios.get(`${LOQUATE_BASE_URL}/Retrieve/v1.2/json3.ws?Key=${CONFIG.LOQUATE_API_KEY}&Id=${encodeURIComponent(containerId)}`);
}

const isAddressValid = (request: ILoquateVerifyRequestAddress, response: ILoquateMatchAddress) => {
  const [p1, p2, postCodeStatus] = response.AVC.split('-');
  const [verificationStatus, postMatchLevel, preMatchLevel] = p1.split('');
  const [parsingStatus, lexiconMatchLevel, contextMatchLevel] = p2.split('');
  let isValid = true;

  if (!['A', 'B'].includes(response.AQI)) {
    isValid = false;
  }

  if (parsingStatus !== 'I' || verificationStatus !== 'V') {

    isValid = false;
  }

  if (!['P7', 'P8'].includes(postCodeStatus)) {

    isValid = false;
  }

  if (+postMatchLevel < 4 || +preMatchLevel < 4) {

    isValid = false;
  }

  if (+lexiconMatchLevel < 4 || +contextMatchLevel < 4) {

    isValid = false;
  }

  return isValid;


}


export const verifyLoquateAddress = async (addressObject: ILoquateVerifyRequestAddress) => {
  try {
    const RESPONSE = await axios.post('https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws', {
      Addresses: [addressObject],
      Key: CONFIG.LOQUATE_API_KEY,
      Geocode: true
    });

    if (isAddressValid(addressObject, RESPONSE.data[0].Matches[0])) {
      return Promise.resolve(true);
    }
    else {
      return Promise.reject(false);
    }

  }
  catch (err) {
    return Promise.reject(false);
  }

}

export const useLoquateOnForm = (form: IFormContext, dispatchForm: any) => {

  const getAddress1 = (selection: ILoquateRetreiveFullAddress) => {

    if (selection.BuildingNumber) {
      return `${selection?.BuildingNumber} ${selection?.Street}`
    }

    if (selection.Line1) {

      return selection.Line1;
    }


    return '';
  }

  const onLoquateSelection = (selection: ILoquateRetreiveFullAddress) => {

    if (form.controls.address1) {
      form.setValue('address1', getAddress1(selection));
    }

    if (form.controls.addressLine1) {
      form.setValue('addressLine1', getAddress1(selection));
    }

    if (form.controls.address2) {
      form.setValue('address2', selection.SubBuilding)
    }
    if (form.controls.addressLine2) {
      form.setValue('addressLine2', selection.SubBuilding)
    }


    form.setValue('city', selection.City)

    form.setValue('state', selection.ProvinceCode)
    form.setValue('zipCode', selection.PostalCode.slice(0, 5))

    dispatchForm();
    dispatchForm();
  }

  const isAddressValid = async (checkPOBox = true) => {
    if (checkPOBox) {
      const ADDRESS_1 = (form.controls['address1'] ? form.controls['address1'].value : form.controls['addressLine1'].value)
      const ADDRESS_2 = (form.controls['address2'] ? form.controls['address2'].value : form.controls['addressLine2'].value)
      const PO_BOX_REGEX = /p(ost)?[ |.]*o(ffice)?[ |.]*\sbox/ig;

      //If the address is a PO Box fail
      if (PO_BOX_REGEX.test(ADDRESS_1)) {
        if (form.controls.address1) {
          form.refControls.address1.errors.push({
            name: 'NO_PO_BOX'
          })
          form.refControls.address1.isValid = false;
        }

        if (form.controls.addressLine1) {
          form.refControls.addressLine1.errors.push({
            name: 'NO_PO_BOX'
          })
          form.refControls.addressLine1.isValid = false;
        }

        dispatchForm();
        dispatchForm();

        return Promise.reject(false);
      }

      if (PO_BOX_REGEX.test(ADDRESS_2)) {
        if (form.controls.address2) {
          form.refControls.address2.errors.push({
            name: 'NO_PO_BOX'
          })
          form.refControls.address2.isValid = false
        }

        if (form.controls.addressLine2) {
          form.refControls.addressLine2.errors.push({
            name: 'NO_PO_BOX'
          })
          form.controls.addressLine2.isValid = false
        }

        dispatchForm()
        dispatchForm()

        return Promise.reject(false)
      }

      return Promise.resolve(true);

    }

    if (form.controls.address1) {
      form.refControls.address1.errors = []
      form.refControls.address1.isValid = true;
    }

    if (form.controls.addressLine1) {
      form.refControls.addressLine1.errors = []
      form.refControls.addressLine1.isValid = true;
    }

    return Promise.resolve(true);
  }

  return {
    onLoquateSelection,
    isAddressValid
  }
}

/**Clear all address fields */
const clearAddressFields = (form: IFormContext) => {
  const VALUES = Object.values(form.controls)
  for (let i = 0; i < VALUES.length; i = i + 1) {
    switch (VALUES[i].name) {
      case 'address1':
      case 'addressLine1':
      case 'address2':
      case 'addressLine2':
      case 'city':
      case 'state':
      case 'zipCode': {
        form.resetControl(VALUES[i])
      }
    }
  }
}

interface ILoquateAutoCompleteProps {
  onSelection: (selection: ILoquateRetreiveFullAddress) => any;
  onlyResidental?: boolean;
  control: IControl;
  usingSuggestion: boolean
  setUsingSuggestion: React.Dispatch<React.SetStateAction<boolean>>
}
export const LoquateAutoComplete = (props: ILoquateAutoCompleteProps) => {
  const { onSelection, onlyResidental = false, control, usingSuggestion, setUsingSuggestion } = props;
  const [form, dispatchControlChanges] = useFormControl()
  const { windowState } = useWindowState();
  const { handleOnBlur } = useTextInput(form, control, dispatchControlChanges)


  /**
   * The addresses from the Loquate API
   */
  const [addresses, setAddresses] = useState<Array<ILoquateAddressResponse>>([]);

  /**
   * The currently selected container. No container is selected if ''
   */
  const [selectedContainer, setSelectedContainer] = useState('');

  /**
   * If the dropdown should be open
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * The error message if it exists
   */
  const [errorMessage, setErrorMessage] = useState('')

  const getAddress1 = (selection: ILoquateRetreiveFullAddress) => {

    if (selection.BuildingNumber) {
      return `${selection?.BuildingNumber} ${selection?.Street}`
    }

    if (selection.Line1) {
      return selection.Line1;
    }


    return '';
  }

  /**
   * Calls the `props.onSelection` once the user selects a address
   * @param selection - The full address from Loquate
   */
  const handleOnSelection = async (selection: ILoquateAddressResponse, setUsingSuggestion?: React.Dispatch<React.SetStateAction<boolean>>) => {

    if (selection.Type === 'Container') {
      getContainer(selection);
      if (setUsingSuggestion) {
        setUsingSuggestion(false)
      }
      return;
    }

    if (selection.Type === 'Address') {
      try {
        const ADDRESS = await getAddress(selection.Id);
        setIsOpen(false);

        //We only want Residential addresses
        if (ADDRESS?.Type !== 'Residential' && onlyResidental) {

          control.errors.push({
            name: 'onlyResidental'
          })
          setErrorMessage('You must have a residental address')
          return;
        }
        else {
          control.errors = [];
        }
        form.setValue(control.name, getAddress1(ADDRESS));
        setErrorMessage('');
        onSelection(ADDRESS as ILoquateRetreiveFullAddress);
        dispatchControlChanges();
      }
      catch (err) {

      }
      if (setUsingSuggestion) {
        setUsingSuggestion(false)
      }
    }
    else {
      //TODO Handle non address
    }
  }

  /**
   * Returns the full address information from loquate
   * @param id - The Id of the address selected
   * @returns - The Full address information
   */
  const getAddress = async (id: string) => {
    try {
      const RESPONSE = await getLoquateFullAddress(id)
      return RESPONSE.data.Items[0] as ILoquateRetreiveFullAddress;
    }
    catch (err) {
      return Promise.reject(false);
    }
  }

  /**
   * Gets the units (apartments) for a given `Container`
   * Sets the `otherAddresses` key on the `addresses` array for the specific container
   * @param selection 
   */
  const getContainer = async (selection: ILoquateAddressResponse) => {
    try {

      const NEW_ADDRESS: Array<ILoquateAddressResponse> = JSON.parse(JSON.stringify(addresses));
      const CONTAINER_INDEX = NEW_ADDRESS.findIndex((e) => e.Id === selection.Id);

      if (CONTAINER_INDEX !== -1) {
        NEW_ADDRESS[CONTAINER_INDEX].otherAddresses = await getContainerAddresses(selection.Id);
        setAddresses(NEW_ADDRESS)
        setSelectedContainer(selection.Id);
      }
    }
    catch (err) {
      console.log('getLoquateContainer', err);
    }
  }

  /**
   * Gets all of the units (apartments) assosiated with a `Container` address
   * @param containerId - The Container Id to call the `getLoquateContainer` call with.
   * @param addresses - All of the units for the `Container` address.
   * @returns - An Array of units for the inital `Container.Id`.
   */
  const getContainerAddresses = async (containerId?: string, addresses: Array<ILoquateAddressResponse> = []): Promise<Array<ILoquateAddressResponse>> => {

    //If there is no containerId then there is no more units to get, return the addresses array
    if (!containerId) {
      return addresses;
    }

    const RESPONSE = await getLoquateContainer(containerId);
    const ADDRESSES = RESPONSE.data.Items.filter((e: ILoquateAddressResponse) => e.Type === 'Address');
    const CONTAINERS = RESPONSE.data.Items.filter((e: ILoquateAddressResponse) => e.Type === 'Container');
    addresses.push(...ADDRESSES);

    // Recursively call getContainerAddresses until there is no containers left to call.
    return getContainerAddresses(CONTAINERS[0]?.Id, addresses);

  }

  /**
   * Call the Loqaute API based on `value` and sets the `addresses` state.
   */
  const getInitailResponses = async (inputValue: string) => {
    form.setValue(control.name, inputValue);
    setSelectedContainer('');
    dispatchControlChanges();

    if (inputValue.length < 3) {
      setAddresses([]);
      setIsOpen(false);
      return;
    }



    try {
      const response = await getLoquateAPI(inputValue);
      setIsOpen(true);
      setAddresses(response.data.Items);
    }
    catch (err) {
      console.log('loquate call failed', err);
    }
  }

  /**
   * Handles when the input has been blurred and closes the dropdown
   * @param event 
   */
  const handleBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  }

  if (!control) {
    return null;
  }

  const ADDRESSES = selectedContainer ? addresses.find(e => e.Id === selectedContainer)?.otherAddresses || [] : addresses;

  return (<div className={`select-input-wrap ${windowState === 'Mobile' ? 'full' : 'half'}`}>
    <div onBlur={handleBlur} tabIndex={-1} className="selection-wrap">
      <InputLabel
        control={control}
        showLabel={false}
      >
        <>
          <div className={`input-box-wrapper ${(errorMessage || control.errors.length) && 'error'}`}>
            {control.value.length === 0 && <img src={searchGlass} alt="search glass" />}
            <input
              disabled={control.isDisabled}
              placeholder='Search for your street address'
              className={`input-box ${control.value.length > 0 ? "boldRegular no-padding" : "regular"}`}
              // className={`pass regular ${(errorMessage || control.errors.length) && 'error'}`}
              value={control.value}
              onChange={(e) => getInitailResponses(e.target.value)}
              onFocus={() => setIsOpen(true)}
              onBlur={handleOnBlur}
              data-testid="street address input"
            />
            {control.value.length > 0 &&
              <img className="cancel-circle" src={cancelCircle} alt="cancel button" onClick={() => { clearAddressFields(form); dispatchControlChanges(); setIsOpen(false) }} />
            }
          </div>
          {isOpen && ADDRESSES.length > 0 &&
            <>
              <div className="test">

                <div className="dropdown cls_mask" style={{
                  borderBottom: selectedContainer ? 'none' : 'inherit',
                  borderBottomLeftRadius: selectedContainer ? '0' : 'inherit',
                  borderBottomRightRadius: selectedContainer ? '0' : 'inherit',
                }}>
                  {ADDRESSES?.map((e) => <AddressDisplayRow key={e.Id} element={e} handleOnSelection={handleOnSelection} setUsingSuggestion={setUsingSuggestion} windowState={windowState} />)}
                </div>
                {/* <span className="bottom-display regular">Can’t find your address? Enter it manually</span> */}
                <LoquateBackButton
                  handleBack={() => { setSelectedContainer('') }}
                  setUsingSuggestion={setUsingSuggestion}
                  form={form}
                  dispatchControlChanges={dispatchControlChanges}
                />
              </div>
            </>
          }
        </>
      </InputLabel>
      {errorMessage && <span className="error-message mobileParagraphRegular">{errorMessage}</span>}
      {control.errors.length > 0 && <InputError controlName={control.name} errorArray={control.errors} getErrorMessage={form.getErrorMessage} />}
      <span className="swap-text regular" onClick={() => { setUsingSuggestion(false); clearAddressFields(form); dispatchControlChanges() }}>Click here to manually enter your address instead</span>
    </div>
  </div>)


}

/**
 * The back button for the loquate dropdown
 * @param props - 
 * @returns 
 */
const LoquateBackButton = (props: { handleBack: () => any, setUsingSuggestion: any, form: IFormContext, dispatchControlChanges: () => void }) => {
  return <div className="dropdown back-container"><div
    className="selection-row back-button"
    onClick={() => { props.handleBack(); props.setUsingSuggestion(false); clearAddressFields(props.form); props.dispatchControlChanges() }}>
    {/* <img src={DownArrow} alt="Go back" /> */}
    <span className="option-text blue regular">Can’t find your address? Enter it manually</span>
  </div>
  </div>
}

/**
 * The row for the Loquate dropdown
 * @param props 
 * @returns 
 */
const AddressDisplayRow = (props: { element: ILoquateAddressResponse, handleOnSelection: (element: ILoquateAddressResponse) => any, setUsingSuggestion: any, windowState: "" | "Mobile" | "Desktop" | "Hybrid" }) => {
  const { element, handleOnSelection, setUsingSuggestion, windowState } = props;
  const highlightArray = element.Highlight.split(",")
  const highlightedIndex = highlightArray[highlightArray.length - 1].split("-")[1] || [0]
  let substringOne = element.Text.substring(0, +highlightedIndex)
  let substringTwo = element.Text.substring(+highlightedIndex, element.Text.length)

  return (
    <>
      {windowState !== "Mobile" ?
        <div
          className={`selection-row cls_mask`}
          onClick={() => { handleOnSelection(element); element.Type !== "Container" ? setUsingSuggestion(false) : setUsingSuggestion(true) }}>
          <span className="option-text boldRegular no-decoration">{substringOne}<span className="option-text no-decoration regular">{substringTwo}</span></span>
          <span className="loquate-description regular desktop">{element.Description}</span>
          {element.Description && <img src={lightGreyChevron} alt="More Address Options" />}
        </div>
        :
        <div
          className="selection-row mobile cls_mask"
          onClick={() => { handleOnSelection(element); element.Type !== "Container" ? setUsingSuggestion(false) : setUsingSuggestion(true) }}
        >
          <span className="option-text no-decoration boldRegular">{substringOne}<span className="option-text no-decoration regular">{substringTwo}</span></span>
          {element.Description &&
            <div className="right-side">
              <span className="loquate-description regular">{element.Description}</span>
              <img src={lightGreyChevron} alt="More Address Options" />
            </div>
          }
        </div>
      }
    </>
  )
}


export const AddressInputWrapper = (props: any) => {
  const { form, onSelection, control, onlyResidental = false, usingSuggestion, setUsingSuggestion, dispatchForm } = props;
  const { windowState } = useWindowState();

  if (!form) {
    return null;
  }

  const DIV_CLASSNAME = windowState !== "Mobile" ? "row" : "row mobile"

  const ADDRESS1 = form.controls.address1 || form.controls.addressLine1;
  const ADDRESS2 = form.controls.address2 || form.controls.addressLine2;
  return <div className="address-input-wrapper">
    {usingSuggestion ?
      <div className={DIV_CLASSNAME}>
        <LoquateAutoComplete
          onlyResidental={onlyResidental}
          onSelection={onSelection}
          control={control}
          usingSuggestion={usingSuggestion}
          setUsingSuggestion={setUsingSuggestion}
        />
      </div>
      :
      <>
        <div className={DIV_CLASSNAME}>
          <RXInput control={{ ...ADDRESS1 }} className={windowState === 'Mobile' ? 'full' : 'half'} />
          <RXInput control={{ ...ADDRESS2 }} className={windowState === 'Mobile' ? 'full' : 'half'} />
        </div>
        <div className={DIV_CLASSNAME}>
          <RXInput control={{ ...form.controls.city }} className={windowState === 'Mobile' ? 'full' : 'third'} />
          <RXInput control={{ ...form.controls.state }} className={windowState === 'Mobile' ? 'full' : 'third'} />
          <RXInput control={{ ...form.controls.zipCode }} className={windowState === 'Mobile' ? 'full' : 'third'} />
        </div>
        <div className="search-text-wrapper">
          <img className="image" src={blueSearchGlass} alt="blue search glass" />
          <span className="search-text regular" onClick={() => { setUsingSuggestion(true); clearAddressFields(form); dispatchForm() }}>Search for your address</span>
        </div>
      </>
    }
  </div>
}

