import './DesktopStatementsLanding.scss'
import blueDocument from '../../../assets/blueDocument.svg'
import { IDesktopStatementsLandingProps } from './interfaces/IDesktopStatementsLandingProps'
import { IStatements } from '../../../api/getStatements'
import { PaginationComponent } from '../../PaginationComponent/PaginationComponent'
import FilterBox from '../../../Pages/Documents/DesktopDocumentsPage/components/DesktopDateSelector/FilterBox'
import emptyStatements from '../../../assets/emptyStatements.svg'
import { downloadAccountStatement } from '../../../api/getAccountInformation'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useRef } from 'react'
import { downloadDocuments } from '../../../utils/downloadDocuments'

/**
 * This is the landing page for the desktop version of statements.  It displays all of the statements associated with
 * a particular account, and a filter to sort them.
 */
const DesktopStatementsLanding = (props: IDesktopStatementsLandingProps) => {
    const { filterYear, setFilterYear, filteredStatements, setShowPDF, statementYearsRef, openFilterMenu, 
            setOpenFilterMenu, selectedAccount, startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow, 
            setNumberOfItemsToShow } = props
    const { message, type, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

     /**handle the onClick event and downloads the account statement document */
    const handleStatementDownload = async (id: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadAccountStatement(selectedAccount.id, id)
                downloadDocuments(response.data, selectedAccount.accountNumber, "Statement", "")
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()
            }
            downloadClickedRef.current = false
        }
    }

    return(
        <div className="desktop-statements-landing-wrap">
            <div className="filter-box-wrapper">
                <span className="text-label small">Time period</span>
                <FilterBox filterSelections={statementYearsRef} selectedFilter={filterYear} setSelectedFilter={setFilterYear} displayMessage="Time Period" />
            </div>
            <div className="card-wrapper">
                {filteredStatements && filteredStatements.length > 0 ? 
                    <>
                    {filteredStatements.slice(startIndex, endIndex).map((statement: IStatements, index: number) => {
                    return(
                        <div key={index}>
                            <div className="row-wrapper" >
                                <img className="document-image" src={blueDocument} alt="document" onClick={() => {setShowPDF(true); handleStatementDownload(statement.id)}} />
                                <span className="statement-text regular" onClick={() => {setShowPDF(true); handleStatementDownload(statement.id)}}>Statement - {statement.date} (PDF)</span>
                            </div>
                            <div className="border-line" />
                            {/* {(index !== filteredStatements.length - 1) && <div className="border-line" />} */}
                        </div>
                    )})}
                    {(filteredStatements && filteredStatements.length) > 10 &&
                        <div className="pagination-wrapper">
                            <PaginationComponent 
                                type="Mobile"
                                itemType=""
                                totalItems={filteredStatements.length}
                                setStartIndex={setStartIndex}
                                setEndIndex={setEndIndex}
                                numberOfItemsToShow={numberOfItemsToShow}
                                setNumberOfItemsToShow={setNumberOfItemsToShow}
                                spaceBetween={true}
                            />
                        </div>
                    }
                    </>
                    :
                    <div className="empty-statements-wrapper">
                        <img className="failed-image" src={emptyStatements} alt="no statements available" />
                        <span className="text h4">No statements</span>
                        <span className="text regular">Statements will be listed here</span>
                    </div>
                }
            </div>
            {message !== "" && <ToastMessageDB type={type} message={message} /> }
        </div>
    )
}

export default DesktopStatementsLanding