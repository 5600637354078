import { BaseDashedNumberInput } from '../BaseDashedNumberInput/BaseDashedNumberInput';
import { ITinInputProps } from './interfaces/ITinInputProps'

/**Renders the Tin Input component used in form entries */
const TinInput = (props: ITinInputProps) => {
    const { control, className, style = {}, form, dispatchControlChanges } = props;

    return <BaseDashedNumberInput data-testid="form-tin" control={control} className={className} style={style} form={form} dispatchControlChanges={dispatchControlChanges} placeholder="XX-XXXXXXX" />
}

export default TinInput