import Checkbox from '../../../../components/Checkbox/Checkbox'
import { useReviewOwner } from '../../../../useHooks/componentHooks/useReviewOwner/useReviewOwner'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import './ReviewOwner.scss'
import { IReviewOwnerProps } from './interfaces/IReviewOwnerProps'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders the review co-owner page as step 3 in the add new co-owner flow */
const ReviewOwner = (props: IReviewOwnerProps) => {
    const { form, dispatchForm, IRSWithholding, setIRSWithholding, foreignAffiliation, setForeignAffiliation, setFormStep, setAddOwner } = props
    const { windowState, electronicConsent, setElectronicConsent, depositAgreement, setDepositAgreement, enableContinue, handleConfirm, handleCancel }
        = useReviewOwner(setFormStep, form, dispatchForm, IRSWithholding, setIRSWithholding, foreignAffiliation, setForeignAffiliation, setAddOwner)

    return (
        <div className="review-owner-wrapper">
            <span className="header-text h3">Co-owner confirmation details</span>
            <span className="required-text regular">Fields marked with an <span className="red-text">*</span> are required</span>
            <div className="details-stack">
                <div className={windowState !== "Mobile" ? "details-wrap" : "details-wrap mobile"}>
                    <span className="details-header h4">Co-owner</span>
                    <div className={windowState !== "Mobile" ? "details-row" : "details-row mobile"}>
                        <div className="left">
                            <div className="label-value-wrap">
                                <span className="label boldSmall">Name</span>
                                <span className="value regular cls_mask">{form.controls.firstName.value} {form.controls.middleName.value} {form.controls.lastName.value} {form.controls.prefix.value !== "" ? `(${form.controls.prefix.value})` : ""}</span>
                            </div>
                            <div className="label-value-wrap">
                                <span className="label boldSmall">Date of birth</span>
                                <span className="value regular cls_mask">{convertDateTommddyyyy(form.controls.dateOfBirth.value)}</span>
                            </div>
                        </div>
                        <div className="right">
                            <div className="label-value-wrap">
                                <span className="label boldSmall">Citizenship</span>
                                <span className="value regular cls_mask">{form.controls.citizenship.value}</span>
                            </div>
                            <div className="label-value-wrap">
                                <span className="label boldSmall">Social Security number</span>
                                <span className="value regular cls_mask">{form.controls.ssn.value}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={windowState !== "Mobile" ? "details-wrap" : "details-wrap mobile"}>
                    <span className="details-header h4">Contact information</span>
                    <div className={windowState !== "Mobile" ? "details-row" : "details-row mobile"}>
                        <div className="left">
                            <div className="label-value-wrap">
                                <span className="label boldSmall">Address</span>
                                <span className="value regular cls_mask">{form.controls.address1.value} {form.controls.address2.value}</span>
                                <span className="value regular cls_mask">{form.controls.city.value}, {form.controls.state.value} {form.controls.zipCode.value}</span>
                                <span className="value regular cls_mask">United States</span>
                            </div>
                        </div>
                        <div className="right">
                            <div className="label-value-wrap">
                                <span className="label boldSmall">Email</span>
                                <span className="value regular cls_mask">{form.controls.email.value}</span>
                            </div>
                            {form.controls.phoneNumber.value !== "" &&
                                <div className="label-value-wrap">
                                    <span className="label boldSmall">Phone number</span>
                                    <span className="value regular cls_mask">{form.controls.phoneNumber.value}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={windowState !== "Mobile" ? "details-wrap" : "details-wrap mobile"}>
                    <span className="details-header h4">Additional co-owner information</span>
                    <div className="question-wrap margin-top">
                        {/* <div className="question-stack">
                            <span className="question regular">I have been notified by the IRS that I am subject to Federal backup withholdings?</span>
                            <span className="answer boldRegular">{IRSWithholding}</span>
                        </div> */}
                        <div className="question-stack">
                            <span className="question regular">Are you or an immediate family member a close associate of someone who is currently or previously a Foreign Senior Political Figure?</span>
                            <span className="answer boldRegular cls_mask">{foreignAffiliation}</span>
                        </div>
                    </div>
                </div>
                <div className="checkbox-wrap">
                    <Checkbox
                        showCheckmark={electronicConsent}
                        setShowCheckmark={setElectronicConsent}
                    />
                    <span data-testid="checkbox-click" className="consent-text regular" onClick={() => setElectronicConsent(!electronicConsent)}>I confirm that I have read and agree to the <a className="blue-text" onClick={(e: any) => e.stopPropagation()} href="https://www.creditonebank.com/content/dam/creditonebank/corporate-transactional/pdf/electronic-disclosure-and-communications-consent-agreement-deposits.pdf" target="_blank" rel="noreferrer noopener">Electronic Disclosure and Communications Consent Agreement.</a><span className="red-text">*</span></span>
                </div>
                <div className="checkbox-wrap">
                    <Checkbox
                        showCheckmark={depositAgreement}
                        setShowCheckmark={setDepositAgreement}
                    />
                    <span data-testid="checkbox-click" className="consent-text regular" onClick={() => setDepositAgreement(!depositAgreement)}>I confirm that I have read and agree to the&nbsp;
                        <a className="blue-text" onClick={(e: any) => e.stopPropagation()} href="https://www.creditonebank.com/content/dam/creditonebank/corporate-transactional/pdf/Deposit_Agreement_Disclosures.pdf" target="_blank" rel="noreferrer noopener">Deposit Agreement and Disclosures.</a>
                        {/* <span className="consent-text">&nbsp;By agreeing to our terms & conditions, they also agree to our </span>
                        <a className="blue-text" onClick={(e: any) => e.stopPropagation()} href="https://www.creditonebank.com/deposits/privacy" target="_blank" rel="noreferrer">Privacy Policy.</a> */}
                        <span className="red-text">*</span>
                    </span>
                </div>
                {windowState !== "Mobile" ?
                    <>
                        <div className="bottom-border" />
                        <div className="button-wrap">
                            <div className="left" onClick={() => { setFormStep(2); setElectronicConsent(false); setDepositAgreement(false) }}>
                                <img src={arrowLeftBlue} alt="blue left facing chevron" />
                                <span className="blue-text regular">Back</span>
                            </div>
                            <div className="right">
                                <button className="Button btn-secondary" onClick={() => handleCancel()}>Cancel</button>
                                {/* <button className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableContinue} onClick={() => handleConfirm()}>Confirm</button> */}
                                <Button
                                    title="Confirm"
                                    isDisabled={!enableContinue}
                                    className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"}
                                    id={TIMER_IDS.OWNER_ADD}
                                    onClick={handleConfirm}
                                />
                            </div>
                        </div>
                    </>
                    :
                    // <button className={enableContinue ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"} disabled={!enableContinue} onClick={() => handleConfirm()}>Confirm</button>
                    <Button
                        title="Confirm"
                        isDisabled={!enableContinue}
                        className={enableContinue ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"}
                        id={TIMER_IDS.OWNER_ADD}
                        onClick={handleConfirm}
                    />
                }
            </div>
        </div>
    )
}

export default ReviewOwner