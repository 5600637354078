import { useOTPModal } from '../../useHooks/componentHooks/useOTPModal/useOTPModal'
import './OTPModal.scss'
import VerifyIdentity from './components/VerifyIdentity/VerifyIdentity'
import close from '../../assets/close.svg'
import VerificationCodeInput from './components/VerificationCodeInput/VerificationCodeInput'
import AccountLocked from './components/AccountLocked/AccountLocked'

const OTPModal = () => {
    const { windowState, step, setStep, handleCancel, verifySelection, setVerifySelection, factorOptions, chosenFactor, setChosenFactor,
        emailRef, openOTPModal } = useOTPModal()

    if (!openOTPModal) {
        return null
    }

    return (
        <div className={`otp-modal-overlay ${windowState}`}>
            <div className={windowState !== "Mobile" ? "otp-modal-details-wrap" : "otp-modal-details-wrap mobile"}>
                {step === 1 && <img className="cancel-image" src={close} alt="cancel x" onClick={() => handleCancel()} />}
                {step === 1 &&
                    <VerifyIdentity
                        setStep={setStep}
                        setVerifySelection={setVerifySelection}
                        factorOptions={factorOptions}
                        setChosenFactor={setChosenFactor}
                        chosenFactor={chosenFactor}
                    />
                }
                {step === 2 &&
                    <VerificationCodeInput
                        setStep={setStep}
                        verifySelection={verifySelection}
                        chosenFactor={chosenFactor}
                    />
                }
                {step === 3 &&
                    <AccountLocked emailRef={emailRef} />
                }
            </div>
        </div>
    )
}

export default OTPModal