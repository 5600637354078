import { getExternalAccountNickname_API } from "../api/User/LinkExternalAccounts"
import { IAddress, IEmail, IPhone, ITrustedDevice, IExternalAccounts } from "../api/User/interfaces"


/**Takes the services response for phones and packages it correctly for UI*/
export const getPhones = (phones: Array<any>) => {
    let tempPhones: Array<IPhone> = []

    for(let i = 0; i < phones.length; i = i + 1){
        tempPhones[i] = {
            id: phones[i].individualPhoneId,
            number: phones[i].phoneNumber,
            type: phones[i].phoneUsageType === "Primary" ? "Mobile" : "Alternate"
        }
    }

    tempPhones = formatPhoneNumbers(tempPhones)

    return tempPhones
}

/**Takes the services response for addresses and packages it correctly for UI */
export const getAddresses = (addresses: Array<any>) => {
    let tempAddresses: Array<IAddress> = []
    let residentialAddressFirstArray: Array<IAddress> = []

    for(let i = 0; i < addresses.length; i = i + 1){
        let tempAddress: IAddress = {
            id: addresses[i].individualAddressId,
            addressType: addresses[i].addressUsageType,
            addressLine1: addresses[i].addressLine1,
            addressLine2: addresses[i].addressLine2 || "",
            city: addresses[i].city,
            state: addresses[i].state,
            zipCode: addresses[i].zipcodeFirst5,
            isMailing: addresses[i].isMailing || false,
            isResidential: addresses[i].addressUsageType === "Residence" ? true : false,
            country: ""
        }
        if(tempAddress.isResidential){
            residentialAddressFirstArray.push(tempAddress)
        }
        else{
            tempAddresses.push(tempAddress)
        }
    }

    let sortedAddressArray = residentialAddressFirstArray.concat(tempAddresses)

    return sortedAddressArray
}

/**Takes the services response for emails and packages it correctly for UI */
export const getEmails = (emails: any) => {
    let tempEmails: Array<IEmail> = []
    
    for(let i = 0; i < emails.length; i = i + 1){
        tempEmails[i] = {
            id: emails[i].individualEmailAddressId,
            type: emails[i].emailUsageType,
            emailAddress: emails[i].emailAddress,
            isPrimary: true
        }
    }

    return tempEmails
}

/**Adds '-' to the phone numbers for display purposes */
const formatPhoneNumbers = (phones: Array<IPhone>) => {
    let formattedPhones = phones

    for(let i = 0; i < formattedPhones.length; i = i + 1){
        let sectionOne = formattedPhones[i].number.slice(0,3)
        let sectionTwo = formattedPhones[i].number.slice(3,6)
        let sectionThree = formattedPhones[i].number.slice(6)
        formattedPhones[i].number = `${sectionOne}-${sectionTwo}-${sectionThree}`
    }

    return formattedPhones
}

/**Populate the users trusted devices */
export const populateTrustedDevices = (trustedDevices: Array<any>) => {
    let tempDevicesArray: Array<ITrustedDevice> = []
    for(let i = 0; i < trustedDevices.length; i = i + 1){
        let tempDevice: ITrustedDevice = {
            id: trustedDevices[i].id,
            device: trustedDevices[i].os || "Device OS",
            deviceNickname: trustedDevices[i].name || "Device Name",
            ip: trustedDevices[i].ipaddress,
            lastSignIn: trustedDevices[i]["last-signin"],
            currentlyUsing: false
        }
        tempDevicesArray.push(tempDevice)
    }

    let sortedArray = tempDevicesArray.sort((a, b) => {return new Date(b.lastSignIn).getTime() - new Date(a.lastSignIn).getTime()})

    return sortedArray
}

/**Maps the status to the correct values */
const mapStatus = (status: string) => {
    let newStatus: string = ""
    switch(status){
        case "verified":{
            newStatus = "Linked"
            break;
        }
        case "pending":{
            newStatus = "Verify now"
            break;
        }
        case "pending_review":{
            newStatus = "Processing"
            break;
        }
    }

    return newStatus
}

const mapExternalAccountType = (type: 'DDA' | 'SAV') => {
    switch(type) {
        case 'DDA':
            return 'Checking';
        case 'SAV':
            return 'Savings';
        default: 
            return 'Savings';
    }
}

/**Populate the external accounts */
export const populateExternalAccounts = (externalBankAccounts: Array<any>) => {
    const externalAccounts = externalBankAccounts
    let accountArray: Array<IExternalAccounts> = []
    for(let i = 0; i < externalAccounts.length; i = i + 1){
      let tempAccount: IExternalAccounts = {
        id: externalAccounts[i].externalAccountId,
        accountNickName: (externalAccounts[i].accountNickName && externalAccounts[i].accountNickName !== "") ? externalAccounts[i].accountNickName : externalAccounts[i].accountTitle,
        status: mapStatus(externalAccounts[i].status ? externalAccounts[i].status.toLowerCase() : ""),
        lastFour: externalAccounts[i].accountLast4DigitNumber,
        description: externalAccounts[i].bankName,
        routingNumber: externalAccounts[i].routingNumber,
        type: mapExternalAccountType(externalAccounts[i].accountType)
      }
      accountArray.push(tempAccount)
    }

    return accountArray  
}

/**
 * Counts the number of verified external accounts
 * returns true if more than one verified external account is found, otherwise it returns false
*/
export const checkExternalAccounts = (externalAccounts: Array<IExternalAccounts>) => {
    let verifiedAccountsFound = 0
    for(let i = 0; i < externalAccounts.length; i = i + 1){
        if(externalAccounts[i].status === "Linked"){
            verifiedAccountsFound++
        }
    }

    if(verifiedAccountsFound > 1){
        return true
    }
    
    return false
}