import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"

/**
 * Holds the account that the interest payments should be paid to
 */
export interface IInterestSettings {
    /*The account number that the interest payments should be applied to*/
    accountNumber: string
}

/**
 * Updates the interest settings, or the account that the interest should be paid to
 */
export const updateInterestSettings_API = (accountId: string, account: any) => {
    return axios.put(`${bffBaseUrl}/accounts/${accountId}/interestpayments`,
        account
    )
}