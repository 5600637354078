import { useEffect } from "react"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"

/**A custom use hook for the maturity settings flow, this hook is attached to the landing page and not the wrapper */
export const useMaturitySettingsFlow = () => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    const {type, message, setMessage} = useToastMessage()

    useScrollToTop()

    /**Resets the toast message if needed */
    useEffect(() => {
        if(message !== ""){
            setTimeout(() => {
                setMessage("")
            }, 4000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return { selectedAccount, isAccountDetailsLoaded, type, message }
}