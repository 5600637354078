import './MobileSideNavigation.scss'
import bankBlue from '../../../../assets/bankBlue.svg'
import bankGrey from '../../../../assets/bankGrey.svg'
import dollarCoinBlue from '../../../../assets/dollarCoinBlue.svg'
import dollarCoinGrey from '../../../../assets/dollarCoinGrey.svg'
import inboxBlue from '../../../../assets/inboxBlue.svg'
import inboxGrey from '../../../../assets/inboxGrey.svg'
import { useSideNavigation } from '../../../../useHooks/componentHooks/useSideNavigation/useSideNavigation'
import greyPerson from '../../../../assets/greyPerson.svg'
import bluePerson from '../../../../assets/bluePerson.svg'

/**
 * Renders the side navigation component for the mobile version.  Component is called from the SideNavigationWrapper
 * component and renders when the pixel width is less than 800 pixels.
 */
const MobileSideNavigation = () => {
    const {history, focus, setFocus} = useSideNavigation()
    
    return (
        <div className="Mobile-SideNavigation-Wrapper">
            <div className="Navigation-Line">
                <div className={focus === "Accounts" ? "Nav-Wrap Focused" : "Nav-Wrap"} onClick={() => {setFocus("Accounts"); history.push("/account-overview")}}>
                    <img src={focus === "Accounts" ? bankBlue : bankGrey} alt="Accounts Symbol" />
                    <span className="Nav-Text mobile-h6">Accounts</span>
                </div>
                <div className={focus === "Transfers" ? "Nav-Wrap Focused" : "Nav-Wrap"} onClick={() => {setFocus("Transfers"); history.push("/transfers")}}>
                    <img src={focus === "Transfers" ? dollarCoinBlue : dollarCoinGrey} alt="Transfers Icon" />
                    <span className="Nav-Text mobile-h6">Transfers</span>
                </div>
                <div className={focus === "Documents" ? "Nav-Wrap Focused" : "Nav-Wrap"} onClick={() => {setFocus("Documents"); history.push("/documents")}}>
                    <img src={focus === "Documents" ? inboxBlue : inboxGrey} alt="Documents Icon" />
                    <span className="Nav-Text mobile-h6">Documents</span>
                </div>
                <div className={focus === "Profile" ? "Nav-Wrap Focused" : "Nav-Wrap"} onClick={() => {setFocus("Profile"); history.push("/user/profile")}}>
                    <img style={{height: "16px", width: "16px"}} src={focus === "Profile" ? bluePerson : greyPerson} alt="Person Icon" />
                    <span className="Nav-Text mobile-h6">Profile</span>
                </div>
            </div>
        </div>
    )
}

export default MobileSideNavigation
