import './VerifyIdentity.scss'
import { IVerifyIdentityProps } from './interfaces/IVerifyIdentityProps'
import verifyMobile from '../../../../assets/verifyMobile.svg'
import verifyEmail from '../../../../assets/verifyEmail.svg'
import arrowRight from '../../../../assets/arrowRight.svg'
import { useVerifyIdentity } from '../../../../useHooks/componentHooks/useVerifyIdentity/useVerifyIdentity'
import Iconphone from '../../../../assets/Iconphone.svg'
import { IFactor } from '../../../../useHooks/componentHooks/useOTPModal/useOTPModal'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'

/**Renders the first page of the OTPModal component "Verifity Identity" */
const VerifyIdentity = (props: IVerifyIdentityProps) => {
    const { setStep, setVerifySelection, factorOptions, setChosenFactor, chosenFactor } = props
    const { handleVerifySelection, windowState, showLoader, message, type } = useVerifyIdentity(setStep)

    return (
        <div className="verify-identity-wrap" tabIndex={0}>
            {message !== "" && <ToastMessageDB message={message} type={type} />}
            <span className="title h4">Verify your identity</span>
            <span className="sub-title regular">To make sure it’s really you, we need to send you a verification code.</span>
            <div className="options-wrap">
                {factorOptions.map((factor: IFactor) => {
                    return (
                        <div key={factor.id} className={windowState !== "Mobile" ? "verify-option-box" : "verify-option-box mobile"} onClick={() => { setVerifySelection(factor.type); handleVerifySelection(factor); setChosenFactor(factor) }}>
                            <div className={windowState !== "Mobile" ? "left-side" : "left-side mobile"} >
                                {factor.type === "sms" &&
                                    <>
                                        <img src={verifyMobile} alt="verify with mobile" />
                                        <div className="data-stack">
                                            <span className="top-text boldSmall">Text code to:</span>
                                            <span className="bottom-text regular cls_mask">{factor.value}</span>
                                        </div>
                                    </>
                                }
                                {factor.type === "email" &&
                                    <>
                                        <img src={verifyEmail} alt="verify with email" />
                                        <div className="data-stack">
                                            <span className="top-text boldSmall">Email code to:</span>
                                            <span className="bottom-text regular cls_mask">{factor.value}</span>
                                        </div>
                                    </>
                                }
                                {factor.type === "call" &&
                                    <>
                                        <img className="image" src={Iconphone} alt="verify with phone call" />
                                        <div className="data-stack">
                                            <span className="top-text boldSmall">Voice call to:</span>
                                            <span className="bottom-text regular cls_mask">{factor.value}</span>
                                        </div>
                                    </>
                                }
                            </div>
                            {!showLoader ?
                                <img className="right-chevron" src={arrowRight} alt="right facing chevron" />
                                :
                                <>
                                    {factor.id === chosenFactor.id &&
                                        <div className="spinner">
                                            <div className="click-loader" />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default VerifyIdentity