import { createElement } from "react";
import { IRXInputProps } from "./interfaces/IRXInputProps"
import TextInput from "../TextInput/TextInput";
import NumberInput from "../NumberInput/NumberInput";
import PhoneInput from "../PhoneInput/PhoneInput";
import { useFormControl } from "../../Form/useForm/useForm";
import SSNInput from "../SSNInput/SSNInput";
import DateInput from "../DateInput/DateInput";
import SelectInput from "../SelectInput/SelectInput";
import EmailInput from "../EmailInput/EmailInput";
import TinInput from "../TinInput/TinInput";

/**RXInput will read the control input 'type' through the switch statement and then create an element of that input type */
const RXInput = (props: IRXInputProps) => {
  const { control, className, style } = props
  const [form, dispatchControlChanges] = useFormControl()

  const getComponentType = (type: string): any => {
    switch (type) {
      case 'input':
        return TextInput;
      case 'number':
        return NumberInput;
      case 'phone':
      case 'tel':
        return PhoneInput;
      case 'select':
        return SelectInput;
      case 'email':
        return EmailInput
      case 'date':
        return DateInput;
      case 'ssn':
        return SSNInput;
      case 'tin':
        return TinInput
      // // case 'numberString':
      //   // return NumberStringInputComponent;
      // // case 'range':
      // //     return RangeInputComponent;
      // case 'currency':
      //   // return CurrencyComponent;
      // case 'checkbox':
      //   // return CheckboxComponent;
      // case 'checkboxToggle':
      //   // return CheckboxToggleComponent;
      // case 'radio':
      //   // return RadioButtonComponent;
      // case 'switch':
      //   // return SlideToggleComponent;
      // case 'textarea':
      //   // return TextAreaComponent;
      // case 'file':
      //   // return FileUploadComponent;
      // case 'password':
      //   // return PasswordComponent;
      // case 'multi':
      //   // return MultiInputComponent;
      // case 'multiDataType':
      //   // return MultiDataTypeComponent;
      default:
        return '';
    }
  };

  return createElement(getComponentType(control.type), { control, form, dispatchControlChanges, className, style });

}

export default RXInput