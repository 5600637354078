import { IButtonProps } from './interfaces/IButtonProps';
import './Button.scss';
import { useRef, useState } from 'react';
import { useIsMounted } from '../../useHooks/useIsMounted/useIsMounted';
/**
 * Buttom Component
 * @param title - What text to display on button
 * @param className - (optional) gives className to button
 * @param sx - (optional) allows for inline styling
 * @param onClick - (optional) onClick function to be passed in button
 */
export const Button = (props: IButtonProps) => {
  const { title, onClick = () => { }, className, sx, isDisabled, id } = props;
  const { shouldShowLoader, handleOnClick, isDebouncingRef } = useLoadingButtonState(onClick, id);

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={`Button-Component ${className}`}
      style={sx}
      onClick={() => { if (!isDebouncingRef.current) { handleOnClick() } }}
    >
      {shouldShowLoader && <div className='button-loader'></div>}
      {title}
    </button>
  );
};

const DEFAULT_TIMER_TIMEOUT = 1000;
const LOCAL_STORAGE_TIMERS_NAME = 'API_TIMERS'

/**
 * All of the ids assosiated with the `LocalStorage.API_TIMERS`.
 */
export const TIMER_IDS = {
  MFA_VERIFY: 'MFA_VERIFY',
  MICROTRANSACTION_VERIFY: 'MICROTRANSACTION_VERIFY',
  MICROTRANSACTION_VALIDATE: 'MICROTRANSACTION_VALIDATE',
  DELETE_EXTERNAL: 'DELETE_EXTERNAL',
  NICKNAME_EXTERNAL: 'NICKNAME_EXTERNAL',
  USER_PASSWORD: "USER_PASSWORD",
  USER_EMAIL: "USER_EMAIL",
  USER_PHONE: "USER_PHONE",
  EDIT_PHONE: "EDIT_PHONE",
  USER_ADDRESS: "USER_ADDRESS",
  INTEREST_PAYMENTS: "INTEREST_PAYMENTS",
  MATURITY_CONFIRM: "MATURITY_CONFIRM",
  CANCEL_MATURITY_TRANSFER: "CANCEL_MATURITY_TRANSFER",
  BENEFICIARY_ALLOCATIONS: "BENEFICIARY_ALLOCATIONS",
  BENEFICIARY_EDIT: "BENEFICIARY_EDIT",
  BENEFICIARY_ADD: "BENEFICIARY_ADD",
  TRANSFER_CREATE: "TRANSFER_CREATE",
  TRANSFER_CANCEL: "TRANSFER_CANCEL",
  OWNER_ADD: "OWNER_ADD",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  YODLEE_LAUNCH_FASTLINK: "YODLEE_LAUNCH_FASTLINK",
  YODLEE_CREATE_ACCOUNT: "YODLEE_CREATE_ACCOUNT",
  INITIATE_MICRO_DEPOSITS: "INITIATE_MICRO_DEPOSITS"
} as const;

/**
 * Handles if a button needs a loader
 * @param onClick - The onClick of the button
 * @param id - The id of the button
 * @returns 
 */
const useLoadingButtonState = (onClick: () => Promise<any>, id?: keyof typeof TIMER_IDS) => {
  const timer = useRef(0);

  const [shouldShowLoader, setShouldShowLoader] = useState(false)
  const isDebouncingRef = useRef<boolean>(false)
  const isMounted = useIsMounted();
  /**
   * If there is an id, wraps the `onClick` function to handle the logging and state management of the `showShowLoader` state.
   */
  const handleOnClick = async () => {
    if (id) {
      isDebouncingRef.current = true
      timer.current = new Date().getTime();
      setLoaderStatus();
      await onClick();
      if (isMounted()) {
        setShouldShowLoader(false);
      }
      updateAPITimer(id, timer.current);
      isDebouncingRef.current = false
    }
    else {
      onClick();
    }

  }

  /**
   * Sets the `shouldShowLoader` value based on the timer's average time. 
   */
  const setLoaderStatus = () => {
    if (timer.current > 0 && id) {

      const timer = getLocalStorageAPITimer();
      const currentTimer = timer[id] || {
        avgTime: 0,
      };
      if (currentTimer.avgTime >= DEFAULT_TIMER_TIMEOUT || currentTimer.avgTime === 0) {
        setShouldShowLoader(true);
        return;
      }
    }

    setShouldShowLoader(false);
  }

  return {
    shouldShowLoader,
    handleOnClick,
    isDebouncingRef
  }
}

interface IAPITimer {
  numOfCalls: number;
  avgTime: number;
}

/**
 * Updates the API timer from local storage for the given id
 * @param id - The id of the timer to update
 * @param startTime - The start time of the timer
 */
const updateAPITimer = (id: string, startTime: number) => {
  const TOTAL_TIME = new Date().getTime() - startTime;
  const timers = getLocalStorageAPITimer();

  const currentTimer = timers[id] || {
    avgTime: 0,
    numOfCalls: 0
  };


  const NEW_NUM_OF_CALLS = currentTimer.numOfCalls + 1
  const TOTAL_PREV_TIME = currentTimer.avgTime * currentTimer.numOfCalls
  const NEW_AVG_TIME = (TOTAL_PREV_TIME + TOTAL_TIME) / NEW_NUM_OF_CALLS
  currentTimer.avgTime = NEW_AVG_TIME;
  currentTimer.numOfCalls = NEW_NUM_OF_CALLS;


  timers[id] = currentTimer;

  localStorage.setItem(LOCAL_STORAGE_TIMERS_NAME, JSON.stringify(timers));

}

/**
 * Returns the `LocalStorage.API_TIMERS`
 * @returns - The `LocalStorage.API_TIMERS`
 */
const getLocalStorageAPITimer = () => {
  let timers: { [key: string]: IAPITimer } = {};

  if (!localStorage.getItem(LOCAL_STORAGE_TIMERS_NAME)) {
    localStorage.setItem(LOCAL_STORAGE_TIMERS_NAME, '{}');
    timers = {};
  }
  else {
    timers = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TIMERS_NAME) as string)
  }

  return timers;
}

