

/**
 * 
 * @param date - a date in either string date format or type date
 * @returns string of date in mm/dd/yyyy
 */
export const convertDateTommddyyyy = (date: string | Date, shorthand: boolean = false) => {
  const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let newNewDate = date.toString();
  if (newNewDate.includes('/')) {
    const [month, day, year] = newNewDate.split('/');
    newNewDate = `${year}-${month}-${day}`
  }
  if (newNewDate.length === 10) {
    newNewDate = newNewDate + 'T00:00:00'
  }

  let [ yyyy, mm, dd ] = newNewDate.split('-')
  if(dd){
    dd = dd.split('T')[0]
  }

  if(shorthand){
    return mm + "/" + dd + "/" + yyyy
  }

  return monthArray[+mm - 1] + ' ' + dd + ', ' + yyyy;
};


/**
 * 
 * @param date - a date in either string date format or type date
 * @returns string of mm-dd-yy at time AM/PM
 */
export const convertDateWithTime = (date: string | Date, showTime = true) => {
  const newDate = new Date(date);
  const dd = String(newDate.getDate()).padStart(2, '0');
  const mm = String(newDate.getMonth() + 1).padStart(2, '0');
  const yy = String(newDate.getFullYear());
  const time = newDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  if (!showTime) {
    return mm + '/' + dd + '/' + yy
  }
  return mm + '/' + dd + '/' + yy + ' at ' + time;
};


/**Gets the users current date */
export const getUserCurrentDate = () => {
  let date = new Date(Date.now()).toLocaleDateString().split('/')
  let todaysDate = [date[2], date[0].padStart(2, '0'), date[1].padStart(2, "0")].join('-')

  return todaysDate
}

/**
 * @param date - a date in either string date format or type date
 * @returns string of month dd, yyyy at time AM/PM
 * ex: Jul 4, 1776 at 11:26 AM Pacific
 */
export const converDateWithTimeLongFormat = (date: string | Date, showTime = true) => {
  const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const pstDate = new Date(date).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})
  const [month, day, year] = pstDate.split("/")
  const time = new Date(date).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles", hour: 'numeric', minute: 'numeric', hour12: true})
  let formattedTime = time.split(",")[1]
  const [left, middle, right] = formattedTime.split(" ")
  formattedTime = left + middle + " " + right[0].toLowerCase() + "." + right[1].toLowerCase() + "."

  if(showTime){
    return `${monthArray[+month - 1 as number]} ${day}, ${year} at ${formattedTime} Pacific`
  }
  return `${monthArray[+month - 1 as number]} ${day}, ${year}`
}

/**
 * Checks to see if the date is sent in UTC format, returns true or false
 */
export const isUTCFormat = (date: string) => {
  for(let i = 0; i < date.length; i = i + 1){
    if(date[i] === 'T'){
      return true
    }
  }
  return false
}