import axios from "axios";
import { bffBaseUrl } from "../../utils/globalVariables";


/**Makes a patch API call to update the users email address */
export const patchEmail_API = (email: string) => {
    return axios.patch(`${bffBaseUrl}/customers/email`,
        {
            email: email,
            isDefaultEmailAddress: true
        },
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )

}