import { useTransferHistory } from '../../../../../useHooks/componentHooks/useTransferHistory/useTransferHistory'
import { ITransferHistoryProps } from '../interfaces/ITransferHistoryProps'
import './MobileTransferHistory.scss'
import emptyTransfers from '../../../../../assets/emptyTransfers.svg'
import { ITransferHistory } from '../../../../../api/transfers'
import { convertDateTommddyyyy } from '../../../../../utils/DateUtils'
import arrowRight from '../../../../../assets/arrowRight.svg'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import TransferDetailsPanel from '../../TransferDetailsPanel/TransferDetailsPanel'
import { PaginationComponent } from '../../../../../components/PaginationComponent/PaginationComponent'
// import redTrashCan from '../../../../../assets/redTrashCan.svg'
import redTrashCan from '../../../../../assets/Delete.png'
import CancelTransferModal from '../../CancelTransferModal/CancelTransferModal'

/**Renders the mobile version of the transfer history table found in the transfers tab */
const MobileTransferHistory = (props: ITransferHistoryProps) => {
    const { transferHistory, setTransferHistory, selectedTransfer, setSelectedTransfer, title, totalTransfers, handleClose, selectedTransferRef, transferErrored, setTransferErrored } = props
    const { startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow, setNumberOfItemsToShow, openModal, setOpenModal, openTransferDetails, setOpenTransferDetails,
            handleCloseModal } = useTransferHistory(transferHistory, setTransferHistory, totalTransfers, selectedTransfer)

    if (openTransferDetails) {
        return <TransferDetailsPanel
            selectedTransfer={selectedTransfer}
            setSelectedTransfer={setSelectedTransfer}
            setOpenTransferDetails={setOpenTransferDetails}
            setOpenModal={setOpenModal}
        />
    }
    return (
        <div className="mobile-history-card-wrap">
            <span className="title-text h3">{title}</span>
            {transferHistory.length > 0 ?
                <div className="table-wrap">
                    {title !== "Scheduled transfers" ?
                        <>
                            {transferHistory.slice(startIndex, endIndex).map((transfer: ITransferHistory, index: number) => {
                                return (
                                    <div key={index} className={selectedTransfer.id === transfer.id ? "transfer-row-wrap green" : "transfer-row-wrap"} onClick={() => { setSelectedTransfer(transfer); setOpenTransferDetails(true) }}>
                                        <div className="top">
                                            <span className="title-text regular">{transfer.transferFromAccount} - {transfer.fromAccountType} {transfer.originLastFour}</span>
                                            <div className="right">
                                                <span className="currency-text regular">{displayCurrency(+transfer.amount)}</span>
                                                <img src={arrowRight} alt="open details" />
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <span className="date-text regular">{convertDateTommddyyyy(new Date(transfer.transferDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        :
                        <>
                            {transferHistory.map((transfer: ITransferHistory, index: number) => {
                                return (
                                    <div data-testid={`transfer row ${index}`} key={index} className="transfer-row-wrap" onClick={() => { setSelectedTransfer(transfer); setOpenTransferDetails(true) }}>
                                        <div className="top">
                                            <span className="title-text regular">{transfer.transferFromAccount} - {transfer.fromAccountType} {transfer.originLastFour}</span>
                                            <div className="right">
                                                <span className="currency-text regular">{displayCurrency(+transfer.amount)}</span>
                                                <img src={arrowRight} alt="open details" />
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <span className="date-text regular">{convertDateTommddyyyy(new Date(transfer.transferDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
                                            {(transfer.status === "Scheduled" && !transfer.isInitialFund && transfer.isCancelAllowed) && <img src={redTrashCan} alt="red trashcan" onClick={(e: any) => { setOpenModal(true); setSelectedTransfer(transfer); e.stopPropagation() }} />}
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                    {totalTransfers > 10 && title !== "Scheduled transfers" &&
                        <div className="pagination-wrapper">
                            <PaginationComponent
                                type="Mobile"
                                itemType=""
                                totalItems={totalTransfers}
                                setStartIndex={setStartIndex}
                                setEndIndex={setEndIndex}
                                numberOfItemsToShow={numberOfItemsToShow}
                                setNumberOfItemsToShow={setNumberOfItemsToShow}
                            />
                        </div>
                    }
                    {openModal &&
                        <CancelTransferModal
                            transferHistory={transferHistory}
                            setTransferHistory={setTransferHistory}
                            selectedTransfer={selectedTransfer}
                            screen="Mobile"
                            setOpenModal={setOpenModal}
                            transferErrored={transferErrored}
                            setTransferErrored={setTransferErrored}
                            handleCloseModal={handleCloseModal}
                            handleClose={handleClose}
                        />
                    }
                </div>
                :
                <div className="empty-state-wrap">
                    <div className="bottom-border-line" />
                    <img className="icon" src={emptyTransfers} alt="no transfers" />
                    <div className="text-wrap">
                        <span className="text h4">No transfers yet</span>
                        <span className="text regular">Once you make a transfer, details will be shown here.</span>
                    </div>
                </div>
            }

        </div>
    )
}

export default MobileTransferHistory