import { setStartBeneficiaryFlow } from '../../../../../slices/selectedAccountSlice/selectedAccountSlice';
import { AccountDetailsNavBarExtras } from '../AccountDetailsNavBarExtras/AccountDetailsNavBarExtras';
import pieChart from '../../../../../assets/pieChart.svg';
import greyDocument from '../../../../../assets/greyDocument.svg';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import { useAppSelector } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
/**
 * The Mobile Account Details Navigation Bar.
 * @returns 
 */
export const AccountDetailsNavBar = (props: any) => {
  const { selectedAccount, dispatch, history, setOpenNavBarMenu } = props;
  const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

  return (
    <div className="details-nav-bar-wrap">
      {isAccountDetailsLoaded !== "Loading" ?
        <>
          {(selectedAccount.accountClosed || selectedAccount.pendingGraceClosure) ? (
            <div className="details-nav-bar">
              <div
                className="nav-option"
                onClick={() =>
                  history.push(`/account/${selectedAccount.id}/statements`, {
                    page: "Account details"
                  })
                }
              >
                <NavOption src={greyDocument} alt="Statements" title="Statements" />
              </div>
            </div>
          ) : (
            <div className="details-nav-bar">
              <div
                className="nav-option"
                onClick={() => {
                  history.push(`/account/${selectedAccount.id}/beneficiary`, {
                    page: "Account details"
                  });
                  dispatch(setStartBeneficiaryFlow(false));
                }}
              >
                <NavOption src={pieChart} alt="pie chart" title="Beneficiaries" />
              </div>
              <div className="divider-pipe" />
              <div
                className="nav-option"
                onClick={() =>
                  history.push(`/account/${selectedAccount.id}/statements`, {
                    page: "Account details"
                  })
                }
              >
                <NavOption src={greyDocument} alt="Statements" title="Statements" />
              </div>
              <div className="divider-pipe" />
              <AccountDetailsNavBarExtras
                setOpenNavBarMenu={setOpenNavBarMenu}
                selectedAccount={selectedAccount}
                history={history}
              />
            </div>
          )}
        </>
        :
        <div className="loading-wrapper">
          <div className="loading-box" />
          <div className="pipe" />
          <div className="loading-box" />
          <div className="pipe" />
          <div className="loading-box" />
          <div className="pipe" />
          <div className="loading-box" />
        </div>
      }
    </div>
  );
};

const MobileNavOption = (props: any) => {
  const { src, alt, title } = props;

  return (<div className="nav-option-stack">
    <img src={src} alt={alt} />
    <p data-testid={title} className="option-text small">{title}</p>
  </div>)
}

const DesktopNavOption = (props: any) => {
  const { src, alt, title } = props;

  return (<><img src={src} alt={alt} />
    <p data-testid={title} className="option-text">{title}</p></>)
}

export const NavOption = (props: any) => {
  const { windowState } = useWindowState();
  const { src, alt, title } = props;

  if (windowState === 'Mobile') {
    return <MobileNavOption
      src={src}
      alt={alt}
      title={title}
    />
  }

  return <DesktopNavOption
    src={src}
    alt={alt}
    title={title}
  />
}