import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import './DesktopVerificationLetter.scss'
import blueDocument from '../../../assets/blueDocument.svg'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { generateVerificationPDF } from '../../../utils/generateVerificationPDF'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'

/**Renders the desktop version of the Verification page */
const DesktopVerificationLetter = () => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    const { setToastMessage, resetToast } = useToastMessage()

    const handleGeneratePDF = async () => {
        const response = await generateVerificationPDF(selectedAccount, userInformation)

        if(response === null){
            setToastMessage("ERROR", "Unable to download. Please try again later")
            resetToast()
        }
    }

    return(
        <div className="Landing-Wrapper">
            <div className="card-wrapper account-header">
                <AccountHeader returnMessage='Account Settings' setDisplayDetails={false}/>
            </div>
            <div className="card-wrapper verification-letter">
                <div className="container">
                    <div className="desktop">
                        {isAccountDetailsLoaded !== "Loading" ?
                            <div className="content-wrapper">
                                <div className="title-lockup">
                                    <h1>Account verification letter</h1>
                                    <p>Download a verification letter with your account details.</p>
                                </div>
                                <div className="grey-line"></div>
                                <div className="content">
                                    <div className="letter-wrap">
                                        <img src={blueDocument} alt="document" />
                                        <span className="link-text regular" onClick={() => handleGeneratePDF()}>{selectedAccount.accountTitle} - Account Verification Letter (PDF)</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="loading-box" />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopVerificationLetter