import './AccountLocked.scss'
import lockedAccount from '../../../../assets/lockedAccount.svg'
import { oktaAuth } from '../../../../Routes'
import { getLogoutUri } from '../../../../utils/globalVariables'
import { useEffect } from 'react'
import { IAccountLockedProps } from './interfaces/IAccountLockedProps'

/**Renders the account locked page in the OTP step up flow, this renders after five consecutive failed attempts */
const AccountLocked = (props: IAccountLockedProps) => {
    const { emailRef } = props

    /**Logs the user out */
    const oktaLogout = async () =>  {
        oktaAuth.signOut({ postLogoutRedirectUri: getLogoutUri })
    }

    /**Logs out the user on unmount */
    useEffect(() => {
        return () => {oktaLogout()}
    }, [])

    return (
        <div className="account-locked-wrapper">
            <img className="lock-image" src={lockedAccount} alt="account locked" />
            <span className="title h4">Account locked</span>
            <span className="sub-text regular">You've reached the maximum number of attempts allowed.</span>
            <span className="sub-text bottom regular">Please check your email <strong>{emailRef.current}</strong> and follow the steps to unlock to your account.</span>
            <button className="Button btn-primary" onClick={() => oktaLogout()}>Close</button>
        </div>
    )
}

export default AccountLocked