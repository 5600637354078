import { COLOR_ARRAY } from '../../../../../../utils/globalVariables'
import './DisplayBeneficiaryRow.scss'
import { IDisplayBeneficiaryRowProps } from './interfaces/IDisplayBeneficiaryRowProps'
import { useDisplayMobileBeneficiaryRow } from '../../../../../../useHooks/componentHooks/useDisplayMobileBeneficiaryRow/useDisplayMobileBeneficiaryRow'

/**
 * The component displays the rows of the beneficiary array.  That is, as the DesktopBeneficiaryEdit component maps the beneficiary array
 * this component is displaying each element of the array.
 * @param props index: the index of the beneficiary array, setEditDetails: determines whether to show the beneficiary edit details page
 *              beneficiary: the beneficiary being adjusted, onChangeHandler: passing the updated information for the particular beneficiary
 */
const DisplayBeneficiaryRow = (props: IDisplayBeneficiaryRowProps) => {
    const { index, beneficiary, onChangeHandler } = props
    /**just borrowing hte inputRef from the mobile beneficiary row custom hook*/
    const { inputRef } = useDisplayMobileBeneficiaryRow()

    return (
        <div className="Beneficiary-Row-Wrapper">
            <div className="Details-Wrapper">
                <div className="Dot" style={{ backgroundColor: COLOR_ARRAY[index] }} />
                <div className="Name-Allocation-Buttons-Wrapper">
                    <div className="Name-Allocation-Wrap">
                        <div className="name-wrap">
                            <span className="Member-Name regular cls_mask">{`${beneficiary.name}`}</span>

                            <span className="Person-Text">{beneficiary.type}</span>
                        </div>
                        <div className={beneficiary.percentage === 0 ? "Error-Box" : "Input-Box"} onClick={() => inputRef.current.focus()}>
                            <input className="Box regular box-text" type="text" value={beneficiary.percentage} onChange={(e) => onChangeHandler(e, index)} ref={inputRef}
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}
                            />
                            <span className="Percentage regular">%</span>
                        </div>
                    </div>
                </div>
            </div>
            {beneficiary.percentage === 0 && <div className="Allocation-Error-Message boldSmall">Allocation must be at least 1%</div>}
        </div>
    )
}

export default DisplayBeneficiaryRow