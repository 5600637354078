import { useBeneficiaryReview } from '../../useHooks/componentHooks/useBeneficiaryReview/useBeneficiaryReview'
import './BeneficiaryReview.scss'
import { IBeneficiaryReviewProps } from './interfaces/IBeneficiaryReviewProps'
import arrowLeftBlue from '../../assets/arrowLeftBlue.svg'
import { Button, TIMER_IDS } from '../Button/Button'
import { convertDateTommddyyyy } from '../../utils/DateUtils'

/**Renders the Add Beneficiary Review page in Add A New Beneficiary Flow */
const BeneficiaryReview = (props: IBeneficiaryReviewProps) => {
    const { newBeneficiary, handleOnCancel, handleOnNext, handleOnFinish, form, handleOnBack, selectedAccount } = props
    const { handleContinueClick, windowState } = useBeneficiaryReview(form, handleOnFinish, newBeneficiary, handleOnNext)

    return (
        <div className="beneficiary-review-page-wrap">

            <div className="title-lockup">
                <div className="pre-header"><div className="content">Add Beneficiary</div><div className="step-count">{selectedAccount.beneficiaries.length > 0 ? "Step 3 of 4" : "Step 3 of 3"}</div></div>
                <h2>Review beneficiary</h2>
            </div>
            <div className={windowState !== "Mobile" ? "card-wrap" : "card-wrap mobile"}>
                <div className="beneficiary-review-left-side">
                    <h2 className="info-header">Beneficiary</h2>
                    <div className="data-wrap">
                        <span className="data-text boldSmall">Name</span>
                        <span className="data-value regular cls_mask">{newBeneficiary.name}</span>
                    </div>
                    {(newBeneficiary.type === "Trust" && newBeneficiary.trustEstablishedDate !== "") &&
                        <div className="data-wrap">
                            <span className="data-text boldSmall">Date Trust Established</span>
                            <span className="data-value regular cls_mask">{convertDateTommddyyyy(newBeneficiary.trustEstablishedDate)}</span>
                        </div>
                    }
                    {newBeneficiary.tin !== '' && <div className="data-wrap">
                        <span className="data-text boldSmall">{newBeneficiary.type === "Person" ? "Social Security Number" : "Tax Identification Number"}</span>
                        <span className="data-value regular cls_mask">{newBeneficiary.tin}</span>
                    </div>}
                    <div className="data-wrap">
                        {(newBeneficiary.address1 !== "" || newBeneficiary.address2 !== "" || newBeneficiary.city !== "" || newBeneficiary.state !== "" || newBeneficiary.zipCode !== "") && 
                            <span className="data-text boldSmall">Address</span>
                        }
                        {(newBeneficiary.address1 !== "" || newBeneficiary.address2 !== "") && 
                            <span className="data-value regular cls_mask">{newBeneficiary.address1}&nbsp;{newBeneficiary.address2}</span>
                        }
                        <div className="city-state-zip-row">
                            {newBeneficiary.city !== "" && <span className="data-value regular cls_mask">{newBeneficiary.city},&nbsp;</span>}
                            {newBeneficiary.state !== "" && <span className="data-value regular cls_mask">{newBeneficiary.state}&nbsp;</span>}
                            {newBeneficiary.zipCode !== "" && <span className="data-value regular cls_mask">{newBeneficiary.zipCode}</span>}
                        </div>
                        {/* <span className="data-value regular cls_mask">{newBeneficiary.city},&nbsp;{newBeneficiary.state}&nbsp;{newBeneficiary.zipCode}</span> */}
                        {(newBeneficiary.address1 !== "" || newBeneficiary.address2 !== "" || newBeneficiary.city !== "" || newBeneficiary.state !== "" || newBeneficiary.zipCode !== "") &&
                            <span className="data-value regular cls_mask">United States</span>
                        }
                    </div>

                </div>
                {windowState === "Mobile" && <div className="bottom-border" />}
                <div className="right-side">
                    {!((newBeneficiary.type === 'Person' && newBeneficiary.email === '' && newBeneficiary.phoneNumber === '' && newBeneficiary.relationship === '')
                        || (newBeneficiary.type === 'Trust' && newBeneficiary.phoneNumber === '')
                        || (newBeneficiary.type === "Charity" && newBeneficiary.phoneNumber === '' && newBeneficiary.email === ''))
                        && <h2 className="info-header">Additional Information</h2>}
                    {(newBeneficiary.type === "Person" || newBeneficiary.type === "Charity") && (newBeneficiary.email !== '') &&
                        <div className="data-wrap">
                            <span className="data-text boldSmall">Email</span>
                            <span className="data-value regular cls_mask">{newBeneficiary.email}</span>
                        </div>
                    }
                    {(newBeneficiary.phoneNumber !== '') && <div className="data-wrap">
                        <span className="data-text boldSmall">Phone Number</span>
                        <span className="data-value regular cls_mask">{newBeneficiary.phoneNumber}</span>
                    </div>}
                    {newBeneficiary.type === "Person" && newBeneficiary.relationship !== '' &&
                        <div className="data-wrap">
                            <span className="data-text boldSmall">Relationship</span>
                            <span className="data-value regular cls_mask">{newBeneficiary.relationship}</span>
                        </div>
                    }
                </div>
            </div>
            {windowState !== "Mobile" ?
                <div className="control-bar">
                    <div className="btn-back" onClick={() => handleOnBack()}>
                        <img src={arrowLeftBlue} alt="back arrow" />
                        <span className="text blue regular">Back</span>
                    </div>
                    <div className="btn-group">
                        <Button
                            className="Button btn-secondary"
                            onClick={() => handleOnCancel()}
                            title="Cancel"
                        />
                        <Button
                            className="Button btn-primary"
                            onClick={handleContinueClick}
                            title="Continue"
                            id={TIMER_IDS.BENEFICIARY_ADD}
                        />
                    </div>
                </div>
                :
                <Button
                    className="Button btn-primary"
                    onClick={handleContinueClick}
                    title="Continue"
                    sx={{ marginBottom: "24px" }}
                    id={TIMER_IDS.BENEFICIARY_ADD}
                />
            }
        </div>
    )
}

export default BeneficiaryReview