import { useHistory } from "react-router"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useBumpRate } from "../../useHooks/pageHooks/useBumpRate"
import DesktopBumpRate from "./DesktopBumpRate/DesktopBumpRate"
import MobileBumpRate from "./MobileBumpRate/MobileBumpRate"

/**Displays the bump rate page */
const BumpRateWrapper = () => {
    const { windowState, selectedAccount, bumpActivated, setBumpActivated, bumpConfirmed, setBumpConfirmed } = useBumpRate()
    const history = useHistory()

    if(selectedAccount.id !== "" && (selectedAccount.accountClosed || selectedAccount.pendingGraceClosure)){
        history.push(`/accounts/${selectedAccount.id}`)
    }

    return (
        <div>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopBumpRate 
                            selectedAccount={selectedAccount}
                            bumpActivated={bumpActivated}
                            setBumpActivated={setBumpActivated}
                            bumpConfirmed={bumpConfirmed} 
                            setBumpConfirmed={setBumpConfirmed}
                        />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopBumpRate 
                            selectedAccount={selectedAccount}
                            bumpActivated={bumpActivated}
                            setBumpActivated={setBumpActivated}
                            bumpConfirmed={bumpConfirmed} 
                            setBumpConfirmed={setBumpConfirmed}
                        />
                    </HybridLayout>
                }
                </>   
                :
                <MobileBumpRate 
                    selectedAccount={selectedAccount}
                    bumpActivated={bumpActivated}
                    setBumpActivated={setBumpActivated}
                    bumpConfirmed={bumpConfirmed} 
                    setBumpConfirmed={setBumpConfirmed}            
                />
            }
        </div>
    )
}

export default BumpRateWrapper