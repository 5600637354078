import { IControl } from "../Form/Control/IControl.interface";
import { IFormContext, TControlDispatch } from "../useForm/useForm";

/**A custom use hook holding the logic for the Number Input */
export const useNumberInput = (form: IFormContext, control: IControl, dispatchControlChanges: TControlDispatch) => {

    /**Handles the onKeyDown event for the Number Input */
    const handleKeyDown = (e: any) => {
        (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.') && e.preventDefault()
        if (((e.target.value.length + 1) > e.target.maxLength) && (e.key !== "Backspace" && e.key !== 'Delete' && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== 'Tab')) {
            e.preventDefault()
        }
    }

    /**Handles the onChange event for the Number Input */
    const onChangeHandler = (e: any) => {
        form.setValue(control.name, e.target.value)
        /**If there are error than run validations on the control */
        if (control.errors.length) {
            form.runValidations(control.name)
        }
        dispatchControlChanges()
    }

    /**Handles the onBlur event for the Number Input */
    const handleOnBlur = () => {
        form.runValidations(control.name)
        dispatchControlChanges()
    }

    return { onChangeHandler, handleOnBlur, handleKeyDown }
}