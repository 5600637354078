import { useState } from 'react';
import {
  ISelectDropdownProps,
  ISelectOptions
} from './interfaces/ISelectDropdownProps';
import './SelectDropdown.scss';
/**
 * Select drop down component
 * @param selectOptions a list of options with a label and value
 * @param label label for the select input
 * @param onChange an onChange event for select
 * @param className (optional) a className for the component
 * @param name (optional) name for select tag
 * @param defaultValue (optiional) the value that the select is defaulted to
 * @param labelInside (optional) whether the label goes inside the dropdown
 */
export const SelectDropdown = (props: ISelectDropdownProps) => {
  const {
    selectOptions,
    name,
    className,
    label,
    onChange,
    defaultValue,
    labelInside,
    isPagination
  } = props;
  const [dropDownOpen, setDropDownOpen] = useState(false);

  return (
    <div
      className={
        labelInside
          ? `select-dropdown-label-inside ${className}`
          : `select-dropdown ${className}`
      }
      style={{border: labelInside && dropDownOpen ? '1px solid #0A5EC0' : ''}}
    >
      <label className='label' htmlFor={name}>{label}</label>
      <select
        name={name}
        className={`select ${dropDownOpen ? `open` : `closed`}`}
        style={{backgroundColor: 'transparent'}}
        onChange={onChange}
        onClick={() => setDropDownOpen(!dropDownOpen)}
        onBlur={() => dropDownOpen === true && setDropDownOpen(!dropDownOpen)}
        onKeyUp={(e: any) =>
          e.keyCode === 27 &&
          dropDownOpen === true &&
          setDropDownOpen(!dropDownOpen)
        }
        defaultValue={defaultValue}
      >
        {selectOptions.map((e: ISelectOptions) => {
          return (
            <option key={e.label} value={isPagination ? `${e.label}`: `${e.label} ${e.value}`} >
              {isPagination ? `${e.label}` : `${e.label} ... ${e.value}`}
            </option>
          );
        })}
      </select>
    </div>
  );
};
