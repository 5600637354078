import './DesktopUserProfileEditAddress.scss'
import { IDesktopUserProfileEditAddressProps } from './interfaces/IDesktopUserProfileEditAddressProps'
import arrowLeft from '../../../../assets/arrowLeft.svg'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import redTrashcan from '../../../../assets/newRedTrashCan.svg'
import ModifyAddressModal from '../ModifyAddressModal/ModifyAddressModal'
import { useModifyAddresses } from '../../../../useHooks/componentHooks/useModifyAddresses/useModifyAddresses'
import { FormProvider } from '../../../../Form/useForm/useForm'
import greyInfo from '../../../../assets/greyInfo.svg'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import { useLoquateOnForm, AddressInputWrapper } from '../../../../shared/LoquateAutoComplete/LoquateAutoComplete'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'
import { useUserProfileContext } from '../../Context/useUserProfileContext'



/**
 * Renders the component displaying the add / edit / remove address from user profile page, contact information tab
 */
const DesktopUserProfileEditAddress = (props: IDesktopUserProfileEditAddressProps) => {
    const { setOpenEditPage, index, addressAction, setAddressAction } = props
    const { userInformation, form, dispatchForm, showCheckmark, setShowCheckmark, showTooltip, setShowTooltip, formFilled, handleOnSave, openMailingModal, setOpenMailingModal,
        purpose, handleRemoveAddress, setFormFilled } = useModifyAddresses(index, addressAction, setOpenEditPage)

    const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm);
    const { usingSuggestion, setUsingSuggestion } = useUserProfileContext()

    const handleSubmit = async () => {
        try {
            if (addressAction !== "EditMain") {
                await isAddressValid(false);
            }
            else {
                await isAddressValid();

            }
            await handleOnSave();
            return Promise.resolve(true)
        }
        catch (err) {

        }
        // handleOnSave();
    }

    return (
        <FormProvider form={form} dispatch={dispatchForm}>
            <div className="desktop-edit-address-wrapper">
                <div className="header-page-wrap">
                    <div className="header-wrapper">
                        <div className="top-line">
                            <img className="back-arrow" src={arrowLeft} alt="back arrow" onClick={() => { setOpenEditPage(""); form.resetForm(); dispatchForm(); setFormFilled(false) }} />
                            <span className="origin-text regular" onClick={() => { setOpenEditPage(""); form.resetForm(); dispatchForm(); setFormFilled(false) }}>Contact information</span>
                        </div>
                    </div>
                </div>
                <div className="card-page-wrapper">
                    <div className="card-wrapper">
                        {addressAction === "EditMain" &&
                            <span className="address-text h2">Change home street address</span>
                        }
                        {addressAction === "Edit" &&
                            <span className="address-text h2">Change address</span>
                        }
                        {addressAction === "Add" &&
                            <>
                            <span className="address-text h2">Add new address</span>
                            <span className="sub-text regular">Fields marked with an <span className="required-star">*</span> are required.</span>
                            </>
                        }
                        <div className="bottom-border" />
                        {addressAction !== "Add" &&
                            <>
                                <span className="address-text boldSmall">Current address</span>
                                <span className="address-text regular cls_mask">{userInformation.contactInformation.addresses[index]?.addressLine1}</span>
                                <span className="address-text regular cls_mask">{userInformation.contactInformation.addresses[index]?.addressLine2}</span>
                                <span className="address-text regular cls_mask">{userInformation.contactInformation.addresses[index]?.city}, {userInformation.contactInformation.addresses[index]?.state} {userInformation.contactInformation.addresses[index]?.zipCode}</span>
                                <div className="bottom-border" />
                                <span className="address-text boldLarge">New address</span>
                                <span className="required-sub-text regular">Fields marked with an <span className="required-star regular">*</span> are required.</span>
                            </>
                        }
                        {usingSuggestion && <span className={`required-text small ${form.controls.addressLine1.errors.length && 'error'} ${addressAction === "Add" && 'no-margin'}`}>Search for an address&nbsp;<span className="red-star">*</span></span>}
                        <AddressInputWrapper
                            onlyResidental
                            onSelection={onLoquateSelection}
                            control={form.controls.addressLine1}
                            form={form}
                            usingSuggestion={usingSuggestion} 
                            setUsingSuggestion={setUsingSuggestion}
                            dispatchForm={dispatchForm}
                        />
                        <div className="checkbox-tooltip-wrap">
                            {(userInformation.contactInformation.addresses.length > 1 || addressAction === "Add") &&
                                <>
                                    <Checkbox
                                        showCheckmark={showCheckmark}
                                        setShowCheckmark={setShowCheckmark}
                                    />
                                    <span className="default-text regular">Set as default mailing address</span>
                                    {/* <img className="tooltip-icon" src={greyInfo} alt="tooltip" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} /> */}
                                </>
                            }
                            {/* {showTooltip &&
                                <div className="tooltip-message-wrap">
                                    <Tooltip anchorPoint={"top-left"} width="250px" headerMessage="Default mailing address" bodyMessage="All of your automated banking correspondence, such as tax documents, will be sent to your mailing address." />
                                </div>
                            } */}
                        </div>
                        <div className="bottom-border" />
                        <div className="bottom-row-wrap">
                            {(addressAction !== "EditMain" && addressAction !== "Add") ?
                                <div className="left-side" onClick={() => handleRemoveAddress()}>
                                    <img src={redTrashcan} alt="red trashcan" />
                                    <span className="remove-text regular">Remove address</span>
                                </div>
                                :
                                <div></div>
                            }
                            <div className="button-wrap">
                                <button className="Button btn-secondary" onClick={() => { setOpenEditPage(""); setAddressAction(""); form.resetForm(); dispatchForm(); setFormFilled(false) }}>Cancel</button>
                                <Button
                                    title="Save"
                                    isDisabled={!formFilled}
                                    className={formFilled ? "Button btn-primary" : "Button btn-primary inactive"}
                                    id={TIMER_IDS.USER_ADDRESS}
                                    onClick={handleSubmit}
                                />
                            </div >
                        </div >
                    </div >
                </div >
                {openMailingModal &&
                    <ModifyAddressModal
                        form={form}
                        index={index}
                        purpose={purpose}
                        setOpenEditPage={setOpenEditPage}
                        setOpenMailingModal={setOpenMailingModal}
                        dispatchForm={dispatchForm}
                    />
                }
            </div >
        </FormProvider >
    )
}

export default DesktopUserProfileEditAddress
