import { emailFormatValidator } from './emailFormat';
import { isRequiredValidator } from './isRequired';
import { maxDateValidator } from './maxDate';
import { maxLengthValidator } from './maxLength';
import { minDateValidator } from './minDate';
import { minLengthValidator } from './minLength';

export const VALIDATORS:{[key:string]: any} = {
  isRequired: isRequiredValidator,
  minLength: minLengthValidator,
  maxLength: maxLengthValidator,
  emailFormat: emailFormatValidator,
  minDate: minDateValidator,
  maxDate: maxDateValidator
}