import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"

/**Makes an API call to post a new co-owner to a particular account */
export const postNewCoOwner_API = (newOwner: any) => {
    return axios.post(`${bffBaseUrl}/co-owners`,
        {
            accountId: newOwner.accountId,
            prefix: newOwner.prefix,
            firstName: newOwner.firstName,
            middleName: newOwner.middleName,
            lastName: newOwner.lastName,
            dateOfBirth: newOwner.dateOfBirth,
            citizenship: newOwner.citizenship,
            residencyStatus: newOwner.residencyStatus,
            addresses: newOwner.addresses,
            emails: newOwner.emails,
            phones: newOwner.phones,
            identificationDetails: newOwner.identificationDetails
        }
    )
}