import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { IVerifiedAccount } from '../../../../../api/getYodleeVerifiedAccounts';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
import { useLinkExternalAccount } from '../../../Context/useLinkExternalAccount';
import { ICreateYodleeAccount, ICreateYodleeAccountsBody, YodleeCreationStatus, postCreateYodleeAccount_API } from '../../../../../api/postCreateYodleeAccount';
import { addExternalAccount } from '../../../../../slices/userInformation/userInformationSlice';
import { usePostYodleeFlow } from '../../../Context/useHooks/usePostYodleeFlow/usePostYodleeFlow.hook';
import { YODLEE_STATUS } from '../../../Context/useHooks/usePostYodleeFlow/usePostYodleeFlow.types';
import { LINKING_STATUS } from '../../../../../api/models/verified-accounts';

//TODO move me to shared.
interface KSG<T = any> {
  [key: string]: T
}

/**
 * Initializes the `selected` state for `useYodleeSelection`
 * @param accounts - The `verifiedAccounts` from Yodlee.
 * @returns - The `externalAccountId` as keys with a value of `false`.
 */
const initSelected = (accounts: Array<IVerifiedAccount>) => {
  const SELECTED_OBJECT: KSG<boolean> = {};

  accounts.forEach(e => SELECTED_OBJECT[e.externalAccountId] = false);
  return SELECTED_OBJECT;
}

/**
 * Returns the max number of external accounts that a user can add.
 * @param externalAccountsLength - The count of currently linked external accounts.
 * @returns - The max number of extneral accounts that s user can add.
 */
const initMaxAmountToSelect = (externalAccountsLength: number) => {
  //MAKE THIS A CONFIG
  const MAX_AMOUNTS = 3;

  return MAX_AMOUNTS - externalAccountsLength;
}

interface IYodleeSelectionProvider {
  verifiedAccounts: Array<IVerifiedAccount>;
  selected: KSG<boolean>;
  maxAmountToSelect: number;
  currentAmountSelected: number;
  toggleSelection: (id: string) => boolean;
  canUserContinue: () => boolean;
  getInputType: () => 'radio' | 'checkbox';
  isSelected: (id: string) => boolean;
  postNewAccounts: () => void;
  getSelectedCopy: () => any
}

export const YodleeSelectionContext = createContext<IYodleeSelectionProvider | null>(null)

export const YodleeSelectionProvider = (props: { children: JSX.Element }) => {

  const { postYodleeFlow, setStep } = useLinkExternalAccount()
  const { verifiedAccounts, _setResponseStatus, setAccountsLinked, convertIVerifiedAccountToICreateYodleeAccount } = postYodleeFlow;
  const [selected, _setSelected] = useState<KSG<boolean>>(initSelected(verifiedAccounts));

  const externalAccountsLength = useAppSelector((state: RootState) => state.userInformation.externalAccounts.length)

  const [maxAmountToSelect] = useState(initMaxAmountToSelect(externalAccountsLength));
  const [currentAmountSelected, setCurrentAmountSelected] = useState(0);

  const dispatch = useAppDispatch()
  const hasNeedsReviewRef = useRef<boolean>(false)

  /**
   * Creates the accounts that have been selected.
   */
  const postNewAccounts = async () => {
    const SELECTED_ACCOUNTS = getSelectedAccounts();
    const BODY: ICreateYodleeAccountsBody = {
      providerAccountId: postYodleeFlow.providerAccountId,
      accounts: SELECTED_ACCOUNTS
      // accounts: SELECTED_ACCOUNTS.map(e => e.externalAccountId)
    }

    // try {
      const RESPONSE = await postCreateYodleeAccount_API(BODY, { factorId: '1234', stepUpToken: "12345", mfaToken: "12345", apiVersion: "2.0.0" });
      if(RESPONSE){
        for(let i = 0; i < RESPONSE.length; i = i + 1){
          if(RESPONSE[i].status !== YodleeCreationStatus.FAILED && RESPONSE[i].status !== YodleeCreationStatus.FRAUD){
            const payload = {
              id: RESPONSE[i].externalAccountId,
              accountNickName: RESPONSE[i].bankName,
              status: RESPONSE[i].status.toLowerCase() === "verified" ? "Linked" : "Processing",
              lastFour: RESPONSE[i].accountNumberLastFourDigit,
              description: RESPONSE[i].bankName,
              routingNumber: SELECTED_ACCOUNTS[i].routingNumber,
              type: SELECTED_ACCOUNTS[i].accountType,
              balance: SELECTED_ACCOUNTS[i].balance
            }
            dispatch(addExternalAccount(payload))
          }
        }
        postYodleeFlow.postNewAccounts(RESPONSE)
      }
    // }
    // catch (err) {
    //   console.error('YodleeSelectionProvider -> postNewAccounts failed.');
    // }
  }

  /**
   * Returns the accounts that have been selected.
   * @returns - All of the accounts that are selected.
   */
  const getSelectedAccounts = () => {
    const SELECTED_ACCOUNTS: Array<IVerifiedAccount> = []
    verifiedAccounts.forEach((e) => {
      if (isSelected(e.externalAccountId)) {
        SELECTED_ACCOUNTS.push(e);
      }
    })

    return SELECTED_ACCOUNTS;
  }
  /** 
 * Sets `selected` for a single select
 * @param id - The `externalAccountId`
  */
  const setSelectedForSingle = (id: string) => {
    const NEW_SELECTED = getSelectedCopy();
    const KEYS = Object.keys(NEW_SELECTED);
    KEYS.forEach(e => NEW_SELECTED[e] = false);
    NEW_SELECTED[id] = !NEW_SELECTED[id];
    _setSelected(NEW_SELECTED);
    setCurrentAmountSelected(1);

    return NEW_SELECTED[id];
  }

  /**
   * Gets a copy of the `selected` state.
   */
  const getSelectedCopy = (): KSG<boolean> => JSON.parse(JSON.stringify(selected));

  /**
 * Sets `selected` for a multi select
 * @param id - The `externalAccountId`
 */
  const getAmountOfAccountsSelected = (selectedAccounts: KSG<boolean>) => {
    const KEYS = Object.values(selectedAccounts);
    return KEYS.reduce((t, e) => e ? t + 1 : t, 0);
  }

  /**
   * Sets `selected` for a multi select
   * @param id - The `externalAccountId`
  */
  const setSelectedForMulti = (id: string) => {
    const NEW_SELECTED = getSelectedCopy();
    NEW_SELECTED[id] = !NEW_SELECTED[id];
    _setSelected(NEW_SELECTED);
    setCurrentAmountSelected(getAmountOfAccountsSelected(NEW_SELECTED));

    return NEW_SELECTED[id];
  }

  /**
   * Toggles a selection for the given id.
   * @param id - The `externalAccountId`
   */
  const toggleSelection = (id: string) => {
    if (maxAmountToSelect === 1) {
      return setSelectedForSingle(id);
    }
    else {
      return setSelectedForMulti(id)
    }
  }

  /**
   * Returns the type of input needed for selection
   */
  const getInputType = () => maxAmountToSelect === 1 ? 'radio' : 'checkbox';

  /**
   * Returns if the account is selected to be added.
   * @param id - The `externalAccountId` of the account.
   * @returns - If the account is selected.
   */
  const isSelected = (id: string) => selected[id]

  /**
   * Can the user continue to the next screen.
   * 
   * Checks if the `currentAmountSelected` selected > 0 && <= `maxAmountToSelect` 
   */
  const canUserContinue = () => currentAmountSelected > 0 && currentAmountSelected <= maxAmountToSelect;


  return <YodleeSelectionContext.Provider value={{
    verifiedAccounts,
    selected,
    maxAmountToSelect,
    currentAmountSelected,
    toggleSelection,
    canUserContinue,
    getInputType,
    isSelected,
    postNewAccounts,
    getSelectedCopy
  }}>{props.children}</YodleeSelectionContext.Provider>
}

export const useYodleeSelection = () => {
  const context = useContext(YodleeSelectionContext);

  if (!context) {
    throw new Error(`Can't use useYodleeSelection outside of YodleeSelectionProvider.`)
  }

  return context;
}