import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';


const RE2 = RegExp
/**
 * The regex for the urls and name of the pages for that URL
 */
const PAGE_CONFIG = [
  {
    pageName: 'account beneficiary',
    regEx: new RE2(/^(\/account\/.+\/beneficiary)$/)
  },
  {
    pageName: 'account details',
    regEx: new RE2(/^(\/accounts\/)/)
  },
  {
    pageName: 'account ownership',
    regEx: new RE2(/^(\/account\/.+\/setting)$/)
  },
  {
    pageName: 'account maturity',
    regEx: new RE2(/^(\/account\/.+\/maturity)$/)
  },
  {
    pageName: 'account statements',
    regEx: new RE2(/^(\/account\/.+\/statements)$/)
  },
  {
    pageName: 'account interest',
    regEx: new RE2(/^(\/account\/.+\/interest)$/)
  },
  {
    pageName: 'account overview',
    regEx: new RE2(/^(\/accounts)$/)
  },
  {
    pageName: 'account overview',
    regEx: new RE2(/^(\/account-overview)$/)
  },
  {
    pageName: 'account settings',
    regEx: new RE2(/^(\/user\/.+\/settings)$/)
  },
  {
    pageName: 'account transactions',
    regEx: new RE2(/^(\/account\/.+\/transactions)$/)
  },
  {
    pageName: 'account bump rate',
    regEx: new RE2(/^(\/account\/.+\/bumprate)$/)
  },
  {
    pageName: 'account early withdrawal',
    regEx: new RE2(/^(\/account\/.+\/earlywithdrawal)$/)
  },
  {
    pageName: 'account verification letter',
    regEx: new RE2(/^(\/account\/.+\/verificationletter)$/)
  },
  {
    pageName: 'transfers',
    regEx: new RE2(/^(\/transfers)$/)
  },
  {
    pageName: 'account grace period',
    regEx: new RE2(/^(\/account\/.+\/graceperiod)$/)
  },
  {
    pageName: 'contact us',
    regEx: new RE2(/^(\/knowledge-base)$/)
  },
  {
    pageName: 'manage external account',
    regEx: new RE2(/^(\/linkexternalaccount)$/)
  },
  {
    pageName: 'document center',
    regEx: new RE2(/^(\/documents)$/)
  },
  {
    pageName: 'account overview',
    regEx: new RE2(/^\/$/)
  },
]

/**
 * Returns the name of the page that matches the pathname.
 * @param pathname - The pathname
 * @returns - The name of the page that matches the pathname.
 */
const getPageName = (pathname: string) => {
  for (let i = 0; i < PAGE_CONFIG.length; i++) {
    if (PAGE_CONFIG[i].regEx.test(pathname)) {
      return PAGE_CONFIG[i].pageName;
    }
  }

  return '';
}

const getPageURL = (location: any) => {
  return `${window.location.origin}${location.pathname}`.replace(/[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/, ':accountID');
}

/**
 * Calls the adobe satellite when a route changes.
 * 
 * Doesn't work for user profile. That is loaded into the component themselves.
 */
export const useSendSatelliteOnRouteChange = () => {
  const location = useLocation();
  let w: any = window;
  w.digitalData = {
    pageInfo: {
      pageURL: getPageURL(location),
      pageName: getPageName(location.pathname)
    }
  }
  useLayoutEffect(() => {
    w.digitalData.pageInfo.pageURL = getPageURL(location);
    w.digitalData.pageInfo.pageName = getPageName(location.pathname);

    try {
      w._satellite?.track?.('pageload');
      if (process.env.NODE_ENV !== "test") {
        console.log('satellite called')
      }
    }
    catch (err) {
      console.log('satellite error');
    }

  }, [location])
}