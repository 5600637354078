import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { MobileLayout } from "../../layouts/MobileLayout/MobileLayout"
import { LinkExternalAccountContextProvider } from "./Context/useLinkExternalAccount"
import DesktopLinkExternalAccount from "./DesktopLinkExternalAccount/DesktopLinkExternalAccount"
import MobileLinkExternalAccount from "./MobileLinkExternalAccount/MobileLinkExternalAccount"

/**Renders the desktop or mobile version of the LinkExternalAccount, used with micro transactions to verify new customer external accounts */
const LinkExternalAccountWrapper = () => {
    const { windowState } = useWindowState()

    return (
        <>
            <LinkExternalAccountContextProvider>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopLinkExternalAccount />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopLinkExternalAccount />
                    </HybridLayout>
                }
                </>
                :
                <MobileLayout>
                    <MobileLinkExternalAccount />
                </MobileLayout>
            }
            </LinkExternalAccountContextProvider>
        </>
    )
}

export default LinkExternalAccountWrapper