import { IMobileDocumentsDisplayHeaderpProps } from './interfaces/IMobileDocumentsDisplayHeaderProps'
import './MobileDocumentsDisplayHeader.scss'
import arrowLeft from '../../../../../assets/arrowLeft.svg'

/**
 * The component displays the Documents Center Mobile version of the account statements
 */
const MobileDocumentsDisplayHeader = (props: IMobileDocumentsDisplayHeaderpProps) => {
    const { selectedPage, setSelectedPage } = props

    return(
        <div className="mobile-documents-display-header">
            <div className="return-line">
                <img className="return-arrow" src={arrowLeft} alt="return" onClick={() => setSelectedPage("")}/>
                <span className="return-text small" onClick={() => setSelectedPage("")}>Document center</span>
            </div>
            <span className="page-title semiboldLarge">{selectedPage}</span>
        </div>
    )
}

export default MobileDocumentsDisplayHeader