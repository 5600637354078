import axios from "axios";
import { bffBaseUrl } from "../../utils/globalVariables";
// import { mockSuccessfulApiResponse } from "../../utils/mockApiResponse";
// import { IPhone } from "./interfaces";

/**
 * API call for put user phone
 * @param customerId 
 * @param phone updated phone object
 */
export const patchPhone_API = (id: string, phoneNumber: string, isDefault: boolean) => {
    let [left, middle, right] = phoneNumber.split("-") 
    let modifiedNumber = left + middle + right
    return axios.patch(`${bffBaseUrl}/customers/phone-numbers`,
        {
            id: id,
            phoneNumber: modifiedNumber,
            isDefaultPhoneNumber: isDefault
        },
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )
}

/**
 * API call for post user phone
 * @param customerId 
 * @param phone new phone object
 */
export const postPhone_API = (phoneNumber: string) => {
    let [left, middle, right] = phoneNumber.split("-") 
    let modifiedNumber = left + middle + right
    return axios.post(`${bffBaseUrl}/customers/phone-numbers`,
        {
            // id: "942cd5a6-159a-11ee-be56-0242ac120018",
            phoneNumber: modifiedNumber,
        },
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )
}

/**
 * API call for delete user phone
 * @param customerId 
 * @param phone phone object to be deleted
 */
export const deletePhone_API = (id: string) => {
    return axios.delete(`${bffBaseUrl}/customers/phone-numbers/${id}`,
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )
}

