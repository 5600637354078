import { ICardProps } from './interfaces/ICardProps'; 
import './Card.scss'; 

/** 
 * A card wrapper to be used for both desktop and mobile versions.
 * Example syntax for using this component...
 * <Card width={"352px"} height={"500px"} showTopBorder={true} topBorderColor={"var(--secondaryDarkBlue)"} />
 */ 
export const Card = (props: ICardProps ) => {  
  const { showTopBorder } = props

  return ( 
    <>
      {showTopBorder && 
        <div className="Card-Top-Border">
          <div className="Top-Border" style={{borderTop: `6px solid ${props.topBorderColor}`}}/>
          <div className="Card-Wrapper"
            style={{height:`${props.height}`}} >
              {props.children}
          </div>
        </div>
      }
      {!showTopBorder &&
        <div className="Card-Wrapper"
          style={{height:`${props.height}`}}>
            {props.children}
        </div>
      }
    </>
  ); 
}; 
