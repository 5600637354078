/**The file holds all API calls and mock data used for transfers */

import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"

export interface ITransferHistory {
    /**The transfer ID */
    id: string

    /**The date the transfer occured on */
    transferDate: string

    /**The account the transferoriginated from */
    transferFromAccount: string

    /**The last four of the orignation account number */
    originLastFour: string

    /**The designation account name of the transfer */
    transferDesignationAccount: string

    /**the last four of the designatin account number */
    designationLastFour: string

    /**The amount of the transfer */
    amount: number

    /**The status of the transfer */
    status: "Scheduled" | ""

    // /**A memo that can be attached to each transaction */
    // memo: string

    /**The account type of the from account */
    fromAccountType: "Savings" | ""

    /**the order reference ID */
    orderReference: string

    /**Whether or not the transfer is the initial funding */
    isInitialFund: boolean

    /**Whether or not the scheduled transfer is allowed to be canceled */
    isCancelAllowed: boolean
}

/**An empty transfer object for initializing purposes */
export const emptyTransfer: ITransferHistory = {
    id: "",
    transferDate: "",
    transferFromAccount: "",
    originLastFour: "",
    transferDesignationAccount: "",
    designationLastFour: "",
    amount: 0,
    status: "",
    // memo: "",
    fromAccountType: "",
    orderReference: "",
    isInitialFund: false,
    isCancelAllowed: true
}


/**Makes a post call to create a transfer */
export const postNewTransfer_API = (payload: any) => {
    if (payload.transferDate !== "") {
        return axios.post(`${bffBaseUrl}/transfers`, {
            transferAmount: payload.transferAmount,
            transferType: payload.transferType,
            transferDate: payload.transferDate,
            fromAccountDetails: { accountId: payload.fromAccountDetails.accountId, accountNickName: payload.fromAccountDetails.accountNickName },
            toAccountDetails: { accountId: payload.toAccountDetails.accountId, accountNickName: payload.toAccountDetails.accountNickName }
        })
    }
    return axios.post(`${bffBaseUrl}/transfers`, {
        transferAmount: payload.transferAmount,
        transferType: payload.transferType,
        fromAccountDetails: { accountId: payload.fromAccountDetails.accountId, accountNickName: payload.fromAccountDetails.accountNickName },
        toAccountDetails: { accountId: payload.toAccountDetails.accountId, accountNickName: payload.toAccountDetails.accountNickName }
    })
}

/**Removes a scheduled transfer */
export const removeTransfer_API = (id: string) => {
    return axios.put(`${bffBaseUrl}/transfers/cancel`, {
        orderId: id
    })
}

/**gets transfer history */
export const getTransferHistory = (offset: number, size: number, sort: string, orderDirection: string) => {
    return axios.get(`${bffBaseUrl}/transfers?offset=${offset}&size=${size}&sort=${sort}&orderBy=${orderDirection}`)
}

/**gets scheduled transfer history */
export const getScheduledTransferHistory = (sort: string, orderDirection: string) => {
    return axios.get(`${bffBaseUrl}/transfers/scheduled?orderBy=${sort}&orderDirection=${orderDirection}`)
}