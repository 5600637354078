import { createContext, useContext, useEffect, useState } from 'react';
import { useTransactionHistoryContext } from '../../../context/TransactionHistoryContext';
import { IDateFilter } from '../../../context/TransactionHistory.types';

type TBoxSelection = 'To' | 'From' | ''
const CustomDateFilterContext = createContext<any>(null);


export const CustomDateFilterProvider = (props: { children: JSX.Element }) => {
  const { dateFilter: { toDate, fromDate }, selectedFromDropDown } = useTransactionHistoryContext();

  const [localToDate, _setLocalToDate] = useState<IDateFilter>(toDate.ref.get())
  const setLocalToDate = (value: IDateFilter) => {
    _setLocalToDate(value);
    toDate.ref.set(value);
  }

  const [localFromDate, _setLocalFromDate] = useState(fromDate.ref.get())
  const setLocalFromDate = (value: IDateFilter) => {
    _setLocalFromDate(value);
    fromDate.ref.set(value);
  }

  const [boxSelection, setBoxSelection] = useState<TBoxSelection>('From');
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    if (selectedFromDropDown) {
      setBoxSelection("From")
      setIsDatePickerOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <CustomDateFilterContext.Provider value={{
    boxSelection,
    setBoxSelection,
    isDatePickerOpen,
    setIsDatePickerOpen,
    localToDate,
    setLocalToDate,
    localFromDate,
    setLocalFromDate
  }}>
    {props.children}
  </CustomDateFilterContext.Provider>

}

export const useCustomDateFilterContext = () => {
  const context = useContext(CustomDateFilterContext);

  if (!context) {
    throw new Error(`Can't use useCustomDateFilterContext outside of CustomDateFilterProvider`)
  }

  return context;
}