import { ITooltipProps } from './interfaces/ITooltipProps'
import './Tooltip.scss'
import topAnchor from '../../assets/topAnchor.svg'

/**
 * The tooltip component is used to display tooltips 
 * It takes in the anchor point and the message
*/
const Tooltip = (props: ITooltipProps) => {
    const {anchorPoint, bodyMessage, headerMessage, width} = props

    return(
        <div className={`tooltip-wrapper ${anchorPoint}`} style={{width: `${width}`}}>
            {anchorPoint === "top-left" &&
            <>
                <img className="shadow" src={topAnchor} alt="anchor point" />
                <div className="tooltip-message-wrapper">
                    {headerMessage !== "" && <span className="header-message boldSmall">{headerMessage}</span>}
                    <div>
                        <span className="tooltip-message small">{bodyMessage}</span>
                    </div>
                </div>
            </>
            }
            {anchorPoint === "top-right" &&
            <>
                <img className="shadow top-right" src={topAnchor} alt="anchor point" />
                <div className="tooltip-message-wrapper">
                    {headerMessage !== "" && <span className="header-message boldSmall">{headerMessage}</span>}
                    <div>
                        <span className="tooltip-message small">{bodyMessage}</span>
                    </div>
                </div>
            </>
            }
            {anchorPoint === "bottom-left" &&
            <>
                <div className="tooltip-message-wrapper">
                    {headerMessage !== "" && <span className="header-message boldSmall">{headerMessage}</span>}
                    <div>
                        <span className="tooltip-message small">{bodyMessage}</span>
                    </div>
                </div>            
                <img className="shadow bottom-left" src={topAnchor} alt="anchor point" />
            </>
            }
            {anchorPoint === "bottom-right" &&
            <></>
            }
        </div>
    )
}

export default Tooltip