import { useEffect, useState } from "react"
import { IProduct } from "../../../api/getAccountInformation"
import { getEventPath } from "../../../utils/getEventPath"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { getProductsAsync } from "../../../slices/accountInformation/accountInformationSlice"

/**custom use hook for selecting a new CD */
export const useCDSelection = (chosenTerm: any, setChosenTerm: any) => {
    const [enableContinue, setEnableContinue] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const gotProductList = useAppSelector((state: RootState) => state.accountInformation.gotProductList)

    const dispatch = useAppDispatch()


    /**the use effect checks to see if the getProducts API has failed, if it has we will try to recall it */
    useEffect(() => {
        if (gotProductList === "Failed") {
            getProductList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gotProductList])

    /**API calls to get the product list */
    const getProductList = async () => {
        try {
            dispatch(getProductsAsync())
        }
        catch {
            console.log("RETRY ATTEMPT TO GET PRODUCTS FAILED")
        }
    }

    /**Check to see if the chosen term is defined on render to enable the continue button */
    useEffect(() => {
        if (chosenTerm) {
            setEnableContinue(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeHandler = (cd: IProduct) => {
        setChosenTerm(cd)
        setEnableContinue(true)
    }

    /**Lock the scrollbar when the tooltip modal is open, unlock when it is closed */
    useEffect(() => {
        if (showTooltip) {
            document.body.style.overflow = "hidden"
        }
        else {
            document.body.style.overflow = "auto"
        }
    }, [showTooltip])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if (Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("tooltip-modal-wrap")) === -1) {
                document.body.style.overflow = "auto"
                setShowTooltip(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { enableContinue, setEnableContinue, showModal, setShowModal, onChangeHandler, showTooltip, setShowTooltip, gotProductList }
}