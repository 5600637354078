import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { MobileLayout } from "../../layouts/MobileLayout/MobileLayout"
import { useTransfers } from "../../useHooks/pageHooks/useTransfers"
import DesktopTransfers from "./DesktopTransfers/DesktopTransfers"
import MobileTransfers from "./MobileTransfers/MobileTransfers"

/**The page wrapper for transfers, will render the desktop / hybrid / or mobiel version */
const TransfersPageWrapper = () => {
    const { windowState, selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions,
            transferAmount, setTransferAmount, transferDate, setTransferDate, topCardIndex, setTopCardIndex, transferHistory, setTransferHistory,
            selectedTransfer, setSelectedTransfer, scheduledTransfers, setScheduledTransfers, totalTransfers, handleClose, selectedTransferRef,
            gotTransferHistory, gotScheduledTransfers, transferErrored, setTransferErrored } = useTransfers()

    return(
        <>
        {windowState !== "Mobile" ?
            <>
            {windowState === "Desktop" ?
                <DesktopLayout>
                    <DesktopTransfers 
                        selectedFromAccount={selectedFromAccount}
                        setSelectedFromAccount={setSelectedFromAccount}
                        selectedToAccount={selectedToAccount}
                        setSelectedToAccount={setSelectedToAccount}
                        selectionOptions={selectionOptions}
                        transferAmount={transferAmount}
                        setTransferAmount={setTransferAmount}
                        transferDate={transferDate}
                        setTransferDate={setTransferDate}
                        topCardIndex={topCardIndex} 
                        setTopCardIndex={setTopCardIndex}
                        transferHistory={transferHistory}
                        setTransferHistory={setTransferHistory}
                        selectedTransfer={selectedTransfer}
                        setSelectedTransfer={setSelectedTransfer}
                        scheduledTransfers={scheduledTransfers}
                        setScheduledTransfers={setScheduledTransfers}
                        totalTransfers={totalTransfers}
                        handleClose={handleClose}
                        selectedTransferRef={selectedTransferRef}
                        gotTransferHistory={gotTransferHistory}
                        gotScheduledTransfers={gotScheduledTransfers}
                        transferErrored={transferErrored}
                        setTransferErrored={setTransferErrored}
                    />
                </DesktopLayout>
                :
                <HybridLayout>
                    <DesktopTransfers 
                        selectedFromAccount={selectedFromAccount}
                        setSelectedFromAccount={setSelectedFromAccount}
                        selectedToAccount={selectedToAccount}
                        setSelectedToAccount={setSelectedToAccount} 
                        selectionOptions={selectionOptions} 
                        transferAmount={transferAmount}
                        setTransferAmount={setTransferAmount}
                        transferDate={transferDate}
                        setTransferDate={setTransferDate}
                        topCardIndex={topCardIndex}
                        setTopCardIndex={setTopCardIndex}
                        transferHistory={transferHistory}
                        setTransferHistory={setTransferHistory}
                        selectedTransfer={selectedTransfer}
                        setSelectedTransfer={setSelectedTransfer}
                        scheduledTransfers={scheduledTransfers}
                        setScheduledTransfers={setScheduledTransfers}
                        totalTransfers={totalTransfers}
                        handleClose={handleClose}
                        selectedTransferRef={selectedTransferRef}
                        gotTransferHistory={gotTransferHistory}
                        gotScheduledTransfers={gotScheduledTransfers}
                        transferErrored={transferErrored}
                        setTransferErrored={setTransferErrored}
                    />
                </HybridLayout>
            }
            </>
            :
            <MobileLayout>
                <MobileTransfers 
                    selectedFromAccount={selectedFromAccount}
                    setSelectedFromAccount={setSelectedFromAccount}
                    selectedToAccount={selectedToAccount}
                    setSelectedToAccount={setSelectedToAccount}   
                    selectionOptions={selectionOptions}   
                    transferAmount={transferAmount}
                    setTransferAmount={setTransferAmount}
                    transferDate={transferDate}
                    setTransferDate={setTransferDate}
                    topCardIndex={topCardIndex}
                    setTopCardIndex={setTopCardIndex}
                    transferHistory={transferHistory}
                    setTransferHistory={setTransferHistory}
                    selectedTransfer={selectedTransfer}
                    setSelectedTransfer={setSelectedTransfer}
                    scheduledTransfers={scheduledTransfers}
                    setScheduledTransfers={setScheduledTransfers}
                    totalTransfers={totalTransfers}
                    handleClose={handleClose}
                    selectedTransferRef={selectedTransferRef}
                    gotTransferHistory={gotTransferHistory}
                    gotScheduledTransfers={gotScheduledTransfers}
                    transferErrored={transferErrored}
                    setTransferErrored={setTransferErrored}
                />
            </MobileLayout>
        }
        </>
    )
}

export default TransfersPageWrapper