import { useHistory } from 'react-router-dom'
import './MobileLinkExternalAccount.scss'
import { useLinkExternalAccount } from '../Context/useLinkExternalAccount'
import EnterBankDetails from '../components/EnterBankDetails/EnterBankDetails'
import AccountVerification from '../components/AccountVerification/AccountVerification'
import arrowLeftBlue from '../../../assets/arrowLeftBlue.svg'
import LinkPicker from '../components/LinkPicker/LinkPicker'
import Confirmation from '../components/Confirmation/Confirmation'
import YodleePrimingScreen from '../components/YodleePrimingScreen/YodleePrimingScreen'
import YodleeWrapperPage from '../components/YodleeWrapperPage/YodleeWrapperPage'
import YodleeResultsPage from '../components/YodleeResultsPage/YodleeResultsPage'
import { deleteYodleeToken_API } from '../../../api/User/yodleeServices'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { YODLEE_STATUS } from '../Context/useHooks/usePostYodleeFlow/usePostYodleeFlow.types'

/**REnders the mobile version of the link extenral account flow */
const MobileLinkExternalAccount = () => {
    const history = useHistory()
    const { step, setStep, showVerified, postYodleeFlow } = useLinkExternalAccount()
    const { responseStatus } = postYodleeFlow
    const { clearToast } = useToastMessage()

    /**The switch statement controls rendering the correct page based on the step number */
    const setPage = () => {
        switch(step){
            case 1:
                return <EnterBankDetails />
            case 2:
                return <LinkPicker />
            case 3:
                return <AccountVerification />
            case 4:
                return <Confirmation />
            case 5:
                return <YodleePrimingScreen />
            case 6:
                return <YodleeWrapperPage />
            case 7:
                return <YodleeResultsPage />
            default: return <EnterBankDetails />
        }
    }

    /**handles the cancel button onclick event */
    const handleCancelClick = async () => {
        // try{
        //     await deleteYodleeToken_API()
        // }
        // catch{
        //     console.log("Failed to delete")
        // }
        history.push("/user/profile/external-accounts")
    }
    
    return (
        <div className="moible-link-account-wrapper">
            {step === 1 &&
                <div className="header-wrapper">
                    {!showVerified && <span className="cancel-text regular" onClick={() => history.push("/user/profile/external-accounts")}>Cancel</span>}
                </div>
            }
            {step === 2 &&
                <div className="header-wrapper">
                    {!showVerified && <span className="cancel-text regular" onClick={() => history.push("/user/profile/external-accounts")}>Cancel</span>}
                </div>            
            }
            {step === 3 &&
                <div className="nav-wrap">
                    <div className="left-side">
                        <img data-testid="back-chevron" className="nav-image" src={arrowLeftBlue} alt="blue left facing chevron" onClick={() => history.goBack()} />
                        <span className="nav-text regular" onClick={() => setStep(2)}>Back</span>
                    </div>
                    <span className="cancel-text regular" onClick={() => history.push("/user/profile/external-accounts")}>Cancel</span>
                </div>
            }
            {step === 4 &&
                <div className="header-wrapper" />
            }
            {step === 5 &&
                <div className="nav-wrap">
                    <div className="left-side" onClick={() => {clearToast(); setStep(2)}}>
                        <img data-testid="back-chevron" className="nav-image" src={arrowLeftBlue} alt="blue left facing chevron" />
                        <span className="nav-text regular">Back</span>
                    </div>
                    <span className="cancel-text regular" onClick={() => {clearToast(); history.push("/user/profile/external-accounts")}}>Cancel</span>
                </div>
            }
            {step === 6 && 
                <div className="header-wrapper">
                    {!showVerified && <span className="cancel-text regular" onClick={() => handleCancelClick()}>Cancel</span>}
                </div>            
            }
            {step === 7 &&
                <div className="header-wrapper">
                    {responseStatus === YODLEE_STATUS.ERROR && <span className="cancel-text regular" onClick={() => handleCancelClick()}>Cancel</span>}
                </div>
            }
            <div className="mobile-link-account-card">
                {setPage()}
            </div>
        </div>
    )
}

export default MobileLinkExternalAccount