import { useTransferFunds } from '../../../../../useHooks/componentHooks/useTransferFunds/useTransferFunds'
import { IMobileTransferFundsProps } from './interfaces/IMobileTransferFundsProps'
import './MobileTransferFunds.scss'
import arrowLeftBlue from '../../../../../assets/arrowLeftBlue.svg'
import { RadioButton } from '../../../../../components/RadioButton/RadioButton'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import bankBlue from "../../../../../assets/bankBlue.svg"
import blueSettings from '../../../../../assets/blueSettings.svg'
import TransfersDropDown from '../../../../../components/TransfersDropDown/TransfersDropDown'
import { convertDateTommddyyyy } from '../../../../../utils/DateUtils'
import { dateLongHand } from '../../../../../utils/dateLongHand'
import MaxAccountsModal from '../../../../UserProfile/components/MaxAccountsModal/MaxAccountsModal'

/**Renders the mobile version of transfer funds from the maturity settings flow when a fund transfer is initiated */
const MobileTransferFunds = (props: IMobileTransferFundsProps) => {
    const { setManageFunds, setShowTermChange, firstStepCompleted, setFirstStepCompleted, setTransferType, transferAccount, setTransferAccount } = props
    const { selectedAccount, handleRadioOnClick, addFunds, withdrawFunds, handleAmountOnChange, linkedAccounts,
        history, handleOnContinue, enableContinue, enableFirstStep, handleCancel, transferAmount, isAmountErrored, addInputError,
        withdrawInputError, handleOnKeyDown, showLimitModal, setShowLimitModal, handleLinkExternalAccount } = useTransferFunds(setShowTermChange, setManageFunds, setTransferType, transferAccount, setTransferAccount)


    return (
        <div className="mobile-fund-transfer-wrap">
            {showLimitModal &&
                <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
            }
            {!firstStepCompleted ?
                <div className="step-header">
                    <span className="cancel-button regular" onClick={() => handleCancel()}>Cancel</span>
                </div>
                :
                <div className="step-header two">
                    <div className="left-side" onClick={() => setFirstStepCompleted(false)}>
                        <img src={arrowLeftBlue} alt="back button" />
                        <span className="back-text regular">Back</span>
                    </div>
                    <span className="cancel-button regular" onClick={() => { setFirstStepCompleted(false); handleCancel() }}>Cancel</span>
                </div>
            }
            {!firstStepCompleted ?
                <div className="step-card-wrap">
                    <div className="header-wrap">
                        <span className="header-text small">Add or withdraw funds at renewal</span>
                        <span className="sub-header-text h4">Would you like to add or withdraw funds?</span>
                    </div>
                    <div className="radio-button-wrap">
                        <div className={addFunds ? "radio-button-selection selected" : "radio-button-selection"}>
                            <RadioButton
                                value={"Add funds"}
                                name="transfer funds"
                                className="radio"
                                onChange={() => handleRadioOnClick("Add")}
                                checked={addFunds}
                                textAlign="Right"
                            ><span>Add funds</span>
                            </RadioButton>
                        </div>
                        <div className={withdrawFunds ? "radio-button-selection selected" : "radio-button-selection"}>
                            <RadioButton
                                value={"Withdraw funds"}
                                name="transfer funds"
                                className="radio"
                                onChange={() => handleRadioOnClick("Withdraw")}
                                checked={withdrawFunds}
                                textAlign="Right"
                            ><span>Withdraw funds</span>
                            </RadioButton>
                        </div>
                    </div>
                    <span className="funds-message small cls_mask">Funds will be transferred on {dateLongHand(convertDateTommddyyyy(selectedAccount.maturityDate))}, when your CD renews</span>
                </div>
                :
                <div className="step-card-wrap">
                    <div className="header-wrap">
                        <span className="header-text small">Add or withdraw funds at renewal</span>
                        <span className="sub-header-text h4">Please enter your fund transfer details</span>
                    </div>
                    <div className="transfer-input-wrapper">
                        <label className="label-wrap">
                            <div className="label-text-wrap">
                                <span className="amount-text regular">Amount</span>
                                <span className="require-star">*</span>
                            </div>
                            <div className={isAmountErrored ? "input-box-wrap error" : "input-box-wrap"}>
                                {transferAmount && transferAmount > 0 && <span className="regular">$</span>}
                                <input
                                    className="input-box regular cls_mask"
                                    type="number"
                                    placeholder="$0.00"
                                    value={transferAmount as number > 0 ? transferAmount as number : "Enter Amount"}
                                    onChange={(e: any) => handleAmountOnChange(e)}
                                    onKeyDown={(e: any) => handleOnKeyDown(e)}
                                />
                            </div>
                            {withdrawFunds && !withdrawInputError &&
                                <span className="available-balance small cls_mask">You can withdraw up to: {displayCurrency(selectedAccount.balance - 100000)}</span>
                            }
                            {(addFunds && !addInputError) &&
                                <span className="available-balance small cls_mask">You can add up to: {displayCurrency(1000000 - selectedAccount.balance)}</span>
                            }
                            {addInputError && <span className="error-text mobileSemiboldError cls_mask">Entered Value Exceeds {displayCurrency(1000000 - selectedAccount.balance)}</span>}
                            {withdrawInputError && <span className="error-text mobileSemiboldError cls_mask">Entered Value Exceeds: {displayCurrency(selectedAccount.balance - 100000)}</span>}
                        </label>
                    </div>
                    <div className="dropdown-wrapper">
                        <TransfersDropDown
                            label={addFunds ? 'Transfer from' : 'Transfer to'}
                            isRequired={false}
                            defaultValue="Select an Account"
                            selectedOption={transferAccount}
                            setSelectedOption={setTransferAccount}
                            selectOptions={linkedAccounts}
                        />
                    </div>
                    <div className="options-wrap">
                        <div className="option no-border">
                            <img src={bankBlue} alt="link external account" onClick={() => handleLinkExternalAccount()} />
                            <span className="option-text regular" onClick={() => handleLinkExternalAccount()}>Link external account</span>
                        </div>
                        <div className="option" onClick={() => history.push("/user/profile/external-accounts/external-accounts")}>
                            <img src={blueSettings} alt="manage external accounts" />
                            <span className="option-text regular">Manage external accounts</span>
                        </div>
                    </div>
                </div>
            }
            {!firstStepCompleted ?
                <div className="button-wrap">
                    <button className={enableFirstStep ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableFirstStep} onClick={() => setFirstStepCompleted(true)}>Continue</button>
                </div>
                :
                <div className="button-wrap">
                    <button className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableContinue} onClick={() => { handleOnContinue(); setFirstStepCompleted(false) }}>Continue</button>
                </div>
            }
        </div>
    )
}

export default MobileTransferFunds