import OverviewMiddle from './components/OverviewMiddle/OverviewMiddle'
import AccountOverviewDropDown from './components/AccountOverviewDropDown/AccountOverviewDropDown'
import AccountOverviewTop from './components/AccountOverviewTop/AccountOverviewTop'
import './MobileOverview.scss'
import { IMobileOverviewProps } from './interfaces/IMobileOverviewProps'

/**
 * Renders the Mobile version of the Overview Component.  It is divided into
 * three portions, the OveriewTop, Middle, and DropDown on the bottom.
 */
const MobileOverview = (props: IMobileOverviewProps) => {
    const { selectedAccount, showDropDown, setShowDropDown, initialClick, setInitialClick, show, setShow, onTransactionDetails} = props
    
    return (
        <div className="mobile-overview-wrapper">
            <AccountOverviewTop selectedAccount={selectedAccount}/> 
            {!onTransactionDetails && 
            <>
                <OverviewMiddle 
                    selectedAccount={selectedAccount}
                />
                <AccountOverviewDropDown 
                    selectedAccount={selectedAccount}
                    showDropDown={showDropDown}
                    setShowDropDown={setShowDropDown}
                    initialClick={initialClick}
                    setInitialClick={setInitialClick}
                    show={show}
                    setShow={setShow}                                    
                />
            </>
            }
        </div>
    )
}

export default MobileOverview