import { ITransfersDropDownProps } from './interfaces/ITransfersDropDownProps'
import './TransfersDropDown.scss'
import downArrow from '../../assets/downArrow.svg'
import { useTransfersDropDown } from '../../useHooks/componentHooks/useTransfersDropDown/useTransfersDropDown'
import blueCheckmark from '../../assets/blueCheckmark.svg'
import { displayCurrency } from '../../utils/displayCurrency'
import redExclamationCircle from "../../assets/redExclamationCircle.svg"
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { createID } from '../../utils/createId'

/**Renders the dropdown selection box for Transfers */
const TransfersDropDown = React.memo((props: ITransfersDropDownProps) => {
    const {
        label,
        isRequired,
        className = "",
        defaultValue,
        removeOption = {},
        selectedOption,
        testId = "",
        setSelectedOption } = props

    const {
        openDropDown,
        setOpenDropDown,
        gotExternalAccounts
    } = useTransfersDropDown()

    const linkedExternalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)

    /**Builds the accounts for the select dropdowns */
    const buildSelectionOptions = () => {
        let tempAccounts: Array<{ title: string, type: string, lastFour: string, accountID: string, balance: number }> = []
        let addAccount = { id: createID(), title: "CREDIT ONE ACCOUNTS", type: "" as "", lastFour: "", accountID: "", balance: 0 }
        tempAccounts.push(addAccount)
        for (let i = 0; i < accountInformation.length; i = i + 1) {
            if (removeOption.id === accountInformation[i].id) {
                continue;
            }
            if (accountInformation[i].accountType === "Savings" && !accountInformation[i].accountClosed) {
                let addAccount = {
                    id: accountInformation[i].id,
                    title: accountInformation[i].accountTitle,
                    type: "Savings" as "Savings",
                    lastFour: accountInformation[i].accountNumber,
                    accountID: accountInformation[i].id,
                    balance: accountInformation[i].balance,
                    accountType: 'Internal'
                }
                tempAccounts.push(addAccount)
            }
        }
        addAccount = { id: createID(), title: "EXTERNAL ACCOUNTS", type: "" as "", lastFour: "", accountID: "", balance: 0 }
        tempAccounts.push(addAccount)
        for (let i = 0; i < linkedExternalAccounts.length; i = i + 1) {
            if (removeOption.id === linkedExternalAccounts[i].id) {
                continue;
            }
            if (['Active', 'Verified', 'Linked'].includes(linkedExternalAccounts[i].status)) {
                let addAccount = {
                    id: linkedExternalAccounts[i].id,
                    title: linkedExternalAccounts[i].accountNickName,
                    type: linkedExternalAccounts[i].type,
                    lastFour: linkedExternalAccounts[i].lastFour as string,
                    accountID: linkedExternalAccounts[i].id,
                    accountType: 'External',
                    balance: 0
                }
                tempAccounts.push(addAccount)
            }
        }
        if (tempAccounts.length) {
            return tempAccounts;
        }

        return []

    }
    const [selectOptions, setSelectOptions] = useState<Array<any>>(buildSelectionOptions())

    useEffect(() => {
        setSelectOptions(buildSelectionOptions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation, linkedExternalAccounts, removeOption.id])

    /**
     * Checks if the option is the currently selected options
     * @param option - The option to check if it's selected.
     * @returns - If the options is selected
     */
    const isSelectedOption = (option: any) => {
        if (selectedOption.id && selectedOption.id === option.id) {
            return true;
        }

        if (selectedOption.accountID && selectedOption.accountID === option.accountID) {
            return true;
        }

        return false;
    }

    return (
        <div className="transfers-dropdown-wrapper">
            <label className="label-wrap" >
                <div className="label-text-wrap">
                    <span className={`label-text regular ${className}`}>{label}</span>
                    {isRequired && <span className="required-star">*</span>}
                </div>
                <div data-testid={testId} className="input-wrap" onClick={() => setOpenDropDown(!openDropDown)}>
                    <div
                        role="combobox"
                        className={selectedOption.title === "" ? `input-box regular cls_mask` : `input-box regular selected cls_mask`}
                    >   {selectedOption.title !== "" ? `${selectedOption.title} - ${selectedOption.accountType === "External" ? "External" : "Savings"} ${selectedOption.lastFour ? selectedOption.lastFour : selectedOption.value}` : defaultValue}

                    </div>
                    <img className={openDropDown ? "arrow-image open" : "arrow-image close"} src={downArrow} alt="open arrow" />
                </div>
                <div>
                    {selectedOption.accountType === "Internal" && <span className="available-balance-text regular cls_mask">Available balance: {displayCurrency(selectedOption.balance)}</span>}
                </div>
            </label>
            {openDropDown &&
                <div className="drop-down-wrap">

                    {selectOptions.map((option: any, index: number) => {
                        return (
                            <div key={index} className="option-row-wrap">
                                {(option.title === "CREDIT ONE ACCOUNTS" || option.title === "EXTERNAL ACCOUNTS") ?
                                    <div className="grouping-block boldSmall">
                                        {(gotExternalAccounts === "Failed" && option.title === "EXTERNAL ACCOUNTS") &&
                                            <img className="red-circle-error-icon" src={redExclamationCircle} alt="red circle exclamation mark" />
                                        }
                                        <span className="grouping-title">{option.title}</span>
                                    </div>
                                    :
                                    <div data-testid={`option ${option.lastFour}`} className="option-row" onClick={() => { setSelectedOption(option); setOpenDropDown(false) }}>
                                        <div className="left-side">
                                            {option.accountType === "Internal" ?
                                                <>
                                                    <span
                                                        className={isSelectedOption(option) ? "title-row regular selected cls_mask" : "title-row regular cls_mask"}>{option.title} - {option.type} {option.lastFour}
                                                    </span>
                                                    <span className={isSelectedOption(option) ? "balance-row small selected cls_mask" : "balance-row small cls_mask"}>Available balance: {displayCurrency(option.balance)}</span>
                                                </>
                                                :
                                                <span className={isSelectedOption(option) ? "title-row regular selected cls_mask" : "title-row regular cls_mask"}>{option.title} - {option.type === "internal" ? "Savings" : "External"} {option.lastFour}</span>
                                            }
                                        </div>
                                        {isSelectedOption(option) && <img src={blueCheckmark} alt="checkmark" />}
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
})

export default TransfersDropDown