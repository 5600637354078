import { useModifyAddressModal } from '../../../../useHooks/componentHooks/useModifyAddressModal/useModifyAddressModal'
import { IModifyAddressModalProps } from './interfaces/interfaces/IModifyAddressModalProps'
import './ModifyAddressModal.scss'
import blueLoadingCircle from '../../../../assets/blueLoadingCircle.svg'
import { IAddress } from '../../../../api/User/interfaces'
import { RadioButton } from '../../../../components/RadioButton/RadioButton'
import editAddress from '../../../../assets/editAddress.svg'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders the component that handles changes to the mailing address */
const ModifyAddressModal = (props: IModifyAddressModalProps) => {
    const { form, index, purpose, setOpenEditPage, setOpenMailingModal, dispatchForm } = props
    const { userInformation, enableConfirm, handleRadioOnClick, handleOnConfirm, alignBorder, handleOnScroll, windowState, getOldMailingIndexData,
        newMailingAddressIndex, setFormFilled } = useModifyAddressModal(form, index, setOpenEditPage, purpose, setOpenMailingModal, dispatchForm)

    return (
        <div className="modify-address-modal-wrap">
            <div className={windowState !== "Mobile" ? "modal-wrapper" : "modal-wrapper mobile"}>
                <div className={windowState !== "Mobile" ? "inner-wrapper" : "inner-wrapper mobile"}>
                    {(purpose === "Remove" || purpose === "Delete") ?
                        <>
                            <div className="image-wrapper">
                                <img className="loading-circle" src={blueLoadingCircle} alt="change" />
                            </div>
                            <span className="modal-message h4">To remove this address, select a new default mailing address for your account.</span>
                            <div className={alignBorder === "Top" ? "update-box-wrapper top-border" : "update-box-wrapper"} onScroll={handleOnScroll} data-testid='address-options'>
                                {/* <div className="currently-selected-address">
                                <span className="from-text small">From</span>
                                <span className="address-text semiboldRegular">{userInformation.contactInformation.addresses[index].addressLine1}</span>
                                <span className="address-text semiboldRegular">{userInformation.contactInformation.addresses[index].addressLine2}</span>
                                <span className="city-text semiboldRegular">{userInformation.contactInformation.addresses[index].city}, {userInformation.contactInformation.addresses[index].state} {userInformation.contactInformation.addresses[index].zipCode}</span>
                            </div> */}
                                <div className="options-box-wrapper" >
                                    {userInformation.contactInformation.addresses.map((address: IAddress, arrayIndex: number) => {
                                        return (
                                            <div key={arrayIndex}>
                                                {index !== arrayIndex &&
                                                    <>
                                                        <div className="address-wrap" onClick={() => handleRadioOnClick(arrayIndex)}>
                                                            <RadioButton name="address-selection" onClick={() => handleRadioOnClick(arrayIndex)} checked={arrayIndex === newMailingAddressIndex} />
                                                            <div className="address-info">
                                                                <span className="from-text small">{address.addressType === "Residential" ? "Residential" : `Address ${arrayIndex + 1}`}</span>
                                                                <span className="address-text semiboldRegular cls_mask">{address.addressLine1}</span>
                                                                <span className="address-text semiboldRegular cls_mask">{address.addressLine2}</span>
                                                                <span className="city-text semiboldRegular cls_mask">{address.city}, {address.state} {address.zipCode}</span>
                                                            </div>
                                                        </div>
                                                        {(arrayIndex !== userInformation.contactInformation.addresses.length - 1 && !userInformation.contactInformation.addresses[index + 1]?.isMailing) &&
                                                            <div className="border-line" />
                                                        }
                                                    </>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="image-wrapper">
                                <img className="loading-circle" src={editAddress} alt="change" />
                            </div>
                            <span className="modal-message h4">Change default mailing address?</span>
                            <div className={alignBorder === "Top" ? "update-box-wrapper top-border" : "update-box-wrapper"} onScroll={handleOnScroll} data-testid='address-options'>
                                <div className="currently-selected-address">
                                    <span className="from-text small">From</span>
                                    <span className="address-text semiboldRegular cls_mask">{getOldMailingIndexData("addressLine1")}</span>
                                    <span className="address-text semiboldRegular cls_mask">{getOldMailingIndexData("addressLine2")}</span>
                                    <span className="city-text semiboldRegular cls_mask">{getOldMailingIndexData("city")}, {getOldMailingIndexData("state")} {getOldMailingIndexData("zipCode")}</span>
                                </div>
                                <div className="options-box-wrapper">
                                    <div className="address-wrap no-radio">
                                        <div className={"address-info"}>
                                            <span className="from-text small">To</span>
                                            <span className="address-text semiboldRegular cls_mask">{form.controls.addressLine1.value}</span>
                                            <span className="address-text semiboldRegular cls_mask">{form.controls.addressLine2.value}</span>
                                            <span className="city-text semiboldRegular cls_mask">{form.controls.city.value}, {form.controls.state.value} {form.controls.zipCode.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {alignBorder === "Bottom" && <div className={purpose !== "Edit" ? "bottom-border" : "bottom-border none"} />}
                </div>
                {windowState !== "Mobile" ?
                    <div className="button-wrapper">
                        <button className="Button btn-secondary" onClick={() => { setOpenEditPage(""); form.resetForm(); dispatchForm(); setFormFilled(false) }}>Cancel</button>
                        <Button
                            title={(purpose === "Remove" || purpose === "Delete") ? "Remove & update" : "Yes, change"}
                            isDisabled={!enableConfirm}
                            className={enableConfirm ? "Button btn-primary primary" : "Button btn-primary inactive primary"}
                            id={TIMER_IDS.USER_ADDRESS}
                            onClick={handleOnConfirm}
                        />
                    </div>
                    :
                    <div className="button-wrapper mobile">
                        <Button
                            title={(purpose === "Remove" || purpose === "Delete") ? "Remove & update" : "Yes, change"}
                            isDisabled={!enableConfirm}
                            className={enableConfirm ? "Button btn-primary" : "Button btn-primary inactive mobile"}
                            id={TIMER_IDS.USER_ADDRESS}
                            onClick={handleOnConfirm}
                        />
                        <button className="Button btn-secondary" onClick={() => { setOpenEditPage(""); form.resetForm(); dispatchForm(); setFormFilled(false) }}>Cancel</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default ModifyAddressModal