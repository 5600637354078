import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import { MobileInterestSettingsChange } from '../../../components/InterestSettingsChange/MobileInterestSettingsChange/MobileInterestSettingsChange'
import MobileInterestSettingsLanding from '../../../components/InterestSettingsLanding/MobileInterestSettingsLanding/MobileInterestSettingsLanding'
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import { IMobileInterestSettingsProps } from './interfaces/IMobileInterestSettingsProps'
import './MobileInterestSettings.scss'
import warning from '../../../assets/warning.svg'

/**This component displays the mobile version of interest settings page */
const MobileInterestSettings = (props: IMobileInterestSettingsProps) => {
    const { selectedAccount, changeClicked, setChangeClicked, accountToTransfer, setAccountToTransfer } = props
    const isInterestSettingsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isInterestSettingsLoaded)

    return (
        <MobileLayout>
            <div className="mobile-interest-settings-wrapper">
                <AccountHeader returnMessage='Account Settings' setDisplayDetails={false} />
                {isInterestSettingsLoaded !== "Loading" ?
                    <>
                        {isInterestSettingsLoaded === "Success" ?
                            <>
                                {!changeClicked ?
                                    <MobileInterestSettingsLanding selectedAccount={selectedAccount} setChangeClicked={setChangeClicked} />
                                    :
                                    <MobileInterestSettingsChange 
                                        setChangeClicked={setChangeClicked} 
                                        accountToTransfer={accountToTransfer}
                                        setAccountToTransfer={setAccountToTransfer}
                                    />
                                }
                            </>
                            :
                            <div className="api-error-wrapper">
                                <img className="error-image" src={warning} alt="failed to get interest settings data" />
                                <span className="header-text h4">Unable to load data</span>
                                <span className="sub-text regular">Please wait a moment and try again.</span>
                            </div>
                        }
                    </>
                    :
                    <div className="mobile-interest-loading-state">
                        <div className="loading-box" />
                    </div>
                }
            </div>
        </MobileLayout>
    )
}

export default MobileInterestSettings