import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { getUserW9Settings, updateW9Status } from "../../../slices/userInformation/userInformationSlice"
import { getEventPath } from "../../../utils/getEventPath"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { patchW9TaxWithholding_API } from "../../../api/User/taxInformation"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"

/**
 * custom hook for the Tax Witholdings component found as a tab on the user profile page
 */
export const useTaxWithholdings = () => {
    useScrollToTop()
    const history = useHistory()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const [toggle, setToggle] = useState<boolean>(userInformation.w9Status)
    const dispatch = useAppDispatch()
    const [openTooltip, setOpenTooltip] = useState<boolean>(false)
    const calledForW9Ref = useRef<boolean>(false)
    const { type, message, setToastMessage, resetToast } = useToastMessage()
    const gotW9 = useAppSelector((state: RootState) => state.userInformation.gotW9)
    const toggleClickedRef = useRef<boolean>(false)

    /**Handles the toggle click event */
    const handleToggleClick = async (toggleStatus: any) => {
        if(!toggleClickedRef.current){
            toggleClickedRef.current = true
            try{
                await patchW9TaxWithholding_API(toggleStatus)
                setToggle(toggleStatus)
                dispatch(updateW9Status(toggleStatus))
                setToastMessage("SUCCESS", "Tax withholdings updated")
                resetToast()
            }
            catch{
                console.log("UNABLE TO UPDATE THE USERS W9 SETTINGS")
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
            }
            toggleClickedRef.current = false
        }
    }

    /**Close the tooltip if a click outside of the popup modal is detected */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("tooltip-wrap")) === -1){
                setOpenTooltip(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**on render call to get the users w9 tax withholding settings */
    useEffect(() => {
        if(!calledForW9Ref.current && userInformation.gotW9 === "Loading"){
            calledForW9Ref.current = true
            callForW9Setting()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInformation])

    /**Makes the API call to get the W9 settings */
    const callForW9Setting = async () => {
        try{
            const response = await dispatch(getUserW9Settings())
            setToggle(response.payload.isTaxWithholding)
        }
        catch{
            console.log("UNABLE TO GET THE USERS W9 SETTINGS")
        }
    }

    return { history, userInformation, toggle, setToggle, handleToggleClick, openTooltip, setOpenTooltip, type, message, gotW9 }
}