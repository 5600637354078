/**The utility function is used to combine the account details keys with the account list keys */
export const populateAccountDetails = (listAccount: any, accountDetails: any) => {
    let tempAccount = {
        ...listAccount,
        accountTitle: accountDetails.accountTitle,
        description: accountDetails.accountName,
        creationDate: accountDetails.createdOn,
        interestPaid: accountDetails.interestPaidAccountToDate,
        interestPaidPriorYear: accountDetails.interestPaidPriorYear,
        interestRate: accountDetails.apy,
        maturitySetting: accountDetails.maturitySettings,
        routingNumber: accountDetails.routingNumber,
        owner: accountDetails.OwnershipDetails,
        term: `${accountDetails.maturitySettings.termLength} months`,
        maturityDate: accountDetails.maturitySettings.matureOn,
        interestPaidYTD: accountDetails.interestPaidYTD                              
    }
    return tempAccount
}