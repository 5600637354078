import { IVerifiedAccount } from '../../../../../api/getYodleeVerifiedAccounts';
import { ICreateYodleeAccount } from '../../../../../api/postCreateYodleeAccount';
import { DisplayBankLinkage } from '../AccountLinkingInProgress/AccountLinkingInProgress.component';
import greenCheckmark from '../../../../../assets/greenCheckmark.svg'
import './YodleeAccountCreated.scss'
import { useHistory } from 'react-router';
import { useLinkExternalAccount } from '../../../Context/useLinkExternalAccount';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import iconAddBankWaiting from '../../../../../assets/iconAddBankWaiting.svg'

/**
 * Displays all of the Yodlee accounts that got passed to `/externalAccounts` .
 * @param props - The accounts that got passed to the API to be created.
 * @returns 
 */
export const YodleeAccountsCreated = (props: { accounts: Array<IVerifiedAccount | ICreateYodleeAccount> }) => {
  const { accounts } = props;
  const history = useHistory()
  const { setStep } = useLinkExternalAccount()
  const { windowState } = useWindowState()

  const IS_MULTI = accounts.length > 1;

  return <div className={`results-accounts-created-wrap ${windowState}`}>
    {IS_MULTI ? <AccountCreatedMulti accounts={accounts} /> : <AccountCreatedSingle accounts={accounts} />}
    {IS_MULTI && accounts.map(e => <div className="bank-linkage-wrapper" key={e.externalAccountId} ><DisplayBankLinkage account={e as ICreateYodleeAccount} key={e.externalAccountId} /></div>)}
    <div className="yodlee-return-button-wrapper">
      {windowState !== "Mobile" ?
        <button className="Button btn-primary" onClick={() => {history.push("/user/profile/external-accounts"); setStep(1)}} >Return to external accounts</button>
        :
        <button className="Button btn-primary mobile" onClick={() => {history.push("/user/profile/external-accounts"); setStep(1)}} >Return to external accounts</button>
      }
    </div>
  </div>
}

/**
 * Display a single account that has been linked from Yodlee.
 * @param props - The accounts that got created.
 * @returns 
 */
const AccountCreatedSingle = (props: { accounts: Array<IVerifiedAccount | ICreateYodleeAccount> }) => {
  const { accounts } = props;
  const { windowState } = useWindowState()

  const ACCOUNT = accounts[0];
  const FOUND_PENDING = accounts.findIndex((account: any) => account.status.toLowerCase() === "pending")

  return (
    <>
    {FOUND_PENDING >= 0 ?
      <div className="yodlee-account-created-wrapper">
        <img className="image" src={iconAddBankWaiting} alt="green checkmark" />
        <p className={`title-text h4 ${windowState}`}>Account linking in progress</p>
        <span className={`sub-title-text margin regular ${windowState}`}>One or more of your accounts are pending manual verification. You’ll need to finish the manual verification process before these accounts can be used.</span>
        <div className="bank-linkage-wrapper" key={ACCOUNT.externalAccountId} ><DisplayBankLinkage account={ACCOUNT as ICreateYodleeAccount} key={ACCOUNT.externalAccountId} /></div>
      </div>
      :
      <div className="yodlee-account-created-wrapper">
        <img className="image" src={greenCheckmark} alt="green checkmark" />
        <p className={`title-text h4 ${windowState}`}>Your account has been linked!</p>
        <span className={`sub-title-text margin regular ${windowState}`}>Your account <strong>{ACCOUNT.bankName} {ACCOUNT.accountNumberLastFourDigit}</strong> is verified and ready to use.</span>
      </div>
    }
    </>
  )
}

/**
 * Display multiple accounts that has been linked from Yodlee.
 * @param props - The accounts that got created.
 * @returns 
 */
const AccountCreatedMulti = (props: { accounts: Array<IVerifiedAccount | ICreateYodleeAccount> }) => {
  const { accounts } = props;
  const { windowState } = useWindowState()

  const FOUND_PENDING = accounts.findIndex((account: any) => account.status.toLowerCase() === "pending")
  const STRING_ACCOUNTS = accounts.map((e, i, arr) => <span><strong>{e.bankName} {e.accountNumberLastFourDigit}</strong> {i !== arr.length - 1 ? ' and ' : ''}</span>)

  const foundPending = () => {
    return (
      <div className="yodlee-account-created-wrapper">
        <img className="image" src={iconAddBankWaiting} alt="bank link" />
        <p className={`title-text h4 margin ${windowState}`}><strong>Account linking in progress</strong></p>
        <span className="sub-title-text regular">One or more of your accounts are pending manual verification. You’ll need to finish the manual verification process before these accounts can be used.</span>
      </div>
    )
  }

  const allLinked = () => {
    return (
      <div className="yodlee-account-created-wrapper">
        <img className="image" src={greenCheckmark} alt="green checkmark" />
        <p className={`title-text h4 ${windowState}`}><strong>Your accounts have been linked!</strong></p>
        <span className="sub-title-text margin regular">Your accounts are verified and ready to use.</span>
      </div>
    )
  }

  // const foundProcessing = () => {
  //   return (
  //     <div className="yodlee-account-created-wrapper">
  //       <img className="image" src={greenCheckmark} alt="green checkmark" />
  //       <p className={`title-text h4 ${windowState}`}><strong>Your accounts have been linked!</strong></p>
  //       <span className="sub-title-text regular">Your accounts are verified and ready to use.</span>
  //     </div>
  //   )
  // }

  // const foundProcessingAndPending = () => {
  //   return (
  //     <div className="yodlee-account-created-wrapper">
  //       <img className="image" src={greenCheckmark} alt="green checkmark" />
  //       <p className={`title-text h4 ${windowState}`}><strong>Your accounts have been linked!</strong></p>
  //       <span className="sub-title-text regular">Your accounts are verified and ready to use.</span>
  //     </div>
  //   )
  // }

  return <>
    {FOUND_PENDING >= 0 && foundPending()}
    {FOUND_PENDING === -1 && allLinked()}
  </>
}