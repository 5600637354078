import { IControl } from '../Control/IControl.interface';
import { EMAIL_FORMAT } from './emailFormat';
import { IS_REQUIRED } from './isRequired';
import { IValidator } from './IValidator.interface';
import { MAX_DATE } from './maxDate';
import { MAX_LENGTH } from './maxLength';
import { MIN_DATE } from './minDate';
import { MIN_LENGTH } from './minLength';
import { VALIDATORS } from './Validators';

export interface IValidation extends IValidator {
  actual: any;
}
export const VALIDATION_FUNCTIONS:{[key:string]:any} = {
  IS_REQUIRED: IS_REQUIRED,
  MIN_LENGTH: MIN_LENGTH,
  MAX_LENGTH: MAX_LENGTH,
  EMAIL_FORMAT: EMAIL_FORMAT,
  add: function (key: string, validationFunction: (...args: Array<any>) => IValidator, validatorFunction: (control: IControl, validator:IValidator) => IValidation | false) {
    this[key] = validatorFunction;
    VALIDATORS[key] = validationFunction
  },
  MAX_DATE: MAX_DATE,
  MIN_DATE: MIN_DATE
}

// VALIDATIONS.add('maxLength', 
//   (length: number) => {
//     return { name: 'maxLength', value: length}
//   },
//   (control: IControl, validator: IValidator) => {
//     if(control.value.length > validator.value) {
//       return { ...validator, actual: control.value.length}
//     }

//     return false;
//   })