/* eslint-disable array-callback-return */
import { useEffect } from "react"
import { getEventPath } from "../../../utils/getEventPath"
import { useTransactionHistoryContext } from '../../../Pages/TransactionHistory/context/TransactionHistoryContext'
// import { sortTransactions } from "../../../utils/sortTransactions"

/** A custom hook for the All Filters component found in the transaction page All Filters selections*/
export const useAllFilters = () => {
    const { dateFilter, amountFilter, typeFilter, setIsAllFiltersSelected, setSelectedFromDropDown, } = useTransactionHistoryContext();

    /**Handles the onClick for the Cancel button */
    const handleCancel = () => {
        document.body.style.overflow = "auto"
        setIsAllFiltersSelected(false)
        setSelectedFromDropDown(false)
        dateFilter.customDate.setStateValueToRef();
        dateFilter.dateRange.setStateValueToRef();
        dateFilter.toDate.setStateValueToRef();
        dateFilter.fromDate.setStateValueToRef();
        amountFilter.maxAmount.setStateValueToRef();
        amountFilter.minAmount.setStateValueToRef();
        typeFilter.transactionType.setStateValueToRef();

    }

    /**Lock the scroll bar on render */
    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if (Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("desktop-all-filters-wrap")) === -1) {
                document.body.style.overflow = "auto";
                setIsAllFiltersSelected(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { handleCancel }
}