import { useEffect, useRef, useState } from "react"
import { getEventPath } from "../../../utils/getEventPath";

//a custom use hook for the desktop date selector found in the document center
export const useDesktopDateSelector = () => {
    const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false)
    const filterBoxRef = useRef<any>()

    /**this is just an event listener looking for a click outside of the calendar, if it happens the calendar closes */
    useEffect(() => {
       const handleClickOutside = (event:any) => {
        const path = getEventPath(event)
         if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("desktop-date-selector-wrap")) === -1){
           setIsMenuOpen(false)
         }
        }
       document.addEventListener('click', handleClickOutside, true);
       return () => {
         document.removeEventListener('click', handleClickOutside, true);  
       };
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);
    

    return {isMenuOpen, setIsMenuOpen, filterBoxRef}
}

