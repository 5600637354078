import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import './MobileBumpRate.scss'
import checkmarkGreenCircle from '../../../assets/checkmarkGreenCircle.svg'
import bumpRateDown from '../../../assets/bumpRateDown.svg'
import greyChartUp from '../../../assets/greyChartUp.svg'
import blueElipseArrowRight from '../../../assets/blueElipseArrowRight.svg'
import bumpRateImage from '../../../assets/bumpRateImage.svg'
import { IMobileBumpRateProps } from './interfaces/IMobileBumpRateProps'
import BumpRateModal from '../components/BumpRateModal/BumpRateModal'
import noBumpCircle from '../../../assets/noBumpCircle.svg'
import { dateLongHand } from '../../../utils/dateLongHand'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { useRef, useEffect } from 'react'
import { useNotifications } from '../../../Context/NotificationContext/useNotificationContext'
import { INotificationServiceObject } from '../../../shared/common/Notification/interfaces/INotifcationProps.types'

// const useBumpRateNotificationDelete = (selectedAccount: any) => {
//     const { notifications, handleDismiss } = useNotifications();
//     const notificationsToDelete = useRef<Array<INotificationServiceObject>>([])

//     useEffect(() => {
//         doDelete();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [notifications])

//     useEffect(() => {
//         deleteNotifications();

//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [selectedAccount.isBumpAvailable, selectedAccount.bumpActivated])

//     const deleteNotifications = () => {
//         notificationsToDelete.current = notifications.filter(e => e.accountId === selectedAccount.id && e.notificationType === 'BumpUpRate')
//         doDelete();
//     }

//     const doDelete = () => {
//         if (notificationsToDelete.current.length) {
//             handleDismiss(notificationsToDelete.current[0].notificationId);
//             notificationsToDelete.current.splice(0, 1);
//         }
//     }

//     return { deleteNotifications };
// }

/**Displays the Mobile version page for bump rate */
const MobileBumpRate = (props: IMobileBumpRateProps) => {
    const { selectedAccount, bumpActivated, setBumpActivated, setBumpConfirmed } = props
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    // useBumpRateNotificationDelete(selectedAccount)
    
    return (
        <MobileLayout>
            <div className="mobile-bump-rate-wrapper">
                <AccountHeader returnMessage='Account Settings' setDisplayDetails={false} />
                {isAccountDetailsLoaded !== "Loading" ?
                    <>
                        {selectedAccount.isBumpAvailable || selectedAccount.bumpActivated ?
                            <>
                                <div className="info-card-wrapper">
                                    <div className="header-wrap">
                                        <span className="header-text h3">Bump rate</span>
                                        <span className="sub-header-text small">Take advantage of rising market rates with a one-time rate increase.</span>
                                    </div>
                                    <div className="info-wrap">
                                        {selectedAccount.bumpActivated ?
                                            <>
                                                <img src={checkmarkGreenCircle} alt="green circle checkmark" />
                                                <span className="header-text h4">Congratulations on your new, higher rate!</span>
                                                <span className="sub-header-text regular">You activated your bump rate on {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.bumpedRateDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.</span>
                                            </>
                                            :
                                            <>
                                                <img className="image" src={bumpRateImage} alt="bump rate" />
                                                <span className="header-text h4">A rate increase is available</span>
                                                <span className="sub-header-text regular">Rates have gone up, and your CD is eligible for an increase of {((selectedAccount.bumpedRate as number) - (selectedAccount.originalApy as number)).toFixed(2)}%! Select ‘Activate bump rate’ to increase your rate today.</span>
                                            </>
                                        }
                                    </div>
                                    <div className="apy-info-wrap">
                                        <div className="left-side">
                                            {selectedAccount.bumpActivated ?
                                                <>
                                                    <span className="current-apy semiboldXLarge">{selectedAccount.originalApy?.toFixed(2)}%</span>
                                                    <span className="current-apy-text small">Your Old APY</span>
                                                </>
                                                :
                                                <>
                                                    <span className="current-apy semiboldCustom">{selectedAccount.interestRate.toFixed(2)}%</span>
                                                    <span className="current-apy-text smallCustom">Your Current APY</span>
                                                </>
                                            }
                                        </div>
                                        <div className="right-side selected">
                                            <div className="top">
                                                {selectedAccount.bumpActivated ?
                                                    <span className="apy-text mobileh3">{selectedAccount.interestRate.toFixed(2)}%</span>
                                                    :
                                                    <span className="apy-text mobileh3">{selectedAccount.bumpedRate?.toFixed(2)}%</span>
                                                }
                                                <img src={greyChartUp} alt="chart up icon" />
                                            </div>
                                            <span className="new-apy-text semibold">New APY</span>
                                        </div>
                                        <img className="right-arrow" src={blueElipseArrowRight} alt="pointer to higher rate" />
                                    </div>
                                    {!selectedAccount.bumpActivated &&
                                        <div className="button-wrapper">
                                            <button className="Button btn-primary" onClick={() => setBumpActivated(true)}>Activate bump rate</button>
                                        </div>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className="info-card-wrapper">
                                    <div className="header-wrap">
                                        <span className="header-text h3">Bump rate</span>
                                        <span className="sub-header-text small">Take advantage of rising market rates with a one-time rate increase.</span>
                                    </div>
                                    <div className="info-wrap">
                                        <img className="image" src={noBumpCircle} alt="no bump" />
                                        <span className="header-text h4">You already have the best rate!</span>
                                        <span className="sub-header-text regular">If a better rate becomes available, we'll let you know.</span>
                                    </div>
                                    <div className="apy-info-wrap unavailable">
                                        <div className="left-side selected">
                                            <span className="current-apy mobileh3">{selectedAccount.interestRate.toFixed(2)}%</span>
                                            <span className="current-apy-text semibold">Your Current APY</span>
                                        </div>
                                        <div className="right-side unavailable">
                                            <div className="top">
                                                <span className="apy-text semiboldXLarge">{selectedAccount.bumpedRate?.toFixed(2)}%</span>
                                                <img src={bumpRateDown} alt="chart up icon" />
                                            </div>
                                            <span className="new-apy-text small">Current Market APY</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                    :
                    <div className="mobile-bump-loading-state">
                        <div className="loading-box" />
                    </div>
                }
                {bumpActivated &&
                    <BumpRateModal
                        setBumpActivated={setBumpActivated}
                        setBumpConfirmed={setBumpConfirmed}
                        bumpAPY={selectedAccount.bumpedRate as number}
                    />
                }
            </div>
        </MobileLayout>
    )
}

export default MobileBumpRate