import './AccountOverviewTop.scss'
import { IAccountOverviewTop } from './interfaces/IAccountOverviewTopInterface'
import editPencil from '../../../../../../assets/editPencil.svg'
import { useOverviewTopComponent } from '../../../../../../useHooks/componentHooks/useOverviewComponent/useOverviewTopComponent'
import { EditAccountName } from '../../../../../EditAccountName/EditAccountName'
import { Pill } from '../../../../../Pill/Pill'
import { convertDateTommddyyyy } from '../../../../../../utils/DateUtils'
import tooltipBlueBackground from '../../../../../../assets/tooltipBlueBackground.svg'
import Tooltip from '../../../../../Tooltip/Tooltip'
import { useState } from 'react'
import loyaltryCrown from '../../../../../../assets/loyaltyCrown.svg'

/**
 * Renders the top portion of the desktop overview component.  Displays the product
 * description, the account type, and last four of the account number.
 * @param props selectedAccount = basic account information of IAccountInformation
 */
const AccountOverviewTop = (props: IAccountOverviewTop) => {
    const { selectedAccount } = props;
    const { editDescription, setEditDescription, isAccountDetailsLoaded } = useOverviewTopComponent(selectedAccount)
    const zeroBalance = 0
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [showLoyaltyTooltip, setShowLoyaltyTooltip] = useState<boolean>(false)

    return (
        <div className="account-overview-top-wrapper">

            {isAccountDetailsLoaded !== "Loading" ?
                <div className="basic-account-top">
                    <div className="left-side">
                        <div className="description">
                            {!editDescription ?
                                <div className="description-wrap">
                                    
                                    <span className="account-description h3">{selectedAccount?.accountTitle}</span>
                                    <img data-testid="nickname-pencil" className="edit-pencil" src={editPencil} alt="Edit Button" onClick={() => setEditDescription(!editDescription)} />
                                    {(selectedAccount.earlyWithdrawalSelected || selectedAccount.pendingGraceClosure) && <Pill title="Pending closure" sx={{ backgroundColor: "var(--softRed)", color: "var(--darkRed)" }} />}
                                    {selectedAccount.accountClosed && <Pill title={`Closed on ${convertDateTommddyyyy(new Date(selectedAccount.accountClosedDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}`} sx={{ backgroundColor: "var(--softRed)", color: "var(--darkRed)" }} />}
                                </div>
                                :
                                <EditAccountName selectedAccount={selectedAccount} setEditDescription={setEditDescription} />
                            }
                        </div>
                        {selectedAccount &&
                            <div className="account-type-number-wrap">
                                <span className="account-type-number regular">{selectedAccount.accountType} &nbsp;
                                    {selectedAccount.accountNumber?.slice(selectedAccount?.accountNumber.length - 4, selectedAccount?.accountNumber.length)}
                                </span>
                                {selectedAccount.loyaltyRate && 
                                    <div className="crown-wrap">
                                        <img data-testid="loyalty-icon" className="loyalty-icon" src={loyaltryCrown} alt="active loyalty rate" onMouseOver={() => setShowLoyaltyTooltip(true)} onMouseOut={() => setShowLoyaltyTooltip(false)}/>
                                        {showLoyaltyTooltip &&
                                            <div className="tooltip-wrap">
                                                <Tooltip 
                                                    anchorPoint="top-left"
                                                    bodyMessage={`We increased your rate to ${selectedAccount.interestRate}%.`}
                                                    headerMessage='You earned the Loyalty Rate!'
                                                    width="250px"
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="right-side">
                        <div className="balance-wrap">
                            <div className="balance-value h1"><span className="dollar-symbol">$</span>{!selectedAccount.accountClosed ?
                                selectedAccount?.currentBalance.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                }).substring(1)
                                :
                                zeroBalance.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                }).substring(1)
                            }
                            </div>
                            <div className="label-wrap">
                                <span className="description-text regular">Current balance</span>
                                <img data-testid="balance-tooltip" src={tooltipBlueBackground} alt="tooltip" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} />
                                {showTooltip &&
                                    <Tooltip
                                        anchorPoint='top-right'
                                        bodyMessage="Current Balance includes deposits that have posted but are not yet available. Pending debits are not deducted from this amount."
                                        headerMessage='What is Current Balance?'
                                        width="250px"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="overview-top-loading">
                    <div className="side">
                        <div className="loading-box-1" />
                        <div className="loading-box-2" />
                    </div>
                    <div className="side right">
                        <div className="loading-box-3" />
                        <div className="loading-box-3" />
                    </div>
                </div>
            }
        </div>
    )
}

export default AccountOverviewTop