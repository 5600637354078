import { createContext, useContext, useRef, useState } from 'react';
import { IControl } from '../Form/Control/IControl.interface';
import { Form } from '../Form/Form/Form';

export interface IFormContext {
  formName: string
  controls: {[key: string]: IControl}
  refControls: {[key: string]: IControl}
  setValue: (controlName: string, value: any) => IControl
  setIsBlurred: (controlName: string, value: boolean) => IControl
  setIsTouched: (controlName: string, value: boolean) => IControl
  runValidations: (controlName: string) => IControl
  runValidationOnAllControls: (updateIsFormValid: boolean) => void
  isFormValid: () => boolean
  getErrorMessage: (controlName: string, errorName: string) => string
  resetForm: () => void,
  overrideControlValues: (data: any) => void
  enableAllInputs: () => void
  resetControl: (control: IControl) => void
}

export type TControlDispatch = () => void

export type TFormContext = [IFormContext, TControlDispatch]

export interface IUseFormControl {

}

export const useForm = (formName: string, controlArray: Array<IControl>, options = {}): [IFormContext, TControlDispatch] => {
  const {current} = useRef(Form(formName, controlArray, options));
  const [reactControls, setReactControls] = useState(current.controls);

  const dispatchControlChanges = () => {
    setReactControls(JSON.parse(JSON.stringify(current.controls)));
  }

  return [{
    formName,
    controls: reactControls,
    refControls: current.controls,
    setValue: current.setValue,
    setIsBlurred: current.setIsBlurred,
    setIsTouched: current.setIsTouched,
    runValidations: current.runValidations,
    runValidationOnAllControls: current.runValidationOnAllControls,
    isFormValid: current.isFormValid,
    getErrorMessage: current.getErrorMessage,
    resetForm: current.resetForm,
    overrideControlValues: current.overrideControlValues,
    enableAllInputs: current.enableAllInputs,
    resetControl: current.resetControl
   }, dispatchControlChanges]
}

export const FormContext = createContext<TFormContext | null>(null);

export const FormProvider = (props: any) => {
  const { form, dispatch, children } = props;
  return <FormContext.Provider value={[form, dispatch]}>{children}</FormContext.Provider>
}

export const useFormControl = () => {
  const context = useContext(FormContext);

  if(!context) {
    throw new Error(`Need to use useFormControl inside of FormContext`);
  }

  return context as TFormContext;
}


// const FormComponent = (props: any) => {
//   const [form, dispatchControlChanges] = useForm('newForm', []);
  
//   return (<FormProvider value={[form, dispatchControlChanges]}>
//     {/* <RXInput control={form.controls[name]} */}
//   </FormProvider>)
// }

// const RXInput = (props: any) => {
//   const {control} = props;

//   const getControlComponent = () => {
//     switch(control.type) {
//       case 'input':
//       return  InputComponent
//     }
//   }

//   return React.createElement(getControlComponent(), {
//     control
//   })
// }

// const InputComponent = (props: any) => {
//   const {control: {name}} = props;
//   const [form, dispatchControlChanges] = useFormControl();

//   const handleChange = (event: any) => {
//     form.setValue(event.target.value);
//     dispatchControlChanges()
//   }

//   return <input type="text" value={form.controls[name]} onChange={(e) => handleChange(e)}/>
// }