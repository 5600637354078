import moon from '../../assets/moon.png';
import sun from '../../assets/sun.png';
import './TotalBalance.scss';
import { useTotalBalance } from '../../useHooks/componentHooks/useTotalBalance/useTotalBalance';
import { displayCurrency } from '../../utils/displayCurrency';

/**
 * Componenet that displays the total balance of the users accounts with a greeting message.
 * Used for both desktop and mobile versions.
 */
export const TotalBalance = () => {
  const { timeOfDay, firstName, totalBalance, isAccountInformationLoaded, windowState, nickname, time } =
    useTotalBalance();
    
  /**I've removed the sun / moon icon for now as it's no longer in the figma, waiting for confirmation before deleting */
  return (
    <>
      {isAccountInformationLoaded === "Success" ?
        <div className="total-balance">
          <div className={windowState !== "Mobile" ? 'desktop' : 'mobile'}>
            <div className="greeting">
              <div className={windowState !== "Mobile" ? "desktop-greeting-message" : "greeting-message"}>
                <span className={windowState !== "Mobile" ? "greeting-text h2" : "greeting-text semiboldxLarge"} >Good {timeOfDay}, {nickname ? nickname : firstName}</span>
                {time >= 5 && time < 17? (
                    <img src={sun} alt="sun" className="icon" />
                ) : (
                    <img src={moon} alt="moon" className="icon" />
                )}
              </div>
            </div>
            {windowState !== "Mobile" && <div className="white-line"></div>}
            <div className="balance">
              <div
                className={
                  windowState === "Desktop"
                    ? 'total-balance-message small'
                    : 'total-balance-message small'
                }
              >
                Your total balance
              </div>
              {isAccountInformationLoaded && (
                <>
                {totalBalance ?
                  <div className="total-balance-amount">
                    <sup>$</sup>{displayCurrency(+totalBalance).substring(1)}
                  </div>
                  :
                  <div className="total-balance-amount">
                    <sup>$</sup>{displayCurrency(+0).substring(1)}
                  </div>
                }
                </>
              )}
            </div>
          </div>
        </div>
        :
        <div className="desktop-account-box-loading-state">
          {(windowState !== "Mobile") ?
            <div className="desktop-account-box-loading-state">
              <div className="loading-wrap">
                <div className="left-side">
                  <div className="left-top" />
                  <div className="left-bottom" />
                </div>
                <div className="right-side">
                  <div className="right-top" />
                  <div className="right-botom" />
                </div>
              </div>
            </div>
            :
            <div className="mobile-account-box-loading-state">
              <div className="gretting-line" />
              <div className="divider-line" />
              <div className="total-balance-text" />
              <div className="total-balance" />
            </div>
          }
        </div>
      }
    </>
  );

};
