import FilterBox from '../../../Documents/DesktopDocumentsPage/components/DesktopDateSelector/FilterBox'
import { TTransactionType } from '../../context/TransactionHistory.types';
import { transactionTypes, useTransactionHistoryContext } from '../../context/TransactionHistoryContext'

/**
 * The functionality for the `TransactionTypeDropdown`.
 */
const useTransactionTypeDropdown = () => {
  const { typeFilter, handleApply } = useTransactionHistoryContext();

  /**
   * Handles the selection of the transaction type dropdown.
   * @param value - The value that has been selected.
   */
  const handleSelection = (value: TTransactionType) => {
    typeFilter.transactionType.ref.set(value);
    handleApply();
  }

  return {
    /**
     * Handles the selection of the transaction type dropdown.
     * @param value - The value that has been selected.
     */
    handleSelection,
    /**
     * The currently selected transaction type filter
     */
    selectedFilter: typeFilter.transactionType.ref.get()
  }
}

/**
 * The Desktop Transaction Type Dropdown
 */
export const TransactionTypeDropdown = () => {
  const { handleSelection, selectedFilter } = useTransactionTypeDropdown();

  return <div className="transaction-type-filter">
    <span className="header-text small">Transaction type</span>
    <FilterBox
      filterSelections={transactionTypes}
      selectedFilter={selectedFilter}
      setSelectedFilter={handleSelection}
      displayMessage="Transaction Type"
    />
  </div>
}