import { IMobileInterestSettingsLandingProps } from './interfaces/IMobileInterestSettingsLandingProps'
import './MobileInterestSettingsLanding.scss'
import bluePencil from '../../../assets/bluePencil.svg';
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage';
import { useToastMessage } from '../../../Context/ToastContext/useToastContext';
import { useEffect } from 'react';

/**The component render the mobile version of the interest settings landing page */
const MobileInterestSettingsLanding = (props: IMobileInterestSettingsLandingProps) => {
    const { selectedAccount, setChangeClicked } = props
    const { message, type, resetToast } = useToastMessage()

    /**resets the toast message when it is not an empty string */
    useEffect(() => {
        if(message !== ""){
            resetToast()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return (
        <div className="mobile-interest-settings-landing">
            <div className="encapsulate">
                <div className="title-messages">
                    <span className="title-text mobileh2">Manage Interest Payments</span>
                    <span className="subtitle-text regular">Change where your interest payments are desposited</span>
                </div>
                <div className="card-wrapper">
                    <div className="account-row">
                        <div className="left-side">
                            <span className="deposit-text boldSmall">Deposit interest payments in:</span>
                            <span className="account-title-text small">{selectedAccount.selectedInterestAccount.accountTitle}</span>
                            <span className="account-type x-small">{selectedAccount.selectedInterestAccount.accountType} {selectedAccount.selectedInterestAccount.accountNumber?.slice(selectedAccount.selectedInterestAccount.accountNumber.length - 4, selectedAccount.selectedInterestAccount.accountNumber.length)}</span>
                        </div>
                        <div className="right-side">
                            <img className="edit-pencil" src={bluePencil} alt="change account" onClick={() => setChangeClicked(true)} />
                        </div>
                    </div>
                </div>
            </div>
            {message !== "" && <ToastMessageDB message={message} type={type} />}
        </div>
    )
}

export default MobileInterestSettingsLanding
