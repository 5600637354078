import axios from "axios";
// import { mockSuccessfulApiResponse } from "../../utils/mockApiResponse";
import { IAddress } from "./interfaces";
import { bffBaseUrl } from "../../utils/globalVariables";

/**
 * API call for put user address
 * @param customerId 
 * @param address updated address object
 */
export const patchAddress_API = (address: IAddress) => {
    return axios.patch(`${bffBaseUrl}/customers/addresses`,
        {
            id: address.id,
            streetOne: address.addressLine1,
            streetTwo: address.addressLine2 || "",
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
            //!isDefaultMailingAddress should be here but it is calling the service to fail for now
            isDefaultMailingAddress: address.isMailing
        },
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )
}

/**
 * API call for post a new user address
 * @param customerId 
 * @param address new address object
 */
export const postAddress_API = (address: IAddress) => {
    return axios.post(`${bffBaseUrl}/customers/addresses`,
        {
            // id: "33efec8a-1583-11ee-be56-0242ac120019",
            streetOne: address.addressLine1,
            streetTwo: address.addressLine2 || "",
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
            isDefaultMailingAddress: address.isMailing
        },
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )
}

/**
 * API call for delete user address
 * @param customerId 
 * @param address address object to be deleted
 */
export const deleteAddress_API = (id: string) => {
    return axios.delete(`${bffBaseUrl}/customers/addresses/${id}`,
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )
}