import { ITransferHistory } from "../api/transfers"

/**The utitlity function is used to srot transfers in ascending or descending order */
export const sortTransfers = (column: string, direction: string, filteredTransactions: Array<ITransferHistory>, setDateSort: any, setAmountSort: any) => {
    // let tempTransactionArray = JSON.parse(JSON.stringify(filteredTransactions))
    let tempTransactionArray = filteredTransactions
    switch(column){
      case "Date": {
        if(direction === "Up"){
          setDateSort("Up")
          setAmountSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransferHistory, b:ITransferHistory) => {
            if(a.transferDate > b.transferDate){
              return -1
            }
            if(a.transferDate < b.transferDate){
              return 1
            }
            return 0
          })
          // a.transferDate > b.transferDate ? -1 : 1)
        }
        else if(direction === "Down"){
          setDateSort("Down")
          setAmountSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransferHistory, b:ITransferHistory) => {
            if(a.transferDate > b.transferDate){
              return 1
            }
            if(a.transferDate < b.transferDate){
              return -1
            }
            return 0
          })
          // a.transferDate > b.transferDate ? 1 : -1)
        }
        break
      }
      case "Amount": {
        if(direction === "Up"){
          setAmountSort("Up")
          setDateSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransferHistory, b:ITransferHistory) => {
            if(+a.amount > +b.amount){
              return -1
            }
            if(+a.amount < +b.amount){
              return 1
            }
            return 0
          })
          // a.amount > b.amount ? -1 : 1)
        }
        else if(direction === "Down"){
          setAmountSort("Down")
          setDateSort("Default")
          tempTransactionArray = tempTransactionArray.sort((a:ITransferHistory, b:ITransferHistory) => {
            if(+a.amount > +b.amount){
              return 1
            }
            if(+a.amount < +b.amount){
              return -1
            }
            return 0
          })
          // a.amount > b.amount ? 1 : -1)
        }
        break
      }
    }
    return tempTransactionArray
}