import { useEffect } from 'react'
import { useHistory } from "react-router"


const AuthenticatePage = () => {
    const history = useHistory()
    useEffect(() => {
        /**
         * Sets `sessionStorage.isAuthenticated` then pushes to the homepage after it's set.
         */
        sessionStorage.setItem('isAuthenticated', 'true');
        let interval: NodeJS.Timeout;
        interval = setInterval(() => {
            if (sessionStorage.getItem('isAuthenticated') === 'true') {
                history.push('/')
                clearInterval(interval);
            }
        }, 10)
    }, [history])

    return null
}

export default AuthenticatePage