import { useState } from "react"
import { useHistory } from "react-router-dom"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"

/**
 * Used to handle State in the OverviewTopComponent for both desktop and mobile
 */
export const useOverviewTopComponent = (selectedAccount: IAccountInformation) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const onTransactionDetails = useAppSelector((state: RootState) => state.transactions.onTransactionDetails)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    const [editDescription, setEditDescription] = useState<boolean>(false)
    const [showLoyaltyTooltip, setShowLoyaltyTooltip] = useState<boolean>(false)

    return {editDescription, setEditDescription, onTransactionDetails, dispatch, history, isAccountDetailsLoaded, showLoyaltyTooltip, setShowLoyaltyTooltip }
}