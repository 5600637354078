import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import './DesktopMaturitySettings.scss'
import { IDesktopMaturitySettingsProps } from './interfaces/IDesktopMaturitySettingsProps'
import blueEditPencil from '../../../assets/blueEditPencil.svg'
import PlanChangeOptions from '../components/PlanChangeOptions/PlanChangeOptions'
import { useMaturitySettingsFlow } from '../../../useHooks/componentHooks/useMaturitySettingsFlow/useMaturitySettingsFlow'
import DesktopCDSelection from '../components/CDSelection/DesktopCDSelection/DesktopCDSelection'
import UpdateMaturityPlan from '../components/UpdateMaturityPlan/UpdateMaturityPlan'
import TransferAtMaturity from '../components/TransferAtMaturity/TransferAtMaturity'
import DesktopTransferFunds from '../components/TransferFunds/DesktopTransferFunds/DesktopTransferFunds'
import { displayCurrency } from '../../../utils/displayCurrency'
import redTrashCan from '../../../assets/redTrashCan.svg'
import CancelTransferModal from '../components/CancelTransferModal/CancelTransferModal'
import CDDetails from '../components/CDDetails/CDDetails'
import { dateLongHand } from '../../../utils/dateLongHand'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useEffect, useState } from 'react'

/**The maturity settings flow for the desktop version */
const DesktopMaturitySettings = (props: IDesktopMaturitySettingsProps) => {
    const { planChange, setPlanChange, termChange, setTermChange, manageFunds, setManageFunds,
        openCancelFunds, setOpenCancelFunds, changePlanOption, setChangePlanOption, openChangeOption, setOpenChangeOption,
        chosenTerm, setChosenTerm, showTermChange, setShowTermChange, transferType, setTransferType, CDProductList, bumpProductList,
        transferAccount, setTransferAccount } = props
    const { selectedAccount, isAccountDetailsLoaded, type, message } = useMaturitySettingsFlow()
    const [changeProduct, setChangeProduct] = useState<string>("")

    useEffect(() => {
        if(selectedAccount.maturitySetting.changeProduct && selectedAccount.maturitySetting.changeProduct !== ""){
            const [group, year, month] = selectedAccount.maturitySetting.changeProduct.split(" ")
            let cdGroup = group === "JumboCD" ? "Jumbo CD" : "Bumpup CD"
            setChangeProduct(`${cdGroup} ${year} ${month}`)
        }
    }, [selectedAccount.maturitySetting.changeProduct])

    return (
        <div className="Landing-Wrapper">
            {message !== "" && !openCancelFunds && <ToastMessageDB type={type} message={message} />}
            <div className="card-wrapper account-header">
                <AccountHeader returnMessage="Account Settings" setDisplayDetails={false} />
            </div>
            {(!planChange && !termChange && !manageFunds) &&
                <div className="card-wrapper maturity-plan">
                    <div className="container">
                        <div className="desktop">
                            <div className={isAccountDetailsLoaded !== "Loading" ? "content-wrapper" : "content-wrapper empty"}>
                                {isAccountDetailsLoaded !== "Loading" ?
                                    <>
                                        <div className="title-lockup">
                                            <h1>Maturity plan</h1>
                                            <p>Choose what happens when your CD matures. Your maturity plan will go into effect when your CD matures on {selectedAccount.maturityDate && dateLongHand(convertDateTommddyyyy(selectedAccount.maturityDate))}.</p>
                                        </div>
                                        <div className="column-cards-wrapper">
                                            <div className="left-box">
                                                <span className="maturity-plan-title h4">Your maturity plan</span>
                                                <div className="maturity-date-wrap">
                                                    <span className="maturity-date-header boldSmall">Maturity date</span>
                                                    <span className="maturity-date-text regular">{selectedAccount.maturityDate && convertDateTommddyyyy(selectedAccount.maturityDate)}</span>
                                                    {/* <span className="maturity-date-text regular">{convertDateTommddyyyy(selectedAccount.maturityDate)}</span> */}
                                                </div>
                                                <div className="action-wrap">
                                                    <div className="left-side">
                                                        {selectedAccount.maturitySetting.option !== "Close" ?
                                                            <>
                                                                <span className="action-header boldSmall">Action at maturity</span>
                                                                <span className="action regular">{selectedAccount.maturitySetting.option} CD</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span className="action-header boldSmall">Action at maturity</span>
                                                                <span className="action regular">Close CD</span>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="right-side" onClick={() => { setPlanChange(true); setOpenChangeOption(true) }}>
                                                        <img src={blueEditPencil} alt="Change action" />
                                                        <span className="change-text regular">Change action</span>
                                                    </div>
                                                </div>
                                                {selectedAccount.maturitySetting.option !== "Close" &&
                                                    <>
                                                        <div className="action-wrap">
                                                            <div className="left-side">
                                                                <span className="action-header boldSmall">Term</span>
                                                                {/* {selectedAccount.maturitySetting.option?.toLowerCase() === "renew" && <span className="action regular">{selectedAccount.maturitySetting.changeProduct}</span>} */}
                                                                {selectedAccount.maturitySetting.option?.toLowerCase() === "renew" && <span className="action regular">{changeProduct}</span>}
                                                            </div>
                                                            <div className="right-side" onClick={() => setTermChange(true)}>
                                                                <img src={blueEditPencil} alt="Change term" />
                                                                <span className="change-text regular">Change term</span>
                                                            </div>
                                                        </div>
                                                        {/* <div className="action-wrap">
                                                    <div className="left-side">
                                                        <span className="action-header small">New APY</span>
                                                        <span className="action semiboldRegular">Determined at Renewal</span>
                                                    </div>
                                                </div> */}
                                                    </>
                                                }
                                                <div className="action-wrap no-border">
                                                    {selectedAccount.maturitySetting.option !== "Close" ?
                                                        <div className="left-side">
                                                            <span className="action-header boldSmall">Add or withdraw funds at maturity</span>
                                                            {(selectedAccount.maturitySetting.transferAmount && selectedAccount.maturitySetting.transferAmount > 0) ?
                                                                <span className="action regular">Transfer {displayCurrency(+selectedAccount.maturitySetting.transferAmount as number)} {selectedAccount.maturitySetting.transferType === "Add" ? "from" : "to"} {selectedAccount.maturitySetting.transferAccount}</span>
                                                                :
                                                                <span className="action regular">No transfer</span>
                                                            }
                                                        </div>
                                                        :
                                                        <div className="left-side">
                                                            <span className="action-header boldSmall">Transfer balance to</span>
                                                            {selectedAccount.maturitySetting.option !== "Close" ?
                                                                <span className="action regular">{selectedAccount.maturitySetting?.transferAccount}</span>
                                                                :
                                                                <span className="action regular">{selectedAccount.maturitySetting?.closedAccountName}</span>
                                                            }
                                                        </div>
                                                    }
                                                    {selectedAccount.maturitySetting.option !== "Close" ?
                                                        <>
                                                            {(selectedAccount.maturitySetting.transferAmount && selectedAccount.maturitySetting.transferAmount > 0) ?
                                                                <div className="right-side" onClick={() => setOpenCancelFunds(true)}>
                                                                    <img src={redTrashCan} alt="remove" />
                                                                    <span className="change-text regular remove">Cancel transfer</span>
                                                                </div>
                                                                :
                                                                <div className="right-side" onClick={() => setManageFunds(true)}>
                                                                    <img src={blueEditPencil} alt="Add or withdraw funds" />
                                                                    <span className="change-text regular">Add / withdraw funds</span>
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        <div className="right-side" onClick={() => { setPlanChange(true); setChangePlanOption("Close"); setShowTermChange(false) }}>
                                                            <img src={blueEditPencil} alt="Change account" />
                                                            <span className="change-text regular">Change account</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={!selectedAccount.isGracePeriod ? "right-box" : "right-box gracie"}>
                                                <CDDetails />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="desktop-maturity-loading-state"></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {planChange &&
                <>
                    {openChangeOption &&
                        <PlanChangeOptions
                            setChangePlanOption={setChangePlanOption}
                            setOpenChangeOption={setOpenChangeOption}
                            setPlanChange={setPlanChange}
                        />
                    }
                    {(changePlanOption === "Renew" && !showTermChange) &&
                        <DesktopCDSelection
                            chosenTerm={chosenTerm}
                            setChosenTerm={setChosenTerm}
                            setPlanChange={setPlanChange}
                            setOpenChangeOption={setOpenChangeOption}
                            setChangePlanOption={setChangePlanOption}
                            setShowTermChange={setShowTermChange}
                            setTermChange={setTermChange}
                            termChange={termChange}
                            CDProductList={CDProductList}
                            bumpProductList={bumpProductList}
                        />
                    }
                    {(changePlanOption === "Close" && !showTermChange) &&
                        <TransferAtMaturity
                            setPlanChange={setPlanChange}
                            setShowTermChange={setShowTermChange}
                            setChangePlanOption={setChangePlanOption}
                            transferAccount={transferAccount}
                            setTransferAccount={setTransferAccount}
                        />
                    }
                    {showTermChange &&
                        <UpdateMaturityPlan
                            changePlanOption={changePlanOption}
                            setPlanChange={setPlanChange}
                            setShowTermChange={setShowTermChange}
                            chosenTerm={chosenTerm}
                            setChangePlanOption={setChangePlanOption}
                            setTermChange={setTermChange}
                            termChange={termChange}
                            setManageFunds={setManageFunds}
                            transferType={transferType}
                            setTransferType={setTransferType}
                        />
                    }
                </>
            }
            {termChange &&
                <>
                    {!showTermChange ?
                        <DesktopCDSelection
                            chosenTerm={chosenTerm}
                            setChosenTerm={setChosenTerm}
                            setPlanChange={setPlanChange}
                            setOpenChangeOption={setOpenChangeOption}
                            setChangePlanOption={setChangePlanOption}
                            setShowTermChange={setShowTermChange}
                            setTermChange={setTermChange}
                            termChange={termChange}
                            CDProductList={CDProductList}
                            bumpProductList={bumpProductList}
                        />
                        :
                        <UpdateMaturityPlan
                            changePlanOption={changePlanOption}
                            setPlanChange={setPlanChange}
                            setShowTermChange={setShowTermChange}
                            chosenTerm={chosenTerm}
                            setChangePlanOption={setChangePlanOption}
                            setTermChange={setTermChange}
                            termChange={termChange}
                            setManageFunds={setManageFunds}
                            transferType={transferType}
                            setTransferType={setTransferType}
                        />
                    }
                </>
            }
            {manageFunds &&
                <>
                    {!showTermChange ?
                        <DesktopTransferFunds
                            setManageFunds={setManageFunds}
                            setShowTermChange={setShowTermChange}
                            setTransferType={setTransferType}
                            transferAccount={transferAccount}
                            setTransferAccount={setTransferAccount}
                        />
                        :
                        <UpdateMaturityPlan
                            changePlanOption={changePlanOption}
                            setPlanChange={setPlanChange}
                            setShowTermChange={setShowTermChange}
                            chosenTerm={chosenTerm}
                            setChangePlanOption={setChangePlanOption}
                            setTermChange={setTermChange}
                            termChange={termChange}
                            setManageFunds={setManageFunds}
                            transferType={transferType}
                            setTransferType={setTransferType}
                        />
                    }
                </>
            }
            {openCancelFunds &&
                <CancelTransferModal
                    setOpenCancelFunds={setOpenCancelFunds}
                />
            }
        </div>
    )
}

export default DesktopMaturitySettings