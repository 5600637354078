import { IMobileDisplayDocumentPagesProps } from './interfaces/IMobileDisplayDocumentPagesProps'
import arrowRight from '../../../../../assets/arrowRight.svg'
import './MobileDisplayDocumentPages.scss'

/**
 * The component displays the four possible pages in the Document Center mobile version.  This is
 * what would be the document center landing page for mobile. 
 */
const MobileDisplayDocumentPages = (props: IMobileDisplayDocumentPagesProps) => {
    const { setSelectedPage, availablePages } = props

    return(
        <div className="mobile-documents-column-wrapper">
            {availablePages.current.map((page: string, index: number) => {
                return(
                    <div key={index} className="mobile-document-page" onClick={() => setSelectedPage(page)}>
                        <span className="display-page mobile-h5">{page}</span>
                        <img src={arrowRight} alt="Open Page" />
                    </div>
                )
            })}
        </div>
    )
}

export default MobileDisplayDocumentPages