import { Button, TIMER_IDS } from '../../Button/Button';
import { RadioButton } from '../../RadioButton/RadioButton';
import { IInterestSettingsProps } from '../interfaces/IInterestSettingsChangeProps';
import arrowUp from '../../../assets/arrowUp.svg';
import './DesktopInterestSettingsChange.scss';
import { useInterestSettingsChange } from '../../../useHooks/componentHooks/useInterestSettingsChange/useInterestSettingsChange';
import { IAccountInformation } from '../../../api/getAccountInformation';
import { IExternalAccounts } from '../../../api/User/interfaces';
import redWarning from '../../../assets/redWarning.svg';
import bluePlus from '../../../assets/bluePlus.svg';
import blueSettings from '../../../assets/blueSettings.svg';
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage';
import MaxAccountsModal from '../../../Pages/UserProfile/components/MaxAccountsModal/MaxAccountsModal';
/**
 * JS DOC for DesktopInterestSettingsChange
 * @param
 */
export const DesktopInterestSettingsChange = (
  props: IInterestSettingsProps
) => {
  const { setChangeClicked, accountToTransfer, setAccountToTransfer } = props;
  const {
    showExternalAccounts,
    setShowExternalAccounts,
    CDAccounts,
    savingsAccounts,
    selectedAccount,
    convertAccountToNameTypeNumber,
    activeSave,
    linkedExternalAccounts,
    handleOnSave,
    history,
    type,
    message,
    handleLinkExternalAccount,
    showLimitModal,
    setShowLimitModal
  } = useInterestSettingsChange(setChangeClicked, accountToTransfer, setAccountToTransfer);

  return (
    <div className="desktop-interest-settings-change">
      {message !== "" && <ToastMessageDB type={type} message={message} />}
      {showLimitModal &&
        <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
      }
      <div className="content-wrapper">
        <div className="title-subtitle-messages">
          <span className="sub-title-text small">Manage interest payments</span>
          <span className="h3">Where would you like us to deposit your interest payments?</span>
        </div>
        <div className="account-section">
          {CDAccounts.length > 0 && (
            <>
              <div className="account-title boldRegular">CD account</div>
              <div className="radio-section">
                {CDAccounts?.map((e: IAccountInformation) => {
                  return (
                    <div key={e.id} className="row-wrapper">
                      <RadioButton
                        value={convertAccountToNameTypeNumber(e)}
                        name="interest account"
                        checked={
                          e.id === accountToTransfer.id
                        }
                        onChange={() => setAccountToTransfer(e)}
                        type="Desktop"
                      >
                        <span className='label-wrapper'>{convertAccountToNameTypeNumber(e)}</span>
                      </RadioButton>
                      {e.accountNumber ===
                        selectedAccount.selectedInterestAccount
                          .accountNumber && (
                          <div className="current-selection mobile-h6">
                            Current Selection
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div className="account-section">
          {savingsAccounts.length > 0 && (
            <>
              <div className="account-title boldRegular">Savings accounts</div>
              <div className="radio-section">
                {savingsAccounts?.map((e: IAccountInformation) => {
                  return (
                    <div key={e.id}>
                      {(!e.accountClosed && !e.pendingGraceClosure) &&
                        <div className="row-wrapper">
                          <RadioButton
                            value={convertAccountToNameTypeNumber(e)}
                            name="interest account"
                            checked={
                              e.id === accountToTransfer.id
                            }
                            onChange={() => setAccountToTransfer(e)}
                            type="Desktop"
                          >
                            <span className='label-wrapper'>{convertAccountToNameTypeNumber(e)}</span>
                          </RadioButton>
                          {e.accountNumber ===
                            selectedAccount.selectedInterestAccount
                              .accountNumber && (
                              <div className="current-selection mobile-h6">
                                Current Selection
                              </div>
                            )}
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div className="account-section">
          <div className={showExternalAccounts ? 'external-account-header' : 'external-account-header hidden'} onClick={() => setShowExternalAccounts(!showExternalAccounts)} >
            <div className="account-title boldRegular">External accounts</div>
            <img className={showExternalAccounts ? 'up-arrow' : 'down-arrow'} src={arrowUp} alt={showExternalAccounts ? 'up arrow' : 'down arrow'} />
          </div>
          {showExternalAccounts && (
            <>
              <div className="message">
                You can link up to 3 external accounts
              </div>
              <div className="radio-section">
                {linkedExternalAccounts?.map((e: IExternalAccounts) => {
                  return (
                    <div key={e.id} className="row-wrapper">
                      <RadioButton
                        value={`${e.accountNickName}`}
                        name="interest account"
                        onChange={() => {
                          if (e.status === "Linked") {
                            setAccountToTransfer(e);
                          }
                        }}
                        checked={
                          e.id === accountToTransfer.id &&
                          e.status === "Linked"
                        }
                        type="Desktop"
                        disabled={e.status !== "Linked"}
                      >
                        <span className={e.status === "Linked" ? 'label-wrapper' : "label-wrapper not-verified"}>{`${e.accountNickName
                          } - External ${e.lastFour}`}</span>
                      </RadioButton>
                      {e.id ===
                        selectedAccount.selectedInterestAccount
                          .id && (
                          <div className="current-selection mobile-h6">
                            Current Selection
                          </div>
                        )}
                      {e.status !== "Linked" && (
                        <div className="verification-wrapper">
                          <img
                            className="verification-img"
                            src={redWarning}
                            alt="warning"
                          />
                          <span className="verification-text small">
                            Verification Pending
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="interest-grey-line" />
                <div className="options-wrapper">
                  <div className="add-account-wrapper" onClick={() => handleLinkExternalAccount()}>
                    <img src={bluePlus} alt="plus" />
                    <span className="add-account-text small">
                      Link external account
                    </span>
                  </div>
                  <div className="manage-accounts-wrapper" onClick={() => { history.push('/user/profile/external-accounts') }}>
                    <img src={blueSettings} alt="plus" />
                    <span className="manage-accounts-text small">
                      Manage external accounts
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="button-wrapper">
          {/* <div className="btn-group"> */}
          <Button
            className="Button btn-secondary"
            onClick={() => { setChangeClicked(false); setAccountToTransfer(selectedAccount.selectedInterestAccount) }}
            title="Cancel"
          />
          <Button
            className={activeSave ? 'Button btn-primary' : 'Button btn-primary inactive'}
            onClick={handleOnSave}
            title="Save"
            isDisabled={!activeSave}
            id={TIMER_IDS.INTEREST_PAYMENTS}
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
