import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import MobileStatementsLanding from '../../../components/Statements/MobileStatementsLanding/MobileStatementsLanding'
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import { IMobileStatementsPageProps } from './interfaces/IMobileStatementsPageProps'
// import mobileFilter from '../../../assets/mobileFilter.svg'
import './MobileStatementsPage.scss'
// import { IStatements } from '../../../api/getStatements'

/**
 * This component renders the mobile version of the statements page.  It shows all statements related to a 
 * particular account.
 */
const MobileStatementsPage = (props: IMobileStatementsPageProps) => {
    const { filterYear, setFilterYear, filteredStatements, setShowPDF, statementYearsRef, openFilterMenu, setOpenFilterMenu,
        tempSelectedYear, setTempSelectedYear, selectedAccount, startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow,
        setNumberOfItemsToShow } = props

    return (
        <MobileLayout>
            <div className="mobile-statements-page">
                <AccountHeader returnMessage={'Account Details'} setDisplayDetails={false} />
                <MobileStatementsLanding
                    setFilterYear={setFilterYear}
                    filterYear={filterYear}
                    filteredStatements={filteredStatements}
                    setShowPDF={setShowPDF}
                    statementYearsRef={statementYearsRef}
                    openFilterMenu={openFilterMenu}
                    setOpenFilterMenu={setOpenFilterMenu}
                    tempSelectedYear={tempSelectedYear}
                    setTempSelectedYear={setTempSelectedYear}
                    selectedAccount={selectedAccount}
                    startIndex={startIndex}
                    setStartIndex={setStartIndex}
                    endIndex={endIndex}
                    setEndIndex={setEndIndex}
                    numberOfItemsToShow={numberOfItemsToShow}
                    setNumberOfItemsToShow={setNumberOfItemsToShow}
                />
            </div>
        </MobileLayout>
    )
}

export default MobileStatementsPage