import './DesktopLinkExternalAccount.scss'
import arrowLeft from '../../../assets/arrowLeft.svg'
import { useHistory } from 'react-router-dom'
import EnterBankDetails from '../components/EnterBankDetails/EnterBankDetails'
import { useLinkExternalAccount } from '../Context/useLinkExternalAccount'
import AccountVerification from '../components/AccountVerification/AccountVerification'
import { useEffect, useState } from 'react'
import LayoutCard from '../../../shared/common/LayoutCard/LayoutCard'
import LinkPicker from '../components/LinkPicker/LinkPicker'
import Confirmation from '../components/Confirmation/Confirmation'
import YodleePrimingScreen from '../components/YodleePrimingScreen/YodleePrimingScreen'
import YodleeWrapperPage from '../components/YodleeWrapperPage/YodleeWrapperPage'
import YodleeResultsPage from '../components/YodleeResultsPage/YodleeResultsPage'

/**Renders the desktop version flow of link external account */
const DesktopLinkExternalAccount = () => {
    const history = useHistory()
    const { step } = useLinkExternalAccount()
    const [previousPage, setPreviousPage] = useState<string>();

    /**
     * Sets the state of previousPage, depending on if there is a `page` state pushed in from history.push
     * This is used to dynamically set the text of the back button on certain pages
     */
    useEffect (() => {
        if (history.location.state !== undefined && history.location.state !== null) {
            const STATE: {page: string} = history.location.state as {page: string}
            setPreviousPage(STATE.page)
        };
        history.replace({});
    //eslint disabling on the next line because we only want to run this useEffect whenever history.location.state is updated. Which should only be once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state]);

    /**The switch statement controls rendering the correct page based on the step number */
    const setPage = () => {
        switch(step){
            case 1:
                return <EnterBankDetails />
            case 2:
                return <LinkPicker />
            case 3:
                return <AccountVerification />
            case 4:
                return <Confirmation />
            case 5:
                return <YodleePrimingScreen />
            case 6:
                return <YodleeWrapperPage />
            case 7:
                return <YodleeResultsPage />
            default: return <EnterBankDetails />
        }
    }

    return (
        <div className="desktop-link-account-wrapper">
            <div className="header-wrapper">
                <div className="nav-wrapper">
                    <img data-testid="back-chevron" className="image" src={arrowLeft} alt="left facing chevron" onClick={() => history.goBack()} />
                    <span className="nav-text regular" onClick={() => history.goBack()}>{previousPage || "Manage external accounts"}</span>
                </div>
            </div>
            <LayoutCard>
                <>
                {setPage()}
                </>
            </LayoutCard>
        </div>
    )
}

export default DesktopLinkExternalAccount