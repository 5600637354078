import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

 /**
  * custom hook for the maturity card component
 */ 
export const useMaturityCard = () => {  
    const history = useHistory()
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return { history, isAccountDetailsLoaded }
}; 
