import DesktopContactInformation from '../../../components/ContactInformation/DesktopContactInformation/DesktopContactInformation'
import DesktopNotificationSettings from '../../../components/NotificationSettings/DesktopNotificationSettings/DesktopNotificationSettings'
import DesktopSecuritySettings from '../../../components/SecuritySettings/DesktopSecuritySettings/DesktopSecuritySettings'
import DesktopTaxWithholdings from '../../../components/TaxWithholdings/DesktopTaxWithholdings/DesktopTaxWIthholdings'
import { useScrollToTop } from '../../../useHooks/ScrollToTopHook/useScrollToTop'
import './DesktopUserProfilePage.scss'
import { IDesktopUserProfilePageProps } from './interfaces/IDesktopUserProfilePageProps'
import DesktopProfileHeader from '../components/DesktopProfileHeader/DesktopProfileHeader'
import DesktopManageExternalAccounts from '../components/DesktopManageExternalAccounts/DesktopManageExternalAccounts'

/**
 * Renders the component for the Desktop version of the user profile page.  All tabs are wrapped in this page file.
 * The tab that is to be displayed first will be determined by the last section on the URL 
 */
const DesktopUserProfilePage = (props: IDesktopUserProfilePageProps) => {
    const { currentTab, setCurrentTab, availableTabs, openEditPage, setOpenEditPage, addressAction, setAddressAction } = props
    useScrollToTop()
    
    return (
        <div className="desktop-user-profile-wrap">
            {openEditPage === "" && (currentTab === "" || currentTab === "Contact information") &&
                <DesktopProfileHeader 
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    availableTabs={availableTabs}
                />
            }
            {currentTab === "Contact information" && 
                <DesktopContactInformation 
                    openEditPage={openEditPage}
                    setOpenEditPage={setOpenEditPage}
                    addressAction={addressAction} 
                    setAddressAction={setAddressAction}
                />
            }
            {currentTab === "Security settings" &&
                <DesktopSecuritySettings 
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    availableTabs={availableTabs}
                />
            }
            {currentTab === "Tax withholdings" &&
                <DesktopTaxWithholdings 
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    availableTabs={availableTabs}
                />
            }
            {currentTab === "Notification settings" &&
                <DesktopNotificationSettings 
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    availableTabs={availableTabs}
                />
            }
            {currentTab === "Manage external accounts" &&
                <DesktopManageExternalAccounts 
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    availableTabs={availableTabs}
                />
            }
        </div>
    )
}

export default DesktopUserProfilePage