import './HybridLayout.scss'
import { IHybridLayoutProps } from './interfaces/IHybridLayoutProps'
import Headers from '../../components/Headers/Headers'
import { SideNavigation } from '../../components/SideNavigation/SideNavigation'
import { Footer } from '../../components/Footer/Footer'
import { usePageLayout } from '../../useHooks/LayoutHooks/usePageLayout'
import { ReduxWithData } from '../ReduxWithData/ReduxWithData'


/**
 * This component renders the Hybrid page template, it is used between 760px and 1079px
 */
const HybridLayout = (props: IHybridLayoutProps) => {
  const { isAccountInformationLoaded, gotUserContactInfo } = usePageLayout()
  return (
    <div className="hybrid-layout-wrapper">
      <Headers />
      <div className="middle">
        <div className='children-wrapper'>
          <div className="children">{props.children}</div>
        </div>
      </div>
      <div className={(isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") ? "footer" : "footer error"}>
        <Footer />
      </div>
      {((isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") &&
        (gotUserContactInfo === "Success" || gotUserContactInfo === "Loading")) &&
        <div className="side-navigation">
          <SideNavigation />
        </div>
      }
      <ReduxWithData />
    </div>
  )
}

export default HybridLayout