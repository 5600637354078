import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import './MobileVerificationLetter.scss'
import { generateVerificationPDF } from '../../../utils/generateVerificationPDF'

/**Renders the Verification Letter component for the mobile design */
const MobileVerificationLetter = () => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return(
        <MobileLayout>
            <div className="mobile-verification-letter">
                <AccountHeader returnMessage='Account Settings' setDisplayDetails={false}/>
                {isAccountDetailsLoaded !== "Loading" ?
                    <div className="card-wrapper">
                        <div className="header-wrap">
                            <span className="header-text h3">Account verification letter</span>
                            <span className="sub-header-text small">Download a verification letter with your account details.</span>
                        </div>
                        <span className="letter-text regular" onClick={() => generateVerificationPDF(selectedAccount, userInformation)}>{selectedAccount.accountTitle} - Account Verification Letter (PDF)</span>
                    </div>
                    :
                    <div className="loading-box" />
                }
            </div>
        </MobileLayout>
    )
}

export default MobileVerificationLetter