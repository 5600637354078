import { useEffect, useRef, useState } from 'react';
import { IVerifiedAccount } from '../../../../../api/getYodleeVerifiedAccounts';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { RadioButton } from '../../../../../components/RadioButton/RadioButton';
import { displayCurrency } from '../../../../../utils/displayCurrency';
import { useLinkExternalAccount } from '../../../Context/useLinkExternalAccount';
import { YodleeSelectionProvider, useYodleeSelection } from './useYodleeSelection.hook';
import './YodleeAccountSelection.scss'
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import redWarning from '../../../../../assets/redWarning.svg'
import { Button, TIMER_IDS } from '../../../../../components/Button/Button';

/**
 * The pop up for selecting Yodlee accounts to add to external accounts.
 * @returns `JSX.Element`
 */
const YodleeAccountSelectionWrapper = () => {
  const {
    maxAmountToSelect,
    canUserContinue,
    postNewAccounts,
    currentAmountSelected
  } = useYodleeSelection();
  const { windowState } = useWindowState()
  const submitButtonClickedRef = useRef<boolean>(false)
  const { step } = useLinkExternalAccount()

  /**Handles the submit button onClick event */
  const handleSubmitButtonClick = async () => {
    if(!submitButtonClickedRef.current){
      submitButtonClickedRef.current = true
      const currentStep = step
      postNewAccounts()
      return new Promise((resolve, reject) => {
        let interval: any;
        interval = setInterval(() => {
          if (step !== currentStep) {
              resolve(true)
              clearInterval(interval)
              submitButtonClickedRef.current = false
          }
       }, 100)})
    }
  }

  return (
    <div className={`yodlee-limit-modal-wrapper ${windowState}`}>
      <div className={`yodlee-selection-modal-card ${windowState}`}>
        <p className="title-text h4">You’re nearing your external account limit</p>
        {maxAmountToSelect === 1 ?
          <p className="sub-title-text regular">You can only link one more external account at this time. To continue, select one of these accounts to link.</p>
          :
          <p className="sub-title-text regular">You can only link {maxAmountToSelect} more external accounts at this time. To continue, select up to {maxAmountToSelect} of these accounts to link.</p>
        }
        {currentAmountSelected > maxAmountToSelect &&
          <div className={`yodlee-selection-error-wrap ${windowState}`}> 
            <img className="image" src={redWarning} alt="error" />
            <span className="error-text regular">You cannot select more than {maxAmountToSelect} accounts.</span>
          </div>
        }
        <YodleeSelection />
        <Button
            title="Submit"
            isDisabled={!canUserContinue()}
            className={canUserContinue() ? "Button btn-primary" : "Button btn-primary inactive"} 
            id={TIMER_IDS.YODLEE_CREATE_ACCOUNT}
            onClick={() => handleSubmitButtonClick()}
        />
      </div>
    </div>
  )
}

export const YodleeAccountSelection = () => {
  return <YodleeSelectionProvider><YodleeAccountSelectionWrapper /></YodleeSelectionProvider>
}

/**
 * The container for all of the Yodlee account selections.
 * @returns `JSX.Element`
 */
const YodleeSelection = () => {
  const { verifiedAccounts } = useYodleeSelection();
  const { windowState } = useWindowState()

  return (
    <div className={`yodlee-selection-wrapper ${windowState}`}>
      {verifiedAccounts.map(e => <SelectionRow key={e.externalAccountId} account={e} />)}
    </div>
  )
}

/**
 * The row for the Yodlee account selection.
 * @param props - The account to display.
 * @returns `JSX.Element`
 */
const SelectionRow = (props: { account: IVerifiedAccount }) => {
  const { account } = props;
  const { getInputType, toggleSelection, isSelected } = useYodleeSelection();
  const [ selected, setSelected ] = useState<boolean>(false)
  const { windowState } = useWindowState()
  
  /**Get's the selection array and sets the checkbox state for the particular account */
  useEffect(() => {
    if(isSelected(account.externalAccountId)){
      setSelected(true)
    }
    else{
      setSelected(false)
    }
  }, [account])

  /**handles the onClick event for the checkbox inputs*/
  const handleCheckboxClick = () => {
    toggleSelection(account.externalAccountId)
  }

  return (
    <div key={account.externalAccountId} className={`yodlee-selection-row-wrap ${windowState}`}>
      <div className={getInputType() === "radio" ? "left-side" : "left-side gap"}>
        {getInputType() === "radio" ?
          <RadioButton 
            name="accountSelection"
            onChange={(e) => toggleSelection(account.externalAccountId)}
            disabled={account.isAlreadyExist}
            textAlign="Right"
            className={windowState !== "Mobile" ? "" : "no-padding"}
          />
          :
          <Checkbox 
            showCheckmark={selected}
            setShowCheckmark={setSelected}
            onClickHandler={handleCheckboxClick}
            disabled={account.isAlreadyExist}
          />
        }
        <div className="bank-data-stack">
          <p className="bank-name small">{account.bankName}</p>
          <span className="bank-account-info semiboldRegular">{account.accountType} | <span className="bank-account-number semiboldRegular">X-{account.accountNumberLastFourDigit}</span></span>
          {windowState === "Mobile" &&
          <>
              {account.status.toLowerCase() === "verified" && <span className="status-pill linked mobile-h6">Linked</span>}
              {account.status.toLowerCase() === "pending" && <span className="status-pill pending mobile-h6">Verification pending</span>}
              {account.status.toLowerCase() === "pending_review" && <span className="status-pill processing mobile-h6">Processing</span>}
              {(account.balance && +account.balance > 0) && <span className={+account.balance > 0 ? "account-balance semiboldRegular green" : "account-balance semiboldRegular red"}>{displayCurrency(+account.balance)}</span>}
          </>
          }
        </div>
      </div>
      {windowState !== "Mobile" &&
        <div className={account.balance && +account.balance > 0 ? "account-balance semiboldRegular green" : "account-balance semiboldRegular red"}>
          {account.status.toLowerCase() === "verified" && <span className="status-pill linked mobile-h6">Linked</span>}
          {account.status.toLowerCase() === "pending" && <span className="status-pill pending mobile-h6">Verification pending</span>}
          {account.status.toLowerCase() === "pending_review" && <span className="status-pill pending mobile-h6">Processing</span>}
          {account.balance ? displayCurrency(+account.balance) : ""}
        </div>
      }
    </div>
  )
}