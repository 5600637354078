import { useEffect, useRef, useState } from "react"
import { useWindowState } from "../../Context/AccountContext/useWindowState"

/**
 * Custom useHook for the user Profile page
 */
export const useUserProfilePage = () => {
    const { windowState } = useWindowState()
    const [currentTab, setCurrentTab] = useState<"Contact information" | "Security settings" | "Tax withholdings" | "Manage external accounts" | "Notification settings" | "">("")
    const availableTabs = useRef(["Contact information", "Security settings", "Tax withholdings", "Manage external accounts", "Notification settings"])
    const [urlChanged, setUrlChanged] = useState<boolean>(false)
    const [openEditPage, setOpenEditPage] = useState<"Email" | "Mobile" | "Alternate" | "Add" | "Addresses" | "">("")
    const [addressAction, setAddressAction] = useState<"Edit" | "EditMain" | "Add" | "Remove" | "">("")

    /**
     * Creates a Mutation Observer that is watching for changes in the URL.  If a change is detected the use Effect 
     * below will be triggered and the tab will user profile selected tab will move to the new selection.  This is 
     * for use with the header drop down menu.
     */
    useEffect(() => {
        let lastUrl = window.location.href
        const observe = new MutationObserver(() => {
            const url = window.location.href
            if(url !== lastUrl){
                lastUrl = url
                setUrlChanged(true)
            }
            else{
                setUrlChanged(false)
            }
        })
        observe.observe(document, {subtree: true, childList: true})

        return () => observe.disconnect()
    }, [])
    
    /**
     * Get the starting tab off the url on render
     */
    useEffect(() => {
        const adjustedUrl = window.location.pathname.split('/')[3];
        switch(adjustedUrl){
            case "contact" :
            {
                setCurrentTab("Contact information")
                break;
            }
            case "security":
            {
                setCurrentTab("Security settings")
                break;
            }
            case "tax":
            {
                setCurrentTab("Tax withholdings")
                break;
            }
            case "external-accounts":
            {
                setCurrentTab("Manage external accounts")
                break;
            }
            case "notifications":
            {
                setCurrentTab("Notification settings")
                break;
            }
            default:
            {
                if(windowState !== "Mobile"){
                    setCurrentTab("Contact information")
                }
                else{
                    setCurrentTab("")
                }
                break;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlChanged])

    return { currentTab, setCurrentTab, availableTabs, openEditPage, setOpenEditPage, windowState, addressAction, setAddressAction }
}