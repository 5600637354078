import { useState, useEffect } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { IFormContext, TControlDispatch } from "../../../Form/useForm/useForm"

/**A custom use hook holding the logic for the Compliance Questions component */
export const useComplianceQuestions = (
        form: IFormContext, 
        dispatchForm: TControlDispatch, 
        IRSWithholding: "Yes" | "No" | "", 
        foreignAffiliation: "Yes" | "No" | "", 
        setFormStep: React.Dispatch<React.SetStateAction<number>>,
        setIRSWithholding: React.Dispatch<React.SetStateAction<"Yes" | "No" | "">>, 
        setForeignAffiliation: React.Dispatch<React.SetStateAction<"Yes" | "No" | "">> 
    ) => {
    const { windowState } = useWindowState()
    const [enableContinue, setEnableContinue] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

    /**Checks to see if a selection has been made for all radial inputs */
    useEffect(() => {
        // if(IRSWithholding !== "" && foreignAffiliation !== ""){
        if(foreignAffiliation !== ""){
            setEnableContinue(true)
        }
        else{
            setEnableContinue(false)
        }

    }, [IRSWithholding, foreignAffiliation])

    /**handles the onClick event for the Cancel button */
    const handleCancel = () => {
        form.resetForm()
        dispatchForm()    
    }

    /**handles the onClick event for the Continue button */
    const handleContinue = () => {
        if(foreignAffiliation === "Yes"){
            setShowErrorModal(true)
            setIRSWithholding("")
            setForeignAffiliation("")
        }
        else{
            setFormStep(3)
        }
    }

    return { windowState, handleCancel, enableContinue, handleContinue, showErrorModal }
}