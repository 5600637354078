import { useBeneficiaryEditProfile } from '../../../../useHooks/componentHooks/useBeneficiaryEditProfile/useBeneficiaryEditProfile'
import { IMobileBeneficiaryEditProfileProps } from './interfaces/IMobileBeneficiaryEditProfileProps'
import './MobileEditBeneficiaryProfile.scss'
import MobileEditPerson from './components/MobileEditPerson/MobileEditPerson'
import MobileEditCharity from './components/MobileEditCharity/MobileEditCharity'
import MobileEditTrust from './components/MobileEditTrust/MobileEditTrust'

/**
 * The component renders the Edit Beneficiary Profile page for the mobile version.
 * This page allows the user to edit details like name / address / phone etc.
 */
const MobileEditBeneficiaryProfile = (props: IMobileBeneficiaryEditProfileProps) => {
    const { setEditProfile, setEditBeneficiary, selectedBeneficiary, setDeleteBeneficiary, form, dispatchForm } = props
    const { editPerson, setEditPerson, displayOnly, setDisplayOnly } = useBeneficiaryEditProfile()

    return(
        <div className="mobile-editProfile-wrapper">
            <span className="cancel-button regular" onClick={() => setEditProfile(false)}>Cancel</span>
            {selectedBeneficiary.type === "Person" && 
                <MobileEditPerson 
                    editPerson={editPerson}
                    setEditPerson={setEditPerson}
                    displayOnly={displayOnly}
                    setDisplayOnly={setDisplayOnly}
                    setEditProfile={setEditProfile}
                    setEditBeneficiary={setEditBeneficiary}
                    selectedBeneficiary={selectedBeneficiary}
                    setDeleteBeneficiary={setDeleteBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}            
                />
            }
            {(selectedBeneficiary.type === "Charity" || selectedBeneficiary.type === "Non Profit") && 
                <MobileEditCharity 
                    editPerson={editPerson}
                    setEditPerson={setEditPerson}
                    displayOnly={displayOnly}
                    setDisplayOnly={setDisplayOnly}
                    setEditProfile={setEditProfile}
                    setEditBeneficiary={setEditBeneficiary}
                    selectedBeneficiary={selectedBeneficiary}
                    setDeleteBeneficiary={setDeleteBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}            
                />
            }
            {selectedBeneficiary.type === "Trust" && 
                <MobileEditTrust 
                    editPerson={editPerson}
                    setEditPerson={setEditPerson}
                    displayOnly={displayOnly}
                    setDisplayOnly={setDisplayOnly}
                    setEditProfile={setEditProfile}
                    setEditBeneficiary={setEditBeneficiary}
                    selectedBeneficiary={selectedBeneficiary}
                    setDeleteBeneficiary={setDeleteBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}            
                />
            }
        </div>
    )
}

export default MobileEditBeneficiaryProfile