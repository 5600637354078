import MobileTransactionHistory from '../../../../TransactionHistory/MobileTransactionHistory/MobileTransactionHistory';
import CreateTransferLanding from '../../../../Transfers/components/CreateTransferLanding/CreateTransferLanding';
import openCD from '../../../../../assets/openCD.svg';
import DesktopTransactionHistory from '../../../../TransactionHistory/DesktopTransactionHistory/DesktopTransactionHistory';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import { getSAMLURL } from '../../../../../shared/config/getSAMLUrl';
import { selectedAccount } from '../../../../../slices/selectedAccountSlice/selectedAccountSlice';
import { useAppSelector } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
import { TransactionHistoryProvider } from '../../../../TransactionHistory/context/TransactionHistoryContext';
import { useOpenNewAccount } from '../../../../../Context/OpenNewAccountContext/useOpenNewAccountContext';

/**
 * The Mobile Account Details Savings Transaction Table.
 * @param props 
 * @returns 
 */
export const AccountDetailsSavingsTransactionTable = (props: any) => {
  //TODO Probably move these into this component since it's the only one that uses it.
  const { selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions, transferAmount, setTransferAmount, transferDate, setTransferDate } = props;

  const { windowState } = useWindowState();

  if (windowState === 'Mobile') {
    return <AccountDetailsSavingsTransactionTableMobile
      selectedFromAccount={selectedFromAccount}
      setSelectedFromAccount={setSelectedFromAccount}
      selectedToAccount={selectedToAccount}
      setSelectedToAccount={setSelectedToAccount}
      selectionOptions={selectionOptions}
      transferAmount={transferAmount}
      setTransferAmount={setTransferAmount}
      transferDate={transferDate}
      setTransferDate={setTransferDate}
    />

  }


  return <AccountDetailsSavingsTransactionTableDesktop
    selectedFromAccount={selectedFromAccount}
    setSelectedFromAccount={setSelectedFromAccount}
    selectedToAccount={selectedToAccount}
    setSelectedToAccount={setSelectedToAccount}
    selectionOptions={selectionOptions}
    transferAmount={transferAmount}
    setTransferAmount={setTransferAmount}
    transferDate={transferDate}
    setTransferDate={setTransferDate}
  />

}

const AccountDetailsSavingsTransactionTableMobile = (props: any) => {
  const { selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions, transferAmount, setTransferAmount, transferDate, setTransferDate } = props;
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)

  return (


    <div className="savings-page-wrap">
      <MobileTransactionHistory
        fromSavings={true}
      />
      {!selectedAccount.accountClosed &&
        <div className="content-wrap">
          <CreateTransferLanding
            selectedFromAccount={selectedFromAccount}
            setSelectedFromAccount={setSelectedFromAccount}
            selectedToAccount={selectedToAccount}
            setSelectedToAccount={setSelectedToAccount}
            selectionOptions={selectionOptions}
            transferAmount={transferAmount}
            setTransferAmount={setTransferAmount}
            transferDate={transferDate}
            setTransferDate={setTransferDate}
            fromDetails={true}
          />
        </div>
      }
      <OpenACDGetStarted />
    </div>
  )
}

const AccountDetailsSavingsTransactionTableDesktop = (props: any) => {
  const { selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions, transferAmount, setTransferAmount, transferDate, setTransferDate } = props;
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)

  return <div className="savings-cards-wrapper">
    <div className="left-right-wrap">
      <div className="savings-left-side">
        <DesktopTransactionHistory
          fromSavings={true}
        />
      </div>
      <div className="savings-right-side">
        {!selectedAccount.accountClosed &&
          <div className="wrap-transfer">
            <CreateTransferLanding
              selectedFromAccount={selectedFromAccount}
              setSelectedFromAccount={setSelectedFromAccount}
              selectedToAccount={selectedToAccount}
              setSelectedToAccount={setSelectedToAccount}
              selectionOptions={selectionOptions}
              transferAmount={transferAmount}
              setTransferAmount={setTransferAmount}
              transferDate={transferDate}
              setTransferDate={setTransferDate}
              fromDetails={true}
            />
          </div>
        }
        <OpenACDGetStarted />
      </div>
    </div>
  </div>
}

const OpenACDGetStarted = () => {
  const { checkExternalAccounts } = useOpenNewAccount()
  return <div className="open-cd-wrap">
    <img className="image" src={openCD} alt="open a cd" />
    <button className="Button btn-primary" onClick={checkExternalAccounts}>Get Started</button>
  </div>
}