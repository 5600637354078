import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { useState, useEffect, useRef } from 'react'
import { IFormContext, TControlDispatch } from "../../../Form/useForm/useForm"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { postNewCoOwner_API } from '../../../api/postCoOwner'

/**a custom use hook holding the logic for the ReviewOwner component */
export const useReviewOwner = (
        setFormStep: React.Dispatch<React.SetStateAction<number>>,
        form: IFormContext,
        dispatchForm: TControlDispatch,
        IRSWithholding: "Yes" | "No" | "",
        setIRSWithholding: React.Dispatch<React.SetStateAction<"Yes" | "No" | "">>,
        foreignAffiliation: "Yes" | "No" | "",
        setForeignAffiliation: React.Dispatch<React.SetStateAction<"Yes" | "No" | "">>,
        setAddOwner: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
    const { windowState } = useWindowState()
    const [electronicConsent, setElectronicConsent] = useState<boolean>(false)
    const [depositAgreement, setDepositAgreement] = useState<boolean>(false)
    const [enableContinue, setEnableContinue] = useState<boolean>(false)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const { setToastMessage, resetToast } = useToastMessage()
    const confirmedClickedRef = useRef<boolean>(false)

    /**Checks to see if both aggreements have been accepted before enabling the confirm button */
    useEffect(() => {
        if(electronicConsent && depositAgreement){
            setEnableContinue(true)
        }
        else{
            setEnableContinue(false)
        }   

    }, [electronicConsent, depositAgreement])

    /**Handles the onclick event for the Confirm button */
    const handleConfirm = async () => {
        if(!confirmedClickedRef.current){
            confirmedClickedRef.current = true
            const [left, middle, right] = form.controls.phoneNumber.value.split("-")
            const coOwner = {
                accountId: selectedAccount.id,
                prefix: form.controls.prefix.value,
                firstName: form.controls.firstName.value,
                middleName: form.controls.middleName.value,
                lastName: form.controls.lastName.value,
                dateOfBirth:  form.controls.dateOfBirth.value,
                citizenship: "us",
                residencyStatus: "Other",
                addresses: [{
                    addressType: "Residence",
                    country: "US",
                    zip: form.controls.zipCode.value,
                    state: form.controls.state.value,
                    addressLine1: form.controls.address1.value,
                    addressLine2: form.controls.address2.value,
                    city: form.controls.city.value,
                    isPrimary: true,
                    // yearsInAddress: "01",
                    // verifyDate: "2011-01-23"
                }],
                emails: [{
                    // emailType: "Personal",
                    email: form.controls.email.value,
                    isPrimary: true,
                    // verifyDate: "2011-01-23"
                }],
                phones: [{
                    phone: left + middle + right,
                    isPrimary: true,
                    // phoneType: "Home",
                    // phoneExtension: "123",
                    // verifyDate: "2011-01-23"
                }],
                identificationDetails: {
                    tin: form.controls.ssn.value,
                    tinType: "SSN"
                },
                isTaxWithHolding: IRSWithholding === "Yes" ? true : false,
                isSeniorFigure: false
            }
            try{
                await postNewCoOwner_API(coOwner)
                setFormStep(4)
                setIRSWithholding("")
                setForeignAffiliation("")
            }
            catch{
                console.log("UNABLE TO CREATE THE NEW CO-OWNER")
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
            }
            confirmedClickedRef.current = false
        }
        Promise.resolve(true)
    }

    /**Handles the onclick event for teh Cancel button */
    const handleCancel = () => {
        form.resetForm()
        dispatchForm()    
        setIRSWithholding("")
        setForeignAffiliation("")
        setAddOwner(false)
        setFormStep(1)
    }

    // console.log("THE FORM IS :", form)

    return { windowState, electronicConsent, setElectronicConsent, depositAgreement, setDepositAgreement, enableContinue, handleConfirm, handleCancel }
}