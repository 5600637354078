import { BaseDashedNumberInput } from '../BaseDashedNumberInput/BaseDashedNumberInput';
import { ISSNInputProps } from './interfaces/ISSNInputProps'

/**Renders the SSN Input component */
const SSNInput = (props: ISSNInputProps) => {
    const { control, className, style = {}, form, dispatchControlChanges } = props;

    return <BaseDashedNumberInput data-testid="form-ssn" control={control} className={className} style={style} form={form} dispatchControlChanges={dispatchControlChanges} placeholder="XXX-XX-XXXX" />
}

export default SSNInput