import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import './DesktopEarlyWithdrawal.scss'
import DesktopEarlyWithdrawalLanding from '../components/EarlyWithdrawalLanding/DesktopEarlyWithdrawal/DesktopEarlyWithdrawalLanding'
import { IDesktopWithdrawalProps } from './interfaces/IDesktopEarlyWithdrawalProps'
// import DesktopLaunchEarlyWithdrawal from '../components/LaunchEarlyWithdrawal/DesktopLaunchEarlyWithdrawal/DesktopLaunchEarlyWithdrawal'
// import DesktopConfirmRequest from '../components/ConfirmRequest/DesktopConfirmRequest/DesktopConfirmRequest'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
// import DesktopManageEarlyWithdrawal from '../components/ManageEarlyWithdrawal/DesktopManageEarlyWithdrawal/DesktopManageEarlyWithdrawal'

/**Early withdrawal flow for the desktop version */
const DesktopEarlyWithdrawal = (props: IDesktopWithdrawalProps) => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const { confirmEarlyWithdrawal, setConfirmEarlyWithdrawal, confirmRequest, setConfirmRequest, transferAccount, setTransferAccount,
            openQuestionPage, setOpenQuestionPage } = props
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return (
        <div className="early-withdrawal-page-wrap">
            <div className="account-header-wrap">
                {!selectedAccount.earlyWithdrawalSelected &&
                    <AccountHeader returnMessage='Account Settings' setDisplayDetails={false}/>
                    // :
                    // <AccountHeader returnMessage='Account Settings' setDisplayDetails={false} showPill={true}/>
                }
            </div>
            {isAccountDetailsLoaded !== "Loading" ?
                <>
                {(!confirmEarlyWithdrawal && !selectedAccount.earlyWithdrawalSelected) &&
                    <DesktopEarlyWithdrawalLanding 
                        setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                    />
                    // :
                    // <>
                    // {!confirmEarlyWithdrawal &&
                    //     <DesktopManageEarlyWithdrawal 
                    //         setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                    //     />
                    // }
                    // </>
                }
                {/* {confirmEarlyWithdrawal &&
                <>
                    {!confirmRequest ?
                        <DesktopLaunchEarlyWithdrawal 
                            setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                            setConfirmRequest={setConfirmRequest}
                            transferAccount={transferAccount}
                            setTransferAccount={setTransferAccount}
                        />
                        :
                        <DesktopConfirmRequest 
                            setConfirmRequest={setConfirmRequest}
                            transferAccount={transferAccount}
                            openQuestionPage={openQuestionPage}
                            setOpenQuestionPage={setOpenQuestionPage}
                        />
                    }
                </>
                } */}
                </>
                :
                <div className="loading-box-wrapper">
                    <div className="loading-box" />
                </div>
            }
        </div>
    )
}

export default DesktopEarlyWithdrawal