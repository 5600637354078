import { useRef, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { updateGraceSettingsOnTransferFunds } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"

/**A custom use hook for scheduling a fund transfer during grace period */
export const useGraceTransferFunds = (
        setTransferType: any, 
        setShowTermChange: any, 
        setFundTransfer: any,
        transferAccount: {id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""}, 
        setTransferAccount: React.Dispatch<React.SetStateAction<{id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""}>>
    ) => {
    const history = useHistory()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const [addFunds, setAddFunds] = useState<boolean>(false)
    const [withdrawFunds, setWithdrawFunds] = useState<boolean>(false)
    const amountInputRef = useRef<any>()
    const [linkedAccounts, setLinkedAccounts] = useState<Array<{id: string, title: string, lastFour: string, accountType: "external" | "internal" | ""}>>([])
    const [enableContinue, setEnableContinue] = useState<boolean>(false)
    const [enableFirstStep, setEnableFirstSet] = useState<boolean>(false)
    const [addInputError, setAddInputError] = useState<boolean>(false)
    const [withdrawInputError, setWithdrawInputError] = useState<boolean>(false)
    const [transferAmount, setTransferAmount] = useState<number | null>()
    const [isAmountErrored, setIsAmountErrored] = useState<boolean>(false)
    const isErroredRef = useRef<boolean>(false)
    const foundInternalAccount = useRef<boolean>(false)
    const linkedExternalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const [showLimitModal, setShowLimitModal] = useState<boolean>(false)
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const linkExternalClicked = useRef<boolean>(false)

    /**Build the linked accounts array for the accounts dropdown selection */
    const buildLinkedAccounts = () => {
        let tempAccounts: Array<{title: string, lastFour: string, id: string, accountType: "external" | "internal" | ""}> = []
        let addAccount = {id: "", title: "CREDIT ONE ACCOUNTS", lastFour: "", accountType: "" as "external" | "internal" | ""}
        tempAccounts.push(addAccount)
        for(let i = 0; i < accountInformation.length; i = i + 1){
            if(accountInformation[i].accountType === "Savings" && !accountInformation[i].accountClosed){
                if(transferAccount.id === "" && !foundInternalAccount.current){
                    foundInternalAccount.current = true
                    setTransferAccount({id: accountInformation[i].id, title: accountInformation[i].accountTitle, lastFour: accountInformation[i].accountNumber, accountType: "internal"})
                }
                let addAccount = {id: accountInformation[i].id, title: accountInformation[i].accountTitle, lastFour: accountInformation[i].accountNumber, accountType: "internal" as "external" | "internal" | ""}
                tempAccounts.push(addAccount)
            }
        }
        addAccount = {id: "", title: "EXTERNAL ACCOUNTS", lastFour: "", accountType: "" as "external" | "internal" | ""}
        tempAccounts.push(addAccount)
        for(let i = 0; i < linkedExternalAccounts.length; i = i + 1){
            if(linkedExternalAccounts[i].status === "Linked"){
                if(i === 0 && transferAccount.id === "" && !foundInternalAccount.current){
                    setTransferAccount({id: linkedExternalAccounts[i].id, title: linkedExternalAccounts[i].accountNickName, lastFour: linkedExternalAccounts[i].lastFour as string, accountType: "external"})
                }
                let addAccount = {id: linkedExternalAccounts[i].id, title: linkedExternalAccounts[i].accountNickName, lastFour: linkedExternalAccounts[i].lastFour as string, accountType: "external" as "internal" | "external" | "" }
                tempAccounts.push(addAccount)
            }
        }
        if(tempAccounts.length){
            setLinkedAccounts(tempAccounts)
        }
    }

    /**Build out the linked accounts array for the drop down once accountINformation and linked external accounts are here */
    useEffect(() => {
        if(accountInformation.length){
            buildLinkedAccounts()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation, linkedExternalAccounts])
    
    /**Handle the onClick of the radio button */
    const handleRadioOnClick = (action: "Add" | "Withdraw") => {
        if(action === "Add"){
            setAddFunds(true)
            setWithdrawFunds(false)
            setEnableFirstSet(true)
            setTransferType("Add")

        }
        else if(action === "Withdraw"){
            setWithdrawFunds(true)
            setAddFunds(false)
            setEnableFirstSet(true)
            setTransferType("Withdraw")
        }
    }

    /**Handles the onChange event of the Amount input box */
    const handleAmountOnChange = (e: any) => {
        setTransferAmount(e.target.value)
        amountInputRef.current = e.target.value
        if(addFunds){
            if(+amountInputRef.current > (1000000 - selectedAccount.balance)){
                setAddInputError(true)
                setIsAmountErrored(true)
                isErroredRef.current = true
            }
            else{
                setAddInputError(false)
                setIsAmountErrored(false)
                isErroredRef.current = false
            }
        }
        if(withdrawFunds){
            if(selectedAccount.balance - +amountInputRef.current < 100000 && amountInputRef.current !== ""){
                setWithdrawInputError(true)
                setIsAmountErrored(true)
                isErroredRef.current = true
            }
            else{
                setWithdrawInputError(false)
                setIsAmountErrored(false)
                isErroredRef.current = false
            }
        }
        if((addFunds || withdrawFunds) && +amountInputRef.current > 0 && !isErroredRef.current && transferAccount.id !== ""){
            setEnableContinue(true)
        }
        else{
            setEnableContinue(false)
        }
    }

    /**Handles the onClick for the Continue button */
    const handleOnContinue = () => {
        const payload = {
            transferAccountId: transferAccount.id,
            transferAccount: `${transferAccount.title} ${transferAccount.lastFour}`, 
            transferAmount: parseFloat(amountInputRef.current), 
            transferType: addFunds ? "Add" : "Withdraw"
        }
        dispatch(updateGraceSettingsOnTransferFunds(payload))
        // setFundTransfer(false)
        setShowTermChange(true)
    }

    /**Handles the onClick for the Cancel button */
    const handleCancel = () => {
        const payload = {transferAccount: "", transferAmount: 0, transferType: ""}
        dispatch(updateGraceSettingsOnTransferFunds(payload))
        setFundTransfer(false)
        setTransferType("")
    }    

     /**
     * Checks to see if the continue button should be enabled, this handles when the Amount input box
     * has the amount entered before selecting add or withdraw funds
     */
     useEffect(() => {
        amountInputRef.current = ""
        setTransferAmount(null)
        setIsAmountErrored(false)
        setAddInputError(false)
        setWithdrawInputError(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addFunds, withdrawFunds])

   /**Handles the onKeyDown event for the Amount number input*/
   const handleOnKeyDown = (e: any) => {
        (e.key  === 'e' || e.key === '-' || e.key === '+') && e.preventDefault()
        const decimalSize = e.target.value.split(".")[1]
        if(decimalSize && decimalSize.length > 1 && (e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Delete" && e.key !== "ArrowUp" && e.key !== "ArrowDown")){
            e.preventDefault()
        }
        if(+e.target.value === 0 && e.key === "0"){
            e.preventDefault()
        }
    }

    /**check to make sure the transfer to account is populated before enabling the continue button */
    useEffect(() => {
        if(transferAccount && transferAccount?.id !== ""){
            setEnableContinue(true)
        }
        else{
            setEnableContinue(false)
        }

    }, [transferAccount])

    /**handles the link external account button click */
    const handleLinkExternalAccount = () => {
        if(userInformation.externalAccounts.length > 2){
            setShowLimitModal(true)
          }
          else{
              linkExternalClicked.current = true
              dispatch(setCheckInternalToken(true))
              dispatch(setOTPPassed(false))
          }      
    }

    /**monitors the success of the otpModal and sets the openEditPage state to open once the OTP passes */
    useEffect(() => {
        if(OTPPassed && linkExternalClicked.current){
            linkExternalClicked.current = false
            dispatch(setOTPPassed(false))
            history.push("/linkexternalaccount")
        }   
          
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**make sure the checkInternalToken key is set to false on render */
    useEffect(() => {
        dispatch(setCheckInternalToken(false))
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])    
    
    return { selectedAccount, addFunds, withdrawFunds, amountInputRef, linkedAccounts, handleRadioOnClick,
             transferAccount, setTransferAccount, history, enableContinue, enableFirstStep, handleAmountOnChange,
             handleOnContinue, handleCancel, addInputError, withdrawInputError, transferAmount, isAmountErrored, handleOnKeyDown,
             handleLinkExternalAccount, showLimitModal, setShowLimitModal }
}