import { useRef, useCallback, useEffect } from 'react';

export function useIsMounted(): () => boolean {
  const ref = useRef(true);
  const cb = useCallback(() => ref.current, []);

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return cb;
}