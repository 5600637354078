/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IAddress, IContactInformation, IEmail, IExternalAccounts, ILinkedExternalAccounts, IPhone, ISettings, ITaxInformation, ITrustedDevice } from '../../api/User/interfaces';
import { RootState } from "../../app/store";
import { getExternalAccounts_API, getTrustedDevices_API, getUserInfo_API } from '../../api/User/getUserInfo';
import { getAddresses, getEmails, getPhones, populateTrustedDevices, populateExternalAccounts } from '../../utils/populateUserDetails';
import { getExternalAccountNickname_API } from '../../api/User/LinkExternalAccounts';
import { getSMSSettings_API } from '../../api/User/smsSettings';
import { getW9TaxWithholding_API } from '../../api/User/taxInformation';

/**
 * The user information interface used in the userInformation Slice
 */
export interface IUser {
  id: string;
  customerId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  nickname: string;
  contactInformation: IContactInformation;
  taxInformation: ITaxInformation;
  settings: ISettings;
  linkedExternalAccounts: Array<ILinkedExternalAccounts>
  userName: string
  w9Status: boolean
  smsEnrolled: "Yes" | "No" | ""
  externalAccounts: Array<IExternalAccounts>
  gotExternalAccounts: "Loading" | "Success" | "Failed"
  customerSince: string
  emailEnrolled: boolean
  trustedDevices: Array<ITrustedDevice>
  gotUserContactInfo: "Loading" | "Success" | "Failed"
  gotProductList: "Loading" | "Success" | "Failed"
  gotTrustedDevices: "Loading" | "Success" | "Failed"
  openOTPModal: boolean,
  OTPPassed: boolean
  checkInternalToken: boolean
  selectedExternalAccount: IExternalAccounts
  gotSMS: "Loading" | "Success" | "Failed"
  gotW9: "Loading" | "Success" | "Failed"
}

//The initial state for the userInformation Slice
const initialState: IUser = {
  id: "",
  customerId: "",
  firstName: "",
  lastName: "",
  fullName: "",
  nickname: "",
  contactInformation: {
    phones: [],
    emails: [],
    addresses: []
  },
  taxInformation: { data: {} },
  settings: {
    securitySettings: { data: {} },
    notificationSettings: { data: {} }
  },
  linkedExternalAccounts: [
    {
      accountTitle: 'Bank of America Gold Checking',
      accountType: 'External',
      isVerified: true,
      accountNumber: '4561231234',
      institution: 'Bank of America'
    },
    {
      accountTitle: 'Bank of America Savings',
      accountType: 'External',
      isVerified: false,
      accountNumber: '45612317483',
      institution: 'Bank of America'
    }
  ],
  userName: "jim.harbaugh",
  w9Status: true,
  smsEnrolled: "Yes",
  externalAccounts: [],
  gotExternalAccounts: "Loading",
  customerSince: '2022-01-30T16:39:25.870+00:00',
  emailEnrolled: true,
  trustedDevices: [],
  gotUserContactInfo: "Loading",
  gotProductList: "Loading",
  gotTrustedDevices: "Loading",
  openOTPModal: false,
  OTPPassed: false,
  checkInternalToken: false,
  selectedExternalAccount: { id: "", accountNickName: "", status: "", type: 'Savings' },
  gotSMS: "Loading",
  gotW9: "Loading"
}

const getUsernameFromOKTAToken = () => {
  try {
    return JSON.parse(localStorage.getItem('okta-token-storage') || '{}').accessToken.claims.sub
  }
  catch (err) {
    return '';
  }
}


export const getUserInfoAsync = createAsyncThunk(
  "userInformation/getUserInfo_API",
  async () => {
    const response = await getUserInfo_API()

    return response.data
  }
)

/**API Call to get user Trusted Devices */
export const getTrustedDevicesAsync = createAsyncThunk(
  "userInformation/getTrustedDevices_API",
  async () => {
    const response = await getTrustedDevices_API()

    return response.data
  }
)

/**API Call to get the users external accounts */
export const getExternalAccountsAsync = createAsyncThunk(
  "userInformation/getExternalAccounts_API",
  async () => {
    const response = await getExternalAccounts_API()

    return response.data
    // try{
    //   console.log("THE RESPONSE IS :", response)
    //   let externalAccounts = response.data.externalBankAccounts
    //   console.log("** EXTERNAL ACCOUNTS ARE :", externalAccounts)
    //   for(let i = 0; i < externalAccounts.length; i = i + 1){
    //     try{
    //       let externalNickname = await getExternalAccountNickname_API(externalAccounts[i].externalAccountId)
    //       console.log("the externalNickname is :", externalNickname)
    //       if(externalNickname.data.accountNickName && externalNickname.data.accountNickName !== ""){
    //         externalAccounts[i].title = externalNickname.data.accountNickName
    //       }
    //   }
    //   catch{
    //       console.log("Unable to get nickname for external account :", externalAccounts[i].id)
    //   }
    //   }
    // }
    // catch{

    // }

  }
)

/**API Call to get the users SMS settings */
export const getUserSMSSettings = createAsyncThunk(
  "userInformation/getUserSMSSettings_API",
  async () => {
    const response = await getSMSSettings_API()

    return response.data
  }
)

/**API call to get the users W9 Tax Withholding settings */
export const getUserW9Settings = createAsyncThunk(
  "userInformation/getUserW9Settings_API",
  async () => {
    const response = await getW9TaxWithholding_API()

    return response.data
  }
)

export const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState,
  reducers: {
    /**updates the users nickname */
    updateUserGreeting: (state, action) => {
      state.nickname = action.payload
    },
    /**updates the users email (currently we are only working with one email) */
    updateUserEmail: (state, action) => {
      state.contactInformation.emails[0].emailAddress = action.payload
    },
    /**updates the users username */
    updateUserName: (state, action) => {
      state.userName = action.payload
    },
    /**update the users w-9 tax withholdings */
    updateW9Status: (state, action) => {
      state.w9Status = action.payload
    },
    /**update the users sms notification settings */
    updateSmsNotificationSettings: (state, action) => {
      if (action.payload) {
        state.smsEnrolled = "Yes"
      }
      else {
        state.smsEnrolled = "No"
      }
    },
    /**Updates a users address by index */
    updateUserAddress: (state, action) => {
      state.contactInformation.addresses[action.payload.arrayIndex] = action.payload.address
    },
    /**Adds a users address to the address array */
    addUserAddress: (state, action) => {
      state.contactInformation.addresses = [...state.contactInformation.addresses, action.payload]
    },
    /**Updates the mailing address when it has been edited by the user */
    updateMailingAddress: (state, action) => {
      state.contactInformation.addresses[action.payload.oldMailingIndex].isMailing = false
      state.contactInformation.addresses[action.payload.newMailingIndex].isMailing = true
    },
    /**Removes an address from the array by index when it is not the mailing address */
    removeAddressByIndex: (state, action) => {
      state.contactInformation.addresses.splice(action.payload, 1)
    },
    /**Removes an address when and sets is mailing to a new address, this is when the isMailing address is deleted */
    removeAddressSetIsMailing: (state, action) => {
      state.contactInformation.addresses[action.payload.newMailingIndex].isMailing = true
      state.contactInformation.addresses.splice(action.payload.oldMailingIndex, 1)
    },
    /**Adds a new phone number to the phones array */
    addNewPhoneNumber: (state, action) => {
      const tempPhone: IPhone = { id: action.payload.id, type: "Alternate", number: action.payload.phoneNumber }
      state.contactInformation.phones.push(tempPhone)
    },
    /**Edits the phone number */
    editPhoneNumber: (state, action) => {
      state.contactInformation.phones[action.payload.arrayIndex].number = action.payload.newNumber
    },
    /**Removes a phone number from the array */
    removePhoneNumber: (state, action) => {
      // console.log("THE ACTION IS :", action)
      // const tempPhones = state.contactInformation.phones
      // tempPhones.splice(action.payload, 1)
      state.contactInformation.phones.splice(action.payload, 1)
      // state.contactInformation.phones = tempPhones
    },
    /**Sets the gotExternalAccounts key */
    setGotExternalAccounts: (state, action) => {
      state.gotExternalAccounts = action.payload
    },
    /**Populates the user external accounts array */
    setExternalAccounts: (state, action) => {
      // const externalAccounts = action.payload.externalBankAccounts
      // let accountArray: Array<IExternalAccounts> = []
      // for(let i = 0; i < externalAccounts.length; i = i + 1){
      //   let tempAccount: IExternalAccounts = {
      //     id: externalAccounts[i].externalBankAccountId,
      //     accountNickName: externalAccounts[i].accountTitle,
      //     status: externalAccounts[i].status,
      //     lastFour: externalAccounts[i].accountLast4DigitNumber,
      //     description: externalAccounts[i].bankName,
      //     routingNumber: externalAccounts[i].routingNumber
      //   }
      //   accountArray.push(tempAccount)
      // }
      // state.externalAccounts = accountArray
    },
    /**Updates a device (login device) nickname */
    updateDeviceNickname: (state, action) => {
      state.trustedDevices[action.payload.arrayIndex].deviceNickname = action.payload.deviceNickname
    },
    /**removes a trusted device by index from the trusted devices array*/
    removeTrustedDevice: (state, action) => {
      state.trustedDevices.splice(action.payload, 1)
    },
    /**update the selected external accounts nickname */
    updateExternalAccountNicknameByIndex: (state, action) => {
      state.externalAccounts[action.payload.arrayIndex].accountNickName = action.payload.nickname
    },
    /**sets the OTP menu status true= open, false= close */
    setOTPMenuStatus: (state, action) => {
      state.openOTPModal = action.payload
    },
    /**Sets if the OTP check passed or failed, true means passed, false means failed */
    setOTPPassed: (state, action) => {
      state.OTPPassed = action.payload
    },
    /**sets the state of the checkInternalToken key, this determines whehter the service to validate the internal token should happen */
    setCheckInternalToken: (state, action) => {
      state.checkInternalToken = action.payload
    },
    /**Updates the external account nickname by index */
    setExternalAccountNicknameByIndex: (state, action) => {
      state.externalAccounts[action.payload.arrayIndex].accountNickName = action.payload.nickname
    },
    /**sets the selected exernal account */
    selectedLinkAccount: (state, action) => {
      state.selectedExternalAccount = action.payload
    },
    /**remove external account by index */
    removeExternalAccountByIndex: (state, action) => {
      state.externalAccounts.splice(action.payload, 1)
    },
    /**adds an external account to the external account array */
    addExternalAccount: (state, action) => {
      state.externalAccounts.push(action.payload)
    },
    updateExternalAccountStatusByIndex: (state, action) => {
      state.externalAccounts[action.payload.arrayIndex].status = action.payload.status
    },
    /**updates the users nickname */
    updateUserNickname: (state, action) => {
      state.nickname = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfoAsync.pending, (state) => {
        state.gotUserContactInfo = "Loading"
      })
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        state.gotUserContactInfo = "Success"
        let userInfo = action.payload
        let tempPhoneArray: Array<IPhone> = getPhones(userInfo.phones)
        let tempAddressArray: Array<IAddress> = getAddresses(userInfo.addresses)
        let tempEmails: Array<IEmail> = getEmails([userInfo.email])
        state.userName = getUsernameFromOKTAToken();
        state.id = userInfo.customerId
        state.customerId = userInfo.customerId
        state.firstName = userInfo.name.split(" ")[0]
        state.lastName = userInfo.name.split(" ")[1]
        state.fullName = userInfo.name
        state.nickname = state.nickname !== "" ? state.nickname : userInfo.name.split(" ")[0]
        state.contactInformation = {
          phones: tempPhoneArray,
          addresses: tempAddressArray,
          emails: tempEmails
        }
        state.customerSince = userInfo.creationDate
        state.gotUserContactInfo = "Success"
      })
      .addCase(getUserInfoAsync.rejected, (state) => {
        state.gotUserContactInfo = "Failed"
      })
      .addCase(getTrustedDevicesAsync.pending, (state) => { })
      .addCase(getTrustedDevicesAsync.fulfilled, (state, action) => {
        state.gotTrustedDevices = "Success"
        let tempTrustedDevices: Array<ITrustedDevice> = populateTrustedDevices(action.payload.devices)
        state.trustedDevices = tempTrustedDevices
      })
      .addCase(getTrustedDevicesAsync.rejected, (state) => {
        state.gotTrustedDevices = "Failed"
      })
      .addCase(getExternalAccountsAsync.pending, (state) => {
        state.gotExternalAccounts = "Loading"
      })
      .addCase(getExternalAccountsAsync.fulfilled, (state, action) => {
        state.gotExternalAccounts = "Success"
        let tempExternalAccount = action.payload.externalBankAccounts ? populateExternalAccounts(action.payload.externalBankAccounts) : []
        state.externalAccounts = tempExternalAccount
      })
      .addCase(getExternalAccountsAsync.rejected, (state) => {
        state.gotExternalAccounts = "Failed"
      })
      .addCase(getUserSMSSettings.pending, (state) => {
        state.gotSMS = "Loading"
      })
      .addCase(getUserSMSSettings.fulfilled, (state, action) => {
        state.smsEnrolled = action.payload.subscription_status === "opt_Out" ? "No" : "Yes"
        state.gotSMS = "Success"
      })
      .addCase(getUserSMSSettings.rejected, (state) => {
        state.gotSMS = "Failed"
      })
      .addCase(getUserW9Settings.pending, (state) => {
        state.gotW9 = "Loading"
      })
      .addCase(getUserW9Settings.fulfilled, (state, action) => {
        state.w9Status = action.payload.isTaxWithholding
        state.gotW9 = "Success"
      })
      .addCase(getUserW9Settings.rejected, (state) => {
        state.gotW9 = "Failed"
      })
  }
})

export const userInformation = (state: RootState) => state.userInformation
export const { updateUserGreeting, updateUserEmail, updateUserName, updateW9Status, updateSmsNotificationSettings, updateUserAddress,
  addUserAddress, updateMailingAddress, removeAddressByIndex, removeAddressSetIsMailing, addNewPhoneNumber,
  editPhoneNumber, removePhoneNumber, setGotExternalAccounts, setExternalAccounts, updateDeviceNickname,
  removeTrustedDevice, updateExternalAccountNicknameByIndex, setOTPMenuStatus, setOTPPassed, setCheckInternalToken,
  setExternalAccountNicknameByIndex, selectedLinkAccount, removeExternalAccountByIndex, addExternalAccount,
  updateExternalAccountStatusByIndex, updateUserNickname } = userInformationSlice.actions

export default userInformationSlice.reducer