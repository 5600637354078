import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { setAccountInformationByIndex } from '../../slices/accountInformation/accountInformationSlice'
import { getAccountDetailsAsync } from '../../slices/selectedAccountSlice/selectedAccountSlice'
import { populateAccountDetails } from '../../utils/populateAccountDetails'

/**a custom use hook to hold the logic for the verification letter page */
export const useVerificationLetter = () => {
    const { windowState } = useWindowState()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const history = useHistory()

    /**Repopulate the selectedAccount on a page refresh */
    useEffect(() => {
        if(selectedAccount.id === "" && accountInformation[0].id !== ""){
            const adjustedUrl = window.location.pathname.split('/')[2]
            const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
            if(index >= 0){
                const account = accountInformation[index]
                repopulateAccountDetails(index, account)
            }
            else{
                history.push("/")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, accountInformation])

    /**Repopulates the account details on a page refresh */
    const repopulateAccountDetails = async (index: number, account: any) => {
        const payload = {arrayIndex: index, account: account}
        const response: any = await dispatch(getAccountDetailsAsync(payload))
        try{
            let tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
            const payload = {arrayIndex: index, account: tempAccount}
            dispatch(setAccountInformationByIndex(payload))            
        }
        catch{
            console.error()
        }
    }

    return { windowState, selectedAccount }
}