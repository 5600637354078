import axios from "axios"
import { bffBaseUrl, token } from "../utils/globalVariables"

/**
 * API call for post memo
 * @param transaction the transaction that will be edited
 * @param memo new memo to change to
 */
export const postMemo_API = (accountId: string, transactionId: string, memo: string, index: number) => {
   return axios.patch(`${bffBaseUrl}/accounts/${accountId}/transactions/${transactionId}`,
        {
            memo: memo,
            index: index
        },
        {
            headers: {
                Authorization: `Bearer ` + token,
                'Content-Type': 'application/json'
            }
        }
   )
}