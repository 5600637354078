import { useEffect } from "react"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { bumpUpCD_API } from "../../../api/updateBumpRate"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { activateBumpRateByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { activateBumpRate } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { getEventPath } from "../../../utils/getEventPath"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useAccountNotifications, useNotifications } from "../../../Context/NotificationContext/useNotificationContext"

/**a bustom use hook holding the logic for the bump rate modal  */
export const useBumpRateModal = (setBumpActivated: any, setBumpConfirmed: any, bumpAPY: number) => {
    const { windowState } = useWindowState()
    const dispatch = useAppDispatch()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const {type, message, resetToast, setToastMessage} = useToastMessage()
    const { notifications, handleDismiss } = useNotifications()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("message-wrap")) === -1){
                document.body.style.overflow = "auto";
                setBumpActivated(false)
                setBumpConfirmed(false)        
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])    

    /**use effect hook to lock the screen scrolling on render */
    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [])

    /**Handles the onClick for the 'Activate' button */
    const handleActivate = async () => {
        const notificationsIdArray: Array<string> = []
        try{
            await bumpUpCD_API(selectedAccount.id)
            for(let i = 0; i < notifications.length; i = i + 1){
                if(notifications[i].notificationType === "BumpUpRate" && notifications[i].accountId === selectedAccount.id){
                    notificationsIdArray.push(notifications[i].notificationId)
                }
            }
            for(let i = 0; i < notificationsIdArray.length; i = i + 1){
                handleDismiss(notificationsIdArray[i])
            }
            const newDate = new Date()
            const date = newDate.getDate()
            const month = newDate.getMonth() + 1
            const year = newDate.getFullYear()
            const initialPayload = {bumpAPY: bumpAPY, date: `${month}/${date}/${year}`}
            dispatch(activateBumpRate(initialPayload))
            const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
            const payload = {arrayIndex: index, bumpAPY: bumpAPY, date: `${month}/${date}/${year}`}
            document.body.style.overflow = "auto";
            dispatch(activateBumpRateByIndex(payload))
            setBumpActivated(false)
            setBumpConfirmed(true)
        }
        catch{
            console.log("ERROR BUMPING THE ACCOUNT")
            setToastMessage("ERROR", "Unable to save changes. Please try again later.")
            resetToast()
        }
    }

    /**Handles the onClick for the 'Cancel' button*/
    const handleCancel = () => {
        document.body.style.overflow = "auto";
        setBumpActivated(false)
        setBumpConfirmed(false)
    }

    return { handleActivate, handleCancel, windowState, type, message }
}