import { YODLEE_STATUS } from '../../Context/useHooks/usePostYodleeFlow/usePostYodleeFlow.types'
import { useLinkExternalAccount } from '../../Context/useLinkExternalAccount'
import { AccountLinkingInProgress } from '../PostYodleeFlow/AccountLinkingInProgress/AccountLinkingInProgress.component'
import { PostYodleeError } from '../PostYodleeFlow/PostYodleeFlowChart/PostYodleeFlowChart.component'
import { YodleeAccountsCreated } from '../PostYodleeFlow/YodleeAccountCreated/YodleeAccountCreated.component'
import './YodleeResultsPage.scss'

/**Renders the proper page used for all of the Yodlee final results*/
const YodleeResultsPage = () => {
    const { postYodleeFlow } = useLinkExternalAccount()
    const { responseStatus, verifiedAccounts, accountsLinked } = postYodleeFlow

    const showResults = () => {
        switch(responseStatus){
                // All of the accounts got created successfully without selection
            case YODLEE_STATUS.SUCCESS:
            case YODLEE_STATUS.ALL_ACCOUNTS_LINKED:
                return <YodleeAccountsCreated accounts={verifiedAccounts} />
            case YODLEE_STATUS.LINKED_SUCCESS:
                return <YodleeAccountsCreated accounts={accountsLinked} />
            case YODLEE_STATUS.LINKED_FAILED:
            case YODLEE_STATUS.CREATION_FAILED:
                return <AccountLinkingInProgress />
            case YODLEE_STATUS.ERROR:
                return <PostYodleeError />
        } 
    }

    return (
        <div>
            {showResults()}
        </div>
    )
}

export default YodleeResultsPage