import { useEffect, useRef } from "react"
import { useAppDispatch } from "../../../app/hooks"
import { clearSelectedTransaction } from "../../../slices/selectedTransaction/selectedTransactionSlice"
import { getEventPath } from "../../../utils/getEventPath"

/**A custom use hook for the logic on the transaction details page */
export const useTransactionDetails = () => {
    const dispatch = useAppDispatch()
    const detailsRef = useRef<any>()
    const isMemoSelected = useRef<any>()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("Data-Wrapper")) === -1){
                document.body.style.overflow = "auto";
                dispatch(clearSelectedTransaction())
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return {detailsRef, dispatch, isMemoSelected }
}