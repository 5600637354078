import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import AddOwnerForm from '../components/AddOwnerForm/AddOwnerForm'
import './DesktopAccountOwnership.scss'
import { IDesktopAccountOwnershipProps } from './interfaces/IDesktopAccountOwnershipProps'
import ComplianceQuestions from '../components/ComplianceQuestions/ComplianceQuestions'
import ReviewOwner from '../components/ReviewOwner/ReviewOwner'
import OwnerConfirmation from '../components/OwnerConfirmation/OwnerConfirmation'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'

/**
 * The component displays the desktop version of the Account Ownership page.  This is the page
 * that renders when there is an account owner plus co-owners.  The page allows for new
 * co-owners to be added, and current account owners to be viewed.
 */
const DesktopAccountOwnership = (props: IDesktopAccountOwnershipProps) => {
    const { addOwner, setAddOwner, selectedAccount, formStep, setFormStep, form, dispatchForm,
            IRSWithholding, setIRSWithholding, foreignAffiliation, setForeignAffiliation, usingSuggestion, setUsingSuggestion } = props
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return(
        <>
            <div className="desktop-account-ownership-wrap">
                <div className="account-header-page-wrap">
                    <div className="account-header-wrap">
                        <AccountHeader returnMessage={'Account Settings'} setDisplayDetails={false} />    
                    </div>
                </div>
                <div className="card-page-wrap">
                    {isAccountDetailsLoaded !== "Loading" ?
                        <div className={(!addOwner || formStep === 4) ? "card" : `card progress-bar`}>
                            {!addOwner ?
                                <>
                                <div className="header-wrap">
                                    <div className="left-side">
                                        <span className="title h2">Account ownership</span>
                                        <span className="text regular">You may add up to four co-owners to your account.</span>
                                    </div>
                                    {selectedAccount.owner.length < 10 && <button className="Button btn-primary" onClick={() => setAddOwner(true)}>Add a co-owner</button>}
                                </div>
                                <div className="headers-wrap">
                                    <span className="name-text small">Name</span>
                                    <span className="role-text small">Role</span>
                                </div>
                                {selectedAccount.owner?.map((owner: any, index: number) => {
                                    return(
                                        <div key={index}>
                                            {(index !== 0) && <div className="border-line" />}
                                            <div key={index} className="owner-row-wrapper">
                                                <span className="owner-name regular">{owner.name}</span>
                                                <span className="owner-role boldRegular">{owner.role}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className='help-text-wrapper'>
                                    <span className='help-text small'>Each co-owner must have a unique email address. Co-owners cannot be removed without closing the account. Co-owners will have full access to the account.</span>
                                    <span className="help-text small">New co-owner requests can take up to 7 business days. For status, give us a call at&nbsp;+1 (877)-628-1610.</span>
                                </div>
                                </>
                                :
                                <>
                                {formStep !== 4 &&
                                    <>
                                    <div className={`progress-bar-step step-${formStep}`} />
                                    <div className="status-wrap">
                                        <span className="step-status regular">Add a co-owner</span>
                                        <span className="step-status small">Step {formStep} of 3</span>
                                    </div>
                                    </>
                                }
                                {formStep === 1 &&
                                    <AddOwnerForm 
                                        dispatchForm={dispatchForm}
                                        form={form}
                                        setAddOwner={setAddOwner}
                                        setFormStep={setFormStep}
                                        usingSuggestion={usingSuggestion}
                                        setUsingSuggestion={setUsingSuggestion}
                                    />
                                }
                                {formStep === 2 &&
                                    <ComplianceQuestions 
                                        form={form}
                                        dispatchForm={dispatchForm}
                                        IRSWithholding={IRSWithholding} 
                                        setIRSWithholding={setIRSWithholding}
                                        foreignAffiliation={foreignAffiliation}
                                        setForeignAffiliation={setForeignAffiliation}
                                        setFormStep={setFormStep}
                                        setAddOwner={setAddOwner}
                                    />
                                }
                                {formStep === 3 &&
                                    <ReviewOwner 
                                        form={form} 
                                        dispatchForm={dispatchForm}
                                        IRSWithholding={IRSWithholding}
                                        setIRSWithholding={setIRSWithholding}
                                        foreignAffiliation={foreignAffiliation}
                                        setForeignAffiliation={setForeignAffiliation}
                                        setFormStep={setFormStep}
                                        setAddOwner={setAddOwner}
                                    />
                                }
                                {formStep === 4 &&
                                    <OwnerConfirmation 
                                        form={form}
                                        dispatchForm={dispatchForm}
                                        setFormStep={setFormStep}
                                        setAddOwner={setAddOwner}
                                    />
                                }
                                </>
                            }
                        </div>
                        :
                        <div className="loading-box" />
                    }
                </div>
            </div>
        </>
    )
}

export default DesktopAccountOwnership