import { IDisplayBeneficiariesProps } from "./interfaces/IDisplayBeneficiariesProps"
import { COLOR_ARRAY } from "../../../utils/globalVariables"
import './DisplayBeneficiaries.scss'

/**
 * Renders the beneficaries color dot, name, and percentage on the 
 * beneficiary summary card.
 * @param props beneficiaries : Array<IBeneficiary>
 */
const DisplayBeneficiaries = (props: IDisplayBeneficiariesProps) => {
  const { beneficiaries } = props

  return (
    <>
      {beneficiaries.map((member: any, index: number) => {
        return (
          <div key={index} className={beneficiaries.length - 1 !== index ? "row-wrap" : "row-wrap no-border"}>
            <div className="left-side">
              <div className="dot" style={{ backgroundColor: COLOR_ARRAY[index] }} />
              {member.type === "Person" && <span className="member-name regular cls_mask">{`${member.name}`}</span>}
              {member.type === "Trust" && <span className="member-name regular cls_mask">{`${member.name}`}</span>}
              {(member.type === "Charity" || member.type === "Non Profit") && <span className="member-name regular cls_mask">{`${member.name}`}</span>}
              {member.firstName === "Others" && <span className="member-name regular cls_mask">{`${member.name}`}</span>}
            </div>
            <span className="member-percentage boldRegular">{member.percentage}%</span>
          </div>
        )
      })}
    </>
  )
}

export default DisplayBeneficiaries
