import { useRef, useState } from "react";
import { IBeneficiary } from "../../../api/getBeneficiaries";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useWindowState } from "../../../Context/AccountContext/useWindowState";
import { IFormContext } from "../../../Form/useForm/useForm";
import { useToastMessage } from "../../../Context/ToastContext/useToastContext";
import { removeBeneficiaryFromArray } from '../../../slices/selectedAccountSlice/selectedAccountSlice';

/**A custom use hook holding the logic of the Beneficiary Review component */
export const useBeneficiaryReview = 
    (
        form: IFormContext, 
        handleOnFinish: (updatedBeneficiary: IBeneficiary) => Promise<boolean>,
        newBeneficiary: IBeneficiary,
        handleOnNext: () => void
    ) => {

    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const dispatch = useAppDispatch();
    const { windowState } = useWindowState()
    const isContinueClicked = useRef<boolean>(false)
    const [apiFailed] = useState<boolean>(false)
    const {type, setType, message, setMessage} = useToastMessage()

    /**Handles the onClick event for the Continue button */
    const handleContinueClick = async () => {
        if(!isContinueClicked.current){
            isContinueClicked.current = true
            if(selectedAccount.beneficiaries.length === 0){
                try {
                    await handleOnFinish(newBeneficiary)
                }
                catch (error){
                    setType("ERROR")
                    setMessage("Unable to save changes. Please try again later.")
                    setTimeout(() => {
                        setMessage("")
                        isContinueClicked.current = false
                    }, 4000)
                }
            }
            else{
                const BENEFICIARY_INDEX = selectedAccount.beneficiaries.findIndex(e => e.id === newBeneficiary.id);

                //?How do I hit this?
                if(BENEFICIARY_INDEX !== -1) {
                    dispatch(removeBeneficiaryFromArray(BENEFICIARY_INDEX)) 
                }
        
                handleOnNext()
            }
            // setApiFailed(success)
            return Promise.resolve(true)
        }
    }
  
    return { handleContinueClick, windowState, apiFailed, type, message }
}