import './CancelTransferModal.scss'
import { ICancelTransferModalProps } from './interfaces/ICancelTransferModalProps'
import blueMoneyHouse from '../../../../assets/blueMoneyHouse.svg'
import { useCancelTransfer } from '../../../../useHooks/componentHooks/useCancelTransfer/useCancelTransfer'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**
 * Renders the pop up modal confirming that the user wants to cancel a fund transfer,
 * this action is found for fund transfers on the maturity settings flow.
 */
const CancelTransferModal = (props: ICancelTransferModalProps) => {
    const { setOpenCancelFunds } = props
    const { selectedAccount, modalRef, handleOnYes, handleOnNo, windowState } = useCancelTransfer(setOpenCancelFunds)

    return (
        <div className="maturity-cancel-modal-wrapper">

            <div className={windowState !== "Mobile" ? "modal-wrapper" : "modal-wrapper mobile"} ref={modalRef}>
                <img className="image" src={blueMoneyHouse} alt="house" />
                <span className="header-message h4">Cancel transfer {selectedAccount.maturitySetting.transferType === "Add" ? "from" : "to"} {selectedAccount.maturitySetting.transferAccount}?</span>
                {/* <span className="warning-text regular">The action cannot be reverted.</span> */}
                {windowState !== "Mobile" ?
                    <div className="button-wrapper">
                        <button className="Button btn-secondary cancel" onClick={() => handleOnNo()}>Keep transfer</button>
                        <Button 
                            title="Cancel transfer"
                            className="Button btn-primary submit"
                            id={TIMER_IDS.CANCEL_MATURITY_TRANSFER}
                            onClick={handleOnYes}
                        />
                    </div>
                    :
                    <div className="button-wrapper mobile">
                        <Button 
                            title="Cancel transfer"
                            className="Button btn-primary mobile"
                            id={TIMER_IDS.CANCEL_MATURITY_TRANSFER}
                            onClick={handleOnYes}
                        />
                        <button className="Button btn-secondary mobile" onClick={() => handleOnNo()}>Keep transfer</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default CancelTransferModal
