import { IMobileTransactionTypeSelectionProps } from './interfaces/IMobileTransactionTypeSelectionProps'
import './MobileTransactionTypeSelection.scss'
import blueCheckmark from '../../../../../assets/blueCheckmark.svg'
import { useMobileTransactionTypeSelection } from '../../../../../useHooks/componentHooks/useMobileTransactionTypeSelection/useMobileTransactionTypeSelection'
import { TTransactionType } from '../../../context/TransactionHistory.types'
import { transactionTypes, useTransactionHistoryContext } from '../../../context/TransactionHistoryContext'

/**Renders the transaction type selection filter menu on mobile transaction history page*/
const MobileTransactionTypeSelection = (props: IMobileTransactionTypeSelectionProps) => {
    const { setTransactionTypeSelected } = props
    const { chosenTransactionType, setChosenTransactionType, handleOnSave } = useMobileTransactionTypeSelection(setTransactionTypeSelected)

    return (
        <div className="mobile-transaction-type-selection-filter">
            <div className="header-row">
                <span className="option-text regular" onClick={() => setTransactionTypeSelected(false)}>Back</span>
                <span className="header-text semiboldRegular">Transaction Type</span>
                <span className="option-text regular" onClick={() => { handleOnSave() }}>Save</span>
            </div>
            <div className="bottom-border" />
            {transactionTypes.current.map((selection: string, index: number) => {
                return (
                    <div key={index}>
                        <div className="row-wrapper" onClick={() => setChosenTransactionType(selection as TTransactionType)}>
                            <span className={chosenTransactionType === selection ? "selection-text regular selected" : "selection-text regular"}>{selection}</span>
                            {chosenTransactionType === selection && <img src={blueCheckmark} alt="checkmark" />}
                        </div>
                        {index !== transactionTypes.current.length - 1 && <div className="bottom-border" />}
                    </div>
                )
            })}
        </div>
    )
}

export default MobileTransactionTypeSelection