import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { getEventPath } from "../../../utils/getEventPath"

/**
 * custom usehook holding logic for the desktop accounts dropdown component
 */
export const useDesktopAccountsDropdown = () => {
    const [ openDropdown, setOpenDropdown ] = useState<boolean>(false)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("desktop-dropdown-wrapper")) === -1){
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("desktop-document-dropdown-display")) === -1){
                    setOpenDropdown(false)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { accountInformation, openDropdown, setOpenDropdown, dispatch }
}