import { useWindowState } from "../../Context/AccountContext/useWindowState";
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout";
import HybridLayout from "../../layouts/HybridLayout/HybridLayout";
import { TransactionHistoryProvider } from '../TransactionHistory/context/TransactionHistoryContext';
import DesktopAccountDetails from "./DesktopAccountDetails/DesktopAccountDetails";
import MobileAccountDetails from "./MobileAccountDetails/MobileAccountDetails";

/**
 * Wrapper to render the account details page.  If the overall pixel width is 800 pixels or greater 
 * than the Desktop version will be rendered.  If the pixel width is less than 800 pixels the
 * Mobile version will render.
 */
const AccountDetails = () => {
    const { windowState } = useWindowState();

    return (
        <>
            <TransactionHistoryProvider>

                {windowState !== "Mobile" ?
                    <>
                        {windowState === "Desktop" ?
                            <DesktopLayout>
                                <DesktopAccountDetails />
                            </DesktopLayout>
                            :
                            <HybridLayout>
                                <DesktopAccountDetails />
                            </HybridLayout>
                        }
                    </>
                    :
                    <MobileAccountDetails />
                }
            </TransactionHistoryProvider>
        </>
    )
}

export default AccountDetails