import { AccountBox } from '../../../components/AccountBox/AccountBox';
import { TotalBalance } from '../../../components/TotalBalance/TotalBalance';
import './DesktopAccountOverview.scss';
import { IDesktopAccountOverviewProps } from './interfaces/IDesktopAccountProps';
import LoadingStateAccountBox from '../../../components/AccountBox/components/LoadingStateAccountBox';
import DesktopErrorAccountInformation from '../../../components/ErrorAccountInformation/DesktopErrorAccountInformation/DesktopErrorAccountInformation';
import plusSign from '../../../assets/plusSign.svg';
import { displayCurrency } from '../../../utils/displayCurrency';
import savingsBanner from '../../../assets/savingsBanner.svg'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import ProductsModal from '../../../components/ProductsModal/ProductsModal';
import cdOffer from '../../../assets/cdOffer.svg'
import information from '../../../assets/information.svg'
import { useState } from 'react';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { getSAMLURL } from '../../../shared/config/getSAMLUrl';
import NotificationMessageDB from '../../../shared/digitalbank/Notification/NotificationMessageDB';
import { useNotifications } from '../../../Context/NotificationContext/useNotificationContext';
import { INotificationServiceObject } from '../../../shared/common/Notification/interfaces/INotifcationProps.types';
import { useHistory } from 'react-router-dom';
import { useOpenNewAccount } from '../../../Context/OpenNewAccountContext/useOpenNewAccountContext';
import { IAccountInformation } from '../../../api/getAccountInformation';
import { getAccountDetailsAsync } from '../../../slices/selectedAccountSlice/selectedAccountSlice';
import { populateAccountDetails } from '../../../utils/populateAccountDetails';
import { setAccountInformationByIndex } from '../../../slices/accountInformation/accountInformationSlice';

/**
 * Displays the desktop version of the Account-Overview page.
 * @param props creditCardAccount, CDAccounts, savingsAccounts = Array<IAccountInformation>
 */
export const DesktopAccountOverview = (props: IDesktopAccountOverviewProps) => {
  const { creditCardAccounts, CDAccounts, savingsAccounts, isAccountInformationLoaded, totalSavingsBalance, totalCDBalance, openRatesModal,
    setOpenRatesModal, isAccountDetailsLoaded, gotUserContactInfo } = props;
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation)
  //!this is mock data that can be removed once services are set up
  const [showTooltipOne, setShowTooltipOne] = useState<boolean>(false)
  const [showTooltipTwo, setShowTooltipTwo] = useState<boolean>(false)
  const [showTooltipThree, setShowTooltipThree] = useState<boolean>(false)
  // const { message, type } = useToastMessage()
  const { getCustomerNotifications, handleDismiss, customerArray } = useNotifications()
  // const customerNotifs = getCustomerNotifications()
  // const customerNotifs = displayAccountOverviewCustomerNotifications()
  const history = useHistory()
  const { checkExternalAccounts } = useOpenNewAccount()
  const dispatch = useAppDispatch()

  const handleOnNotifLinkClick = (event: any, notification: any) => {
    if (notification.notificationUrl) {
      // if(notification.notificationType === "BumpUpRate"){
        const arrayIndex = accountInformation.accountInformation.findIndex((account: IAccountInformation) => account.id === notification.accountId)
        if(arrayIndex >= 0){
          const account = accountInformation.accountInformation[arrayIndex]
          repopulateAccountDetails(arrayIndex, account)
        }
      // }
      // if(notification.notificationType === "UpcomingMaturityDate"){

      // }
      history.push(notification.notificationUrl)
    }
  }

  /**Repopulates the account details on a page refresh */
  const repopulateAccountDetails = async (index: number, account: any) => {
    const payload = {arrayIndex: index, account: account}
    const response: any = await dispatch(getAccountDetailsAsync(payload))
    try{
        let tempAccount = populateAccountDetails(accountInformation.accountInformation[index], response.payload.response)
        const payload = {arrayIndex: index, account: tempAccount}
        dispatch(setAccountInformationByIndex(payload))            
    }
    catch{
        console.error()
    }
  }

  return (
    <div>
      {((isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") && isAccountDetailsLoaded !== "Failed" && gotUserContactInfo !== "Failed") &&
        <div className="desktop-account-overview">
          <div className="total-balance-wrapper">
            <TotalBalance />
          </div>
          {isAccountInformationLoaded === "Success" ?
            <>
              <div className="accounts-displayed">
                {/* {customerNotifs.numToDisplay > 0 && <div className="customer-notifications-wrapper">
                  {customerNotifs.notifications.slice(0, customerNotifs.numToDisplay).map((e: INotificationServiceObject) => {
                    return (
                      <div key={e.notificationId} className="customer-notifications-row">
                        <NotificationMessageDB key={e.notificationId} data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotifLinkClick} />
                      </div>)
                  }
                  )} */}
                {customerArray.length > 0 && <div className="customer-notifications-wrapper">
                  {customerArray.map((e: INotificationServiceObject) => {
                    return (
                      <div key={e.notificationId} className="customer-notifications-row">
                        <NotificationMessageDB key={e.notificationId} data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotifLinkClick} />
                      </div>)
                  }
                  )}
                {/* {customerArray.length > 0 && <div className="customer-notifications-wrapper">
                  {customerArray.map((e: INotificationServiceObject) => {
                    return (
                      <div key={e.notificationId} className="customer-notifications-row">
                        <NotificationMessageDB key={e.notificationId} data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotifLinkClick} />
                      </div>)
                  }
                  )} */}
                </div>}
                {creditCardAccounts.length > 0 && (
                  <div className="account-type-wrapper">
                    <>
                      <h4 className="account-title">Credit Cards</h4>
                      {creditCardAccounts.map((account: any, index: number) => {
                        return (
                          <div key={index} className="account-box-wrapper">
                            <AccountBox
                              account={account}
                            />
                          </div>
                        );
                      })}
                    </>
                  </div>
                )}
                {CDAccounts.length > 0 ?
                  <div className="account-type-wrapper">
                    <div className="title-wrap">
                      <span className="account-title h3">CD accounts</span>
                      {CDAccounts.length > 1 && <span className="total-text mobileLarge">Total: {displayCurrency(+totalCDBalance)}</span>}
                    </div>
                    <div className="group-display-wrap">
                      <div className="account-wrap-headers">
                        <span className="header-text semibold">Account</span>
                        <div className="right-side">
                          <span className="header-text apy semibold">APY</span>
                          <span className="header-text balance semibold">Balance</span>
                        </div>
                      </div>
                      {CDAccounts.map((account: any, index: number) => {
                        return (
                          <div key={index} className="account-box-wrapper">
                            <AccountBox
                              account={account}
                              index={index}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  :
                  <div className="account-type-wrapper">
                    <div className="title-wrap">
                      <span className="account-title h3">CD accounts</span>
                    </div>
                    <div className="banner-wrap">
                      <img className="banner-image" src={cdOffer} alt="cd offer" />
                      <button className="Button btn-primary" onClick={checkExternalAccounts}>Open new CD account</button>
                      <div className="tooltip-wrap-one">
                        <img className="tooltip" src={information} alt="tooltip" onMouseOver={() => setShowTooltipOne(true)} onMouseOut={() => setShowTooltipOne(false)} />
                        {showTooltipOne &&
                          <div className="tooltip-message-wrap">
                            <Tooltip
                              anchorPoint='top-left'
                              bodyMessage="After you open a CD account with us, if we increase the interest rate and Annual Percentage Yield (APY) we offer for the same CD product and term you selected within 10 calendar days of your account opening date (account opening date plus 9 calendar days), we will automatically give you the increased rate and APY. The higher rate will apply from the day you opened your CD."
                              headerMessage=''
                              width="350px"
                            />
                          </div>
                        }
                      </div>
                      <div className="tooltip-wrap-two">
                        <img className="tooltip" src={information} alt="tooltip" onMouseOver={() => setShowTooltipTwo(true)} onMouseOut={() => setShowTooltipTwo(false)} />
                        {showTooltipTwo &&
                          <div className="tooltip-message-wrap">
                            <Tooltip
                              anchorPoint='top-left'
                              bodyMessage="Receive a 0.05% Loyalty Rate increase when you renew your CD account with us. The Loyalty Rate increase is subject to change and may be discontinued at any time. When your CD is approaching maturity, visit our CD homepage to see what our current reward is at that time. Please see our FAQs for additional information."
                              headerMessage=''
                              width="350px"
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                {savingsAccounts.length > 0 ?
                  <div className="account-type-wrapper">
                    <>
                      <div className="title-wrap">
                        <span className="account-title h3">Savings accounts</span>
                        {savingsAccounts.length > 1 && <span className="total-text mobileLarge">Total: {displayCurrency(+totalSavingsBalance)}</span>}
                      </div>
                      <div className="group-display-wrap">
                        <div className="account-wrap-headers">
                          <span className="header-text semibold">Account</span>
                          <div className="right-side">
                            <span className="header-text apy semibold">APY</span>
                            <span className="header-text balance semibold">Balance</span>
                          </div>
                        </div>
                        {savingsAccounts.map((account: any, index: number) => {
                          return (
                            <div key={index} className="account-box-wrapper">
                              <AccountBox
                                account={account}
                                index={CDAccounts.length + index}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  </div>
                  :
                  <div className="account-type-wrapper">
                    <span className="account-title h3">Savings accounts</span>
                    <div className="banner-wrap">
                      <img className="banner-image" src={savingsBanner} alt="savings account" />
                      <button className="Button btn-primary" onClick={checkExternalAccounts}>Open a savings account</button>
                      <div className="tooltip-wrap-three">
                        <img className="tooltip" src={information} alt="tooltip" onMouseOver={() => setShowTooltipThree(true)} onMouseOut={() => setShowTooltipThree(false)} />
                        {showTooltipThree &&
                          <div className="tooltip-message-wrap">
                            <Tooltip
                              anchorPoint='top-left'
                              bodyMessage={"Receive a 0.05% Loyalty Rate increase when you renew your CD account.\n\nThe Loyalty Rate increase is subject to change and may be discontinued at any time. When your CD is approaching maturity, visit our CD homepage to to view the current reward rate. See the FAQs for more information."}
                              headerMessage=''
                              width="350px"
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                <div className="new-account-button">
                  <button className="Button btn-openaccount" onClick={checkExternalAccounts}>
                    <img src={plusSign} alt="Plus Symbol" />
                    <span className="new-account-text regular">Open a new account</span>
                  </button>
                </div>
                <span className="current-rates regular">Ready to start saving more?&nbsp;<span className="current-rates-link regular" onClick={() => setOpenRatesModal(true)}>Check out our current rates.</span></span>
                {openRatesModal &&
                  <ProductsModal
                    setShowModal={setOpenRatesModal}
                    CDProductList={accountInformation.CDProductList}
                    bumpProductList={accountInformation.BumpProductList}
                    savingsProductList={accountInformation.SavingsProductList}
                    showSavingsProducts={true}
                    origin="account"
                  />
                }
              </div>

            </>
            :
            <div className="overview-loading-state">
              <LoadingStateAccountBox />
              <LoadingStateAccountBox />
              <LoadingStateAccountBox />
            </div>
          }
        </div>
      }
      {(isAccountInformationLoaded === "Failed" || isAccountDetailsLoaded === "Failed" || gotUserContactInfo === "Failed") &&
        <DesktopErrorAccountInformation />
      }
    </div>
  );
};

