import axios from "axios"
import { bffBaseUrl } from "../../utils/globalVariables"

/**An API get call to retreive the users nickname */
export const getUserNickname_API = async () => {
    return axios.get(`${bffBaseUrl}/customers/nickname`)
}

/**AN API patch call to update the users nickname */
export const patchUserNickname_API = async (nickname: string) => {
    return axios.patch(`${bffBaseUrl}/customers/nickname`,
        {
            nickName: nickname
        }
    )
} 