import { useEffect } from 'react'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { useTransfersLanding } from '../../../components/useTransfersLanding/useTransfersLanding'
import CreateTransferLanding from '../components/CreateTransferLanding/CreateTransferLanding'
import TransferConfirmation from '../components/TransferConfirmation/TransferConfirmation'
import MobileTransferHistory from '../components/TransferHistory/MobileTransferHistory/MobileTransferHistory'
import TransferReview from '../components/TransferReview/TransferReview'
import { ITransfersProps } from '../interfaces/ITransfersProps'
import './MobileTransfers.scss'
import warning from '../../../assets/warning.svg'
import { TransferCards } from '../DesktopTransfers/DesktopTransfers'

/**Renders the mobile version of the transfers landing page */
const MobileTransfers = (props: ITransfersProps) => {
    const { selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions,
        transferAmount, setTransferAmount, transferDate, setTransferDate, topCardIndex, setTopCardIndex, transferHistory, setTransferHistory,
        selectedTransfer, setSelectedTransfer, scheduledTransfers, setScheduledTransfers, totalTransfers, handleClose, selectedTransferRef,
        gotTransferHistory, gotScheduledTransfers, transferErrored, setTransferErrored } = props
    const { transferSelected, setTransferSelected, historySelected, setHistorySelected } = useTransfersLanding()
    const { message, resetToast } = useToastMessage()

    /**clear the toast message if necessary */
    useEffect(() => {
        if (message !== "") {
            resetToast()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return (
        <div className="mobile-transfers-landing">

            <div className="header-wrap" id={"transfer"}>
                <p className="header-text h4">Transfers</p>
            </div>
            <div className="outter-jump-wrap" >
                <div>
                    <div className="jump-menu-wrap" >
                        <div className="left-side">
                            <span className={transferSelected ? "selection-text selected boldRegular" : "selection-text regular"} onClick={(e: any) => { setTransferSelected(true); setHistorySelected(false); document.getElementById("transfer")?.scrollIntoView({ block: "start", behavior: "smooth" }) }}>Make a transfer</span>
                            {transferSelected && <div className="selected-border" />}
                        </div>
                        <div className="right-side">
                            {gotTransferHistory !== "Loading" ?
                                <span className={historySelected ? "selection-text selected boldRegular" : "selection-text regular"} onClick={(e: any) => { setHistorySelected(true); setTransferSelected(false); document.getElementById("history")?.scrollIntoView({ block: "start", behavior: "smooth" }) }}>Transfer history</span>
                                :
                                <span className={"selection-text disabled boldRegular"} >Transfer history</span>
                            }
                            {historySelected && <div className="selected-border" />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-wraps">
                <TransferCards
                    topCardIndex={topCardIndex}
                    selectedFromAccount={selectedFromAccount}
                    setSelectedFromAccount={setSelectedFromAccount}
                    selectedToAccount={selectedToAccount}
                    setSelectedToAccount={setSelectedToAccount}
                    selectionOptions={selectionOptions}
                    transferAmount={transferAmount}
                    setTransferAmount={setTransferAmount}
                    setTransferDate={setTransferDate}
                    setTopCardIndex={setTopCardIndex}
                    transferDate={transferDate}
                    scheduledTransfers={scheduledTransfers}
                    setScheduledTransfers={setScheduledTransfers}
                />
                {gotScheduledTransfers !== "Loading" ?
                    <>
                        {gotScheduledTransfers === "Success" ?
                            <>
                                {scheduledTransfers.length > 0 &&
                                    <div className="transfers-card middle">
                                        <MobileTransferHistory
                                            transferHistory={scheduledTransfers}
                                            setTransferHistory={setScheduledTransfers}
                                            selectedTransfer={selectedTransfer}
                                            setSelectedTransfer={setSelectedTransfer}
                                            title="Scheduled transfers"
                                            totalTransfers={totalTransfers}
                                            handleClose={handleClose}
                                            selectedTransferRef={selectedTransferRef}
                                            key={1}
                                            transferErrored={transferErrored}
                                            setTransferErrored={setTransferErrored}
                                        />
                                    </div>
                                }
                            </>
                            :
                            <div className="transfers-card middle">
                                <div className="transfer-error-wrapper">
                                    <span className="title-text h3">Scheduled transfers</span>
                                    <div className="error-stack">
                                        <img className="erorr-image" src={warning} alt="unable to load data" />
                                        <span className="header-text h4">Unable to load data</span>
                                        <span className="sub-text regular">Please wait a moment and try again.</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div className="loading-box" />
                }
                {gotTransferHistory !== "Loading" ?
                    <>
                        <div id={"history"} style={{ scrollMargin: "100px" }} />
                        <div className="transfers-card" >
                            {gotTransferHistory === "Success" ?
                                <MobileTransferHistory
                                    transferHistory={transferHistory}
                                    setTransferHistory={setTransferHistory}
                                    selectedTransfer={selectedTransfer}
                                    setSelectedTransfer={setSelectedTransfer}
                                    title="Transfer history"
                                    totalTransfers={totalTransfers}
                                    handleClose={handleClose}
                                    selectedTransferRef={selectedTransferRef}
                                    key={2}
                                />
                                :
                                <div className="transfer-error-wrapper history">
                                    <span className="title-text h3">Transfer history</span>
                                    <div className="error-stack">
                                        <img className="erorr-image" src={warning} alt="unable to load data" />
                                        <span className="header-text h4">Unable to load data</span>
                                        <span className="sub-text regular">Please wait a moment and try again.</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <div className="loading-box" id="history" />
                }
            </div >
        </div >
    )
}

export default MobileTransfers