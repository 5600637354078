import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import './CDDetails.scss'
import graceClock from '../../../../assets/graceClock.svg'
import { dateLongHand } from '../../../../utils/dateLongHand'
import { calculateGracePeriodDates } from '../../../../utils/gracePeriodEnd'
import bluePencil from '../../../../assets/bluePencil.svg'
import { useHistory } from 'react-router-dom'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'

/**Renders a component box showing CD Details for use in the maturity settings flow */
const CDDetails = () => {
    const { windowState } = useWindowState()
    const history = useHistory()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)

    return(
        <div className="cd-details-wrapper">
            <span className="header-text-title boldLarge">Your current CD details</span>
            <div className="peronal-cd-wrap">
                <span className="personal-cd-header mobileBoldSmall">Personal CD account</span>
                <span className="personal-cd regular">{selectedAccount.cdGroup === "BumpupCD" ? "Bump-Up Jumbo CD" : "Jumbo CD"} {selectedAccount.term} - CD {selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length - 4, selectedAccount.accountNumber.length)}</span>
            </div>
            <div className="term-apy-row-wrap">
                <div className="current-term-wrap">
                    <span className="curernt-term-header mobileBoldSmall">Current term</span>
                    <span className="current-term regular">{selectedAccount.term}</span>
                </div>
                <div className="apy-wrap">
                    <span className="current-apy-header mobileBoldSmall">Current APY</span>
                    <span className="current-apy regular">{selectedAccount.interestRate?.toFixed(2)}%</span>
                </div>
            </div>
            {selectedAccount.isGracePeriod &&
                <div className="grace-period-link-wrap">
                    <div className="header-wrap">
                        <img src={graceClock} alt="grace period clock" />
                        <span className="header-text boldRegular">Need to make changes to your current CD?</span>
                    </div>
                    <span className="details-text regular">Your CD renewed on {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.graceStartDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}. You can make changes until your grace period ends on {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.graceEndDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.    </span>
                    <div className={windowState !== "Mobile" ? "link-wrap" : "link-wrap mobile"} onClick={() => history.push(`/account/${selectedAccount.id}/graceperiod`)}>
                        <img src={bluePencil} alt="edit" />
                        <span className="change-text regular">Make Changes</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default CDDetails