import axios from "axios";
import { bffBaseUrl } from "../../utils/globalVariables";

/**API Get call to get the users w9 tax withholding settings */
export const getW9TaxWithholding_API = () => {
    return axios.get(`${bffBaseUrl}/customers/tax-withholdings`)
}

/**API Patch call to update the users w9 tax withholding settings */
export const patchW9TaxWithholding_API = (status: boolean) => {
    return axios.patch(`${bffBaseUrl}/customers/tax-withholdings`,
        {
            isTaxWithholding: status
        }
    )
}
