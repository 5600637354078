import {
  useEffect,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';
import { INotificationContext } from './interfaces/INotificationContextProps';
import { patchRemoveNotification_API } from '../../api/removeNotification';
import { getAllNotifications_API } from '../../api/getAllNotifications';
import { INotificationServiceObject } from '../../shared/common/Notification/interfaces/INotifcationProps.types';

/**
* The NotificationContext for handling customer and account level notifications
*/
export const NotificationContext = createContext<INotificationContext | null>(null);


/**
* The provider for the NotificationContext
* @param props
* @returns notification context provider
*/
export const NotificationProvider = (props: any) => {
  const [notifications, setNotifications] = useState<Array<INotificationServiceObject>>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const notificationMap = useRef(new Map());
  const [customerArray, setCustomerArray] = useState<Array<INotificationServiceObject>>([])
  const [accountArray, setAccountArray] = useState<Array<INotificationServiceObject>>([])

  /**
   * Call to get all customer notification messages
   * @returns customer level notification messages
   */
  const getCustomerNotificationsMessages = () =>
    notifications.filter((e) => e.notificationLevel === 'Customer');

  const getAccountNotificationMessages = (accountId: string) =>
    notifications.filter(
      (e) => e.notificationLevel === 'Account' && e.accountId === accountId
    )

  /**Maps notifications to the account that it belongs to */
  const setNotificationMappings = (newNotifications: Array<INotificationServiceObject>) => {
    const mapper = new Map();

    const ACCOUNTS = Array.from(new Set(newNotifications.filter(e => e.accountId)));
    const CUSTOMER_OBJ = initAccountObject();

    CUSTOMER_OBJ.notifications = getCustomerNotificationsMessages();

    mapper.set('Customer', CUSTOMER_OBJ);

    for (let i = 0; i < ACCOUNTS.length; i++) {

      const ACCOUNT_ID = ACCOUNTS[i].accountId

      const OBJ = initAccountObject();
      OBJ.notifications = newNotifications.filter(
        (e) => e.notificationLevel === 'Account' && e.accountId === ACCOUNT_ID
      );
      mapper.set(ACCOUNT_ID, OBJ);

    }

    notificationMap.current = mapper;
  }


  const getAllNotifications = async () => {
    try {
      let response: any = await getAllNotifications_API()
      displayAccountOverviewCustomerNotifications(response.data.Notification)
      displayAccountOverviewAccountNotifications(response.data.Notification)  
      return response.data.Notification || [];
    }
    catch (err) {

      console.log('GET NOTIFICATIONS CALL FAILED', err)
      return [];
    }
  }

  /**Gets the customer level notifications that should be displayed on the account overview page */
  const displayAccountOverviewCustomerNotifications = (notificationsResponse: any) => {
    const sortedArray = notificationsResponse.sort((a: any, b: any) => {
      if(a.priority > b.priority){
        return -1
      }
      if(a.priority < b.priority){
        return 1
      }
      return 0
    })

    const tempArray = sortedArray.slice(0,3)
    // const tempArray = sortedArray

    const tempCustomerArray = tempArray.filter((e: any) => e.notificationLevel === 'Customer')

    setCustomerArray(tempCustomerArray)
  }

  /**Gets the account level notifications that should be displayed on teh account overview page */
  const displayAccountOverviewAccountNotifications = (notificationsResponse: any) => {
    const sortedArray = notificationsResponse.sort((a: any, b: any) => {
      if(a.priority > b.priority){
        return -1
      }
      if(a.priority < b.priority){
        return 1
      }
      return 0
    })

    const tempArray = sortedArray.slice(0,3)
    // const tempArray = sortedArray

    const tempAccountArray = tempArray.filter((e: any) => e.notificationLevel === 'Account')

    setAccountArray(tempAccountArray)
  }

  /**Gets account notifications */
  const initNotifications = async () => {
    let allNotifications = await getAllNotifications()

    const tempArray = allNotifications.sort((a: any, b: any) => {
      if(a.priority > b.priority){
        return -1
      }
      if(a.priority < b.priority){
        return 1
      }
      return 0
    })

    setNotifications(tempArray);
    setNotificationMappings(allNotifications)
    setIsLoaded(true);
  }
  useEffect(() => {
    initNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Call to get all customer level notifications
   * @returns customer level notifications
   */
  const getCustomerNotifications = () => {
    const NOTIFICATIONS = getCustomerNotificationsMessages();

    return {
      notifications: NOTIFICATIONS,
      numToDisplay: NOTIFICATIONS.length > 3 ? 3 : NOTIFICATIONS.length
    };
  };

  const getNumToDisplay = (accountId: string, index: number) => {
    let num = getCustomerNotificationsMessages().length
    const indecies = []
    const KEYS = Array.from(notificationMap.current.keys());
    for (let i = 0; i < KEYS.length; i++) {
      for (let j = 0; j < index; j++) {
        if (notificationMap.current.get(KEYS[i]).index === j) {
          indecies.push(notificationMap.current.get(KEYS[i]).notifications.length);
          break;
        }
      }
    }
    const NUM_TO_DISPLAY = 3 - (num + indecies.reduce((t, e) => t + e, 0))
    if (NUM_TO_DISPLAY <= 0) {
      return 0;
    }
    else {
      if (notificationMap.current.get(accountId).notifications.length <= NUM_TO_DISPLAY) {
        return notificationMap.current.get(accountId).notifications.length
      }
      else {
        return NUM_TO_DISPLAY;
      }
    }
  }

  const doesAllPreviousNotificationsExist = (index: number) => {
    const indecies = []
    const KEYS = Array.from(notificationMap.current.keys());

    for (let i = 0; i < KEYS.length; i++) {
      for (let j = 0; j < index; j++) {
        if (notificationMap.current.get(KEYS[i]).index === j) {
          indecies.push(j);
          break;
        }
      }
    }

    return indecies.length === index;
  }

  const getNotifications = (accountId: string, index?: number) => {
    const NOTIFICATIONS = getAccountNotificationMessages(accountId);

    return {
      notifications: NOTIFICATIONS,
      numToDisplay: index !== undefined ? getNumToDisplay(accountId, index) : 3
    }
  }


  const initAccountObject = () => ({
    index: -1,
    notifications: [] as Array<INotificationServiceObject>
  })

  const removeNotification = (notificationIndex: number) => {
    let NEW_NOTIFICATIONS = JSON.parse(JSON.stringify(notifications));
    NEW_NOTIFICATIONS.splice(notificationIndex, 1);
    setNotificationMappings(NEW_NOTIFICATIONS);
    setNotifications(NEW_NOTIFICATIONS);
    displayAccountOverviewCustomerNotifications(NEW_NOTIFICATIONS)
    displayAccountOverviewAccountNotifications(NEW_NOTIFICATIONS)
  }

  const handleDismiss = async (notificationId: string, callApi:boolean = true) => {

    const NOTIFICATION_INDEX = notifications.findIndex(e => e.notificationId === notificationId);

    if (NOTIFICATION_INDEX !== -1) {
      try {
        removeNotification(NOTIFICATION_INDEX)
        if(callApi){
          await patchRemoveNotification_API(notificationId)
        }
      }
      catch {
        console.log('REMOVE NOTIFICATIONS CALL FAILED')
      }

      return Promise.resolve(true)
    }

    return Promise.reject(false);
  }

  /**
   * Call to get all account level notifications
   * @param accountId id of account where notification exists
   * @param index position within the notification array
   * @returns notifications array and number to display
   */
  const getAccountNotifications = async (accountId: string, index?: number) => {
    return new Promise((resolve, reject) => {
      if (index !== undefined) {
        const MAPPER = notificationMap.current.get(accountId) || initAccountObject();

        MAPPER.index = index;
        notificationMap.current.set(accountId, MAPPER);

        if (doesAllPreviousNotificationsExist(index)) {
          resolve(getNotifications(accountId, index));
        }
        else {
          let interval: NodeJS.Timeout;
          interval = setInterval(() => {
            resolve(getNotifications(accountId, index))
            clearInterval(interval);
          }, 100)
        }
      }
      else {
        resolve(getNotifications(accountId, index))
      }
    })
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        getAccountNotifications,
        getCustomerNotifications,
        isLoaded,
        handleDismiss,
        initNotifications,
        customerArray,
        accountArray
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

/**
* A custom use hook for the notification context provider
* prioritizes customer level notifications over account level notifications
* @returns notification context
*/
export const useNotifications = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      `Can't use useNotifications outside of NotificationProvider`
    );
  }

  return context as INotificationContext
};

/**
* A custom use hook to set account notfication messages
* @param accountId id of account where notification exists
* @param index position within the notification array
* @returns accountNotifications array and how many to display
*/
export const useAccountNotifications = (accountId: string, index?: number) => {
  const [accountNotifications, setAccountNotifications] = useState({
    notifications: [],
    numToDisplay: 0
  });

  const { notifications, getAccountNotifications, isLoaded, handleDismiss } = useNotifications();

  const setAccount = async () => {
    setAccountNotifications(await getAccountNotifications(accountId, index));
  }
  useEffect(() => {

    if (isLoaded) {
      setAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notifications,
    accountId,
    index,
    setAccountNotifications,
    getAccountNotifications,
    isLoaded
  ]);

  return { accountNotifications, handleDismiss };
};
