import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import { useMaturitySettingsFlow } from '../../../useHooks/componentHooks/useMaturitySettingsFlow/useMaturitySettingsFlow'
import { IMobileMaturitySettingsProps } from './interfaces/IMobileMaturitySettingsProps'
import './MobileMaturitySettings.scss'
import redTrashCan from '../../../assets/redTrashCan.svg'
import CancelTransferModal from '../components/CancelTransferModal/CancelTransferModal'
import blueEditPencil from '../../../assets/blueEditPencil.svg'
import PlanChangeOptions from '../components/PlanChangeOptions/PlanChangeOptions'
import TransferAtMaturity from '../components/TransferAtMaturity/TransferAtMaturity'
import UpdateMaturityPlan from '../components/UpdateMaturityPlan/UpdateMaturityPlan'
import MobileCDSelection from '../components/CDSelection/MobileCDSelection/MobileCDSelection'
import MobileTransferFunds from '../components/TransferFunds/MobileTransferFunds/MobileTransferFunds'
import { displayCurrency } from '../../../utils/displayCurrency'
import CDDetails from '../components/CDDetails/CDDetails'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { dateLongHand } from '../../../utils/dateLongHand'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useEffect, useState } from 'react'


/**Renders the Maturity Settings flow for the Mobile Display */
const MobileMaturitySettings = (props: IMobileMaturitySettingsProps) => {
    const { planChange, setPlanChange, termChange, setTermChange, manageFunds, setManageFunds,
        openCancelFunds, setOpenCancelFunds, changePlanOption, setChangePlanOption, openChangeOption, setOpenChangeOption,
        chosenTerm, setChosenTerm, showTermChange, setShowTermChange, firstStepCompleted, setFirstStepCompleted,
        transferType, setTransferType, CDProductList, bumpProductList, transferAccount, setTransferAccount } = props
    const { selectedAccount, isAccountDetailsLoaded, type, message } = useMaturitySettingsFlow()
    const [changeProduct, setChangeProduct] = useState<string>("")

    useEffect(() => {
        if(selectedAccount.maturitySetting.changeProduct && selectedAccount.maturitySetting.changeProduct !== ""){
            const [group, year, month] = selectedAccount.maturitySetting.changeProduct.split(" ")
            let cdGroup = group === "JumboCD" ? "Jumbo CD" : "Bumpup CD"
            setChangeProduct(`${cdGroup} ${year} ${month}`)
        }
    }, [selectedAccount.maturitySetting.changeProduct])

    return (
        <MobileLayout>
            <div className="mobile-maturity-main-wrapper">
                {(!planChange && !termChange && !manageFunds) &&
                    <>
                        {message !== "" && <ToastMessageDB type={type} message={message} />}
                        <AccountHeader returnMessage="Account Settings" setDisplayDetails={false} />
                        {isAccountDetailsLoaded !== "Loading" ?
                            <div className="maturity-card-wrapper">
                                <>
                                    <div className="card-header-wrap">
                                        <span className="title-text mobileh2">Maturity plan</span>
                                        <span className="mature-text small">Choose what happens when your CD matures. Your maturity plan will go into effect when your CD matures on {dateLongHand(convertDateTommddyyyy(selectedAccount.maturityDate))}.</span>
                                    </div>
                                    <div className="column-cards-wrapper">
                                        <div className="left-box">
                                            <span className="maturity-plan-title mobileBoldLarge">Your maturity plan</span>
                                            <div className="maturity-date-wrap">
                                                <span className="maturity-date-header mobileBoldSmall">Maturity date</span>
                                                <span className="maturity-date-text regular">{convertDateTommddyyyy(selectedAccount.maturityDate)}</span>
                                            </div>
                                            <div className="action-wrap">
                                                <div className="left-side">
                                                    {selectedAccount.maturitySetting.option !== "Close" ?
                                                        <>
                                                            <span className="action-header mobileBoldSmall">Action at maturity</span>
                                                            <span className="action regular">{selectedAccount.maturitySetting.option}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="action-header mobileBoldSmall">Action at maturity</span>
                                                            <span className="action regular">Close CD</span>
                                                        </>
                                                    }
                                                </div>
                                                <div className="right-side" onClick={() => { setPlanChange(true); setOpenChangeOption(true) }}>
                                                    <img src={blueEditPencil} alt="Change action" />
                                                </div>
                                            </div>
                                            {selectedAccount.maturitySetting.option !== "Close" &&
                                                <>
                                                    <div className="action-wrap">
                                                        <div className="left-side">
                                                            <span className="action-header mobileBoldSmall">Renewal term</span>
                                                            {selectedAccount.maturitySetting.option === "Renew" && <span className="action regular">{changeProduct}</span>}
                                                        </div>
                                                        <div className="right-side" onClick={() => setTermChange(true)}>
                                                            <img src={blueEditPencil} alt="Change term" />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="action-wrap no-border">
                                                {selectedAccount.maturitySetting.option !== "Close" ?
                                                    <div className="left-side">
                                                        <span className="action-header mobileBoldSmall">Fund Transfer at Maturity</span>
                                                        {(selectedAccount.maturitySetting.transferAmount && selectedAccount.maturitySetting.transferAmount > 0) ?
                                                            <span className="action regular">Transfer {displayCurrency(+selectedAccount.maturitySetting.transferAmount as number)} {selectedAccount.maturitySetting.transferType === "Add" ? "from" : "To"} {selectedAccount.maturitySetting.transferAccount}</span>
                                                            :
                                                            <span className="action regular">None</span>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="left-side">
                                                        <span className="action-header mobileBoldSmall">Transfer Balance To</span>
                                                        <span className="action regular">{selectedAccount.maturitySetting?.closedAccountName}</span>
                                                    </div>
                                                }
                                                {selectedAccount.maturitySetting.option !== "Close" ?
                                                    <>
                                                        {(selectedAccount.maturitySetting.transferAmount && selectedAccount.maturitySetting.transferAmount > 0) ?
                                                            <div className="right-side" onClick={() => setOpenCancelFunds(true)}>
                                                                <img src={redTrashCan} alt="remove" />
                                                            </div>
                                                            :
                                                            <div className="right-side" onClick={() => setManageFunds(true)}>
                                                                <img src={blueEditPencil} alt="Fund Transfer at Maturity" />
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <div className="right-side" onClick={() => { setPlanChange(true); setChangePlanOption("Close"); setShowTermChange(false) }}>
                                                        <img src={blueEditPencil} alt="Change transfer balance account" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={!selectedAccount.isGracePeriod ? "right-box" : "right-box gracie"}>
                                            <CDDetails />
                                        </div>
                                    </div>
                                </>
                            </div>
                            :
                            <div className="mobile-maturity-loading-state-wrapper">
                                <div className="mobile-maturity-loading-state"></div>
                            </div>
                        }
                    </>
                }
                {planChange &&
                    <>
                        {openChangeOption &&
                            <PlanChangeOptions
                                setChangePlanOption={setChangePlanOption}
                                setOpenChangeOption={setOpenChangeOption}
                                setPlanChange={setPlanChange}
                            />
                        }
                        {(changePlanOption === "Renew" && !showTermChange) &&
                            <MobileCDSelection
                                chosenTerm={chosenTerm}
                                setChosenTerm={setChosenTerm}
                                setPlanChange={setPlanChange}
                                setOpenChangeOption={setOpenChangeOption}
                                setChangePlanOption={setChangePlanOption}
                                setShowTermChange={setShowTermChange}
                                setTermChange={setTermChange}
                                termChange={termChange}
                                CDProductList={CDProductList}
                                bumpProductList={bumpProductList}
                            />
                        }
                        {(changePlanOption === "Close" && !showTermChange) &&
                            <TransferAtMaturity
                                setPlanChange={setPlanChange}
                                setShowTermChange={setShowTermChange}
                                setChangePlanOption={setChangePlanOption}
                                transferAccount={transferAccount}
                                setTransferAccount={setTransferAccount}
                            />
                        }
                        {showTermChange &&
                            <UpdateMaturityPlan
                                changePlanOption={changePlanOption}
                                setPlanChange={setPlanChange}
                                setShowTermChange={setShowTermChange}
                                chosenTerm={chosenTerm}
                                setChangePlanOption={setChangePlanOption}
                                setTermChange={setTermChange}
                                termChange={termChange}
                                setManageFunds={setManageFunds}
                                transferType={transferType}
                                setTransferType={setTransferType}
                            />
                        }
                    </>
                }
                {termChange &&
                    <>
                        {!showTermChange ?
                            <MobileCDSelection
                                chosenTerm={chosenTerm}
                                setChosenTerm={setChosenTerm}
                                setPlanChange={setPlanChange}
                                setOpenChangeOption={setOpenChangeOption}
                                setChangePlanOption={setChangePlanOption}
                                setShowTermChange={setShowTermChange}
                                setTermChange={setTermChange}
                                termChange={termChange}
                                CDProductList={CDProductList}
                                bumpProductList={bumpProductList}
                            />
                            :
                            <UpdateMaturityPlan
                                changePlanOption={changePlanOption}
                                setPlanChange={setPlanChange}
                                setShowTermChange={setShowTermChange}
                                chosenTerm={chosenTerm}
                                setChangePlanOption={setChangePlanOption}
                                setTermChange={setTermChange}
                                termChange={termChange}
                                setManageFunds={setManageFunds}
                                transferType={transferType}
                                setTransferType={setTransferType}
                            />
                        }
                    </>
                }
                {manageFunds &&
                    <>
                        {!showTermChange ?
                            <MobileTransferFunds
                                setManageFunds={setManageFunds}
                                setShowTermChange={setShowTermChange}
                                firstStepCompleted={firstStepCompleted}
                                setFirstStepCompleted={setFirstStepCompleted}
                                setTransferType={setTransferType}
                                transferAccount={transferAccount}
                                setTransferAccount={setTransferAccount}
                            />
                            :
                            <UpdateMaturityPlan
                                changePlanOption={changePlanOption}
                                setPlanChange={setPlanChange}
                                setShowTermChange={setShowTermChange}
                                chosenTerm={chosenTerm}
                                setChangePlanOption={setChangePlanOption}
                                setTermChange={setTermChange}
                                termChange={termChange}
                                setManageFunds={setManageFunds}
                                transferType={transferType}
                                setTransferType={setTransferType}
                            />
                        }
                    </>
                }
                {openCancelFunds &&
                    <CancelTransferModal
                        setOpenCancelFunds={setOpenCancelFunds}
                    />
                }
            </div>
        </MobileLayout>
    )
}

export default MobileMaturitySettings