import { INotificationMessageProps } from "./interfaces/INotifcationProps.types";
import close from "../../../assets/close.svg";
import redExclamationCircle from "../../../assets/redExclamationCircle.svg";
import greenCheckmark from "../../../assets/greenCheckmark.svg";
import yellowWarning from "../../../assets/yellowWarning.svg";
import blueInfoCircle from "../../../assets/blueInfoCircle.svg";
import bumpCircle from '../../../assets/bumpCircle.svg'
import graceClock from '../../../assets/graceClock.svg'
import arrowRight from '../../../assets/arrowRight.svg'
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { IAccountInformation } from "../../../api/getAccountInformation";
import { getAccountDetailsAsync, setSelectedAccount } from "../../../slices/selectedAccountSlice/selectedAccountSlice";
import { populateAccountDetails } from "../../../utils/populateAccountDetails";
import { setAccountInformationByIndex } from "../../../slices/accountInformation/accountInformationSlice";
import { useRef } from "react";
import { useWindowState } from "../../../Context/AccountContext/useWindowState";
//import { removeNotification } from '../../../api/removeNotification';

/**
 * A notification message with a title and body row of text, an icon, and a border around the message. 
 * @param props INotificationMessageProps which consists notifications api data object and various classNames for styling.
 * @returns 
 */
const NotificationMessage = (props: INotificationMessageProps) => {
  const { notificationId, notificationBody, notificationTitle, notificationIndicator, notificationLinkText,
    notificationUrl, dismissible, notificationType, accountId } = props.data
  const { iconClassName = "",
    wrapperClassName = "",
    messageWrapperClassName = "",
    subHeaderTextClassName = "",
    headerTextClassName = "",
    linkTextClassName = "",
    notificationIndicatorClassName = notificationIndicator.toLowerCase(),
    onDismiss,
    onLinkClick = () => { } } = props
    const history = useHistory()
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const debouncerRef = useRef<boolean>(false)
    const { windowState } = useWindowState()

  const linkOnClick = (event: any) => {
    if (notificationUrl) {
      onLinkClick(event, props.data);
    }
  }

  const handleOnDismiss = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    //removeNotification(notificationId);
    onDismiss(notificationId)
  }

  const getNotificationIcon = (indicator: string, type: string) => {
    switch (indicator) {
      case 'error':
        return (<img alt="red error exclamation mark" className={iconClassName} src={redExclamationCircle}></img>)
      case 'information':
        if(type === "bumpuprate"){
          return (<img alt="blue information symbol" className={iconClassName} src={bumpCircle}></img>)
        }
        if(type === "graceperiod"){
          return (
            <div className={`grace-period-image-wrap ${windowState}`}>
              <img alt="blue information symbol" className={`${iconClassName} grace-period ${windowState}`} src={graceClock}></img>
            </div>
          )
        }
        return (<img alt="blue information symbol" className={iconClassName} src={blueInfoCircle}></img>)
      case 'warning':
        return (<img alt="yellow warning symbol" className={iconClassName} src={yellowWarning}></img>)
      case 'confirmation':
        return (<img alt="green check mark" className={iconClassName} src={greenCheckmark}></img>)
      // ...continue as necessary
    }
  }

  const transformBumpTitleText = () => {
    return "Great news"
  }

  const transformBumpBodyText = () => {
    return "your CD is eligible for a rate increase!"
  }

  /**Handles the onClick navigation event for the grace period banner */
  const handleClick = async (e: any) => {
    if(notificationType.toLowerCase() === "graceperiod" && !debouncerRef.current){
      debouncerRef.current = true
      e.stopPropagation()
      if(selectedAccount.id === "" || selectedAccount.id === accountId){
        history.push(`/account/${accountId}/graceperiod`)
      }
      else if(selectedAccount.id !== accountId ){
        const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => accountId === account.id)
        if(arrayIndex >= 0){
          const payload = {arrayIndex: arrayIndex, account: accountInformation[arrayIndex]}
          try{
            const response: any = await dispatch(getAccountDetailsAsync(payload))
            let tempAccount = populateAccountDetails(accountInformation[arrayIndex], response.payload.response)
            const payload2 = {arrayIndex: arrayIndex, account: tempAccount}
            dispatch(setAccountInformationByIndex(payload2))
            history.push(`/account/${accountId}/graceperiod`)
          }
          catch{
            console.log("FAILED TO GET THE ACCOUNT DETAILS")
          }
        }  
      }
      debouncerRef.current = false
    }
  }

  return (
    <div id={notificationId} className={`${wrapperClassName} ${notificationIndicatorClassName}-notification ${notificationType.toLowerCase()}`} onClick={(e) => handleClick(e)}>
      {getNotificationIcon(notificationIndicator.toLowerCase(), notificationType.toLowerCase())}
      <div className={`${messageWrapperClassName} ${notificationType.toLowerCase()}`}>
        <div className={windowState === "Mobile" && notificationType.toLowerCase() === "bumpuprate" ? `title-row-wrapper ${notificationType.toLowerCase()} mobile-bump` : `title-row-wrapper ${notificationType.toLowerCase()}`}>
          <div className={`left-side ${notificationType.toLowerCase()}`}>
            {notificationType.toLowerCase() !== "bumpuprate" ?
              <span className={headerTextClassName}>{notificationTitle}</span>
              :
              <span className={`notification-bump-row semiboldRegular ${windowState}`}>{transformBumpTitleText()}<span className={`dash-line ${windowState}`}/><span className="regular">{transformBumpBodyText()}</span><span onClick={linkOnClick} className={linkTextClassName}>&nbsp;{notificationLinkText}</span></span>
            }
            <div>
              <span className={subHeaderTextClassName}>{notificationBody} </span>
              {(notificationLinkText && notificationType.toLowerCase() !== "graceperiod" && notificationType.toLowerCase() !== "bumpuprate") &&
                <span onClick={linkOnClick} className={linkTextClassName}>{notificationLinkText}</span>
              }
            </div>
          </div>
          {dismissible &&
            <div className={`right-side ${notificationType.toLowerCase()}`}>
              {notificationType.toLowerCase() === "graceperiod" ?
                <img alt="Close notification message" className="close-button" src={arrowRight}></img>
                :
                <img onClick={(e) => handleOnDismiss(e)} alt="Close notification message" className={windowState === "Mobile" && notificationType.toLowerCase() === "bumpuprate" ? "close-button mobile-bump" : "close-button"} src={close}></img>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
};

export default NotificationMessage;
