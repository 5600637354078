import { useEffect, useRef, useState } from "react";
import { IGetFastLinkAccessTokenResponse, IYodleeFastLinkProps } from "../Interfaces/IYodleeFastLinkProps";
import { getYodleeToken_API } from "../../../api/User/yodleeServices";
import { useLinkExternalAccount } from "../../../Pages/LinkExternalAccount/Context/useLinkExternalAccount";
import { getEnvironment } from "../../../utils/getEnvironment";
import { CONFIG } from "../../../utils/globalVariables";


/**A custom use hook that handles Yodlee Fast Link  */
export const useYodleeFastLink = (props: IYodleeFastLinkProps) => {
    const { onError, onSuccess, onClose, onEvent, routingNumber } = props

    const { isYodleeFastLinkLoaded, setIsYodleeFastLinkLoaded, yodleeToken } = useLinkExternalAccount()

    // const accessToken = useRef('');
    const eventCountRef = useRef<number>(0)
    const onErrorRef = useRef<boolean>(false)

    const [isFastLinkLoaded, setIsFastLinkLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [reloadFastLink, setReloadFastLink] = useState<boolean>(false)

    /**
     * The FastLink config url
     */
    // const FAST_LINK_URL = getEnvironment() === "PROD" ? 'https://finapp.creditonebank.yodlee.com/authenticate/creditonebank/fastlink/' : 'https://finapp.creditonebankstage.yodlee.com/authenticate/creditonebank/fastlink/';
    const FAST_LINK_URL = CONFIG.YODLEE_URL

    /**
     * The configuration name for FastLink
     */
    const CONFIG_NAME = 'DigitalBankVerification';

    /**
     * Returns the access token for adding to the Yodlee SDK.
     * @returns - The API repsonse
     */
    // : Promise<string>
    const getFastLinkKey = async () => {
      // let FAST_LINK_KEY = ""
      // try{
      //   const response = await getYodleeToken_API()
      //   FAST_LINK_KEY = response.data.token.accessToken
      // }
      // catch{
      //   console.log("ERROR RETREIVING THE FAST LINK TOKEN")
      // }
      return yodleeToken
      
      
        // return Promise.resolve('oIAjWRGy33n67r2mZRj66G7XC4T4');
     
        //TODO This api call should change to a BFF URL to call.
        // return new Promise((resolve, reject) => {
     
        //   let myHeaders = new Headers();
        //   myHeaders.append("loginName", LOGIN_NAME);
        //   myHeaders.append("apiKey", API_KEY);
        //   myHeaders.append("Api-Version", API_VERSION);
     
        //   let requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders
        //   };
     
        //   fetch(GET_TOKEN_URL, requestOptions)
        //     .then(response => response.json())
        //     .then((result: IGetFastLinkAccessTokenResponse) => {
        //       accessToken.current = result.token.accessToken;
        //       resolve(result.token.accessToken);
        //     })
        //     .catch(error => reject(error));
        // })
      } 
     

     
      //* Adds the FastLink script
      useEffect(() => {
        if((window as any).fastlink){
          setIsFastLinkLoaded(true)
          return
        }
        if (!(window as any).fastlink && !isFastLinkLoaded) {
          const SCRIPT_URL = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';
          const SCRIPT_TAG = document.createElement('script');
          SCRIPT_TAG.src = SCRIPT_URL;
          document.head.appendChild(SCRIPT_TAG);
          let interval: any;
          interval = setInterval(() => {
            if ((window as any).fastlink) {
              setIsFastLinkLoaded(true);
              clearInterval(interval)
            }
          }, 100)
        }
     
      }, [])
     
      //* Loads the FastLink Iframe after the script it loaded.
      useEffect(() => {
     
        if (!(window as any).fastlink || !isFastLinkLoaded) {
          return;
        }
     
        //? The accessToken is revoked after 25 minutes. Might want to add a way to re-invoke if the timeout happens.
        getFastLinkKey()
          .then(yodleeToken => {
            if((window as any).fastlink){
              (window as any).fastlink.close()
            }
            let PARAMS: any
            if(onErrorRef.current){
              PARAMS = JSON.parse(JSON.stringify({
                configName: CONFIG_NAME,
              }));
            }
            else{
              PARAMS = JSON.parse(JSON.stringify({
                flow: props.routingNumber ? 'add' : undefined,
                configName: CONFIG_NAME,
                routingNumber: props.routingNumber || undefined
              }));
            }
            // console.log({
            //   fastLinkURL: FAST_LINK_URL,
            //   // fastLinkURL: 'https://finapp.creditonebank.yodlee.com/authenticate/creditonebank/fastlink/',
            //   accessToken: `Bearer ${yodleeToken}`,
            //   forceIframe: true,
            //   params: PARAMS,
            //   onSuccess,
            //   onError,
            //   onClose,
            //   onEvent
            // });
            (window as any).fastlink.open({
              fastLinkURL: FAST_LINK_URL,
              // fastLinkURL: 'https://finapp.creditonebank.yodlee.com/authenticate/creditonebank/fastlink/',
              accessToken: `Bearer ${yodleeToken}`,
              // accessToken: `Bearer pV8l1cdZXiaCwxEcuum9XJUnOmK8`,
              forceIframe: true,
              params: PARAMS,
              onSuccess,
              onError: onLoadError,
              onClose,
              onEvent: onLoadEvent
            },
              'container-fastlink');
          })
          .catch(error => console.log('get access token failed', error));
        //? I don't know what happens if this fails to load. Options will be dependant on what UX provides.
        //? OPTION 1: Probably just add another component for the error handling.
        //? OPTION 2: Call props.onError and let the parent decide.
      }, [isFastLinkLoaded, reloadFastLink])

      const onLoadError = (data: any) => {
        if(data.code && data.code === "E801"){
          onErrorRef.current = true
          setReloadFastLink(true)
          setTimeout(() => {
            setIsLoaded(true)
          }, 700)
        }
        onError(data)
      }

      const onLoadEvent = (data: any) => {
        if(data.fnToCall && data.fnToCall === "renewClientSession" && !onErrorRef.current){
          eventCountRef.current += 1
          // setTimeout(() => {
            setIsLoaded(true)
            // setIsYodleeFastLinkLoaded(true)
          // }, 1000)
        }
        else{
          onEvent(data)
          setIsLoaded(true)
          setIsYodleeFastLinkLoaded(true)
        }
      }
  
    return { isLoaded }
}