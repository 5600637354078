import { useEffect, useState } from 'react';
import { IBeneficiary } from '../../../../api/getBeneficiaries';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IFormContext } from '../../../../Form/useForm/useForm';
import { checkControlValues } from '../../../../utils/IsFormFilled';

export const useAddCharityRequired = (
  setNewBeneficiary: React.Dispatch<React.SetStateAction<IBeneficiary>>,
  newBeneficiary: IBeneficiary,
  handleOnFinish: (updatedBeneficiary: IBeneficiary) => void,
  form: IFormContext
  ) => {
  const [formFilled, setFormFilled] = useState(false);
  const selectedAccount = useAppSelector((state:RootState) => state.selectedAccount.selectedAccount)

  /**
   * When page is rendered, there's a check to see if the form is already filled
   */
  useEffect(() => {
    setFormFilled(checkControlValues(form.controls, "Charity"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.controls]);

  /**
   * Sets the new beneficiary to what the user enetered in the form, then goes to the enxt page
   */
  const handleOnSubmit = () => {
    const inputValues = form.controls
    setNewBeneficiary({
      ...newBeneficiary,
      name: inputValues.charityName.value,
      charityName: inputValues.charityName.value,
      tin: inputValues.tin.value,
      address1: inputValues.address1.value.trim(),
      address2: inputValues.address2.value.trim(),
      city: inputValues.city.value.trim(),
      state: inputValues.state.value,
      zipCode: inputValues.zipCode.value,
      phoneNumber: inputValues.phoneNumber.value,
      email: inputValues.email.value,
      type: "Charity",
      percentage: selectedAccount.beneficiaries.length > 0 ? 0 : 100
    });
  };
  return {
    handleOnSubmit,
    setFormFilled,
    formFilled,
  };
};
