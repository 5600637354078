import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useStatementsPage } from "../../useHooks/pageHooks/useStatementsPage"
import DesktopStatementsPage from "./DesktopStatementsPage/DesktopStatementsPage"
import MobileStatementsPage from "./MobileStatementsPage/MobileStatementsPage"

/**
 * This is the wrapper component for the statemetns page.  It determines which versoin of the page that 
 * should be shown.
 */
const StatementsPageWrapper = () => {
    const { windowState, filterYear, setFilterYear, filteredStatements, showPDF, setShowPDF, statementYearsRef,
            openFilterMenu, setOpenFilterMenu, tempSelectedYear, setTempSelectedYear, selectedAccount, startIndex, 
            setStartIndex, endIndex, setEndIndex, numberOfItemsToShow, setNumberOfItemsToShow  } = useStatementsPage()

    return(
        <div>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopStatementsPage 
                            filterYear={filterYear} 
                            setFilterYear={setFilterYear} 
                            filteredStatements={filteredStatements} 
                            showPDF={showPDF}
                            setShowPDF={setShowPDF}
                            statementYearsRef={statementYearsRef}
                            openFilterMenu={openFilterMenu}
                            setOpenFilterMenu={setOpenFilterMenu}
                            selectedAccount={selectedAccount}
                            startIndex={startIndex}
                            setStartIndex={setStartIndex}
                            endIndex={endIndex}
                            setEndIndex={setEndIndex}
                            numberOfItemsToShow={numberOfItemsToShow}
                            setNumberOfItemsToShow={setNumberOfItemsToShow}
                        />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopStatementsPage 
                            filterYear={filterYear} 
                            setFilterYear={setFilterYear} 
                            filteredStatements={filteredStatements} 
                            showPDF={showPDF}
                            setShowPDF={setShowPDF}
                            statementYearsRef={statementYearsRef}
                            openFilterMenu={openFilterMenu}
                            setOpenFilterMenu={setOpenFilterMenu}
                            selectedAccount={selectedAccount}
                            startIndex={startIndex}
                            setStartIndex={setStartIndex}
                            endIndex={endIndex}
                            setEndIndex={setEndIndex}
                            numberOfItemsToShow={numberOfItemsToShow}
                            setNumberOfItemsToShow={setNumberOfItemsToShow}
                        />
                    </HybridLayout>
                }
                </>
                :
                <MobileStatementsPage 
                    filterYear={filterYear} 
                    setFilterYear={setFilterYear} 
                    filteredStatements={filteredStatements}
                    showPDF={showPDF}
                    setShowPDF={setShowPDF}
                    statementYearsRef={statementYearsRef}
                    openFilterMenu={openFilterMenu}
                    setOpenFilterMenu={setOpenFilterMenu}
                    tempSelectedYear={tempSelectedYear} 
                    setTempSelectedYear={setTempSelectedYear}
                    selectedAccount={selectedAccount}
                    startIndex={startIndex}
                    setStartIndex={setStartIndex}
                    endIndex={endIndex}
                    setEndIndex={setEndIndex}
                    numberOfItemsToShow={numberOfItemsToShow}
                    setNumberOfItemsToShow={setNumberOfItemsToShow}
                />
            }
        </div>
    )
}

export default StatementsPageWrapper