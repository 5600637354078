import './RequiredConsentModal.scss'
import consentForm from '../../assets/consentForm.svg'
import { useWindowState } from '../../Context/AccountContext/useWindowState'
import ReviewTerms from './components/ReviewTerms/ReveiwTerms'
import { useEffect, useState } from 'react'
import greenCheckmark from '../../assets/greenCheckmark.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { updateIsConsentRequired } from '../../slices/accountInformation/accountInformationSlice'
import { RootState } from '../../app/store'

/**Renders the modal for required consent. This only renders for CD type accounts that have rolled over to a new term agreement */
const RequiredConsentModal = () => {
    const { windowState } = useWindowState()
    const isConsentRequired = useAppSelector((state: RootState) => state.accountInformation.isConsentRequired)
    // const isConsentRequired = false
    const [consentModalStep, setConsentModalStep] = useState<number>(1)
    const consentRequiredAccounts = useAppSelector((state: RootState) => state.accountInformation.consentRequiredAccounts)
    const dispatch = useAppDispatch()
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)

    /**Lock the scroll bar on mount, unlock on unmount */
    useEffect(() => {
        if(isConsentRequired){
            document.body.style.overflow = "hidden";
        }
        else{
            document.body.style.overflow = "auto";
        }
        return () => { document.body.style.overflow = "auto"; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConsentRequired])

    if (!isConsentRequired || gotUserContactInfo === "Failed") {
        return null;
    }

    return (
        <div className={windowState !== "Mobile" ? "consent-modal-overlay" : consentModalStep === 1 ? "consent-modal-overlay" : "consent-modal-overlay mobile"}>
            <div className={windowState !== "Mobile" ? "consent-modal-data" : consentModalStep === 1 ? "consent-modal-data mobile" : "consent-modal-data mobile padding"} >
                {consentModalStep === 1 &&
                    <>
                        <img className="image" src={consentForm} alt="consent form" />
                        <span className="title h4">Action needed</span>
                        <span className="text regular">Your {consentRequiredAccounts.length === 1 ? "CD has" : "CDs have"} renewed! To access your account, take a moment to review and accept our deposit agreement.</span>
                        <button className={windowState !== "Mobile" ? "Button btn-primary" : "Button btn-primary mobile"} onClick={() => setConsentModalStep(2)}>Review terms</button>
                    </>
                }
                {consentModalStep === 2 &&
                    <ReviewTerms
                        setConsentModalStep={setConsentModalStep}
                        consentRequiredAccounts={consentRequiredAccounts}
                    />
                }
                {consentModalStep === 3 &&
                    <>
                        <img className="completed-image" src={greenCheckmark} alt="green checkmark circle" />
                        <span className="title main h4">You’re all set</span>
                        <span className="text regular">You can access the deposit agreement anytime on the Agreements tab of the document center. You may now continue to your Accounts page.</span>
                        <button className={windowState !== "Mobile" ? "Button btn-primary" : "Button btn-primary mobile"} onClick={() => dispatch(updateIsConsentRequired(false))}>Go to accounts</button>
                    </>
                }
            </div>
        </div>
    )
}

export default RequiredConsentModal