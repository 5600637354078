import './Checkbox.scss'
import { ICheckboxProps } from './interfaces/ICheckboxProps'
import check from '../../assets/whiteCheckmark.svg'

/**Renders a custom checkbox component */
const Checkbox = (props: ICheckboxProps) => {
    const { showCheckmark, setShowCheckmark, onClickHandler, disabled=false } = props

    return(
        <div className="Checkbox-Component-Wrapper">
        <label className="Checkbox-Wrapper" data-testid="checkbox">
          <input
            data-testid="checkbox-input"
            className={"checkbox-input"}
            type="checkbox"
            style={{ height: '18px', width: '18px' }}
            onClick={() => {setShowCheckmark(!showCheckmark); onClickHandler && onClickHandler()}}
            disabled={disabled}
          />
          <div className="Checkbox">
            {/* {isLoaded && ( */}
              <div
                data-testid="clicked"
                className={showCheckmark ? 'Inner-Checkbox' : disabled ? 'shrink-checkbox disabled' : "shrink-checkbox"}
              >
                {showCheckmark && (
                  <img
                    src={check}
                    alt="checkmark symbol"
                    className="check-symbol"
                    style={{width: "17px", height: "17px"}}
                  />
                )}
              </div>
            {/* )} */}
          </div>
        </label>
      </div>
      )
}

export default Checkbox