import { useAccountOverview } from '../../useHooks/pageHooks/useAccountOverview';
import { MobileAccountOverview } from './MobileAccountOverview/MobileAccountOverview';
import { DesktopAccountOverview } from './DesktopAccountOverview/DesktopAccountOverview';
import { DesktopLayout } from '../../layouts/DesktopLayout/DesktopLayout';
import HybridLayout from '../../layouts/HybridLayout/HybridLayout';
import { MobileLayout } from '../../layouts/MobileLayout/MobileLayout';

/**
 * Wrapper component for the Account-Overview page.  DesktopAccountOverview is rendered when the overall
 * pixel width is greater or equal to 800px, MobileAccountOverview is rendered when less than 800px.
 */
export const AccountOverview = () => {
  const { creditCardAccounts, CDAccounts, savingsAccounts, windowState, isAccountInformationLoaded,
    totalSavingsBalance, totalCDBalance, openRatesModal, setOpenRatesModal, isAccountDetailsLoaded, gotUserContactInfo } = useAccountOverview();

  return (
    <>
      {windowState !== "Mobile" ? (
        <>
          {windowState === "Desktop" ?
            <DesktopLayout>
              <DesktopAccountOverview
                creditCardAccounts={creditCardAccounts}
                CDAccounts={CDAccounts}
                savingsAccounts={savingsAccounts}
                isAccountInformationLoaded={isAccountInformationLoaded}
                totalSavingsBalance={totalSavingsBalance}
                totalCDBalance={totalCDBalance}
                isAccountDetailsLoaded={isAccountDetailsLoaded}
                openRatesModal={openRatesModal}
                setOpenRatesModal={setOpenRatesModal}
                gotUserContactInfo={gotUserContactInfo}
              />
            </DesktopLayout>
            :
            <HybridLayout>
              <DesktopAccountOverview
                creditCardAccounts={creditCardAccounts}
                CDAccounts={CDAccounts}
                savingsAccounts={savingsAccounts}
                isAccountInformationLoaded={isAccountInformationLoaded}
                totalSavingsBalance={totalSavingsBalance}
                totalCDBalance={totalCDBalance}
                isAccountDetailsLoaded={isAccountDetailsLoaded}
                openRatesModal={openRatesModal}
                setOpenRatesModal={setOpenRatesModal}
                gotUserContactInfo={gotUserContactInfo}
              />
            </HybridLayout>
          }
        </>
      ) : (
        <MobileLayout>
          <MobileAccountOverview
            creditCardAccounts={creditCardAccounts}
            CDAccounts={CDAccounts}
            savingsAccounts={savingsAccounts}
            isAccountInformationLoaded={isAccountInformationLoaded}
            totalSavingsBalance={totalSavingsBalance}
            totalCDBalance={totalCDBalance}
            isAccountDetailsLoaded={isAccountDetailsLoaded}
            openRatesModal={openRatesModal}
            setOpenRatesModal={setOpenRatesModal}
            gotUserContactInfo={gotUserContactInfo}
          />
        </MobileLayout>
      )}
    </>
  );
};
