import { OverviewComponent } from '../../../components/OverviewComponent/OverviewComponent';
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout';
import './MobileAccountDetails.scss';
import { TransactionDetails } from '../../../components/TransactionDetails/TransactionDetails';
import { useAccountDetails } from '../../../useHooks/pageHooks/useAccountDetails';
import MobileErrorAccountInformation from '../../../components/ErrorAccountInformation/MobileErrorAccountInformation/MobileErrorAccountInformation';
import { AccountDetailsCDTransactionTable } from './components/AccountDetailsCDTransactionTable/AccountDetailsCDTransactionTable';

import { AccountDetailsNavBar } from './components/AccountDetailsNavBar/AccountDetailsNavBar';
import { AccountDetailsOpenNavBar } from './components/AccountDetailsOpenNavBar/AccountDetailsOpenNavBar';
import { AccountDetailsSavingsTransactionTable } from './components/AccountDetailsSavingsTransactionTable/AccountDetailsSavingsTransactionTable';
import { AccountDetailsSummaryCards } from './components/AccountDetailsSummaryCards/AccountDetailsSummaryCards';
import NotificationMessageDB from '../../../shared/digitalbank/Notification/NotificationMessageDB';
import { useAccountNotifications } from '../../../Context/NotificationContext/useNotificationContext';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { createId } from '../../../Form/Form/utils';

/**
 * Render the Mobile version of the AccountDetails page
 * TODO Create a Account Details context instead of passing it all down.
 * @param props selectedAccount: IAccountInformation
 */
const MobileAccountDetails = () => {
  const { selectedAccount,
    onTransactionDetails,
    displayDetails,
    setDisplayDetails,
    selectedTransaction,
    isAccountInformationLoaded,
    openNavBarMenu,
    setOpenNavBarMenu,
    selectedFromAccount,
    setSelectedFromAccount,
    selectedToAccount,
    setSelectedToAccount,
    selectionOptions,
    transferAmount,
    setTransferAmount,
    transferDate,
    setTransferDate,
    handleBannerCancel,
    transactions,
    dispatch,
    history,
    type,
    message,
    gotUserContactInfo } = useAccountDetails();
  const { accountNotifications, handleDismiss } = useAccountNotifications(selectedAccount.id)
  const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

  const handleOnNotifLinkClick = (event: any, notification: any) => {
    if (notification.notificationUrl) {
      history.push(notification.notificationUrl)
    }
  }

  return (

    <MobileLayout>
      {(isAccountInformationLoaded === 'Success' || isAccountInformationLoaded === "Loading") && gotUserContactInfo !== "Failed" ?
        <div className="Mobile-Account-Details-Wrapper">
          {/* {!onTransactionDetails ? ( */}
          {!displayDetails ? (
            <>
              <div className="Account-Overview">
                <OverviewComponent />
              </div>
              {isAccountDetailsLoaded !== "Loading" ?
                <AccountDetailsNavBar
                  setOpenNavBarMenu={setOpenNavBarMenu}
                  selectedAccount={selectedAccount}
                  dispatch={dispatch}
                  history={history}
                />
                :
                <div className="loading-wrapper">
                  <div className="loading-box" />
                  <div className="pipe" />
                  <div className="loading-box" />
                  <div className="pipe" />
                  <div className="loading-box" />
                  <div className="pipe" />
                  <div className="loading-box small" />
                </div>
              }
              <AccountDetailsOpenNavBar
                selectedAccount={selectedAccount}
                dispatch={dispatch}
                history={history}
                openNavBarMenu={openNavBarMenu}
                setOpenNavBarMenu={setOpenNavBarMenu}
              />
              <div className='mobile-accounts-notification-wrapper'>
                {accountNotifications.notifications.slice(0, accountNotifications.numToDisplay).map(e => <div className='mobile-accounts-notification' key={selectedAccount.id + createId()}><NotificationMessageDB data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotifLinkClick} /></div>)}
              </div>
              {selectedAccount.accountType === 'CD' && (<>
                <AccountDetailsCDTransactionTable
                  displayDetails={displayDetails}
                  setDisplayDetails={setDisplayDetails}
                  selectedAccount={selectedAccount}
                  transactions={transactions}
                  history={history}
                  selectedTransaction={selectedTransaction}
                />
                <AccountDetailsSummaryCards
                  selectedAccount={selectedAccount}
                />
              </>)}

              {selectedAccount.accountType === 'Savings' && <AccountDetailsSavingsTransactionTable

                selectedFromAccount={selectedFromAccount}
                setSelectedFromAccount={setSelectedFromAccount}
                selectedToAccount={selectedToAccount}
                setSelectedToAccount={setSelectedToAccount}
                selectionOptions={selectionOptions}
                transferAmount={transferAmount}
                setTransferAmount={setTransferAmount}
                transferDate={transferDate}
                setTransferDate={setTransferDate}
              />}

            </>
          ) : (
            <div>
              {selectedTransaction.id !== "" && onTransactionDetails && (
                <TransactionDetails
                  selectedTransaction={selectedTransaction}
                  displayDetails={displayDetails}
                  setDisplayDetails={setDisplayDetails}
                />
              )}
            </div>
          )}
        </div>
        :
        <MobileErrorAccountInformation />
      }
    </MobileLayout>
  );
};

export default MobileAccountDetails;





