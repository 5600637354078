/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { IBeneficiary } from "../../../api/getBeneficiaries"
import { RootState } from "../../../app/store"
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAccountBeneficiariesAsync, setSortedBeneficiaries } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { updateBeneficiaryArrayByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { useWindowState } from "../../../Context/AccountContext/useWindowState";

/**
 * The hook is used for the API call to get beneficiaries, to set the color array,
 * and to handle routing with useHistory.
 * The color array uses specific colors in a priority order.
 * @returns beneficiaries, colorArray, history
 */
export const useBeneficiaryCard = () => {
    const { windowState } = useWindowState()
    const history = useHistory()
    const [ beneficiariesTruncated, setBeneficiariesTruncated] = useState<Array<IBeneficiary>>([])
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    // const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const isBeneficiariesLoaded = useAppSelector((state: RootState) => state.selectedAccount.isBeneficiariesLoaded)
    const dispatch = useAppDispatch();
    const [isMenuClicked, setIsMenuClicked] = useState<boolean>(false)
    const menuRef = useRef<any>()

    /**
     * When beneficiaries change check to see if the number of beneficiaries is greater than
     * four.  If it is then truncate the list, set the last field to "Other" and set the 
     * percentage equal to the remaining field percentages added together.
     */
    useEffect(() => {   
        if(selectedAccount.beneficiaries && selectedAccount.beneficiaries.length > 4){
            let otherValue: number = 0
            let tempArray: Array<IBeneficiary> = []
            if(selectedAccount.beneficiaries.length){
                dispatch(setSortedBeneficiaries())
                //sort account information array as well
            }
            for(let i = 0; i < 3; i = i + 1){
                tempArray.push(selectedAccount.beneficiaries[i])
            }
            let sortedArray = tempArray.sort((a:IBeneficiary, b:IBeneficiary) => {
                if(a.percentage > b.percentage){
                    return -1;
                }
                if(a.percentage < b.percentage){
                    return 1
                }
                return 0
            })
            otherValue = sortedArray[0].percentage + sortedArray[1].percentage + sortedArray[2].percentage
            let otherObject: IBeneficiary = {name: "Others", firstName: "", lastName: "", percentage: 100 - otherValue, tin: "", dateOfBirth: "",
                               address1: "", city: "", state: "", zipCode: "", relationship: "", type: "Person", isDeleted: false}
            tempArray.push(otherObject)
            let finalSort = sortedArray.sort((a:IBeneficiary, b:IBeneficiary) => {
                if(a.percentage > b.percentage && a.name !== "Others"){
                    return - 1
                }
                if(a.percentage < b.percentage && a.name !== "Others"){
                    return 1
                }
                return 0
            })
            // (a.percentage > b.percentage && a.name !== "Others") ? -1 : 1)
            
            setBeneficiariesTruncated(finalSort as IBeneficiary[])
        }
        else{
            if(selectedAccount.beneficiaries && selectedAccount.beneficiaries.length){
                dispatch(setSortedBeneficiaries())
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedAccount.beneficiaries])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if(menuRef.current && !menuRef.current.contains(event.target)){
                document.body.style.overflow = "auto";
                setIsMenuClicked(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    /**Locks the scroll when we are on mobile and the menu is opened */
    useEffect(() => {
        if(windowState === "Mobile" && isMenuClicked){
            document.body.style.overflow = "hidden";
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuClicked])

    /**Unlocks the scroll when a selection has been made */
    const handleScrollLock = () => {
        document.body.style.overflow = "auto";
    }

    /**Disable the scroll lock if the menu is open and the window state changes */
    useEffect(() => {
        document.body.style.overflow = "auto";
    },[windowState])

    return { selectedAccount, history, beneficiariesTruncated, isBeneficiariesLoaded, dispatch, isMenuClicked, setIsMenuClicked, menuRef,
             windowState, handleScrollLock }
}

