import { IControl } from "../Form/Control/IControl.interface";
import { IFormContext, TControlDispatch } from "../useForm/useForm";

/**A custom use hook holding the logic for the email input component */
export const useEmailInput = (form: IFormContext, control: IControl, dispatchControlChanges: TControlDispatch) => {

    /**Handles the onChange event of the email input component */
    const onChangeHandler = (e: any) => {
        form.setValue(control.name, e.target.value)
        /**If there are error than run validations on the control */
        if(control.errors.length){
            form.runValidations(control.name)
        }
        dispatchControlChanges()
    }

    /**Handles the onBlur event for the email input component */
    const handleOnBlur = () => {
        form.runValidations(control.name)
        dispatchControlChanges()
    }

    return { onChangeHandler, handleOnBlur }
}