import { setStartBeneficiaryFlow } from '../../../../../slices/selectedAccountSlice/selectedAccountSlice';
import pieChart from '../../../../../assets/pieChart.svg';
import greyDocument from '../../../../../assets/greyDocument.svg';
import maturityClock from '../../../../../assets/maturityClock.svg';
import settings from '../../../../../assets/settings.svg';


/**
 * The mobile Navigation Bar Selection for the Account Details
 * @param props 
 * @returns 
 */
export const AccountDetailsOpenNavBar = (props: any) => {
  const { openNavBarMenu, setOpenNavBarMenu, selectedAccount, dispatch, history } = props;

  if (!openNavBarMenu) {
    return null;
  }

  return (<div className="nav-bar-menu-wrap">
    <div className="menu-wrap">
      <div className="header-wrap">
        <p className="title-text semibold">More Options</p>
        <p className="cancel-text regular" onClick={() => { setOpenNavBarMenu(false); document.body.style.overflow = "auto" }}>Cancel</p>
      </div>
      <div className="nav-option" onClick={() => { history.push(`/account/${selectedAccount.id}/beneficiary`, { page: "Account Details" }); dispatch(setStartBeneficiaryFlow(false)) }}>
        <img src={pieChart} alt="Go To Beneficiaries" />
        <p className="option-text small">Beneficiaries</p>
      </div>
      <div className="bottom-border" />
      <div className="nav-option" onClick={() => history.push(`/account/${selectedAccount.id}/statements`, { page: "Account Details" })}>
        <img src={greyDocument} alt="Go To Statements" />
        <p className="option-text small">Statements</p>
      </div>
      <div className="bottom-border" />
      <div className="nav-option" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`, { page: "Account Details" })}>
        <img src={maturityClock} alt="Go to Maturity Plan" />
        <p className="option-text small">Maturity</p>
      </div>
      <div className="bottom-border" />
      <div className="nav-option" onClick={() => history.push(`/user/${selectedAccount.id}/settings`, { page: "Account Details" })}>
        <img src={settings} alt="Go To Account Settings" />
        <p className="option-text small">Settings</p>
      </div>
    </div>
  </div>)
}