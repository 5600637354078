import { useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useUserProfileContext } from "../../../Pages/UserProfile/Context/useUserProfileContext"

/**
 * A custom hook for the Security Settings component that is a tab on the user profile page
 */
export const useSecuritySettings = () => {
    useScrollToTop()
    const { changePassword, setChangePassword, manageDevices, setManageDevices } = useUserProfileContext()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const openOTPModal = useAppSelector((state: RootState) => state.userInformation.openOTPModal)
    const dispatch = useAppDispatch()
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const editButtonClickedRef = useRef<boolean>(false)
    const { message, type, resetToast } = useToastMessage()

    /**Handles the Change Password onCLick event */
    const handlePasswordClick = () => {
        editButtonClickedRef.current = true
        dispatch(setOTPPassed(false))
        dispatch(setCheckInternalToken(true))
    }

    /**Checks to see if the OTP has passed, if it has allow access */
    useEffect(() => {
        if(OTPPassed && editButtonClickedRef.current){
            editButtonClickedRef.current = false
            dispatch(setOTPPassed(false))
            setChangePassword(true); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**if toast message is not an empty string then reset it */
    useEffect(() => {
        if(message !== ""){
            resetToast()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return { userInformation, dispatch, openOTPModal, handlePasswordClick, message, type, changePassword, setChangePassword, 
             manageDevices, setManageDevices }
}