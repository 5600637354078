import { useEffect, useState } from "react"
import { useAppSelector } from "../../../app/hooks"
import { getEventPath } from "../../../utils/getEventPath"
import { RootState } from "../../../app/store"
import { IAccountInformation } from "../../../api/getAccountInformation"

/**The custom hook is for the Document Centers mobile filter */
export const useMobileDocumentCenterFilter = (selectedDate: string | undefined, setSelectedDate: any) => {
    const [ openFilter, setOpenFilter ] = useState<boolean>(false)
    const [ accountsSelected, setAccountsSelected ] = useState<boolean>(false)
    const [ dateSelected, setDateSelected ] = useState<boolean>(false)
    const [numOfFilters, setNumOfFilters] = useState<number>(1)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [chosenAccount, setChosenAccount] = useState<IAccountInformation>(selectedAccount)

    useEffect(() => {
        if(openFilter){
            document.body.style.overflow = "hidden";
        }
        return () => {document.body.style.overflow = "auto"}
    }, [openFilter])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("mobile-filter")) === -1){
                setOpenFilter(false)
                document.body.style.overflow = "auto";
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**handles the onSave button click */
    const onSave = (chosenAccountId: string) => {
        document.body.style.overflow = "auto";
        if(selectedAccount.id !== chosenAccountId){
            setNumOfFilters(1)
            setSelectedDate("All")
        }
        else if(selectedDate && selectedDate !== "All"){
            setNumOfFilters(2)
        }
        else{
            setNumOfFilters(1)
        }
    }

    return { openFilter, setOpenFilter, accountsSelected, setAccountsSelected, dateSelected, setDateSelected, numOfFilters, onSave,
             chosenAccount, setChosenAccount }
}