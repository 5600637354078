import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"

/**An API Post call to change the current term of the product in grace period */
export const changeGracePeriodTerm_API = (id: string, productId: string, term: string) => {
    const date = new Date().toLocaleDateString()
    let [month, day, year] = date.split("/")

    if(month.length === 1){
        month = `0${month}`
    }
    if(day.length === 1){
        day = `0${day}`
    }

    return axios.post(`${bffBaseUrl}/accounts/${id}/terms`,
        {
            productId: productId,
            term: term,
            EffectiveDate: `${year}-${month}-${day}`
        }
    )
}

/**AN API Post call to create a new Grace Period fund transfer */
export const createGraceFundTransfer_API = (payload: any) => {
    return axios.post(`${bffBaseUrl}/transfers`,
        {
            transferAmount: payload.transferAmount,
            transferType: payload.transferType,
            fromAccountDetails: {accountId: payload.fromAccountDetails.accountId, accountNickName: payload.fromAccountDetails.accountNickName},
            toAccountDetails: {accountId: payload.toAccountDetails.accountId, accountNickName: payload.toAccountDetails.accountNickName}
        }
    )
}

/**An API post call to close an account, it can only be used successfully during grace period */
export const closeGraceAccount_API = (accountId: string, payload: any) => {
    return axios.post(`${bffBaseUrl}/accounts/${accountId}/close`,
        {
            toAccountDetails: {
                accountId: payload.toAccountId,
                accountType: payload.accountType
            }
        }
    )
}