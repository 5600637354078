import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { ITransferConfirmationProps } from './interfaces/ITransferConfirmationProps'
import './TransferConfirmation.scss'
import greenCheckmark from '../../../../assets/greenCheckmark.svg'
import { displayCurrency } from '../../../../utils/displayCurrency'
import { useTransferConfirmation } from '../../../../useHooks/componentHooks/useTransferConfirmation/useTransferConfirmation'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'

/**Renders the Transfer Confirmation component used at the end of the create a new transfer flow found in the Transfers tab */
const TransferConfirmation = (props: ITransferConfirmationProps) => {
    const { windowState } = useWindowState()
    const nickName = useAppSelector((state: RootState) => state.userInformation.nickname)
    const { selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, transferAmount, setTransferAmount,
        transferDate, setTransferDate, setTopCardIndex, scheduledTransfers } = props
    const { formatedTransferDate, amount, handleOnClick, todaysDate } =
        useTransferConfirmation(transferDate, transferAmount as number, setSelectedFromAccount, setSelectedToAccount, setTransferAmount, setTransferDate, setTopCardIndex)

    return (
        <div className={windowState !== "Mobile" ? "confirmation-display-wrap" : "confirmation-display-wrap mobile"}>
            <TransferConfirmationLeft
                nickName={nickName}
                scheduledTransfers={scheduledTransfers}
                handleOnClick={handleOnClick}
            />
            <TransferConfirmationRight
                selectedFromAccount={selectedFromAccount}
                selectedToAccount={selectedToAccount}
                amount={amount}
                formatedTransferDate={formatedTransferDate}
                transferDate={transferDate}
                todaysDate={todaysDate}
            />
        </div >
    )
}

export default TransferConfirmation


const TransferConfirmationLeft = (props: any) => {

    const {
        nickName,
        scheduledTransfers,
        handleOnClick } = props;
    const { windowState } = useWindowState();

    const getParentClassName = () => {
        let className = 'left-side';
        if (windowState) {
            className += ' mobile'
        }

        return className
    }

    const getTextClassName = () => {
        let className = 'text';
        if (windowState === 'Mobile') {
            className += ' small';
        }
        else {
            className += ' h5';
        }

        return className;
    }
    return <div className={getParentClassName()}>
        <img className="green-circle" src={greenCheckmark} alt="green checkmark" />
        <div className="message-wrap">
            <span className="text h3">You’re all set, {nickName}!</span>
            <span className="text regular">Your transfer has been scheduled. We sent you an email with your transfer details for your records.</span>
        </div>
        <span className={getTextClassName()}>Transfer ID: {scheduledTransfers[0]?.orderReference}</span>
        <button className="Button btn-secondary" onClick={() => handleOnClick()}>Make another transfer</button>
    </div>
}


const TransferConfirmationRight = (props: any) => {

    const {
        selectedFromAccount,
        selectedToAccount,
        amount,
        formatedTransferDate,
        transferDate,
        todaysDate } = props;
    const { windowState } = useWindowState();

    const getParentClassName = () => {
        let className = 'right-side';
        if (windowState) {
            className += ' mobile'
        }

        return className
    }

    const getTextClassName = () => {
        let className = 'details-title';
        if (windowState === 'Mobile') {
            className += ' boldButtonLarge';
        }
        else {
            className += ' h4';
        }

        return className;
    }

    return <div className={getParentClassName()}>
        <span className={getTextClassName()}>Transfer details</span>
        <div className="label-value-wrap">
            <span className="label boldSmall">Transfer from</span>
            <span className="value regular">{selectedFromAccount.title} - {selectedFromAccount.type} {selectedFromAccount.lastFour}</span>
        </div>
        <div className="label-value-wrap">
            <span className="label boldSmall">Transfer to</span>
            <span className="value regular">{selectedToAccount.title} - {selectedToAccount.type} {selectedToAccount.lastFour}</span>
        </div>
        <div className="label-value-wrap">
            <span className="label boldSmall">Amount</span>
            <span className="value regular">{displayCurrency(+parseFloat(amount).toFixed(2))}</span>
        </div>
        <div className="label-value-wrap">
            <span className="label boldSmall">Transfer date</span>
            <span className="value regular">{convertDateTommddyyyy(formatedTransferDate)} {transferDate === todaysDate ? " (Today)" : ""}</span>
        </div>
    </div>
}