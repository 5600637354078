import { ITransactionHistoryData } from '../api/getTransactionHistory/types/ITransactionHistory.type'


/**The utility function handles the standard filtering, meaning "All Filters" has not been selected */
export const handleStandardFiltering = (selectedDate: string, date: any, transactions: Array<ITransactionHistoryData>) => {
    let tempArray = JSON.parse(JSON.stringify(transactions))
    const todaysDate = new Date().getTime()

    switch (selectedDate) {
        case "All": {
            break;
        }
        case "Last month": {
            tempArray = transactions.filter((transaction: ITransactionHistoryData) => new Date(transaction.effectiveDate).getTime() > todaysDate - (31 * 24 * 3600 * 1000))
            break;
        }
        case "Last 90 days": {
            tempArray = transactions.filter((transaction: ITransactionHistoryData) => new Date(transaction.effectiveDate).getTime() > todaysDate - (3 * 31 * 24 * 3600 * 1000))
            break;
        }
        case "Last 6 months": {
            tempArray = transactions.filter((transaction: ITransactionHistoryData) => new Date(transaction.effectiveDate).getTime() > todaysDate - (6 * 31 * 24 * 3600 * 1000))
            break;
        }
        case "Last 12 months": {
            tempArray = transactions.filter((transaction: ITransactionHistoryData) => new Date(transaction.effectiveDate).getTime() > todaysDate - (12 * 31 * 24 * 3600 * 1000))

            break;
        }
        case "YTD": {
            const currentYear = new Date().getFullYear()
            const firstOfYear = new Date(`${currentYear}-01-01T10:00:33.901+00:00`).getTime()
            tempArray = transactions.filter((transaction: ITransactionHistoryData) => new Date(transaction.effectiveDate).getTime() > firstOfYear)

            break;
        }
        case "Since last statement": {
            const currentYear = new Date().getFullYear()
            const currentMonth = new Date().getMonth() + 1
            const firstOfMonth = new Date(currentYear, currentMonth - 1, 1, 0, 0, 0).getTime()
            tempArray = transactions.filter((transaction: ITransactionHistoryData) => new Date(transaction.effectiveDate).getTime() > firstOfMonth)
            break;
        }
    }


    return tempArray
}
