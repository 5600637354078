import { RadioButton } from '../../../../components/RadioButton/RadioButton'
import { useComplianceQuestions } from '../../../../useHooks/componentHooks/useComplianceQuestions/useComplianceQuestions'
import './ComplianceQuestions.scss'
import { IComplianceQuestionsProps } from './interfaces/IComplianceQuestionsProps'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import EligibilityModal from '../EligibilityModal/EligibilityModal'

/**Renders the compliance questions which would be step two of add co-owner flow */
const ComplianceQuestions = (props: IComplianceQuestionsProps) => {
    const { form, dispatchForm, IRSWithholding, setIRSWithholding, foreignAffiliation, setForeignAffiliation, setFormStep, setAddOwner } = props
    const { windowState, handleCancel, enableContinue, handleContinue, showErrorModal } = useComplianceQuestions(form, dispatchForm, IRSWithholding, foreignAffiliation, setFormStep, setIRSWithholding, setForeignAffiliation)

    return (
        <div className="compliance-questions-wrapper">
            <span className="header-text h3">Additional co-owner information</span>
            <span className="required-text regular">Fields marked with an <span className="red-text">*</span> are required</span>
            {/* <div className="question-wrap">
                <span className="question-text semiboldLarge">I have been notified by the IRS that I am subject to Federal backup withholdings?<span className="required-star mobileboldextraSmall">*</span></span>
                {windowState !== "Mobile" ?
                    <>
                    <div className={IRSWithholding === "Yes" ? "row-wrapper selected" : "row-wrapper"} onClick={() => setIRSWithholding("Yes")}>
                        <RadioButton 
                            value={IRSWithholding}
                            name="irs withholding"
                            className="radio"
                            onChange={() => setIRSWithholding("Yes")}
                            checked={IRSWithholding === "Yes"}
                            textAlign="Right"
                        >{"Yes" as any}</RadioButton>
                    </div>
                    <div className={IRSWithholding === "No" ? "row-wrapper selected" : "row-wrapper"} onClick={() => setIRSWithholding("No")}>
                        <RadioButton 
                            value={"IRSWithholding"}
                            name="irs withholding"
                            className="radio"
                            onChange={() => setIRSWithholding("No")}
                            checked={IRSWithholding === "No"}
                            textAlign="Right"
                        >{"No" as any}</RadioButton>
                    </div>
                    </>
                    :
                    <>
                    <div className={IRSWithholding === "Yes" ? "row-wrapper selected mobile" : "row-wrapper mobile"} onClick={() => setIRSWithholding("Yes")}>
                        <RadioButton 
                            value={IRSWithholding}
                            name="irs withholding"
                            className="radio"
                            onChange={() => setIRSWithholding("Yes")}
                            checked={IRSWithholding === "Yes"}
                            textAlign="Right"
                        >{"Yes" as any}</RadioButton>
                    </div>
                    <div className={IRSWithholding === "No" ? "row-wrapper selected mobile" : "row-wrapper mobile"} onClick={() => setIRSWithholding("No")}>
                        <RadioButton 
                            value={"IRSWithholding"}
                            name="irs withholding"
                            className="radio"
                            onChange={() => setIRSWithholding("No")}
                            checked={IRSWithholding === "No"}
                            textAlign="Right"
                        >{"No" as any}</RadioButton>
                    </div>
                    </>
                }
            </div> */}
            <div className="question-wrap">
                <span className="question-text semiboldLarge">Are you or an immediate family member or a close associate of someone who is currently or previously a Foreign Senior Political Figure?<span className="required-star mobileboldextraSmall">*</span></span>
                {windowState !== "Mobile" ?
                    <>
                    <div className={foreignAffiliation === "Yes" ? "row-wrapper selected" : "row-wrapper"} onClick={() => setForeignAffiliation("Yes")}>
                        <RadioButton 
                            value={foreignAffiliation}
                            name="foreignAffiliation"
                            className="radio"
                            onChange={() => setIRSWithholding("Yes")}
                            checked={foreignAffiliation === "Yes"}
                            textAlign="Right"
                        >{"Yes" as any}</RadioButton>
                    </div>
                    <div className={foreignAffiliation === "No" ? "row-wrapper selected" : "row-wrapper"} onClick={() => setForeignAffiliation("No")}>
                        <RadioButton 
                            value={foreignAffiliation}
                            name="foreignAffiliation"
                            className="radio"
                            onChange={() => setIRSWithholding("No")}
                            checked={foreignAffiliation === "No"}
                            textAlign="Right"
                        >{"No" as any}</RadioButton>
                    </div>
                    </>
                    :
                    <>
                    <div className={foreignAffiliation === "Yes" ? "row-wrapper selected mobile" : "row-wrapper mobile"} onClick={() => setForeignAffiliation("Yes")}>
                        <RadioButton 
                            value={foreignAffiliation}
                            name="foreignAffiliation"
                            className="radio"
                            onChange={() => setIRSWithholding("Yes")}
                            checked={foreignAffiliation === "Yes"}
                            textAlign="Right"
                        >{"Yes" as any}</RadioButton>
                    </div>
                    <div className={foreignAffiliation === "No" ? "row-wrapper selected mobile" : "row-wrapper mobile"} onClick={() => setForeignAffiliation("No")}>
                        <RadioButton 
                            value={foreignAffiliation}
                            name="foreignAffiliation"
                            className="radio"
                            onChange={() => setIRSWithholding("No")}
                            checked={foreignAffiliation === "No"}
                            textAlign="Right"
                        >{"No" as any}</RadioButton>
                    </div>
                    </>
                }
            </div>
            {windowState !== "Mobile" ?
                <>
                <div className="bottom-border" />
                <div className="button-wrapper">
                    <div className="left-side" onClick={() => {setFormStep(1); setIRSWithholding(""); setForeignAffiliation("")}}>
                        <img src={arrowLeftBlue} alt="blue left facing chevron" />
                        <span className="blue-text regular">Back</span>
                    </div>
                    <div className="right-side">
                        <button className="Button btn-secondary" onClick={() => {handleCancel(); setAddOwner(false); setFormStep(1)}}>Cancel</button>
                        <button className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableContinue} onClick={() => handleContinue()}>Continue</button>
                    </div>
                </div>
                </>
                :
                <button  className={enableContinue ? "Button btn-primary mobile" : "Button btn-primary mobile inactive"} disabled={!enableContinue} onClick={() => handleContinue()}>Continue</button>
            }
            {showErrorModal &&
                <EligibilityModal 
                    errorMessage='You are not eligible to open an account because you, a relative, or a close associate is or has been a Foreign Senior Political Figure.'
                    setAddOwner={setAddOwner}
                    setFormStep={setFormStep}
                    form={form}
                    dispatchForm={dispatchForm}
                />
            }
        </div>
    )
}

export default ComplianceQuestions