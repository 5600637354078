import { IControl } from '../Control/IControl.interface';
import { IValidator } from './IValidator.interface';

export const maxLengthValidator = (length: number):IValidator => ({
  name: 'MAX_LENGTH',
  value: length
})

export const MAX_LENGTH = (control:IControl, validator:IValidator) => {
  let newValue = control.value;
  const TYPE_OF = typeof newValue;
  
  if(!['number', 'string'].includes(TYPE_OF)) {
    throw new Error(`Max Length Validator on ${control.name} need to be typeof number or string. value is ${typeof newValue}`)
  }

  const VALUE_LENGTH = (newValue as string | number).toString().length;
  if(VALUE_LENGTH <= validator.value) {
    return false;
  }

  return {...validator, actual:VALUE_LENGTH}
}