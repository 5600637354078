/** Creates a unique ID */
// export const createId = (): string =>
//   `${Math.random().toString(16).slice(2)}_${Math.random()
//     .toString(32)
//     .slice(2)}`;

declare global {
  interface Crypto {
    randomUUID: () => `${string}-${string}-${string}-${string}-${string}`;
  }
}

export const createId = (): string => {
  if(window.isSecureContext){
    const id = window.crypto.randomUUID()
    return id
  }
  const array = new Uint32Array(10)
  return `${crypto.getRandomValues(array).toString}`
}