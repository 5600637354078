import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { MobileLayout } from "../../layouts/MobileLayout/MobileLayout"
import ContactPage from "./ContactPage/ContactPage"

/**Wrapper component that renders either the desktop / hybrid / mobile version fo contact page */
const ContactWrapper = () => {
    const { windowState } = useWindowState()
    

    return (
        <>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <ContactPage windowState={windowState} />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <ContactPage windowState={windowState} />
                    </HybridLayout>
                }
                </>
            :
                <MobileLayout>
                    <ContactPage windowState={windowState} />
                </MobileLayout>
            }
        </>
    )
}

export default ContactWrapper