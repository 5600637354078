import { useHistory } from "react-router-dom";
import { FormProvider } from "../../Form/useForm/useForm";
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout";
import HybridLayout from "../../layouts/HybridLayout/HybridLayout";
import { useBeneficiaryPage } from "../../useHooks/pageHooks/useBeneficiaryPage";
import DesktopBeneficiary from "./DesktopBeneficiary/DesktopBeneficiary"
import MobileBeneficiary from "./MobileBeneficiary/MobileBeneficiary"

/**
 * The component is used to determine whether the desktop or mobile version of the beneficiary landing
 * page should be rendered.
 */
const BeneficiaryPageWrapper = () => {
    const {
        editBeneficiary,
        setEditBeneficiary,
        editProfile,
        setEditProfile,
        selectedBeneficiary,
        setSelectedBeneficiary,
        deleteBeneficiary,
        setDeleteBeneficiary,
        showAlertMessage,
        setShowAlertMessage,
        addBeneficiary,
        setAddBeneficiary,
        selectedAccount,
        unsavedChanges,
        setUnsavedChanges,
        childRef,
        checkAllocations, 
        setCheckAllocations,
        currentPage,
        setCurrentPage,
        newBeneficiary,
        setNewBeneficiary,
        windowState,
        form, 
        dispatchForm,
        handleOnClick
      } = useBeneficiaryPage();
    
    const history = useHistory();

    if(selectedAccount.accountClosed || selectedAccount.pendingGraceClosure) {
        history.push(`/accounts/${selectedAccount.id}`);
    }

    return (
        <>
            <FormProvider form={form} dispatch={dispatchForm}>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopBeneficiary 
                            editBeneficiary={editBeneficiary}
                            setEditBeneficiary={setEditBeneficiary}
                            editProfile={editProfile}
                            setEditProfile={setEditProfile}
                            selectedBeneficiary={selectedBeneficiary}
                            setSelectedBeneficiary={setSelectedBeneficiary}
                            deleteBeneficiary={deleteBeneficiary}
                            setDeleteBeneficiary={setDeleteBeneficiary}
                            showAlertMessage={showAlertMessage}
                            setShowAlertMessage={setShowAlertMessage}
                            addBeneficiary={addBeneficiary}
                            setAddBeneficiary={setAddBeneficiary}
                            unsavedChanges={unsavedChanges} 
                            setUnsavedChanges={setUnsavedChanges}   
                            childRef={childRef}
                            checkAllocations={checkAllocations}
                            setCheckAllocations={setCheckAllocations}                         
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                            newBeneficiary={newBeneficiary} 
                            setNewBeneficiary={setNewBeneficiary}
                            form={form}
                            dispatchForm={dispatchForm}
                            handleOnClick={handleOnClick}
                        /> 
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopBeneficiary 
                            editBeneficiary={editBeneficiary}
                            setEditBeneficiary={setEditBeneficiary}
                            editProfile={editProfile}
                            setEditProfile={setEditProfile}
                            selectedBeneficiary={selectedBeneficiary}
                            setSelectedBeneficiary={setSelectedBeneficiary}
                            deleteBeneficiary={deleteBeneficiary}
                            setDeleteBeneficiary={setDeleteBeneficiary}
                            showAlertMessage={showAlertMessage}
                            setShowAlertMessage={setShowAlertMessage}
                            addBeneficiary={addBeneficiary}
                            setAddBeneficiary={setAddBeneficiary}
                            unsavedChanges={unsavedChanges} 
                            setUnsavedChanges={setUnsavedChanges}   
                            childRef={childRef}
                            checkAllocations={checkAllocations}
                            setCheckAllocations={setCheckAllocations}                         
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                            newBeneficiary={newBeneficiary} 
                            setNewBeneficiary={setNewBeneficiary}
                            form={form}
                            dispatchForm={dispatchForm}
                            handleOnClick={handleOnClick}
                        /> 
                    </HybridLayout> 
                    }
                </>
                :
                <MobileBeneficiary 
                    editBeneficiary={editBeneficiary}
                    setEditBeneficiary={setEditBeneficiary}
                    editProfile={editProfile}
                    setEditProfile={setEditProfile}
                    selectedBeneficiary={selectedBeneficiary}
                    setSelectedBeneficiary={setSelectedBeneficiary}
                    deleteBeneficiary={deleteBeneficiary}
                    setDeleteBeneficiary={setDeleteBeneficiary}
                    showAlertMessage={showAlertMessage}
                    setShowAlertMessage={setShowAlertMessage}
                    addBeneficiary={addBeneficiary}
                    setAddBeneficiary={setAddBeneficiary}    
                    selectedAccount={selectedAccount}
                    unsavedChanges={unsavedChanges} 
                    setUnsavedChanges={setUnsavedChanges}  
                    childRef={childRef}  
                    checkAllocations={checkAllocations}
                    setCheckAllocations={setCheckAllocations}                        
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage} 
                    newBeneficiary={newBeneficiary}
                    setNewBeneficiary={setNewBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}
                    handleOnClick={handleOnClick}
                />
            }
            </FormProvider>
        </>
    )
}

export default BeneficiaryPageWrapper