import { useState } from "react"
import { TTransactionType } from '../../../Pages/TransactionHistory/context/TransactionHistory.types'
import { useTransactionHistoryContext } from '../../../Pages/TransactionHistory/context/TransactionHistoryContext'

/**A custom use hook for the mobile transaction type selection filter */
export const useMobileTransactionTypeSelection = (setTransactionTypeSelected: React.Dispatch<React.SetStateAction<boolean>>) => {
    const { typeFilter } = useTransactionHistoryContext();
    const [chosenTransactionType, setChosenTransactionType] = useState<TTransactionType>(typeFilter.transactionType.ref.get())

    /**
     * Handles when the `Save` button is clicked.
     */
    const handleOnSave = () => {
        typeFilter.transactionType.ref.set(chosenTransactionType);
        setTransactionTypeSelected(false)
    }

    return { chosenTransactionType, setChosenTransactionType, handleOnSave }
}