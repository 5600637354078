import { createContext, useContext, useRef, useState } from "react";
import { IUserProfileContext } from "./interfaces/IUserProfileContext";
import { useForm } from "../../../Form/useForm/useForm";
import { addAddressControls } from "../components/MobileModifyAddresses/addAddressControls";
import { IExternalAccounts } from "../../../api/User/interfaces";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

/**Context with custom hook for the user profile page flows */
export const UserProfileContext = createContext<IUserProfileContext | null>(null)

export const UserProfileContextProvider = (props: any) => {
    //customer account
    const [newPhone, setNewPhone] = useState<string>("")
    const [newEmail, setNewEmail] = useState<string>("")
    const [phoneInputError, setPhoneInputError] = useState<boolean>(false)
    const [emailInputError, setEmailInputError] = useState<boolean>(false)
    const newPhoneRef = useRef(document.createElement('input'))
    const newEmailRef = useRef<string>("")
    const [arrayIndex, setArrayIndex] = useState<number>(0)
    const [form, dispatchForm] = useForm('addAddressForm', addAddressControls())
    const [formFilled, setFormFilled] = useState<boolean>(false)
    const [saveEnabled, setSaveEnabled] = useState<boolean>(false)
    const [usingSuggestion, setUsingSuggestion] = useState<boolean>(true)

    //security settings
    const [changePassword, setChangePassword] = useState<boolean>(false)
    const [manageDevices, setManageDevices] = useState<boolean>(false)
    const [currentPassword, setCurrentPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [retypedPassword, setRetypedPassword] = useState<string>("")
    const currentPasswordRef = useRef<string>("")
    const newPasswordRef = useRef<string>("")
    const retypedPasswordRef = useRef<string>("")
    const [showRetypeError, setShowRetypeError] = useState<boolean>(false)
    const [ ShowCurrentPasswordError, setShowCurrentPasswordError ] = useState<boolean>(false)
    const [lettersPassed, setLettersPassed] = useState<boolean>(false)
    const [numberPassed, setNumberPassed] = useState<boolean>(false)
    const [specialPassed, setSpecialPassed] = useState<boolean>(false)
    const [lengthPassed, setLengthPassed] = useState<boolean>(false)
    const [showPasswordReqError, setShowPasswordReqError] = useState<boolean>(false)

    //manage external accounts
    const [newNickname, setNewNickname] = useState<string>("")
    const newNicknameRef = useRef<string>("")
    const [openEditName, setOpenEditName] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [selectedExternalAccount, setSelectedExternalAccount] = useState<IExternalAccounts | undefined>()
    const [showDetails, setShowDetails] = useState<boolean>(false)
    const [showLimitModal, setShowLimitModal] = useState<boolean>(false)

    return (
        <UserProfileContext.Provider value={{
            newPhone,
            setNewPhone,
            newEmail,
            setNewEmail,
            phoneInputError, 
            setPhoneInputError,
            emailInputError, 
            setEmailInputError,
            newPhoneRef,
            newEmailRef,
            arrayIndex,
            setArrayIndex,
            form,
            dispatchForm,
            formFilled, 
            setFormFilled,
            changePassword,
            setChangePassword,
            manageDevices,
            setManageDevices,
            currentPassword,
            setCurrentPassword,
            newPassword,
            setNewPassword,
            retypedPassword,
            setRetypedPassword,
            currentPasswordRef,
            newPasswordRef,
            retypedPasswordRef,
            showRetypeError,
            setShowRetypeError,
            ShowCurrentPasswordError,
            setShowCurrentPasswordError,
            lettersPassed,
            setLettersPassed,
            numberPassed,
            setNumberPassed,
            specialPassed,
            setSpecialPassed,
            lengthPassed,
            setLengthPassed,
            newNickname, 
            setNewNickname,
            newNicknameRef,
            openEditName,
            setOpenEditName,
            openDeleteModal,
            setOpenDeleteModal,
            selectedExternalAccount,
            setSelectedExternalAccount,
            showDetails, 
            setShowDetails,
            showLimitModal, 
            setShowLimitModal,
            saveEnabled, 
            setSaveEnabled,
            usingSuggestion, 
            setUsingSuggestion,
            showPasswordReqError, 
            setShowPasswordReqError
        }}
        >
            {props.children}
        </UserProfileContext.Provider>
    )
}

export const useUserProfileContext = () => {
    const context = useContext(UserProfileContext)
    const userInformation = useAppSelector((state: RootState) => state.userInformation)

    if(!context){
        throw new Error("Need to use useUserProfileContext inside of UserProfileContextProvider")
    }

    /**Initializes the form fields when the user clicks on an address */
    const initializeFormFields = (addressAction: "" | "Edit" | "EditMain" | "Add" | "Remove") => {
        if(addressAction !== "Add"){
            const addressData = {
                id: userInformation.contactInformation.addresses[context.arrayIndex].id,
                addressLine1: userInformation.contactInformation.addresses[context.arrayIndex].addressLine1,
                addressLine2: userInformation.contactInformation.addresses[context.arrayIndex].addressLine2,
                city: userInformation.contactInformation.addresses[context.arrayIndex].city,
                state: userInformation.contactInformation.addresses[context.arrayIndex].state,
                zipCode: userInformation.contactInformation.addresses[context.arrayIndex].zipCode,
            }
            context.form.overrideControlValues(addressData)
            context.form.enableAllInputs()
            context.dispatchForm()        
        }
    }

    return {
        newPhone: context.newPhone,
        setNewPhone: context.setNewPhone,
        newEmail: context.newEmail,
        setNewEmail: context.setNewEmail,
        phoneInputError: context.phoneInputError,
        setPhoneInputError: context.setPhoneInputError,
        emailInputError: context.emailInputError,
        setEmailInputError: context.setEmailInputError,
        newPhoneRef: context.newPhoneRef,
        newEmailRef: context.newEmailRef,
        arrayIndex: context.arrayIndex,
        setArrayIndex: context.setArrayIndex,
        form: context.form,
        dispatchForm: context.dispatchForm,
        changePassword: context.changePassword,
        setChangePassword: context.setChangePassword,
        manageDevices: context.manageDevices,
        setManageDevices: context.setManageDevices,
        currentPassword: context.currentPassword,
        setCurrentPassword: context.setCurrentPassword,
        newPassword: context.newPassword,
        setNewPassword: context.setNewPassword,
        retypedPassword: context.retypedPassword,
        setRetypedPassword: context.setRetypedPassword,
        currentPasswordRef: context.currentPasswordRef,
        newPasswordRef: context.newPasswordRef,
        retypedPasswordRef: context.retypedPasswordRef,
        showRetypeError: context.showRetypeError,
        setShowRetypeError: context.setShowRetypeError,
        ShowCurrentPasswordError: context.ShowCurrentPasswordError,
        setShowCurrentPasswordError: context.setShowCurrentPasswordError,
        lettersPassed: context.lettersPassed,
        setLettersPassed: context.setLettersPassed,
        numberPassed: context.numberPassed,
        setNumberPassed: context.setNumberPassed,
        specialPassed: context.specialPassed,
        setSpecialPassed: context.setSpecialPassed,
        lengthPassed: context.lengthPassed,
        setLengthPassed: context.setLengthPassed,
        newNickname: context.newNickname,
        setNewNickname: context.setNewNickname,
        newNicknameRef: context.newNicknameRef,
        openEditName: context.openEditName,
        setOpenEditName: context.setOpenEditName,
        openDeleteModal: context.openDeleteModal,
        setOpenDeleteModal: context.setOpenDeleteModal,
        selectedExternalAccount: context.selectedExternalAccount,
        setSelectedExternalAccount: context.setSelectedExternalAccount,
        showDetails: context.showDetails,
        setShowDetails: context.setShowDetails,
        showLimitModal: context.showLimitModal,
        setShowLimitModal: context.setShowLimitModal,
        formFilled: context.formFilled,
        setFormFilled: context.setFormFilled,
        saveEnabled: context.saveEnabled, 
        setSaveEnabled: context.setSaveEnabled,
        usingSuggestion: context.usingSuggestion,
        setUsingSuggestion: context.setUsingSuggestion,
        initializeFormFields: initializeFormFields,
        showPasswordReqError: context.showPasswordReqError,
        setShowPasswordReqError: context.setShowPasswordReqError
    }
}