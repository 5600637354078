import { IOptionBoxProps } from './interfaces/IOptionBoxProps';
import './OptionBox.scss';
/**
 * Box to indecate different options to user
 * @param img
 * @param altText
 * @param title
 * @param description
 * @param width (optional)
 */
export const OptionBox = (props: IOptionBoxProps) => {
  const { img, title, description, altText, width, active } = props;
  return (
    <div
      className={active ? 'option-box active' : 'option-box'}
      style={{ width: width }}
    >
      <img className="image" src={img} alt={altText}></img>
      <div className="content">
        <div className="title boldRegular">{title}</div>
        <div className="description small">{description}</div>
      </div>
    </div>
  );
};
