/**
 * Converts a number to a US currency format example: 100 becomes $100.00
 * @param amount a number value 
 */
export const displayCurrency = (amount: number) => {
    return (amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    })
    )
}