import { IToggleProps } from './interfaces/IToggleProps'
import './Toggle.scss'

/**Toggle component for all versions */
const Toggle = (props: IToggleProps) => {
    const {toggle, setToggle} = props

    return (
        <div className={toggle ? "toggle-wrapper on" : "toggle-wrapper off"} onClick={() => setToggle(!toggle)} data-testid="toggle" >
            <span className="on-text semibold" style={{visibility: !toggle ? 'hidden' : 'visible'}}>Yes</span>
            <span className="off-text semibold" style={{visibility: toggle ? 'hidden' : 'visible'}}>No</span>
            <div className={toggle ? "circle-wrapper right" : "circle-wrapper left"}>
                <div className="toggle-circle-selector" />
            </div>
        </div>
    )
}

export default Toggle