import { IButtonProps } from './interfaces/IButtonProps.type';
import { Button } from '../../common/Button/Button';
import './styles/Button.scss';

/**
 * Digital Bank Button component with styling
 * @param props IButtonProps interface
 * @returns common Button component
 */
export const ButtonDB = (props: IButtonProps) => {
  const {
    type,
    isDisabled = false,
    onClick = () => { },
    title,
    ariaLabel,
    children
  } = props;

  /**
   * Determines which class is applied depending on the type
   */
  const CLASS_NAME = (() => {
    let className = '';
    switch (type) {
      case 'primary':
        className = 'ButtonDB btn-primaryDB';
        break;
      case 'secondary':
        className = 'ButtonDB btn-secondaryDB';
        break;
      case 'secondary-square':
        className = 'ButtonDB btn-secondaryDB squareDB';
        break;
      case 'text':
        className = 'ButtonDB textDB';
        break;
    }

    if (isDisabled) {
      className += ' disabled';
    }

    return className;
  })();

  return (
    <Button
      className={CLASS_NAME}
      isDisabled={isDisabled}
      onClick={onClick}
      title={title}
      ariaLabel={ariaLabel}
      children={children}
    />
  );
};
