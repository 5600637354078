/**
 * List of environments.
 */
export const ENV = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  SIT: 'SIT',
  UAT: 'UAT',
  PROD: 'PROD'
} as const;

/**
 * Returns the environment we are currently in.
 * @returns 
 */
export const getEnvironment = () => {
  switch (window.location.host) {
    case 'localhost:3000':
    case 'localhost':
      return ENV.LOCAL;
    case 'deposit-dev.creditone.com':
    case 'deposit-dev.creditonebank.com':
      return ENV.DEV
    case 'deposit-sit.creditone.com':
    case 'deposit-sit.creditonebank.com':
      return ENV.SIT;
    case 'deposit-uat.creditone.com':
    case 'deposit-uat.creditonebank.com':
      return ENV.UAT
    case 'deposit.creditone.com':
    case 'deposit.creditonebank.com':
      return ENV.PROD
  }

  return ENV.LOCAL;
}