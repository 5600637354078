import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { addNewPhoneNumber, editPhoneNumber, removePhoneNumber, setCheckInternalToken, setOTPPassed, updateUserEmail } from "../../../slices/userInformation/userInformationSlice"
import { deletePhone_API, patchPhone_API, postPhone_API } from "../../../api/User/phone"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { patchEmail_API } from "../../../api/User/email"
import { Form } from '../../../Form/Form/Form/Form'
import { Control } from '../../../Form/Form/Control/Control'
import { useBriteVerifyOnForm } from '../../../shared/BriteVerify/BriteVerify'
import { useForm } from '../../../Form/useForm/useForm'
import { useUserProfileContext } from "../../../Pages/UserProfile/Context/useUserProfileContext"

const RE2 = RegExp;
/**A custom use hook for the user profile basic edit information page i.e. only one field is changing like email or phone*/
export const useUserProfileBasicEdit = (
    setOpenEditPage: React.Dispatch<React.SetStateAction<"" | "Email" | "Mobile" | "Alternate" | "Add" | "Addresses">>
) => {
    const { newPhone, setNewPhone, newEmail, setNewEmail, phoneInputError, setPhoneInputError, emailInputError, setEmailInputError,
        newPhoneRef, newEmailRef, saveEnabled, setSaveEnabled } = useUserProfileContext()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    // const [ saveEnabled, setSaveEnabled ] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const openOTPModal = useAppSelector((state: RootState) => state.userInformation.openOTPModal)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const selectedFieldRef = useRef<"Email" | "Mobile" | "Alternate" | "Add" | "Remove" | "">("")
    const handleSaveClickedRef = useRef<boolean>(false)
    const phoneIndexRef = useRef<number>(0)
    const { setToastMessage, resetToast, message, type } = useToastMessage()
    const lastKeyRef = useRef<string>("")
    const patternEmail = new RE2(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const [emailForm, dispatchForm] = useForm('form', [Control('email', newEmailRef.current, 'email', 'email')])
    const { isEmailValid } = useBriteVerifyOnForm(emailForm, dispatchForm)
    const apiStatusRef = useRef<true | false | null>(null)

    /**Handles the onChange for email and enables the Save button when a new value is inputted */
    const handleEmailOnChange = (e: any) => {
        newEmailRef.current = e.target.value
        setNewEmail(e.target.value)
        if (newEmailRef.current.length > 0 && newEmailRef.current.trim().length > 0) {
            setSaveEnabled(true)
        }
        else {
            setSaveEnabled(false)
        }
    }

    /**Checks to see where the dashes should be shown in the phone number */
    const checkDashes = (value: string) => {
        let newValue = ""
        const [left, middle, right] = value.split("-")
        const temp = (left || "") + (middle || "") + (right || "")
        for (let i = 0; i < temp.length; i = i + 1) {
            newValue = newValue + temp[i]
            if (i === 2 && i < temp.length - 1) {
                newValue = newValue + "-"
            }
            if (i === 5 && i < temp.length - 1) {
                newValue = newValue + "-"
            }
        }

        return newValue
    };

    /**handles key down press for the phone inputs */
    const phoneKeyDown = (e: any) => {
        lastKeyRef.current = e.key
        if (e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
            let patternNumber = new RegExp("^(?=.*\\d).+$")
            if (!patternNumber.test(e.key)) {
                e.preventDefault()
            }
        }
    }

    /**sets the correct cursor position */
    const setCursorPosition = (startPosition: number) => {
        const input: HTMLInputElement = document.getElementById("phone-input") as HTMLInputElement || document.getElementById("phone-input-mobile") as HTMLInputElement;
        input?.focus()
        if (lastKeyRef.current === "Backspace" || lastKeyRef.current === "Delete") {
            if (startPosition === 3 || startPosition === 7) {
                input?.setSelectionRange(startPosition + 2, startPosition + 2)
            }
            else if ((startPosition === 4 || startPosition === 8) && lastKeyRef.current === "Backspace") {
                input?.setSelectionRange(startPosition - 1, startPosition - 1)
            }
            else {
                input?.setSelectionRange(startPosition, startPosition)
            }
        }
        else {
            if (startPosition === 4 || startPosition === 8) {
                input?.setSelectionRange(startPosition + 1, startPosition + 1)
            }
            else {
                input?.setSelectionRange(startPosition, startPosition)
            }
        }
    }

    /**Handles the onChange for the phone and enables the Save button */
    const handlePhoneOnChange = (e: any) => {
        let startPosition = e.target.selectionStart
        const FORMATED_VALUE = checkDashes(e.target.value)
        newPhoneRef.current.value = FORMATED_VALUE
        setNewPhone(newPhoneRef.current.value)
        if (newPhoneRef.current.value.length === 12 && newPhoneRef.current.value.trim().length === 12) {
            phoneOnBlur()
        }
        else {
            setSaveEnabled(false)
        }
        setCursorPosition(startPosition)
    }

    /**handles the onBlur for the phone input */
    const phoneOnBlur = () => {
        let totalDashes = 0
        for (let i = 0; i < newPhoneRef.current.value.length; i = i + 1) {
            if (newPhoneRef.current.value[i] === "-") {
                totalDashes = totalDashes + 1
            }
        }
        if (newPhoneRef.current.value.length === 12 && totalDashes === 2) {
            setPhoneInputError(false)
            setSaveEnabled(true)
        }
        else {
            setPhoneInputError(true)
            setSaveEnabled(false)
        }
    }

    /**
     * Returns if the email is valid
     * @returns - If the email is valid 
     */
    const getEmailValidity = async () => {
        if (!patternEmail.test(newEmailRef.current)) {
            return Promise.resolve(false);
        }
        try {
            const emailControl = Control('email', newEmailRef.current, 'email', 'email')
            await isEmailValid(emailControl)
            return Promise.resolve(true);
        }
        catch (err) {
            return Promise.resolve(false);
        }
    }

    /**Handles the Save onClick event */
    const handleSave = async (field: "Email" | "Mobile" | "Alternate" | "Add" | "Remove", index: number = 0) => {
        if (!handleSaveClickedRef.current) {
            handleSaveClickedRef.current = true
            dispatch(setOTPPassed(false))
            selectedFieldRef.current = field
            // if (field === "Email" && await getEmailValidity()) {
            if (field === "Email") {
                let result = await getEmailValidity()
                if(result){
                    setEmailInputError(false)
                    dispatch(setCheckInternalToken(true))
                }
                else{
                    setSaveEnabled(false)
                    setEmailInputError(true)
                    apiStatusRef.current = false
                    handleSaveClickedRef.current = false
                }
                // initiateStepupProcess(field)
            }
            // if (field === "Email" && await !getEmailValidity()) {
            //     setSaveEnabled(false)
            //     setEmailInputError(true)
            //     apiStatusRef.current = false
            //     handleSaveClickedRef.current = false
            // }
            if (field === "Mobile" || field === "Alternate" || field === "Remove" || field === "Add") {
                phoneIndexRef.current = index
                dispatch(setCheckInternalToken(true))
                // initiateStepupProcess(field)
            }
            return new Promise((resolve) => {
                let interval: any
                interval = setInterval(() => {
                    if (apiStatusRef.current === false) {
                        apiStatusRef.current = null
                        resolve(true)
                        clearInterval(interval)
                    }
                }, 100)
            })
        }
    }

    /**check token and initiate api call stepup process */
    // const initiateStepupProcess = (field: "Email" | "Mobile" | "Alternate" | "Add" | "Remove") => {
    // dispatch(setOTPPassed(false))
    // handleSaveClickedRef.current = true
    // selectedFieldRef.current = field
    // dispatch(setCheckInternalToken(true))        
    // }

    /**useEffect monitors the OTP, if successful the service calls function will be called */
    useEffect(() => {
        if (OTPPassed && handleSaveClickedRef.current && apiStatusRef.current === null) {
            handleServiceCalls()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**handles the multiple service calls that can occur when the OTP is successful*/
    const handleServiceCalls = async () => {
        apiStatusRef.current = true
        if (selectedFieldRef.current === "Email") {
            try {
                await patchEmail_API(newEmailRef.current)
                dispatch(updateUserEmail(newEmailRef.current))
                setToastMessage("SUCCESS", "Email address changed")
                setOpenEditPage("")
                setNewEmail("")
                newEmailRef.current = ""
                setSaveEnabled(false)
            }
            catch {
                console.log("FAILED TO UPDATE THE EMAIL ADDRESS")
                handleAPIError()
            }
        }
        else if (selectedFieldRef.current === "Mobile" || selectedFieldRef.current === "Alternate") {
            try {
                await patchPhone_API(userInformation.contactInformation.phones[phoneIndexRef.current].id, newPhoneRef.current.value, selectedFieldRef.current === "Mobile" ? true : false)
                const payload = { arrayIndex: phoneIndexRef.current, newNumber: newPhoneRef.current.value }
                dispatch(editPhoneNumber(payload))
                if (selectedFieldRef.current === "Mobile") {
                    setToastMessage("SUCCESS", "Phone number changed")
                }
                else {
                    setToastMessage("SUCCESS", "Alternate number changed")
                }
                setNewPhone("")
                setOpenEditPage("")
                setSaveEnabled(false)
            }
            catch {
                console.log("FAILED TO UPDATE THE PHONE NUMBER")
                handleAPIError()
            }
        }
        else if (selectedFieldRef.current === "Add") {
            try {
                const response = await postPhone_API(newPhoneRef.current.value)
                const payload = { id: response.data.id, phoneNumber: newPhoneRef.current.value }
                dispatch(addNewPhoneNumber(payload))
                setToastMessage("SUCCESS", "Alternate number added")
                setNewPhone("")
                setOpenEditPage("")
                setSaveEnabled(false)
            }
            catch {
                console.log("FAILED TO ADD THE NEW PHONE NUMBER")
                handleAPIError()
            }
        }
        else if (selectedFieldRef.current === "Remove") {
            try {
                await deletePhone_API(userInformation.contactInformation.phones[phoneIndexRef.current].id)
                dispatch(removePhoneNumber(phoneIndexRef.current))
                setToastMessage("SUCCESS", "Alternate number removed")
                setOpenEditPage("")
                setNewPhone("")
                setSaveEnabled(false)
            }
            catch {
                console.log("Failed TO DELETE THE PHONE NUMBER")
                handleAPIError()
            }
        }
        handleSaveClickedRef.current = false
        apiStatusRef.current = false
        dispatch(setOTPPassed(false))
    }

    /**Handles the toast messaging of an API error */
    const handleAPIError = () => {
        setToastMessage("ERROR", "Unable to save changes. Please try again later")
        resetToast()
    }

    /**Handles the cancel button onClick event */
    const handleCancel = () => {
        setOpenEditPage("")
        setNewEmail("")
        setNewPhone("")
        setEmailInputError(false)
        setPhoneInputError(false)
        // newPhoneRef.current.value = 
        newEmailRef.current = ""
        setSaveEnabled(false)
    }

    return {
        userInformation, handleEmailOnChange, saveEnabled, newEmail, handleSave, handlePhoneOnChange, newPhone, openOTPModal,
        message, type, OTPPassed, handleCancel, phoneKeyDown, newPhoneRef, phoneInputError, phoneOnBlur, emailInputError, setSaveEnabled
    }
}
