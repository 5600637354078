/* eslint-disable @typescript-eslint/no-unused-vars */
import { Route, Switch, useHistory } from "react-router-dom";
import { AccountOverview } from "./Pages/AccountOverview/AccountOverview";
import AccountDetails from "./Pages/AccountDetails/AccountDetails";
import BeneficiaryPageWrapper from "./Pages/Beneficiary/BeneficiaryPageWrapper";
import AccountOwnershipPageWrapper from "./Pages/AccountOwnership/AccountOwnershipPageWrapper";
import AccountSettingsWrapper from "./Pages/AccountSettings/AccountSettings";
import InterestSettingsWrapper from "./Pages/InterestSettings/InterestSettingsWrapper";
import DocumentsPageWrapper from "./Pages/Documents/DocumentsPageWrapper";
import StatementsPageWrapper from "./Pages/Statements/StatementsPageWrapper";
import TransactionHistoryWrapper from "./Pages/TransactionHistory/TransactionHistoryWrapper";
import UserProfileWrapper from "./Pages/UserProfile/UserProfileWrapper";
import BumpRateWrapper from "./Pages/BumpRate/BumpRateWrapper";
import MaturitySettingsWrapper from "./Pages/MaturitySettings/MaturitySettingsWrapper";
import EarlyWithdrawalWrapper from "./Pages/EarlyWithdrawal/EarlyWithdrawalWrapper";
import VerificationLetterWrapper from "./Pages/VerificationLetter/VerificationLetterWrapper";
import GracePeriodWrapper from "./Pages/GracePeriod/GracePeriodWrapper";
import TransfersPageWrapper from "./Pages/Transfers/TransfersPageWrapper";
import ContactWrapper from "./Pages/Contact/ContactWrapper";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { getClidentId, getIssuer, getRedirect, getLogoutUri } from "./utils/globalVariables";
import LinkExternalAccountWrapper from "./Pages/LinkExternalAccount/LinkExternalAccountWrapper";
import VerifyExternalAccountWrapper from "./Pages/VerifyExternalAccount/VerifyExternalAccountWrapper";
import { useEffect, useState } from 'react';
import { ReroutePage } from './Pages/ReroutePage/ReroutePage';
import { shouldReroute } from './App';
import { LoginPage } from './Pages/LoginPage/LoginPage';
import AuthenticatePage from './Pages/AuthenticatePage/AuthenticatePage';
import { TestRoute } from './shared/components/TestRoute.component';
import { TestYodlee } from './Pages/TestYodlee/TestYodlee';

/**The okta authentication object */
export let oktaAuth: any;


export function Routes(): JSX.Element | null {
    const history = useHistory()
    const [isOktaSet, setIsOktaSet] = useState(false);
    useEffect(() => {
        oktaAuth = new OktaAuth({
            issuer: getIssuer(),
            clientId: getClidentId(),
            redirectUri: getRedirect(),
            pkce: true,
            scopes: ["openid", "email", "profile", "customer"],
            postLogoutRedirectUri: getLogoutUri
        })
        setIsOktaSet(true);
    }, [])
    /**Okta function to restore the original Uri */
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {

        if(localStorage.getItem("timerToken")){
            localStorage.removeItem("timerToken")
        }

        if (shouldReroute()) {
            history.push('/reroute');
        }
        else {
            history.replace(toRelativeUrl(originalUri || "/", window.location.origin))
        }
    }

    if (!isOktaSet) {
        return null;
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
                <SecureRoute path="/account/:id/beneficiary" component={BeneficiaryPageWrapper} />
                <SecureRoute path="/account/:id/settings" component={AccountOwnershipPageWrapper} />
                <SecureRoute path="/account/:id/maturity" component={MaturitySettingsWrapper} />
                <SecureRoute path="/account/:id/statements" component={StatementsPageWrapper} />
                <SecureRoute path="/account-overview" component={AccountOverview} />
                <SecureRoute path="/account/:id/interest" component={InterestSettingsWrapper} />
                <SecureRoute path="/accounts" component={AccountDetails} />
                <SecureRoute path="/documents" component={DocumentsPageWrapper} />
                <SecureRoute path="/user/profile" component={UserProfileWrapper} />
                <SecureRoute path="/user/:id/settings" component={AccountSettingsWrapper} />
                <SecureRoute path="/account/:id/transactions" component={TransactionHistoryWrapper} />
                <SecureRoute path="/account/:id/bumprate" component={BumpRateWrapper} />
                <SecureRoute path="/account/:id/earlywithdrawal" component={EarlyWithdrawalWrapper} />
                <SecureRoute path="/account/:id/verificationletter" component={VerificationLetterWrapper} />
                <SecureRoute path="/account/:id/graceperiod" component={GracePeriodWrapper} />
                <SecureRoute exact path="/" component={AccountOverview} />
                <Route exact path="/login/callback" component={LoginCallback} />
                <SecureRoute exact path="/transfers" component={TransfersPageWrapper} />
                <SecureRoute exact path='/knowledge-base' component={ContactWrapper} />
                <SecureRoute exact path='/linkexternalaccount' component={LinkExternalAccountWrapper} />
                <SecureRoute exact path='/:id/verifyexternalaccount' component={VerifyExternalAccountWrapper} />
                <SecureRoute exact path="/reroute" component={ReroutePage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/authenticate" component={AuthenticatePage} />
                <TestRoute exact path="/test-yodlee" component={TestYodlee} />
            </Switch>
        </Security>
    );
}