import { useSecuritySettings } from '../../../useHooks/componentHooks/useSecuritySettings/useSecuritySettings'
import './DesktopSecuritySettings.scss'
import { IDesktopSecuritySettingsProps } from './interfaces/IDesktopSecuritySettingsProps'
import bluePencil from '../../../assets/bluePencil.svg'
import ChangePassword from '../../../Pages/UserProfile/components/ChangePassword/ChangePassword'
import DesktopProfileHeader from '../../../Pages/UserProfile/components/DesktopProfileHeader/DesktopProfileHeader'
import { ITrustedDevice } from '../../../api/User/interfaces'
import DesktopManageDevices from '../../../Pages/UserProfile/components/DesktopManageDevices/DesktopManageDevices'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import warning from '../../../assets/warning.svg'
import iconHistory from '../../../assets/iconHistory.svg'

/**
 * Renders the Desktop version of the Security Settings tab on the User Profile page.
 */
const DesktopSecuritySettings = (props: IDesktopSecuritySettingsProps) => {
    const { currentTab, setCurrentTab, availableTabs } = props
    const { userInformation, handlePasswordClick, message, type, changePassword, setChangePassword, manageDevices, setManageDevices } = useSecuritySettings()
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)
    const gotTrustedDevices = useAppSelector((state: RootState) => state.userInformation.gotTrustedDevices)

    return (
        <div className="desktop-security-wrapper">
            {(!changePassword && !manageDevices) ?
                <>
                    <DesktopProfileHeader
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        availableTabs={availableTabs}
                    />
                    {message !== "" && <ToastMessageDB type={type} message={message} />}
                    <div className="page-card-wrap">
                        {gotUserContactInfo !== "Loading" ?
                            <div className="top-card">
                                <div className="left-side">
                                    <span className="sign-in-header boldRegular">Username and password </span>
                                    <span className="sign-in-text small">You’ll use these to sign in to your online account.</span>
                                </div>
                                <div className="right-side">
                                    <div className="user-row">
                                        <div className="left">
                                            <span className="username-header mobileBoldSmall">Username</span>
                                            <span className="username-text regular cls_mask">{userInformation.userName}</span>
                                        </div>
                                        {/* <div className="right">
                                        <img className="edit-pencil" src={bluePencil} alt="edit username" onClick={() => {setEditInformation(true); setEditMessage("Username")}}/>
                                        <span className="edit-text regular" onClick={() => {setEditInformation(true); setEditMessage("Username")}}>Edit</span>
                                    </div> */}
                                    </div>
                                    <div className="user-row">
                                        <div className="left">
                                            <span className="username-header mobileBoldSmall">Password</span>
                                            <span className="username-text regular">*************</span>
                                        </div>
                                        <div className="right">
                                            <img className="edit-pencil" src={bluePencil} alt="edit username" onClick={() => handlePasswordClick()} data-testid='change password' />
                                            <span className="edit-text regular" onClick={() => handlePasswordClick()} >Change password</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="loading-box" />
                        }
                        {gotTrustedDevices !== "Loading" ?
                            <div className="top-card">
                                {gotTrustedDevices === "Success" ?
                                    <>
                                        <div className="left-side">
                                            <span className="sign-in-header boldRegular">Sign-in history</span>
                                            <span className="sign-in-text small">View the devices used to access your account and the date they signed in.</span>
                                        </div>
                                        <div className="right-side row">
                                            <div className="device-stack">
                                                <div className="headers">
                                                    <span className="header-text boldSmall">Device</span>
                                                    <span className="header-text boldSmall">Date</span>
                                                </div>
                                                {userInformation.trustedDevices.slice(0, 5).map((device: ITrustedDevice) => {
                                                    return (
                                                        <div key={device.id} className="device-row-wrap">
                                                            <span className="data-text regular">{device.device}</span>
                                                            <span className="data-text regular">{convertDateTommddyyyy(device.lastSignIn)}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="edit-wrap" onClick={() => setManageDevices(true)} >
                                                <img src={iconHistory} alt="sign in history" />
                                                <span className="blue-text regular">View full history</span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="failed-devices-wrap">
                                        <img className="failed-image" src={warning} alt="failed to load trusted devices data" />
                                        <span className="message-title h4" >Unable to load data</span>
                                        <span className="message-text regular">Please wait a moment and try again.</span>
                                    </div>
                                }
                            </div>
                            :
                            <div className="loading-box" />
                        }
                    </div>
                </>
                :
                <>
                    {changePassword &&
                        <ChangePassword
                            setChangePassword={setChangePassword}
                        />
                    }
                    {manageDevices &&
                        <DesktopManageDevices
                            setManageDevices={setManageDevices}
                        />
                    }
                </>

            }
        </div>
    )
}

export default DesktopSecuritySettings