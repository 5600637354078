import './AccountOverviewDropDown.scss'
import arrowDownWhite from '../../../../../../assets/arrowDownWhite.svg'
import editPencil from '../../../../../../assets/editPencil.svg'
import { IAccountOverviewMobileDropDownProps } from './interfaces/IAccountOverviewMobileDropDownProps'
import { displayCurrency } from '../../../../../../utils/displayCurrency'
import DisplayAccount from '../../../DisplayAccount/DisplayAccount'
import { useHistory } from 'react-router'
import { useAppSelector } from '../../../../../../app/hooks'
import { RootState } from '../../../../../../app/store'

/**
 * Renders the Mobile version of the Overview Drop Down component. This is the bottom portion of
 * the Overview Component that drops down when the More Account Details button is pressed.
 * @param selectedAccount = IAccountInformation
 */
const AccountOverviewDropDown = (props: IAccountOverviewMobileDropDownProps) => {
    const history = useHistory()
    const { selectedAccount, showDropDown, setShowDropDown, initialClick, setInitialClick, show, setShow } = props;
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return (
        <>
            {isAccountDetailsLoaded !== "Loading" ?
                <div className="account-overview-drop-down-wrapper">
                    {show &&
                        <div className={showDropDown ? "show-drop-down-details" : "close-drop-down-details"}>
                            <div className="top-portion">
                                <div className="row">
                                    <span className="field-text mobileRegular">Account type</span>
                                    <span className="values-text semiboldRegular">{selectedAccount.accountType}</span>
                                </div>
                                <div className="row">
                                    <span className="field-text mobileRegular">Account number</span>
                                    <div className="values-wrap">
                                        <DisplayAccount selectedAccount={selectedAccount} showDropDown={showDropDown} />
                                    </div>
                                </div>
                                <div className="row">
                                    <span className="field-text mobileRegular">Routing number</span>
                                    <span className="values-text semiboldRegular">{selectedAccount.routingNumber}</span>
                                </div>
                                <div className="row">
                                    <span className="field-text mobileRegular">Ownership</span>
                                    {selectedAccount.owner.length === 1 ?
                                        <div className="values-wrap">
                                            <span className="values-text semiboldRegular">{selectedAccount.owner[0].name}</span>
                                            <img onClick={() => history.push(`/account/${selectedAccount.id}/settings`, {page: "Account Details"})} className="edit-ownership" src={editPencil} alt="Edit Icon" />
                                        </div>
                                        :
                                        <span className="values-text boldRegular joint-account" onClick={() => history.push(`/account/${selectedAccount.id}/settings`)}>Joint ({selectedAccount.owner.length})</span>
                                    }
                                </div>
                            </div>
                            <div className="bottom-portion">
                                <div className="row">
                                    <span className="field-text mobileRegular">Interest paid to date</span>
                                    <span className="values-text semiboldRegular">{displayCurrency(selectedAccount.interestPaid as number)}</span>
                                </div>
                                {selectedAccount.accountType !== "Savings" &&
                                    <div className="row">
                                        <span className="field-text mobileRegular">Interest paid YTD</span>
                                        <span className="values-text semiboldRegular">{displayCurrency(selectedAccount.interestPaidYTD as number)}</span>
                                    </div>
                                }
                                <div className="row">
                                    <span className="field-text mobileRegular">Interest paid prior year</span>
                                    <span className="values-text semiboldRegular">{displayCurrency(selectedAccount.interestPaidPriorYear as number)}</span>
                                </div>
                                <div className="row">
                                    {selectedAccount.beneficiaries.length === 0 ?
                                        <>
                                            <span className="field-text mobileRegular">Payable on death</span>
                                            <div className="values-wrap" onClick={() => history.push(`/account/${selectedAccount.id}/beneficiary`, {page: "Account Details"})}>
                                                <span className="values-text semiboldRegular" >No</span>
                                                {(!selectedAccount.accountClosed && !selectedAccount.pendingGraceClosure) && <img className="edit-ownership" src={editPencil} alt="Edit Icon" />}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <span className="field-text mobileRegular">Payable on death</span>
                                            <span className="values-text semiboldRegular">Yes</span>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className="account-details-wrapper" >
                        <div className="account-details-wrapper" onClick={() => { setShowDropDown(!showDropDown); setShow(!show); setInitialClick(true) }}>
                            {!show ? <span className="details-text semiboldRegular">More account details</span> :
                                <span className="details-text semiboldRegular">Fewer account details</span>
                            }
                            {initialClick ? <img className={showDropDown ? "drop-down-icon-open icon" : "drop-down-icon-close icon"} src={arrowDownWhite} alt="Drop Down Icon" /> :
                                <img className="icon" src={arrowDownWhite} alt="Drop Down Icon" />
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="mobile-bottom-loading">
                    <div className="loading-box-1" />
                </div>
            }
        </>
    )
}

export default AccountOverviewDropDown