import { IDesktopTransactionDetailsProps } from './interfaces/IDesktopTransactionDetailsProps';
import './DesktopTransactionDetails.scss';
import arrow from '../../../../assets/arrowTransactionDetails.svg';
import { converDateWithTimeLongFormat, convertDateTommddyyyy, convertDateWithTime, isUTCFormat } from '../../../../utils/DateUtils';
import { Pill } from '../../../Pill/Pill';
import { Memo } from '../Memo/Memo';
import { displayCurrency } from '../../../../utils/displayCurrency';
import { clearSelectedTransaction } from '../../../../slices/selectedTransaction/selectedTransactionSlice';
import { useTransactionDetails } from '../../../../useHooks/componentHooks/useTransactionDetails/useTransactionDetails';

/**
 * Transaction Details for Desktop
 * @param setDisplayDetails - for the user to be able to close the sidebar
 * @param selectedTransaction - transaction to show the details of
 */
export const DesktopTransactionDetails = (
  props: IDesktopTransactionDetailsProps
) => {
  const { displayDetails, setDisplayDetails, selectedTransaction } = props;
  const { dispatch, detailsRef, isMemoSelected } = useTransactionDetails()
  return (
    <div className="DesktopTransactionDetails">
      <>
        {displayDetails && <div className="Overlay"></div>}
        {/* <div className={`Detail-Wrapper ${displayDetails ? ` Open` : ` Close`}`} ref={detailsRef}> */}
        <div className={`Detail-Wrapper Open`} ref={detailsRef}>
          <div className="Title-Wrapper">
            <img
              className="Arrow"
              src={arrow}
              alt="arrow"
              onClick={() => {
                setDisplayDetails(false);
                dispatch(clearSelectedTransaction())
              }}
            />
            <h5 className="Title">Transaction details</h5>
          </div>
          <div className="Title-Line"></div>
          <div className="Data-Wrapper">
            <div className="Transaction-Description x-large">
              {selectedTransaction?.description}
            </div>
            <div className="Transaction-Amount-Wrapper">
              <h2 className="Transaction-Amount">
                {displayCurrency(selectedTransaction.amount)}
              </h2>
              {selectedTransaction.isAmountHold && <div className="Status">
                <Pill title="On Hold" />
              </div>}
            </div>
            <div className="Row">
              <span className="Row-Title boldRegular">Transaction date</span>
              <span className="Row-Data regular">
                {isUTCFormat(selectedTransaction?.effectiveDate) ? converDateWithTimeLongFormat(selectedTransaction?.effectiveDate) : convertDateTommddyyyy(selectedTransaction?.effectiveDate)}
                {/* {convertDateWithTime(selectedTransaction?.effectiveDate)} */}
              </span>
            </div>
            <div className="Row">
              <span className="Row-Title boldRegular">Transaction type</span>
              <span className="Row-Data regular">
                {selectedTransaction?.type}
              </span>
            </div>
            {selectedTransaction?.institution &&
              <div className="Row">
                <span className="Row-Title boldRegular">Institution</span>
                <span className="Row-Data regular">
                  {selectedTransaction?.institution}
                </span>
              </div>
            }
            {(selectedTransaction.isAmountHold || selectedTransaction.status === "Pending") &&
              <>
                <div className="Row">
                  <span className="Row-Title boldRegular">Available now</span>
                  <span className="Row-Data regular">
                    {displayCurrency(0)}
                    {/* {displayCurrency(selectedTransaction.availableNow)} */}
                  </span>
                </div>
                {/* <div className="Row">
                  <span className="Row-Title boldRegular">
                    Available on{' '}
                    {convertDateWithTime(selectedTransaction.availableOn)}
                  </span>
                  <span className="Row-Data regular">
                    {displayCurrency(selectedTransaction.holdAmount)}
                  </span>
                </div> */}
              </>}
            <Memo selectedTransaction={selectedTransaction} displayDetails={displayDetails} isMemoSelected={isMemoSelected} />

            {selectedTransaction?.isAmountHold && (
              <span className="Disclaimer mobileSmall">
                Some funds are on hold while we process your deposit. For more
                information, please call us at 877-628-1610
              </span>
            )}
          </div>
        </div>
      </>
    </div>
  );
};
