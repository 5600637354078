import { createContext, useContext, useEffect, useState } from 'react';
import { useWindowResize } from '../../useHooks/WindowResizeHook/windowResize';
import {
  IWindowStateContext,
} from './interfaces/IWindowStateContext';

/**
 * The AccountContext handles all things related to the accounts
 */
export const WindowStateContext = createContext<IWindowStateContext>({ windowState: "Desktop", setWindowState: () => { } });
export const WindowStateContextProvider = (props: any) => {
  const windowSize = useWindowResize();
  const [windowState, setWindowState] = useState<
    'Desktop' | 'Hybrid' | 'Mobile' | ''
  >(windowSize);

  useEffect(() => {
    if (windowSize !== windowState) {
      setWindowState(windowSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  return (
    <WindowStateContext.Provider
      value={{
        windowState,
        setWindowState,
      }}
    >
      {props.children}
    </WindowStateContext.Provider>
  );
};

/**
 * Starting the Account Information for detailed account info,
 * transaction history, and transaction details
 */
export const useWindowState = () => {
  //giving the function access to Context
  const context = useContext(WindowStateContext);


  return {
    windowState: context.windowState,
  };
};
