import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { MobileLayout } from "../../layouts/MobileLayout/MobileLayout"
import DesktopVerifyExternalAccount from "./DesktopVerifyExternalAccount/DesktopVerifyExternalAccount"
import MobileVerifyExternalAccount from "./MobileVerifyExternalAccount/MobileVerifyExternalAccount"

/**Determines whether to render the desktop or mobile version of VerifyExternalAccount */
const VerifyExternalAccountWrapper = () => {
    const { windowState } = useWindowState()

    return (
        <>
        {windowState !== "Mobile" ?
            <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopVerifyExternalAccount />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopVerifyExternalAccount />
                    </HybridLayout>
                }
            </>
            :
            <MobileLayout>
                <MobileVerifyExternalAccount />
            </MobileLayout>
        }
        </>
    )
}

export default VerifyExternalAccountWrapper