import Headers from '../../components/Headers/Headers';
import { SideNavigation } from '../../components/SideNavigation/SideNavigation';
import { usePageLayout } from '../../useHooks/LayoutHooks/usePageLayout';
import { ReduxWithData } from '../ReduxWithData/ReduxWithData';
import { IMobileLayoutProps } from './interfaces/IMobileLayoutProps';
import './MobileLayout.scss';

/**
 * layout for mobile version of application. Used as a wrapper for mobile pages. Has a header and navigation
 * @param children a JSX element
 */
export const MobileLayout = (props: IMobileLayoutProps) => {
  const {isAccountInformationLoaded, gotUserContactInfo} = usePageLayout()

  return (
    <div className='MobileLayout'>
      {((isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") && (gotUserContactInfo === "Success" || gotUserContactInfo === "Loading")) ?
      <>
        <div className="Navigation">
          <SideNavigation />
        </div>
        <Headers />
        <div className="Children">{props.children}</div>
      </>
      : 
      <>
        <Headers />
        <div className="Children error">{props.children}</div>
      </>
      }
      <ReduxWithData />
    </div>
  );
};
