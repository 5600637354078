/* tslint:disable */
/* eslint-disable */
/**
 * Savings BFF API
 * This is BFF savings API
 *
 * OpenAPI spec version: 1.0.32
 * Contact: savingsbff@creditone.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResponseStatus {
    SUCCESS = 'SUCCESS',
    CREATED = 'CREATED',
    UPDATED = 'UPDATED'
}

