import './BeneficiaryCard.scss';
import { Card } from '../Card/Card';
import { useBeneficiaryCard } from '../../useHooks/componentHooks/useBeneficiaryCard/useBeneficiaryCard';
import { PieChart } from '../PieChart/PieChart';
import DisplayBeneficiaries from './components/DisplayBeneficiaries';
import warning from '../../assets/warning.svg'
import beneficiaryPeopleCard from '../../assets/beneficiaryPeopleCard.svg'
import { setStartBeneficiaryFlow } from '../../slices/selectedAccountSlice/selectedAccountSlice';
import horizontalDots from '../../assets/horizontalDots.svg'
import greyPerson from '../../assets/greyPerson.svg'
import greyPlusSign from '../../assets/greyPlusSign.svg'

/** 
 * Displays the beneficiary card displayed on the account details page for
 * both desktop and mobile versions.
 * @param accountId : string
 */
export const BeneficiaryCard = () => {
  const { selectedAccount, history, beneficiariesTruncated, isBeneficiariesLoaded, dispatch, isMenuClicked, setIsMenuClicked,
    menuRef, windowState, handleScrollLock } = useBeneficiaryCard()
  const MAX_BENEFICIARIES = 10

  return (
    <>
      {isBeneficiariesLoaded !== "Loading" ?
        <Card width={"352px"} height={"444px"} showTopBorder={true} topBorderColor={"var(--secondaryDarkBlue)"}>
          {isBeneficiariesLoaded === "Success" ?
            <div className="beneficiary-card">
              {selectedAccount.beneficiaries && selectedAccount.beneficiaries.length ?
                <>
                  <div className="card-information">
                    <div className="header-wrap">
                      <span className="card-title boldLarge">Beneficiaries</span>
                      <div className="menu-wrap">
                        <img data-testid="card-elipses" className="horizontal-dots" src={horizontalDots} alt="menu" onClick={() => setIsMenuClicked(true)} />
                        {isMenuClicked &&
                          <>
                            {windowState === "Mobile" && <div className="overlay" />}
                            <div className={windowState !== "Mobile" ? "menu-selection-box-desktop" : "menu-selection-box-mobile"} ref={menuRef}>
                              {windowState === "Mobile" &&
                                <div className="mobile-header-wrap">
                                  <span className="cancel-button regular" onClick={() => { setIsMenuClicked(false); handleScrollLock() }}>Close</span>
                                  <span className="beneficiary-option-text semiboldRegular">Beneficiary options</span>
                                </div>
                              }
                              <div className="option-row-wrap" onClick={() => { history.push(`/account/${selectedAccount.id}/beneficiary`, { page: "Account details" }); dispatch(setStartBeneficiaryFlow(false)); handleScrollLock() }}>
                                <img className="image" src={greyPerson} alt="manage beneficiaries" />
                                <span className="row-text regular" >Manage beneficiaries</span>
                              </div>
                              <div className={windowState !== "Mobile" ? "bottom-border-desktop" : "bottom-border-mobile"} />
                              {selectedAccount.beneficiaries.length < MAX_BENEFICIARIES && <div className="option-row-wrap" onClick={() => { history.push(`/account/${selectedAccount.id}/beneficiary`, { page: "Account Details" }); dispatch(setStartBeneficiaryFlow(true)); handleScrollLock() }}>
                                <img className="image" src={greyPlusSign} alt="add beneficiary" />
                                <span className="row-text regular">Add beneficiaries</span>
                              </div>}
                            </div>
                          </>
                        }
                      </div>
                    </div>
                    <div className="pie-chart-wrapper">
                      <div className="pie-chart">
                        {
                          selectedAccount.beneficiaries && selectedAccount.beneficiaries.length > 4 ?
                            <PieChart beneficiaries={beneficiariesTruncated} />
                            :
                            <PieChart beneficiaries={selectedAccount.beneficiaries} />
                        }
                      </div>
                    </div>
                    <div className="member-wrapper">
                      {
                        selectedAccount.beneficiaries && selectedAccount.beneficiaries.length > 4 ?
                          <DisplayBeneficiaries beneficiaries={beneficiariesTruncated} />
                          :
                          <DisplayBeneficiaries beneficiaries={selectedAccount.beneficiaries} />
                      }
                    </div>
                  </div>
                </>
                :
                <div className="beneficiary-card-empty-state">
                  <div className="top-portion">
                    <span className="title-text boldLarge">Beneficiaries</span>
                    <div className="image-wrapper">
                      <img className="card-image" src={beneficiaryPeopleCard} alt="people" />
                    </div>
                    <span className="image-text boldRegular">You haven’t named any beneficiaries on your account yet.</span>
                  </div>
                  <button className="Button btn-primary" onClick={() => { history.push(`/account/${selectedAccount.id}/beneficiary`); dispatch(setStartBeneficiaryFlow(true)) }}>
                    Add a beneficiary
                  </button>
                </div>
              }
            </div>
            :
            <div className="card-error-state">
              <span className="title-text boldLarge">Beneficiaries</span>
              <div className="image-wrap">
                <img className="error-image" src={warning} alt="error" />
                <span className="sub-title-text boldButtonLarge">Unable to load data</span>
                <span className="text regular">Unable to load data. Please wait a moment and try again</span>
              </div>
            </div>
          }
        </Card>
        :
        <div className="card-loading-state" />
      }
    </>
  );
}; 
