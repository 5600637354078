import { useCDSelection } from "../../../../../useHooks/componentHooks/useCDSelection/useCDSelection"
import { IMobileCDSelectionProps } from "./interfaces/IMobileCDSelectionProps"
import "./MobileCDSelection.scss"
import arrowLeftBlue from '../../../../../assets/arrowLeftBlue.svg'
import { RadioButton } from "../../../../../components/RadioButton/RadioButton"
import information from '../../../../../assets/information.svg'
import { IProduct } from "../../../../../api/getAccountInformation"
import ProductsModal from "../../../../../components/ProductsModal/ProductsModal"
import warning from '../../../../../assets/warning.svg'

/**Renders the Mobile CD Selection component found in the maturity settings flow when "Renew" has been selected */
const MobileCDSelection = (props: IMobileCDSelectionProps) => {
    const { chosenTerm, setChosenTerm, setPlanChange, setOpenChangeOption, setChangePlanOption, setShowTermChange, 
            setTermChange, termChange, CDProductList, bumpProductList } = props
    const { enableContinue, setEnableContinue, showModal, setShowModal, onChangeHandler, showTooltip, setShowTooltip, gotProductList } = useCDSelection(chosenTerm, setChosenTerm)
    
    // variable for the verbage of how month should be displayed
    const month = ' months'
    return (
        <div className="mobile-cd-selection">
          {gotProductList !== 'Loading' ? (
            <>
            <div className="header-wrap">
                <div className="left-side">
                    <img src={arrowLeftBlue} alt="back button" />
                    <span className="header-text regular" onClick={() => {setOpenChangeOption(!termChange ? true : false); setChangePlanOption(""); setTermChange(false)}}>Back</span>
                </div>
                <span className="header-text regular" onClick={() => {setPlanChange(false); setChangePlanOption(""); setTermChange(false)}}>Cancel</span>
            </div>
            <div className="mobile-card-wrapper">
                <div className="card-header-wrap">
                    <span className="header-text small">Change maturity plan</span>
                    <span className="sub-header-text h4">Select a term for your CD</span>
                    <span className="view-rates small" onClick={() => setShowModal(true)}>View today’s rates</span>
                </div>
                {gotProductList !== "Failed" ? (
                <div className="mobile-display-options">
                    <div className="cd-group">
                        <div className="options-header-wrap">
                            <span className="header-text boldLarge">Jumbo CD</span>
                        </div>
                        {CDProductList.map((cd: IProduct, index: number) => {
                            return (
                                <div data-testid={`Jumbo CD ${cd.name}`} key={index} className={chosenTerm?.code === cd.code ? "row-wrapper selected" : "row-wrapper"} onClick={() => {setChosenTerm(cd); setEnableContinue(true)}}>
                                    <RadioButton
                                        value={cd.name}
                                        name="new term"
                                        className="radio"
                                        onChange={() => onChangeHandler(cd)}
                                        checked={chosenTerm?.code === cd.code}
                                        textAlign="Right"
                                    >{cd.name.split(' ')[0] + month as any}</RadioButton>
                                </div>
                            )
                        })}
                    </div>
                    <div className="cd-group">
                        <div className="options-header-wrap">
                            <span className="header-text boldLarge">Jumbo Bump-Up CD</span>
                            <img className="information-image" src={information} alt="information" onClick={() => setShowTooltip(true)}/>
                        </div>
                        {showTooltip &&
                            <div className="tooltip-modal-overlay">
                                <div className="tooltip-modal-wrap">
                                    <div className="header-wrap">
                                        <span className="title-text semiboldRegular">Bump-Up Jumbo CD</span>
                                        <span className="close-text regular" onClick={() => setShowTooltip(false)}>Close</span>
                                    </div>
                                    <p className="text regular">Our Jumbo Bump-Up CD lets you take advantage of rising interest rates with a one-time rate increase option. See the FAQs for more information.</p>
                                </div>
                            </div>
                        }
                        {bumpProductList.map((cd: IProduct, index: number) => {
                            return (
                                <div data-testid={`Jumbo Bump-Up CD ${cd.name}`} key={index} className={chosenTerm?.code === cd.code ? "row-wrapper selected" : "row-wrapper"} onClick={() => {setChosenTerm(cd); setEnableContinue(true)}}>
                                    <RadioButton
                                        value={cd.name}
                                        name="new term"
                                        className="radio"
                                        onChange={() => onChangeHandler(cd)}
                                        checked={chosenTerm?.code === cd.code}
                                        textAlign="Right"
                                    >{cd.name.split(' ')[0] + month as any}</RadioButton>
                                </div>
                            )
                        })}
                    </div>
                </div>
                ) : (
                  <div className="product-api-fail-wrap">
                    <img className="image" src={warning} alt="warning circle" />
                    <span className="header-text h4">Unable to load data</span>
                    <span className="sub-text regular">Please wait a moment and try again</span>
                  </div>
                )}
            </div>
            <div className="button-wrapper">
                <button className={enableContinue ? "continue-button mobileButtonBold" : "continue-button disabled mobileButtonBold"} onClick={() => {if(enableContinue){setShowTermChange(true); setChangePlanOption("Renew")}}}>Continue</button>
            </div>
            </>
          )
          : (
            <div className="loading-box"/>
          )
          }
            {showModal &&
                <ProductsModal 
                    setShowModal={setShowModal}
                    CDProductList={CDProductList}
                    bumpProductList={bumpProductList}
                    showSavingsProducts={false}
                    origin="maturity"
                />
            }
        </div>
    )
}

export default MobileCDSelection