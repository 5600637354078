import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountInformationReducer from '../slices/accountInformation/accountInformationSlice'
import selectedAccountReducer from '../slices/selectedAccountSlice/selectedAccountSlice'
import transactionsReducer from '../slices/transactions/transactionsSlice'
import userInformationReducer from '../slices/userInformation/userInformationSlice'
import selectedTransactionReducer from '../slices/selectedTransaction/selectedTransactionSlice'

export const store = configureStore({
  reducer: {
    accountInformation: accountInformationReducer,
    selectedAccount: selectedAccountReducer,
    transactions: transactionsReducer,
    userInformation: userInformationReducer,
    selectedTransaction: selectedTransactionReducer
  },
});

export const createNewStore = () => {
  return configureStore({
    reducer: {
      accountInformation: accountInformationReducer,
      selectedAccount: selectedAccountReducer,
      transactions: transactionsReducer,
      userInformation: userInformationReducer,
      selectedTransaction: selectedTransactionReducer
    },
  });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;