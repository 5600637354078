import axios from "axios"
import { bffBaseUrl } from "../../utils/globalVariables"

/**makes an api call to link a new external account */
export const linkExternalAccount_API = (account: { accountType: string, routingNumber: string, accountNumber: string, channel: string, accountTitle: string }) => {
    return axios.post(`${bffBaseUrl}/customers/externalaccounts`,
        {
            accountType: account.accountType,
            routingNumber: account.routingNumber,
            accountNumber: account.accountNumber,
            channel: account.channel,
            accountTitle: account.accountTitle,
            acctNickName: account.accountTitle
        },
        {
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken"),
                apiVersion: "2.0.0"
            }
        }
    )
}

/**makes an api call to change the nickname of an external account */
export const changeExternalAccountNickname_API = (accountId: string, nickname: string) => {
    return axios.patch(`${bffBaseUrl}/customers/externalaccounts/${accountId}/nickname`,
        {
            accountNickName: nickname
        }
    )
}

/**makes an api call to get the nickname on a particular external account */
export const getExternalAccountNickname_API = (accountId: string) => {
    return axios.get(`${bffBaseUrl}/customers/externalaccounts/${accountId}/nickname`)
}

/**makes an api call to delete an external account */
export const deleteExternalAccount_API = (accountId: string) => {
    return axios.delete(`${bffBaseUrl}/customers/externalaccounts/${accountId}`)
}

/**makes an api call to initiate the micro transactions, meaning two small transactions will be sent to the external account being linked*/
export const initiateMicroTransactions_API = (accountId: string) => {
    return axios.put(`${bffBaseUrl}/customers/externalaccounts/${accountId}/verification`,
        {
            action: "initiate"
        }
    )
}

/**makes an api call that sends both micro transactions to validate the external account being linked */
export const validateMicroTransactions_API = (accountId: string, value1: string, value2: string) => {
    return axios.put(`${bffBaseUrl}/customers/externalaccounts/${accountId}/verification`,
        {
            value1: value1,
            value2: value2,
            action: "validate"
        }
    )
}