import './BeneficiaryConfirmation.scss'
import { IBeneficiaryConfirmationProps } from './interfaces/IBeneficiaryConfirmation'
import greenCheckmark from '../../assets/greenCheckmark.svg'
import { Button } from '../Button/Button'
import { createID } from '../../utils/createId'
import { IBeneficiary } from '../../api/getBeneficiaries'
import { useWindowState } from '../../Context/AccountContext/useWindowState'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'

/**Renders the Beneficiary Confirmation page found at the end of the add new beneficiary flow */
const BeneficiaryConfirmation = (props: IBeneficiaryConfirmationProps) => {
    const { windowState } = useWindowState()
    const { setCurrentPage, setDisplayAddBeneficiary, setNewBeneficiary, form, dispatchForm } = props
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const defaultBeneficiary: IBeneficiary = {
        name: "",
        id: createID(),
        percentage: 0,
        tin: '',
        address1: '',
        city: '',
        state: '',
        zipCode: '',
        type: '',
        isDeleted: false
    };
    const MAX_BENEFICIARIES = 10;

    return (
        <div className="beneficiary-confirmation-wrap">
            <div className="confirmation-info-wrap">
                <img className="checkmark" src={greenCheckmark} alt="green checkmark" />
                <span className="confirmation-text mobileh3">Beneficiary added</span>
                <div className="confirmation-details">
                    {form.refControls.firstName.value !== "" && <p>{form.refControls.firstName.value} {form.refControls.lastName.value}</p>}
                    {form.refControls.charityName.value !== "" && <p>{form.refControls.charityName.value}</p>}
                    {form.refControls.trustName.value !== "" && <p>{form.refControls.trustName.value}</p>}
                    {form.refControls.firstName.value === "" && form.refControls.charityName.value === "" && form.refControls.trustName.value === "" && <p>{selectedAccount.beneficiaries[0].name}</p>}
                    <span className="semiboldRegular">Allocation: </span>
                    <span className="regular">{selectedAccount.beneficiaries[selectedAccount.beneficiaries.length - 1].percentage}%</span>
                </div>
                {windowState !== "Mobile" ?
                    <div className="confirmation-buttons-wrap btn-group">
                        {selectedAccount.beneficiaries.length < MAX_BENEFICIARIES && <Button onClick={() => { setCurrentPage(0); setNewBeneficiary(defaultBeneficiary); setDisplayAddBeneficiary(true); form.resetForm(); dispatchForm() }}
                            className="Button btn-secondary"
                            title="Add another"
                        />}
                        <Button onClick={() => { setNewBeneficiary(defaultBeneficiary); setCurrentPage(0); setDisplayAddBeneficiary(false); form.resetForm(); dispatchForm() }}
                            className="Button btn-primary"
                            title="Return to beneficiaries"
                        />
                    </div>
                    :
                    <div className="confirmation-buttons-wrap mobile">
                        <Button
                            onClick={() => { setNewBeneficiary(defaultBeneficiary); setCurrentPage(0); setDisplayAddBeneficiary(false); form.resetForm(); dispatchForm() }}
                            className="Button btn-primary"
                            title="Return to beneficiaries"
                        />
                        {selectedAccount.beneficiaries.length < MAX_BENEFICIARIES && <Button
                            onClick={() => { setCurrentPage(0); setNewBeneficiary(defaultBeneficiary); form.resetForm(); dispatchForm() }}
                            className="Button btn-secondary"
                            title="Add another"
                        />}
                    </div>
                }
            </div>
        </div>
    )
}

export default BeneficiaryConfirmation