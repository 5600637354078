import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import './MaxAccountsModal.scss'
import { IMaxAccountsModalProps } from './interfaces/IMaxAccountsModalProps'
import redCircleExclamation from '../../../../assets/redCircleExclamation.svg'
import { useEffect } from 'react'
import { getEventPath } from '../../../../utils/getEventPath'

/**Renders the maximum number of linked external accounts modal */
const MaxAccountsModal = (props: IMaxAccountsModalProps) => {
    const { windowState } = useWindowState()
    const { setShowMaxAccountsModal } = props

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-card-wrap")) === -1){
                document.body.style.overflow = "auto";
                setShowMaxAccountsModal(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Remove the scrollbar when the modal renders */
    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {document.body.style.overflow = "auto"}
    }, [])

    return (
        <div data-testid="max-accounts-overlay" className="max-accounts-overlay">
            {windowState !== "Mobile" ?
                <div className="modal-card-wrap">
                    <img className="modal-image" src={redCircleExclamation} alt="red circle with exclamation mark" />
                    <span className="modal-title h4">External account limit reached</span>
                    <span className="modal-text regular">To link a new account, first remove one of your existing accounts.</span>
                    <button className="Button btn-primary" onClick={() => setShowMaxAccountsModal(false)}>Close</button>
                </div>
                :
                <div className="modal-card-wrap mobile">
                    <img className="modal-image" src={redCircleExclamation} alt="red circle with exclamation mark" />
                    <span className="modal-title h4">External account limit reached</span>
                    <span className="modal-text regular">To link a new account, first remove one of your existing accounts.</span>
                    <button className="Button btn-primary mobile" onClick={() => setShowMaxAccountsModal(false)}>Close</button>
                </div>
            }
        </div>
    )
}

export default MaxAccountsModal