import { ISelectBeneficiaryTypeProps } from './interfaces/ISelectBeneficiaryTypeProps';
import './SelectBeneficiaryType.scss';
import personImg from '../../../../assets/person.svg';
import trustImg from '../../../../assets/trust.svg';
import charityImg from '../../../../assets/badge-charity.svg';
import { OptionBox } from '../../../OptionBox/OptionBox';
import { Button } from '../../../Button/Button';
import { useWindowState } from '../../../../Context/AccountContext/useWindowState';

/**
 * First step in add beneficiary form to select a type
 */
export const SelectBeneficiaryType = (props: ISelectBeneficiaryTypeProps) => {
  const { windowState } = useWindowState();
  const { handleOnNext, handleOnCancel, setNewBeneficiary, newBeneficiary, selectedAccount} = props;
  return (
    <div className="select-beneficiary-type">
      <div className="form">
          
        <div className="title-lockup">
            <div className="pre-header"><div className="content">Add Beneficiary</div><div className="step-count">{selectedAccount.beneficiaries.length > 0 ? "Step 1 of 4" : "Step 1 of 3"}</div></div>
            <h2>What type of beneficiary would you like to add?</h2>
        </div>

        <div className={windowState !== "Mobile" ? "cards-wrapper" : "cards-wrapper mobile"}>
          <div
            className={windowState !== "Mobile" ? "card person-wrapper" : "card person-wrapper mobile"}
            onClick={() => {
              setNewBeneficiary({ ...newBeneficiary, type: 'Person' });
            }}
          >
            <OptionBox
              img={personImg}
              altText={'person'}
              title="Person"
              description=""
              active={newBeneficiary.type === 'Person'}
            />
          </div>
          <div
            className={windowState !== "Mobile" ? "card trust-wrapper" : "card trust-wrapper mobile"}
            onClick={() => {
              setNewBeneficiary({ ...newBeneficiary, type: 'Trust' });
            }}
          >
            <OptionBox
              img={trustImg}
              altText={'trust'}
              title="Trust"
              description=""
              active={newBeneficiary.type === 'Trust'}
            />
          </div>
          <div
            className={windowState !== "Mobile" ? "card charity-wrapper" : "card charity-wrapper mobile"}
            onClick={() => {
              setNewBeneficiary({ ...newBeneficiary, type: 'Charity' });
            }}
          >
            <OptionBox
              img={charityImg}
              altText={'charity'}
              title="Charity or nonprofit"
              description=""
              active={newBeneficiary.type === 'Charity'}
            />
          </div>
        </div>
        
      </div>

      {windowState !== "Mobile" ?
        <div className="control-bar">
          <div></div>
            <div className="btn-group">
              <Button
                className="Button btn-secondary"
                onClick={() => handleOnCancel()}
                title="Cancel"
              />
              <Button
                className={
                  newBeneficiary.type
                    ? 'Button btn-primary'
                    : 'Button btn-primary inactive'
                }
                onClick={() => {
                  handleOnNext();
                }}
                title="Continue"
                isDisabled={!newBeneficiary.type}
              />
            </div>
        </div>
        :
        <Button 
            className={newBeneficiary.type ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"}
            onClick={() => handleOnNext()}
            title="Continue"
            isDisabled={!newBeneficiary.type}
        />
      }
    </div>
  );
};
