import './InputLabel.scss'
import { IInputLabelProps } from "./interfaces/IInputLabelProps";

/**Renders the label for an input component */
const InputLabel = (props: IInputLabelProps) => {
    const { control, showLabel = true } = props

    return (
        <div className="input-label-wrap">
            <div className="input-header">
                <label className="label-text mobileParagraphRegular" htmlFor={control.id}>{showLabel ? control.label : null}</label>
                {(control.isRequired && showLabel) && <span className="required-star">*</span>}
            </div>
            {props.children}
        </div>
    )
}

export default InputLabel