import { useHistory } from 'react-router-dom'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import './Confirmation.scss'
import greenCheckmark from '../../../../assets/greenCheckmark.svg'

/**renders the Confirmation page which is the final step in the link external account flow */
const Confirmation = () => {
    const { windowState } = useWindowState()
    const history = useHistory()

    return (
        <div className="confirmation-wrapper">
            <img className="image" src={greenCheckmark} alt="green circle white checkmark" />
            <span className="header-text h4">Verification started</span>
            <span className="sub-text regular">Within 5 business days, you'll receive two small deposits (less than $1 each) in your bank account. Once you receive the deposits, return here to enter the amounts and finish verifying your account.</span>
            <button className={`Button btn-primary ${windowState}`} onClick={() => history.push("/user/profile/external-accounts")}>Return to external accounts</button>
        </div>
    )
}

export default Confirmation