// import { useLoquateOnForm, AddressInputWrapper } from '../../../../../../shared/LoquateAutoComplete/LoquateAutoComplete'
import { useEditBeneficiaryPerson } from '../../../../../../useHooks/componentHooks/useEditBeneficiaryPerson/useEditBeneficiaryPerson'
import RXInput from '../../../../../RXInput/RXInput'
import { IMobileEditPersonProps } from './interface/IMoibleEditPersonProps'
import './MobileEditPerson.scss'
import { useBriteVerifyOnForm } from '../../../../../../shared/BriteVerify/BriteVerify'
import { Button, TIMER_IDS } from '../../../../../Button/Button'

const MobileEditPerson = (props: IMobileEditPersonProps) => {
    const { setEditPerson, displayOnly, setDisplayOnly, setEditProfile, setEditBeneficiary, selectedBeneficiary, setDeleteBeneficiary, form, dispatchForm } = props
    const { handleOnSubmit, formFilled } = useEditBeneficiaryPerson(setEditProfile, setEditBeneficiary, selectedBeneficiary, form)
    // const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)
    const { isEmailValid } = useBriteVerifyOnForm(form, dispatchForm)

    const handleValidation = async () => {

        try {
            // await isAddressValid();
            await isEmailValid();
            await handleOnSubmit();
            return Promise.resolve(true)
        }
        catch (err) {

        }

    }
    return (
        <div className="mobile-edit-person-wrapper">
            <div className="title-wrap">
                <p className="title-text mobileh2">Beneficiary Profile</p>
                <p className="sub-title-text regular">Make sure your beneficiary’s information is up to date.</p>
            </div>
            <div className="Personal-Information-Wrap">
                <span className="Personal-Information-Header mobileBoldRegular">Beneficiary Personal Information</span>
                <RXInput control={form.controls.firstName} className="full" />
                <RXInput control={form.controls.middleName} className="full" />
                <RXInput control={form.controls.lastName} className="full" />
                <RXInput control={form.controls.ssn} className="full" />
                <RXInput control={form.controls.address1} className="full" />
                <RXInput control={form.controls.address2} className="full" />
                <RXInput control={form.controls.city} className="full" />
                <RXInput control={form.controls.state} className="full" />
                <RXInput control={form.controls.zipCode} className="full" />
                {/* <AddressInputWrapper
                    onlyResidental
                    onSelection={onLoquateSelection}
                    control={form.controls.address1}
                    form={form}
                /> */}
            </div>
            <div className="Additional-Information-Wrap">
                <span className="Additional-Information-Header mobileBoldRegular">Additional Information</span>
                <RXInput control={form.controls.dateOfBirth} className="full" />
                <RXInput control={form.controls.relationship} className="full" />
                <RXInput control={form.controls.phoneNumber} className="full" />
                <RXInput control={form.controls.email} className="full" />
            </div>
            <div className="Button-Wrapper">
                {displayOnly ?
                    <button className="Left-Button boldButtonLarge" onClick={() => setDeleteBeneficiary(true)}>Remove</button>
                    :
                    <button className="Left-Button boldButtonLarge" onClick={() => { setEditPerson(false); setEditProfile(false); form.resetForm(); dispatchForm() }}>Cancel</button>
                }
                {displayOnly ?
                    <button className="Button btn-primary Right-Button" onClick={() => { setEditPerson(true); setDisplayOnly(false); form.enableAllInputs(); dispatchForm() }}>Edit</button>
                    :
                    <Button 
                        title="Save"
                        isDisabled={!formFilled}
                        className={formFilled ? "Button btn-primary Right-Button" : "Button btn-primary Right-Button inactive"}
                        id={TIMER_IDS.BENEFICIARY_EDIT}
                        onClick={handleValidation}
                    />
                }
            </div>
        </div>
    )
}

export default MobileEditPerson