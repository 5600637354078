import { useMobileProfileLanding } from '../../../../useHooks/componentHooks/useMobileProfileLanding/useMobileProfileLanding'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import './DesktopProfileHeader.scss'
import editPencil from '../../../../assets/editPencil.svg'
import editCheckmark from '../../../../assets/editCheckmark.svg'
import editCancel from '../../../../assets/editCancel.svg'
import { IDesktopProfileHeaderProps } from './interfaces/IDesktopProfileHeaderProps'
import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'

/**Renders the header and page tabs found on the desktop version of the user profile flow */
const DesktopHeader = (props: IDesktopProfileHeaderProps) => {
    const { currentTab, setCurrentTab, availableTabs } = props
    const { userInformation, editNickname, setEditNickname, onChangeNickname, newNickname, handleUpdateNickname, handleCancelOnClick, pushSelectedTab } = useMobileProfileLanding()
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)

    return (
        <>
        <div className="profile-header-wrap">
            <div className="profile-header">
                {gotUserContactInfo !== "Loading" ?
                    <>
                    {!editNickname ?
                        <div className="top-row">
                            <span className="profile-text h2">Hi, {userInformation.nickname}</span>
                            <img className="edit-icon" src={editPencil} alt="edit pencil" onClick={() => setEditNickname(true)} data-testid = "edit-nickname"/>
                        </div>
                        :
                        <div className="input-wrap">
                            <label className="input-label small">Greeting name
                                <div className="input-row-wrap">
                                    <input 
                                        className="input-box regular" 
                                        type="text" 
                                        onChange={(e: any) => onChangeNickname(e)}
                                        value={newNickname}
                                    />
                                    <img className="edit-icon" src={editCheckmark} alt="checkmark" onClick={() => handleUpdateNickname()}/>
                                    <img className="edit-icon" src={editCancel} alt="cancel x" onClick={() => handleCancelOnClick()}/>
                                </div>
                            </label>
                        </div>
                    }
                    {/* <span className="customer-since-text small">Customer since {convertDateTommddyyyy(userInformation.customerSince)}</span> */}
                    </>
                    :
                    <div className="profile-header-loading-wrapper">
                        <div className="top-box" />
                        {/* <div className="bottom-box" /> */}
                    </div>
                }
            </div>
        </div>
        <div className="tab-page-wrap">
            {gotUserContactInfo !== "Loading" ?
                <div className="tab-wrapper">
                    {availableTabs.current.map((tab: any, index: number) => {
                        return (
                            <div key={index} className="tab">
                                <span className={tab === currentTab ? "tab-text regular selected" : "tab-text regular"} onClick={() => {pushSelectedTab(tab); setCurrentTab(tab)}}>{tab}</span>
                                {tab === currentTab && <div className="selected-line" />}
                            </div>
                        )
                    })}
                </div>
                :
                <div className={currentTab !== "Security settings" ? "profile-header-loading-row-wrap" : "profile-header-loading-row-wrap no-margin"}>
                    <div className="loading-box" />
                    <div className="loading-box" />
                    <div className="loading-box" />
                    <div className="loading-box large" />
                    <div className="loading-box" />
                </div>
            }
        </div>
        </>
    )
}

export default DesktopHeader