import './MobileVerifyExternalAccount.scss'
import bankBlue from '../../../assets/bankBlue.svg'
import { useVerifyExternalAccount } from '../../../useHooks/pageHooks/useVerifyExternalAccount'
// import bankStatement from '../../../assets/bankStatement.svg'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import greenCheckmark from '../../../assets/greenCheckmark.svg'
import redExclamationCircle from '../../../assets/redExclamationCircle.svg'
import FailedToLink from '../components/FailedToLink/FailedToLink'
import imgBankStatementMobile from '../../../assets/imgBankStatementMobile.svg'
import iconBank from '../../../assets/iconBank.svg'
import { Button, TIMER_IDS } from '../../../components/Button/Button'

/**renders the mobile version of the Verify External Account page */
const MobileVerifyExternalAccount = () => {
    const { history, showModal, setShowModal, enableVerify, handleAccountKeyDown, handleValueOne, handleValueTwo, handleVerifyDeposits, type,
        message, showSuccess, showError, selectedExternalAccount, handleReturn, showLocked, valueOne, valueTwo } = useVerifyExternalAccount()

    return (
        <div className="mobile-verify-ext-account-wrapper">
            {message !== "" && <ToastMessageDB type={type} message={message} />}
            {!showSuccess ?
                <>
                    {!showLocked ?
                        <>
                            <span className="cancel-text regular" onClick={() => history.push("/user/profile/external-accounts")}>Cancel</span>
                            <div className="data-card">
                                <span className="title-text h3">Verify your account</span>
                                <span className="info-text regular">We've made two small deposits to your bank account. Enter the deposit amounts to verify your account.&nbsp;
                                    <span className="blue-text" onClick={() => setShowModal(true)}>What's this?</span>
                                </span>
                                {showError &&
                                    <div className="error-wrapper">
                                        <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                                        <div className="message-stack">
                                            <span className="error-title boldLarge">Unable to verify</span>
                                            <span className="error-text regular">The amounts you entered don't match the deposits we sent to your bank account. Please try again.</span>
                                        </div>
                                    </div>
                                }
                                <div className="bankname-wrap">
                                    <img src={bankBlue} alt="blue house" />
                                    <span className="bank-name semiboldRegular">{selectedExternalAccount.accountNickName}</span>
                                </div>
                                <div className="inputs-column">
                                    <label className="input-wrap">
                                        <div className="label-row">
                                            <span className={showError ? "label-text small error" : "label-text small"}>Deposit amount&nbsp;<span className="red-star">*</span></span>
                                        </div>
                                        <div className={showError ? "input-symbol-wrap regular error" : "input-symbol-wrap regular"}>{valueOne.length > 0 && "$"}
                                            <input
                                                className="input-box regular"
                                                type="number"
                                                min=".01"
                                                max=".99"
                                                onKeyDown={(e: any) => handleAccountKeyDown(e)}
                                                onChange={(e: any) => handleValueOne(e)}
                                                placeholder="$0.00"
                                            />
                                        </div>
                                    </label>
                                    <label className="input-wrap">
                                        <div className="label-row">
                                            <span className={showError ? "label-text small error" : "label-text small"}>Deposit amount&nbsp;<span className="red-star">*</span></span>
                                        </div>
                                        <div className={showError ? "input-symbol-wrap regular error" : "input-symbol-wrap regular"}>{valueTwo.length > 0 && "$"}
                                            <input
                                                className="input-box regular"
                                                type="number"
                                                min=".01"
                                                max=".99"
                                                onKeyDown={(e: any) => handleAccountKeyDown(e)}
                                                onChange={(e: any) => handleValueTwo(e)}
                                                placeholder="$0.00"
                                            />
                                        </div>
                                    </label>
                                </div>
                                <Button 
                                    title="Verify deposits"
                                    isDisabled={!enableVerify}
                                    className={enableVerify ? "Button btn-primary margin" : "Button btn-primary margin inactive"}
                                    id={TIMER_IDS.MICROTRANSACTION_VALIDATE}
                                    onClick={() => handleVerifyDeposits()}
                                />
                            </div>
                        </>
                        :
                        <FailedToLink />
                    }
                </>
                :
                <div className="validate-success-wrapper">
                    <img className="success-image" src={greenCheckmark} alt="green circle with white checkmark" />
                    <span className="success-title h4">Thanks for verifying your account!</span>
                    <span className="success-text regular">Your account&nbsp;
                        <span className="semiboldRegular">{selectedExternalAccount.accountNickName} {selectedExternalAccount.lastFour}</span>
                        &nbsp;has been linked and is ready to use.
                    </span>
                    <div className="success-button-wrapper">
                        <button className="Button btn-primary" onClick={() => { handleReturn(); history.push("/user/profile/external-accounts") }}>Return to external accounts</button>
                    </div>
                </div>
            }
            {showModal &&
                <div className="modal-overlay">
                    <div className="modal-card">
                        <div className="header-wrap">
                            <span className="header-text semiboldRegular">Where are my deposits?</span>
                            <span className="blue-text regular" onClick={() => setShowModal(false)}>Close</span>
                        </div>
                        <div className="modal-data">
                            <span className="modal-text regular">You can find two small deposits from Credit One in your account transactions or statements.</span>
                            <div className="image-wrap">
                                <img className={`bank-statement`} src={imgBankStatementMobile} alt="bank statement" />
                                <div className="icon-header-wrap">
                                    <img src={iconBank} alt="blue colored bank sketch" />
                                    <span className={`nickname semiboldRegular`}>{selectedExternalAccount?.accountNickName} {selectedExternalAccount?.lastFour}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default MobileVerifyExternalAccount