import './DesktopInterestSettingsLanding.scss'
import { IDesktopInterestSettingsLandingProps } from './interfaces/IDesktopInterestSettingsLandingProps'
import bluePencil from '../../../assets/bluePencil.svg';
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage';
import { useToastMessage } from '../../../Context/ToastContext/useToastContext';
import { useEffect } from 'react';

/**The component renders the desktop version of the interest settings landing page */
const DesktopInterestSettingsLanding = (props: IDesktopInterestSettingsLandingProps) => {
    const { selectedAccount, setChangeClicked } = props
    const { type, message, resetToast } = useToastMessage()

    /**reset the toast if the message does not equal an empty string */
    useEffect(() => {
        if(message !== ""){
            resetToast()
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return (
        <div className="card-wrapper interest-settings">
            <div className="container">
                <div className="desktop">
                    <div className="content-wrapper">
                        <div className="title-lockup">
                            <h1>Manage interest payments</h1>
                            <p>Change where your interest payments are deposited</p>
                        </div>
                        {/* <div className="grey-line"></div> */}
                        <div className="content">
                            <div className="account-row">
                                <div className="left">
                                    <span className="deposit-text boldRegular">Deposit interest payments in:</span>
                                    {selectedAccount.selectedInterestAccount.accountType && selectedAccount.selectedInterestAccount.accountType !== "External" ?
                                        <span className="account-details regular">{selectedAccount.selectedInterestAccount.accountTitle} - {selectedAccount.selectedInterestAccount.accountType} {selectedAccount.selectedInterestAccount.accountNumber?.slice(selectedAccount.selectedInterestAccount.accountNumber.length - 4, selectedAccount.selectedInterestAccount.accountNumber.length)}</span>
                                        :
                                        <span className="account-details regular">{selectedAccount.selectedInterestAccount.accountTitle}</span>
                                    }
                                </div>
                                <div className="right">
                                    <img className="edit-pencil" src={bluePencil} alt="change account" onClick={() => setChangeClicked(true)} />
                                    <span className="change-click regular" onClick={() => setChangeClicked(true)} >Change account</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {message !== "" && <ToastMessageDB message={message} type={type} />}
        </div>
    )
}

export default DesktopInterestSettingsLanding