import { IVerifiedAccount } from '../../../../../api/getYodleeVerifiedAccounts';
import { ICreateYodleeAccount } from '../../../../../api/postCreateYodleeAccount';

/**
 * Interface for `usePostYodleeFlow` hook.
 */
export interface IUsePostYodleeFlow {
  /**
   * The verified accounts from yodlee.
   * 
   * `/yodlee/verifiedAccounts`
   */
  verifiedAccounts: Array<IVerifiedAccount>;
  /**
   * The `providerAccountId` from Yodlee.
   */
  providerAccountId: string;
  /**
   * The status of the response from `/yodlee/verifiedAccounts
   */
  responseStatus: YODLEE_STATUS;
  _setResponseStatus: React.Dispatch<React.SetStateAction<YODLEE_STATUS>>

  /**
   * Gets the `verifiedAccounts` from Yodlee.
   * 
   */
  getVerifiedAccounts: () => void;

  /**
   * Sets the `providerAccountId`.
   */
  // setProviderAccountId: React.Dispatch<React.SetStateAction<string>>;

  accountsLinked: Array<ICreateYodleeAccount>;
  setAccountsLinked: React.Dispatch<React.SetStateAction<Array<ICreateYodleeAccount>>>

  postNewAccounts: (accounts: Array<ICreateYodleeAccount>) => void;

  /**Calls function to clean the response data */
  convertIVerifiedAccountToICreateYodleeAccount: (accounts: Array<IVerifiedAccount>) => Array<ICreateYodleeAccount>

  /**Whether or not hasVerifiedAccountsBeenCalledRef has been called, need to set this back to false when handling the /verifyAccounts rollback scenario */
  hasVerifiedAccountsBeenCalledRef: React.MutableRefObject<boolean>
}

export enum YODLEE_STATUS {
  /** 201 `/yodlee/verifiedAccounts`. All selected accounts created. No action needed from UI.  */
  SUCCESS = 201,
  /** 200 `/yodlee/verifiedAccounts` Too many accounts selected. Selection needed. */
  SELECTION_NEEDED = 200,
  /** 200 `/yodlee/verifiedAccounts` All accounts came back with `isAlreadyExist === true`. */
  ALL_ACCOUNTS_LINKED = 'ALL_ACCOUNTS_LINKED',
  /** `usePostYodleeFlow.getVerifiedAccounts` `/yodlee/verifiedAccounts` hasn't been called yet. */
  NOT_CALLED = 0,
  /** `userPostYodleeFlow.setNewAccounts` failed. Probably need different statuses based on this */
  LINKED_FAILED = 'LINKED_CALL_FAILED',
  /** `usePostYodleeFlow.setNewAccounts` `/createExternalAccount` has been called and returned*/
  LINKED_SUCCESS = 'LINKED_SUCCESS',
  /** All accounts selected from yodlee could be created but some failed. */
  CREATION_FAILED = 'CREATION_FAILED',
  /** If `/yodlee/verifiedAccounts` errored. Need to update when we have different errors */
  ERROR = 0
}