import React, { useEffect, useState } from "react"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { getUserCurrentDate } from '../../../utils/DateUtils'

/**A custom use hook holding the logic for the Transfer Confirmation component */
export const useTransferConfirmation = (
        transferDate: string, 
        transferAmount: number,
        setSelectedFromAccount: React.Dispatch<React.SetStateAction<{title: string, type: "Savings" | "", lastFour: string, accountId: string, balance: number}>>,
        setSelectedToAccount: React.Dispatch<React.SetStateAction<{title: string, type: "Savings" | "", lastFour: string, accountId: string, balance: number}>>,
        setTransferAmount: React.Dispatch<React.SetStateAction<number | undefined>>,
        setTransferDate: React.Dispatch<React.SetStateAction<string>>,
        setTopCardIndex: React.Dispatch<React.SetStateAction<number>>
        ) => {
    const [formatedTransferDate, setFormatedTransferDate] = useState<string>("")
    const [amount, setAmount] = useState<string>("")
    const [todaysDate] = useState<string>(getUserCurrentDate())
    useScrollToTop()

    /**Reformat the transfer date mm/dd/yyyy*/
    useEffect(() => {
        if(transferDate !== ""){
            const [ year, month, day ] = transferDate.split("-")
            const formatedDate = `${month}/${day}/${year}`
            setFormatedTransferDate(formatedDate)
        }
    }, [transferDate])

    /**Reassign the transferAmount to amount variable as string.  Without the swap the Javascript display as currency function will not work*/
    useEffect(() => {
        if(transferAmount && transferAmount > 0){
            setAmount(String(transferAmount))
        }
    }, [transferAmount])

    /**Handles the onClick event for the 'Make Another Transfer' button */
    const handleOnClick = () => {
        setSelectedFromAccount({title: "", type: "", lastFour: "", accountId: "", balance: 0})
        setSelectedToAccount({title: "", type: "", lastFour: "", accountId: "", balance: 0})
        setTransferAmount(undefined)
        setTransferDate("")
        setTopCardIndex(0)
    }

    return { formatedTransferDate, amount, handleOnClick, todaysDate }
}