import './TimeoutModal.scss'
import redExclamationCircle from '../../assets/redExclamationCircle.svg'
import padlock from '../../assets/padlock.svg'
import { useTimeoutModal } from '../../useHooks/componentHooks/useTimeoutModal/useTimeoutModal'

/**Renders the auto timeout modal when a user has been idle for 14 consecutive minutes, and logs out the user at the 15 minute mark */
const TimeoutModal = () => {
    const { windowState, step, countdownTimer, showTimeoutModal, handleTimerReset, setResetTimer, oktaLogout } = useTimeoutModal()
    
    if(!showTimeoutModal){
        return null
    }

    return (
        <div className="timeout-modal-overlay">
            <div className={windowState !== "Mobile" ? "timeout-modal-details-wrap" : "timeout-modal-details-wrap mobile"}>
                <img className="image" src={redExclamationCircle} alt="red circe with exclamation mark" />
                {step === 1 &&
                <>
                    <span className="header-text h4">Your session is about to expire</span>
                    <span className="text regular">To protect your account, you will be signed out in:</span>
                    <span className="timer-red-text semiboldxLarge">00:{countdownTimer > 9 ? countdownTimer : `0${countdownTimer}`} seconds</span>
                    <div className={windowState !== "Mobile" ? "button-wrap" : "button-wrap mobile"}>
                        <button className={windowState !== "Mobile" ? "Button btn-primary" : "Button btn-primary mobile"} onClick={() => {setResetTimer(true); handleTimerReset()}}>I'm still here</button>
                        <button className="blue-text-button regular" onClick={() => oktaLogout()}>Sign me out</button>
                    </div>
                </>
                }
                {/* {step === 2 &&
                <>
                    <span className="header-text h4">Your session has expired</span>
                    <span className="text regular">To protect your account, please sign-in again to access your account.</span>
                    <div className="button-wrap locked">
                        <img src={padlock} alt="white padlock" />
                        <span className="boldLarge" onClick={() => oktaLogout()}>Return to Sign In</span>
                    </div>
                </>
                } */}
            </div>
        </div>
    )
}

export default TimeoutModal