import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ICustomFilter, IDateFilter, ITransactionHistoryContext, IUseAmountFilter, IUseDateFilter, IUseTransactionFilter, TDateSelection, TSortType, TTransactionType } from './TransactionHistory.types';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { ITransactionHistoryData } from '../../../api/getTransactionHistory';
import { IAccountInformation } from '../../../api/getAccountInformation';
import { updateTransactionsLoadedByIndex, setAccountTransactionsByIndex, setAccountInformationByIndex } from '../../../slices/accountInformation/accountInformationSlice';
import { updateIsTransactionsLoaded, setSelectedAccountTransactions, getAccountDetailsAsync } from '../../../slices/selectedAccountSlice/selectedAccountSlice';
import { clearTransactionsArray, getTransactionsAsync, setTransactionsWithAccountData } from '../../../slices/transactions/transactionsSlice';
import { convertDateWithTime } from '../../../utils/DateUtils';
import { displayCurrency } from '../../../utils/displayCurrency';
import { filterByAmount } from '../../../utils/filterByAmount';
import { filterCustomDates } from '../../../utils/filterCustomDates';
import { handleStandardFiltering } from '../../../utils/handleStandardFiltering';
import { populateAccountDetails } from '../../../utils/populateAccountDetails';
import { sortTransactions } from '../../../utils/sortTransactions';
import { useHistory } from 'react-router';
import { useWindowState } from '../../../Context/AccountContext/useWindowState';
import { useRefState } from '../../../shared/useHooks/useStateRef';

const TransactionHistoryContext = createContext<ITransactionHistoryContext | null>(null);

export const dateSelections: { current: Array<TDateSelection> } = { current: ["All", "Last month", "Last 90 days", "Last 6 months", "Last 12 months", "YTD", "Since last statement", "Custom date range"] };

export const transactionTypes = { current: ["All", "Debit", "Credit"] };

export const monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const TransactionHistoryProvider = (props: { children: JSX.Element }) => {
  const dateFilter = useDateFilter();
  const amountFilter = useAmountFilter();
  const typeFilter = useTransactionTypeFilter();

  const [isAllFiltersSelected, _setIsAllFiltersSelected] = useState(false);
  const [mobileFilterOpen, _setMobileFilterOpen] = useState<boolean>(false);

  const setIsAllFiltersSelected = (value: boolean) => {
    _setIsAllFiltersSelected(value);
    _setMobileFilterOpen(value);
  }

  const setMobileFilterOpen = (value: boolean) => {
    setIsAllFiltersSelected(value);
    _setMobileFilterOpen(value);
  }

  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)

  const transactionsLoaded = useAppSelector((state: RootState) => state.transactions.transactionsLoaded)
  const transactions = useAppSelector((state: RootState) => state.transactions.transactions)
  const [filteredTransactions, _setFilteredTransactions] = useState<Array<ITransactionHistoryData>>([])

  const setFilteredTransactions = (transactions: any) => {
    if (!transactions?.[0]?.id) {
      _setFilteredTransactions([]);
      return;
    }
    _setFilteredTransactions(transactions)
  }
  const filteredTransactionsRef = useRef<Array<ITransactionHistoryData>>([])
  const selectedTransaction = useAppSelector((state: RootState) => state.selectedTransaction.selectedTransaction)

  const [displayDetails, setDisplayDetails] = useState(false);
  const [startIndex, setStartIndex] = useState<number>(0)
  const [endIndex, setEndIndex] = useState<number>(10)

  const [dateSort, setDateSort] = useState<TSortType>("Up")
  const [amountSort, setAmountSort] = useState<TSortType>("Default")
  const [firstSortComplete, setFirstSortComplete] = useState<boolean>(false)

  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(10);
  const [showCustomFilter, setShowCustomFilter] = useState<boolean>(false)
  const customFilterArray = useRef<Array<ICustomFilter>>([])
  const [selectedFromDropDown, setSelectedFromDropDown] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const history = useHistory();

  const initialCallRef = useRef<boolean>(true)


  /**
   * Call the getTransactions function in the Account Context use hook, which either calls the API or returns the 
   * already populated array data.  Sets the state for the selected array of transactions
   */
  const getAccountTransactionArray = async () => {
    dispatch(clearTransactionsArray())
    const index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
    const payload = { arrayIndex: index, status: selectedAccount.isTransactionsLoaded }
    const response = await dispatch(getTransactionsAsync({ customerId: '', accountId: selectedAccount.id }))
    try {
      //updates account information index to transactions loaded and sets the transactions
      if (selectedAccount.accountType !== "Savings") {
        dispatch(updateTransactionsLoadedByIndex(payload))
      }
      //updates selected account to transactions loaded
      dispatch(updateIsTransactionsLoaded(true))
    }
    catch {
      dispatch(updateTransactionsLoadedByIndex(payload))
      dispatch(updateIsTransactionsLoaded(false))
      console.error()
    }
  }

  /**Checks to see if transactions api needs to be called again due to a page refresh */
  useEffect(() => {
    if (selectedAccount.id !== "") {
      getAccountTransactionArray()
    }
    initialCallRef.current = false
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount.id])

  /** When the selected accounts id changes, reset the filters to their defaults. This is only needed when going from savings -> savings account  */
  useEffect(() => {
    if (selectedAccount.accountType === "Savings") {
      /** Resets the custom filter fields */
      handleClearAll();
      /** Resets the Transaction Type drop-down filter */
      typeFilter.transactionType.reset();

      /** Resets the Selected Date drop-down filter */
      dateFilter.dateRange.reset();
      dateFilter.toDate.reset();
      dateFilter.fromDate.reset();
      dateFilter.customDate.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount.id]);

  /**Populate the selected accounts transactions with transaction history */
  useEffect(() => {
    if (selectedAccount.isTransactionsLoaded) {
      dispatch(setSelectedAccountTransactions(transactions))
      setFilteredTransactions(transactions)
      const index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
      const payload = { arrayIndex: index, transactions: transactions }
      dispatch(setAccountTransactionsByIndex(payload))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount.isTransactionsLoaded])

  /**If filtered transaction lengh is less than 10 set the start index back to 0 */
  useEffect(() => {
    if (filteredTransactions && filteredTransactions.length <= numberOfItemsToShow) {
      setStartIndex(0)
      setEndIndex(numberOfItemsToShow)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTransactions])


  /**
   * Re-sort after the filter has been applied, we are sorting with the filteredTransactionsRef this time due to
   * scheduling issues that the filtered transactions state has not yet been updated, but a ref updates instantly.
   */
  const sortFilteredTransactions = () => {
    let tempTransactionArray = filteredTransactionsRef.current
    if (dateSort !== "Default") {
      if (dateSort === "Up") {
        tempTransactionArray = sortTransactions("Date", "Up", tempTransactionArray, setDateSort, setAmountSort)
      }
      else {
        tempTransactionArray = sortTransactions("Date", "Down", tempTransactionArray, setDateSort, setAmountSort)
      }
    }
    else if (amountSort !== "Default") {
      if (amountSort === "Up") {
        tempTransactionArray = sortTransactions("Amount", "Up", tempTransactionArray, setDateSort, setAmountSort)
      }
      else {
        tempTransactionArray = sortTransactions("Amount", "Down", tempTransactionArray, setDateSort, setAmountSort)
      }
    }
    setFilteredTransactions(tempTransactionArray)
  }

  /**downloads the csv file */
  const downloadCSV = (csv: any, filename: string) => {
    let csvFile
    let downloadLink

    csvFile = new Blob([csv], { type: "text/csv" })
    downloadLink = document.createElement("a")
    downloadLink.download = filename
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = "none"
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }

  /**Handles the export to csv file button press */
  const handleExportToCSV = () => {
    const fileName = `account__${selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length - 4, selectedAccount.accountNumber.length)}__transactions`
    let csv = []
    let rows = document.querySelectorAll("table tr")

    /**Setting the headers */
    let row = []
    row.push("Date")
    row.push("Description")
    row.push("Amount")
    csv.push(row.join(","))

    /**Filling the rows */
    for (let i = 0; i < filteredTransactions.length; i++) {
      let row = []
      if(filteredTransactions[i].effectiveDate.includes("T")){
        row.push(convertDateWithTime(filteredTransactions[i].effectiveDate))
      }
      else{
        let [year, month, day] = filteredTransactions[i].effectiveDate.split("-")
        if(month.length < 2)
          month = "0" + month
        if(day.length < 2)
          day = "0" + day
        row.push(month + "/" + day + "/" + year)
      }
      row.push(filteredTransactions[i].description)
      row.push(filteredTransactions[i].amount)
      csv.push(row.join(","))
    }

    downloadCSV(csv.join("\n"), fileName)
  }

  /**Disable scroll when the mobile filter is open */
  useEffect(() => {
    if (mobileFilterOpen) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "auto";
    }

  }, [mobileFilterOpen])

  /**Handles the sorting feature on the transaction table */
  const handleTransactionColumnSort = (column: string, direction: string) => {
    let tempTransactionArray = filteredTransactions
    switch (column) {
      case "Date": {
        if (direction === "Up") {
          tempTransactionArray = sortTransactions(column, direction, filteredTransactions, setDateSort, setAmountSort)
        }
        else if (direction === "Down") {
          tempTransactionArray = sortTransactions(column, direction, filteredTransactions, setDateSort, setAmountSort)
        }
        setFilteredTransactions(tempTransactionArray)
        break
      }
      case "Amount": {
        if (direction === "Up") {
          tempTransactionArray = sortTransactions(column, direction, filteredTransactions, setDateSort, setAmountSort)
        }
        else if (direction === "Down") {
          tempTransactionArray = sortTransactions(column, direction, filteredTransactions, setDateSort, setAmountSort)
        }
        setFilteredTransactions(tempTransactionArray)
        break
      }
    }
    setFirstSortComplete(true)
  }

  /**onclick handler for the clear all button */
  const handleClearAll = () => {
    let tempArray: Array<ICustomFilter> = []
    customFilterArray.current = tempArray
    setFilteredTransactions(transactions)
    setShowCustomFilter(false)
    setIsAllFiltersSelected(false);
    filteredTransactionsRef.current = transactions
    sortFilteredTransactions()
    dateFilter.dateRange.reset();
    dateFilter.toDate.reset();
    dateFilter.fromDate.reset();
    dateFilter.customDate.reset();
    amountFilter.minAmount.reset();
    amountFilter.maxAmount.reset();
  }

  /**
   * Returns the filtered transactions by date range
   * @returns 
   */
  const getFilteredTransactionByToFromDate = () => {
    const { year: FROM_YEAR, month: FROM_MONTH, day: FROM_DAY } = dateFilter.fromDate.ref.get();
    const { year: TO_YEAR, month: TO_MONTH, day: TO_DAY } = dateFilter.toDate.ref.get();
    const fromDate = new Date(FROM_YEAR, FROM_MONTH - 1, FROM_DAY, 0, 0, 0, 0).getTime()
    const toDate = new Date(TO_YEAR, TO_MONTH - 1, TO_DAY, 23, 59, 59).getTime()
    return filterCustomDates(fromDate, toDate, transactions)
  }

  /**Handles the removal of a filter, it's only coming here for desktop */
  const handleRemoveFilter = (filter: string, filterType: string, index: number) => {
    customFilterArray.current.splice(index, 1)
    switch (filterType) {
      case "Date Selection":
      case "Custom Date": {
        dateFilter.dateRange.reset();
        dateFilter.customDate.reset();
        dateFilter.fromDate.reset();
        dateFilter.toDate.reset();
        handleApply();

        break;
      }
      case "Transaction Type": {
        typeFilter.transactionType.reset();
        handleApply();

        break
      }
      case "Amount": {
        amountFilter.maxAmount.reset();
        amountFilter.minAmount.reset();
        handleApply();

        break
      }
    }
    // sortFilteredTransactions()
  }

  /**
   * Returns the `transactions` filtered by the selected date range.
   */
  const getFilteredTransactionsByDate = () => {
    let filteredTransactions: Array<ITransactionHistoryData> = [];
    if (dateFilter.dateRange.ref.get() !== 'Custom date range') {
      /**Error handling for an invalid input, we are resetting it to the default state */
      if (dateFilter.fromDate.ref.get().year !== 0 && dateFilter.toDate.ref.get().year === 0) {
        dateFilter.fromDate.ref.set(createDateFilter());
      }
      else if (dateFilter.fromDate.ref.get().year === 0 && dateFilter.toDate.ref.get().year !== 0) {
        dateFilter.toDate.ref.set(createDateFilter());
      }

      /**This section runs when the standard date selector drop down is used */
      /**filters the date selection and transaction type */
      filteredTransactions = handleStandardFiltering(dateFilter.dateRange.ref.get(), typeFilter.transactionType.ref.get(), transactions)
    }
    else {
      if (dateFilter.fromDate.ref.get().year && dateFilter.toDate.ref.get().year) {

        const { year: fYear, day: fDay, month: fMonth } = dateFilter.fromDate.ref.get();
        const { year: tYear, day: tDay, month: tMonth } = dateFilter.toDate.ref.get();

        /**filter first based on custom date selection */
        filteredTransactions = getFilteredTransactionByToFromDate();
        customFilterArray.current.push({ filter: `${monthsArray[fMonth - 1]} ${fDay}, ${fYear} - ${monthsArray[tMonth - 1]} ${tDay}, ${tYear}`, filterType: "Custom Date" })
      }
    }

    dateFilter.fromDate.setRefValueToState();
    dateFilter.toDate.setRefValueToState();
    dateFilter.dateRange.setRefValueToState();
    dateFilter.customDate.setRefValueToState();

    return filteredTransactions;


  }

  /**
   * Returns the `filteredArray` filtering based off of the transaction type.
   * @param filteredArray - The array to be filtered.
   * @returns 
   */
  const getFilteredTransactionsByTransactionType = (filteredTransactions: Array<ITransactionHistoryData>) => {
    const VALUE = typeFilter.transactionType.ref.get();
    if (VALUE !== "All") {
      filteredTransactions = filteredTransactions.filter((transaction: ITransactionHistoryData) => transaction.type === VALUE)
      // customFilterArray.push({filter:selectedTransactionType, filterType: "Transaction Type"})
    }

    typeFilter.transactionType.setRefValueToState();

    return filteredTransactions;
  }

  const getFilteredTransactionsByAmount = (filteredTransactions: Array<ITransactionHistoryData>) => {
    if (!!(amountFilter.minAmount.ref.get() !== null && amountFilter.maxAmount.ref.get() !== null)) {
      amountFilter.maxAmount.setRefValueToState()
      amountFilter.minAmount.setRefValueToState();
      customFilterArray.current.push({ filter: `${displayCurrency(amountFilter.minAmount.ref.get() as number)}-${displayCurrency(amountFilter.maxAmount.ref.get() as number)}`, filterType: "Amount" })
      filteredTransactions = filterByAmount(amountFilter.minAmount.ref.get() as number, amountFilter.maxAmount.ref.get() as number, filteredTransactions)
    }

    return filteredTransactions;

  }

  /**
   * Handles applying the filters when the "Apply" button has been selected, since this function is reused after filter removals the buildArray boolean is needed.
   * If hanldeApply receives true for buildArray then an element will be inserted into the array for each filter, otherwise it will simply filter based on the 
   * conditional states of the filter.
   */
  const handleApply = () => {
    if ((amountFilter.maxAmount.ref.get() || 0) < (amountFilter.minAmount.ref.get() || 0)) {
      return;
    }

    let tempArray: Array<{ filter: string, filterType: string }> = []
    customFilterArray.current = tempArray

    //* Filters by date
    let filteredTransactions = getFilteredTransactionsByDate();
    // let filteredTransactions = JSON.parse(JSON.stringify(transactions));
    //* Filter by type
    filteredTransactions = getFilteredTransactionsByTransactionType(filteredTransactions);
    //* Filter by amount
    filteredTransactions = getFilteredTransactionsByAmount(filteredTransactions);

    setFilteredTransactions(filteredTransactions);
    filteredTransactionsRef.current = filteredTransactions

    if (customFilterArray.current.length) {
      setShowCustomFilter(true)
    }
    // udpateTempAmounts()
    sortFilteredTransactions()
    setIsAllFiltersSelected(false)
    setSelectedFromDropDown(false)
    document.body.style.overflow = "auto";
  }

  /**Handles the onClick for the filter box */
  const handleFilterBoxClick = (date: string) => {
    if (date === "Custom date range") {
      setSelectedFromDropDown(true)
      setIsAllFiltersSelected(true)
    }
  }

  /**Repopulate the selectedAccount on a page refresh */
  useEffect(() => {
    if (selectedAccount.id === "" && accountInformation[0].id !== "") {
      const adjustedUrl = window.location.pathname.split('/')[2]
      const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
      if (index >= 0) {
        const account = accountInformation[index]
        repopulateAccountDetails(index, account)
      }
      else {
        history.push("/")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, accountInformation])

  /**Repopulates the account details on a page refresh */
  const repopulateAccountDetails = async (index: number, account: any) => {
    const payload = { arrayIndex: index, account: account }
    const response: any = await dispatch(getAccountDetailsAsync(payload))
    try {
      let tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
      const payload = { arrayIndex: index, account: tempAccount }
      dispatch(setAccountInformationByIndex(payload))
    }
    catch {
      console.error()
    }
  }


  return <TransactionHistoryContext.Provider value={{
    dateFilter,
    amountFilter,
    typeFilter,
    selectedAccount,
    accountInformation,
    transactionsLoaded,
    transactions,
    filteredTransactions,
    setFilteredTransactions,
    filteredTransactionsRef,
    displayDetails,
    setDisplayDetails,
    startIndex,
    setStartIndex,
    endIndex,
    setEndIndex,
    mobileFilterOpen,
    setMobileFilterOpen,
    dateSort,
    setDateSort,
    amountSort,
    setAmountSort,
    firstSortComplete,
    setFirstSortComplete,
    numberOfItemsToShow,
    setNumberOfItemsToShow,
    showCustomFilter,
    setShowCustomFilter,
    customFilterArray,
    selectedFromDropDown,
    setSelectedFromDropDown,
    isAllFiltersSelected,
    setIsAllFiltersSelected,
    selectedTransaction,
    handleExportToCSV,
    handleTransactionColumnSort,
    handleRemoveFilter,
    handleApply,
    handleFilterBoxClick,

  }}>
    {props.children}
  </TransactionHistoryContext.Provider>
}

export const useTransactionHistoryContext = () => {
  const context = useContext(TransactionHistoryContext);

  if (!context) {
    throw new Error(`Can't use useTransactionHistoryContext outside of TransactionHistoryProvider`)
  }

  return context;
}

export const createDateFilter = (month = 0, day = 0, year = 0) => {
  return {
    month,
    day,
    year
  }
}


const useDateFilter = (): IUseDateFilter => {
  const toDate = useRefState<IDateFilter>(createDateFilter());
  const fromDate = useRefState<IDateFilter>(createDateFilter());
  const dateRange = useRefState<TDateSelection>('All');
  const customDate = useRefState<string>('');

  return {
    toDate,
    fromDate,
    dateRange,
    customDate
  }
}

const useAmountFilter = (): IUseAmountFilter => {
  const maxAmount = useRefState<number | null>(null);
  const minAmount = useRefState<number | null>(null);

  return {
    maxAmount,
    minAmount
  }
}

const useTransactionTypeFilter = () => {
  const transactionType = useRefState<TTransactionType>('All');

  return {
    transactionType
  }
}

