// import { useLoquateOnForm, AddressInputWrapper } from '../../../../../../shared/LoquateAutoComplete/LoquateAutoComplete'
import { useEditBeneficiaryTrust } from '../../../../../../useHooks/componentHooks/useEditBeneficiaryTrust/useEditBeneficiaryTrust'
import { Button, TIMER_IDS } from '../../../../../Button/Button'
import RXInput from '../../../../../RXInput/RXInput'
import { IMobileEditTrustProps } from './interface/IMobileEditTrustProps'
import './MobileEditTrust.scss'

/**
 * Render the Edit Beneficiary Trust component for Mobile version.  That is the name, address, email, etc..
 */
const MobileEditTrust = (props: IMobileEditTrustProps) => {
    const { setEditPerson, displayOnly, setDisplayOnly, setEditProfile, setEditBeneficiary, selectedBeneficiary, setDeleteBeneficiary, form, dispatchForm } = props
    const { handleOnSubmit, formFilled } = useEditBeneficiaryTrust(setEditProfile, setEditBeneficiary, selectedBeneficiary, form)
    // const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)

    const handleValidation = async () => {

        try {
            // await isAddressValid();
            await handleOnSubmit();
        }
        catch (err) {

        }

    }

    return (
        <div className="Mobile-Edit-Trust-Wrapper">
            <div className="title-wrap">
                <p className="title-text mobileh2">Beneficiary Profile</p>
                <p className="sub-title-text regular">Make sure your beneficiary’s information is up to date.</p>
            </div>
            <div className="Trust-Information-Wrap">
                <span className="Trust-Information-Header mobileBoldRegular">Trust Information</span>
                <RXInput control={form.controls.trustName} className="full" />
                <RXInput control={form.controls.trustEstablishedDate} className="full" />
                <RXInput control={form.controls.tin} className="full" />
                <RXInput control={form.controls.address1} className="full" />
                <RXInput control={form.controls.address2} className="full" />
                <RXInput control={form.controls.city} className="full" />
                <RXInput control={form.controls.state} className="full" />
                <RXInput control={form.controls.zipCode} className="full" />
                {/* <AddressInputWrapper
                    onSelection={onLoquateSelection}
                    control={form.controls.address1}
                    form={form}
                /> */}
            </div>
            <div className="Additional-Information-Wrap">
                <span className="Additional-Information-Header mobileBoldRegular">Additional Information</span>
                <RXInput control={form.controls.phoneNumber} className="full" />
            </div>
            <div className="Button-Wrapper">
                {displayOnly ?
                    <button className="Left-Button boldButtonLarge" onClick={() => setDeleteBeneficiary(true)}>Remove</button>
                    :
                    <button className="Left-Button boldButtonLarge" onClick={() => { setEditPerson(false); setEditProfile(false); form.resetForm(); dispatchForm() }}>Cancel</button>
                }
                {displayOnly ?
                    <button className="Button btn-primary Right-Button" onClick={() => { setEditPerson(true); setDisplayOnly(false); form.enableAllInputs(); dispatchForm() }}>Edit</button>
                    :
                    <Button 
                        title="Save"
                        isDisabled={!formFilled}
                        className={formFilled ? "Button btn-primary Right-Button" : "Button btn-primary Right-Button inactive"}
                        id={TIMER_IDS.BENEFICIARY_EDIT}
                        onClick={handleValidation}
                    />
                }
            </div>
        </div>
    )
}

export default MobileEditTrust