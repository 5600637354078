import { IPlanChangeProps } from './interfaces/IPlanChangeProps'
import './PlanChangeOptions.scss'
import renew from '../../../../assets/renew.svg'
import closeCD from '../../../../assets/closeCD.svg'
import arrowRight from '../../../../assets/arrowRight.svg'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'

/**Render the plan change options when the action at maturity change is selected at maturity settings */
const PlanChangeOptions = (props: IPlanChangeProps) => {
    const { windowState } = useWindowState()
    const { setChangePlanOption, setOpenChangeOption, setPlanChange } = props

    return (
        <>
        {windowState === "Mobile" &&
            <div className="mobile-plan-change-header-wrap">
                <button className="mobile-cancel-button regular" onClick={() => {setPlanChange(false); setOpenChangeOption(false)}}>Cancel</button>
            </div>
        }
        <div className={windowState !== "Mobile" ? "page-wrapper desktop" : "page-wrapper"}>
            <div className={windowState !== "Mobile" ? "plan-change-card-wrapper" : "plan-change-card-wrapper mobile"}>
                <div className={windowState !== "Mobile" ? "header-wrap" : "header-wrap mobile"}>
                    <span className={windowState !== "Mobile" ? "header-text small" : "header-text small"}>Change maturity plan</span>
                    <span className={windowState !== "Mobile" ? "header-question h3" : "header-question h4"}>What would you like to do when your CD matures? </span>
                </div>
                <div className={windowState !== "Mobile" ? "options-wrapper" : "options-wrapper mobile"}>
                    <div className="option-card-wrap" onClick={() => {setChangePlanOption("Renew"); setOpenChangeOption(false)}}>
                        <div className={windowState !== "Mobile" ? "left-side" : "left-side mobile"}>
                            <img src={renew} alt="renew" />
                            <div className={windowState !== "Mobile" ? "text-stack" : "text-stack mobile"}>
                                <span className={windowState !== "Mobile" ? "option-header boldRegular" : "option-header boldSmall"}>Renew CD</span>
                                <span className={windowState !== "Mobile" ? "option-text regular" : "option-text small"}>Renew your CD at maturity</span>
                            </div>
                        </div>
                        <img className="image-arrow" src={arrowRight} alt="select" />
                    </div>
                    <div className="option-card-wrap" onClick={() => {setChangePlanOption("Close"); setOpenChangeOption(false)}}>
                        <div className={windowState !== "Mobile" ? "left-side" : "left-side mobile"}>
                            <img src={closeCD} alt="close" />
                            <div className={windowState !== "Mobile" ? "text-stack" : "text-stack mobile"}>
                                <span className={windowState !== "Mobile" ? "option-header boldRegular" : "option-header boldSmall"}>Close CD</span>
                                <span className={windowState !== "Mobile" ? "option-text regular" : "option-text small"}>Close your account at maturity and withdraw the funds</span>
                            </div>
                        </div>
                        <img className="image-arrow" src={arrowRight} alt="select" />
                    </div>
                </div>
                {windowState !== "Mobile" &&
                <>
                    <div className="button-wrap">
                        <button className="Button btn-secondary" onClick={() => {setPlanChange(false); setOpenChangeOption(false)}}>Cancel</button>
                    </div>
                </>
                }
            </div>
        </div>
        </>
    )
}

export default PlanChangeOptions