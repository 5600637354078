import axios from "axios";
import { IFormContext } from "../../Form/useForm/useForm";
import { IControl } from '../../Form/Form/Control/IControl.interface';
import { ENV, getEnvironment } from '../../utils/getEnvironment';
import { CONFIG } from '../../utils/globalVariables';

/**
 * Sets the Base URL based on environment
 */
export const BRITE_VERIFY_BASE_URL = (() => {

    if (getEnvironment() === ENV.LOCAL) {

        return 'https://deposit-dev.creditone.com/api/v1/fullverify'

    }

    return `${window.location.origin}/api/v1/fullverify`
})();


/**
 * POST BriteVerify API Call
 * @param email to be verified
 * @returns response
 */
const postBriteVerifyAPI = (email: string) => {
    if (getEnvironment() === 'LOCAL' || (CONFIG.OUTSIDE_EMAIL_VALIDATION === false || CONFIG.OUTSIDE_EMAIL_VALIDATION === 'false')) {
        return Promise.reject(false)
    }

    return axios.post(`${BRITE_VERIFY_BASE_URL}`, {
        email
    }, {
        headers: {
            "Authorization": `ApiKey: ${CONFIG.BRITE_VERIFY_API_KEY}`
        },
        timeout: CONFIG.EMAIL_VERIFY_TIMEOUT
    })
}

interface IBriteVerifyEmailResponse {
    account: string;
    address: string;
    connected: string | null;
    disposable: boolean;
    domain: string;
    role_address: false;
    status: 'valid' | 'invalid' | 'accept_all' | 'unknown';
}

/**
 * Returns if the email address response from brite verify is valid
 * @param email - The response for email from brite verify
 * @returns - If the email is valid
 */
const getEmailValidity = (email: IBriteVerifyEmailResponse) => {

    if (email.disposable) {
        return false;
    }

    if (email.status === 'valid' || email.status === 'accept_all') {
        return true;
    }

    return false
}


export const useBriteVerifyOnForm = (form: IFormContext, dispatchForm: any) => {

    /**
     * Invalidates the email control and the ref control for email
     * @param emailControl - The email control
     */
    const invalidateEmailControl = (emailControl: IControl) => {

        const refControl = form.refControls.email || {};

        emailControl.errors.push({ name: 'INVALID_EMAIL' })
        emailControl.isValid = false;
        emailControl.isTouched = true;

        refControl.errors.push({ name: 'INVALID_EMAIL' })
        refControl.isValid = false;
        refControl.isTouched = true;

        dispatchForm();
        dispatchForm();
    }

    /**
     * Validates the email control and ref control for email
     * @param emailControl - The email control
     */
    const validateEmailControl = (emailControl: IControl) => {
        const refControl = form.refControls.email || {};

        emailControl.isValid = true;
        emailControl.errors = [];

        refControl.isValid = true;
        refControl.errors = [];

        dispatchForm();
        dispatchForm();
    }

    /**
     * Returns a Promise.reject/resolve based on the API response validity
     * @param response - The response from the API
     * @param control - The email control
     * @returns 
     */
    const getEmailResponseValidity = (response: any, control: IControl) => {

        if (!getEmailValidity(response.data.email)) {
            invalidateEmailControl(control)
            return Promise.reject(false);
        }
        else {
            validateEmailControl(control);
            return Promise.resolve(true);
        }
    }

    /**
     * Returns a Promise.reject/resolve based on the email Regex 
     * @param control - The email control
     * @returns 
     */
    const getRegexValidity = (control: IControl) => {
        const SIMPLE_EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s]+$/;

        if (SIMPLE_EMAIL_REGEX.test(control.value)) {
            validateEmailControl(control)
            return Promise.resolve(true);
        }
        else {
            invalidateEmailControl(control);
            return Promise.reject(false);
        }
    }

    /**
     * Makes API call to check status of the email
     * @returns whether email is valid or not
     */
    const isEmailValid = async (emailControl?: IControl) => {

        const control = emailControl || form.controls.email;

        const VALUE = control.value;

        if (!VALUE) {
            return Promise.resolve(true);
        }

        try {
            const response = await postBriteVerifyAPI(VALUE);

            return getEmailResponseValidity(response, control);
        }
        catch (err) {

            return getRegexValidity(control);
        }
    }

    return {
        isEmailValid,
    }
}

