import { IMobileBasicEditProps } from './interfaces/IMobileBasicEditProps'
import './MobileBasicEdit.scss'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { useUserProfileBasicEdit } from '../../../../useHooks/componentHooks/useUserProfileBasicEdit/useUserProfileBasicEdit'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders a basic edit page for the mobile version in the user profile flows i.e. change email, phone, will also add alternate phone number */
const MobileBasicEdit = (props: IMobileBasicEditProps) => {
    const { openEditPage, setOpenEditPage, index } = props
    const { userInformation, handleEmailOnChange, saveEnabled, newEmail, handleSave, handlePhoneOnChange, newPhone, message,
        phoneKeyDown, type, phoneInputError, emailInputError, newPhoneRef, phoneOnBlur, handleCancel, setSaveEnabled } = useUserProfileBasicEdit(setOpenEditPage)

    return (
        <div className="mobile-basic-edit-wrap">
            {message !== "" && <ToastMessageDB type={type} message={message} />}
            <div className="basic-edit-header-wrap">
                <img className="back-image" src={arrowLeftBlue} alt="back arrow" onClick={() => { handleCancel(); setOpenEditPage(""); setSaveEnabled(false) }} />
                <span className="header-text regular" onClick={() => { handleCancel(); setOpenEditPage(""); setSaveEnabled(false) }}>Contact information</span>
            </div>
            <div className="data-card-wrap">
                {openEditPage === "Email" &&
                    <>
                        <span className="title-text h3">Change email address</span>
                        <span className="title-text small">To make changes, enter your new email address and select 'Save.'</span>
                        <div className="bottom-border" />
                        <span className="title-text margin boldSmall">Current email address</span>
                        <span className="title-text regular cls_mask">{userInformation.contactInformation.emails[0].emailAddress}</span>
                        <div className="bottom-border" />
                        <label className={emailInputError ? "label-text error" : "label-text"}>New email address
                            <input
                                className={emailInputError ? "input-box error regular" : "input-box regular"}
                                type="text"
                                value={newEmail}
                                onChange={(e: any) => handleEmailOnChange(e)}
                            />
                            {emailInputError && <span className="error-text mobileboldextraSmall">Please provide a valid email address</span>}
                        </label>
                        <Button
                            title="Save"
                            isDisabled={!saveEnabled}
                            className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                            id={TIMER_IDS.USER_EMAIL}
                            onClick={() => handleSave("Email")}
                        />
                    </>
                }
                {openEditPage === "Mobile" &&
                    <>
                        <span className="title-text h3">Change mobile number</span>
                        <span className="title-text small">To make changes, enter your new mobile number and select 'Save.'</span>
                        <div className="bottom-border" />
                        <span className="title-text margin boldSmall">Current mobile number</span>
                        <span className="title-text regular cls_mask">{userInformation.contactInformation.phones[index].number}</span>
                        <div className="bottom-border" />
                        <label className={phoneInputError ? "label-text error" : "label-text"}>New mobile number
                            <input
                                className={phoneInputError ? "input-box error regular" : "input-box regular"}
                                type="text"
                                value={newPhone}
                                onChange={(e: any) => handlePhoneOnChange(e)}
                                maxLength={12}
                                onKeyDown={(e: any) => phoneKeyDown(e)}
                                id="phone-input-mobile"
                                ref={newPhoneRef}
                                onBlur={() => phoneOnBlur()}
                            />
                            {phoneInputError && <span className="error-text mobileboldextraSmall">Please provide a valid 10-digit mobile number</span>}
                        </label>
                        <Button
                            title="Save"
                            isDisabled={!saveEnabled}
                            className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                            id={TIMER_IDS.USER_PHONE}
                            onClick={() => handleSave("Mobile", index)}
                        />
                    </>
                }
                {openEditPage === "Alternate" &&
                    <>
                        <span className="title-text h3">Change alternate number</span>
                        <div className="bottom-border" />
                        <span className="title-text margin boldSmall">Current alternate number</span>
                        <span className="title-text regular cls_mask">{userInformation.contactInformation.phones[index]?.number}</span>
                        <div className="bottom-border" />
                        <label className={phoneInputError ? "label-text error" : "label-text"}>New alternate number
                            <input
                                className={phoneInputError ? "input-box error regular" : "input-box regular"}
                                type="text"
                                value={newPhone}
                                onChange={(e: any) => handlePhoneOnChange(e)}
                                onKeyDown={(e: any) => phoneKeyDown(e)}
                                maxLength={12}
                                id="phone-input-mobile"
                                ref={newPhoneRef}
                                onBlur={() => phoneOnBlur()}
                            />
                            {phoneInputError && <span className="error-text mobileboldextraSmall">Please provide a valid 10-digit mobile number</span>}
                        </label>
                        <Button
                            title="Save"
                            isDisabled={!saveEnabled}
                            className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                            id={TIMER_IDS.EDIT_PHONE}
                            onClick={() => handleSave("Alternate", index)}
                        />
                    </>
                }
                {openEditPage === "Add" &&
                    <>
                        <span className="title-text h3">Add alternate number</span>
                        <span className="title-text small">Enter your alternate number and select 'Save.'</span>
                        <div className="bottom-border" />
                        <label className={phoneInputError ? "label-text error" : "label-text"}>Alternate number
                            <input
                                className={phoneInputError ? "input-box error regular" : "input-box regular"}
                                type="text"
                                value={newPhone}
                                onChange={(e: any) => handlePhoneOnChange(e)}
                                onKeyDown={(e: any) => phoneKeyDown(e)}
                                maxLength={12}
                                id="phone-input-mobile"
                                ref={newPhoneRef}
                                onBlur={() => phoneOnBlur()}
                            />
                            {phoneInputError && <span className="error-text mobileboldextraSmall">Please provide a valid 10-digit mobile number</span>}
                        </label>
                        <Button
                            title="Save"
                            isDisabled={!saveEnabled}
                            className={saveEnabled ? "Button btn-primary" : "Button btn-primary inactive"}
                            id={TIMER_IDS.USER_PHONE}
                            onClick={() => handleSave("Add", index)}
                        />
                    </>
                }
            </div>
            {openEditPage === "Alternate" &&
                <div className="remove-number-wrap">
                    <img className="icon" src={redTrashCan} alt="red trashcan" onClick={() => handleSave("Remove", index)} />
                    <span className="red-text regular" onClick={() => handleSave("Remove", index)}>Remove alternate number</span>
                </div>
            }
        </div>
    )
}

export default MobileBasicEdit