import { getLastDayOfMonth } from "./getLastDayOfMonth"

/**Builds the custom picker array dates */
export const setCustomPickerArrayDates = (
        firstDayOfMonth: string, 
        months: Array<{month: string, days: number}>, 
        currentMonth: React.MutableRefObject<any>, 
        lastDayOfMonth: string, 
        lastDayOfPreviousMonth: string
        ) => {

    let tempArray: Array<{day: number, outside: boolean}> = []
    switch(firstDayOfMonth){
        case "Mon": {
            for(let i = 0; i < months[currentMonth.current].days; i = i + 1){
                let element = {day: i + 1, outside: false}
                tempArray.push(element)
            }
            getLastDayOfMonth(lastDayOfMonth, tempArray)
            break;
        }
        case "Tue": {
            for(let i = 0; i < 1; i = i + 1){
                let element = {day: parseInt(lastDayOfPreviousMonth), outside: true}
                tempArray.push(element)
            }
            for(let i = 0; i < months[currentMonth.current].days; i = i + 1){
                let element = {day: i + 1, outside: false}
                tempArray.push(element)
            }
            getLastDayOfMonth(lastDayOfMonth, tempArray)
            break;
        }
        case "Wed": {
            for(let i = 0; i < 2; i = i + 1){
                let element = {day: parseInt(lastDayOfPreviousMonth) - 1 + i, outside: true}
                tempArray.push(element)
            }
            for(let i = 0; i < months[currentMonth.current].days; i = i + 1){
                let element = {day: i + 1, outside: false}
                tempArray.push(element)
            }
            getLastDayOfMonth(lastDayOfMonth, tempArray)
            break;
        }
        case "Thu": {
            for(let i = 0; i < 3; i = i + 1){
                let element = {day: parseInt(lastDayOfPreviousMonth) - 2 + i, outside: true}
                tempArray.push(element)
            }
            for(let i = 0; i < months[currentMonth.current].days; i = i + 1){
                let element = {day: i + 1, outside: false}
                tempArray.push(element)
            }
            getLastDayOfMonth(lastDayOfMonth, tempArray)
            break;
        }
        case "Fri": {
            for(let i = 0; i < 4; i = i + 1){
                let element = {day: parseInt(lastDayOfPreviousMonth) - 3 + i, outside: true}
                tempArray.push(element)
            }
            for(let i = 0; i < months[currentMonth.current].days; i = i + 1){
                let element = {day: i + 1, outside: false}
                tempArray.push(element)
            }
            getLastDayOfMonth(lastDayOfMonth, tempArray)
            break;
        }
        case "Sat": {
            for(let i = 0; i < 5; i = i + 1){
                let element = {day: parseInt(lastDayOfPreviousMonth) - 4 + i, outside: true}
                tempArray.push(element)
            }
            for(let i = 0; i < months[currentMonth.current].days; i = i + 1){
                let element = {day: i + 1, outside: false}
                tempArray.push(element)
            }
            getLastDayOfMonth(lastDayOfMonth, tempArray)
            break;
        }
        case "Sun": {
            for(let i = 0; i < 6; i = i + 1){
                let element = {day: parseInt(lastDayOfPreviousMonth) - 5 +  i, outside: true}
                tempArray.push(element)
            }
            for(let i = 0; i < months[currentMonth.current].days; i = i + 1){
                let element = {day: i + 1, outside: false}
                tempArray.push(element)
            }
            getLastDayOfMonth(lastDayOfMonth, tempArray)
            break;
        }
    }
    return tempArray
}