import { useRef } from "react"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { downloadAccountStatement } from "../../../api/getAccountInformation"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { downloadDocuments } from "../../../utils/downloadDocuments"

/**A custom use hook for the document center account statements tab */
export const useDocumentAccountStatements = (selectedDate: string) => {
    const gotStatements = useAppSelector((state: RootState) => state.selectedAccount.gotStatements)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const { type, message, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

     /**handle the onClick event and downloads the account statement document */
     const handleStatementDownload = async (id: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadAccountStatement(selectedAccount.id, id)
                downloadDocuments(response.data, selectedAccount.accountNumber, "Statement", "")
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()
            }
            downloadClickedRef.current = false
        }
    }
    
    return { gotStatements, handleStatementDownload, selectedAccount, type, message }
}