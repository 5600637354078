import { useTextInput } from '../../Form/useHooks/useTextInput';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import { ITextInputProps } from './interfaces/ITextInputProps'
import './TextInput.scss'

/**Renders an input component for a Text Input type */
const TextInput = (props: ITextInputProps) => {
    const { control, style = {}, className = "", form, dispatchControlChanges } = props;
    const { onChangeHandler, handleOnBlur } = useTextInput(form, control, dispatchControlChanges)

    return (
        <div className={`text-input-wrap ${className}`}>
            <InputLabel control={control} >
                <input
                    data-testid={control.name}
                    type='text'
                    placeholder={control.isDisabled ? '' : control.placeholder}
                    className={control.errors.length > 0 ? "pass error regular" : "pass regular"}
                    id={control.id}
                    style={style}
                    onChange={(e: any) => onChangeHandler(e)}
                    onBlur={() => handleOnBlur()}
                    disabled={control.isDisabled}
                    value={control.value}
                />
            </InputLabel>
            {control.errors.length > 0 && <InputError controlName={control.name} errorArray={control.errors} getErrorMessage={form.getErrorMessage} />}
        </div>
    )
}


export default TextInput