import { useTransactionTable } from '../../useHooks/componentHooks/useTransactionTable/useTransactionTable';
import { DesktopTransactionTable } from './components/DesktopTransactionTable/DesktopTransactionTable';
import { MobileTransactionTable } from './components/MobileTransactionTable/MobileTransactionTable';
import { ITransactionTableProps } from './interfaces/ITransactionTableProps';
/**
 * Transaction table for Overview Page
 * @param startIndex - (optional) start index of transaction array for display
 * @param endIndex - (optional) end index of transaction array for display
 */
export const TransactionTable = (props: ITransactionTableProps) => {
  const { startIndex, endIndex, selectedTransaction, displayDetails, setDisplayDetails, transactions, isSortable, setFilteredTransactions,
          handleTransactionColumnSort, dateSort, amountSort } = props;
  const { windowState } = useTransactionTable(transactions, setFilteredTransactions);

  return (
    <div className="TransactionPreview">
      {windowState === "Desktop" || windowState === "Hybrid" ? (
        <DesktopTransactionTable
          transactionHistory={transactions}
          startIndex={startIndex}
          endIndex={endIndex}
          setDisplayDetails={setDisplayDetails}
          displayDetails={displayDetails}
          selectedTransaction={selectedTransaction}
          handleTransactionColumnSort={handleTransactionColumnSort}
          isSortable={isSortable}
          dateSort={dateSort} 
          amountSort={amountSort}
        />
      ) : (
        <MobileTransactionTable
          transactionHistory={transactions}
          startIndex={startIndex}
          endIndex={endIndex}
          setDisplayDetails={setDisplayDetails}
          displayDetails={displayDetails}
          selectedTransaction={selectedTransaction}
        />
      )}
    </div>
  );
};
