import { AccountBox } from '../../../components/AccountBox/AccountBox';
import { TotalBalance } from '../../../components/TotalBalance/TotalBalance';
import { IMobileAccountOverviewProps } from './interfaces/MobileAccountOverviewProps';
import './MobileAccountOverview.scss';
import LoadingStateAccountBox from '../../../components/AccountBox/components/LoadingStateAccountBox';
import MobileErrorAccountInformation from '../../../components/ErrorAccountInformation/MobileErrorAccountInformation/MobileErrorAccountInformation';
import plusSign from '../../../assets/plusSign.svg'
import { displayCurrency } from '../../../utils/displayCurrency';
import mobileSavingsBanner from '../../../assets/mobileSavingsBanner.svg'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import ProductsModal from '../../../components/ProductsModal/ProductsModal';
import mobileCdOffer from '../../../assets/mobileCdOffer.svg'
import information from '../../../assets/information.svg'
import { useState } from 'react';
import MobileAlertBox from '../../../components/MobileAlertBox/MobileAlertBox'
import { getSAMLURL } from '../../../shared/config/getSAMLUrl';
import { useNotifications } from '../../../Context/NotificationContext/useNotificationContext';
import NotificationMessageDB from '../../../shared/digitalbank/Notification/NotificationMessageDB';
import { INotificationServiceObject } from '../../../shared/common/Notification/interfaces/INotifcationProps.types';
import { useHistory } from 'react-router-dom';
import { useOpenNewAccount } from '../../../Context/OpenNewAccountContext/useOpenNewAccountContext';
import { IAccountInformation } from '../../../api/getAccountInformation';
import { getAccountDetailsAsync } from '../../../slices/selectedAccountSlice/selectedAccountSlice';
import { populateAccountDetails } from '../../../utils/populateAccountDetails';
import { setAccountInformationByIndex } from '../../../slices/accountInformation/accountInformationSlice';

/**
 * Displays the mobile version of the Account-Overview page.
 * @param props creditCardAccount, CDAccounts, savingsAccounts = Array<IAccountInformation>
 */
export const MobileAccountOverview = (props: IMobileAccountOverviewProps) => {
  const { creditCardAccounts, CDAccounts, savingsAccounts, isAccountInformationLoaded, totalSavingsBalance, totalCDBalance, openRatesModal,
    setOpenRatesModal, isAccountDetailsLoaded, gotUserContactInfo } = props;
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation)
  //!this is mock data that can be removed once services are set up
  const [showTooltipOne, setShowTooltipOne] = useState<boolean>(false)
  const [showTooltipTwo, setShowTooltipTwo] = useState<boolean>(false)
  // const { message, type } = useToastMessage()
  const { getCustomerNotifications, handleDismiss, customerArray } = useNotifications()
  const customerNotifs = getCustomerNotifications()
  const history = useHistory()
  const { checkExternalAccounts } = useOpenNewAccount()
  const dispatch = useAppDispatch()

  const handleOnNotifLinkClick = (event: any, notification: any) => {
    if (notification.notificationUrl) {
      // if(notification.notificationType === "BumpUpRate"){
        const arrayIndex = accountInformation.accountInformation.findIndex((account: IAccountInformation) => account.id === notification.accountId)
        if(arrayIndex >= 0){
          const account = accountInformation.accountInformation[arrayIndex]
          repopulateAccountDetails(arrayIndex, account)
        }
      // }
      history.push(notification.notificationUrl)
    }
  }

  /**Repopulates the account details on a page refresh */
  const repopulateAccountDetails = async (index: number, account: any) => {
    const payload = {arrayIndex: index, account: account}
    const response: any = await dispatch(getAccountDetailsAsync(payload))
    try{
        let tempAccount = populateAccountDetails(accountInformation.accountInformation[index], response.payload.response)
        const payload = {arrayIndex: index, account: tempAccount}
        dispatch(setAccountInformationByIndex(payload))            
    }
    catch{
        console.error()
    }
  }  

  return (
    <div>
      {(isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") && isAccountDetailsLoaded !== "Failed" && gotUserContactInfo !== "Failed" ?
        <div className="mobile-account-overview">
          <div className="toast-wrap">

          </div>
          <div className="total-balance-wrapper">
            <TotalBalance />
          </div>
          {isAccountInformationLoaded === "Success" ?
            <>
              <div className="accounts-displayed">
                {customerArray.length > 0 && <div className="customer-notifications-wrapper">
                  {customerArray.map((e: INotificationServiceObject) =>
                    <div key={e.notificationId} className="customer-notifications-row">
                      <NotificationMessageDB key={e.notificationId} data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotifLinkClick} />
                    </div>
                  )}
                {/* {customerNotifs.numToDisplay > 0 && <div className="customer-notifications-wrapper">
                  {customerNotifs.notifications.slice(0, customerNotifs.numToDisplay).map((e: INotificationServiceObject) =>
                    <div key={e.notificationId} className="customer-notifications-row">
                      <NotificationMessageDB key={e.notificationId} data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotifLinkClick} />
                    </div>
                  )} */}
                </div>}
                {creditCardAccounts.length > 0 && (
                  <div className="account-type-wrapper">
                    <>
                      <div className="account-title mobileh3">
                        Credit Cards
                      </div>
                      {creditCardAccounts.map((account: any, index: number) => {
                        return (
                          <div key={index} className="account-box-wrapper">
                            <AccountBox
                              account={account}
                            />
                          </div>
                        );
                      })}
                    </>
                  </div>
                )}
                {CDAccounts.length > 0 ?
                  <div className="account-type-wrapper">
                    <div className="account-title-wrap">
                      <span className="account-title mobileh3">CD accounts</span>
                      {CDAccounts.length > 1 && <span className="account-title small">Total: <span className="account-title regular">{displayCurrency(+totalCDBalance)}</span></span>}
                    </div>
                    {CDAccounts.map((account: any, index: number) => {
                      return (
                        <div key={index} className="account-box-wrapper">
                          <AccountBox
                            account={account}
                            index={index}
                          />
                        </div>
                      );
                    })}
                  </div>
                  :
                  <div className="account-type-wrapper">
                    <span className="account-title mobileh3">CD accounts</span>
                    <div className="banner-wrap">
                      <img className="banner-image" src={mobileCdOffer} alt="cd account offer" />
                      <button className="Button btn-primary boldSmall" onClick={checkExternalAccounts}>Open new CD account</button>
                      <div className="tooltip-wrap-one">
                        <img className="tooltip" src={information} alt="tooltip" onClick={() => setShowTooltipOne(true)} />
                        {showTooltipOne &&
                          <div className="tooltip-message-wrap">
                            <MobileAlertBox
                              setStateAction={setShowTooltipOne}
                              headerMessage="Rate Guarantee"
                              bodyMessage="After you open a CD account with us, if we increase the interest rate and Annual Percentage Yield (APY) we offer for the same CD product and term you selected within 10 calendar days of your account opening date (account opening date plus 9 calendar days), we will automatically give you the increased rate and APY. The higher rate will apply from the day you opened your CD."
                            />
                          </div>
                        }
                      </div>
                      <div className="tooltip-wrap-two">
                        <img className="tooltip" src={information} alt="tooltip" onClick={() => setShowTooltipTwo(true)} />
                        {showTooltipTwo &&
                          <div className="tooltip-message-wrap">
                            <MobileAlertBox
                              setStateAction={setShowTooltipTwo}
                              headerMessage="Loyalty Rate"
                              bodyMessage={"Receive a .05% Loyalty Rate increase when you renew your CD account.\n\nThe Loyalty Rate increase is subject to change and may be discontinued at any time. When your CD is approaching maturity, visit our CD homepage to view the current reward rate. See the FAQs for more information."}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                {savingsAccounts.length > 0 ?
                  <div className="account-type-wrapper">
                    <>
                      <div className="account-title-wrap">
                        <span className="account-title mobileh3">Savings accounts</span>
                        {savingsAccounts.length > 1 && <span className="account-title small">Total: <span className="account-title regular">{displayCurrency(+totalSavingsBalance)}</span></span>}
                      </div>
                      {savingsAccounts.map((account: any, index: number) => {
                        return (
                          <div key={index} className="account-box-wrapper">
                            <AccountBox
                              account={account}
                              index={CDAccounts.length + index}
                            />
                          </div>
                        );
                      })}
                    </>
                  </div>
                  :
                  <div className="account-type-wrapper">
                    <span className="account-title h3">Savings accounts</span>
                    <div className="banner-wrap">
                      <img className="banner-image" src={mobileSavingsBanner} alt="savings account" />
                      <button className="Button btn-primary boldSmall" onClick={checkExternalAccounts}>Open a savings account</button>
                      <div className="tooltip-wrap-three">
                        <img className="tooltip" src={information} alt="tooltip" onClick={() => setShowTooltipTwo(true)} />
                        {showTooltipTwo &&
                          <div className="tooltip-message-wrap">
                            <MobileAlertBox
                              setStateAction={setShowTooltipTwo}
                              headerMessage="Loyalty Rate"
                              bodyMessage={"Receive a .05% Loyalty Rate increase when you renew your CD account.\n\nThe Loyalty Rate increase is subject to change and may be discontinued at any time. When your CD is approaching maturity, visit our CD homepage to view the current reward rate. See the FAQs for more information."}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="new-account-button">
                <button className="Button btn-openaccount" onClick={checkExternalAccounts}>
                  <img src={plusSign} alt="Plus Symbol" />
                  <span className="New-Account-Text boldRegular">Open a new account</span>
                </button>
              </div>
              <div className="products-modal-wrap">
                <span className="products-text regular">Ready to start saving more?</span>
                <span className="products-link regular" onClick={() => setOpenRatesModal(true)}>Check out our current rates.</span>
              </div>
              {openRatesModal &&
                <ProductsModal
                  setShowModal={setOpenRatesModal}
                  CDProductList={accountInformation.CDProductList}
                  bumpProductList={accountInformation.BumpProductList}
                  savingsProductList={accountInformation.SavingsProductList}
                  showSavingsProducts={true}
                  origin='account'
                />
              }
            </>
            :
            <div className="loading-state">
              <LoadingStateAccountBox />
              <LoadingStateAccountBox />
              <LoadingStateAccountBox />
            </div>
          }
        </div>
        :
        <MobileErrorAccountInformation />
      }
    </div>
  );
};
