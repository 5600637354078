import { IToastMessageProps } from '../../common/ToastMessage/IToastMessageProps.types';

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARNING: 'WARNING'
} as const;

export interface IToastMessageDBProps extends Omit<IToastMessageProps, 'className'> {
  /**
   * The type of Toast Message to display.
   * @enum 'SUCCESS' | 'ERROR' | 'INFO' | 'WARNING'
   */
  type: keyof typeof TOAST_TYPE
}