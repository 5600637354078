import { AccountHeader } from '../../../components/AccountHeader/AccountHeader';
import './DesktopBeneficiary.scss';
import DesktopBeneficiaryEdit from '../../../components/BeneficiaryEdit/components/DesktopBeneficiaryEdit/DesktopBeneficiaryEdit';
import DesktopBeneficiarySummary from '../../../components/BeneficiarySummary/components/DesktopBeneficiarySummary/DesktopBeneficiarySummary';
import DesktopBeneficiaryEditProfile from '../../../components/BeneficiaryEditProfile/components/DesktopBeneficiaryEditProfile/DesktopBeneficiaryEditProfile';
import { AddBeneficiaryForm } from '../../../components/BeneficiaryAdd/AddBeneficiaryForm';
import DesktopBeneficiaryDelete from '../../../components/BeneficiaryDelete/DesktopBeneficiaryDelete/DesktopBeneficiaryDelete';
import { IDesktopBeneficiaryProps } from './interfaces/IDesktopBeneficiaryProps';

/**
 * Renders the Desktop Version Beneficiary landing page.  From this page beneficiaries
 * can be edited or the add beneficiary flow can begin.
 */
const DesktopBeneficiary = (props: IDesktopBeneficiaryProps) => {
  // const isBeneficiariesLoaded = useAppSelector((state: RootState) => state.selectedAccount.isBeneficiariesLoaded)
  const {
    editBeneficiary,
    setEditBeneficiary,
    editProfile,
    setEditProfile,
    selectedBeneficiary,
    deleteBeneficiary,
    setDeleteBeneficiary,
    showAlertMessage,
    setShowAlertMessage,
    addBeneficiary,
    setAddBeneficiary,
    unsavedChanges,
    setUnsavedChanges,
    childRef,
    checkAllocations,
    setCheckAllocations,
    currentPage,
    setCurrentPage,
    newBeneficiary,
    setNewBeneficiary,
    form,
    dispatchForm,
    handleOnClick
  } = props;

  return (
    <div className="Landing-Wrapper" ref={childRef}>
      {!editBeneficiary &&
        <>
          <div className="card-wrapper account-header">
            <AccountHeader
              returnMessage={'Account Settings'}
              setDisplayDetails={false}
            />
          </div>
        </>
      }
      {!editBeneficiary && !editProfile && !addBeneficiary && (
        <DesktopBeneficiarySummary
          addBeneficiary={addBeneficiary}
          setAddBeneficiary={setAddBeneficiary}
          editBeneficiary={editBeneficiary}
          setEditBeneficiary={setEditBeneficiary}
          setEditProfile={setEditProfile}
          showAlertMessage={showAlertMessage}
          setShowAlertMessage={setShowAlertMessage}
          handleOnClick={handleOnClick}
          setDeleteBeneficiary={setDeleteBeneficiary}
          form={form}
          dispatchForm={dispatchForm}
        />
      )}
      {editBeneficiary && (
        <DesktopBeneficiaryEdit
          setEditBeneficiary={setEditBeneficiary}
          showAlertMessage={showAlertMessage}
          setShowAlertMessage={setShowAlertMessage}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          childRef={childRef}
          checkAllocations={checkAllocations}
          setCheckAllocations={setCheckAllocations}
          onAddBeneficiary={false}
        />
      )}
      {editProfile && (
        <DesktopBeneficiaryEditProfile
          setEditProfile={setEditProfile}
          setEditBeneficiary={setEditBeneficiary}
          selectedBeneficiary={selectedBeneficiary}
          setDeleteBeneficiary={setDeleteBeneficiary}
          showAlertMessage={showAlertMessage}
          setShowAlertMessage={setShowAlertMessage}
          form={form}
          dispatchForm={dispatchForm}
        />
      )}
      {deleteBeneficiary && (
        <DesktopBeneficiaryDelete
          setDeleteBeneficiary={setDeleteBeneficiary}
          selectedBeneficiary={selectedBeneficiary}
          setEditBeneficiary={setEditBeneficiary}
          setEditProfile={setEditProfile}
          setShowAlertMessage={setShowAlertMessage}
          setCheckAllocations={setCheckAllocations}
        />
      )}
      {addBeneficiary && (
        <AddBeneficiaryForm
          setDisplayAddBeneficiary={setAddBeneficiary}
          setEditBeneficiary={setEditBeneficiary}
          setShowAlertMessage={setShowAlertMessage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          newBeneficiary={newBeneficiary}
          setNewBeneficiary={setNewBeneficiary}
          form={form}
          dispatchForm={dispatchForm}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          childRef={childRef}
          checkAllocations={checkAllocations}
          setCheckAllocations={setCheckAllocations}
        />
      )}
    </div>
  );
};

export default DesktopBeneficiary;
