import './CustomDateFilter.scss'
import { DateFilterInput } from './DateInput'
import { CustomDateFilterProvider } from './context/CustomDateFilterContext'
import { CustomDatePicker } from './CustomDatePicker'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'


/**Renders the custom date filter used as a filter for the transaction history page */
const CustomDateFilter = () => {
    const { windowState } = useWindowState();

    return (
        <CustomDateFilterProvider>

            <div className={windowState !== "Mobile" ? "custom-date-filter-wrap" : "custom-date-filter-wrap mobile"} >
                <div className="input-box-wrap">
                    <DateFilterInput
                        type='From'
                    />
                    <DateFilterInput
                        type='To'
                    />
                </div>
                <CustomDatePicker />

            </div >
        </CustomDateFilterProvider>
    )
}

export default CustomDateFilter


