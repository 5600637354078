import { useEffect, useRef, useState } from "react"

/**Checks for a backspace key press */
const checkBackspace = (key: string, setInput: any) => {
    if(key === "Backspace"){
        setInput("")
    }
}

/**When pasting a code focus the input to the length of the pasted code.  ie: length is 4 so focus the fourth input */
const focusPastedInput = (length: number, input1Ref: any, input2Ref: any, input3Ref: any, input4Ref: any, input5Ref: any, input6Ref: any) => {
    if(length > 0){
        switch(length){
            case 1:
                input2Ref.current.focus()
                break
            case 2:
                input3Ref.current.focus()
                break
            case 3:
                input4Ref.current.focus()
                break
            case 4:
                input5Ref.current.focus()
                break
            default:
                input6Ref.current.focus()
                break
        }
    }
}

/**A custom use hook for the 6-digit verification code input, **THE CUSTOM USE HOOK STARTS HERE** */
export const useVerificationCode = (setInputCode: any, clearInputs: boolean, setClearInputs: any) => {
    const input1Ref = useRef<any>()
    const input2Ref = useRef<any>()
    const input3Ref = useRef<any>()
    const input4Ref = useRef<any>()
    const input5Ref = useRef<any>()
    const input6Ref = useRef<any>()
    const userCodeRef = useRef<string>("")
    const [input1, setInput1] = useState<string>("")
    const [input2, setInput2] = useState<string>("")
    const [input3, setInput3] = useState<string>("")
    const [input4, setInput4] = useState<string>("")
    const [input5, setInput5] = useState<string>("")
    const [input6, setInput6] = useState<string>("")

    /**Focusing the first input box on render */
    useEffect(() => {
        input1Ref.current.focus()
    }, [])

    const handleOnPaste = (e: any) => {
        e.preventDefault()
        userCodeRef.current = e.clipboardData.getData("text/plain").slice(0,6)
        setInput1(userCodeRef.current[0])
        setInput2(userCodeRef.current[1])
        setInput3(userCodeRef.current[2])
        setInput4(userCodeRef.current[3])
        setInput5(userCodeRef.current[4])
        setInput6(userCodeRef.current[5])
        setInputCode(userCodeRef.current)
        focusPastedInput(userCodeRef.current.length, input1Ref, input2Ref, input3Ref, input4Ref, input5Ref, input6Ref)
    }

    useEffect(() => {
        if(clearInputs){
            userCodeRef.current = ""
            setInput1("")
            setInput2("")
            setInput3("")
            setInput4("")
            setInput5("")
            setInput6("")
            setClearInputs(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearInputs])

    /**Handles updating the userCodeRef with the new input */
    const updateRefInput = (value: string, index: number) => {
        if(index === 0){
            userCodeRef.current = value + userCodeRef.current.substring(index + 1)    
        }
        else if(index === 5){
            userCodeRef.current = userCodeRef.current.substring(0, index) + value   
        }
        else{
            const temp1 = userCodeRef.current.substring(0, index)
            const temp2 = userCodeRef.current.substring(index + 1)
            userCodeRef.current = temp1 + value + temp2
        }
        setInputCode(userCodeRef.current)
    }

    /**Handles input1 onChange */
    const handleOne = (e: any) => {
        updateRefInput(e.target.value, 0)
        setInput1(e.target.value)
        input2Ref.current.focus()
    }

    /**Handles input2 on Change */
    const handleTwo = (e: any) => {
        updateRefInput(e.target.value, 1)
        setInput2(e.target.value)
        input3Ref.current.focus()
    }

    /**Handles input3 on Change */
    const handleThree = (e: any) => {
        updateRefInput(e.target.value, 2)
        setInput3(e.target.value)
        input4Ref.current.focus()
    }

    /**Handles input4 on Change */
    const handleFour = (e: any) => {
        updateRefInput(e.target.value, 3)
        setInput4(e.target.value)
        input5Ref.current.focus()
    }

    /**Handles input5 on Change */
    const handleFive = (e: any) => {
        updateRefInput(e.target.value, 4)
        setInput5(e.target.value)
        input6Ref.current.focus()
    }

    /**Handles input6 on Change */
    const handleSix = (e: any) => {
        updateRefInput(e.target.value, 5)
        setInput6(e.target.value)
    }

    /**Handles the onKeyDown event */
    const handleKeyDown = (e: any, location: number) => {
        if(e.key !== "Tab" && e.key !== "Space" && e.key !== "CapsLock" && e.key !== "Shift"){
            switch(e.key){
                case "ArrowLeft":{
                    if(location > 0){
                        moveCursor(location - 1, e)
                    }
                    break
                }
                case "ArrowRight":{
                    if(location < 6){
                        moveCursor(location + 1, e)
                    }
                    break
                }
                case "Backspace":{
                    e.preventDefault()
                    removeCharacter(location, "Backspace")
                    break
                }
                case "Delete":{
                    e.preventDefault()
                    break
                }
                default:{
                    checkInputs(location, e.key)
                }
            }
        }
    }

    /**Checks the inputs to populate the next input box when backspace has been used and the user presses a new key */
    const checkInputs = (location: number, key: string) => {
        switch(location){
            case 1:{
                if(input1 !== "" && input2 === ""){
                    updateRefInput(key, 1)
                    setInput2(key)
                    input2Ref.current.focus()
                }
                break        
            }
            case 2:{
                if(input2 !== "" && input3 === ""){
                    updateRefInput(key, 2)
                    setInput3(key)
                    input3Ref.current.focus()
                }
                break
            }
            case 3:{
                if(input3 !== "" && input4 === ""){
                    updateRefInput(key, 3)
                    setInput4(key)
                    input4Ref.current.focus()
                }
                break
            }
            case 4:{
                if(input4 !== "" && input5 === ""){
                    updateRefInput(key, 4)
                    setInput5(key)
                    input5Ref.current.focus()
                }
                break
            }
            case 5:{
                if(input5 !== "" && input6 === ""){
                    updateRefInput(key, 5)
                    setInput6(key)
                    input6Ref.current.focus()
                }
            }
        }
    }

    /**Sets the positioning of the cursor */
    const setCursorPosition = (inputRef: React.MutableRefObject<any>, e: any) => {
        if(typeof e !== "string"){
            e.preventDefault()
            setTimeout(() => {
                inputRef.current.setSelectionRange(1, 1);
            }, )
        }
    }

    /**Handles moving the cursor around the input boxes*/
    const moveCursor = (location: number, e: any = "", key: "Backspace" | "" = "") => {
        switch(location){
            case 0: 
                checkBackspace(key, setInput1)
                setCursorPosition(input1Ref, e)
                break
            case 1:
                checkBackspace(key, setInput2)
                input1Ref.current.focus()
                setCursorPosition(input1Ref, e)
                break
            case 2: 
                checkBackspace(key, setInput3)
                input2Ref.current.focus()
                setCursorPosition(input2Ref, e)
                break
            case 3: 
                checkBackspace(key, setInput4)
                input3Ref.current.focus()
                setCursorPosition(input3Ref, e)
                break
            case 4: 
                checkBackspace(key, setInput5)
                input4Ref.current.focus()
                setCursorPosition(input4Ref, e)
                break
            case 5: 
                checkBackspace(key, setInput6)
                input5Ref.current.focus()
                setCursorPosition(input5Ref, e)
                break
            case 6: 
                input6Ref.current.focus()
                setCursorPosition(input6Ref, e)
        }
    }

    /**Removes a character from the userCodeRef string */
    const removeCharacter = (location: number, key: "Backspace" | "Delete") => {
        updateRefInput(" ", location - 1)
        if(key === "Backspace"){
            moveCursor(location - 1, "", "Backspace")
        }
    }

    /**Handles the onblur event */
    const handleOnBlur = () => {
        setInputCode(userCodeRef.current)
    }

    return { input1Ref, input2Ref, input3Ref, input4Ref, input5Ref, input6Ref, input1, input2, input3, input4, input5, input6,
             handleOne, handleTwo, handleThree, handleFour, handleFive, handleSix, handleKeyDown, handleOnBlur, handleOnPaste }
}