import './EditAccountNameDesktop.scss'
import editCheckmark from '../../../../assets/editCheckmark.svg'
import editCancel from '../../../../assets/editCancel.svg'
import { IEditAccountNameDesktopProps } from './interfaces/IEditAccountNameDesktopProps'
import { useEditAccountName } from '../../../../useHooks/componentHooks/useEditAccountName/useEditAccountName'

/**
 * The component allows the user to change the title of the account to some nickname.
 * @param props selectedAccount = IAccountInformation, setEditDescription: boolean
 */
const EditAccountNameDesktop = (props: IEditAccountNameDesktopProps) => {
    const { selectedAccount, setEditDescription } = props
    const { onChangeHandler, nickname, submit, handleResetClick } = useEditAccountName(selectedAccount, setEditDescription)

    return (
        <div className="desktop-edit-account-wrapper">
            <label className="label small" htmlFor="input">Account Nickname</label>
            <div className="input-wrapper">
                <div className="input-box-wrapper">
                    <input data-testid="nickname-input" className="input-box regular" type="text" id="input" value={nickname} onChange={(e) => onChangeHandler(e)} maxLength={40} />
                    <span className="reset-text regular" onClick={() => handleResetClick()}>Reset</span>
                </div>
                <div className="icon-wrapper">
                    <img data-testid="edit-check" src={editCheckmark} alt="checkmark" className="check-icon" onClick={() => submit()} />
                    <img data-testid="edit-cancel" src={editCancel} alt="cancel" className="cancel-icon" onClick={() => setEditDescription(false)} />
                </div>
            </div>
        </div>
    )
}

export default EditAccountNameDesktop