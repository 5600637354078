import { IMobileOverviewMiddleProps } from './interfaces/IMobileOverviewMiddleProps';
import './OverviewMiddle.scss'
import bumpPathWhite from '../../../../../../assets/bumpPathWhite.svg'
import { useState } from 'react';
import MobileAlertBox from '../../../../../MobileAlertBox/MobileAlertBox';
import { convertDateTommddyyyy } from '../../../../../../utils/DateUtils';
import tooltipBlueBackground from '../../../../../../assets/tooltipBlueBackground.svg'
import { displayCurrency } from '../../../../../../utils/displayCurrency';
import { useAppSelector } from '../../../../../../app/hooks';
import { RootState } from '../../../../../../app/store';
import bumpBlueCircle from '../../../../../../assets/bumpBlueCircle.svg'
import { useHistory } from 'react-router';
import { dateLongHand } from '../../../../../../utils/dateLongHand';

const OverviewMiddle = (props: IMobileOverviewMiddleProps) => {
    const history = useHistory()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showTooltipModal, setShowTooltipModal] = useState<boolean>(false)
    const { selectedAccount } = props;
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return (
        <div className="mobile-overview-middle-wrapper">
            <div className="mobile-layout">
                {isAccountDetailsLoaded !== "Loading" ?
                    <>
                        <div className="balance-wrap">
                            <div className="balance mobileh1"><sup>$</sup>{selectedAccount.currentBalance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            }).substring(1)}
                            </div>
                            <div className="title-row">
                                <span className="text regular">Current balance</span>
                                <img data-testid="balance-tooltip" className="icon" src={tooltipBlueBackground} alt="info" onClick={() => setShowTooltipModal(true)} />
                            </div>
                        </div>
                    <div className="account-details">
                        {selectedAccount.accountType !== "Savings" ?
                            <div className="term-wrap">
                                <span className="text small">Term</span>
                                <div className="values boldRegular">{selectedAccount.term}</div>
                            </div>
                            :
                            <div className="term-wrap">
                                <span className="text small">APY</span>
                                <div className="values boldRegular">{selectedAccount.interestRate.toFixed(2)}%</div>
                            </div>
                        }
                        <div className="interest-rate-wrap">
                            {selectedAccount.accountType !== "Savings" ? 
                            <>
                                <span className="text small">APY</span>
                                <div className="bottom-row-wrap">
                                    <div className="values boldRegular">{selectedAccount.interestRate.toFixed(2)}%</div>
                                    <div className="activated-wrap">
                                        {(selectedAccount.cdGroup === "BumpupCD" && (!selectedAccount.accountClosed && !selectedAccount.pendingGraceClosure)) &&
                                        <>
                                            {selectedAccount.bumpActivated ? 
                                                <img data-testid="bumped-icon" className="bumped-arrow" src={bumpPathWhite} alt="bump up path" onClick={() => setShowModal(true)}/>
                                                :
                                                <img data-testid="bump-icon" className="bump-circle" src={bumpBlueCircle} alt="bump up circle" onClick={() => history.push(`/account/${selectedAccount.id}/bumprate`)} />
                                            }
                                        </>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <span className="text small">Interest paid YTD</span>
                                <div className="values boldRegular">{displayCurrency(selectedAccount.interestPaidYTD)}</div>
                            </>
                            }
                            </div>
                            {selectedAccount.accountType !== "Savings" ?
                                <div className="matures-on-wrap">
                                    {!selectedAccount.accountClosed ?
                                        <>
                                        <span className="text small">Matures on</span>
                                        <div className="values boldRegular">{convertDateTommddyyyy(selectedAccount.maturityDate)}</div>
                                        </>
                                        :
                                        <>
                                        <span className="text small">Closed on</span>
                                        <div className="values boldRegular">{convertDateTommddyyyy(selectedAccount.accountClosedDate)}</div>
                                        </>
                                    }
                                </div>
                                :
                                <div className="term-wrap">
                                    <span className="text small">Available balance</span>
                                    <div className="values boldRegular">{displayCurrency(selectedAccount.balance)}</div>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <div className="mobile-middle-loading">
                        <div className="top-row">
                            <div className="loading-box-1" />
                        </div>
                        <div className="bottom-row">
                            <div className="loading-box-2" />
                            <div className="loading-box-2" />
                            <div className="loading-box-2" />
                        </div>
                    </div>
                }
            </div>
            {showModal &&
                <MobileAlertBox 
                    setStateAction={setShowModal}
                    headerMessage="Bump rate activated"
                    bodyMessage={`You increased your rate from ${selectedAccount.originalApy?.toFixed(2)}% to ${selectedAccount.interestRate.toFixed(2)}% on ${dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.bumpedRateDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.`}
                />
            }
            {showTooltipModal &&
                <MobileAlertBox 
                    setStateAction={setShowTooltipModal}
                    headerMessage="What is Current Balance?"
                    bodyMessage="Current Balance includes deposits that have posted but are not yet available. Pending debits are not deducted from this amount."
                />
            }
        </div>
    )
}

export default OverviewMiddle