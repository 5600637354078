import FilterBox from '../../../Documents/DesktopDocumentsPage/components/DesktopDateSelector/FilterBox'
import { TDateSelection } from '../../context/TransactionHistory.types';
import { dateSelections, useTransactionHistoryContext } from '../../context/TransactionHistoryContext'

const useDatePeriodDropdown = () => {
  const { dateFilter, handleFilterBoxClick, handleApply } = useTransactionHistoryContext();

  /**
   * Updates the `dateFilter.dateRange.ref` to the `dateRange` argument and filters transactions.
   * @param dateRange - The newly selected date range.
   */
  const handleDateSelect = (dateRange: TDateSelection) => {
    dateFilter.dateRange.ref.set(dateRange);
    handleApply();
  }

  return {
    handleDateSelect,
    handleFilterBoxClick,
    selectedDate: dateFilter.dateRange.state.get(),
  }
}


export const DatePeriodDropdown = () => {
  const { handleDateSelect, handleFilterBoxClick, selectedDate } = useDatePeriodDropdown();

  return <div className="date-filter">
    <span className="header-text small">Time period</span>
    <div className="filter-box-wrap">
      <FilterBox
        filterSelections={dateSelections}
        selectedFilter={selectedDate}
        setSelectedFilter={handleDateSelect}
        displayMessage="Date Period"
        handleFilterBoxClick={handleFilterBoxClick}
      />
    </div>
  </div>
}