import { useEffect } from 'react';
import { LinkExternalAccountContextProvider, useLinkExternalAccount } from '../LinkExternalAccount/Context/useLinkExternalAccount'
import { PostYodleeFlowChart } from '../LinkExternalAccount/components/PostYodleeFlow/PostYodleeFlowChart/PostYodleeFlowChart.component';
import { MockDigitalBankAPIs } from '../../testUtils/utils/mockDigitalBankAPIs';
import { MockAxios } from '../../utils/mockAxios';
import { ResponseStatus } from '../../api/models/response-status';
import { DesktopLayout } from '../../layouts/DesktopLayout/DesktopLayout';
import { IVerifiedAccountExtended } from '../../testUtils/utils/MockDigitalBankAPIs/contextControllers/YodleeController';
import { YodleeCreationStatus } from '../../api/postCreateYodleeAccount';
import { LINKING_STATUS } from '../../api/models/verified-accounts';

const INIT: Array<Partial<IVerifiedAccountExtended>> = [
  {
    externalAccountId: '1111',
    accountNickName: 'First One',
    accountNumberLastFourDigit: '1111'
  },
  {
    externalAccountId: '2222',
    accountNickName: 'Second One',
    accountNumberLastFourDigit: '2222'
  },
  {
    externalAccountId: '3322',
    accountNickName: 'Third One',
    accountNumberLastFourDigit: '3322'
  },
  {
    externalAccountId: '4444',
    accountNickName: 'Fourth One',
    accountNumberLastFourDigit: '4444'
  },
  {
    externalAccountId: '5555',
    accountNickName: 'Fifth One',
    accountNumberLastFourDigit: '5555'
  }
  ,
  {
    externalAccountId: '6666',
    accountNickName: 'Sixth One',
    accountNumberLastFourDigit: '6666'
  }
]

/**
 * Creates a number of Yodlee accounts that are already external accounts
 * @param context - The `MockDigitalBankAPIs`
 * @param numToCreate - The number of linked yodlee accounts to create.
 */
export const createAlreadyLinkedAccounts = (context: MockDigitalBankAPIs, numToCreate: number) => {
  const numOfAccountsAlreadyCreated = context.yodlee.yodleeVerifiedAccounts.size
  const START = numOfAccountsAlreadyCreated;
  for (let i = START; i < START + numToCreate; i++) {
    context.yodlee.addAlreadyLinkedAccount(INIT[i]);
  }
}

/**
 * Creates a number of Yodlee accounts that aren't already linked.
 * @param context - The `MockDigitalBankAPIs`
 * @param numToCreate - The number of non linked yodlee accounts to create.
 */
export const createNonLinkedAccounts = (context: MockDigitalBankAPIs, numToCreate: number) => {
  const numOfAccountsAlreadyCreated = context.yodlee.yodleeVerifiedAccounts.size
  const START = numOfAccountsAlreadyCreated;
  for (let i = START; i < START + numToCreate; i++) {
    context.yodlee.addNonLinkedAccount(INIT[i]);
  }
}

/**
 * Create selection needed accounts that aren't already linked.
 * @param context - The 'MockDIgitalBankAPIs'
 * @param numToCreate - The number of user selection needed account to create
 */
export const createSelectionNeededAccounts = (context: MockDigitalBankAPIs, numToCreate: number = 1) => {
  const numOfAccoutnsAlreadyCreated = context.yodlee.yodleeVerifiedAccounts.size
  const START = numOfAccoutnsAlreadyCreated
  for(let i = START; i < START + numToCreate; i = i + 1){
    context.yodlee.addSelectionNeededAccount(INIT[i])
  }
}

/**
 * Create "Processing" or "Pending _Review" accounts
 * @param context - The 'MockDigitalBankAPI's
 * @param numToCreate - The number of Processing accounts that should be created
 */
export const createProcessingAccounts = (context: MockDigitalBankAPIs, numToCreate: number = 1) => {
  const numOfAccountsAlreadyCreated = context.yodlee.yodleeVerifiedAccounts.size
  const START = numOfAccountsAlreadyCreated
  for(let i = START; i < START + numToCreate; i = i + 1){
    context.yodlee.addProcessingAccount(INIT[i])
  }
}

/**
 * Create "Verification pending" accounts
 */
export const createPendingAccounts = (context: MockDigitalBankAPIs, numToCreate: number = 1) => {
  const numOfAccountsAlreadyCreated = context.yodlee.yodleeVerifiedAccounts.size
  const START = numOfAccountsAlreadyCreated
  for(let i = START; i < START + numToCreate; i = i + 1){
    context.yodlee.addVerifyPendingAccount(INIT[i])
  }
}

/**
 * Create accounts that fail
 * @param context - The 'MockDigitalBankAPI's
 * @param numToCreate - The number of processing accounts that should be created
 */
export const createFailedAccounts = (context: MockDigitalBankAPIs, numToCreate: number = 1) => {
  const numOfAccountsAlreadyCreated = context.yodlee.yodleeVerifiedAccounts.size
  const START = numOfAccountsAlreadyCreated
  for(let i = START; i < START + numToCreate; i = i + 1){
    context.yodlee.addFailedAccount(INIT[i])
  }
}

/**
 * Creates a number of external accounts
 * @param context - The `MockDigitalBankAPIs`
 * @param numToCreate - The number of external accounts to create.
 */
const createExternalAccounts = (context: MockDigitalBankAPIs, numToCreate: number) => {
  for (let i = 0; i < numToCreate; i++) {
    context.addExternalAccount();
  }
}

/**
 * Sets the `account.status = LINKING_STATUS.FAILURE` for the given `externalAccountId`
 * @param context - The `MockDigitalBankAPIs`
 * @param failures - Am array of `externalAccountId` to make fail
 */
export const updateFailuresOnRetreieve = (context: MockDigitalBankAPIs, failures: Array<string>) => {
  for (let i = 0; i < failures.length; i++) {
    const account = context.yodlee.getAccount(failures[i])
    if (account) {
      account.status = LINKING_STATUS.FAILURE
    }
  }
}

/**
 * Sets the `account.statusOnCreation = YodleeCreationStatus.FAILED` for the given `externalAccountId`
 * @param context - The `MockDigitalBankAPIs`
 * @param failures - Am array of `externalAccountId` to make fail
 */
export const updateFailuresOnCreation = (context: MockDigitalBankAPIs, failures: Array<string>) => {
  for (let i = 0; i < failures.length; i++) {
    const account = context.yodlee.getAccount(failures[i])
    if (account) {
      account.statusOnCreation = YodleeCreationStatus.FAILED;
    }
  }
}

/**
 * All of the accounts that were returned from Yodlee where already linked.
 * @param numOfAccountsLinked - The number of accounts that should be already linked.
 * @returns 
 */
export const allAccountsAlreadyLinkedContext = (numOfAccountsLinked: number) => {
  const context = new MockDigitalBankAPIs();
  context.addAccount();
  createAlreadyLinkedAccounts(context, numOfAccountsLinked);
  context.yodlee.setResponseStatus(ResponseStatus.SUCCESS);
  createExternalAccounts(context, 2);
  return context;
}

/**
 * All of the accounts that were returned from Yodlee were created successfully.
 * @param numOfAccountToCreate - The number of accounts that should be created successfully.
 * @returns 
 */
export const allAccountsCreatedContext = (numOfAccountToCreate: number) => {
  const context = new MockDigitalBankAPIs();
  context.addAccount();
  createNonLinkedAccounts(context, numOfAccountToCreate);
  context.yodlee.setResponseStatus(ResponseStatus.CREATED);
  createExternalAccounts(context, 1);
  if (numOfAccountToCreate > 0) {
    const accounts = Array.from(context.yodlee.yodleeVerifiedAccounts).map(([, value]) => value);
    for (let i = 0; i < accounts.length; i = i + 1) {
      accounts[i].status = LINKING_STATUS.SUCCESS
    }
  }
  return context;
}

/**
 * All of the accounts that were returned from Yodlee were available to be created but some failed.
 * @param numOfAccountsToCreate - Number of Yodlee accounts to create
 * @param numberOfFailures - Number of accounts to fail to create.
 * @returns 
 */
export const someAccountsFailedToCreateContext = (numOfAccountsToCreate: number, numberOfFailures: number) => {
  const context = new MockDigitalBankAPIs();
  context.addAccount();
  createNonLinkedAccounts(context, numOfAccountsToCreate);
  if (numberOfFailures > 0) {
    const accounts = Array.from(context.yodlee.yodleeVerifiedAccounts).map(([, value]) => value);
    for (let i = 0; i < accounts.length; i = i + 1) {
      accounts[i].status = LINKING_STATUS.SUCCESS
    }
  }
  context.yodlee.setResponseStatus(ResponseStatus.SUCCESS);
  updateFailuresOnRetreieve(context, INIT.slice(0, numberOfFailures).map(e => e.externalAccountId as string))
  return context;
}

/**
 * All of the accounts that returned from Yodlee were available to be created, but some needed an agent review
 * @param numOfAccountsToCreate - Numbe of Yodlee accounts to create
 * @param numberOfProcessingAccounts - The number of accounts that should require an agent review
 * @param numberOfPending - The number of accounts that should be in pending review (micro -deposit) status
 */
export const someAccountRequireReviewContext = (numOfAccountsToCreate: number, numberOfReview: number, numberOfPending: number = 0) => {
  const context = new MockDigitalBankAPIs();
  context.addAccount();
  createAlreadyLinkedAccounts(context, numOfAccountsToCreate)
  createProcessingAccounts(context, numberOfReview)
  createPendingAccounts(context, numberOfPending)
  context.yodlee.setResponseStatus(ResponseStatus.SUCCESS)

  return context;
}

/**
 * There are more accounts from Yodlee then available external accounts slots. Non fail on creation.
 * @param numOfAccountToCreate - The number of Yodlee accounts to create.
 * @param numOfAlreadyLinkedAccoounts - The number of External accounts to create.
 * @returns 
 */
export const selectionNeededNoFailuresContext = (numOfAccountToCreate: number, numOfAlreadyLinkedAccoounts = 0) => {
  const context = new MockDigitalBankAPIs();
  context.addAccount();
  createAlreadyLinkedAccounts(context, numOfAlreadyLinkedAccoounts);
  createExternalAccounts(context, numOfAlreadyLinkedAccoounts)
  createNonLinkedAccounts(context, numOfAccountToCreate);
  createSelectionNeededAccounts(context, 1)
  context.yodlee.setResponseStatus(ResponseStatus.SUCCESS);

  return context;
}

/**
 * There are more accounts from Yodlee then available external accounts slots. Some fail on creation
 * @param numOfAccountToCreate - The number of Yodlee accounts to create.
 * @param numOfAlreadyLinkedAccoounts - The number of External accounts to create.
 * @param numOfFailures - The number of accounts that fail on creation.
 * @returns 
 */
export const selectionNeededFailureOnCreateContext = (numOfAccountToCreate: number, numOfAlreadyLinkedAccoounts = 0, numOfFailures = 0) => {
  const context = new MockDigitalBankAPIs();
  context.addAccount();
  createNonLinkedAccounts(context, numOfAccountToCreate);
  createAlreadyLinkedAccounts(context, numOfAlreadyLinkedAccoounts);
  createExternalAccounts(context, numOfAlreadyLinkedAccoounts)
  createSelectionNeededAccounts(context, 1)
  context.yodlee.setResponseStatus(ResponseStatus.SUCCESS);
  updateFailuresOnCreation(context, INIT.slice(0, numOfFailures).map(e => e.externalAccountId as string))
  return context;
}



// export const setContext = (responseStatus: YODLEE_STATUS, accountsToAdd = 1, numOfExternalAccounts: 1 | 2 = 1, failures: Array<string> = []) => {
//   switch (responseStatus) {
//     case YODLEE_STATUS.ALL_ACCOUNTS_LINKED: {
//       if (accountsToAdd + numOfExternalAccounts > MAX_EXTERNAL_ACCOUNTS) {
//         throw new Error(`Yodlee accounts created + external accounts created greater than MAX_EXTERNAL_ACCOUNTS {${MAX_EXTERNAL_ACCOUNTS}}`)
//       }
//       const context = allAccountsLinked(accountsToAdd);
//       createExternalAccounts(context, numOfExternalAccounts);
//       updateFailures(context, failures);
//       MockAxios(context);
//       break;
//     }
//     case YODLEE_STATUS.SUCCESS: {
//       if (accountsToAdd + numOfExternalAccounts > MAX_EXTERNAL_ACCOUNTS) {
//         throw new Error(`Yodlee accounts created + external accounts created greater than MAX_EXTERNAL_ACCOUNTS {${MAX_EXTERNAL_ACCOUNTS}}`)
//       }
//       const context = createdAllYodleeAccounts(accountsToAdd);
//       createExternalAccounts(context, numOfExternalAccounts);
//       updateFailures(context, failures);
//       MockAxios(context);
//       break;
//     }
//     case YODLEE_STATUS.SELECTION_NEEDED: {
//       const context = tooManyAccountsSelect(accountsToAdd);
//       createExternalAccounts(context, numOfExternalAccounts);
//       updateFailures(context, failures);
//       MockAxios(context);
//       console.log('setContext',
//         'yodlee Length',
//         context.yodlee.yodleeVerifiedAccounts.size,
//         'external Accounts Length',
//         context.externalaccounts.size)
//       break;
//     }
//   }


// }

const Inner = () => {
  const { setProviderAccountId, providerAccountId } = useLinkExternalAccount();

  useEffect(() => {
    setTimeout(() => {
      setProviderAccountId('1234');
    }, 1000)
  }, [])

  if (providerAccountId) {
    return <div><PostYodleeFlowChart /></div>
  }
  else {
    return <div>Pretending to load providerAccountId</div>
  }
}

export const TestYodlee = () => {
  const context = allAccountsCreatedContext(1);
  MockAxios(context);
  return <LinkExternalAccountContextProvider><DesktopLayout><Inner /></DesktopLayout></LinkExternalAccountContextProvider>


}