import { createContext, useContext, useEffect, useState } from "react";
import { IOpenNewAccountContextProps } from "./interfaces/IOpenNewAccountContextProps";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getSAMLURL } from "../../shared/config/getSAMLUrl";
import { useWindowState } from "../AccountContext/useWindowState";
import redCircleExclamation from '../../assets/redCircleExclamation.svg'
import { getEventPath } from "../../utils/getEventPath";
import './OpenNewAccountErrorModal.scss'
import { useHistory } from "react-router";
import { IExternalAccounts } from "../../api/User/interfaces";

/**
 * The context is used for the open new account buttons spread around the app. It will
 * check that the user has at least one external account before allowing the user to 
 * enter the open new account flow.  If the user does not have at least one external account
 * then a modal will render showing an error message
 */
export const OpenNewAccountContext = createContext<IOpenNewAccountContextProps>({
    showModal: false,
    setShowModal: () => {},
})

/**THe provider for the open new account context */
export const OpenNewAccountContextProvider = (props: any) => {
    const [showModal, setShowModal] = useState<boolean>(false)

    return (
        <OpenNewAccountContext.Provider value={{
            showModal,
            setShowModal
        }}>
            {showModal && <OpenNewAccountErrorModal />}
            {props.children}
        </OpenNewAccountContext.Provider>
    )
}

/**A custom use hook for the OpenNewAccountContext provider, this can be called within any component */
export const useOpenNewAccount = () => {
    const context = useContext(OpenNewAccountContext)
    const externalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)

    const checkExternalAccounts = () => {
        const foundAccounts = externalAccounts.filter((account: IExternalAccounts) => account.status === "Linked")
        if(foundAccounts.length === 0){
            context.setShowModal(true)
        }
        else{
            window.open(getSAMLURL(), '_blank')
        }
    }

    return {
        setShowModal: context.setShowModal,
        checkExternalAccounts
    }
}

/**
 * This is the error modal component that will render if the user attempts to open a new account while
 * having no external accounts linked.
 */
const OpenNewAccountErrorModal = () => {
    const { setShowModal } = useOpenNewAccount()
    const { windowState } = useWindowState()
    const history = useHistory()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-card-wrap")) === -1){
                document.body.style.overflow = "auto";
                setShowModal(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Remove the scrollbar when the modal renders */
    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {document.body.style.overflow = "auto"}
    }, [])


    return (
        <div className="open-new-account-error-overlay">
            {windowState !== "Mobile" ?
                <div className="modal-card-wrap">
                    <img className="modal-image" src={redCircleExclamation} alt="red circle with exclamation mark" />
                    <span className="modal-title h4">Unable to proceed</span>
                    <span className="modal-text regular">To open a new account, you must have at least one linked external account.</span>
                    <button className="Button btn-primary" onClick={() => {setShowModal(false); history.push('/user/profile/external-accounts')}}>Go to external accounts</button>
                </div>
                :
                <div className="modal-card-wrap mobile">
                    <img className="modal-image" src={redCircleExclamation} alt="red circle with exclamation mark" />
                    <span className="modal-title h4">Unable to proceed</span>
                    <span className="modal-text regular">To open a new account, you must have at least one linked external account.</span>
                    <button className="Button btn-primary mobile" onClick={() => {setShowModal(false); history.push('/user/profile/external-accounts')}}>Go to external accounts</button>
                </div>
            }
        </div>
    )
}

