import { Control } from "../../Form/Form/Control/Control"
import { VALIDATORS } from "../../Form/Form/Validators/Validators";

/**Controls for the add co-owner form */
export const addOwnerFormControls = () => {
    const prefix = Control(
        'prefix',
        '',
        'Prefix',
        'select',
        {
            selectedOptions: ["Mr", "Mrs", "Ms"]
        }
    );

    const firstName = Control(
        'firstName',
        '',
        'First name',
        'input',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required'
            },

        }
    );

    const middleName = Control('middleName', '', 'Middle name', 'input');

    const lastName = Control('lastName', '', 'Last name', 'input', {
        validations: [VALIDATORS.isRequired()],
        errorMessages: {
            IS_REQUIRED: 'This field is required'
        }
    });

    const dateOfBirth = Control('dateOfBirth', '', 'Date of birth', 'date', {
        validations: [VALIDATORS.isRequired(), VALIDATORS.maxDate(new Date().toLocaleString())],
        errorMessages: {
            IS_REQUIRED: 'This field is required',
            MAX_DATE: "The entered date cannot be a future date.",
        },
        placeholder: "Select date"
    });

    const citizenship = Control(
        'citizenship',
        '',
        'Citizenship',
        'select',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required'
            },
            selectedOptions: ["U.S. citizen", "Permanent U.S. resident", "Non-U.S. citizen/resident"]
        }
    );

    const ssn = Control('ssn', '', 'Social Security number', 'ssn', {
        validations: [VALIDATORS.isRequired(), VALIDATORS.minLength(11), VALIDATORS.maxLength(11)],
        errorMessages: {
            IS_REQUIRED: 'This field is required',
            MIN_LENGTH: `Please enter with format XXX-XX-XXXX`,
            MAX_LENGTH: `Please enter with format XXX-XX-XXXX`
        },
    });

    const address1 = Control('address1', '', 'Address 1', 'input', {
        validations: [VALIDATORS.isRequired()],
        errorMessages: {
            IS_REQUIRED: 'This field is required',
            NO_PO_BOX: 'Please enter a street address'
        },
        placeholder: "Street address and number"
    }
    );

    const address2 = Control('address2', '', 'Address 2 (optional)', 'input', {
        errorMessages: {
            NO_PO_BOX: 'Please enter a street address'
        },
        placeholder: "Apartment, suit, unit, building, etc"
    });

    const city = Control('city', '', 'City', 'input',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required'
            },
            placeholder: "City"
        }
    );

    const state = Control('state', '', 'State', 'select',
        {
            validations: [VALIDATORS.isRequired()],
            errorMessages: {
                IS_REQUIRED: 'This field is required'
            },
            selectedOptions: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA",
                "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK",
                "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
        }
    );

    const zipCode = Control('zipCode', '', 'ZIP Code', 'number',
        {
            validations: [VALIDATORS.isRequired(), VALIDATORS.minLength(5), VALIDATORS.maxLength(5)],
            errorMessages: {
                IS_REQUIRED: 'This field is required',
                MIN_LENGTH: `Please enter with format XXXXX`,
                MAX_LENGTH: `Please enter with format XXXXX`
            },
        }
    );

    const email = Control('email', '', 'Email address', 'email',
        {
            validations: [VALIDATORS.isRequired(), VALIDATORS.emailFormat()],
            errorMessages: {
                IS_REQUIRED: 'This field is required',
                EMAIL_FORMAT: `Please enter with format example@mail.com`,
                INVALID_EMAIL: `Please enter a valid email`
            },
            placeholder: "example@mail.com"
        },
    );

    const phoneNumber = Control('phoneNumber', '', 'Mobile number', 'phone',
        {
            validations: [VALIDATORS.isRequired(), VALIDATORS.minLength(12), VALIDATORS.maxLength(12)],
            errorMessages: {
                IS_REQUIRED: 'This field is required',
                MIN_LENGTH: `Please enter with format XXX-XXX-XXXX`,
                MAX_LENGTH: `Please enter with format XXX-XXX-XXXX`
            },
            placeholder: "e.g. 415-234-6650"
        }
    );

    return ([
        prefix,
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        citizenship,
        ssn,
        address1,
        address2,
        city,
        state,
        zipCode,
        email,
        phoneNumber
    ])
}