import { IDesktopOverviewMiddleProps } from './interfaces/IDesktopOverviewMiddleProps';
import './OverviewMiddle.scss'
import bumpPathWhite from '../../../../../../assets/bumpPathWhite.svg'
import Tooltip from '../../../../../Tooltip/Tooltip';
import { useState } from 'react';
import { dateLongHand } from '../../../../../../utils/dateLongHand';
import { convertDateTommddyyyy } from '../../../../../../utils/DateUtils';
import arrowDownWhite from '../../../../../../assets/arrowDownWhite.svg'
import AccountOverviewDropDown from '../AccountOverviewDropDown/AccountOverviewDropDown';
import { useHistory } from 'react-router';
import { displayCurrency } from '../../../../../../utils/displayCurrency';
import { useAppSelector } from '../../../../../../app/hooks';
import { RootState } from '../../../../../../app/store';
import bumpBlueCircle from '../../../../../../assets/bumpBlueCircle.svg'

/**
 * Renders the Desktop middle portion of the overview component, which is the Available Balance,
 * Interest Rate, Term, and Matures On fields.  
 * @param props selectedAccount = IAccountInformation
 */
const OverviewMiddle = (props: IDesktopOverviewMiddleProps) => {
    const history = useHistory()
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const { selectedAccount, showDropDown, setShowDropDown, initialClick, setInitialClick, show, setShow } = props;
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return (
        <>
            {isAccountDetailsLoaded !== "Loading" ?
                <div className="desktop-overview-middle-wrapper">
                    <div className="middle-row-wrap">
                        <div className='left-side'>
                            <div className="left">
                                <div className="label-wrap">
                                    {selectedAccount.accountType !== "Savings" ?
                                        <>
                                            <span className="description-text small">Term</span>
                                            <div className="value h3">{selectedAccount.term}</div>
                                        </>
                                        :
                                        <>
                                            <span className="description-text small apy min">APY</span>
                                            <div className="value h3">{selectedAccount.interestRate.toFixed(2)}%</div>
                                        </>
                                    }
                                </div>
                                <div className="label-wrap bump">
                                    {selectedAccount.accountType !== "Savings" ?
                                        <>
                                            <span className="description-text small apy">APY</span>
                                            <div className="bottom-row-wrap">
                                                <div className="value h3">{selectedAccount.interestRate.toFixed(2)}<span className='vertical-align__super boldSmall'>%</span></div>
                                                {(selectedAccount.cdGroup === "BumpupCD" && (!selectedAccount.accountClosed && !selectedAccount.pendingGraceClosure)) &&
                                                    <>
                                                        {!selectedAccount.bumpActivated ?
                                                            <div className="bump-rate-wrapper" onClick={() => history.push(`/account/${selectedAccount.id}/bumprate`)}>
                                                                <img data-testid="bump-icon" className="bump-link" src={bumpBlueCircle} alt="bump up circle" />
                                                            </div>
                                                            :
                                                            <div className="bumped-wrapper">
                                                                <img data-testid="bumped-icon" src={bumpPathWhite} alt="bump up arrow" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} />
                                                                <div className="tool-tip-wrapper" >
                                                                    {showTooltip &&
                                                                        <Tooltip
                                                                            anchorPoint='top-left'
                                                                            bodyMessage={`You increased your rate from ${selectedAccount.originalApy?.toFixed(2)}% to ${selectedAccount.interestRate.toFixed(2)}% on ${dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.bumpedRateDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.`}
                                                                            headerMessage='Bump rate activated'
                                                                            width="260px"
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                            <span className="description-text small">Interest paid YTD</span>
                                            <span className="value h3">{displayCurrency(selectedAccount.interestPaidYTD as number)}</span>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="right-side-wrap">
                            <div className="label-wrap">
                                {selectedAccount.accountType !== "Savings" ?
                                    <>
                                        {!selectedAccount.accountClosed ?
                                            <>
                                                <span className="description-text small">Matures on</span>
                                                <div className="value h3">{convertDateTommddyyyy(selectedAccount.maturityDate)}</div>
                                            </>
                                            :
                                            <>
                                                <span className="description-text small">Closed on</span>
                                                <div className="value h3">{convertDateTommddyyyy(selectedAccount.accountClosedDate)}</div>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <span className="description-text small">Available balance</span>
                                        <div className="value h3">{displayCurrency(selectedAccount.balance)}</div>
                                    </>
                                }
                            </div>
                            <div className="drop-down" onClick={() => { setShowDropDown(!showDropDown); setShow(!show); setInitialClick(true) }}>
                                {!show ?
                                    <span className="text semiboldRegular">More account details</span>
                                    :
                                    <span className="text semiboldRegular">Fewer account details</span>
                                }
                                {initialClick ?
                                    <img className={showDropDown ? "drop-down-icon-open" : "drop-down-icon-close"} src={arrowDownWhite} alt="Drop Down Arrow" />
                                    :
                                    <img src={arrowDownWhite} alt="Drop Down Arrow" />
                                }
                            </div>
                        </div>
                    </div>
                    <AccountOverviewDropDown
                        selectedAccount={selectedAccount}
                        showDropDown={show}
                        initialClick={initialClick}
                    />
                </div>
                :
                <div className="middle-loading-state">
                    <div className="left-side">
                        <div className="loading-box-1" />
                        <div className="loading-box-1" />
                        <div className="loading-box-1" />
                    </div>
                    <div className="loading-box-2" />
                </div>
            }
        </>
    )
}

export default OverviewMiddle