import { IMobileModifyAddressesProps } from './interfaces/IMobileModifyAddressesProps'
import './MobileModifyAddresses.scss'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { useModifyAddresses } from '../../../../useHooks/componentHooks/useModifyAddresses/useModifyAddresses'
import { FormProvider } from '../../../../Form/useForm/useForm'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import greyInfo from '../../../../assets/greyInfo.svg'
import ModifyAddressModal from '../ModifyAddressModal/ModifyAddressModal'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import { useLoquateOnForm, AddressInputWrapper } from '../../../../shared/LoquateAutoComplete/LoquateAutoComplete'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'
import { useUserProfileContext } from '../../Context/useUserProfileContext'

/**Renders the mobile version of the modify addresses flow i.e. Edit / Add / Remove*/
const MobileModifyAddresses = (props: IMobileModifyAddressesProps) => {
    const { setOpenEditPage, index, addressAction, setAddressAction } = props
    const { userInformation, form, dispatchForm, showCheckmark, setShowCheckmark, showTooltip, setShowTooltip, formFilled, handleOnSave,
        openMailingModal, setOpenMailingModal, purpose, handleRemoveAddress, setFormFilled } = useModifyAddresses(index, addressAction, setOpenEditPage)
    const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)
    const { usingSuggestion, setUsingSuggestion } = useUserProfileContext()

    

    const handleSubmit = async () => {
        try {
            if (addressAction !== "EditMain"){
                await isAddressValid(false)
            }
            else {
                await isAddressValid();
            }
            await handleOnSave();
            return Promise.resolve(true)
        }
        catch (err) {

        }
    }

    return (
        <FormProvider form={form} dispatch={dispatchForm}>
            <div className="mobile-modify-addresses-wrap">
                <div className="basic-edit-header-wrap">
                    <img className="back-image" src={arrowLeftBlue} alt="back arrow" onClick={() => { setOpenEditPage(""); setAddressAction(""); form.resetForm(); dispatchForm(); setFormFilled(false) }} />
                    <span className="header-text regular" onClick={() => { setOpenEditPage(""); setAddressAction(""); form.resetForm(); dispatchForm(); setFormFilled(false) }}>Contact information</span>
                </div>
                <div className="info-card-wrap">
                    {addressAction !== "Add" ?
                        <>
                            {addressAction === "EditMain" ?
                                <span className="card-title h3">Change home street address</span>
                                :
                                <span className="card-title h3">Change address</span>
                            }
                        </>
                        :
                        <>
                            <span className="card-title h3">Add new address</span>
                            <span className="sub-text small">Fields marked with an <span className="required-star small">*</span> are required.</span>
                        </>
                    }
                    <div className={addressAction !== "Add" ? "bottom-border" : "bottom-border no-margin"} />
                    {addressAction !== "Add" &&
                        <>
                            <div className="current-address-wrap">
                                {addressAction === "EditMain" ?
                                    <span className="data-text boldSmall">Home street address</span>
                                    :
                                    <span className="data-text boldSmall">Current address</span>
                                }
                                <span className="data-text regular cls_mask">{userInformation.contactInformation.addresses[index]?.addressLine1}</span>
                                <span className="data-text regular cls_mask">{userInformation.contactInformation.addresses[index]?.addressLine2}</span>
                                <span className="data-text regular cls_mask">{userInformation.contactInformation.addresses[index]?.city}, {userInformation.contactInformation.addresses[index]?.state} {userInformation.contactInformation.addresses[index]?.zipCode}</span>
                            </div>
                            <div className="bottom-border" />
                            <span className="data-text boldRegular">New address</span>
                            <span className="required-sub-text regular">Fields marked with an <span className="required-star regular">*</span> are required.</span>
                        </>
                    }
                    <div className="inputs-wrap">
                        {usingSuggestion && <span className={`auto-search-text small ${form.controls.addressLine1.errors.length && 'error'}`}>Search for an address&nbsp;<span className="required-star small">*</span></span>}
                        <AddressInputWrapper
                            onlyResidental
                            onSelection={onLoquateSelection}
                            control={form.controls.addressLine1}
                            form={form}
                            usingSuggestion={usingSuggestion}
                            setUsingSuggestion={setUsingSuggestion}
                            dispatchForm={dispatchForm}
                        />
                    </div>
                    <div className="checkbox-tooltip-wrap">
                        {(userInformation.contactInformation.addresses.length > 1 || addressAction === "Add") &&
                            <Checkbox
                                showCheckmark={showCheckmark}
                                setShowCheckmark={setShowCheckmark}
                            />
                        }
                        <span className="default-text regular">Set as default mailing address</span>
                        {/* <img className="tooltip-icon" src={greyInfo} alt="tooltip" onClick={() => setShowTooltip(true)} /> */}
                    </div>
                    <Button
                        title="Save"
                        isDisabled={!formFilled}
                        className={formFilled ? "Button btn-primary" : "Button btn-primary inactive"}
                        id={TIMER_IDS.USER_ADDRESS}
                        onClick={handleSubmit}
                    />
                </div>
                {(addressAction !== "EditMain" && addressAction !== "Add") &&
                    <div className="remove-address-wrap">
                        <img className="remove-image" src={redTrashCan} alt="red trashcan" onClick={() => handleRemoveAddress()} />
                        <span className="remove-text regular" onClick={() => handleRemoveAddress()}>Remove address</span>
                    </div>
                }
                {/* {showTooltip &&
                    <div className="modal-overlay">
                        <div className="menu-wrap">
                            <div className="header-wrap">
                                <span className="title-text semiboldRegular">Default mailing address</span>
                                <span className="link-text regular" onClick={() => setShowTooltip(false)}>Close</span>
                            </div>
                            <span className="body-text regular">All of your automated banking correspondence, such as tax documents, will be sent to your mailing address.</span>
                        </div>
                    </div>
                } */}
                {openMailingModal &&
                    <ModifyAddressModal
                        form={form}
                        index={index}
                        purpose={purpose}
                        setOpenEditPage={setOpenEditPage}
                        setOpenMailingModal={setOpenMailingModal}
                        dispatchForm={dispatchForm}
                    />
                }
            </div>
        </FormProvider>
    )
}

export default MobileModifyAddresses