import { useMaturityCard } from '../../useHooks/componentHooks/useMaturityCard/useMaturityCard';
import { convertDateTommddyyyy } from '../../utils/DateUtils';
import { Card } from '../Card/Card';
import { IMaturityCardProps } from './interfaces/IMaturityCardProps'; 
import './MaturityCard.scss'; 
import warning from '../../assets/warning.svg'

/** 
 * Component creates the Maturity Summary Card found on the account details page
 * @param selectedAccount of IAccountInformation
 */ 
export const MaturityCard = (props: IMaturityCardProps) => {
  const { history, isAccountDetailsLoaded } = useMaturityCard()
   const { selectedAccount } = props

   return ( 
    <>
    {isAccountDetailsLoaded !== "Loading" ?
      <Card width={"352px"} height={"444px"} showTopBorder={true} topBorderColor={"var(--secondaryDarkBlue)"}>
          <div className="maturity-card-wrapper">
            <div className="card-details"> 
              <div className="header-wrap">
                <span className="card-title boldLarge">Maturity plan</span>
                <button className="manage-button semiboldRegular" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`, {page: "Account Details"})}>Manage</button>
              </div>
              <div className="wrapper">
                <span className="text boldSmall">Maturity date</span>
                <span className="value regular">{convertDateTommddyyyy(selectedAccount?.maturityDate)}</span>
              </div>
              <div className="wrapper">
                <span className="text boldSmall">Action at maturity</span>
                <span className="value regular">{selectedAccount.maturitySetting.option} CD</span>
              </div>
              <div className="wrapper">
                {selectedAccount.maturitySetting.option !== "Close" ?
                <>
                  <span className="text boldSmall">Term</span>
                  <span className="value regular">{selectedAccount?.maturitySetting.changeProduct}</span>
                </>
                :
                <>
                  <span className="text boldSmall">Transfer balance to</span>
                  <span className="value regular">{selectedAccount.maturitySetting.closedAccountName} {selectedAccount.maturitySetting?.closedAccountId?.slice(selectedAccount.maturitySetting.closedAccountId.length - 4, selectedAccount.maturitySetting.closedAccountId.length)}</span>
                </>
                }
              </div>
            </div>
          </div>
      </Card> 
      :
      <div className="card-loading-state" />
    }
    </>
  ); 
}; 
