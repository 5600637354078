import { useEffect } from "react"
import { getEventPath } from "../../../utils/getEventPath";

/**A custom use hook for the Mobile Alert Box component */
export const useMobileAlertBox = (setStateAction: any) => {

    /**Locks the scrolling on render */
    useEffect(() => {
        document.body.style.overflow = "hidden";
    },[])

        /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("data-wrapper")) === -1){
                document.body.style.overflow = "auto";
                setStateAction(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])    

    /**handles the onClick for "Done" */
    const handleDone = () => {
        document.body.style.overflow = "auto";
        setStateAction(false)
    }

    /**Locks the scrollbar when mounted, unlocks the scrollbar when unmounted */
    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {document.body.style.overflow = "auto"}
    }, [])

    return { handleDone }
}