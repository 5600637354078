/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEditAccountName } from '../../../../useHooks/componentHooks/useEditAccountName/useEditAccountName'
import './EditAccountNameMobile.scss'
import { IEditAccountNameMobileProps } from './interfaces/IEditAccountNameMobileProps'

/**
 * Component allows the user to add a custom name to their account on the mobile version
 * @param props 
 * @returns 
 */
const EditAccountNameMobile = (props: IEditAccountNameMobileProps) => {
    const { selectedAccount, setEditDescription } = props
    const { onChangeHandler, nickname, submit, handleResetClick } = useEditAccountName(selectedAccount, setEditDescription)

    return (
        <div className="mobile-edit-nickname-wrap">
            <div className="nickname-menu-wrap">
                <div className="header-wrap">
                    <span className="reset-text regular" onClick={() => handleResetClick()}>Reset</span>
                    <span className="account-title-text semibold">Account Nickname</span>
                    <span className="done-text regular" onClick={() => submit()}>Done</span>
                </div>
                <div className="input-wrap">
                    <label className="label small" htmlFor="input">Account Nickname</label>
                    <div className="input-wrapper">
                        <input data-testid="nickname-input" className="input-box regular" type="text" id="input" value={nickname} onChange={(e) => onChangeHandler(e)} maxLength={40}/>
                    </div>
                    <span className="info-text small">Enter the nickname you want to assign to this account.</span>
                </div>
            </div>
        </div>
    )
}

export default EditAccountNameMobile