import contactInfo from '../../../../assets/contactInfo.svg'
import lock from '../../../../assets/lock.svg'
import information from '../../../../assets/information.svg'
import settings from '../../../../assets/settings.svg'
import bankGrey from '../../../../assets/bankGrey.svg'
import logout from '../../../../assets/logout.svg'
import './DesktopDropdown.scss'
import { IDesktopDropDownProps } from './interfaces/IDesktopDropDownProps'
import { useHeaderDropdown } from '../../../../useHooks/componentHooks/headerComponentHook/useHeaderDropdown'
import { oktaAuth } from '../../../../Routes'
import { getLogoutUri } from '../../../../utils/globalVariables'

/**
 * DesktopDropDown renders the dropdown menu in the desktop header bar when the pixel count is 800 or greater
 * @param props showMenu, setShowMenu
 * @returns the desktop header drop down menu
 */
const DesktopDropdown = (props: IDesktopDropDownProps) => {
    const { history, show } = useHeaderDropdown(props)

    const oktaLogout = async () => {
        if(sessionStorage.getItem("isAuthenticated")){
            sessionStorage.removeItem("isAuthenticated")
        }
        oktaAuth.signOut({ postLogoutRedirectUri: getLogoutUri })
    }

    return (
        <div>
            {show &&
                <div className="Dropdown-Wrapper" id={"dropDown"} >
                    <div className="Option-List">
                        <div className="Option-Row" onClick={() => { history.push('/user/profile/contact') }}>
                            <img className="image-padding" src={contactInfo} alt="Contact Information" />
                            <span className="Text regular">Contact information</span>
                        </div>
                        <div className="Option-Row" onClick={() => { history.push('/user/profile/security') }}>
                            <img className="image-padding" src={lock} alt="Security Settings" />
                            <span className="Text regular" >Security settings</span>
                        </div>
                        <div className="Option-Row" onClick={() => { history.push('/user/profile/tax') }}>
                            <img className="image-padding" src={information} alt="Tax Information" />
                            <span className="Text regular" >Tax withholding</span>
                        </div>
                        <div className="Option-Row" onClick={() => { history.push('/user/profile/external-accounts') }}>
                            <img className="image-padding" src={bankGrey} alt="Manage External Accounts" />
                            <span className="Text regular" >External accounts</span>
                        </div>
                        <div className="Option-Row" onClick={() => { history.push('/user/profile/notifications') }}>
                            <img className="image-padding" src={settings} alt="Notification Settings" />
                            <span className="Text regular" >Notifications</span>
                        </div>
                        {/* <div className="Option-Row" onClick={() => {history.push('/login')}}> */}
                        <div className="Option-Row" onClick={() => oktaLogout()}>
                            <img className="image-padding" src={logout} alt="Sign out" />
                            <span className="Text Sign-Out regular" >Sign out</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DesktopDropdown