import { IPieChartProps } from './interfaces/IPieChartProps';
import './PieChart.scss';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { usePieChart } from '../../useHooks/componentHooks/usePieChart/usePieChart';

  //These are options that effect the rendering of every PieChart in the application.
  export const options = {
    plugins: {
      //These effect how the tooltip is displayed
      tooltip: {
        //Disables the built in tooltip
        enabled: false,
        external: function (context: { tooltip: any; chart: { canvas: { getBoundingClientRect: () => any; }; }; }) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem: any) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            let innerHtml = '<thead>';

            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function (body: string, i: number) {
              let newBody = body.toString().replace(":", " ")

              let style = 'background: black';
              style += '; border-width: 2px';
              style += '; border-radius: 4px';
              style += '; padding: 6px';
              const span = '<span style="' + style + '">' + newBody + "% </span>";
              innerHtml += '<tr><td>' + span + '</td></tr>';
            });
            innerHtml += '</tbody>';

            let tableRoot = tooltipEl.querySelector('table');
            tableRoot!.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          const positionoffset = 3/4 * tooltipModel.caretX;

          // Display, position, and set styles for font
          if(window.innerWidth >= 768) {
            tooltipEl.style.opacity = "0.9";
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.display = "block";
            tooltipEl.style.left = position.left + window.pageXOffset + positionoffset + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = "Open Sans";
            tooltipEl.style.color = "white";
            tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
          else {
            tooltipEl.style.opacity = "0";
            tooltipEl.style.display = "none";
          }
        }
      },
      //These effect how the label legend is displayed.
      legend: {
        display: false,
      }
    }
  }

ChartJS.register(ArcElement, Legend, [Tooltip]);
/** 
 * Creates a Pie Chart for the beneficiary display
 * @param beneficiaries = Array<IGetBeneficiaries>
 */
export const PieChart = (props: IPieChartProps) => {
  const { beneficiaries } = props
  const { data } = usePieChart(beneficiaries)

  return (
    <>
      {data && <Pie data={data} options={options} />}
    </>
  );
}; 
