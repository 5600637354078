export const getLastDayOfMonth = (lastDayOfMonth: string, tempArray: Array<{day: number, outside: boolean}>) => {
    switch(lastDayOfMonth){
        case "Mon": {
            for(let i = 0; i < 6; i = i + 1){
                let element = {day: i + 1, outside: true}
                tempArray.push(element)
            }
            break;
        }
        case "Tue": {
            for(let i = 0; i < 5; i = i + 1){
                let element = {day: i + 1, outside: true}
                tempArray.push(element)
            }
            break;
        }
        case "Wed": {
            for(let i = 0; i < 4; i = i + 1){
                let element = {day: i + 1, outside: true}
                tempArray.push(element)
            }
            break;
        }
        case "Thu": {
            for(let i = 0; i < 3; i = i + 1){
                let element = {day: i + 1, outside: true}
                tempArray.push(element)
            }
            break;
        }
        case "Fri": {
            for(let i = 0; i < 2; i = i + 1){
                let element = {day: i + 1, outside: true}
                tempArray.push(element)
            }
            break;
        }
        case "Sat": {
            for(let i = 0; i < 1; i = i + 1){
                let element = {day: i + 1, outside: true}
                tempArray.push(element)
            }
            break;
        }
        case "Sun": {
            break;
        }
    }
}