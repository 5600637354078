import { useEffect, useState } from "react";
import { getEventPath } from "../../utils/getEventPath";
import { IControl } from "../Form/Control/IControl.interface";
import { IFormContext, TControlDispatch } from "../useForm/useForm";

/**A custom usehook for the SelectInput logic */
export const useSelectInput = (form: IFormContext, control: IControl, dispatchControlChanges: TControlDispatch) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false)

    /**Handles the onClick event for the select input */
    const handleOnClick = (option: any) => {
        form.setValue(control.name, option)
        setOpenDropdown(false)
        dispatchControlChanges()
    }

    /**Handles the onBlur event for the select input so long as the input is not disabled*/
    const handleOnBlur = () => {
        if(!control.isDisabled){
            form.runValidations(control.name)
            dispatchControlChanges()
        }
    }

    /**
     * use effect creates an event listener to check for clicks outside of the dropdown space
     * If a click outside the dropdown space is found then the dropdown will close.
     * To customize this to your project you may need to change the className to match your own.
     */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("selection-wrap")) === -1){
                setOpenDropdown(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    },[])

    return { openDropdown, setOpenDropdown, handleOnClick, handleOnBlur }
}