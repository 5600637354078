import { useRef, useState } from 'react';

export interface IUseRefState<T = any> {
  state: {
    /**
      * Returns the `state` value
     */
    get: () => T;
    /**
      * Sets the `state` value
    */
    set: (value: T) => void
  },
  ref: {
    /**
     * Returns the `ref` value
     */
    get: () => T;
    /**
      * Sets the `ref` value
    */
    set: (value: T) => void
  },
  /**
  * Sets the `state` value to the `ref` value.
  * @returns - The new `state` value
  */
  setRefValueToState: () => T;
  /**
  * Sets the `ref` value to the `state` value.
  * @returns - The new `ref` value
  */
  setStateValueToRef: () => T;
  /**
   * Resets both the `state` and the `ref` value to their initial values.
   */
  reset: () => void;
}


export function useRefState<T = any>(initialState: T, initialRef?: T): IUseRefState<T> {
  const [state, sState] = useState(initialState);
  const ref = useRef(initialRef || initialState);

  /**
    * Returns the `state` value
  */
  const getState = () => state;
  /**
    * Sets the `state` value
  */
  const setState = (newState: T) => sState(newState);

  /**
    * Returns the `ref` value
  */
  const getRef = () => ref.current;
  /**
    * Sets the `ref` value
  */
  const setRef = (newRef: T) => {
    ref.current = newRef;
  }

  /**
   * Sets the `state` value to the `ref` value.
   * @returns - The new `state` value
   */
  const setRefValueToState = () => {
    sState(ref.current);
    return ref.current;
  }
  /**
   * Sets the `ref` value to the `state` value.
   * @returns - The new `ref` value
   */
  const setStateValueToRef = () => {
    ref.current = state;
    return ref.current;
  }

  /**
   * Resets both the `state` and the `ref` value to their initial values.
   */
  const reset = () => {
    sState(initialState);
    ref.current = initialRef || initialState;
  }


  return {
    state: {
      get: getState,
      set: setState,
    },
    ref: {
      get: getRef,
      set: setRef
    },
    setRefValueToState,
    setStateValueToRef,
    reset
  }
}