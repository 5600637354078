import { IMobileTransactionFilterProps } from './interfaces/IMobileTransactionFilterProps'
import './MobileTransactionFilter.scss'
import arrowRight from '../../../../../assets/arrowRight.svg'
import { useMobileTransactionFilter } from '../../../../../useHooks/componentHooks/useMobileTransactionFilter/useMobileTransactionFilter'
import MobileDateRangeSelection from '../MobileDateRangeSelection/MobileDateRangeSelection'
import greyCancelX from '../../../../../assets/greyCancelX.svg'
import MobileTransactionTypeSelection from '../MobileTransactionTypeSelection/MobileTransactionTypeSelection'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import { useTransactionHistoryContext } from '../../../context/TransactionHistoryContext'
import MobileAmountFilter from '../MobileAmountFilter/MobileAmountFilter'
import { useState } from 'react'


/**Displays the filters for the Mobile Transaction history page */
const MobileTransactionFilter = (props: IMobileTransactionFilterProps) => {
    const { setMobileFilterOpen } = props


    const {
        dateRangeSelected,
        setDateRangeSelected,
        transactionTypeSelected,
        setTransactionTypeSelected,
        amountSelected,
        setAmountSelected,
        handleReset,
        handleCancel,
        handleApply
    } = useMobileTransactionFilter(setMobileFilterOpen)

    return (
        <div className="mobile-transaction-filter-wrap">
            {(!dateRangeSelected && !transactionTypeSelected && !amountSelected) &&
                <>
                    <div className="header-row">
                        <span className="option-text regular" onClick={() => handleReset()}>Reset</span>
                        <span className="header-text semiboldRegular">Filters</span>
                        <span className="option-text regular" onClick={() => handleCancel()}>Cancel</span>
                    </div>
                    <div className="bottom-border" />

                    <DateFilterDisplay
                        setDateRangeSelected={setDateRangeSelected} />

                    <div className="bottom-border" />

                    <TransactionTypeDisplay
                        setTransactionTypeSelected={setTransactionTypeSelected}
                    />

                    <div className="bottom-border" />

                    <AmountFilterDisplay
                        setAmountSelected={setAmountSelected} />

                    <div className="bottom-border" />
                    <div className="button-wrapper">
                        <button className="apply-button smallBoldButton" onClick={() => handleApply()}>Apply</button>
                    </div>
                </>
            }
            {dateRangeSelected &&
                <MobileDateRangeSelection
                    setDateRangeSelected={setDateRangeSelected}
                />
            }
            {transactionTypeSelected &&
                <MobileTransactionTypeSelection
                    setTransactionTypeSelected={setTransactionTypeSelected}
                />
            }
            {amountSelected &&
                <MobileAmountFilter setAmountSelected={setAmountSelected} />
            }
        </div>
    )
}

const stopDefault = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
}

const DateFilterDisplay = (props: { setDateRangeSelected: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { dateFilter: { dateRange, customDate, fromDate, toDate }, handleApply } = useTransactionHistoryContext();

    const [triggerUpdate, setTriggerUpdate] = useState(0)

    return <div className="row-wrapper" onClick={() => {
        props.setDateRangeSelected(true)
    }}><div className="left-side">
            <span className="selection-text regular">Date Range</span>
            {dateRange.ref.get() === "All" ?
                <span className="selected-text semiboldRegular">All</span>
                :
                <div className="filter-wrap" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <span className="selected-text semiboldRegular">{customDate.ref.get() || dateRange.ref.get()}</span>
                    <img className="cancel-filter" src={greyCancelX} alt="cancel filter" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dateRange.ref.set('All');

                        if (customDate) {
                            fromDate.ref.set({ month: 0, day: 0, year: 0 });
                            toDate.ref.set({ month: 0, day: 0, year: 0 });
                        }
                        setTriggerUpdate(triggerUpdate + 1);
                    }} />
                </div>

            }
        </div>
        <img className="right-arrow" src={arrowRight} alt="selection arrow" />
    </div>
}

const AmountFilterDisplay = (props: { setAmountSelected: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { setAmountSelected } = props;
    const { amountFilter: { maxAmount, minAmount } } = useTransactionHistoryContext();

    const [triggerUpdate, setTriggerUpdate] = useState(0)

    return <div className="row-wrapper" onClick={() => setAmountSelected(true)}>
        <div className="left-side">
            <span className="selection-text regular">Amount</span>
            {!maxAmount.ref.get() && !minAmount.ref.get() ?
                <span className="selected-text semiboldRegular">Any</span>
                :
                <div className="filter-wrap">
                    <span className="selected-text semiboldRegular">{displayCurrency(minAmount.ref.get() as number)}-{displayCurrency(maxAmount.ref.get() as number)}</span>
                    <img className="cancel-filter" src={greyCancelX} alt="cancel filter" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        maxAmount.ref.set(null);
                        minAmount.ref.set(null);
                        maxAmount.state.set(null);
                        minAmount.state.set(null);
                        setTriggerUpdate(triggerUpdate + 1)
                    }} />
                </div>
            }
        </div>
        <img className="right-arrow" src={arrowRight} alt="selection arrow" />
    </div>
}

/**
 * Displays the Transaction Type for the mobile transaction filters.
 * @param props 
 * @returns 
 */
const TransactionTypeDisplay = (props: { setTransactionTypeSelected: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { setTransactionTypeSelected } = props;
    const { typeFilter } = useTransactionHistoryContext();

    const [triggerUpdate, setTriggerUpdate] = useState(0)

    const REF = typeFilter.transactionType.ref.get();
    return (<div className="row-wrapper" onClick={() => setTransactionTypeSelected(true)}>
        <div className="left-side">
            <span className="selection-text regular">Transaction Type</span>
            <div>
                {REF === "All" ?
                    <span className="selected-text semiboldRegular">{REF}</span>
                    :
                    <div className="filter-wrap" onClick={stopDefault}>
                        <span className="selected-text semiboldRegular">{REF}</span>
                        <img className="cancel-filter" src={greyCancelX} alt="cancel filter" onClick={(e) => {
                            stopDefault(e);
                            typeFilter.transactionType.ref.set('All');
                            setTriggerUpdate(triggerUpdate + 1)
                        }} />
                    </div>
                }
            </div>
        </div>
        <img className="right-arrow" src={arrowRight} alt="selection arrow" />
    </div>

    )
}

export default MobileTransactionFilter