import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"

/**
 * the custom use hook is for the documents landing page
 */
export const useDocumentsLandingPage = () => {
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const isAccountInformationLoaded = useAppSelector((state: RootState) => state.accountInformation.isAccountInformationLoaded)

    return { accountInformation, isAccountInformationLoaded }
}