import { ToastMessage } from '../../common/ToastMessage/ToastMessage';
import { IToastMessageDBProps, TOAST_TYPE } from './IToastMessageDBProps.types';
import './ToastMessage.scss';

export const ToastMessageDB = (props: IToastMessageDBProps) => {
  const { timeout = 4000, type, onClick = () => { }, message = '', disableTimer } = props;

  const getClassName = () => {
    let className = (disableTimer !== undefined && disableTimer) ? 'toast-message no-animation' : 'toast-message ';
    switch (type) {
      case TOAST_TYPE.ERROR:
        className += ' red ';
        break;
      default:
        className += ' black ';
    }

    // if(disableTimer !== undefined && disableTimer){
    //   className += 'no-animation'
    // }
    return className;
  }
  return <ToastMessage
    className={getClassName()}
    timeout={timeout}
    onClick={onClick}
    message={message}
    disableTimer={disableTimer} />
}