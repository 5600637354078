import { CONFIG } from "./globalVariables"

/**Compares the entered routing number against the routing number block list */
export const checkBlockList = (routingNumber: string) => {
    let result: boolean = false
    for(let i = 0; i < CONFIG.BLOCKED_ROUTING_NUMBERS.length; i = i + 1){
        if(routingNumber === CONFIG.BLOCKED_ROUTING_NUMBERS[i]){
            result = true
        }
    }

    return result
}