import settings from '../../../../../assets/settings.svg';
import maturityClock from '../../../../../assets/maturityClock.svg';
import verticalElipses from '../../../../../assets/verticalElipses.svg';
import { NavOption } from '../AccountDetailsNavBar/AccountDetailsNavBar';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
/**
 * Displays the rest of the NavBar options depending on `accountType`.
 * @returns 
 */
export const AccountDetailsNavBarExtras = (props: any) => {
  const { setOpenNavBarMenu, selectedAccount, history } = props;
  const { windowState } = useWindowState();
  if (selectedAccount.accountType === 'Savings') {
    return (<>
      <div className="nav-option" onClick={() => history.push(`/user/${selectedAccount.id}/settings`, { page: "Account Details" })}>
        <NavOption src={settings} alt="Settings" title="Settings" />
      </div>
    </>)
  }

  if (selectedAccount.accountType === 'CD') {
    return (<>
      <div className="nav-option" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`, { page: "Account Details" })}>
        <NavOption src={maturityClock} alt="Maturity" title="Maturity" />
      </div>
      <div className="divider-pipe" />

      {windowState === 'Mobile' && <div className="nav-option" onClick={() => setOpenNavBarMenu(true)}>
        <img data-testid="nav-elipses" src={verticalElipses} alt="More Options" />
      </div>}

      {windowState !== 'Mobile' && <div className="nav-option" onClick={() => history.push(`/user/${selectedAccount.id}/settings`, { page: "Account Details" })}>
        <NavOption src={settings} alt="Settings" title="Settings" />
      </div>}
    </>)
  }

  return null;
}
