import MobileHeaderBox from '../MobileHeaderBox/MobileHeaderBox'
import { IMobileManageExternalAccountsProps } from './interfaces/IMobileManageExternalAccountsProps'
import './MobileManageExternalAccounts.scss'
import bluePlus from '../../../../assets/bluePlus.svg'
import { useManageExternalAccounts } from '../../../../useHooks/componentHooks/useMangeExternalAccounts/useManageExternalAccounts'
import arrowRight from '../../../../assets/arrowRight.svg'
import { IExternalAccounts } from '../../../../api/User/interfaces'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import blueEditPencil from '../../../../assets/blueEditPencil.svg'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import DeleteExternalAccount from '../DeleteExternalAccount/DeleteExternalAccount'
import MaxAccountsModal from '../MaxAccountsModal/MaxAccountsModal'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import warning from '../../../../assets/warning.svg'
import emptyTransactions from '../../../../assets/emptyTransactions.svg'
import { selectedLinkAccount } from '../../../../slices/userInformation/userInformationSlice'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'
import { checkExternalAccounts } from '../../../../utils/populateUserDetails'
import informationIconLight from '../../../../assets/informationIconLight.svg'
import MobileAlertBox from '../../../../components/MobileAlertBox/MobileAlertBox'
// import DesktopErrorAPICall from '../../../../components/ErrorAPICall/DesktopErrorAPICall/DesktopErrorAPICall'

/**render the mobile version of manage external acconts found in the user profile flow */
const MobileManageExternalAccounts = (props: IMobileManageExternalAccountsProps) => {
    const history = useHistory()
    const { setCurrentTab } = props
    const { userInformation, selectedExternalAccount, setSelectedExternalAccount, showDetails, setShowDetails, openEditName, setOpenEditName,
            openDeleteModal, setOpenDeleteModal, handleNickname, newNickname, handleSave, enableSave, setNewNickname, handleLinkExternalAccount, 
            showLimitModal, setShowLimitModal, type, message, dispatch, showTooltip, setShowTooltip } = useManageExternalAccounts()
    const gotExternalAccounts = useAppSelector((state: RootState) => state.userInformation.gotExternalAccounts)

    return (
        <div className="mobile-manage-accounts-wrap">
            {message !== "" && <ToastMessageDB message={message} type={type} />}
            {!showDetails && !openEditName &&
                <>
                    <MobileHeaderBox
                        setCurrentTab={setCurrentTab}
                        title="Manage external accounts"
                    />
                    {gotExternalAccounts !== "Loading" ?
                        <>
                        <div className="info-card-wrap">
                            {gotExternalAccounts === "Success" ?
                                <>
                                {userInformation.externalAccounts.length > 0 ?
                                    <>
                                    {userInformation.externalAccounts.map((account: IExternalAccounts) => {
                                        return (
                                            <div className="data-row gap" key={account.id} onClick={() => { setSelectedExternalAccount(account); setShowDetails(true) }}>
                                                <div className="left-side">
                                                    <span className="nickname-text semiboldRegular">{account.accountNickName}</span>
                                                    <span className="value-text small">{account.description} ****{account.lastFour}</span>
                                                    {account?.status === "Verify now" ?
                                                        <span className="pill-wrap verify smallBoldButton" onClick={() => {dispatch(selectedLinkAccount(account)); history.push(`/${account.id}/verifyexternalaccount`)}}>{account?.status}</span>
                                                        :
                                                        <>
                                                        <div className="tooltip-wrap">
                                                            <span className={account.status === "Linked" ? "pill-wrap mobile-h6" : "pill-wrap mobile-h6 processing"}>{account?.status}</span>
                                                            {account.status === "Processing" && <img src={informationIconLight} alt="tooltip icon" onClick={(e: any) => {setShowTooltip(true); e.stopPropagation()}}/>}
                                                        </div>
                                                        {showTooltip &&
                                                            <MobileAlertBox 
                                                                setStateAction={setShowTooltip}
                                                                headerMessage='What’s next?'
                                                                bodyMessage='We need to do a quick review of your account before it can be linked. This typically takes up to 1-2 business days.'
                                                            />
                                                        }
                                                        </>
                                                    }
                                                </div>
                                                <img className="edit-image" src={arrowRight} alt="edit pencil" />
                                            </div>
                                        )
                                    })}
                                    </>
                                    :
                                    <div className="message-wrapper">
                                        <img src={emptyTransactions} alt="empty account list" />
                                        <span className="message-title h4">No external accounts</span>
                                    </div>
                                }
                                <div className="add-value-wrap">
                                    <img className="add-image" src={bluePlus} alt="add account" onClick={() => handleLinkExternalAccount()} />
                                    <span className="link-text regular" onClick={() => handleLinkExternalAccount()}>Link external account</span>
                                </div>
                                </>
                                :
                                <div className="message-wrapper">
                                    <img className="message-image" src={warning} alt="could not retrieve data" />
                                    <span className="message-title h4" >Unable to load data</span>
                                    <span className="message-text regular">Please wait a moment and try again.</span>
                                </div>
                            }
                        </div>
                        {showLimitModal &&
                            <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
                        }
                        </>
                        :
                        <div className="loading-box" />
                    }
                </>
            }
            {showDetails &&
                <>
                    <div className="header-wrap">
                        <img className="image" src={arrowLeftBlue} alt="left facing blue chevron" onClick={() => setShowDetails(false)} />
                        <span className="blue-text regular" onClick={() => setShowDetails(false)}>Manage external accounts</span>
                    </div>
                    <div className="card-wrapper">
                        <span className="header h3">External account details</span>
                        <div className="label-value-wrap">
                            <span className="label boldSmall">Account</span>
                            <span className="value regular">{selectedExternalAccount?.description} ****{selectedExternalAccount?.lastFour}</span>
                        </div>
                        <div className="label-value-wrap row">
                            <div className="row-stack">
                                <span className="label boldSmall">Nickname</span>
                                <span className="value regular">{selectedExternalAccount?.accountNickName}</span>
                            </div>
                            <img className="edit-pencil" src={blueEditPencil} alt="blue edit pencil" onClick={() => { setOpenEditName(true); setShowDetails(false); setNewNickname(selectedExternalAccount?.accountNickName as string) }} />
                        </div>
                        <div className="label-value-wrap no-border">
                            <span className="label boldSmall">Routing number</span>
                            <span className="value regular">{selectedExternalAccount?.routingNumber}</span>
                        </div>
                        {((checkExternalAccounts(userInformation.externalAccounts) || selectedExternalAccount?.status === "Verify now") && selectedExternalAccount?.status !== "Processing") &&
                            <div className="remove-wrap" >
                                <img className="image" src={redTrashCan} alt="red trashcan" onClick={() => setOpenDeleteModal(true)} />
                                <span className="red-text regular" onClick={() => setOpenDeleteModal(true)}>Remove account</span>
                            </div>
                        }
                    </div>
                    {openDeleteModal &&
                        <DeleteExternalAccount
                            setOpenDeleteModal={setOpenDeleteModal}
                            selectedExternalAccount={selectedExternalAccount}
                            setSelectedExternalAccount={setSelectedExternalAccount}
                            setShowDetails={setShowDetails}
                        />
                    }
                </>
            }
            {openEditName &&
                <>
                <div className="mobile-edit-header-wrap">
                    <img className="edit-image" src={arrowLeftBlue} alt="blue colored left facing chevron" onClick={() => setOpenEditName(false)}/>
                    <span className="blue-text regular" onClick={() => setOpenEditName(false)}>External account details</span>
                </div>
                <div className="card-wrapper">
                    <span className="header border h3">Edit account nickname</span>
                    <label className="input-label-wrap small">New nickname
                        <input 
                            className="input-box regular"
                            type="text"
                            onChange={(e: any) => handleNickname(e)}
                            value={newNickname}
                            minLength={1}
                            maxLength={40}
                        />
                    </label>
                    <div className="button-wrap">
                        <Button 
                            title="Save"
                            isDisabled={!enableSave}
                            className={enableSave ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"}
                            id={TIMER_IDS.NICKNAME_EXTERNAL}
                            onClick={() => handleSave()}
                        />
                    </div>
                </div>
                </>
            }
        </div>
    )
}

export default MobileManageExternalAccounts