import { useAppSelector } from '../../app/hooks'

export const ReduxWithData = () => {
  const isAccountInformationLoaded = useAppSelector(state => state.accountInformation.isAccountInformationLoaded);
  return (
    <>
      {isAccountInformationLoaded && <div key="re" style={{ visibility: 'hidden', position: 'fixed', zIndex: -100 }} data-testid="123456_loaded"></div>}
    </>
  )

}