import axios from "axios"
import { bffBaseUrl } from "../../utils/globalVariables"

/**API put call to change the users password */
export const changePassword_API = (oldPassword: string, newPassword: string) => {
    return axios.put(`${bffBaseUrl}/customers/password/change-password`,
        {
            oldPassword: oldPassword,
            newPassword: newPassword
        },
        {            
            headers: {
                stepUpToken: localStorage.getItem("validateToken"),
                mfaToken: localStorage.getItem("timerToken")
            },
        }
    )
}