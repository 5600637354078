import { useState, useEffect } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { oktaAuth } from '../../../Routes'
import { getLogoutUri } from '../../../utils/globalVariables'

const TIMER = 900

/**A custom use hook holding the logic for the timeout modal */
export const useTimeoutModal = () => {
    const { windowState } = useWindowState()
    const [step, setStep] = useState<number>(1)
    const [countdownTimer, setCountdownTimer] = useState<number>(TIMER)
    const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false)
    const [resetTimer, setResetTimer] = useState<boolean>(false)
  
    /**This useEffect hook starts the countdown timer */
    useEffect(() => {
      const timer = setInterval(() => {
        if(countdownTimer > 0){
          setCountdownTimer((countdownTimer) => countdownTimer - 1)
        }
      }, 1000)
    
      return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * the useEffect function is used to countdown the coutdownTimer, if a click event is detected while the timeout modal is not open 
     * the timer will be reset. 
     * If the countdown gets below one minute the warning modal will appear.
     * If the timer expires the step will be icnremented to then show the locked out message
     * */
    useEffect(() => {
      if(step === 1){

        if(countdownTimer === 59){
          setShowTimeoutModal(true)
        }

        if(countdownTimer === 1){
          // setStep(2)
          if(sessionStorage.getItem("isAuthenticated")){
            sessionStorage.removeItem("isAuthenticated")
          }
          oktaAuth.signOut({ postLogoutRedirectUri: getLogoutUri })
          const token = JSON.parse(localStorage.getItem("okta-token-storage") as string)
          oktaAuth.revokeAccessToken(token.accessToken.accessToken)
          closeOktaSession()
          localStorage.removeItem("okta-token-storage")
          localStorage.removeItem("okta-cache-storage")
          localStorage.removeItem("okta-shared-transaction-storage")
          localStorage.removeItem("okta-original-uri-storage")
        }

      }

      const handleClick = () => {
        if(!showTimeoutModal && !resetTimer){
          setCountdownTimer(TIMER)
        }
      }

      document.addEventListener("click", handleClick, true)
      document.addEventListener("keydown", handleClick, true)
      return () => {document.removeEventListener('click', handleClick, true); document.removeEventListener('keydown', handleClick, true)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdownTimer])

    /**A function that handles the resetTimer */
    const handleTimerReset = () => {
      setCountdownTimer(TIMER)
      setResetTimer(false)
      setShowTimeoutModal(false)
    }

    /**Closes the Okta Session */
    const closeOktaSession = async () => {
        await oktaAuth.closeSession()
        // .then()
        // .catch()
    }

    /**Locks the scrollbar on mount, unlocks on unmount */
    useEffect(() => {
        // if(step === 2){
        // const token = JSON.parse(localStorage.getItem("okta-token-storage") as string)
        // oktaAuth.revokeAccessToken(token.accessToken.accessToken)
        // closeOktaSession()
        // localStorage.removeItem("okta-token-storage")
        // localStorage.removeItem("okta-cache-storage")
        // localStorage.removeItem("okta-shared-transaction-storage")
        // localStorage.removeItem("okta-original-uri-storage")
        // }

        if(showTimeoutModal){
          document.body.style.overflow = "hidden";
        }
        return () => {document.body.style.overflow = "auto"}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, showTimeoutModal])

    /**Logs out the user and navigates to the okta login page */
    const oktaLogout = async () =>  {
        oktaAuth.signOut({ postLogoutRedirectUri: getLogoutUri })
    }


    return { windowState, step, countdownTimer, showTimeoutModal, resetTimer, handleTimerReset, setResetTimer, oktaLogout}
}