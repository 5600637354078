import './DeleteExternalAccount.scss'
import iconAddBankDelete from '../../../../assets/iconAddBankDelete.svg'
import { IDeleteExternalAccountProps } from './interfaces/IDeleteExternalAccountProps'
import { useDeleteExternalAccount } from '../../../../useHooks/componentHooks/useDeleteExternalAccount/useDeleteExternalAccount'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders the modal for deleting an external account */
const DeleteExternalAccount = (props: IDeleteExternalAccountProps) => {
    const { setOpenDeleteModal, selectedExternalAccount, setShowDetails } = props
    const { windowState, handleRemove } = useDeleteExternalAccount(setOpenDeleteModal, setShowDetails)

    return (
        <div className="delete-external-account-overlay">
            <div className={`modal-details ${windowState}`}>
                <img className="image" src={iconAddBankDelete} alt="blue bank with delete icon"/>
                <span className="header-text h4">Remove {selectedExternalAccount?.accountNickName} from external accounts?</span>
                <span className="sub-text regular">
                    {selectedExternalAccount?.status.toLocaleLowerCase() !== "verify now" ?
                        "This action cannot be undone."
                        :
                        "Your pending bank account verification will be canceled. This action cannot be undone."
                    }</span>
                {windowState !== "Mobile" ?
                    <div className="button-wrap">
                        <button className="Button btn-secondary" onClick={() => setOpenDeleteModal(false)}>Cancel</button>
                        <Button 
                            title="Remove account"
                            isDisabled={false}
                            className="Button btn-primary"
                            id={TIMER_IDS.DELETE_EXTERNAL}
                            onClick={() => handleRemove(selectedExternalAccount)}
                        />
                    </div>
                    :
                    <div className="button-wrap mobile">
                        <Button 
                            title="Remove account"
                            isDisabled={false}
                            className="Button btn-primary mobile"
                            id={TIMER_IDS.DELETE_EXTERNAL}
                            onClick={() => handleRemove(selectedExternalAccount)}
                        />
                        <button className="Button btn-secondary mobile" onClick={() => setOpenDeleteModal(false)}>Cancel</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default DeleteExternalAccount