import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useUserProfilePage } from "../../useHooks/pageHooks/useUserProfilePage"
import { UserProfileContextProvider } from "./Context/useUserProfileContext"
import DesktopUserProfilePage from "./DesktopUserProfilePage/DesktopUserProfilePage"
import MobileUserProfilePage from "./MobileUserProfilePage/MobileUserProfilePage"

/**
 * The user profile wrapper page that determines which version should be displayed ie:(desktop, hybrid, mobile)
 */
const UserProfileWrapper = () => {
    const { windowState, currentTab, setCurrentTab, availableTabs, openEditPage, setOpenEditPage, addressAction, setAddressAction } = useUserProfilePage()

    return (
        <div>
            <UserProfileContextProvider>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopUserProfilePage 
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            availableTabs={availableTabs}
                            openEditPage={openEditPage}
                            setOpenEditPage={setOpenEditPage}
                            addressAction={addressAction}
                            setAddressAction={setAddressAction}
                        />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopUserProfilePage 
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            availableTabs={availableTabs}
                            openEditPage={openEditPage}
                            setOpenEditPage={setOpenEditPage}
                            addressAction={addressAction}
                            setAddressAction={setAddressAction}
                        />
                    </HybridLayout>
                }
                </>
                :
                <MobileUserProfilePage 
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    openEditPage={openEditPage}
                    setOpenEditPage={setOpenEditPage}
                    addressAction={addressAction}    
                    setAddressAction={setAddressAction}
                />
            }
            </UserProfileContextProvider>
        </div>
    )
}

export default UserProfileWrapper