/* tslint:disable */
/* eslint-disable */
/**
 * Savings BFF API
 * This is BFF savings API
 *
 * OpenAPI spec version: 1.0.32
 * Contact: savingsbff@creditone.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {

} from ".";

export enum LINKING_STATUS {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    NOT_CREATED = 'Pending_Review',
    PENDING_CREATION = "Pending_Case_Creation",
    PENDING_USER_CREATION = "Pending_User_Selection",
    PENDING_VERIFICATION = "Pending",
    FRAUD = "FRAUD"
}

/**
 * 
 *
 * @export
 * @interface VerifiedAccounts
 */
export interface VerifiedAccounts {

    /**
     * @type {string}
     * @memberof VerifiedAccounts
     * @example 8473
     */
    accountNumberLastFourDigit?: string;

    /**
     * @type {string}
     * @memberof VerifiedAccounts
     */
    accountType?: string;

    /**
     * @type {string}
     * @memberof VerifiedAccounts
     */
    routingNumber?: string;

    /**
     * @type {string}
     * @memberof VerifiedAccounts
     * @example 10000
     */
    balance?: string;

    /**
     * @type {string}
     * @memberof VerifiedAccounts
     * @example Bank of America
     */
    bankName?: string;

    /**
     * @type {boolean}
     * @memberof VerifiedAccounts
     */
    isAlreadyExist?: boolean;

    /**
     * @type {string}
     * @memberof VerifiedAccounts
     * @example 14336258
     */
    externalAccountId?: string;

    /**
     * @type {string}
     * @memberof VerifiedAccounts
     * @example College funds
     */
    accountNickName?: string;

    status: LINKING_STATUS
}
