import { useEffect, useState } from "react"

/**The custom usehook is used for the desktop version of the Document Center Header component */
export const useDesktopDocumentCenterHeader = (selectedPage: any) => {
    const [focusIndex, setFocusIndex] = useState<number>(0)

    /**Set's the correct index for highlighting the selected tab */
    useEffect(() => {
        switch(selectedPage){
            case "Statements":
            {
                setFocusIndex(0)
                break
            }
            case "Tax documents":
            {
                setFocusIndex(1)
                break
            }
            case "Agreements":
            {
                setFocusIndex(2)
                break
            }
            case "Notices & other documents":
            {
                setFocusIndex(3)
                break
            }
            default:
            {
                setFocusIndex(0)
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedPage])

    return { focusIndex, setFocusIndex }
}