import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { useWindowState } from '../../Context/AccountContext/useWindowState';

/**
 * use hook for account overview page that breaks accounts up into categories
 * @returns creditCardAccounts, CDAccounts, savingsAccounts 
 */
export const useAccountOverview = () => {
  const { windowState } = useWindowState();
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
  const isAccountInformationLoaded = useAppSelector((state: RootState) => state.accountInformation.isAccountInformationLoaded)
  const totalSavingsBalance = useAppSelector((state: RootState) => state.accountInformation.totalSavingsBalance)
  const totalCDBalance = useAppSelector((state: RootState) => state.accountInformation.totalCDBalance)
  const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
  const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)
  const [openRatesModal, setOpenRatesModal] = useState<boolean>(false)

  let creditCardAccounts: any = [];
  let CDAccounts: any = [];
  let savingsAccounts: any = [];
  const accounts = accountInformation;
  if (accounts) {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].accountType === 'Credit Card') {
        creditCardAccounts.push(accounts[i]);
      }
      if (accounts[i].accountType === 'Single' || accounts[i].accountType === "Bump" || accounts[i].accountType === "CD") {
        CDAccounts.push(accounts[i]);
      }
      if (accounts[i].accountType === 'Savings') {
        savingsAccounts.push(accounts[i]);
      }
    }
  }

  return { creditCardAccounts, CDAccounts, savingsAccounts, windowState, isAccountInformationLoaded, totalSavingsBalance, totalCDBalance, 
           openRatesModal, setOpenRatesModal, isAccountDetailsLoaded, gotUserContactInfo };
};

