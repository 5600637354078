import { useState } from "react";
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop";

 /**
 * JS DOC for useBeneficiaryEditProfile 
 * @return 
 */ 
export const useBeneficiaryEditProfile = () => {  
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [editPerson, setEditPerson] = useState<boolean>(false)
    const [displayOnly, setDisplayOnly] = useState<boolean>(true)
    useScrollToTop()

    return { editPerson, setEditPerson, displayOnly, setDisplayOnly }
}; 
