import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import { IMobileUserProfileProps } from './interfaces/IMobileUserProfileProps'
import './MobileUserProfilePage.scss'
import editPencil from '../../../assets/editPencil.svg'
import contactInfo from '../../../assets/contactInfo.svg'
import lock from '../../../assets/lock.svg'
import userProfileDocument from '../../../assets/userProfileDocument.svg'
import notificationBell from '../../../assets/notificationBell.svg'
import bankGrey from '../../../assets/bankGrey.svg'
import arrowRight from '../../../assets/arrowRight.svg'
import { oktaAuth } from '../../../Routes'
import { getLogoutUri } from '../../../utils/globalVariables'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useMobileProfileLanding } from '../../../useHooks/componentHooks/useMobileProfileLanding/useMobileProfileLanding'
import MobileContactPage from '../components/MobileContactPage/MobileContactPage'
import MobileSecuritySettings from '../components/MobileSecuritySettings/MobileSecuritySettings'
import MobileTaxWithholding from '../components/MobileTaxWithholding/MobileTaxWithholding'
import MobileManageExternalAccounts from '../components/MobileManageExternalAccounts/MobileManageExternalAccount'
import MobileNotificationSettings from '../components/MobileNotificationSettings/MobileNotificationSettings'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'

/**
 * Renders the component for the Mobile version of the user profile page.  All tabs are wrapped in this page file.
 * The tab that is to be displayed first will be determined by the last section on the URL 
 */
const MobileUserProfilePage = (props: IMobileUserProfileProps) => {
    const { currentTab, setCurrentTab, openEditPage, setOpenEditPage, addressAction, setAddressAction } = props
    const { newNickname, handleUpdateNickname, userInformation, onChangeNickname, editNickname, setEditNickname, handleCancelOnClick } = useMobileProfileLanding()
    const history = useHistory()
    const oktaLogout = async () => {
        if(sessionStorage.getItem("isAuthenticated")){
            sessionStorage.removeItem("isAuthenticated")
        }
        oktaAuth.signOut({ postLogoutRedirectUri: getLogoutUri })
    }
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)

    /** When the user navigates to a new route using the option buttons, the current tab will change accordingly */
    useEffect(() => {
        if (history.location !== undefined) {
            const route = history.location.pathname as string
            switch (route) {
                case '/user/profile/contact':
                    setCurrentTab("Contact information");
                    break;
                case '/user/profile/external-accounts':
                    setCurrentTab("Manage external accounts");
                    break;
                case '/user/profile/tax':
                    setCurrentTab("Tax withholdings")
                    break;
                case '/user/profile/security':
                    setCurrentTab("Security settings")
                    break;
                case '/user/profile/notifications':
                    setCurrentTab("Notification settings");
                    break;
                case '/user/profile':
                    setCurrentTab("");
                    break;
            }
        }
        // Disabling eslint since we only want to update this useEffect when location updates and nothing else 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location])

    return (
        <MobileLayout>
            <>
                {currentTab === "" ?
                    <div className="mobile-user-profile-wrapper">
                        {editNickname &&
                            <div className="overlay-wrap">
                                <div className="edit-box">
                                    <div className="header-row">
                                        <span className="blue-text regular" onClick={() => handleCancelOnClick()}>Cancel</span>
                                        <span className="header-text semiboldRegular">Greeting name</span>
                                        <span className="blue-text regular" onClick={() => handleUpdateNickname()}>Save</span>
                                    </div>
                                    <div className="input-wrap">
                                        <label className="input-label small">Greeting name
                                            <input
                                                className="input-box regular"
                                                type="text"
                                                onChange={(e: any) => onChangeNickname(e)}
                                                value={newNickname}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="mobile-profile-header-wrap">
                            {gotUserContactInfo !== "Loading" ?
                                <div className="top-row">
                                    <span className="user-name h3" onClick={() => setEditNickname(true)}>Hi, {userInformation.nickname}</span>
                                    <img className="edit-icon" src={editPencil} alt="edit pencil" onClick={() => setEditNickname(true)} data-testid="edit-nickname" />
                                </div>
                                :
                                <div className="loading-box" />
                            }
                            {/* <span className="customer-since-text small">Customer since {convertDateTommddyyyy(userInformation.customerSince)}</span> */}
                        </div>
                        <div className="bottom-card">
                            <div className="row-wrapper" onClick={() => history.push('/user/profile/contact')}>
                                <div className="left-side">
                                    <img className="tab-image" src={contactInfo} alt="contact information" />
                                    <span className="tab-text regular">Contact Information</span>
                                </div>
                                <img className="arrow-image" src={arrowRight} alt="select tab" />
                            </div>
                            <div className="border" />
                            <div className="row-wrapper" onClick={() => history.push('/user/profile/security')}>
                                <div className="left-side">
                                    <img className="tab-image" src={lock} alt="lock" />
                                    <span className="tab-text regular">Security Settings</span>
                                </div>
                                <img className="arrow-image" src={arrowRight} alt="select tab" />
                            </div>
                            <div className="border" />
                            <div className="row-wrapper" onClick={() => history.push('/user/profile/tax')}>
                                <div className="left-side">
                                    <img className="tab-image" src={userProfileDocument} alt="document" />
                                    <span className="tab-text regular">Tax Information</span>
                                </div>
                                <img className="arrow-image" src={arrowRight} alt="select tab" />
                            </div>
                            <div className="border" />
                            <div className="row-wrapper" onClick={() => history.push('/user/profile/external-accounts')}>
                                <div className="left-side">
                                    <img className="tab-image" src={bankGrey} alt="external accounts" />
                                    <span className="tab-text regular">Manage External Accounts</span>
                                </div>
                                <img className="arrow-image" src={arrowRight} alt="select tab" />
                            </div>
                            <div className="border" />
                            <div className="row-wrapper" onClick={() => history.push('/user/profile/notifications')}>
                                <div className="left-side">
                                    <img className="tab-image" src={notificationBell} alt="ringer" />
                                    <span className="tab-text regular">Notification Settings</span>
                                </div>
                                <img className="arrow-image" src={arrowRight} alt="select tab" />
                            </div>
                        </div>
                        <div className="sign-out-wrapper">
                            <span className="sign-out-text small" onClick={(() => oktaLogout())}>Sign Out</span>
                        </div>
                    </div>
                    :
                    <>
                        {currentTab === "Contact information" &&
                            <MobileContactPage
                                setCurrentTab={setCurrentTab}
                                addressAction={addressAction}
                                setAddressAction={setAddressAction}
                                openEditPage={openEditPage}
                                setOpenEditPage={setOpenEditPage}
                            />
                        }
                        {currentTab === "Security settings" &&
                            <MobileSecuritySettings
                                setCurrentTab={setCurrentTab}
                            />
                        }
                        {currentTab === "Tax withholdings" &&
                            <MobileTaxWithholding setCurrentTab={setCurrentTab} />
                        }
                        {currentTab === "Manage external accounts" &&
                            <MobileManageExternalAccounts setCurrentTab={setCurrentTab} />
                        }
                        {currentTab === "Notification settings" &&
                            <MobileNotificationSettings setCurrentTab={setCurrentTab} />
                        }
                    </>
                }
            </>
        </MobileLayout>
    )
}

export default MobileUserProfilePage