import { useHistory } from "react-router-dom"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useMaturitySettings } from "../../useHooks/pageHooks/useMaturitySettings"
import DesktopMaturitySettings from "./DesktopMaturitySettings/DesktopMaturitySettings"
import MobileMaturitySettings from "./MobileMaturitySettings/MobileMaturitySettings"

/**The Maturity Settings Wrapper determines which display should be used (Desktop, Mobile, Hybrid) */
const MaturitySettingsWrapper = () => {
    const { windowState, planChange, setPlanChange, termChange, setTermChange, manageFunds, setManageFunds,
            openCancelFunds, setOpenCancelFunds, changePlanOption, setChangePlanOption, openChangeOption, setOpenChangeOption,
            chosenTerm, setChosenTerm, showTermChange, setShowTermChange, firstStepCompleted, setFirstStepCompleted,
            transferType, setTransferType, CDProductList, bumpProductList, selectedAccount, transferAccount, setTransferAccount } = useMaturitySettings()

    const history = useHistory();

    if(selectedAccount.accountClosed || selectedAccount.pendingGraceClosure) {
        history.push(`/accounts/${selectedAccount.id}`);
    }

    return(
        <div>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopMaturitySettings 
                            planChange={planChange}
                            setPlanChange={setPlanChange}
                            termChange={termChange}
                            setTermChange={setTermChange}
                            manageFunds={manageFunds}
                            setManageFunds={setManageFunds}
                            openCancelFunds={openCancelFunds}
                            setOpenCancelFunds={setOpenCancelFunds}
                            changePlanOption={changePlanOption}
                            setChangePlanOption={setChangePlanOption}
                            openChangeOption={openChangeOption}
                            setOpenChangeOption={setOpenChangeOption}
                            chosenTerm={chosenTerm}
                            setChosenTerm={setChosenTerm}
                            showTermChange={showTermChange}
                            setShowTermChange={setShowTermChange}
                            transferType={transferType} 
                            setTransferType={setTransferType}
                            CDProductList={CDProductList}
                            bumpProductList={bumpProductList}
                            transferAccount={transferAccount}
                            setTransferAccount={setTransferAccount}
                        />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopMaturitySettings 
                            planChange={planChange}
                            setPlanChange={setPlanChange}
                            termChange={termChange}
                            setTermChange={setTermChange}
                            manageFunds={manageFunds}
                            setManageFunds={setManageFunds}
                            openCancelFunds={openCancelFunds}
                            setOpenCancelFunds={setOpenCancelFunds}
                            changePlanOption={changePlanOption}
                            setChangePlanOption={setChangePlanOption}
                            openChangeOption={openChangeOption}
                            setOpenChangeOption={setOpenChangeOption}
                            chosenTerm={chosenTerm}
                            setChosenTerm={setChosenTerm}
                            showTermChange={showTermChange}
                            setShowTermChange={setShowTermChange}
                            transferType={transferType} 
                            setTransferType={setTransferType}
                            CDProductList={CDProductList}
                            bumpProductList={bumpProductList}
                            transferAccount={transferAccount}
                            setTransferAccount={setTransferAccount}
                        />
                    </HybridLayout>
                }
                </>
                :
                <MobileMaturitySettings 
                    planChange={planChange}
                    setPlanChange={setPlanChange}
                    termChange={termChange}
                    setTermChange={setTermChange}
                    manageFunds={manageFunds}
                    setManageFunds={setManageFunds}
                    openCancelFunds={openCancelFunds}
                    setOpenCancelFunds={setOpenCancelFunds}  
                    changePlanOption={changePlanOption}
                    setChangePlanOption={setChangePlanOption}
                    openChangeOption={openChangeOption}
                    setOpenChangeOption={setOpenChangeOption}
                    chosenTerm={chosenTerm}
                    setChosenTerm={setChosenTerm}
                    showTermChange={showTermChange}
                    setShowTermChange={setShowTermChange}
                    firstStepCompleted={firstStepCompleted}
                    setFirstStepCompleted={setFirstStepCompleted}
                    transferType={transferType}
                    setTransferType={setTransferType}
                    CDProductList={CDProductList}
                    bumpProductList={bumpProductList}
                    transferAccount={transferAccount}
                    setTransferAccount={setTransferAccount}
                />
            }
        </div>
    )
}

export default MaturitySettingsWrapper