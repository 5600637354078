import { BeneficiaryCard } from '../../../../../components/BeneficiaryCard/BeneficiaryCard';
// import EarlyWithdrawalCard from '../../../../../components/EarlyWithdrawalCard/EarlyWithdrawalCard';
import { MaturityCard } from '../../../../../components/MaturityCard/MaturityCard';
import { StatementsCard } from '../../../../../components/StatementsCard/StatementsCard';
import openNewAccount from '../../../../../assets/openNewAccount.svg';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import { Button } from '../../../../../components/Button/Button';
import { getSAMLURL } from '../../../../../shared/config/getSAMLUrl';
import { useOpenNewAccount } from '../../../../../Context/OpenNewAccountContext/useOpenNewAccountContext';


/**
 * The Mobile Account Details Summary Cards
 * @param props 
 * @returns 
 */
export const AccountDetailsSummaryCards = (props: any) => {
  const { selectedAccount } = props;
  const { windowState } = useWindowState();

  if (windowState === 'Mobile') {
    return <AccountDetailsSummaryCardsMobile
      selectedAccount={selectedAccount}
    />
  }

  return <AccountDetailsSummaryCardsDesktop
    selectedAccount={selectedAccount}
  />
}


const AccountDetailsSummaryCardsMobile = (props: any) => {
  const { selectedAccount } = props;

  if (!selectedAccount.accountClosed && !selectedAccount.pendingGraceClosure) {
    return (<div className="Summary-Cards">
      {selectedAccount.accountType !== "Savings" &&
        <>
          {!selectedAccount.earlyWithdrawalSelected &&
            <MaturityCard selectedAccount={selectedAccount} />
            // :
            // <EarlyWithdrawalCard selectedAccount={selectedAccount} />
          }
        </>
      }
      <div className="Summary-Beneficiary">
        <BeneficiaryCard />
      </div>
      <StatementsCard selectedAccount={selectedAccount} />
    </div>)
  }

  return <NewAccountCard />
}


const AccountDetailsSummaryCardsDesktop = (props: any) => {
  const { selectedAccount } = props;

  if (!selectedAccount.accountClosed && !selectedAccount.pendingGraceClosure) {
    return (<div className={selectedAccount.accountType !== "Savings" ? "Summary-Card-Wrapper" : "Summary-Card-Wrapper savings"}>
      {selectedAccount.accountType !== "Savings" &&
        <>
          {!selectedAccount.earlyWithdrawalSelected &&
            <MaturityCard selectedAccount={selectedAccount} />
            // :
            // <EarlyWithdrawalCard selectedAccount={selectedAccount} />
          }
        </>
      }
      <BeneficiaryCard />
      <StatementsCard selectedAccount={selectedAccount} />
    </div>)
  }

  return <NewAccountCard />
}



const NewAccountCard = () => {
  const { windowState } = useWindowState();
  const wrapperClassName = windowState === 'Mobile' ? 'open-new-account-card' : 'open-new-account-card open-account-card-wrap'
  const titleClassName = windowState === 'Mobile' ? 'title-text mobileh4' : 'title-text h4'
  const subTitleClassName = windowState === 'Mobile' ? 'sub-title-text small' : 'sub-title-text regular'
  const buttonClassName = windowState === 'Mobile' ? 'Button btn-primary mobile' : 'Button btn-primary'
  const { checkExternalAccounts } = useOpenNewAccount()

  return (<div className={wrapperClassName}>
    <img src={openNewAccount} alt="open new account" />
    <span className={titleClassName}>Ready to grow your money?</span>
    <span className={subTitleClassName}>With a range of banking products, Credit One Bank offers the right fit for your financial goals. Don't settle for less—open a new account today and start making your money work harder for you.</span>
    <Button
      className={buttonClassName}
      title="Open a new account"
      onClick={checkExternalAccounts}
    />
  </div>
  )
}