import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"

/**custom hook for the layout pages, desktop, hybrid, and mobile */
export const usePageLayout = () => {
    const { pathname } = useLocation()
    const isAccountInformationLoaded = useAppSelector((state: RootState) => state.accountInformation.isAccountInformationLoaded)
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    /**the useEffect sets the scroll to the top of the page when pathname changes */
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])


    return { isAccountInformationLoaded, gotUserContactInfo, isAccountDetailsLoaded }
}