import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { getUserCurrentDate } from "../../../utils/DateUtils"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { getTodaysDate } from "../../../utils/getCurrentDate"
import { setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { IAccountInformation } from "../../../api/getAccountInformation"

/**A custom use hook holding the logic for the createTransferLanding component */
export const useCreateTransferLanding = (
        selectedFromAccount: any, 
        selectionOptions: any, 
        selectedToAccount: any,
        setSelectedToAccount: any, 
        setTransferAmount: any, 
        transferAmount: number | undefined,
        transferDate: any,
        setTransferDate: any,
        topCardIndex: number | undefined,
        setTopCardIndex: any,
        setSelectedFromAccount: any
        ) => {
    const [transferToArray, setTransferToArray] = useState<Array<{title: string, type: "Savings" | "", lastFour: string, accountID: string, balance: number}>>([])
    const [amountInputError, setAmountInputError] = useState<boolean>(false)
    const [enableContinue, setEnableContinue] = useState<boolean>(false)
    const [maxTransferError, setMaxTransferError] = useState<boolean>(false)
    const [dateInputError, setDateInputError] = useState<boolean>(false)
    const history = useHistory()
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [totalBalance, setTotalBalance] = useState<number>(0)
    const [minDate, setMinDate] = useState<string>(getUserCurrentDate())
    const [isAmountErrored, setIsAmountErrored] = useState<boolean>(false)
    useScrollToTop()
    const gotExternalAccounts = useAppSelector((state: RootState) => state.userInformation.gotExternalAccounts)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const openOTPModal = useAppSelector((state: RootState) => state.userInformation.openOTPModal)
    const linkExternalClickedRef = useRef<boolean>(false)
    const dispatch = useAppDispatch()
    const [maxAccountError, setMaxAccountError] = useState<boolean>(false)
    const [showMaxExternalAccountsModal, setShowMaxExternalAccountsModal] = useState<boolean>(false)
    const externalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)

    useEffect(() => {
        if(!openOTPModal) {
            linkExternalClickedRef.current = false
        }
    }, [openOTPModal])

    const IS_TRANSFER_DATE_EMPTY = transferDate === '';
    /**Default the transfer date to todays date on render */
    useEffect(() => {
        if(IS_TRANSFER_DATE_EMPTY){
            setTransferDate(getTodaysDate())
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IS_TRANSFER_DATE_EMPTY])

    /**If any errors are existing that are amount input box related set the overall error to true */
    useEffect(() => {
        if(amountInputError || maxTransferError || maxAccountError){
            setIsAmountErrored(true)
        }
        else{
            setIsAmountErrored(false)
        }

    }, [amountInputError, maxTransferError, maxAccountError])

    /**Calculate the total balance of all accounts */
    useEffect(() => {
        if(accountInformation.length && accountInformation[0].id !== ""){
            let tempBalance: number = 0
            for(let i = 0; i < accountInformation.length; i = i + 1){
                tempBalance = tempBalance + accountInformation[i].balance
            }
            setTotalBalance(tempBalance)
        }
    }, [accountInformation])

    /**
     * When transfer from is an external account we need to remove external accounts from the
     * selectOptions array provided to the Transfer To selection drop down.  This is because
     * we do not allow external account -> external account transfers. The external accounts 
     * have a type of ""
    */
   useEffect(() => {
        if(selectedFromAccount.accountType === "External"){
            let tempAccountArray: Array<{title: string, type: "Savings" | "", lastFour: string, accountID: string, balance: number}> = []
            for(let i = 0; i < selectionOptions.length; i = i + 1){
                if(selectionOptions[i].title === "EXTERNAL ACCOUNTS"){
                    break;
                }
                else{
                    tempAccountArray.push(selectionOptions[i])
                }
            }
            setTransferToArray(tempAccountArray)
        }
        else if(selectedFromAccount.accountType === "Internal"){
            let tempAccountArray: Array<{title: string, type: "Savings" | "", lastFour: string, accountID: string, balance: number}> = []
            for(let i = 0; i < selectionOptions.length; i = i + 1){
                if(selectionOptions[i].accountID === selectedFromAccount.accountID){
                    continue
                }
                tempAccountArray.push(selectionOptions[i])
            }
            setTransferToArray(tempAccountArray)
        }
        if(selectedFromAccount.lastFour === selectedToAccount.lastFour){
            setSelectedToAccount({title: "", type: "", lastFour: "", accoutID: "", balance: 0})
        }

        if(selectedFromAccount.accountType === "External" && selectedToAccount.accountType === "External"){
            setSelectedToAccount({title: "", type: "", lastFour: "", accoutID: "", balance: 0})
        }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedFromAccount])

   useEffect(() => {
        if(selectedFromAccount.accountType === "External" && selectedToAccount.accountType === "External"){
            setSelectedFromAccount({title: "", type: "", lastFour: "", accoutID: "", balance: 0})
        }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedToAccount])

   /**Checks for error in the event that the amount field has been entered and the from account haas been changed */
   useEffect(() => {
        if(transferAmount && (transferAmount > selectedFromAccount.balance) && selectedFromAccount.accountType !== "External"){
            setAmountInputError(true)
        }
        else{
            setAmountInputError(false)
        }
        if(transferAmount && (transferAmount > 1100000)){
            setMaxTransferError(true)
        }
        else{
            setMaxTransferError(false)
        }
        let foundInternalIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedToAccount.id)
        if(foundInternalIndex >= 0 && transferAmount && (transferAmount + accountInformation[foundInternalIndex].balance > 1500000)){
            setMaxAccountError(true)
        }
        else{
            setMaxAccountError(false)
        }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedFromAccount, selectedToAccount])

   /**Checks for errors when the date input has been entered */
   useEffect(() => {
        if(transferDate !== ""){
            const selectedDay = transferDate.split('-')[2]
            const selectedMonth = transferDate.split('-')[1]
            const selectedYear = transferDate.split('-')[0]
            const date = new Date()
            const today = date.getDate()
            const month = date.getMonth()
            const year = date.getFullYear()
            if((+selectedDay < today && +selectedMonth <= month + 1 && +selectedYear <= year) || (+selectedDay <= today && +selectedMonth < month + 1 && +selectedYear <= year) || (+selectedDay > today && +selectedMonth < month + 1 && +selectedYear <= year) || +selectedYear < year){
                setDateInputError(true)
            }
            else{
                setDateInputError(false)
            }
        }
   }, [transferDate])

   /**Handles the onChange event for the Amount number input */
   const amountChangeHandler = (e: any) => {
        if(+e.target.value !== 0){
            setTransferAmount(+e.target.value)
        }
        else{
            setTransferAmount(undefined)
        }
        if(parseFloat(e.target.value) > selectedFromAccount.balance && selectedFromAccount.accountType !== "External"){
            setAmountInputError(true)
        }
        else{
            setAmountInputError(false)
        }
        if(parseFloat(e.target.value) > 1100000){
            setMaxTransferError(true)
        }
        else{
            setMaxTransferError(false)
        }
        let foundInternalIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedToAccount.id)
        if(foundInternalIndex >= 0 && (parseFloat(e.target.value) + accountInformation[foundInternalIndex].balance > 1500000)) {
            setMaxAccountError(true)
        }
        else{
            setMaxAccountError(false)
        }
        // if(selectedFromAccount.accountType === "Internal")
   }

   /**Handles the onKeyDown event for the Amount number input*/
   const handleOnKeyDown = (e: any) => {
       (e.key  === 'e' || e.key === '-' || e.key === '+') && e.preventDefault()
        const decimalSize = e.target.value.split(".")[1]
        if(decimalSize && decimalSize.length > 1 && (e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Delete" && e.key !== "ArrowUp" && e.key !== "ArrowDown")){
            e.preventDefault()
        }
        if(+e.target.value === 0 && e.key === "0"){
            e.preventDefault()
        }
   }

   /**Handles the onChange event for the date input component */
   const dateChangeHandler = (e: any) => {
        setTransferDate(e.target.value)
   }

   /**Handles checking all validation and enabling the Continue button */
   useEffect(() => {
        if(selectedFromAccount.title !== "" && selectedToAccount.title !== "" && (transferAmount as number) > 0 && transferDate !== ""){
            if(!amountInputError && !maxTransferError && !dateInputError && !maxAccountError){
                setEnableContinue(true)
            }
            else{
                setEnableContinue(false)
            }
        }
        else{
            setEnableContinue(false)
        }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedFromAccount, selectedToAccount, transferAmount, transferDate, dateInputError])

   useEffect(() => {
    if(selectedFromAccount.title !== "" && selectedToAccount.title !== "" && (transferAmount as number) > 0 && transferDate !== ""){
        let foundInternalIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedToAccount.id)
        if(transferAmount && (transferAmount > selectedFromAccount.balance) && selectedFromAccount.accountType !== "External"){
            setEnableContinue(false)
        }
        else if(transferAmount && transferAmount > 1100000){
            setEnableContinue(false)
        }
        else if(foundInternalIndex >= 0 && transferAmount && (transferAmount + accountInformation[foundInternalIndex].balance > 1500000)){
            setEnableContinue(false)
        }
        else{
            setEnableContinue(true)
        }
    }
    else{
        setEnableContinue(false)
    }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   /**Handles the onClick event of the continue button */
   const handleContinue = () => {
        if(enableContinue && topCardIndex !== undefined && setTopCardIndex !== undefined){
            setTopCardIndex(topCardIndex + 1)
        }
   }

   /**handles the onClick for the link external account button */
   const handleLinkExternalAccount = async () => {
        if(!linkExternalClickedRef.current){
            if(externalAccounts.length > 2){
                setShowMaxExternalAccountsModal(true)
            }
            else{
                
                linkExternalClickedRef.current = true
                dispatch(setOTPPassed(false))
                dispatch(setCheckInternalToken(true))
            }
        }

   }

   /**monitors the OTPPassed status and routes to link external accounts if successful */
   useEffect(() => {

        if(OTPPassed && linkExternalClickedRef.current){
            dispatch(setOTPPassed(false))
            dispatch(setCheckInternalToken(true))
            history.push("/linkexternalaccount", {page: "Transfers"})
        }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [OTPPassed])



   return { transferToArray, amountChangeHandler, handleOnKeyDown, amountInputError, enableContinue, minDate, setMinDate,
            maxTransferError, dateChangeHandler, dateInputError, history, isAmountErrored, handleContinue, gotExternalAccounts,
            handleLinkExternalAccount, maxAccountError, showMaxExternalAccountsModal, setShowMaxExternalAccountsModal }
}