import { useEffect, useRef, useState } from "react"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { removeGraceFundTransferByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { removeGraceFundTransfer } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { getEventPath } from "../../../utils/getEventPath"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { removeTransfer_API } from "../../../api/transfers"

/**A custom use hook for the Grace Period Cancel Fund Transfer modal */
export const useGraceCancelFunds = (setOpenCancelFunds: any) => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const modalRef = useRef<any>()
    const yesClickedRef = useRef<boolean>(false)
    const { setToastMessage, resetToast } = useToastMessage()
    const [transferErrored, setTransferErrored] = useState<boolean>(false)

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-wrapper")) === -1){
                document.body.style.overflow = "auto";
                setOpenCancelFunds(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Lock down the scroll on render */
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {document.body.style.overflow = "auto"}
    }, [])

    /**Handles the onClick event for the Yes, Remove Transfer button */
    const handleOnYes = async () => {
        if(!yesClickedRef.current){
            yesClickedRef.current = true
            try{
                await removeTransfer_API(selectedAccount.gracePeriodFundTransfer.transferId)
                const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                dispatch(removeGraceFundTransfer())
                if(arrayIndex >= 0){
                    dispatch(removeGraceFundTransferByIndex(arrayIndex))
                }
                document.body.style.overflow = "auto";
                setToastMessage("SUCCESS", "Transfer cancelled")
                resetToast()
                setOpenCancelFunds(false)
            }
            catch(error: any){
                let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
                if(errorReturn !== "" && errorReturn.data.errorMsg[0] === "Validation failed for cancel order, Caused by: Order is completed and cannot be canceled"){
                    setTransferErrored(true)
                    const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
                    dispatch(removeGraceFundTransfer())
                    if(arrayIndex >= 0){
                        dispatch(removeGraceFundTransferByIndex(arrayIndex))
                    }    
                }
                else{
                    console.log("UNABLE TO CANCEL THE GRACE PERIOD TRANSFER")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()
                }    
            }
            yesClickedRef.current = false
        }
    }

    /**Handles the onClick event for the No button */
    const handleOnNo = () => {
        document.body.style.overflow = "auto";
        setOpenCancelFunds(false)
    }

    /**handles the close button when transfer cancel error modal appears */
    const handleClose = () => {
        document.body.style.overflow = "auto"
        setTransferErrored(false)
        setOpenCancelFunds(false)
    }

    return { selectedAccount, modalRef, handleOnYes, handleOnNo, transferErrored, handleClose }
}