import { IPaginationComponentProps } from './interfaces/IPaginationComponentProps';
import './PaginationComponent.scss';
// import actvieLeftArrow from '../../assets/arrowDoubleLeftBlue.svg';
import greyRightArrow from '../../assets/greyRightArrow.svg';
import greyLeftArrow from '../../assets/greyLeftArrow.svg';
import { SelectDropdown } from '../SelectDropdown/SelectDropdown';
import { usePaginationComponent } from '../../useHooks/componentHooks/usePaginationComponent/usePaginationComponent';

/**
 * Pagination component
 * @param itemType the type of item that will be displayed
 * @param totalItems the total number of items
 */
export const PaginationComponent = (props: IPaginationComponentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { itemType, totalItems, setStartIndex, setEndIndex, type, numberOfItemsToShow, setNumberOfItemsToShow, spaceBetween = false } = props;
  const {
    optionsArray,
    currentPage,
    setCurrentPage,
    pagesArray,
    totalPages,
    getItemNumbersOnPage,
    windowState
  } = usePaginationComponent(itemType, totalItems, setStartIndex, setEndIndex, numberOfItemsToShow, setNumberOfItemsToShow);

  return (
    <>
    {windowState !== "Mobile" ?
      <div className={spaceBetween ? "pagination-component space" : "pagination-component"}>
        <div className="left-side">
          <div className="select-to-show">
            <SelectDropdown
              label={'Show'}
              selectOptions={optionsArray}
              onChange={(e: any) => {setNumberOfItemsToShow(e.target.value)}}
              labelInside={true}
              className="select-limiter"
              defaultValue={String(numberOfItemsToShow)}
              isPagination={true}
            />
          </div>
          <div className="number-of-items">
            <span className="boldRegular">{getItemNumbersOnPage()}</span>
            <span className="semibold text">{' of '}</span>
            <span className="boldRegular">{totalItems}</span>
            <span className="semibold text">{' Results'}</span>
          </div>
        </div>
        <div className="page-bar">
          {currentPage === 1 ? (
            <div className="back-arrow-inactive" />
          ) : (
            <div
              className="back-arrow-active"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <img src={greyLeftArrow} alt="active back arrow" />
            </div>
          )}

          {pagesArray.map((e: any, index: number) => {
            return (
              <div
                className={
                  currentPage === e ? 'page-number-active' : 'page-number'
                }
                onClick={() => {
                  if (e !== '...') {
                    setCurrentPage(e);
                  }
                }}
                key={`${e} ${index}`}
              >
                {e}
              </div>
            );
          })}
          {currentPage === totalPages ? (
            <div className="foward-arrow-inactive" />
          ) : (
            <div className="foward-arrow-active" onClick={() => setCurrentPage(currentPage + 1)}>
              <img src={greyRightArrow} alt="active foward arrow" />
            </div>
          )}
        </div>
      </div>
      :
      <div className="pagination-component mobile">
        <div className="page-bar">
          {currentPage === 1 ? (
            <div className="back-arrow-inactive" />
          ) : (
            <div
              className="back-arrow-active"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <img src={greyLeftArrow} alt="active back arrow" />
            </div>
          )}

          {pagesArray.map((e: any, index: number) => {
            return (
              <div
                className={
                  currentPage === e ? 'page-number-active' : 'page-number'
                }
                onClick={() => {
                  if (e !== '...') {
                    setCurrentPage(e);
                  }
                }}
                key={`${e} ${index}`}
              >
                {e}
              </div>
            );
          })}
          {currentPage === totalPages ? (
            <div className="foward-arrow-inactive" />
          ) : (
            <div className="foward-arrow-active" onClick={() => setCurrentPage(currentPage + 1)}>
              <img src={greyRightArrow} alt="active foward arrow" />
            </div>
          )}
        </div>
        <div className={spaceBetween ? "bottom-row space" : "bottom-row"}>
          <div className="select-to-show">
            <SelectDropdown
              label={'Show'}
              selectOptions={optionsArray}
              onChange={(e: any) => {setNumberOfItemsToShow(e.target.value)}}
              labelInside={true}
              className="select-limiter"
              defaultValue={String(numberOfItemsToShow)}
              isPagination={true}
            />
          </div>
          <div className="number-of-items">
            <span className="boldRegular">{getItemNumbersOnPage()}</span>
            <span className="semibold text">{' of '}</span>
            <span className="boldRegular">{totalItems}</span>
            <span className="semibold text">{' Results'}</span>
          </div>
        </div>
      </div>
    }
    </>
  );
};
