import './DesktopSideNavigation.scss'
import bankBlue from '../../../../assets/bankBlue.svg'
import bankGrey from '../../../../assets/bankGrey.svg'
import dollarCoinGrey from '../../../../assets/dollarCoinGrey.svg'
import dollarCoinBlue from '../../../../assets/dollarCoinBlue.svg'
import inboxGrey from '../../../../assets/inboxGrey.svg'
import inboxBlue from '../../../../assets/inboxBlue.svg'
import { useSideNavigation } from '../../../../useHooks/componentHooks/useSideNavigation/useSideNavigation'
import { IAccountInformation } from '../../../../api/getAccountInformation'
import { useEffect, useRef } from 'react'
// import { setSelectedAccount } from '../../../../slices/selectedAccountSlice/selectedAccountSlice'

let navScrollTop = 0;
/**
 * The component renders the desktop version of the side navigation bar.  It will render when the 
 * total pixel width is 800 pixels or greater.
 */
const DesktopSideNavigation = () => {
    const { history, focus, setFocus, focusIndex, setFocusIndex, accountInformation, handleOnClick } = useSideNavigation()
    const sideNavRef = useRef(document.createElement('div'));
    const isListening = useRef(false)
    useEffect(() => {

        const SIDE_NAV_COPY = sideNavRef
        sideNavRef.current.scrollTop = navScrollTop;
        if (isListening.current) {
            return;
        }
        const l = (event: any) => {
            navScrollTop = event.target.scrollTop;
        }
        sideNavRef.current.addEventListener('scroll', l)
        isListening.current = true;


        return () => {
            SIDE_NAV_COPY.current?.removeEventListener('scroll', l);
            isListening.current = false;

        }
    }, [])
    return (
        <div className="Desktop-SideNavigation-Wrapper" ref={sideNavRef}>
            <div className="Nav-Wrapper">
                <div className="Accounts">
                    <div className={focus === "Accounts" ? "Account-Line Focused" : "Account-Line"} onClick={() => { setFocus("Accounts"); setFocusIndex(""); history.push("/account-overview") }}>
                        <img src={focus === "Accounts" ? bankBlue : bankGrey} alt="Bank Icon" />
                        <span className="Accounts-Text boldSmall" >Accounts</span>
                    </div>
                    <div className="Account-Listing">
                        {accountInformation && accountInformation?.map((account: IAccountInformation, index: number) => {
                            return (
                                <div key={index} className={focusIndex === account.id && focus === "Accounts" ? "Account-Wrap Focus-Account" : "Account-Wrap"}
                                    onClick={() => { handleOnClick(account) }}>
                                    <div>
                                        <span className={(focusIndex === account.id && focus) === "Accounts" ? "mobileBoldSmall Account-Title Focus-Title" : "Account-Title mobileBoldSmall"}
                                        >
                                            {account.accountTitle}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="Account-Type mobileX-Small" >{account.accountType} {account.accountNumber}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="Transfers">
                    <div className={focus === "Transfers" ? "Transfer-Line Focused" : "Transfer-Line"} onClick={() => { setFocus("Transfers"); setFocusIndex(""); history.push("/transfers") }}>
                        <img src={focus === "Transfers" ? dollarCoinBlue : dollarCoinGrey} alt="Dollar Coin Icon" />
                        <span className="Transfers-Text boldSmall" >Transfers</span>
                    </div>
                </div>
                <div className="Documents" >
                    <div className={focus === "Documents" ? "Documents-Line Focused" : "Documents-Line"} onClick={() => { setFocus("Documents"); setFocusIndex(""); history.push("/documents") }}>
                        <img src={focus === "Documents" ? inboxBlue : inboxGrey} alt="Inbox Icon" />
                        <span className="Documents-Text boldSmall" >Documents</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopSideNavigation