import { useHistory } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useState } from 'react'

/**Custom use hook for the early withdrawal landing pages */
export const useEarlyWithdrawalLanding = () => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const isSelectedAccountLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    const history = useHistory()
    const [showTooltip, setShowTooltip] = useState<boolean>(false)


    return { selectedAccount, history, isSelectedAccountLoaded, showTooltip, setShowTooltip }
}