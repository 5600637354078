import { useAccountVerification } from '../../../../useHooks/componentHooks/useAccountVerification/useAccountVerification'
import './AccountVerification.scss'
// import verificationBank from '../../../../assets/verificationBank.svg'
// import bankStatement from '../../../../assets/bankStatement.svg'
import { useLinkExternalAccount } from '../../Context/useLinkExternalAccount'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { useHistory } from 'react-router-dom'
import imgBankStatementDesktop from '../../../../assets/imgBankStatementDesktop.svg'
import imgBankStatementMobile from '../../../../assets/imgBankStatementMobile.svg'
import iconBank from '../../../../assets/iconBank.svg'
import iconAddBankWaiting from '../../../../assets/iconAddBankWaiting.svg'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Step two of the link external account manual process. This step will initiate two micro transactions to be used verification purposes */
const AccountVerification = () => {
    const history = useHistory()
    const { windowState, handleContinue, handleCancel } = useAccountVerification()
    const { nickname, setStep } = useLinkExternalAccount()

    return (
        <div className={`account-verification-wrap ${windowState}`}>
            <div className={`header-wrap ${windowState}`}>
                <span className="header regular">Link external account</span>
                <span className="sub-header h3">Manual verification</span>
            </div>
            <div className={`data-wrap ${windowState}`}>
                <div className="left-side">
                        {windowState !== "Mobile" ?
                            <img className="image" src={iconAddBankWaiting} alt="blue circle centering bank" />
                            :
                            <div className="image-wrapper">
                                <img className="image" src={iconAddBankWaiting} alt="blue circle centering bank" />
                            </div>
                        }
                    <div className="number-wrap first">
                        <span className="main-text semiboldRegular">We’ll send two small deposits (less than $1 each) to your bank account, which will arrive within 1-5 business days.</span>
                    </div>
                    <div className="number-wrap">
                        <span className="main-text regular">Once you receive them, come back and enter them to verify your account.</span>
                    </div>
                </div>
                <div className="right-side">
                    {windowState !== "Mobile" ?
                        <img className={`bank-statement`} src={imgBankStatementDesktop} alt="bank statement" />
                        :
                        <img className={`bank-statement ${windowState}`} src={imgBankStatementMobile} alt="bank statement" />
                    }
                    <div className="icon-header-wrap">
                        <img src={iconBank} alt="blue colored bank stencil" />
                        <span className={`nickname semiboldRegular ${windowState}`}>{nickname}</span>
                    </div>
                </div>
            </div>
            <div className="bottom-border" />
            <div className="button-wrap">
                {windowState !== "Mobile" ?
                <>
                    <div className="left-side" onClick={() => setStep(2)}>
                        <img src={arrowLeftBlue} alt="back arrow" />
                        <span className="blue-text regular">Back</span>
                    </div>
                    <div className="right-side">
                        <button className="Button btn-secondary" onClick={() => handleCancel()}>Cancel</button>
                        {/* <button className="Button btn-primary" onClick={() => handleContinue()}>Start verification</button> */}
                        <Button 
                            title="Start verification"
                            onClick={() => handleContinue()} 
                            className="Button btn-primary"
                            id={TIMER_IDS.INITIATE_MICRO_DEPOSITS}
                        />
                    </div>
                </>
                :
                // <button className="Button btn-primary mobile" onClick={() => handleContinue()}>Start verification</button>
                <Button 
                    title="Start verification"
                    onClick={() => handleContinue()}
                    className="Button btn-primary mobile"
                    id={TIMER_IDS.INITIATE_MICRO_DEPOSITS}
                />
            }
            </div>
        </div>
    )
}

export default AccountVerification