import React, { useRef, useState } from 'react';
import { IUsePostYodleeFlow, YODLEE_STATUS } from './usePostYodleeFlow.types';
import { VerifiedAccountRequest } from '../../../../../api/models/verified-account-request';
import { IGetYodleeVerifiedAccountsHeaders, IVerifiedAccount, IYodleeVerifiedAccountResponse, getYodleeVerifiedAccounts_API } from '../../../../../api/getYodleeVerifiedAccounts';
import { AxiosResponse } from 'axios';
import { ICreateYodleeAccount, YodleeCreationStatus } from '../../../../../api/postCreateYodleeAccount';
import { LINKING_STATUS } from '../../../../../api/models/verified-accounts';
import { useLinkExternalAccount } from '../../useLinkExternalAccount';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { addExternalAccount } from '../../../../../slices/userInformation/userInformationSlice';
import { RootState } from '../../../../../app/store';
import { deleteYodleeToken_API } from '../../../../../api/User/yodleeServices';
import { IEDPErrorInterface, edpErrorReporting_API } from '../../../../../api/edpErrorReporting';



/**
 * A `useHook` for the flow after a user selects accounts from Yodlee.
 */
export const usePostYodleeFlow = (setStep: React.Dispatch<React.SetStateAction<number>>, setYodleeToken: React.Dispatch<React.SetStateAction<string>>, providerAccountId: string = ""): IUsePostYodleeFlow => {
  const [verifiedAccounts, setVerifiedAccounts] = useState<Array<IVerifiedAccount>>([]);
  // const [providerAccountId, _setProviderAccountId] = useState('');
  const [responseStatus, _setResponseStatus] = useState<YODLEE_STATUS>(YODLEE_STATUS.NOT_CALLED);
  const [accountsLinked, setAccountsLinked] = useState<Array<ICreateYodleeAccount>>([]);
  const existingExternalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
  const hasVerifiedAccountsBeenCalledRef = useRef<boolean>(false)

  const dispatch = useAppDispatch()

  const postNewAccounts = (accounts: Array<ICreateYodleeAccount>) => {
    setAccountsLinked(accounts);
    // const IS_SUCCESSFUL = accounts.every(e => e.status === YodleeCreationStatus.CREATED);
    const IS_SUCCESSFUL = accounts.every(e => e.status === "Verified" as any);
    _setResponseStatus(IS_SUCCESSFUL ? YODLEE_STATUS.LINKED_SUCCESS : YODLEE_STATUS.LINKED_FAILED);
    setStep(7)
  }

  /**
   * Populate the external account list with verified accounts
   */
  const populateExternalAccountList = (accounts: Array<IVerifiedAccount>) => {
    for (let i = 0; i < accounts.length; i = i + 1) {
      if (accounts[i].externalAccountId && accounts[i].status !== LINKING_STATUS.FRAUD) {
        const payload = {
          id: accounts[i].externalAccountId,
          accountNickName: accounts[i].accountNickName,
          status: accounts[i].status.toLowerCase() === "verified" ? "Linked" : "Processing",
          lastFour: accounts[i].accountNumberLastFourDigit,
          description: accounts[i].bankName,
          routingNumber: accounts[i].routingNumber,
          type: accounts[i].accountType,
          balance: accounts[i].balance
        }
        dispatch(addExternalAccount(payload))
      }
    }
  }

  /**
   * Gets/sets the `verifiedAccounts` and `responseStatus`, this is used when exiting Yodlee and making the verifiedAccounts call with the 
   * new providerID
   */
  const getYodleeVerifiedAccounts = () => {
    const BODY: VerifiedAccountRequest = {
      providerAccountId
    }
    const HEADERS: IGetYodleeVerifiedAccountsHeaders = {
      factorId: '1234',
      stepUpToken: "12345",
      mfaToken: "12345",
      apiVersion: "2.0.0"
    }
    if(!hasVerifiedAccountsBeenCalledRef.current){
      getYodleeVerifiedAccounts_API(BODY, HEADERS)
        .then(setGetVerifiedAccountResponse)
        .then(() => {
          deleteYodleeToken_API()
          localStorage.removeItem("token")
          setYodleeToken("")
          hasVerifiedAccountsBeenCalledRef.current = true
        })
        .catch(err => {
          // setResponseStatus(err.status);
          let errorReturn: any = err.response ? JSON.parse(JSON.stringify(err.response)) : ""
          let edpErrorObject: IEDPErrorInterface = {
              category: "External_Account",
              errorType: "API_error",
              errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""}, {message: "INSTANT VERIFICATION (VERIFIED ACCOUNTS)"}],
              timeStamp: new Date().toISOString(),
              additionalDetails: {
                externalAccounts: [{
                  errorCode: errorReturn.status || "",
                  verificationMethod: "YODLEE"
                }]        
              }
          }
          try{
              edpErrorReporting_API(edpErrorObject)
          }
          catch{}
          setResponseStatus(500)
          setStep(7)
          console.warn('usePostYodleeFlow -> setProviderAccountId. Error.', err);
        })
    }
  }

  /**
   * Handles the response from the `getYodleeVerifiedAccounts_API` call.
   * @param res - The response from the API.
   */
  const setGetVerifiedAccountResponse = (res: AxiosResponse<IYodleeVerifiedAccountResponse>) => {
    setVerifiedAccounts(res.data.externalAccounts);

    if (res.status === 200) {
      setSelectionNeededResponse(res.data.externalAccounts);
    }
    else {
      setResponseStatus(res.status);
      convertIVerifiedAccountToICreateYodleeAccount(res.data.externalAccounts)
      populateExternalAccountList(res.data.externalAccounts)
      setStep(7)
    }
  }

  /**
   * Sets the `responseStatus` if it comes back as a 200 response.
   * @param accounts - The verified accounts.
   */
  const setSelectionNeededResponse = (accounts: Array<IVerifiedAccount>) => {
    if (isAllAccountsAlreadyLinked(accounts) && !didSomeAccountRequireReview(accounts)) {
      setAccountsLinked(convertIVerifiedAccountToICreateYodleeAccount(accounts))
      _setResponseStatus(YODLEE_STATUS.ALL_ACCOUNTS_LINKED);
      populateExternalAccountList(accounts)
      setStep(7)
      return;
    }

    if (isAllAccountsAlreadyLinkedOrVerified(accounts) && !didSomeAccountRequireReview(accounts)) {
      setAccountsLinked(convertIVerifiedAccountToICreateYodleeAccount(accounts))
      _setResponseStatus(YODLEE_STATUS.ALL_ACCOUNTS_LINKED);
      populateExternalAccountList(accounts)
      setStep(7)
      return;
    }

    if (didSomeAccountFailToCreate(accounts)) {
      _setResponseStatus(YODLEE_STATUS.CREATION_FAILED);
      setAccountsLinked(convertIVerifiedAccountToICreateYodleeAccount(accounts))
      populateExternalAccountList(accounts)
      setStep(7)
      return
    }

    if (didSomeAccountRequireReview(accounts) && !isSomeAccountsPendingCaseOrUserSelection(accounts)){
      _setResponseStatus(YODLEE_STATUS.LINKED_FAILED);
      setAccountsLinked(convertIVerifiedAccountToICreateYodleeAccount(accounts))
      populateExternalAccountList(accounts)
      setStep(7)
      return
    }

    if (isSomeAccountsFraud(accounts) && !isSomeAccountsPendingCaseOrUserSelection(accounts)){
      _setResponseStatus(YODLEE_STATUS.LINKED_FAILED);
      setAccountsLinked(convertIVerifiedAccountToICreateYodleeAccount(accounts))
      populateExternalAccountList(accounts)
      setStep(7)
      return
    }

    else {
      _setResponseStatus(YODLEE_STATUS.SELECTION_NEEDED);
    }
  }

  /**
   * Converts an array of `IVerifiedAccount` objects to an array of `ICreateYodleeAccount` objects.
   * @param accounts - The array of `IVerifiedAccount` to convert.
   * @returns - An array of `ICreateYodleeAccount`
   */
  const convertIVerifiedAccountToICreateYodleeAccount = (accounts: Array<IVerifiedAccount>) => {
    const updatedAccounts: Array<ICreateYodleeAccount> = [];

    for (let i = 0; i < accounts.length; i++) {
      const CURR = accounts[i];
      const OBJ: ICreateYodleeAccount = {
        accountNumberLastFourDigit: CURR.accountNumberLastFourDigit,
        bankName: CURR.bankName,
        externalAccountId: CURR.externalAccountId,
        status: convertStatusToCreationStatus(CURR.status)
      }

      updatedAccounts.push(OBJ);
    }

    return updatedAccounts;
  }

  /**
   * Converts the `GET yodlee/getverifiedaccount` `status` variable to the `POST /yodlee/create` `status` variable.
   * 
   * ### `POST /yodlee/create` doesn't have a status mapped to `LINKING_STATUS.NOT_CREATED` only pass for `SUCCESS / FAILURE`
   * @param status - The status from the account
   * @returns 
   */
  const convertStatusToCreationStatus = (status: LINKING_STATUS) => {
    let creationStatus: YodleeCreationStatus;
    switch (status) {
      case LINKING_STATUS.FAILURE:
        creationStatus = YodleeCreationStatus.FAILED;
        break;
      case LINKING_STATUS.NOT_CREATED:
        creationStatus = YodleeCreationStatus.NOT_CREATED;
        break;
      case LINKING_STATUS.PENDING_VERIFICATION:
        creationStatus = YodleeCreationStatus.PENDING_VERIFICATION;
        break;
      case LINKING_STATUS.FRAUD:
        creationStatus = YodleeCreationStatus.FRAUD;
        break;
      case LINKING_STATUS.SUCCESS:
      default:
        creationStatus = YodleeCreationStatus.CREATED;
        break;
    }

    return creationStatus;
  }

  /**Returns trueif some account are in pending review status */
  const didSomeAccountRequireReview = (accounts: Array<IVerifiedAccount>) => accounts.some(e => e.status === LINKING_STATUS.NOT_CREATED)

  /**Returns true if some account failed to link */
  const didSomeAccountFailToCreate = (accounts: Array<IVerifiedAccount>) => accounts.some(e => e.status === LINKING_STATUS.FAILURE)

  /**
   * Returns true if all of the verified accounts comes back already linked.
   * @param accounts - The verified accounts.
   */
  const isAllAccountsAlreadyLinked = (accounts: Array<IVerifiedAccount>) => accounts.every(e => e.isAlreadyExist);

  /**
   * Returns true if all accounts are already existing or verified
   */
  const isAllAccountsAlreadyLinkedOrVerified = (accounts: Array<IVerifiedAccount>) => accounts.every(e => e.isAlreadyExist || e.status.toLowerCase() === "verified")

  /**Returns true if some accounts are pending case creation or pending user selection */
  const isSomeAccountsPendingCaseOrUserSelection = (accounts: Array<IVerifiedAccount>) => accounts.some(e => e.status === LINKING_STATUS.PENDING_CREATION || e.status === LINKING_STATUS.PENDING_USER_CREATION)

  /**Returns true if some accounts are returned with a FRAUD status */
  const isSomeAccountsFraud = (accounts: Array<IVerifiedAccount>) => accounts.some(e => e.status.toUpperCase() === LINKING_STATUS.FRAUD)

  /**
   * Sets the `responseStatus` state that isn't a 200 response.
   * @param status - The status from the API.
   */
  const setResponseStatus = (status: number) => {
    switch (status) {
      case 201:
        _setResponseStatus(YODLEE_STATUS.SUCCESS);
        break;
      default:
        _setResponseStatus(YODLEE_STATUS.ERROR);
        break;
    }
  }

  /**
   * Sets the `providerAccountId`, `verifiedAccounts` and `responseStatus`
   */
  const getVerifiedAccounts = () => {
    if (providerAccountId) {
      getYodleeVerifiedAccounts();
    }
  }

  return {
    verifiedAccounts,
    providerAccountId,
    getVerifiedAccounts,
    responseStatus,
    // setProviderAccountId: _setProviderAccountId,
    accountsLinked,
    postNewAccounts,
    _setResponseStatus,
    setAccountsLinked,
    convertIVerifiedAccountToICreateYodleeAccount,
    hasVerifiedAccountsBeenCalledRef
  }
}