import axios from "axios"
import { bffBaseUrl } from "../utils/globalVariables"

export interface IEDPErrorInterface {
    /**The app location that the error occured in */
    category: "External_Account"

    /**The type of error */
    errorType: "Validation_Error" | "Authentication_error" | "System_error" | "API_error" | "Network_Error" | "Resource_Not_Found"

    /**The error message */
    errorMessage: Array<{message: string}>

    /**The timestamp in UTC format (use GMT timezone) */
    timeStamp: string

    /**Additional details about the error, send addtionalDetails.externalAccounts as an empty array if not in use */
    additionalDetails: {
        externalAccounts?: [{
            acctNo?: string
            acctId?: string
            routingNo?: string
            bankName?: string
            errorCode?: string //use for Yodlee error codes as well
            verificationMethod?: string
        }]
    }

}

/**Make EDP service POST call to report errors */
export const edpErrorReporting_API = (edpErrorObject: IEDPErrorInterface) => {
    return axios.post(`${bffBaseUrl}/customers/ui/errors`,
        {
            category: edpErrorObject.category,
            errorType: edpErrorObject.errorType,
            errorMessage: edpErrorObject.errorMessage,
            timeStamp: edpErrorObject.timeStamp,
            additionalDetails: {
                externalAccounts: edpErrorObject.additionalDetails?.externalAccounts ? edpErrorObject.additionalDetails?.externalAccounts : []
            }
        }
    )
}