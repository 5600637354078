import MobileDocumentsAccountAgreement from '../../../components/DocumentAccountAgreements/MobileDocumentAccountAgreements/MobileDocumentsAccountAgreements'
import MobileDocumentAccountNotices from '../../../components/DocumentAccountNotices/MobileDocumentAccountNotices/MobileDocumentAccountNotices'
import MobileDocumentAccountStatements from '../../../components/DocumentsAccountStatements/MobileDocumentAccountStatements/MobileDocumentAccountStatements'
import MobileDocumentTaxDocuments from '../../../components/DocumentTaxDocuments/MobileDocumentTaxDocuments/MobileDocumentTaxDocuments'
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import { useDocumentsLandingPage } from '../../../useHooks/componentHooks/useDocumentsLandingPage/useDocumentsLandingPage'
import MobileDisplayDocumentPages from './components/MobileDisplayDocumentPages/MobileDisplayDocumentPages'
import { IMobileDocumentsLandingPage } from './interfaces/IMobileDocumentLandingPageProps'
import './MobileDocumentsLandingPage.scss'

/**
 * The component renders the mobile version of the Document Center Landing Page
 */
const MobileDocumentsLandingPage = (props: IMobileDocumentsLandingPage) => {
    const { selectedPage, setSelectedPage, availablePages, selectedAccount, dateSelections, selectedDate, setSelectedDate, handleSave, 
            filteredAccounts } = props
    const { accountInformation, isAccountInformationLoaded } = useDocumentsLandingPage()
    
    return(
        <MobileLayout>
            <div className="mobile-document-center-landing">
                {selectedPage === "" ? 
                    <div className="landing-page-header">
                        {isAccountInformationLoaded !== "Loading" ?
                            <span className="landing-title h3">Document center</span>
                            :
                            <div className="loading-title-box" />
                        }
                    </div>
                    :
                    <div></div>
                }
                {selectedPage === "" &&
                    <div>
                        {isAccountInformationLoaded !== "Loading" ?
                            <MobileDisplayDocumentPages setSelectedPage={setSelectedPage} availablePages={availablePages} />
                            :
                            <div className="card-loading-box" />
                        }
                    </div>
                }
                {selectedPage === "Statements" &&
                    <MobileDocumentAccountStatements 
                        selectedAccount={selectedAccount} 
                        selectedPage={selectedPage} 
                        setSelectedPage={setSelectedPage} 
                        accountInformation={accountInformation}
                        dateSelections={dateSelections}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        handleSave={handleSave}
                        filteredAccounts={filteredAccounts}
                    />
                }
                {selectedPage === "Tax documents" && 
                    <MobileDocumentTaxDocuments 
                        selectedAccount={accountInformation[0]} 
                        selectedPage={selectedPage} 
                        setSelectedPage={setSelectedPage} 
                    />
                }
                {selectedPage === "Agreements" &&
                    <MobileDocumentsAccountAgreement 
                        selectedAccount={selectedAccount} 
                        accountInformation={accountInformation}
                        selectedPage={selectedPage} 
                        setSelectedPage={setSelectedPage}
                        handleSave={handleSave} 
                        setSelectedDate={setSelectedDate}
                    />
                }
                {selectedPage === "Notices & other documents" &&
                    <MobileDocumentAccountNotices 
                        selectedAccount={selectedAccount} 
                        accountInformation={accountInformation}
                        selectedPage={selectedPage} 
                        setSelectedPage={setSelectedPage}
                        handleSave={handleSave}          
                        setSelectedDate={setSelectedDate}       
                    />
                }
            </div>
        </MobileLayout>
    )
}

export default MobileDocumentsLandingPage