import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { getAccountDetailsAsync } from "../../slices/selectedAccountSlice/selectedAccountSlice"
import { setAccountInformationByIndex } from '../../slices/accountInformation/accountInformationSlice'
import { populateAccountDetails } from "../../utils/populateAccountDetails"
import { useHistory } from "react-router-dom"
import { getProductList_API, IProduct } from "../../api/getAccountInformation"

/**A custom use hook for the maturity settings flow */
export const useMaturitySettings = () => {
    const { windowState } = useWindowState()
    const [planChange, setPlanChange] = useState<boolean>(false)
    const [termChange, setTermChange] = useState<boolean>(false)
    const [manageFunds, setManageFunds] = useState<boolean>(false)
    const [openCancelFunds, setOpenCancelFunds] = useState<boolean>(false)
    const [changePlanOption, setChangePlanOption] = useState<"Renew" | "Close" | "">("")
    const [openChangeOption, setOpenChangeOption] = useState<boolean>(false)
    const [chosenTerm, setChosenTerm] = useState<IProduct>()
    const [showTermChange, setShowTermChange] = useState<boolean>(false)
    const [firstStepCompleted, setFirstStepCompleted] = useState<boolean>(false)
    const [transferType, setTransferType] = useState<"Add" | "Withdraw" | "">("")
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [CDProductList, setCDProductList] = useState<Array<IProduct>>([])
    const [bumpProductList, setBumpProductList] = useState<Array<IProduct>>([])
    const [transferAccount, setTransferAccount] = useState<{id: string, title: string, value: string, accountType: "external" | "internal" | ""}>({id: "", title: "", value: "", accountType: ""}) 

    /**Repopulate the selectedAccount on a page refresh */
    useEffect(() => {
        if(selectedAccount.id === "" && accountInformation[0].id !== ""){
            const adjustedUrl = window.location.pathname.split('/')[2]
            const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
            if(accountInformation[index]?.accountType === "Savings"){
                history.push("/")
            }
            if(index >= 0){
                const account = accountInformation[index]
                repopulateAccountDetails(index, account)
            }
            else{
                history.push("/")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, accountInformation])

    /**Repopulates the account details on a page refresh */
    const repopulateAccountDetails = async (index: number, account: any) => {
        const payload = {arrayIndex: index, account: account}
        const response: any = await dispatch(getAccountDetailsAsync(payload))
        try{
            let tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
            const payload = {arrayIndex: index, account: tempAccount}
            dispatch(setAccountInformationByIndex(payload))            
        }
        catch{
            console.error()
        }
    }

    /**Call function to get the list of products on render*/
    useEffect(() => {
        if(CDProductList.length === 0){
            getProductList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Function calls to get the listof products */
    const getProductList = async () => {
        const response = await getProductList_API()
        try {
            const cdArray = response.data.jumboCD
            const bumpArray = response.data.bumbupCD
            for(let i = 0; i < cdArray.length; i = i + 1){
                cdArray[i].name = cdArray[i].name.toLowerCase()
            }
            for(let i = 0; i < bumpArray.length; i = i + 1){
                bumpArray[i].name = bumpArray[i].name.toLowerCase()
            }
            const sortedCDArray = cdArray.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1 )
            const sortedBumpCds = bumpArray.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1)
            setCDProductList(sortedCDArray)
            setBumpProductList(sortedBumpCds)
        }
        catch(err){
            console.log("ERROR IN THE GET PRODUCTS LIST API CALL", err);
        }
    }

    return { windowState, planChange, setPlanChange, termChange, setTermChange, manageFunds, setManageFunds,
             openCancelFunds, setOpenCancelFunds, changePlanOption, setChangePlanOption, openChangeOption, setOpenChangeOption,
             chosenTerm, setChosenTerm, showTermChange, setShowTermChange, firstStepCompleted, setFirstStepCompleted,
             transferType, setTransferType, CDProductList, bumpProductList, selectedAccount, transferAccount, setTransferAccount }
}