import warning from '../../../assets/warning.svg';
import { ButtonDB } from '../../../shared/digitalbank/Button/Button';
import { IDesktopErrorAPICallProps } from './interfaces/IDesktopErrorAPICallProps';
import './DesktopErrorAPICall.scss';

/**
 * Error handling component that displays on the desktop
 * when various API calls fail.
 * @Currently used for: Products GET, Manage External Accounts GET
 */
const DesktopErrorAPICall = (props: IDesktopErrorAPICallProps) => {
  const { onClick = () => { }, showCloseButton = true, topText = "Unable to load data", bottomText = "Unable to load data. Please wait a moment and try again" } = props;

  return (
    <div className="Transactions-API-Error">
      <div className="API-Error-Inner-Box">
        <img className="Image" src={warning} alt="Error Message" />
        <span className="Top-Text bold-x-large">{topText}</span>
        <span className="Bottom-Text regular">
          {bottomText}
        </span>
        <br />
        {showCloseButton &&
          <ButtonDB type="primary" title="Close" onClick={onClick} />
        }
      </div>
    </div>
  );
};

export default DesktopErrorAPICall;
