import { converDateWithTimeLongFormat, convertDateTommddyyyy, isUTCFormat } from '../../../../utils/DateUtils';
import { displayCurrency } from '../../../../utils/displayCurrency';
import { AccountHeader } from '../../../AccountHeader/AccountHeader';
import { Pill } from '../../../Pill/Pill';
import { Memo } from '../Memo/Memo';
import { IMobileTransactionDetailsProps } from './interfaces/IMobileTransactionDetailsProps';
import './MobileTransactionDetails.scss';

/**
 * Transaction Details for Desktop
 * @param setDisplayDetails - for the user to be able to close the sidebar
 * @param selectedTransaction - transaction to show the details of
 */
export const MobileTransactionDetails = (
  props: IMobileTransactionDetailsProps
) => {
  const { selectedTransaction, setDisplayDetails } = props;

  return (
    <div className="MobileTransactionDetails">
      <div className="Account-Header-Wrap">
        <AccountHeader returnMessage={"Back to Account Details"} setDisplayDetails={setDisplayDetails} />
      </div>
      <div className="Data-Wrapper">
        <div className="Title-Wrapper">
          <span className="card-title h4">Transaction details</span>
        </div>
        <span className="Transaction-Description mobileLarge">
          {selectedTransaction.description}
        </span>
        <div className="Transaction-Amount-Wrapper" style={{flexFlow: 'column'}}>
          <span className="Transaction-Amount h2">
            {displayCurrency(selectedTransaction.amount)}
          </span>
          {selectedTransaction.isAmountHold && <div className="Status">
            <Pill title="On Hold" />
          </div>}
        </div>
        <div className="Row">
          <span className="Label mobileBoldSmall">Transaction date</span>
          <span className="Value mobileRegular">
            {isUTCFormat(selectedTransaction?.effectiveDate) ? converDateWithTimeLongFormat(selectedTransaction?.effectiveDate) : convertDateTommddyyyy(selectedTransaction?.effectiveDate)}
            {/* {convertDateWithTime(selectedTransaction.effectiveDate)} */}
          </span>
        </div>
        <div className="Row">
          <span className="Label mobileBoldSmall">Transaction type</span>
          <span className="Value mobileRegular">
            {selectedTransaction.type}
          </span>
        </div>
        {selectedTransaction.institution &&
          <div className="Row">
            <span className="Label mobileBoldSmall">Institution</span>
            <span className="Value mobileRegular">
              {selectedTransaction.institution}
            </span>
          </div>
        }
        {(selectedTransaction.isAmountHold || selectedTransaction.status === "Pending") &&
          <>
            <div className="Row">
              <span className="Label mobileBoldSmall">Available now</span>
              <span className="Value mobileRegular">
                {displayCurrency(0)}
                {/* {displayCurrency(selectedTransaction.availableNow)} */}
              </span>
            </div>
            {/* <div className="Row">
              <span className="Label mobileBoldSmall">
                Available on {convertDateWithTime(selectedTransaction.availableOn)}
              </span>
              <span className="Value mobileRegular">
                {displayCurrency(selectedTransaction.holdAmount)}
              </span>
            </div> */}
          </>}
        <Memo selectedTransaction={selectedTransaction} />
      </div>
      {selectedTransaction.status === 'On Hold' && (
        <div className="Disclaimer-Wrapper">
          <span className="Disclaimer small">
            Some funds are on hold while we process your deposit. For more
            information, please call us at 877-628-1610
          </span>
        </div>
      )}

    </div>
  );
};
