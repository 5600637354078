import { useHistory } from "react-router-dom"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { MobileLayout } from "../../layouts/MobileLayout/MobileLayout"
import { useAccountOwnership } from "../../useHooks/pageHooks/useAccountOwnership"
import DesktopAccountOwnership from "./DesktopAccountOwnership/DesktopAccountOwnership"
import MobileAccountOwnership from "./MobileAccountOwnership/MobileAccountOwnership"

const AccountOwnershipPageWrapper = () => {
    const { windowState, selectedAccount, addOwner, setAddOwner, formStep, setFormStep, form, dispatchForm,
            IRSWithholding, setIRSWithholding, foreignAffiliation, setForeignAffiliation, usingSuggestion, setUsingSuggestion } = useAccountOwnership()

    const history = useHistory();

    if(selectedAccount.accountClosed || selectedAccount.pendingGraceClosure) {
        history.push(`/accounts/${selectedAccount.id}`);
    }

    return(
        <>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopAccountOwnership 
                            addOwner={addOwner} 
                            setAddOwner={setAddOwner} 
                            selectedAccount={selectedAccount}
                            formStep={formStep}
                            setFormStep={setFormStep}
                            dispatchForm={dispatchForm}
                            form={form}
                            IRSWithholding={IRSWithholding}
                            setIRSWithholding={setIRSWithholding} 
                            foreignAffiliation={foreignAffiliation}
                            setForeignAffiliation={setForeignAffiliation}
                            usingSuggestion={usingSuggestion}
                            setUsingSuggestion={setUsingSuggestion}
                        />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopAccountOwnership 
                            addOwner={addOwner} 
                            setAddOwner={setAddOwner} 
                            selectedAccount={selectedAccount}
                            formStep={formStep}
                            setFormStep={setFormStep}
                            dispatchForm={dispatchForm}
                            form={form}
                            IRSWithholding={IRSWithholding}
                            setIRSWithholding={setIRSWithholding} 
                            foreignAffiliation={foreignAffiliation}
                            setForeignAffiliation={setForeignAffiliation}
                            usingSuggestion={usingSuggestion}
                            setUsingSuggestion={setUsingSuggestion}
                        />
                    </HybridLayout>
                }
                </>
                :
                <MobileLayout>
                    <MobileAccountOwnership 
                        addOwner={addOwner} 
                        setAddOwner={setAddOwner} 
                        selectedAccount={selectedAccount}
                        formStep={formStep}
                        setFormStep={setFormStep}
                        dispatchForm={dispatchForm}
                        form={form}
                        IRSWithholding={IRSWithholding}
                        setIRSWithholding={setIRSWithholding} 
                        foreignAffiliation={foreignAffiliation}
                        setForeignAffiliation={setForeignAffiliation}
                        usingSuggestion={usingSuggestion}
                        setUsingSuggestion={setUsingSuggestion}
                    />
                </MobileLayout>
            }
        </>
    )
}

export default AccountOwnershipPageWrapper