/**
 * This is a generic function used to download documents ie: statements, tax information, agreements, others... 
 * It takes in a blob and produces the downloaded pdf
 * */
export const downloadDocuments = (blob: any, lastFour: string, type: "Statement" | "Tax" | "Agreement" | "Others", date: string) => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    if(type === "Statement"){
        a.download = `Account-${lastFour}-Statement.pdf`
    }
    else if(type === "Tax"){
        a.download = `TaxDocument-${date}.pdf`
    }
    else if(type === "Agreement"){
        a.download = `Agreement-${lastFour}.pdf`
    }
    else if(type === "Others"){
        a.download = `Account-${lastFour}-Notice.pdf`
    }

    a.click()

    //cleaning up
    setTimeout(() => {
        a.remove()
        URL.revokeObjectURL(url)
    }, 100)
}