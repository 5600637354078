import './DisplayMobileBeneficiaryRow.scss'
import { IDisplayMobileBeneficiaryRowProps } from './interfaces/IDisplayMobileBeneficiaryRowProps'
import { useDisplayMobileBeneficiaryRow } from '../../../../../../useHooks/componentHooks/useDisplayMobileBeneficiaryRow/useDisplayMobileBeneficiaryRow'
import { COLOR_ARRAY } from '../../../../../../utils/globalVariables'

/**
 * The component displays the beneficiaries by row.  That is, in the array is mapped in BeneficiaryMobileEdit component the rows
 * of the map are being displayed here.
 * @param props index: type number, the beneficiary array index, beneficiary: type: IBeneficiary, the particular beneiciary being displayed
 *              onChangeHandler: function with props e: any, and index: number
 */
const DisplayMobileBeneficiaryRow = (props: IDisplayMobileBeneficiaryRowProps) => {
    const { index, beneficiary, onChangeHandler } = props
    const { inputRef } = useDisplayMobileBeneficiaryRow()
    return (
        <div key={index} className="display-mobile-row-wrapper">
            <div className="details-wrapper">
                <div className="name-allocation-dots-wrapper">
                    <div className="left-side">
                        <div className="dot" style={{ backgroundColor: COLOR_ARRAY[index] }} />
                        <div className="name-type-stack">
                            {beneficiary.type === "Person" && <span className="member-name mobileSemiboldSmall cls_mask">{`${beneficiary.name}`}</span>}
                            {(beneficiary.type === "Charity" || beneficiary.type === "Non Profit") && <span className="member-name mobileSemiboldSmall cls_mask">{`${beneficiary.name}`}</span>}
                            {beneficiary.type === "Trust" && <span className="member-name mobileSemiboldSmall cls_mask">{`${beneficiary.name}`}</span>}
                            <span className="member-type mobileX-Small">{beneficiary.type}</span>
                        </div>
                    </div>
                    <div className={beneficiary.percentage === 0 ? "Error-Box" : "Input-Box"} onClick={() => inputRef.current.focus()}>
                        <input className="Box mobileSmall box-text" type="text" value={beneficiary.percentage} onChange={(e) => onChangeHandler(e, index)} ref={inputRef} />
                        <span className="Percentage mobileSmall">%</span>
                    </div>
                </div>
            </div>
            {beneficiary.percentage === 0 && <div className="Allocation-Error-Message mobileBoldSmall">Allocation must be at least 1%</div>}
        </div>
    )
}

export default DisplayMobileBeneficiaryRow