import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import './EarlyTooltip.scss'
import topAnchor from '../../../../assets/topAnchor.svg'
import { IEarlyTooltipProps } from './interfaces/IEarlyTooltipProps'
import { useEffect } from 'react'
import { getEventPath } from '../../../../utils/getEventPath'
import { RootState } from '../../../../app/store'
import { useHistory } from 'react-router'
import { useAppSelector } from '../../../../app/hooks'

/**Tooltip used for the tooltip message within early withdrawal */
const EarlyTooltip = (props: IEarlyTooltipProps) => {
    const { setShowTooltip } = props
    const { windowState } = useWindowState()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const history = useHistory()

    /**useEffect controls locking and unlocking the scrollbar */
    useEffect(() => {
        if (windowState === "Mobile") {
            document.body.style.overflow = "hidden";
        }
        return () => { document.body.style.overflow = "auto" };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if (Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("message-wrapper")) === -1) {
                if (windowState === "Mobile" && setShowTooltip) {
                    setShowTooltip(false)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {windowState !== "Mobile" ?
                <div className="desktop-early-tooltip-wrap">
                    <img className="shadow" src={topAnchor} alt="anchor point" />
                    <div className="message-wrap">
                        <span className="header-text boldSmall">How is penalty amount calculated?</span>
                        <span className="message-text small">The penalty for early withdrawal is based on the length of your term:</span>
                        <span className="message-text small">• CD Term 1-12 months = Early Withdrawal Penalty of 90 days simple interest</span>
                        <span className="message-text small">• CD Term 13-60 months = Early Withdrawal Penalty of 180 days simple interest</span>
                        <span className="message-text small">• CD Term 61+ months = Early Withdrawal Penalty of 365 days simple interest</span>
                    </div>
                </div>
                :
                <div data-testid="overlay" className="mobile-early-tooltip-wrap">
                    <div className="message-wrapper">
                        <div className="header-wrap">
                            <span className="header-text semiboldRegular">Penalty amount</span>
                            {setShowTooltip &&
                                <span className="blue-text regular" onClick={() => setShowTooltip(false)}>Close</span>
                            }
                        </div>
                        <div className="body-wrap">
                            <span className="body-text regular first">The penalty for early withdrawal is based on the length of your term:</span>
                            <span className="body-text regular">• CD Term 1-12 months = Early Withdrawal Penalty of 90 days simple interest</span>
                            <span className="body-text regular">• CD Term 13-60 months = Early Withdrawal Penalty of 180 days simple interest</span>
                            <span className="body-text regular">• CD Term 61+ months = Early Withdrawal Penalty of 365 days simple interest</span>
                        </div>
                        <span className="link-text regular">Changed your mind? Manage your&nbsp;
                            <span className="blue-text" onClick={() => { history.push(`/account/${selectedAccount.id}/maturity`) }}>maturity plan</span>&nbsp;instead.
                        </span>
                    </div>
                </div>
            }
        </>
    )
}

export default EarlyTooltip