import './InputError.scss'
import { IInputErrorProps } from './interfaces/IInputError'

/**Renders the component that displays an input error if an error exists */
const InputError = (props: IInputErrorProps) => {
    const { controlName, errorArray, getErrorMessage } = props

    return (
        <span data-testid="form-error" className="error-message mobileParagraphRegular">{getErrorMessage(controlName, errorArray[0].name)}</span>
    )
}

export default InputError