import { ICreateYodleeAccount, YodleeCreationStatus } from '../../../../../api/postCreateYodleeAccount';
import { useLinkExternalAccount } from '../../../Context/useLinkExternalAccount';
import './AccountLinkingInProgress.scss'
import greenCheckmark from '../../../../../assets/greenCheckmark.svg'
import iconAddBankWaiting from '../../../../../assets/iconAddBankWaiting.svg'
import { useHistory } from 'react-router';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import iconBankError from '../../../../../assets/iconBankError.svg'
import iconError from '../../../../../assets/iconError.svg'
import { PostYodleeError } from '../PostYodleeFlowChart/PostYodleeFlowChart.component';
import { YODLEE_STATUS } from '../../../Context/useHooks/usePostYodleeFlow/usePostYodleeFlow.types';

export const AccountLinkingInProgress = () => {
  const { accountsLinked, hasVerifiedAccountsBeenCalledRef, _setResponseStatus } = useLinkExternalAccount().postYodleeFlow;
  const { setStep, setProviderAccountId } = useLinkExternalAccount()
  const history = useHistory()
  const { windowState } = useWindowState()
  let numberOfFailures = 0

  const IS_ALL_FAILED = accountsLinked.every(e => e.status === YodleeCreationStatus.FAILED);
  const FULL_API_FAILURE = accountsLinked.every(e => e.status === YodleeCreationStatus.FAILED);
  const All_ACCOUNTS_PROCESSING = accountsLinked.every(e => e.status === YodleeCreationStatus.NOT_CREATED)
  const SOME_ACCOUNTS_PROCESSING = accountsLinked.some(e => e.status === YodleeCreationStatus.NOT_CREATED)
  const SOME_ACCOUNTS_PENDING_VERIFICATION = accountsLinked.some(e => e.status === YodleeCreationStatus.PENDING_VERIFICATION)
  const SOME_ACCOUNTS_FRAUD = accountsLinked.some(e => e.status === YodleeCreationStatus.FRAUD)
  const ALL_ACCOUNTS_FRAUD = accountsLinked.every(e => e.status === YodleeCreationStatus.FRAUD)

  /**Check for the number of failures */
  for(let i = 0; i < accountsLinked.length; i = i + 1){
    if(accountsLinked[i].status === YodleeCreationStatus.FAILED){
      numberOfFailures = numberOfFailures + 1
    }
  }

  /**handles the onClick for the "Try linking again" button */
  const handleTryLinkingAgain = () => {
    setStep(5) 
    setProviderAccountId("") 
    hasVerifiedAccountsBeenCalledRef.current = false
    _setResponseStatus(YODLEE_STATUS.NOT_CALLED)
  }

  /**Find out if one or more than one accounts are in frozen status */
  const checkFrozenAccounts = () => {
    if(ALL_ACCOUNTS_FRAUD && accountsLinked.length > 1){
      return "accounts"
    }
    return "account"
  }

  return (
    <div className={`yodlee-link-in-progress-wrapper ${windowState}`}>
      {(numberOfFailures > 0 && numberOfFailures < accountsLinked.length && !SOME_ACCOUNTS_FRAUD) ? 
          <>
            <img className="bank-link-image" src={iconBankError} alt="linking error" />
            <span className="title-text h4">Not all of your accounts could be linked</span>
            <span className="sub-title-text regular">We were unable to verify one of your accounts due to a technical issue on our end.</span>
            {accountsLinked.map(e => <DisplayBankLinkage account={e} key={e.externalAccountId}/>)}
            {windowState !== "Mobile" ?
              <div className="button-wrapper">
                <button className="Button btn-primary" onClick={() => handleTryLinkingAgain()}>Try linking again</button>
                <span className="link-text regular" onClick={() => {history.push("/user/profile/external-accounts"); setStep(1); setProviderAccountId("")}} >Return to external accounts</span>
              </div>
              :
              <div className="button-wrapper">
                <button className="Button btn-primary mobile" onClick={() => handleTryLinkingAgain()}>Try linking again</button>
                <span className="link-text regular mobile" onClick={() => {history.push("/user/profile/external-accounts"); setStep(1); setProviderAccountId("")}} >Return to external accounts</span>
              </div>
            }
          </>
        :
        <>
        {FULL_API_FAILURE ? 
          <PostYodleeError />
          :
          <>
            {!ALL_ACCOUNTS_FRAUD && !SOME_ACCOUNTS_FRAUD ?
              <img className="bank-link-image" src={iconAddBankWaiting} alt="bank link" />
              :
              <img className="bank-link-image" src={iconBankError} alt="bank not linked" />
            }
            {ALL_ACCOUNTS_FRAUD ?
              <p className="title-text h4">Your {checkFrozenAccounts()} could not be linked</p>
              :
              <>
              {SOME_ACCOUNTS_FRAUD ? 
                <p className="title-text h4">One of your accounts could not be linked</p>
                :
                <p className="title-text h4">Account linking in progress</p>
              }
              </>
            }
            {(All_ACCOUNTS_PROCESSING || ALL_ACCOUNTS_FRAUD) ? <AccountLinkingInProgressAllSame /> : <AccountLinkingInProgressDifferent />}
            <div className="yodlee-return-button-wrapper">
              {windowState !== "Mobile" ?
                <button className="Button btn-primary" onClick={() => {history.push("/user/profile/external-accounts"); setStep(1)}} >Return to external accounts</button>
                :
                <button className="Button btn-primary mobile" onClick={() => {history.push("/user/profile/external-accounts"); setStep(1)}} >Return to external accounts</button>
              }
            </div>
          </>
        }
        </>
      }
    </div>
  )
}

/**
 * Displays the veribage for when all of the yodlee accounts that tried to be created came back with `status = YodleeCreationStatus.FAILED`
 * @returns `JSX.Element`
 */
const AccountLinkingInProgressAllSame = () => {
  const { accountsLinked } = useLinkExternalAccount().postYodleeFlow;
  const { windowState } = useWindowState()

  const ALL_ACCOUNTS_FRAUD = accountsLinked.every(e => e.status === YodleeCreationStatus.FRAUD)

  const IS_MULTI = accountsLinked.length > 1;
  const ACCOUNT_STRING = IS_MULTI ? 'your accounts are' : 'your account is';

  return (
    <>
    {ALL_ACCOUNTS_FRAUD ?
      <p className={`link-in-progress-body regular ${windowState}`}>Please give us a call at <a className="blue-text regular" href={"tel:18776281610"}>877-628-1610</a> so we can help.</p>
      :
      <p className={`link-in-progress-body regular ${windowState}`}>Please allow up to two business days for account linking to be completed. We'll send you an email when {ACCOUNT_STRING} ready to use.</p>
    }
    </>
  )

}

/**
 * Component to display the verbiage and accounts for when the yodlee accounts that tried to be created came back with different statuses.
 * @returns `JSX.Element`
 */
const AccountLinkingInProgressDifferent = () => {
  const { accountsLinked } = useLinkExternalAccount().postYodleeFlow;
  const { windowState } = useWindowState()

  const SOME_ACCOUNTS_PROCESSING = accountsLinked.some(e => e.status === YodleeCreationStatus.NOT_CREATED)
  const SOME_ACCOUNTS_PENDING_VERIFICATION = accountsLinked.some(e => e.status === YodleeCreationStatus.PENDING_VERIFICATION)
  const SOME_ACCOUNTS_FRAUD = accountsLinked.some(e => e.status === YodleeCreationStatus.FRAUD)

  return <>
    {(SOME_ACCOUNTS_PROCESSING && SOME_ACCOUNTS_PENDING_VERIFICATION && !SOME_ACCOUNTS_FRAUD) && <p className={`link-in-progress-body regular ${windowState}`}>One or more of your accounts are pending manual verification. You'll need to finish the manual verification process before these accounts can be used. For any accounts still processing, no action is needed and you'll receive an email when they're ready to use, typically in about 1-2 business days.</p>}
    {(SOME_ACCOUNTS_PROCESSING && !SOME_ACCOUNTS_PENDING_VERIFICATION && !SOME_ACCOUNTS_FRAUD) && <p className={`link-in-progress-body regular ${windowState}`}>Please allow up to two business days for account linking to be completed. For any accounts still processing, you’ll receive an email when they’re ready to use.</p>}
    {SOME_ACCOUNTS_FRAUD && <p className={`link-in-progress-body regular ${windowState}`}>Please give us a call at <a className="blue-text regular" href={"tel:18776281610"}>877-628-1610</a> so we can help.</p>}

    {accountsLinked.map(e => <DisplayBankLinkage account={e} key={e.externalAccountId}/>)}
  </>
}

/**
 * Displays the `bankName` `accountNumberLastFourDigit` and the `status` of a yodlee created external account.
 * @param props - The account to be displayed.
 * @returns 
 */
export const DisplayBankLinkage = (props: { account: ICreateYodleeAccount }) => {
  const { windowState } = useWindowState()
  const { account } = props;

  return (
    <div className="yodlee-bank-wrapper">
      <span className={account.status === YodleeCreationStatus.FAILED ? "bank-name semiboldRegular failed" : "bank-name semiboldRegular"}>{account.bankName} {account.accountNumberLastFourDigit}</span>
       <span className={`hyphens ${windowState}`} />
       <Indicator account={account} />
    </div>)
}


/**
 * Display the status of a yodlee account that was created as an external account.
 * @param props - The account to display
 * @returns 
 */
const Indicator = (props: { account: ICreateYodleeAccount }) => {
  const { windowState } =useWindowState()

  if (props.account.status === YodleeCreationStatus.FAILED) {
    return ( 
      <div className={`yodlee-indicator-pill failed ${windowState}`}>
        <img className="pill-icon" src={iconError} alt="error" />
        <p className="pill-text failed mobile-h6">Failed to link</p>
      </div>
    )
  }
  if(props.account.status.toLowerCase() === "pending"){
    return ( 
      <div className={`yodlee-indicator-pill ${windowState} pending`}>
        <p className="pill-text mobile-h6">Verification pending</p>
      </div>
    )
  }
  // if(props.account.status === YodleeCreationStatus.NOT_CREATED){
  //   return (
  //     <div className={`yodlee-indicator-pill processing ${windowState}`}>
  //       <p className="pill-text mobile-h6">Processing</p>
  //     </div>
  //   )
  // }
  // if(props.account.status === YodleeCreationStatus.FRAUD){
  //   return (
  //     <div className={`yodlee-indicator-pill frozen ${windowState}`}>
  //       <p className="pill-text mobile-h6">Frozen</p>
  //     </div>
  //   )
  // }
  return AdditionalIndicators(props.account)
  // return (
  //   <div className={`yodlee-indicator-pill success ${windowState}`}>
  //     <img className="image" src={greenCheckmark} alt="green checkmark" />
  //     <p className="pill-text success mobile-h6">Ready to use</p>
  //   </div>
  // )
}

/**Additional indicator for the only prupose of splitting up the function */
const AdditionalIndicators = (account: ICreateYodleeAccount) => {
  const { windowState } =useWindowState()

  if(account.status === YodleeCreationStatus.NOT_CREATED){
    return (
      <div className={`yodlee-indicator-pill processing ${windowState}`}>
        <p className="pill-text mobile-h6">Processing</p>
      </div>
    )
  }

  if(account.status === YodleeCreationStatus.FRAUD){
    return (
      <div className={`yodlee-indicator-pill frozen ${windowState}`}>
        <img className="pill-icon" src={iconError} alt="error" />
        <p className="pill-text not-linked mobile-h6">Not linked</p>
      </div>
    )
  }

  return (
    <div className={`yodlee-indicator-pill success ${windowState}`}>
      <img className="image" src={greenCheckmark} alt="green checkmark" />
      <p className="pill-text success mobile-h6">Ready to use</p>
    </div>
  )
}