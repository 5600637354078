import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import '../SSNInput/SSNInput.scss';
import { useBaseDashedNumberInput } from './hooks/useBaseDashedNumberInput';
import { IBaseDashedNumberInputProps } from './types/IBasedDashedNumberInput.type';


export const BaseDashedNumberInput = (props: IBaseDashedNumberInputProps) => {
  const { control, className = '', style = {}, form } = props;
  const { inputRef, handleOnBlur, handleOnChange, handleKeyDown } = useBaseDashedNumberInput(props);
  return (
    <div className={`ssn-input-wrap ${className}`}>
      <InputLabel control={control}>
    <input
      data-testid={control.name}
      disabled={control.isDisabled}
      className={control.errors.length > 0 ? "pass error regular" : "pass regular"}
      style={style}
      placeholder={control.isDisabled ? '' : control.placeholder}
      ref={inputRef}
      value={control.value}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      onBlur={() => handleOnBlur()}
    />
    </InputLabel>
    {control.errors.length > 0 && <InputError controlName={control.name} errorArray={control.errors} getErrorMessage={form.getErrorMessage} />}
    </div>
  );
}