import { useDateInput } from '../../Form/useHooks/useDateInput';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel'
import './DateInput.scss'
import { IDateInputProps } from './interfaces/IDateInputProps'

/**Render the date type input box to be used with the form and form controls */
const DateInput = (props: IDateInputProps) => {
    const { control, className = '', style = {}, form, dispatchControlChanges } = props;
    const { handleOnBlur, onChangeHandle, getMaxDate, getMinDate } = useDateInput(form, control, dispatchControlChanges)

    return (
        <div className={`date-input-wrap ${className}`}>
            <InputLabel control={control}>
                <input 
                    data-testid={control.name}
                    type="date" 
                    placeholder={control.isDisabled ? '' : control.placeholder} 
                    className={control.errors.length > 0 ? "pass error regular" : "pass regular"} 
                    id={control.id} 
                    style={style}  
                    onBlur={(e) => handleOnBlur(e)}
                    onChange={(e) => onChangeHandle(e)}
                    disabled={control.isDisabled}
                    value={control.value}
                    max={getMaxDate()}
                    min={getMinDate()}
                />
            </InputLabel>
            {control.errors.length > 0 && <InputError controlName={control.name} errorArray={control.errors} getErrorMessage={form.getErrorMessage} />}            
        </div>
    )
}

export default DateInput