import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { AccountHeader } from '../../../components/AccountHeader/AccountHeader';
import { DesktopInterestSettingsChange } from '../../../components/InterestSettingsChange/DesktopInterestSettingsChange/DesktopInterestSettingsChange';
import DesktopInterestSettingsLanding from '../../../components/InterestSettingsLanding/DesktopInterestSettingsLanding/DesktopInterestSettingsLanding';
import './DesktopInterestSettings.scss';
import { IDesktopInterestSettingsProps } from './interfaces/IDesktopInterestSettingsProps';
import warning from '../../../assets/warning.svg'

/**This component displays the desktop version of the interest settings page */
const DesktopInterestSettings = (props: IDesktopInterestSettingsProps) => {
  const { selectedAccount, changeClicked, setChangeClicked, accountToTransfer, setAccountToTransfer } = props;
  const isInterestSettingsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isInterestSettingsLoaded)

  return (
    <div className="Landing-Wrapper">
      <div className="card-wrapper account-header">
        <AccountHeader
          returnMessage="Account Settings"
          setDisplayDetails={false}
        />
      </div>
      {isInterestSettingsLoaded !== "Loading" ?
        <>
          {isInterestSettingsLoaded === "Success" ?
            <>
              {!changeClicked ? (
                <DesktopInterestSettingsLanding
                  selectedAccount={selectedAccount}
                  setChangeClicked={setChangeClicked}
                />
              ) : (
                <DesktopInterestSettingsChange 
                  setChangeClicked={setChangeClicked}
                  accountToTransfer={accountToTransfer}  
                  setAccountToTransfer={setAccountToTransfer}
                />
              )}
            </>
            :
            <div className="failed-api-page-wrapper">
              <div className="failed-api-data-wrapper">
                <img className="failed-image" src={warning} alt="failed to get interest settings data" />
                <span className="header-text h4">Unable to load data</span>
                <span className="sub-text regular">Please wait a moment and try again.</span>
              </div>
            </div>
          }
        </>
        :
        <div className="interest-loading-wrapper">
          <div className="desktop-interest-loading-state" />
        </div>
      }
    </div>
  );
};

export default DesktopInterestSettings;
