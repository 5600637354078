import './DesktopTaxWithholdings.scss'
import { IDesktopTaxWithholdingsProps } from './interfaces/IDesktopTaxWIthholdingsProps'
import { useTaxWithholdings } from '../../../useHooks/componentHooks/useTaxWithholdings/useTaxWithholdings'
import arrowRight from '../../../assets/arrowRight.svg'
import DesktopProfileHeader from '../../../Pages/UserProfile/components/DesktopProfileHeader/DesktopProfileHeader'
import moneyHouseBlueBack from '../../../assets/moneyHouseBlueBack.svg'
import greyInfo from '../../../assets/greyInfo.svg'
import Tooltip from '../../Tooltip/Tooltip'
import Toggle from '../../Toggle/Toggle'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import warning from '../../../assets/warning.svg'

/**
 * Renders the Desktop version of the Tax Withholdings component found as a tab on the user profile page
 */
const DesktopTaxWithholdings = (props: IDesktopTaxWithholdingsProps) => {
    const { currentTab, setCurrentTab, availableTabs } = props
    const { history, toggle, handleToggleClick, openTooltip, setOpenTooltip, type, message, gotW9 } = useTaxWithholdings()

    return (
        <div className="tax-withholdings-wrapper">
            <DesktopProfileHeader 
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                availableTabs={availableTabs}
            />
            {(message !== "" && type === "ERROR") && <ToastMessageDB type={type} message={message} />}
            <div className="tax-page-wrapper">
                {gotW9 !== "Loading" ?
                    <>
                    <div className="top-card">
                        {gotW9 === "Success" ?
                            <>
                            <span className="sign-in-header h2">W-9 tax withholdings</span>
                            <div className="bottom-border" />
                            <div className="data-row-wrap">
                                <div className="left-side">
                                    <span className="sign-in-text regular">Are you subject to W-9 tax withholdings?</span>
                                    <img className="tooltip" src={greyInfo} alt="tooltip" onMouseOver={() => setOpenTooltip(true)} onMouseOut={() => setOpenTooltip(false)} />
                                    {openTooltip &&
                                        <div className="tooltip-message-wrap">
                                            <Tooltip anchorPoint={"top-left"} width="250px" headerMessage="About W-9 tax withholding" bodyMessage="Backup withholding is a type of tax levied at an established tax rate on specific types of income for federal tax purposes. Backup withholding may be mandatory in certain circumstances. Most taxpayers however are exempt from backup withholding."/>
                                        </div>
                                    }
                                </div>
                                <Toggle 
                                    toggle={toggle}
                                    setToggle={handleToggleClick}
                                />
                            </div>
                            </>
                            :
                            <div className="api-error-wrap">
                                <img src={warning} alt="failed to retreive data" />
                                <span className="header-text h4">Unable to load data</span>
                                <span className="sub-text regular">Please wait a moment and try again.</span>
                            </div>
                        }
                    </div>
                    <div className="bottom-card" onClick={() => history.push('/documents/tax')}>
                        <div className="left-side">
                            <img src={moneyHouseBlueBack} alt="house" />
                            <div className="text-wrap">
                                <span className="header-text boldLarge">Looking for your tax documents?</span>
                                <span className="info-text regular">Manage, view and download your tax documents from the document center</span>
                            </div> 
                        </div>
                        <img className="right-arrow" src={arrowRight} alt="open" />
                    </div>
                    </>
                    :
                    <div className="w9-loading-card-wrap">
                        <div className="w9-loading-card top" />
                        <div className="w9-loading-card bottom" />
                    </div>
                }
            </div>
        </div>
    )
}

export default DesktopTaxWithholdings