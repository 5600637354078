import { IButtonProps } from './interfaces/IButtonProps.type';

/**
 * Common button component
 * @param props
 * @returns common button without styling
 */
export const Button = (props: IButtonProps) => {
  const {
    isDisabled = false,
    onClick = () => {},
    title,
    ariaLabel,
    children,
    className
  } = props;

  /**
   * Sets the text to be used for the aria-label
   * @returns aria-label value
   */
  let label: string = (() => {
    let label = '';
    if (title !== undefined) {
      label = title.toString();
    } else if (ariaLabel !== undefined) {
      label = ariaLabel.toString();
    } else {
      throw new Error(
        "Requires either 'title' or 'ariaLabel' props defined on ButtonComponent."
      );
    }
    return label;
  })();

  /**
   * Sets the content shown within a button
   * @returns button title or children prop
   */
  let buttonContent: string | number | JSX.Element = (() => {
    let buttonContent;
    if (title !== undefined && children !== undefined) {
      buttonContent = children;
      console.warn(
        "Both 'title' and 'children' shouldn't be present as props on ButtonComponent"
      );
    } else if (title !== undefined && children === undefined) {
      buttonContent = title;
    } else if (title === undefined && children !== undefined) {
      buttonContent = children;
    } else {
      throw new Error(
        "Requires either 'title' or 'children' props defined on ButtonComponent, but not both."
      );
    }

    return buttonContent;
  })();

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={isDisabled}
      aria-label={label}
      aria-disabled={isDisabled}
    >
      {buttonContent}
    </button>
  );
};
