import { useEffect, useRef, useState } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setCheckInternalToken, setOTPMenuStatus, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { getFactorList_API, validateMFAToken_API } from "../../../api/User/otpServices"
import { RootState } from "../../../app/store"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"

/**an interface to be used with the factor options state */
export interface IFactor {
    id: string,
    type: "email" | "call" | "sms" | "",
    value: string,
}

/**A custom use hook for the OTPModal component stepper */
export const useOTPModal = () => {
    const { windowState } = useWindowState()
    const [ step, setStep ] = useState<number>(1)
    const dispatch = useAppDispatch()
    const [ verifySelection, setVerifySelection ] = useState<"" | "email" | "call" | "sms">("")
    const [factorOptions, setFactorOptions] = useState<Array<IFactor>>([])
    const [chosenFactor, setChosenFactor] = useState<IFactor>({id: "", type: "", value: ""})
    const emailRef = useRef<string>("")
    const openOTPModal = useAppSelector((state: RootState) => state.userInformation.openOTPModal)
    const checkInternalToken = useAppSelector((state: RootState) => state.userInformation.checkInternalToken)
    const { setToastMessage } = useToastMessage()

    /**Handles the cancel 'X' button click on the OTPModal */
    const handleCancel = () => {
        dispatch(setOTPPassed(false))
        dispatch(setOTPMenuStatus(false))
    }

    /**Locks the scrollbar on mount, unlocks the srcollbar when unmounted */
    useEffect(() => {
        if(openOTPModal){
            document.body.style.overflow = "hidden"
        }
        else{
            document.body.style.overflow = "auto"
        }

        return () => {document.body.style.overflow = "auto"}
        
    }, [openOTPModal])

    /**modifies the email address to be the hidden version */
    const modifyValue = (type: "email" | "call" | "sms", value: string) => {
        let modifiedValue: string = ""
        if(type  === "email"){
            let [leftSide, rightSide] = value.split("@")
            modifiedValue = leftSide[0] + "******" + leftSide[leftSide.length -1] + "@" + rightSide
        }
        else{
            let rightSide = value.slice(value.length - 4, value.length)
            modifiedValue = "***-***-" + rightSide
         }
        return modifiedValue
    }

    /**gets the OTP factors to be used with the first step of the OTP modal */
    const getOTPFactors = async () => {
        try{
            const response = await getFactorList_API()
            const tempFactorArray: Array<IFactor> = []
            for(let i = 0; i < response.data.length; i = i + 1){
                let tempFactor: IFactor = {
                    id: response.data[i].id,
                    type: response.data[i].type,
                    value: modifyValue(response.data[i].type, response.data[i].value),
                }
                tempFactorArray.push(tempFactor)
                if(response.data[i].type === "email"){
                    emailRef.current = response.data[i].value || ""
                }
            }
            setFactorOptions(tempFactorArray)
            dispatch(setOTPPassed(false))
            dispatch(setOTPMenuStatus(true))     
            dispatch(setCheckInternalToken(false))
        }
        catch{
            dispatch(setCheckInternalToken(false))
            console.log("FAILED TO GET THE OTP FACTORS.")
            setToastMessage("ERROR", "Unable to save changes. Please try again later")
        }
    }

    /**monitors the checkInternalToken key, if it is true then we call the service */
    useEffect(() => {
     
        if(checkInternalToken){
            checkInternalTokenStatus()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkInternalToken])

    /**makes the api call to check the internal token, if token is valid we will allow access, otherwise initial the OTP */
    const checkInternalTokenStatus = async () => {
        const timerToken = localStorage.getItem("timerToken")
        // const stepToken = localStorage.getItem("stepToken")
        if(timerToken){
            try{
                const response = await validateMFAToken_API(timerToken)
                localStorage.setItem("validateToken", response.headers.stepuptoken)
                dispatch(setOTPMenuStatus(false))
                dispatch(setOTPPassed(true))
                dispatch(setCheckInternalToken(false))
            }
            catch{
                getOTPFactors()
            }
        }
        else{
            getOTPFactors()
        }
    }

    return { windowState, step, setStep, handleCancel, verifySelection, setVerifySelection, factorOptions, chosenFactor, setChosenFactor,
             emailRef, openOTPModal }
}