import './MobileErrorAccountInformation.scss'
import overviewError from '../../../assets/overviewError.svg'

/**The mobile version error state that is displayed when the account information API fails */
const MobileErrorAccountInformation = () => {

    return(
        <div className="api-overview-error-mobile">
            <div className="error-display">
              <img className="image" src={overviewError} alt="Error Message" />
              <span className="top-message h4">Uh oh.. Something went wrong!</span>
              <span className="bottom-message regular">Unable to load data. Please wait a moment and try again</span>
            </div>
        </div>
    )
}

export default MobileErrorAccountInformation