import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { useGraceCancelFunds } from '../../../../useHooks/componentHooks/useGraceCancelFunds/useGraceCancelFunds'
import './CancelTransfersModal.scss'
import lightBlueBank from '../../../../assets/lightBlueBank.svg'
import { ICancelTransfersModalProps } from './interfaces/ICancelTransfersModalProps'
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'

/**Renders the modal to cancel a grace period fund transfer */
const CancelTransfersModal = (props: ICancelTransfersModalProps) => {
    const { windowState } = useWindowState()
    const { setOpenCancelFunds } = props
    const { selectedAccount, modalRef, handleOnYes, handleOnNo, transferErrored, handleClose } = useGraceCancelFunds(setOpenCancelFunds)

    return (
        <div data-testid="grace-cancel-modal" className="cancel-modal-wrapper">
            <div className={windowState !== "Mobile" ? "modal-wrapper" : "modal-wrapper mobile"} ref={modalRef}>
                {!transferErrored ?
                    <>
                    <img className="image" src={lightBlueBank} alt="house" />
                    <span className="header-message h4">Cancel transfer from {selectedAccount.gracePeriodFundTransfer.transferAccount}?</span>
                    <span className="warning-text regular">The action cannot be undone.</span>
                    {windowState !== "Mobile" ?
                        <div className="button-wrapper">
                            <button className="Button btn-secondary" onClick={() => handleOnNo()}>Keep transfer</button>
                            <button className="Button btn-primary" onClick={() => handleOnYes()}>Cancel transfer</button>
                        </div>
                        :
                        <div className="button-wrapper mobile">
                            <button className="Button btn-secondary mobile" onClick={() => handleOnNo()}>Keep transfer</button>
                            <button className="Button btn-primary mobile" onClick={() => handleOnYes()}>Cancel transfer</button>
                        </div>
                    }
                    </>
                    :
                    <>
                        <img src={redExclamationCircle} alt="warning circle" />
                        <span className="header-message h4">This transfer cannot be canceled</span>
                        <span className="warning-text regular">This transfer is already complete.</span>
                        {windowState !== "Mobile" ?
                            <div className="button-wrapper">
                                <button className="Button btn-primary" onClick={() => { handleClose() }}>Close</button>
                            </div>
                            :
                            <div className="button-wrapper mobile">
                                <button className="Button btn-primary mobile" onClick={() => { handleClose() }}>Close</button>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default CancelTransfersModal