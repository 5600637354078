import { useWindowState } from "../../../Context/AccountContext/useWindowState";
import { ILayoutPageProps } from "./interfaces/ILayoutCardProps";
import './LayoutCard.scss'

/**Creates a layout card for each page */
const LayoutCard = (props: ILayoutPageProps) => {
    const { windowState } = useWindowState()
    
    return (
        <div className={`page-layout-card-wrapper ${windowState}`}>
            <div className={`page-layout-card ${windowState}`}>
                {props.children}
            </div>
        </div>
    )
}

export default LayoutCard