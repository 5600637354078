import { IConsentAccount } from "../api/getAccountInformation/types/getAccountInformation.types"

/**Build the array for accounts that have terms and conditions requirements */
export const buildTermsAndConditions = (accountArray: any) => {
    const consentArray: Array<IConsentAccount> = []
    let temp: IConsentAccount = {id: "", term: "", newApy: "", currentBalance: "", renewedOn: "", accountType: "", lastFour: "", completed: false}
    for(let i = 0; i < accountArray.length; i = i + 1){
        if(accountArray[i].status === "Closed"){
            continue
        }
        if(!accountArray[i].termsAndCondition.isSigned && accountArray[i].status !== "Closed"){
            if(accountArray[i].accountType !== "Savings"){
                temp = {
                    id: accountArray[i].id,
                    term: (accountArray[i].productGroup && accountArray[i].productGroup === "BumpupCD") ? `Jumbo Bump-Up CD ${accountArray[i].term.split("M")[0]} months` : `Jumbo CD ${accountArray[i].term.split("M")[0]} months`,
                    newApy: accountArray[i].apy,
                    currentBalance: accountArray[i].currentBalance,
                    renewedOn: accountArray[i].createdDate,
                    accountType: accountArray[i].accountType,
                    lastFour: accountArray[i].accountNumber,
                    completed: false
                }
            }
            else{
                temp = {
                    id: accountArray[i].id,
                    term: "HYSA",
                    newApy: accountArray[i].apy,
                    currentBalance: accountArray[i].currentBalance,
                    renewedOn: accountArray[i].createdDate,
                    accountType: accountArray[i].accountType,
                    lastFour: accountArray[i].accountNumber,
                    completed: false
                }
            }
            consentArray.push(temp)
        }
    }

    return consentArray
}