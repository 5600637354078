import { useEffect, useRef, useState } from 'react';
import { IAccountInformation, ISelectedInterestAccount } from '../../../api/getAccountInformation';
import { IExternalAccounts } from '../../../api/User/interfaces';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { setInterestAccountToTransfer } from '../../../slices/selectedAccountSlice/selectedAccountSlice';
import { setAccountInformationIndexInterestTransfer } from '../../../slices/accountInformation/accountInformationSlice'
import { useHistory } from 'react-router-dom';
import { updateInterestSettings_API } from '../../../api/putInterestSettings';
import { useToastMessage } from '../../../Context/ToastContext/useToastContext';
import { setCheckInternalToken, setOTPPassed } from '../../../slices/userInformation/userInformationSlice';

/**
 * custom useHook for changing interest settings
 */
export const useInterestSettingsChange = (
    setChangeClicked: any,
    accountToTransfer: ISelectedInterestAccount | IExternalAccounts, 
    setAccountToTransfer: React.Dispatch<React.SetStateAction<ISelectedInterestAccount | IExternalAccounts>>
  ) => {
  const history = useHistory()
  const userInformation = useAppSelector((state: RootState) => state.userInformation)
  const [CDAccounts, setCDAccounts] = useState<IAccountInformation[]>([]);
  const [savingsAccounts, setSavingsAccounts] = useState<IAccountInformation[]>([]);
  const [activeSave, setActiveSave] = useState(false);
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
  const dispatch = useAppDispatch()
  const [showExternalAccounts, setShowExternalAccounts] = useState(selectedAccount.selectedInterestAccount.accountType === 'External');
  const { type, message, setToastMessage, resetToast } = useToastMessage()
  const linkExternalClicked = useRef<boolean>(false)
  const [showLimitModal, setShowLimitModal] = useState<boolean>(false)
  const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
  const saveClickedRef = useRef<boolean>(false)

  const getAccountByType = () => {
    const accounts = accountInformation;
    if (accounts) {
      let tempCD = [];
      let tempSavings = [];
      for (let i = 0; i < accounts.length; i++) {
        if (accounts[i].accountType === 'CD' && accounts[i].accountNumber === selectedAccount.accountNumber) {
          tempCD.push(accounts[i]);
        }
        if (accounts[i].accountType === 'Savings') {
          tempSavings.push(accounts[i]);
        }
      }
      setCDAccounts(tempCD);
      setSavingsAccounts(tempSavings);
    }
  };

  const determineActiveSave = () => {
    if (accountToTransfer.id !== selectedAccount.selectedInterestAccount.id) {
      setActiveSave(true);
    
    } else {
      setActiveSave(false);
    }
  };

  const convertAccountToNameTypeNumber = (
    selectedAccount: IAccountInformation
  ) => {
    return `${selectedAccount.accountTitle} - ${
      selectedAccount.accountType
    } ${selectedAccount.accountNumber.slice(
      selectedAccount.accountNumber.length - 4,
      selectedAccount.accountNumber.length
    )}`;
  };

  useEffect(() => {
    getAccountByType();
    if(userInformation.customerId === ""){
        // getUserInformation()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    determineActiveSave();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountToTransfer]);

  /** Update the account object on Save */
  const handleOnSave = async () => {
    if(!saveClickedRef.current){
      saveClickedRef.current = true
      const ACCOUNT_TYPE = Object.keys(accountToTransfer).indexOf("accountType") !== -1 ? (accountToTransfer as ISelectedInterestAccount)['accountType'] : false;
      const account = {
        accountId: accountToTransfer.id,
        accountSubType: ACCOUNT_TYPE ? "Internal" : "External",
        postingType: (ACCOUNT_TYPE && (accountToTransfer as ISelectedInterestAccount).accountType !== "CD")  || !ACCOUNT_TYPE ? "transfer" : "capitalize",
        accountType: "SDA"
      }
      try{
        await updateInterestSettings_API(selectedAccount.id, account)
        let index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
        let externalIndex = userInformation.externalAccounts.findIndex((account: any) => account.id === accountToTransfer.id)
        if(index >= 0){
            dispatch(setInterestAccountToTransfer(accountToTransfer))
            let payload = {accountIndex: index, accountToTransferTo: accountToTransfer}
            dispatch(setAccountInformationIndexInterestTransfer(payload))
        }
        if(externalIndex >= 0){
          dispatch(setInterestAccountToTransfer(accountToTransfer))
          let payload = {accountIndex: index, accountToTransferTo: accountToTransfer}
          dispatch(setAccountInformationIndexInterestTransfer(payload))
        }
        setToastMessage("SUCCESS", "Changes saved")
        setChangeClicked(false)
      }
      catch{
        console.log("ERROR UPDATE INTEREST SETTINGS")
        setToastMessage("ERROR", "Unable to save changes. Please try again later")
        resetToast()
      }
      saveClickedRef.current = false
    }
  }

  /**handles the linked external account button click */
  const handleLinkExternalAccount = () => {
    if(userInformation.externalAccounts.length > 2){
      setShowLimitModal(true)
    }
    else{
        linkExternalClicked.current = true
        dispatch(setCheckInternalToken(true))
        dispatch(setOTPPassed(false))
    }
  }

    /**monitors the success of the otpModal and sets the openEditPage state to open once the OTP passes */
    useEffect(() => {
      if(OTPPassed && linkExternalClicked.current){
          linkExternalClicked.current = false
          dispatch(setOTPPassed(false))
          history.push("/linkexternalaccount")
      }   
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**make sure the checkInternalToken key is set to false on render */
    useEffect(() => {
      dispatch(setCheckInternalToken(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return {
    showExternalAccounts,
    setShowExternalAccounts,
    CDAccounts,
    savingsAccounts,
    selectedAccount,
    convertAccountToNameTypeNumber,
    accountToTransfer,
    setAccountToTransfer,
    activeSave,
    linkedExternalAccounts: userInformation?.externalAccounts,
    handleOnSave,
    history,
    type, 
    message,
    handleLinkExternalAccount,
    showLimitModal,
    setShowLimitModal
  };
};
