import { useEffect } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { getEventPath } from "../../../utils/getEventPath"

/**A custom use hook for the max address modal found in the user profile add address flow */
export const useMaxAddressModal = (setShowMaxAddressModal: any) => {
    const { windowState } = useWindowState()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-card-wrap")) === -1){
                document.body.style.overflow = "auto";
                setShowMaxAddressModal(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Remove the scrollbar when the modal renders */
    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {document.body.style.overflow = "auto"}
    }, [])

    return { windowState }
}