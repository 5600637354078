import axios from 'axios'
import { bffBaseUrl, token } from '../utils/globalVariables'

const NOTIFICATION_URL = `${bffBaseUrl}/notifications`;

/**
 * Removes the notification banner
 * @param notificationId - The id of the notification.
 * @returns 
 */
export const patchRemoveNotification_API = async (notificationId: string) => {
  return axios.patch(`${NOTIFICATION_URL}/${notificationId}`, {
    displayNotification: false
  }, {
    headers: {
      Authorization: `Bearer ` + token(),
      'Content-Type': 'application/json'
    }
  });
}