import { useHistory } from "react-router-dom"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useVerificationLetter } from "../../useHooks/pageHooks/useVerificationLetter"
import DesktopVerificationLetter from "./DesktopVerificationLetter/DesktopVerificationLetter"
import MobileVerificationLetter from "./MobileVerificationLetter/MobileVerificationLetter"

/**Renders the account verification letter wrapper which determines which design to use: Mobile, Hybrid, Desktop */
const VerificationLetterWrapper = () => {
    const { windowState, selectedAccount } = useVerificationLetter()
    const history = useHistory();

    if(selectedAccount.accountClosed || selectedAccount.pendingGraceClosure) {
        history.push(`/accounts/${selectedAccount.id}`);
    }

    return(
        <div>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopVerificationLetter />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopVerificationLetter />
                    </HybridLayout>
                }
                </>
                :
                <MobileVerificationLetter />
            }
        </div>
    )
}

export default VerificationLetterWrapper