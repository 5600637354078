import { IMobileFilterProps } from './interfaces/IMobileFilterProps'
import './MobileFilter.scss'
import blueCheckmark from '../../../../../assets/blueCheckmark.svg'
import { useEffect } from 'react'
import { getEventPath } from '../../../../../utils/getEventPath'

/**Displays a filter used by the mobile version staements page, it is used to filter by year */
const MobileFilter = (props: IMobileFilterProps) => {
    const { filterYear, setFilterYear, statementYearsRef, setOpenFilterMenu, tempSelectedYear, setTempSelectedYear } = props

    useEffect(() => {
        document.body.style.overflow = "hidden"

        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("mobile-statement-filter-wrap")) === -1){
                document.body.style.overflow = "auto";
                setOpenFilterMenu(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener("click", handleClickOutside, true)
    }, [])

    return(
        <div className="mobile-statement-filter-wrap">
            <div className="header-row">
                <span className="blue-text mobileSmallSemibold" onClick={() => {setOpenFilterMenu(false); setTempSelectedYear(filterYear); document.body.style.overflow = "auto";}}>Back</span>
                <span className="date-text semiboldRegular">Date Range</span>
                <span className="blue-text mobileSmallSemibold" onClick={() => {setFilterYear(tempSelectedYear); setOpenFilterMenu(false); document.body.style.overflow = "auto";}}>Save</span>
            </div>
            <div className="year-display-wrap">
                {statementYearsRef.current.map((year: string, index: number) => {
                    return(
                        <div key={index}>
                            <div className="row-wrapper" onClick={() => setTempSelectedYear(year)}>
                                <span className={year === filterYear ? "year-text blue regular" : "year-text regular"} >{year}</span>
                                {tempSelectedYear === year && <img className="checkmark-symbol" src={blueCheckmark} alt="checkmark" />}
                            </div>
                            <div className="border-line" />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default MobileFilter