import './DesktopErrorAccountInformation.scss'
import overviewError from '../../../assets/overviewError.svg'

/**Error handling page that displays on the desktop version when the Account Information API fails */
const DesktopErrorAccountInformation = () => {

    return(
        <div className="api-error-state-account-overview">
            <div className="display-error">
                <div className="inner-display">
                    <img src={overviewError} alt="Error Message" />
                    <span className="top-message h3">Uh oh.. Something went wrong!</span>
                    <span className="lower-message regular">Unable to load data. Please wait a moment and try again</span>
                </div>
            </div>
        </div>        
    )
}

export default DesktopErrorAccountInformation