import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import './CurrentBalanceHeader.scss'
import informationIconWhite from '../../../assets/informationIconWhite.svg'
import { useState } from 'react'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { useWindowState } from '../../../Context/AccountContext/useWindowState'
import MobileAlertBox from '../../../components/MobileAlertBox/MobileAlertBox'

const CurrentBalanceHeader = () => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false)
    const { windowState } = useWindowState()

    return (
        <>
        {windowState === "Mobile" && <div className="current-balance-top-border-wrap"><div className="current-balance-top-border" /></div>}
        <div className={`current-balance-header-wrap ${windowState}`}>
            {isAccountDetailsLoaded !== "Loading" ?
            <>
                {windowState !== "Mobile" ?
                    <>
                    <span className="current-balance h1">
                        <span className="dollar-symbol">$</span>
                        {selectedAccount.currentBalance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).substring(1)}
                    </span>
                    <div className={`current-balance-text-wrap ${windowState}`}>
                        <span className="current-balance-text small">Current balance</span>
                        <img src={informationIconWhite} alt="tooltip" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} />
                        {showTooltip &&
                            <Tooltip
                                anchorPoint='top-right'
                                bodyMessage="Current Balance includes deposits that have posted but are not yet available. Pending debits are not deducted from this amount."
                                headerMessage='What is Current Balance?'
                                width="256px"
                            />
                        }
                    </div>
                    </>
                    :
                    <>
                        <div className={`current-balance-text-wrap ${windowState}`}>
                            <span className="current-balance-text small">Current balance</span>
                            <img src={informationIconWhite} alt="tooltip" onClick={() => setShowTooltip(true)} />
                            {showTooltip &&
                                <MobileAlertBox 
                                    setStateAction={setShowTooltip}
                                    headerMessage="What is Current Balance?"
                                    bodyMessage="Current Balance includes deposits that have posted but are not yet available. Pending debits are not deducted from this amount."
                                />                        
                            }
                        </div>
                        <span className="current-balance mobileh2">
                            ${selectedAccount.currentBalance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            }).substring(1)}
                        </span>
                    </>
                }
            </>
            :
            <>
                <div className={`loading-box-wrap ${windowState}`}>
                    <div className={`loading-box top ${windowState}`} />
                    <div className={`loading-box bottom ${windowState}`} />
                </div>
            </>
            }
        </div>
        </>
    )
}

export default CurrentBalanceHeader