import { RadioButton } from '../../../../components/RadioButton/RadioButton'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { useGraceChangeCD } from '../../../../useHooks/componentHooks/useGraceChangeCD/useGraceChangeCD'
import './ChangeCD.scss'
import { IChangeCDProps } from './interfaces/IChangeCDProps'
import information from '../../../../assets/information.svg'
import { IProduct } from '../../../../api/getAccountInformation'
import Tooltip from '../../../../components/Tooltip/Tooltip'
// import { AlertMessage } from '../../../../components/AlertMessage/AlertMessage'
import MobileAlertBox from '../../../../components/MobileAlertBox/MobileAlertBox'

/**Renders the Change CD page found within the Grace Period flow */
const ChangeCD = (props: IChangeCDProps) => {
    const { windowState } = useWindowState()
    const { chosenTerm, setChosenTerm, setShowTermChange, setChangeCD } = props
    const { jumboCDs, bumpCDs, enableContinue, setEnableContinue, handleCancel, onChangeHandler, showTooltip, setShowTooltip } =useGraceChangeCD(chosenTerm, setChosenTerm)

    const month = " months"

    return (
        <>
        {windowState === "Mobile" &&
            <div className="mobile-cd-header-wrap">
                <span className="cancel-text regular" onClick={() => {setChangeCD(false); handleCancel()}}>Cancel</span>
            </div>
        }
        <div className={windowState !== "Mobile" ? "grace-cd-select-wrap" : "grace-cd-select-wrap mobile"}>
        <div className={windowState !== "Mobile" ? "change-cd-wrapper" : "change-cd-wrapper mobile"}>
            <div className="change-cd-card">
                <div className="header-wrap">
                    <span className="title-text small">Change term</span>
                    <span className="sub-title-text h3">Select a term for your CD</span>
                </div>
                <div className="grace-display-options" >
                    <div className={windowState !== "Mobile" ? "grace-cd-options" : "grace-cd-options mobile"} >
                        <div className="grace-cd-group">
                            <div className="cd-header-wrap">
                                <span className="header-text h4">Jumbo CD</span>
                            </div>
                            {jumboCDs.map((cd: IProduct, index: number) => {
                                return (
                                    <div data-testid={`Jumbo CD ${cd.name}`} key={index} className={chosenTerm?.code === cd.code ? "row-wrapper selected" : "row-wrapper"} onClick={() => {setChosenTerm(cd); setEnableContinue(true)}}>
                                        <RadioButton 
                                            value={cd.name.split("")[0] + month}
                                            name="new term"
                                            className="radio"
                                            onChange={() => onChangeHandler(cd)}
                                            checked={chosenTerm?.code === cd.code}
                                            textAlign="Right"
                                        >{cd.name.toLowerCase().split(" ")[0] + month as any}</RadioButton>
                                        <span className="show-apy regular">{cd.apy.substring(0,4)}% APY</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="grace-cd-group">
                            <div className="cd-header-wrap">
                                <span className="header-text h4">Jumbo Bump-Up CD</span>
                                <div className="tooltip-wrap">
                                    {windowState !== "Mobile" ?
                                        <img data-testid="grace-tooltip" className="information-image" src={information} alt="information" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}/>
                                        :
                                        <img data-testid="grace-tooltip" className="information-image" src={information} alt="information" onClick={() => setShowTooltip(true)}/>
                                    }
                                    {showTooltip &&
                                        <>
                                        {windowState !== "Mobile" ?
                                            <div className="tooltip">
                                                <Tooltip 
                                                    anchorPoint="top-right"
                                                    bodyMessage="Our Jumbo Bump-Up CD lets you to take advantage of rising interest rates with a one-time rate increase option. See the FAQs for more information."
                                                    headerMessage=""
                                                    width="356px"
                                                />
                                            </div>
                                            :
                                            <div data-testid="grace-overlay" className="overlay">
                                                <MobileAlertBox 
                                                    setStateAction={setShowTooltip}
                                                    headerMessage="Bump-Up Jumbo CD"
                                                    bodyMessage="Our Jumbo Bump-Up CD lets you to take advantage of rising interest rates with a one-time rate increase option. See the FAQs for more information."
                                                />
                                            </div>
                                        }
                                        </>
                                    }
                                </div>
                            </div>
                            {bumpCDs.map((cd: IProduct, index: number) => {
                                return (
                                    <div data-testid={`Jumbo CD ${cd.name}`} key={index} className={chosenTerm?.code === cd.code ? "row-wrapper selected" : "row-wrapper"} onClick={() => {setChosenTerm(cd); setEnableContinue(true)}}>
                                        <RadioButton 
                                            value={cd.name.split(" ")[0] + month}
                                            name="new term"
                                            className="radio"
                                            onChange={() => onChangeHandler(cd)}
                                            checked={chosenTerm?.code === cd.code}
                                            textAlign="Right"
                                        >{cd.name.toLowerCase().split(" ")[0] + month as any}</RadioButton>
                                        <span className="show-apy regular">{cd.apy.substring(0,4)}% APY</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <span className="rate-text regular">*Rates shown here include your .05% Loyalty Rate increase.</span>
                    {windowState !== "Mobile" ?
                        <div className="button-wrap">
                            <button className="cancel-button boldButtonLarge" onClick={() => {setChangeCD(false); handleCancel()}}>Cancel</button>
                            <button className={enableContinue ? "continue-button boldButtonLarge" : "continue-button disabled boldButtonLarge"} onClick={() => {if(enableContinue){setShowTermChange(true); setChangeCD(false)}}}>Continue</button>
                        </div>
                        :
                        <div className="mobile-continue-button-wrap">
                            <button className={enableContinue ? "mobile-continue-button mobileButtonBold" : "mobile-continue-button disabled mobileButtonBold"} onClick={() => {if(enableContinue){setShowTermChange(true); setChangeCD(false)}}}>Continue</button>
                        </div>
                    }
                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default ChangeCD