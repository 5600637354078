/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getProductList_API, IAccountInformation } from "../../../api/getAccountInformation";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { getAccountInformationAsync, getProductsAsync, setAccountInformationByIndex, updateAccountStatementsByIndex, updateBeneficiaryArrayByIndex, updateProductList } from "../../../slices/accountInformation/accountInformationSlice";
import { getAccountBeneficiariesAsync, getAccountDetailsAsync, getAccountStatementsAsync } from "../../../slices/selectedAccountSlice/selectedAccountSlice";
import { getTrustedDevicesAsync, getUserInfoAsync, getExternalAccountsAsync, setExternalAccountNicknameByIndex, updateUserNickname } from "../../../slices/userInformation/userInformationSlice";
import { populateStatements } from "../../../utils/populateStatements";
import { useSendSatelliteOnRouteChange } from './useSendSatelliteOnRouteChange/useSendSatelliteOnRouteChange';
import { getExternalAccountNickname_API } from "../../../api/User/LinkExternalAccounts";
import { getUserNickname_API } from "../../../api/User/userNickname";
// import { getUserInfoAsync } from '../../../slices/userInformation'

/**
* custom hook used for the SideNavigation component.  This custom hook will hold all of the
* logic needed in both the Dekstop and Mobile versions of this component.
* @return 
*/
export const useSideNavigation = () => {
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [focus, setFocus] = useState<"Accounts" | "Transfers" | "Documents" | "Profile">("Accounts")
    const [focusIndex, setFocusIndex] = useState<string>("")
    const history = useHistory()
    const dispatch = useAppDispatch();
    const url = window.location.pathname
    const userInfo = useAppSelector((state: RootState) => state.userInformation)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    const isAccountInformationLoaded = useAppSelector((state: RootState) => state.accountInformation.isAccountInformationLoaded)
    useSendSatelliteOnRouteChange();
    const selectedClickedRef = useRef<boolean>(false)

    /**
     * Sets the focusIndex and focus states for the side navigation component
     */
    useEffect(() => {
        const adjustedUrl = window.location.pathname.split('/')[2]
        const locationUrl = window.location.pathname.split('/')[1]
        if (adjustedUrl) {
            if (adjustedUrl !== "profile") {
                setFocusIndex(adjustedUrl)
            }
            else {
                setFocus("Profile")
            }
        }
        if (locationUrl === "documents") {
            setFocus("Documents")
        }
        if (locationUrl === 'transfers') {
            setFocus("Transfers")
        }
        if (focusIndex && focusIndex !== "" && focus !== "Documents" && focus !== "Transfers" && focus !== "Profile") {
            setFocus("Accounts")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, focusIndex, url])

    /**
     * Call the account information API for the event that a page has been reloaded
     */
    useEffect(() => {
        if (accountInformation[0].accountNumber === "") {
            dispatch(getAccountInformationAsync(""))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Call to get the external accounts belonging to the customer */
    useEffect(() => {
        if (userInfo.gotExternalAccounts === "Loading") {
            getUserNickname()
            getUserInfo()
            getExternalAccounts()
            getProductsList()
            getTrustedDevices()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**Calls to get the external accounts */
    const getExternalAccounts = async () => {
        try {
            dispatch(getExternalAccountsAsync())
        }
        catch {
            console.log("ERROR GETTING EXTERNAL ACCOUNTS")
        }
    }

    /**Calls to get the Product List */
    const getProductsList = async () => {
        try {
            dispatch(getProductsAsync())
            // const response = await getProductList_API()
            // const payload = { CDProductList: response.data.jumboCD, BumpProductList: response.data.bumbupCD, SavingsProductList: response.data.savings }
            // dispatch(updateProductList(payload))
        }
        catch {
    
            if (process.env.NODE_ENV !== 'test') {
                console.log("ERROR RETREIVING PRODUCT LIST")
            }
        }
    }

    /**Calls to get the user information */
    const getUserInfo = async () => {
        try {
            await dispatch(getUserInfoAsync())
        }
        catch(err) {
            console.log("ERROR RETREIVING THE USER INFORMATION", err)
        }
    }

    /**Calls to get the users trusted devices */
    const getTrustedDevices = async () => {
        try{
            await dispatch(getTrustedDevicesAsync())
        }
        catch{
            console.log("ERROR RETREIVING TRUSTED DEVICES")
        }
    }

    /**Calls to get the users nickname */
    const getUserNickname = async () => {
        try{
            const response = await getUserNickname_API()
            dispatch(updateUserNickname(response.data.nickName))
        }
        catch{
            console.log("UNABLE TO RETREIVE THE USERS NICKNAME")
        }
    }

    /**Handles the onClick event for the account boxes */
    const handleOnClick = async (account: any) => {
        if(!selectedClickedRef.current){
            selectedClickedRef.current = true
            // if (account.isGracePeriod) {
                const index = accountInformation.findIndex((accountInfo: IAccountInformation) => account.id === accountInfo.id)
                const payload = { arrayIndex: index, account: account }
                const apiData = {selectedAccountId: account.id, dateFilter: 'All'}
                await dispatch(getAccountDetailsAsync(payload))
                .then((response: any) => {
                    history.push(`/accounts/${account.id}`)
                    setFocusIndex(account.id)
                    setFocus("Accounts")
                })
                .catch((error: any) => console.error())
                await dispatch(getAccountBeneficiariesAsync(account.id))
                .then((response: any) => {
                    const payload = {arrayIndex: index, beneficiaryArray: response.payload.beneficiaries}
                    dispatch(updateBeneficiaryArrayByIndex(payload))    
                })
                .catch((error: any) => console.error())
                await dispatch(getAccountStatementsAsync(apiData))
                .then((response: any) => {
                    const payload = {arrayIndex: index, statements: populateStatements(response.payload.response.documentIds)}
                    dispatch(updateAccountStatementsByIndex(payload))    
                })
                .catch((error: any) => console.error())
            // }
            selectedClickedRef.current = false
        }
    }

    /**updates the accountInformation array with the account details when the selected account changes */
    useEffect(() => {
        const index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
        if (index >= 0 && !accountInformation[index].gotAccountDetails && selectedAccount.term !== "") {
            let tempAccount = JSON.parse(JSON.stringify(selectedAccount))
            tempAccount.gotAccountDetails = true
            const payload = { arrayIndex: index, account: tempAccount }
            dispatch(setAccountInformationByIndex(payload))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    return { history, focus, setFocus, focusIndex, setFocusIndex, accountInformation, dispatch, handleOnClick };
} 
