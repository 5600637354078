import MobileDocumentsDisplayHeader from '../../../Pages/Documents/MobileDocumentsPage/components/MobileDocumentsDisplayHeader/MobileDocumentsDisplayHeader'
import { IMobileDocumentAccountStatementsProps } from './interfaces/IMobileDocumentAccountStatementsProps'
import './MobileDocumentAccountStatements.scss'
import MobileDocumentCenterFilter from '../../../Pages/Documents/MobileDocumentsPage/components/MobileDocumentCenterFilter/MobileDocumentsFilter'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useDocumentAccountStatements } from '../../../useHooks/componentHooks/useDocumentAccountStatements/useDocumentAccountStatements'
import blueDocument from '../../../assets/blueDocument.svg'
import emptyStatements from '../../../assets/emptyStatements.svg'
import warning from '../../../assets/warning.svg'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'

/**
 * The component displays the mobile version of the Document Center Account Statements page
 */
const MobileDocumentAccountStatements = (props: IMobileDocumentAccountStatementsProps) => {
    const { selectedAccount, selectedPage, setSelectedPage, accountInformation, dateSelections, selectedDate, setSelectedDate, handleSave, filteredAccounts } = props
    const { gotStatements, handleStatementDownload, type, message } = useDocumentAccountStatements(selectedDate)

    return(
        <div className="mobile-document-account-statements">
            <MobileDocumentsDisplayHeader selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <div className="mobile-account-statements-list">
                <MobileDocumentCenterFilter 
                    selectedAccount={selectedAccount} 
                    accountInformation={accountInformation}
                    dateSelections={dateSelections}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedPage={selectedPage}    
                    handleSave={handleSave}
                />
                <div className="statements-wrap">  
                    {gotStatements !== "Failed" ?
                        <>
                        {gotStatements !== "Loading" ? 
                            <>
                            {filteredAccounts.length > 0 ?
                                <>
                                {filteredAccounts.map((statement: any, index: number) => {
                                    return(
                                        <div key={index} className="account-row" onClick={() => handleStatementDownload(statement.id)}>
                                            <img className="download-image" src={blueDocument} alt="download" />
                                            <span className="account-statement-text regular" >Statements {statement.date} - PDF</span>
                                        </div>
                                    )
                                })}
                                </>
                                :
                                <div className="message-wrapper">
                                    <img className="message-image" src={emptyStatements} alt="no statements" />
                                    <span className="header-text margin h4">No statements to display</span>
                                </div>
                            }
                            </>
                            :
                            <div className="loading-box" />
                        }
                        </>
                        :
                        <div className="message-wrapper">
                            <img className="message-icon" src={warning} alt="failed to load data" />
                            <span className="header-text h4">Unable to load data</span>
                            <span className="sub-text regular">Please wait a moment and try again.</span>
                        </div>
                    }
                </div>
            </div>
            {message !== "" && <ToastMessageDB type={type} message={message} /> }
        </div>
    )
}

export default MobileDocumentAccountStatements