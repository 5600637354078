/* eslint-disable jsx-a11y/anchor-is-valid */
import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import { useAccountSettings } from '../../../useHooks/pageHooks/useAccountSettings'
import beneficiarySettings from '../../../assets/beneficiarySettings.svg'
import maturitySettings from '../../../assets/maturitySettings.svg'
import manageInterest from '../../../assets/manageInterest.svg'
import arrowRight from '../../../assets/arrowRight.svg'
import bumpBlueBackground from '../../../assets/bumpBlueBackground.svg'
import './DesktopAccountSettings.scss'
import verificationLetter from '../../../assets/verificationLetter.svg'
import { dateLongHand } from '../../../utils/dateLongHand'
import redTrashCan from '../../../assets/redTrashCan.svg'
import { setStartBeneficiaryFlow } from '../../../slices/selectedAccountSlice/selectedAccountSlice'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { NavLink } from 'react-router-dom'

/**
 * This is the desktop version of the Account Settings page
 */
const DesktopAccountSettings = () => {
    const { history, selectedAccount, dispatch, isAccountDetailsLoaded } = useAccountSettings()
    
    return(
        <div className="Landing-Wrapper">
            <div className="card-wrapper account-header">
                {!selectedAccount.earlyWithdrawalSelected ?
                    <AccountHeader returnMessage='Account Details' setDisplayDetails={false}/>
                    :
                    <AccountHeader returnMessage='Account Details' setDisplayDetails={false} showPill={true}/>
                }
            </div>
            <div className="card-wrapper account-settings">
                <div className="container">
                    {isAccountDetailsLoaded !== "Loading" ?
                        <div className="desktop">
                            <div className="content-wrapper">
                                <h1>Account settings</h1>
                                <div className="show-cards-wrap">
                                    {selectedAccount.accountType !== "Savings" && 
                                    <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/interest`)}>
                                        <div className="left-side">
                                        <img src={manageInterest} alt="Manage Interest" />
                                            <div className="text-wrap">
                                                <p className="title boldRegular">Manage interest payments</p>
                                                <p>Change where your interest payments are deposited</p>
                                            </div>
                                        </div>
                                        <img className="arrow" src={arrowRight} alt="Open" />
                                    </div>
                                    }
                                    {selectedAccount.accountType !== "Savings" &&
                                    <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`)}>
                                        <div className="left-side">
                                            <img src={maturitySettings} alt="Maturity Settings" />
                                            <div className="text-wrap">
                                                <p className="title boldRegular">Maturity plan</p>
                                                <p>Choose what happens when your CD matures</p>
                                            </div>
                                        </div>
                                        <img className="arrow" src={arrowRight} alt="Open" />
                                    </div>
                                    }
                                    <div className="card" onClick={() => {dispatch(setStartBeneficiaryFlow(false)); history.push(`/account/${selectedAccount.id}/beneficiary`)}}>
                                        <div className="left-side">
                                            <img src={beneficiarySettings} alt="Beneficiary Settings" />
                                            <div className="text-wrap">
                                                <p className="title boldRegular">Beneficiaries</p>
                                                <p>Add or manage beneficiaries and allocation amounts.</p>
                                            </div>
                                        </div>
                                        <img className="arrow" src={arrowRight} alt="Open" />
                                    </div>
                                    <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/verificationletter`)}>
                                        <div className="left-side">
                                            <img src={verificationLetter} alt="Verification Letter" />
                                            <div className="text-wrap">
                                                <p className="title boldRegular">Account verification letter</p>
                                                <p>Download an account verification letter</p>
                                            </div>
                                        </div>
                                        <img className="arrow" src={arrowRight} alt="Open" />
                                    </div>
                                    {selectedAccount.cdGroup === "BumpupCD" &&
                                    <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/bumprate`)}>
                                        <div className="left-side">
                                            <img src={bumpBlueBackground} alt="Bump Rate Settings" />
                                            <div className="text-wrap">
                                                <span className="title boldRegular">Bump rate</span>
                                                <>
                                                {!selectedAccount.bumpActivated ?
                                                    <>
                                                    {selectedAccount.isBumpAvailable ? 
                                                        <p className="Text regular">Your CD is eligible for a one-time rate bump from {selectedAccount.originalApy.toFixed(2)}% APY to {selectedAccount.bumpedRate.toFixed(2)}% APY</p>
                                                        :
                                                        <p className="Text regular">No rate increase available—you already have the best rate!</p>
                                                    }
                                                    </>
                                                    :
                                                    <p className="Text regular">You bumped your rate on {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.bumpedRateDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.</p>
                                                }
                                                </>
                                            </div>
                                        </div>
                                        <div className="right-side">
                                            {selectedAccount.bumpActivated && <span className="activated-pill mobile-h6">Activated</span>}
                                            <img className="arrow" src={arrowRight} alt="Open" />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="desktop-settings-loading-state">
                            <div className="loading-box" />
                        </div>
                    }
                    {selectedAccount.accountType !== "Savings" &&
                        <div className="early-withdrawl-breakpoint-wrap">
                            {!selectedAccount.earlyWithdrawalSelected ?
                                <div className="early-withdrawal-wrap">
                                    <p><NavLink to={`/account/${selectedAccount.id}/earlywithdrawal`} className='blue-text'>Close your CD early.</NavLink>&nbsp;Penalties apply, and your request is subject to approval.</p>
                                </div>
                                :
                                <div className="cancel-early-withdrawal-wrap" onClick={() => history.push(`/account/${selectedAccount.id}/earlywithdrawal`)}>
                                    <img src={redTrashCan} alt="remove" />
                                    <span className="cancel-text boldLarge">Cancel Early Withdrawal</span>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DesktopAccountSettings