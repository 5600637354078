import { useState, useRef, useEffect, useCallback } from "react";
import closeIcon from '../../../assets/closeIcon.svg';
import { IToastMessageProps } from './IToastMessageProps.types';
import { useToastMessage } from "../../../Context/ToastContext/useToastContext";

/**
 * Displays a ToastMessage at the bottom of the page.
 * @param props 
 * @returns ToastMessage Component
 */
export const ToastMessage = (props: IToastMessageProps) => {
  const { timeout = 4000,
    className = '',
    onClick = () => { },
    message = '', 
    disableTimer} = props;

  const [isShown, setIsShown] = useState(true);
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const isLoaded = useRef(false);
  const { clearToast } = useToastMessage()

  const handleOnClick = useCallback(() => {
    // if(disableTimer !== undefined && !disableTimer){
      setIsShown(false);
      clearToast()
    // }
    onClick();
  }, [setIsShown, onClick])

  useEffect(() => {
    isLoaded.current = true;
    if(disableTimer && !disableTimer){
      setTimeout(() => {
        if (!isLoaded.current) {
          return;
        }
          handleOnClick();
      }, timeout)
    }

    const node = buttonRef.current;

    if (node) {
      node.focus();
    }

    return () => {
      isLoaded.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isShown) {
    return (
      <div className={className} style={{
        animationDelay: `${timeout}ms`,
        animationDuration: !disableTimer ? `${timeout / 2}ms` : "100000ms"
      }}>
        <div role="alert">
          <span>{message}</span>
        </div>
        <button ref={buttonRef}  onClick={handleOnClick} >
          <img data-testid="toast-message-close" className="close-icon" src={closeIcon} alt="close" />
        </button>
      </div>
    )
  }

  return null;
}