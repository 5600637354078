import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import './MobileEarlyWithdrawal.scss'
import MobileEarlyWithdrawalLanding from '../components/EarlyWithdrawalLanding/MobileEarlyWithdrawalLanding/MobileEarlyWithdrawalLanding'
import { IMobileEarlyWithdrawalProps } from './interfaces/IMobileEarlyWithdrawalProps'
// import MobileLaunchEarlyWithdrawal from '../components/LaunchEarlyWithdrawal/MobileLaunchEarlyWithdrawal/MobileLaunchEarlyWithdrawal'
// import MobileConfirmRequest from '../components/ConfirmRequest/MobileConfirmRequest/MobileConfirmReqeust'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
// import MobileManageEarlyWithdrawal from '../components/ManageEarlyWithdrawal/MobileManageEarlyWithdrawal/MobileManageEarlyWithdrawal'

/**Early withdrawal flow for the mobile design */
const MobileEarlyWithdrawal = (props: IMobileEarlyWithdrawalProps) => {
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const { confirmEarlyWithdrawal, setConfirmEarlyWithdrawal, confirmRequest, setConfirmRequest, transferAccount, setTransferAccount,
            openQuestionPage, setOpenQuestionPage } = props

    return (
        <MobileLayout>
            <div className="mobile-early-withdrawal">
                {(!confirmEarlyWithdrawal && !selectedAccount.earlyWithdrawalSelected) &&
                    <MobileEarlyWithdrawalLanding 
                        setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                    />
                    // :
                    // <>
                    // {!confirmEarlyWithdrawal &&
                    //     <MobileManageEarlyWithdrawal
                    //         setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                    //     />
                    // }
                    // </>
                }
                {/* {confirmEarlyWithdrawal &&
                <>
                    {!confirmRequest ?
                        <MobileLaunchEarlyWithdrawal 
                            setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                            setConfirmRequest={setConfirmRequest}
                            transferAccount={transferAccount}
                            setTransferAccount={setTransferAccount}
                        />
                        :
                        <MobileConfirmRequest 
                            setConfirmRequest={setConfirmRequest}
                            transferAccount={transferAccount}
                            openQuestionPage={openQuestionPage}
                            setOpenQuestionPage={setOpenQuestionPage}
                        />
                    }
                </>
                } */}
            </div>
        </MobileLayout>
    )
}

export default MobileEarlyWithdrawal