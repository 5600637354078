import { useRef } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { useLinkExternalAccount } from "../../../Pages/LinkExternalAccount/Context/useLinkExternalAccount"
import { initiateMicroTransactions_API } from "../../../api/User/LinkExternalAccounts"
import { useAppDispatch } from "../../../app/hooks"
import { addExternalAccount } from "../../../slices/userInformation/userInformationSlice"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useHistory } from "react-router"
import { IEDPErrorInterface, edpErrorReporting_API } from "../../../api/edpErrorReporting"

/**A custom use hook holding hte logic for account verification component in link external account flow */
export const useAccountVerification = () => {
    const { windowState } = useWindowState()
    const { setStep, newExternalAccount, setNewExternalAccount } = useLinkExternalAccount()
    const dispatch = useAppDispatch()
    const continueClickedRef = useRef<boolean>(false)
    const { setToastMessage, resetToast } = useToastMessage()
    const history = useHistory()

    /**Handles the click event for the Continue button */
    const handleContinue = async () => {
        if(!continueClickedRef.current){
            continueClickedRef.current = true
            try {
                await initiateMicroTransactions_API(newExternalAccount.id)
                dispatch(addExternalAccount(newExternalAccount))
                setNewExternalAccount({id: "", accountNickName: "", status: "", verifiedDate: "", verifiedBy: "", lastFour: "", description: "", routingNumber: "", type: ""})
                setStep(4)
                continueClickedRef.current = false
            }
            catch(err: any) {
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
                let errorReturn: any = err.response ? JSON.parse(JSON.stringify(err.response)) : ""
                let edpErrorObject: IEDPErrorInterface = {
                    category: "External_Account",
                    errorType: "API_error",
                    errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""}, {message: "MANUAL VERIFICATION"}],
                    timeStamp: new Date().toISOString(),
                    additionalDetails: {
                        externalAccounts: [{
                            acctNo: newExternalAccount.lastFour,
                            acctId: newExternalAccount.id,
                            routingNo: newExternalAccount.routingNumber,
                            bankName: newExternalAccount.accountNickName !== "" ? newExternalAccount.accountNickName : newExternalAccount.description || "",
                            errorCode: errorReturn.status || "",
                            verificationMethod: "MD"
                        }]    
                    }
                }
                try{
                    edpErrorReporting_API(edpErrorObject)
                }
                catch{}      
                continueClickedRef.current = false
            }
        }
    }

    /**Handles the cancel button on Click event */
    const handleCancel = () => {
        setNewExternalAccount({id: "", accountNickName: "", status: "", verifiedDate: "", verifiedBy: "", lastFour: "", description: "", routingNumber: "", type: ""})
        history.push("/user/profile/external-accounts")
    }

    return { windowState, handleContinue, handleCancel }
}