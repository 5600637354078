import { dateLongHand } from "./dateLongHand";
import { convertDateTommddyyyy } from "./DateUtils";

/**Calculates the maturity date based off the creation date and term length*/
export const calculateMaturityDate = (creationDate: string, term: string) => {
    let [year, month, day] = creationDate.split('-')
    if(day){
        day = day.split('T')[0]
    }

    const tempMaturity = new Date(+year, +month + parseInt(term) - 1, +day).toISOString()
    
    return tempMaturity
}
