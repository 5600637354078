import { useStatementsCard } from '../../useHooks/componentHooks/useStatementsCard/useStatementsCard';
import { Card } from '../Card/Card';
import { IStatementsCardProps } from './interfaces/IStatementsCardProps'; 
import './StatementsCard.scss'; 
import arrowRight from '../../assets/arrowRight.svg'
import { IStatements } from '../../api/getStatements';
import warning from '../../assets/warning.svg'
import emptyStatements from '../../assets/emptyStatements.svg'

/** 
 * The component gets and displays the Summary Statements Card for both desktop and mobile
 * @param selectedAccount = IAccountInformation
 */ 
export const StatementsCard = (props: IStatementsCardProps) => {  
  const { selectedAccount } = props
  const { history, gotStatements, handleStatementDownload } = useStatementsCard(selectedAccount)

  return ( 
    <>
    {gotStatements !== "Loading" ?
      <Card width={"352px"} height={"444px"} showTopBorder={true} topBorderColor={"var(--secondaryDarkBlue)"} >
        {gotStatements === "Success" ?
          <div className="statements-card"> 
            <div className="card-information">
            <div className="header-wrap">
              <span className="card-title boldLarge">Statements</span>
              <button data-testid="view-all-button" className="manage-button semiboldRegular" onClick={() => history.push(`/account/${selectedAccount.id}/statements`, {page: "Account Details"})}>View all</button>
            </div>
              <div className="display-statements">
                {selectedAccount.accountStatements && selectedAccount.accountStatements.length > 0 ?
                  <div className="display-statements">
                    {selectedAccount.accountStatements && selectedAccount.accountStatements.length < 4 ?
                      <>
                      {selectedAccount.accountStatements?.map((statement: IStatements, index: number) => {
                        return (
                          <div key={index} className="statement-row" onClick={() => handleStatementDownload(statement.id)}>
                            <div className="left-side">
                              <span className="statement-date regular">{statement.date}</span>
                            </div>
                            <img data-testid="download-chevron" className="arrow-icon" src={arrowRight} alt="View" />
                          </div>
                        )
                      })}
                      </>
                      :
                      <>
                      {selectedAccount.accountStatements?.slice(0,4).map((statement: IStatements, index: number) => {
                        return (
                          <div key={index} className="statement-row" onClick={() => handleStatementDownload(statement.id)}>
                            <div className="left-side">
                              <span className="statement-date regular">{statement.date}</span>
                            </div>
                            <img data-testid="download-chevron" className="arrow-icon" src={arrowRight} alt="View" />
                          </div>
                        )
                      })}
                      </>
                    }
                  </div>
                  :
                  <div className="empty-statements-wrap">
                    <img src={emptyStatements} alt="empty statements" />
                    <span className="text boldRegular">No statements yet</span>
                    <span className="text regular">Statements will be listed here when they’re ready.</span>
                  </div>
                }
              </div>
            </div>
          </div> 
          :
          <div className="card-error-state">
            <span className="title-text boldLarge">Statements</span>
            <div className="image-wrap">
              <img className="error-image" src={warning} alt="error" />
              <span className="sub-title-text boldButtonLarge">Unable to load data</span>
              <span className="text regular">Unable to load data. Please wait a moment and try again</span>
            </div>
          </div>
        }
      </Card>
      :
      <div className="card-loading-state" />
    }
    </>
  ); 
}; 
