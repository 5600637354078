import { useRef, useEffect } from 'react';
import { useWindowState } from '../../../../Context/AccountContext/useWindowState';
import { useCustomDateFilterContext } from './context/CustomDateFilterContext';

export const useCalendarRef = () => {
  const calendarRef = useRef<any>()
  const { windowState } = useWindowState();
  const { setIsDatePickerOpen, setBoxSelection } = useCustomDateFilterContext();

  /**this is just an event listener looking for a click outside of the calendar, if it happens the calendar closes */
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target) && windowState !== 'Mobile') {
        setIsDatePickerOpen(false)
        setBoxSelection("")
      }
    }

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return calendarRef;
}
