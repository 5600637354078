import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import './DesktopStatementsPage.scss'
import DesktopStatementsLanding from '../../../components/Statements/DesktopStatementsLanding/DesktopStatementsLanding'
import { IDesktopStatementsPageProps } from './interfaces/IDesktopStatementsPageProps'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import warning from '../../../assets/warning.svg'

/**
 * The compnent renders the desktop version of the statements page.  It shows all statements related
 * to a particular account.
 */
const DesktopStatementsPage = (props: IDesktopStatementsPageProps) => {
    const { filterYear, setFilterYear, filteredStatements, setShowPDF, statementYearsRef, openFilterMenu,
        setOpenFilterMenu, selectedAccount, startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow,
        setNumberOfItemsToShow } = props

    const gotStatements = useAppSelector((state: RootState) => state.selectedAccount.gotStatements)

    return (
        <div className="Landing-Wrapper">
            <div className="card-wrapper account-header">
                <AccountHeader returnMessage={'Account Details'} setDisplayDetails={false} />
            </div>
            <div className="card-wrapper statements">
                <div className="container">
                    {gotStatements !== "Loading" ?
                        <div className="desktop">
                            <div className="content-wrapper">
                                {gotStatements === "Success" ?
                                    <>
                                        <div className="title-lockup">
                                            <h1>Statements</h1>
                                            <p>Download your account statements</p>
                                        </div>
                                        <div className="grey-line"></div>
                                        <div className="content">
                                            <DesktopStatementsLanding
                                                setFilterYear={setFilterYear}
                                                filterYear={filterYear}
                                                filteredStatements={filteredStatements}
                                                setShowPDF={setShowPDF}
                                                statementYearsRef={statementYearsRef}
                                                openFilterMenu={openFilterMenu}
                                                setOpenFilterMenu={setOpenFilterMenu}
                                                selectedAccount={selectedAccount}
                                                startIndex={startIndex}
                                                setStartIndex={setStartIndex}
                                                endIndex={endIndex}
                                                setEndIndex={setEndIndex}
                                                numberOfItemsToShow={numberOfItemsToShow}
                                                setNumberOfItemsToShow={setNumberOfItemsToShow}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="failed-state-wrapper">
                                        <img className="failed-image" src={warning} alt="unable to load statements" />
                                        <span className="text h4">Unable to load data</span>
                                        <span className="text regular">Please wait a moment and try again</span>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className='loading-state-wrapper' />
                    }
                </div>
            </div>
        </div>
    )
}

export default DesktopStatementsPage