import { IPillProps } from './interfaces/IPillProps'; 
import './Pill.scss'; 
/** 
 * Pill component
 * @param title - title that the pill will display
 */ 
export const Pill = (props: IPillProps) => { 
  const {title, sx} = props; 
  return ( 
    <div className="Pill" style={sx}> 
      <span className='title mobile-h6'>{title}</span>
    </div> 
  ); 
}; 
