import { useMobileAlertBox } from '../../useHooks/componentHooks/useMobileAlertBox/useMobileAlertBox'
import { IMobileAlertBoxProps } from './interfaces/IMobileAlertBoxProps'
import './MobileAlertBox.scss'

/**Displays a alert message box modal that appears at the bottom of the mobile version */
const MobileAlertBox = (props: IMobileAlertBoxProps) => {
    const { setStateAction, headerMessage, bodyMessage } = props
    const { handleDone } = useMobileAlertBox(setStateAction)

    return (
        <div data-testid="mobile-alert-box-overlay" className="mobile-alert-box-wrapper">
            <div className="data-wrapper">
                <div className="alert-header-wrap" >
                    <span className="header-message-text semiboldRegular">{headerMessage}</span>
                    <span className="done-button regular" onClick={(e: any) => {handleDone(); e.stopPropagation()}}>Close</span>
                </div>
                <span className="body-message-text regular">{bodyMessage}</span>
            </div>
        </div>
    )
}

export default MobileAlertBox

