import axios from "axios"
import { bffBaseUrl } from "../../utils/globalVariables"

/**an API call to get the users SMS settings */
export const getSMSSettings_API = () => {
    return axios.get(`${bffBaseUrl}/customers/sms/subscription`)
}

/**an API to update the users SMS settings */
export const updateSMSSettings_API = (state: boolean) => {
    return axios.patch(`${bffBaseUrl}/customers/sms/subscription`,
        {
            subscription_status: state ? "Opt_In" : "Opt_Out"
        }
    )
}