import { useEffect, useState } from 'react';
import { useWindowState } from '../../../Context/AccountContext/useWindowState'
import { checkAddOwnerControlValues } from '../../../utils/IsFormFilled';

/**A custom use hook holding the logic for the add use owner form */
export const useAddOwnerForm = (form: any, dispatchForm: any, setFormStep: React.Dispatch<React.SetStateAction<number>>) => {
    const { windowState } = useWindowState()
    const [formFilled, setFormFilled] = useState(false);
    const [showEligibilityModal, setShowEligibilityModal] = useState<boolean>(false)

    /**
     * When page is rendered, there's a check to see if the form is already filled
     */
    useEffect(() => {
      setFormFilled(checkAddOwnerControlValues(form.controls));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.controls]);

    /**Handles the onclick event for the Continue button */
    const handleContinue = () => {
      if(form.controls.citizenship.value === "Non-U.S. citizen/resident"){
        setShowEligibilityModal(true)
      }
      else{
        setFormStep(2)
      }        
    }

    /**Handles the onclick event for the Cancel button */
    const handleCancel = () => {
      setFormStep(1)
      form.resetForm()
      dispatchForm()
    }

    return { windowState, formFilled, showEligibilityModal, handleContinue, handleCancel }
}