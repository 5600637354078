import { useEffect, useState } from "react"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { getEventPath } from "../../../utils/getEventPath"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"

/**A custom use hook for the open savings account found in the early withdrawal flow */
export const useOpenSavingsAccount = () => {
    const { windowState } = useWindowState()
    const [openSavings, setOpenSavings] = useState<boolean>(false)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("tool-card")) === -1){
                document.body.style.overflow = "auto";
                setShowTooltip(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Locks the scroll when the tooltip is open and unlocks it if it is closed */
    useEffect(() => {
        if(windowState === "Mobile" && showTooltip){
            document.body.style.overflow = "hidden"
        }
        else{
            document.body.style.overflow = "auto"
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTooltip])


    return { openSavings, setOpenSavings, selectedAccount, showTooltip, setShowTooltip }
}