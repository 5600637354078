import { useEffect, useState } from 'react';
import { ISelectOptions } from '../../../components/SelectDropdown/interfaces/ISelectDropdownProps';
import { useWindowState } from '../../../Context/AccountContext/useWindowState';

/**
 * useHook for pagination
 */
export const usePaginationComponent = (itemType: string, totalItems: number, setStartIndex: any, setEndIndex: any, numberOfItemsToShow: number, setNumberOfItemsToShow: any) => {
  const { windowState } = useWindowState()
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / numberOfItemsToShow);
  const [pagesArray, setPagesArray] = useState<Array<string | number>>([]);
  const optionsArray: ISelectOptions[] = [
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 50, value: 50 }
  ];

  /**
   * will udpate the array of pages as the user clicks through the pagination
   */
  const updatePageArray = () => {
    let temp: Array<string | number> = [];
    for (let i = 1; i < totalPages; i++) {
      temp.push(i);
      //only make elipses if needed
      if (totalPages > 7) {
        //pattern at the begininng
        if (currentPage < 5 && i === 5) {
          temp.push('...');
          i = totalPages; //to break out of loop
        }
        //pattern in the middle
        if (currentPage >= 5 && currentPage < totalPages - 3) {
          temp.push('...');
          temp.push(currentPage - 1);
          temp.push(currentPage);
          temp.push(currentPage + 1);
          temp.push('...');
          i = totalPages; //to break out of loop
        }
        //pattern at the end
        if (currentPage >= totalPages - 3) {
          temp.push('...');
          temp.push(totalPages - 4);
          temp.push(totalPages - 3);
          temp.push(totalPages - 2);
          temp.push(totalPages - 1);
          i = totalPages; //to break out of loop
        }
      }
    }
    temp.push(totalPages);
    setPagesArray(temp);
  };
  /**
   * gets the number of itmes shown on current page out of the total amount of items
   */
  const getItemNumbersOnPage = () => {
    let firstNumber =
      currentPage * numberOfItemsToShow - numberOfItemsToShow + 1;
    let lastNumber = currentPage * numberOfItemsToShow;
    if (currentPage === totalPages) {
      lastNumber = totalItems;
    }
    return `${firstNumber}-${lastNumber}`;
  };

  /**Updates the page array when the page, number of items or total items has changed */
  useEffect(() => {
    setStartIndex(((+currentPage - 1) * +numberOfItemsToShow))
    setEndIndex((((+currentPage - 1) * +numberOfItemsToShow) + +numberOfItemsToShow))
    updatePageArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, numberOfItemsToShow, totalItems]);

  /**Defaults back to the first page when number of items to show has changed */
  useEffect(() => {
    setCurrentPage(1);
  }, [numberOfItemsToShow]);

  return {
    optionsArray,
    currentPage,
    setCurrentPage,
    pagesArray,
    totalPages,
    getItemNumbersOnPage,
    windowState
  };
};
