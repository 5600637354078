import axios from "axios"
import { bffBaseUrl } from "../../utils/globalVariables"

/**calls to post the token and get the status of the mfa 15 minute token */
export const validateMFAToken_API = (token: string) => {
    return axios.post(`${bffBaseUrl}/mfa/validate-mfa-token`,
    {
        mfaToken: token,
        // stepUpToken: localStorage.getItem("stepToken"),
    },
    { 
        headers:{
            stepUpToken: localStorage.getItem("verifyToken"),
        }
    },
    )
}

/**calls to get the factor list for the OTP flow */
export const getFactorList_API = () => {
    return axios.get(`${bffBaseUrl}/mfa/factors`)
}

/**calls to post the users factor selection */
export const postFactorSelection_API = (id: string) => {
    return axios.post(`${bffBaseUrl}/mfa/factors/${id}/challenge`)
}

/**calls to post the otp code for verification */
export const postVerificationCode_API = (id: string, code: string) => {
    return axios.post(`${bffBaseUrl}/mfa/factors/${id}/verify`,
        {
            passCode: code
        }
    )
}

/**calls service to post a resend for the verification code */
export const postResendVerificationCode_API = (id: string) => {
    return axios.post(`${bffBaseUrl}/mfa/factors/${id}/resend-code`)
}

