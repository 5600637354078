import { IVerifiedAccount } from '../../../../api/getYodleeVerifiedAccounts';
import { ResponseStatus } from '../../../../api/models/response-status';
import { LINKING_STATUS } from '../../../../api/models/verified-accounts';
import { YodleeCreationStatus } from '../../../../api/postCreateYodleeAccount';


export interface IVerifiedAccountExtended extends IVerifiedAccount {
  statusOnCreation: YodleeCreationStatus;
}

/**
 * Controller for `MockDigitalBankAPIs.yodlee`
 */
export class YodleeController {
  public yodleeVerifiedAccounts = new Map<string, IVerifiedAccountExtended>();
  public verifiedAccountStatus: ResponseStatus = ResponseStatus.SUCCESS;
  /**
   * Initializes a Yodlee External Account
   */
  initYodleeExternalAccount = () => {
    const ACCOUNT: IVerifiedAccountExtended = {
      accountNickName: 'Test',
      accountNumberLastFourDigit: crypto.getRandomValues(new Uint32Array(1)).toString(),
      accountType: 'Savings',
      balance: "10000000",
      bankName: "Michigan University Federal Credit Union",
      externalAccountId: crypto.getRandomValues(new Uint32Array(2)).toString(),
      isAlreadyExist: false,
      routingNumber: '244425642',
      status: LINKING_STATUS.NOT_CREATED,
      statusOnCreation: YodleeCreationStatus.CREATED
    }

    return ACCOUNT;
  }

  /**
   * Creates/Adds a Yodlee external account that `isAlreadyExists === true`
   * @param overload - All of the fields to overload.
   * @returns - The created account
   */
  addAlreadyLinkedAccount = (overload: Partial<Omit<IVerifiedAccountExtended, 'isAlreadyExist' | 'status'>> = {}) => {
    const ACCOUNT: IVerifiedAccountExtended = {
      ...this.initYodleeExternalAccount(),
      ...overload,
      status: LINKING_STATUS.SUCCESS,
      isAlreadyExist: true
    }

    this.yodleeVerifiedAccounts.set(ACCOUNT.externalAccountId, ACCOUNT);

    return ACCOUNT;
  }

  /**
   * Creates/Adds a Yodlee external account that `isAlreadyExists === false`
   * @param overload - All of the fields to overload.
   * @returns - The created account
   */
  addNonLinkedAccount = (overload: Partial<Omit<IVerifiedAccountExtended, 'isAlreadyExist'>> = {}) => {
    const ACCOUNT: IVerifiedAccountExtended = {
      ...this.initYodleeExternalAccount(),
      ...overload,
      isAlreadyExist: false
    }

    this.yodleeVerifiedAccounts.set(ACCOUNT.externalAccountId, ACCOUNT);

    return ACCOUNT;
  }

  /**
   * Sets the response status for the `getVerifiedAccounts` call.
   * @param status - The status to change to.
   */
  setResponseStatus = (status: ResponseStatus) => {
    this.verifiedAccountStatus = status;
  }

  /**
   * Add a selection needed account
   * @param externalAccountId 
   * @returns 
   */
  addSelectionNeededAccount = (overload: Partial<Omit<IVerifiedAccountExtended, 'isAlreadyExist'>> = {}) => {
    const ACCOUNT: IVerifiedAccountExtended = {
      ...this.initYodleeExternalAccount(),
      ...overload,
      isAlreadyExist: false,
      status: LINKING_STATUS.PENDING_USER_CREATION,
      // statusOnCreation: YodleeCreationStatus.FAILED
    }

    this.yodleeVerifiedAccounts.set(ACCOUNT.externalAccountId, ACCOUNT);

    return ACCOUNT
  }

  /**
   * Adds an account with a status of "Processing" or "Pending_Review"
   */
  addProcessingAccount = (overload: Partial<Omit<IVerifiedAccountExtended, 'isAlreadyExist'>> = {}) => {
    const ACCOUNT: IVerifiedAccountExtended = {
      ...this.initYodleeExternalAccount(),
      ...overload,
      isAlreadyExist: false,
      status: LINKING_STATUS.NOT_CREATED,
    }

    this.yodleeVerifiedAccounts.set(ACCOUNT.externalAccountId, ACCOUNT);

    return ACCOUNT
  }

  /**
   * Adds an account that is in "Pending_Verification" or pending the micro deposit completition
   * @param externalAccountId
   * @returns 
   */
  addVerifyPendingAccount = (overload: Partial<Omit<IVerifiedAccountExtended, 'isAlreadyExist'>> = {}) => {
    const ACCOUNT: IVerifiedAccountExtended = {
      ...this.initYodleeExternalAccount(),
      ...overload,
      isAlreadyExist: false,
      status: LINKING_STATUS.PENDING_VERIFICATION
    }

    this.yodleeVerifiedAccounts.set(ACCOUNT.externalAccountId, ACCOUNT);

    return ACCOUNT
  }

  /**
   * Adds an account that fails on creation
   * @param externalAccountId
   * @returns 
   */
  addFailedAccount = (overload: Partial<Omit<IVerifiedAccountExtended, 'isAlreadyExist'>> = {}) => {
    const ACCOUNT: IVerifiedAccountExtended = {
      ...this.initYodleeExternalAccount(),
      ...overload,
      isAlreadyExist: false,
      status: LINKING_STATUS.FAILURE
    }

    this.yodleeVerifiedAccounts.set(ACCOUNT.externalAccountId, ACCOUNT)

    return ACCOUNT
  }


  getAccount = (externalAccountId: string) => this.yodleeVerifiedAccounts.get(externalAccountId);
}