import { IEditAccountNameProps } from './interfaces/IEditAccountNameProps'; 
import './EditAccountName.scss'; 
import EditAccountNameDesktop from './components/EditAccountNameDesktop/EditAccountNameDesktop';
import EditAccountNameMobile from './components/EditAccountNameMobile/EditAccountNameMobile';
import { useWindowState } from '../../Context/AccountContext/useWindowState';

/**
 * The component allows the user to add a custom name to their account
 * @param props selectedAccount = IAccountInformation, setEditDescription: boolean
 */
export const EditAccountName = (props: IEditAccountNameProps) => {  
  const { selectedAccount, setEditDescription } = props
  const { windowState } = useWindowState(); 
  
  return ( 
    <div className="EditAccountName"> 
      {(windowState === "Desktop" || windowState === "Hybrid") ? 
        <EditAccountNameDesktop 
          selectedAccount={selectedAccount} 
          setEditDescription={setEditDescription}
        /> 
        : 
        <EditAccountNameMobile 
          selectedAccount={selectedAccount}
          setEditDescription={setEditDescription}
        />
      }
    </div> 
  ); 
}; 
