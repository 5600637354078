import { useCDSelection } from '../../../../../useHooks/componentHooks/useCDSelection/useCDSelection'
import './DesktopCDSelection.scss'
import information from '../../../../../assets/information.svg'
import { RadioButton } from '../../../../../components/RadioButton/RadioButton'
import { IDekstopCDSelectionProps } from './interfaces/IDesktopCDSelectionProps'
import arrowLeftBlue from '../../../../../assets/arrowLeftBlue.svg'
import { IProduct } from '../../../../../api/getAccountInformation'
import ProductsModal from '../../../../../components/ProductsModal/ProductsModal'
import Tooltip from '../../../../../components/Tooltip/Tooltip'
import warning from '../../../../../assets/warning.svg'

/**Renders the CD selection page used dring the renew or change term flows of maturity settings */
const DesktopCDSelection = (props: IDekstopCDSelectionProps) => {
    const { chosenTerm, setChosenTerm, setPlanChange, setOpenChangeOption, setChangePlanOption, setShowTermChange, setTermChange, termChange,
        CDProductList, bumpProductList } = props
    const { enableContinue, setEnableContinue, showModal, setShowModal, onChangeHandler, showTooltip, setShowTooltip, gotProductList } = useCDSelection(chosenTerm, setChosenTerm)

    const month = ' months'
    return (
        <div className="select-term-page-wrapper">
            {gotProductList !== 'Loading' ? (
                <div className="select-term-wrapper">
                    <div className="header-wrap">
                        <span className="header-text small">Renew your CD at maturity</span>
                        <div className="bottom-row">
                            <span className="select-cd-text h3">Select a term for your CD </span>
                            <span className="modal-link regular" onClick={() => setShowModal(true)}>View today’s rates</span>
                        </div>
                    </div>
                    {gotProductList === 'Success' ? (
                        <div className="display-options">
                            <div className="cd-group">
                                <div className="header-wrap">
                                    <span className="header-text h4">Jumbo CD</span>
                                </div>
                                {CDProductList.map((cd: IProduct, index: number) => {
                                    return (
                                        <div data-testid={`Jumbo CD ${cd.name}`} key={index} className={chosenTerm?.code === cd.code ? "row-wrapper selected" : "row-wrapper"} onClick={() => { setChosenTerm(cd); setEnableContinue(true) }}>
                                            <RadioButton
                                                value={cd.name}
                                                name="new term"
                                                className="radio"
                                                onChange={() => onChangeHandler(cd)}
                                                checked={chosenTerm?.code === cd.code}
                                                textAlign="Right"
                                            >{cd.name.split(' ')[0] + month as any}</RadioButton>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="cd-group">
                                <div className="header-wrap">
                                    <span className="header-text h4">Jumbo Bump-Up CD</span>
                                    <div className="image-wrapper">
                                        <img className="information-image" src={information} alt="information" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} />
                                        {showTooltip &&
                                            <div className="tooltip-wrap">
                                                <Tooltip anchorPoint={"top-right"} width="250px" headerMessage="" bodyMessage="Our Jumbo Bump-Up CD lets you take advantage of rising interest rates with a one-time rate increase option. See the FAQs for more information." />
                                            </div>
                                        }
                                    </div>
                                </div>
                                {bumpProductList.map((cd: IProduct, index: number) => {
                                    return (
                                        <div data-testid={`Jumbo Bump-Up CD ${cd.name}`} key={index} className={chosenTerm?.code === cd.code ? "row-wrapper selected" : "row-wrapper"} onClick={() => { setChosenTerm(cd); setEnableContinue(true) }}>
                                            <RadioButton
                                                value={cd.name}
                                                name="new term"
                                                className="radio"
                                                onChange={() => onChangeHandler(cd)}
                                                checked={chosenTerm?.code === cd.code}
                                                textAlign="Right"
                                            >{cd.name.split(' ')[0] + month as any}</RadioButton>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="product-api-fail-wrap">
                            <img className="image" src={warning} alt="warning circle" />
                            <span className="header-text h4">Unable to load data</span>
                            <span className="sub-text regular">Please wait a moment and try again</span>
                        </div>
                    )}
                    <div className="border-bottom" />
                    <div className="button-wrap">
                        <div className="left-side" onClick={() => { setOpenChangeOption(!termChange ? true : false); setChangePlanOption(""); setTermChange(false) }}>
                            <img className="back-arrow" src={arrowLeftBlue} alt="back" />
                            <span className="back-text regular">Back</span>
                        </div>
                        <div className="right-side">
                            <button className="Button btn-secondary" onClick={() => { setPlanChange(false); setChangePlanOption(""); setTermChange(false) }}>Cancel</button>
                            <button className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableContinue} onClick={() => { setShowTermChange(true); setChangePlanOption("Renew") }}>Continue</button>
                        </div>
                    </div>
                    {showModal && (
                        <ProductsModal
                            setShowModal={setShowModal}
                            CDProductList={CDProductList}
                            bumpProductList={bumpProductList}
                            showSavingsProducts={false}
                            origin='maturity'
                        />
                    )}
                </div>
            ) : (
                <div className="loading-box" />
            )}
        </div>
    );
}

export default DesktopCDSelection