import './BumpRateModal.scss'
import { IBumpRateModalProps } from './interfaces/IBumpRateModalProps'
import bumpBlueBackground from '../../../../assets/bumpBlueBackground.svg'
import { useBumpRateModal } from '../../../../useHooks/componentHooks/useBumpRateModal/useBumpRateModal'

/**Renders the confirmation modal that is displayed when applying a "Bump" to a bump-rate CD */
const BumpRateModal = (props: IBumpRateModalProps) => {
    const { setBumpActivated, setBumpConfirmed, bumpAPY } = props
    const { handleActivate, handleCancel, windowState } = useBumpRateModal(setBumpActivated, setBumpConfirmed, bumpAPY)

    return (
        <div className="bump-rate-modal-wrap">

            <div className={windowState !== "Mobile" ? "message-wrap" : "message-wrap mobile"}>
                <img className="image" src={bumpBlueBackground} alt="bump up arrow" />
                <span className="header-text h4">Activate bump rate</span>
                <span className="sub-header-text regular">Bumping your rate is a one-time action, and you will not be able to increase your rate again throughout the rest of your CD’s term. Are you sure you want to activate your bump rate now?</span>
                <div className={windowState !== "Mobile" ? "modal-button-wrapper" : "modal-button-wrapper mobile"}>
                    {windowState !== "Mobile" ?
                        <>
                            <button data-testid="cancel-bump" className="Button btn-secondary" onClick={() => handleCancel()}>Don’t activate</button>
                            <button data-testid="enable-bump" className="Button btn-primary" onClick={() => handleActivate()}>Activate bump rate</button>
                        </>
                        :
                        <>
                            <button data-testid="enable-bump" className="Button btn-primary" onClick={() => handleActivate()}>Activate bump rate</button>
                            <button data-testid="cancel-bump" className="Button btn-secondary" onClick={() => handleCancel()}>Don’t activate</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default BumpRateModal