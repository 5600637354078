/* eslint-disable @typescript-eslint/no-unused-vars */
import { IBeneficiaryPersonalInformationProps } from './interfaces/IBeneficiaryPersonalInformationProps';
// import Input from '../../../../Input';
import { Button } from '../../../../Button/Button';
import { isFormFilled } from '../../../../../utils/IsFormFilled';
import { useAddPersonRequired } from '../../../../../useHooks/componentHooks/useAddBeneficiary/useAddPerson/useAddPersonRequired';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import RXInput from '../../../../RXInput/RXInput';
import arrowLeftBlue from '../../../../../assets/arrowLeftBlue.svg'
import { AddressInputWrapper, LoquateAutoComplete, useLoquateOnForm, verifyLoquateAddress } from '../../../../../shared/LoquateAutoComplete/LoquateAutoComplete';
import { ILoquateRetreiveFullAddress } from '../../../../../shared/LoquateAutoComplete/LoquateAutoComplete.types';
import { useRef, useState } from 'react';
import { forOfStatement } from '@babel/types';
import { useBriteVerifyOnForm } from '../../../../../shared/BriteVerify/BriteVerify';

/**
 * First step for adding a person
 */
export const BeneficiaryPersonalInformation = (
  props: IBeneficiaryPersonalInformationProps
) => {
  const { windowState } = useWindowState();
  const {
    newBeneficiary,
    setNewBeneficiary,
    handleOnCancel,
    handleOnNext,
    handleOnFinish,
    setCurrentPage,
    form,
    selectedAccount,
    dispatchForm
  } = props;
  const {
    handleOnSubmit,
    formFilled,
  } = useAddPersonRequired(setNewBeneficiary, handleOnFinish, newBeneficiary, form);

  // const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)
  const { isEmailValid } = useBriteVerifyOnForm(form, dispatchForm)

  const handleValidation = async () => {

    try {
      // const response = await isAddressValid();
      await isEmailValid();
      handleOnSubmit();
      handleOnNext();
    }
    catch (err) {

    }

  }

  const DIV_CLASSNAME = windowState !== "Mobile" ? "row" : "row mobile"
  return (
    <div className="beneficiary-personal-information">
      <div className="form">

        <div className="title-lockup">
          <div className="pre-header"><div className="content">Add Beneficiary</div><div className="step-count">{selectedAccount.beneficiaries.length > 0 ? "Step 2 of 4" : "Step 2 of 3"}</div></div>
          <h2>Tell us about this person</h2>
        </div>
        <div className="grey-line" />

        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.firstName} className="third" />
          <RXInput control={form.controls.middleName} className="quarter" />
          <RXInput control={form.controls.lastName} className="third" />
        </div>
        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.ssn} />
        </div>

        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.address1} className="half" />
          <RXInput control={form.controls.address2} className="half" />
        </div>
        
        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.city} className="third" />
          <RXInput control={form.controls.state} className="third" />
          <RXInput control={form.controls.zipCode} className="third" />
        </div>

        <h3>Additional Information</h3>
        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.dateOfBirth} className="quarter" />
          <RXInput control={form.controls.relationship} className="quarter" />
          <RXInput control={form.controls.email} className="quarter" />
        </div>
        <div className={DIV_CLASSNAME}>
          <RXInput control={form.controls.phoneNumber} className="third" />
        </div>
      </div>

      {windowState !== "Mobile" ?
        <div className="control-bar">

          <div className="btn-back" onClick={() => { setCurrentPage(0); form.resetForm(); dispatchForm(); }}>
            <img src={arrowLeftBlue} alt={'back arrow'} />
            <span className="text blue regular">Back</span>
          </div>

          <div className="btn-group">
            <Button
              className="Button btn-secondary"
              onClick={() => handleOnCancel()}
              title="Cancel"
            />
            <Button
              className={
                formFilled
                  ? 'Button btn-primary'
                  : 'Button btn-primary inactive'
              }
              onClick={() => {
                handleValidation();
              }}
              title="Continue"
              isDisabled={!formFilled}
            />
          </div>
        </div>
        :
        <Button
          className={formFilled ? "Button btn-primary" : "Button btn-primary inactive"}
          onClick={() => { handleValidation(); }}
          title="Continue"
          isDisabled={!formFilled}
          sx={{ width: "100%", marginBottom: "24px" }}
        />
      }

    </div>
  );
};
