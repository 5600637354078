import { useTransferAtMaturity } from '../../../../useHooks/componentHooks/useTransferAtMaturity/useTransferAtMaturity'
import './TransferAtMaturity.scss'
import bluePlus from '../../../../assets/bluePlus.svg'
import bankBlue from '../../../../assets/bankBlue.svg'
import blueSettings from '../../../../assets/blueSettings.svg'
import { ITransferAtMaturityProps } from './interfaces/ITransferAtMaturityProps'
import OpenSavingsPage from '../OpenSavingsPage/OpenSavingsPage'
import { dateLongHand } from '../../../../utils/dateLongHand'
import TransfersDropDown from '../../../../components/TransfersDropDown/TransfersDropDown'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import MaxAccountsModal from '../../../UserProfile/components/MaxAccountsModal/MaxAccountsModal'
import { getSAMLURL } from '../../../../shared/config/getSAMLUrl'
import { useOpenNewAccount } from '../../../../Context/OpenNewAccountContext/useOpenNewAccountContext'

/**Renders the maturity transfer page when the option to "Close" the CD at maturity is selected */
const TransferAtMaturity = (props: ITransferAtMaturityProps) => {
    const { setPlanChange, setShowTermChange, setChangePlanOption, transferAccount, setTransferAccount } = props
    const { foundSavings, linkedAccounts, history, handleContinue, handleCancel, setOpenSavings,
        windowState, selectedAccount, savingsPageClosed, setSavingsPageClosed, enableSave, handleLinkExternalAccount,
        showLimitModal, setShowLimitModal } = useTransferAtMaturity(setPlanChange, setShowTermChange, setChangePlanOption, transferAccount, setTransferAccount)
    const { checkExternalAccounts } = useOpenNewAccount()

    return (
        <>
            {savingsPageClosed ?
                <>
                    {showLimitModal &&
                        <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
                    }
                    {windowState === "Mobile" &&
                        <div className="mobile-transfer-header-wrap">
                            <span className="header-text regular" onClick={() => handleCancel()}>Cancel</span>
                        </div>
                    }
                    <div className={windowState !== "Mobile" ? "transfer-maturity-page-wrap desktop" : "transfer-maturity-page-wrap"}>
                        <div className={windowState !== "Mobile" ? "transfer-maturity-wrapper" : "transfer-maturity-wrapper mobile"}>
                            <div className="header-wrap">
                                <span className="header-text small">Close your CD at maturity</span>
                                <span className="sub-header-text h3">Where would you like to transfer your balance to?</span>
                            </div>
                            {selectedAccount.accountType !== "Savings" && <span className="information-text regular">Your balance will be transferred to the account below when your CD matures on {dateLongHand(convertDateTommddyyyy(selectedAccount.maturityDate))}.</span>}
                            <div className="dropdown-wrapper">
                                <TransfersDropDown
                                    label={'Transfer balance to:'}
                                    isRequired={false}
                                    defaultValue="Select an Account"
                                    selectedOption={transferAccount}
                                    setSelectedOption={setTransferAccount}
                                    selectOptions={linkedAccounts}
                                />
                            </div>
                            <div className="options-wrapper">
                                {!foundSavings &&
                                    <div className="option-wrap" onClick={checkExternalAccounts}>
                                        <img src={bluePlus} alt="open savings account" />
                                        <span className="option-text small">Open a savings account</span>
                                    </div>
                                }
                                <div className="option-wrap" onClick={() => handleLinkExternalAccount()}>
                                    <img src={bankBlue} alt="link external account" />
                                    <span className="option-text small">Link external account </span>
                                </div>
                                <div className="option-wrap" onClick={() => history.push("/user/profile/external-accounts")}>
                                    <img src={blueSettings} alt="manage accounts" />
                                    <span className="option-text small">Manage external accounts</span>
                                </div>
                            </div>
                            {windowState !== "Mobile" ?
                                <div className="button-wrapper">
                                    <button className="Button btn-secondary" onClick={() => handleCancel()}>Cancel</button>
                                    <button className={enableSave ? "Button btn-primary" : "Button btn-primary inactive"} onClick={() => handleContinue()} disabled={!enableSave}>Continue</button>
                                </div>
                                :
                                <div className="mobile-button-wrapper">
                                    <button className={enableSave ? "Button btn-primary" : "Button btn-primary inactive"} onClick={() => handleContinue()} disabled={!enableSave}>Continue</button>
                                </div>
                            }
                        </div>
                    </div>
                </>
                :
                <OpenSavingsPage
                    setFoundSavings={setSavingsPageClosed}
                    setOpenSavings={setOpenSavings}
                    setChangePlanOption={setChangePlanOption}
                    setPlanChange={setPlanChange}
                    setShowTermChange={setShowTermChange}
                    transferAccount={transferAccount}
                    setTransferAccount={setTransferAccount}
                />
            }
        </>
    )
}

export default TransferAtMaturity