import { useEffect } from 'react';
import { useLinkExternalAccount } from '../../../Context/useLinkExternalAccount'
import { YODLEE_STATUS } from '../../../Context/useHooks/usePostYodleeFlow/usePostYodleeFlow.types';
import { YodleeAccountSelection } from '../YodleeAccountSelection/YodleeAccountSelection.component';
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState';
import "./PostYodleeFlowChart.component.scss"
import warning from '../../../../../assets/warning.svg'
import { useHistory } from 'react-router';

export const PostYodleeFlowChart = () => {
  const { postYodleeFlow, setStep } = useLinkExternalAccount();
  const { getVerifiedAccounts, providerAccountId, responseStatus } = postYodleeFlow;


  useEffect(() => {
    // setTimeout(() => {
      getVerifiedAccounts();
    // }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!providerAccountId) {
    //This shouldn't happen the provideAccountId should be set before coming into this component

    throw new Error('providerAccountId not set. This shouldn\'t happen. providerAccountId should be set before coming into this component');
  }

  const handleResponse = () => {
    switch (responseStatus) {
      //The `getVerifiedAccounts` call hasn't finished yet
      case YODLEE_STATUS.NOT_CALLED:
        return <YodleeLoader />
  
      // All of the accounts got created successfully without selection, single and multiple successful links
      case YODLEE_STATUS.SUCCESS:
        // setStep(7)
        break;
        // return <YodleeAccountsCreated accounts={verifiedAccounts} />
        
      // User needs to select accounts from yodlee, multiple account picker modal
      case YODLEE_STATUS.SELECTION_NEEDED:
        return <YodleeAccountSelection />
          
      // All of the accounts that were selected from yodlee were already linked (this might need to exist)
      // case YODLEE_STATUS.ALL_ACCOUNTS_LINKED:
      //   return <YodleeAlreadyLinked />
          
      // Some error happened. There is currently nothing for this state. This is just a placeholder
      case YODLEE_STATUS.ERROR:
        return <PostYodleeError />
            
      // All of the accounts that needed to be created were created successfully. single and multiple successful links
      case YODLEE_STATUS.LINKED_SUCCESS:
      case YODLEE_STATUS.ALL_ACCOUNTS_LINKED:
        // setStep(7)
        break;
        // return <YodleeAccountsCreated accounts={accountsLinked} />
  
      // 1 or more accounts that needed to be created failed.
      case YODLEE_STATUS.LINKED_FAILED:
      case YODLEE_STATUS.CREATION_FAILED:
        // setStep(7)
        break;
        // return <AccountLinkingInProgress />
      default: return <PostYodleeError />
    }

    return null
    // return <PostYodleeError />
  }

  return handleResponse()
  // return <PostYodleeError />

}

/**Renders teh loading screen when we exit Yodlee Fast Link and the /verifiedAccounts call is processing */
const YodleeLoader = () => {
  const { windowState } = useWindowState()

  return <div className={`post-yodlee-loading-box ${windowState}`} />
}

/**Renders the error screen when /verifiedAccounts fails */
export const PostYodleeError = () => {
  const { windowState } = useWindowState()
  const { setStep, postYodleeFlow, setProviderAccountId } = useLinkExternalAccount()
  const { _setResponseStatus, hasVerifiedAccountsBeenCalledRef } = postYodleeFlow
  const history = useHistory()
  
  return (
    <div className="verified-accounts-error-screen-wrapper">
      <img className="image" src={warning} alt="warning" />
      <span className={windowState !== "Mobile" ? `header-text h4` : "header-text mobile mobileh3"}>We ran into a technical issue</span>
      <span className={`sub-text regular ${windowState}`}>Sorry, we could not link your account due to an issue on our end.</span>
      <button className={`Button btn-primary ${windowState}`} onClick={() => {setStep(5); setProviderAccountId(""); _setResponseStatus(YODLEE_STATUS.NOT_CALLED); hasVerifiedAccountsBeenCalledRef.current = false}}>Try linking again</button>
      <div className="border-line" />
      <div className={`static-footer ${windowState}`}>
          <span className="footer-text boldRegular">Having trouble verifying this way?</span>
          <span className="footer-link regular" onClick={() => setStep(3)}>Use manual verification instead.</span>
      </div>
    </div>
  )
}
