import { AccountHeader } from '../../../../../components/AccountHeader/AccountHeader'
import { useEarlyWithdrawalLanding } from '../../../../../useHooks/componentHooks/useEarlyWithdrawalLanding/useEarlyWithdrawalLanding'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import './MobileEarlyWithdrawalLanding.scss'
import information from '../../../../../assets/information.svg'
import { IMobileEarlyWithdrawalLandingProps } from './interfaces/IMobileEarlyWithdrawalLandingProps'
import blueHeadset from '../../../../../assets/blueHeadset.svg'
import EarlyTooltip from '../../EarlyTooltip/EarlyTooltip'
import { useAppSelector } from '../../../../../app/hooks'
import { RootState } from '../../../../../app/store'

/**Mobile version for early withdrawal landing page */
const MobileEarlyWithdrawalLanding = (props: IMobileEarlyWithdrawalLandingProps) => {
    const { selectedAccount, history, showTooltip, setShowTooltip } = useEarlyWithdrawalLanding()
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return (
        <div className="mobile-early-landing">
            <AccountHeader returnMessage='Account Settings' setDisplayDetails={false} />
            {isAccountDetailsLoaded !== "Loading" ?
                <>
                    <div className="card-wrapper">
                        <div className="header-wrap">
                            <span className="header-text h3">Early withdrawal</span>
                            <span className="sub-header-text small">Request early closure and withdrawal of your CD's funds. This request is subject to review and approval, and penalties apply. It may take up to 10 business days to process your early withdrawal request. </span>
                        </div>
                        <div className="title-wrap">
                            <span className="title-text boldLarge">CD early withdrawal calculator</span>
                            {/* <img src={information} alt="tool tip" /> */}
                        </div>
                        <div className="section-wrap">
                            <div className="top">
                                <span className='row-text small'>Current balance</span>
                                <span className="row-value semiboldRegular">{displayCurrency(selectedAccount.balance)}</span>
                            </div>
                            <div className="bottom">
                                <span className='row-text small'>Interest accrued</span>
                                <span className="row-value semiboldRegular">+{displayCurrency(+selectedAccount.interestPaid)}</span>
                            </div>
                        </div>
                        <div className="border" />
                        <div className="section-wrap">
                            <div className="top">
                                <div className="left-side">
                                    <span className='row-text small'>Penalty</span>
                                    <img data-testid="early-tooltip" className="image" src={information} alt="tool tip" onClick={() => setShowTooltip(true)} />
                                </div>
                                <span className="row-value red semiboldRegular">-{displayCurrency(+selectedAccount.earlyWithdrawalPenalty)}</span>
                            </div>
                            <div className="bottom">
                                <span className='row-text small'>Balance at close</span>
                                <span className="row-value semiboldRegular">{displayCurrency(selectedAccount.balance - +selectedAccount.earlyWithdrawalPenalty)}</span>
                            </div>
                        </div>
                        <div className="bottom-box">
                            <span className="disclaimer-text mobileBoldSmall">Total interest you would lose</span>
                            <span className="disclaimer-value mobileh4">{displayCurrency(+selectedAccount.earlyWithdrawalPenalty)}</span>
                            <div className="route-maturity-link-wrap">
                                <span className="normal-text small">Changed your mind? Manage your</span>
                                <span data-testid="maturity-link" className="link-text small" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`)}>&nbsp;maturity plan</span>
                                <span className="normal-text small">&nbsp;instead.</span>
                            </div>
                        </div>
                        <div className="contact-help-wrap">
                            <img src={blueHeadset} alt="blue headset" />
                            <span className="help-text regular">If you want to proceed, please call us at&nbsp;<a className="blue-text regular" href="tel:8776281610">877-628-1610</a>&nbsp;to request early withdrawal.</span>
                        </div>
                    </div>
                    {showTooltip &&
                        <EarlyTooltip setShowTooltip={setShowTooltip} />
                    }
                    {/* <div className="button-wrap">
                    <button className="early-draw-button boldSmall" onClick={() => setConfirmEarlyWithdrawal(true)}>Request CD Early Withdrawal</button>
                    <button className="cancel-button boldSmall" onClick={() => history.push(`/user/${selectedAccount.id}/settings`)}>Cancel</button>
                </div> */}
                </>
                :
                <div className="loading-box" />
            }
        </div>
    )
}

export default MobileEarlyWithdrawalLanding