import { useEffect } from "react";
import { emptyTransfer, } from "../../../api/transfers";
import { useWindowState } from "../../../Context/AccountContext/useWindowState";
import { getEventPath } from "../../../utils/getEventPath";

/**A custom use hook for the Transfer Details Panel */
export const useTransferDetailsPanel = (
        setOpenTransferDetails: any, 
        setSelectedTransfer: any, 
    ) => {
    const { windowState } = useWindowState()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {

        if(windowState === 'Mobile') {
            return;
        }
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("side-panel-wrap")) === -1){
                document.body.style.overflow = "auto"
                setOpenTransferDetails(false)
                setSelectedTransfer(emptyTransfer)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Locks down the sroll bar when the side panel is opened */
    useEffect(() => {
        // if(windowState === 'Mobile') {
        //     return;
        // }
        document.body.style.overflow = "hidden"
        return () => {document.body.style.overflow = "auto"}
    }, [])

    /**Handles the onClick event for the back arrow */
    const handleOnBack = () => {
        setOpenTransferDetails(false)
        document.body.style.overflow = "auto"
    }

    return { handleOnBack, windowState }
}