/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { buildSavingsTransactionHistoryData, buildTransactionHistoryData, getTransactionHistory_API } from '../../api/getTransactionHistory';
import { postMemo_API } from '../../api/postMemo';
import { RootState } from "../../app/store";
import { cdTransactions } from '../../api/getTransactionHistory/mocks/getTransactionCDHistory';

/**The interface for a single account transaction */
export interface ITransactionHistoryData {
    transactions: [{
        id: string;
        memo: string;
        description: string;
        amount: number;
        effectiveDate: any;
        payerName: string;
        type: string;
        institution: string;
        availableNow: number;
        accountType: string;
        accountNumber?: string;
        holdAmount: number;
        holdAmountReason: string;
        currentBalance: number;
        status: "On Hold" | "Pending" | "Confirmed";
        availableOn: string;
        index: number
    }],
    transactionsLoaded: "Loading" | "Success" | "Failed"
    onTransactionDetails: boolean
}

//The initital state of the transactions slice
const initialState: ITransactionHistoryData = {
    transactions: [{
        id: "",
        memo: "",
        description: "",
        amount: 0,
        effectiveDate: "",
        payerName: "",
        type: "",
        institution: "",
        availableNow: 0,
        accountType: "",
        accountNumber: "",
        holdAmount: 0,
        holdAmountReason: "",
        currentBalance: 0,
        status: "Confirmed",
        availableOn: "",
        index: 0
    }],
    transactionsLoaded: "Loading",
    onTransactionDetails: false
}

//API call to GET transactions for a particular account
export const getTransactionsAsync = createAsyncThunk(
    "transactions/getTransactions_API",
    /** We need to call the api 3 times here to get all the required information */
    async (apiData: { customerId: string, accountId: string }) => {
        /** API here with an offset of 0 will call finxact for the up to date info */
        const getFinxactInfo = await getTransactionHistory_API(apiData.customerId, apiData.accountId, 0, 1);
        /** API here with offset 1 and size 1 will get back the total of all transactions via data.total */
        const getAllTransactions = await getTransactionHistory_API(apiData.customerId, apiData.accountId, 1, 1);
        /** We cannot call the API with size of 0 because that will break. So we either return the true size of data > 0 or extremely large number if data.total is 0 */
        const totalTransactionsAmount = getAllTransactions.data.total !== 0 ? getAllTransactions.data.total : 500;
        /** API here with offset 1 and true size of the transactions */
        const getListOfTransactions = await getTransactionHistory_API(apiData.customerId, apiData.accountId, 1, totalTransactionsAmount);
        if (getListOfTransactions.data) {
            return getListOfTransactions.data
        }
        else {
            return Promise.reject({});
        }
    }
)

//The transactions object slice
export const transactionsSlice = createSlice({
    name: "transactions",
    initialState,
    //A list of reducer functions that modify the transactions state
    reducers: {
        //Clear out the transaction history array
        clearTransactionsArray: (state) => {
            const tempArray: any = []
            state.transactions = tempArray
        },
        //Update the transaction array with new data, the action payload is an array of transactions
        updateTransactionArray: (state, action) => {
            state.transactions = action.payload
        },
        //Update the memo on a particular transaction, the action payload is an index and a memo string
        updateTransactionMemo: (state, action) => {
            state.transactions[action.payload.arrayIndex].memo = action.payload.memo
        },
        //Updates whether or not we are on the 
        updateOnTransactionDetails: (state, action) => {
            state.onTransactionDetails = action.payload
        },
        //Updates the memo of a particular transaction by index
        updateTransactionMemoByIndex: (state, action) => {
            state.transactions[action.payload.arrayIndex].memo = action.payload.memo
        },
        //Fills the transactions array with mock Savings data
        updateWithSavingsData: (state) => {
            // state.transactions = buildSavingsTransactionHistoryData([])
        },
        //Fills the transactions array with mock CD data
        updateWithCDData: (state) => {
            // state.transactions = buildTransactionHistoryData([])
        },
        //Fill transaction array with savings account data (remove all of this when API's exist)
        fillWithSavingsData: (state) => {
            // const tempTransactions: any = savingsTransactions.data
            // state.transactions = tempTransactions
        },
        /**set transactions to what is on the selected account */
        setTransactionsWithAccountData: (state, action) => {
            state.transactions = action.payload
        },
        /**adds a fake transaction to the transaction array for demonstrating an account closure during grace period */
        addFakeTransaction: (state, action: any) => {
            const fakeTransaction = {
                accountType: action.payload.accountType,
                amount: action.payload.amount,
                availableOn: action.payload.availableOn,
                availableNow: action.payload.availableNow,
                currentBalance: action.payload.currentBalance,
                description: action.payload.description,
                effectiveDate: action.payload.effectiveDate,
                holdAmount: action.payload.holdAmount,
                holdAmountReason: action.payload.holdAmountReason,
                id: action.payload.id,
                institution: action.payload.institution,
                memo: action.payload.memo,
                payerName: action.payload.payerName,
                status: action.payload.status,
                type: action.payload.type,
                index: action.payload.index || 0
            }
            let newArray = state.transactions
            newArray.push(fakeTransaction)
            state.transactions = newArray
        }
    },
    //Extra reducers are used to handle the API call
    extraReducers: (builder) => {
        builder
            .addCase(getTransactionsAsync.pending, (state) => {
                state.transactionsLoaded = "Loading"
            })
            .addCase(getTransactionsAsync.fulfilled, (state, action) => {
                state.transactionsLoaded = "Success"
                state.transactions = buildTransactionHistoryData(action.payload.trnDetailHistDetail, action.payload.accountId)
                // let tempTransactions = buildTransactionHistoryData(action.payload.transactions, action.payload.accountId)
                // state.transactions = tempTransactions
            })
            .addCase(getTransactionsAsync.rejected, (state) => {

                state.transactionsLoaded = "Failed"
            })
    }
})

export const transactions = (state: RootState) => state.transactions
export const { clearTransactionsArray, updateTransactionArray, updateTransactionMemo, updateOnTransactionDetails, addFakeTransaction,
    updateTransactionMemoByIndex, updateWithSavingsData, updateWithCDData, fillWithSavingsData, setTransactionsWithAccountData } = transactionsSlice.actions

export default transactionsSlice.reducer
