import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { IDesktopDropDownProps } from "../../../components/Headers/components/DesktopDropdown/interfaces/IDesktopDropDownProps";
import { IUseHeaderDropdownReturn } from "./interfaces/useHeaderDropdown.interface";
import { useIsMounted } from '../../useIsMounted/useIsMounted';

/**
 * The useHook for the Header Dropdown Component
 * @param props - The props from the Header 
 * @param toggleOnClick - If we want to toggle the dropdown after a user clicks off the header. True for desktop and false for mobile.
 */
export const useHeaderDropdown = (props: IDesktopDropDownProps, toggleOnClick = true): IUseHeaderDropdownReturn => {
    const history = useHistory();
    const [show, setShow] = useState<boolean>(true);
    const userName = useAppSelector((state: RootState) => state.userInformation.fullName)
    const isMounted = useIsMounted();
    /**
     * The useEffect hook is used to control the 'show' state.  The setTimeout allows for the close menu animation 
     * to be displayed to the user before removing the drop down menu.
     */
    useEffect(() => {
        if (!props.showMenu) {
            timeoutMenuClose();
        }
        else {
            setShow(true)
        }
    }, [props.showMenu])

    /**
     * Closes the menu when a mouse click occurs
     */
    const closeMenu = () => {
        props.setShowMenu(false)
        timeoutMenuClose();
    }

    /**
     * Sets the `show` to false after 3 seconds.
     */
    const timeoutMenuClose = () => {
        setTimeout(() => {
            if (isMounted()) {
                setShow(false)
            }
        }, 300)
    }

    /**
     * Handles the eventListener that is triggered whenever the drop down menu is open
     */
    useEffect(() => {
        if (show && toggleOnClick) {
            window.addEventListener("click", closeMenu)
            return () => window.removeEventListener("click", closeMenu)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return { history, show, userName };
}