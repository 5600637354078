import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useWindowState } from "../../../Context/AccountContext/useWindowState";
import { setSelectedAccount } from "../../../slices/selectedAccountSlice/selectedAccountSlice";

 /**
  * the custom hook is used for the AccountHeader component.  This component is the summarized
  * version of the overview component in which it only shows the Account Title, Account Type,
  * and the last four digits of the Account Number.  It used on all pages other than account-overview
  * and account details.
 */ 
export const useAccountHeader = () => {  
    const { windowState } = useWindowState()
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    const [previousPage, setPreviousPage] = useState<string>();
    /**
     * Populates the selectedAccount information.  This is important for when the user refreshes 
     * a page that is not account details or account overview.
     */
    useEffect(() => {
        if(selectedAccount.accountNumber === ""){
            const adjustedUrl = window.location.pathname.split('/')[2]
            if(accountInformation && accountInformation[0].accountNumber !== ""){
                const index = accountInformation.findIndex((account: any) => account.accountNumber === adjustedUrl)
                if(index >= 0){
                    dispatch(setSelectedAccount(accountInformation[index]))
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accountInformation, selectedAccount])

    /**
     * Sets the state of previousPage, depending on if there is a `page` state pushed in from history.push
     * This is used to dynamically set the text of the back button on certain pages
     */
    useEffect (() => {
        if (history.location.state !== undefined && history.location.state !== null) {
            const STATE: {page: string} = history.location.state as {page: string}
            setPreviousPage(STATE.page)
        };
    //eslint disabling on the next line because we only want to run this useEffect whenever history.location.state is updated. Which should only be once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state])

    return { selectedAccount, history, dispatch, windowState, isAccountDetailsLoaded, previousPage }
}; 
