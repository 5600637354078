import { useRef, useState } from "react";
import { useLinkExternalAccount } from "../../Pages/LinkExternalAccount/Context/useLinkExternalAccount";
import { YodleeFastLink } from "./components/YodleeFastLink/YodleeFastLink"
import { usePostYodleeFlow } from "../../Pages/LinkExternalAccount/Context/useHooks/usePostYodleeFlow/usePostYodleeFlow.hook";
import { useHistory } from "react-router";
import { deleteYodleeToken_API } from "../../api/User/yodleeServices";
import { IEDPErrorInterface, edpErrorReporting_API } from "../../api/edpErrorReporting";

/**
 * This is a Yodlee Fast Link Wrapper designed for Digital Bank. The purpose of it is to handle call back functions that are returned from Yodlee
 * If you are not working on the Digital Bank project then this should only be used as an example. If your project requires different handling 
*/
const YodleeFastLinkWrapper = () => {
    const { routingNumber, setRoutingNumber, setStep, setProviderAccountId, setYodleeToken } = useLinkExternalAccount()
    const { getVerifiedAccounts } = usePostYodleeFlow(setStep, setYodleeToken)
    const history = useHistory()
    const currentErrorRef = useRef<string>("")

    /**Handles the Yodlee onSuccess call back, this is called when the save & finish button is clicked */
    const onSuccess = (data: any) => {
    }

    /**Handles the Yodlee onError call back */
    const onError = (data: any) => {
        if(data.additionalStatus && data.additionalStatus.toLowerCase() === "addl_authentication_required"){
            if(data.additionalStatus.toLowerCase() === currentErrorRef.current){
                currentErrorRef.current = ""
                setStep(5)
            }
            currentErrorRef.current = data.additionalStatus.toLowerCase() 
        }
        if(data.additionalStatus && data.additionalStatus.toLowerCase() === "site_session_invalidated"){
            if(data.additionalStatus.toLowerCase() === currentErrorRef.current){
                currentErrorRef.current = ""
                setStep(5)
            }
            currentErrorRef.current = data.additionalStatus.toLowerCase()
        }
        let edpErrorObject: IEDPErrorInterface = {
            category: "External_Account",
            errorType: "System_error",
            errorMessage: [{message: data.reason ? data.reason : data.message}],
            timeStamp: new Date().toISOString(),
            additionalDetails: {
                externalAccounts: [{
                    errorCode: data.code ? data.code : "",
                    verificationMethod: "Yodlee"
                }]
            }
        }
        try{
            edpErrorReporting_API(edpErrorObject)
        }
        catch{}
    }

    /**Handles the Yodlee onClose call back */
    const onClose = async (data: any) => {
        if(data.sites && data.sites[0].status.toLowerCase() === "success"){
            const ACCOUNTS = data.sites
            const SUCCESS_ACCOUNTS = ACCOUNTS.filter((account: any) => account.status === "SUCCESS")
            setProviderAccountId(SUCCESS_ACCOUNTS[0].providerAccountId)
        }
        else if(data.action && data.action === "exit" && !data.sites){
            history.push("/user/profile/external-accounts")    
        }
        else if(data.sites && data.sites[0].status.toLowerCase() === "action_abandoned"){
            history.push("/user/profile/external-accounts")
        }
        else if(data.sites[0].additionalStatus){
            switch(data.sites[0].additionalStatus.toLowerCase()){
                case "beta_site_dev_in_progress":
                case "no_accounts_for_verification":
                case "site_unavailable":
                case "action_abandoned":
                case "unexpected_site_error":
                case "site_not_supported":
                case "tech_error":
                case "incorrect_oauth_token":
                case "request_time_out":
                    history.push("/user/profile/external-accounts")
                    break;
                default: break;
            }
        }
    }

    /**Handles the Yodlee onEvent call back */
    const onEvent = (data: any) => {
        // if(currentErrorRef.current === "addl_authentication_required"){
        //     currentErrorRef.current = ""
        //     setStep(5)
        // }
        // didEventHappenRef.current = true
    }


    return (
        <>
            <YodleeFastLink 
                routingNumber={routingNumber}
                onSuccess={onSuccess}
                onError={onError}
                onClose={onClose}
                onEvent={onEvent}
            />
        </>
    )
}

export default YodleeFastLinkWrapper