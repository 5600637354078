import { useSelectInput } from '../../Form/useHooks/useSelectInput';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import { ISelectInputProps } from './interfaces/ISelectInputProps'
import './SelectInput.scss'
import downArrow from '../../assets/downArrow.svg'
import blueCheckmark from '../../assets/blueCheckmark.svg'

/**Renders the SelectInput component  */
const SelectInput = (props: ISelectInputProps) => {
    const { control, className, form, dispatchControlChanges } = props;
    const { openDropdown, setOpenDropdown, handleOnClick, handleOnBlur } = useSelectInput(form, control, dispatchControlChanges)

    return (
        <div className={`select-input-wrap ${className}`}>
            <InputLabel control={control}>
                <div className={control.isDisabled ? "selection-wrap disabled" : "selection-wrap"} tabIndex={0} onBlur={() => handleOnBlur()}>
                    <div  className={control.errors.length > 0 ? "input-selection-wrap pass error" : "input-selection-wrap pass"} onClick={() => { if (!control.isDisabled) { setOpenDropdown(!openDropdown) } }}>
                        <span data-testid={control.name} className={control.errors.length > 0 ? "selection-text regular" : "selection-text regular"} >
                            {control.value ? control.value : control.placeholder}
                        </span>
                        <img src={downArrow} alt="down arrow" />
                    </div>
                    {openDropdown &&
                        <div data-testid="prefix-option" className="dropdown">
                            {control.selectOptions.map((option: string, index: number) => {
                                return (
                                    <div key={index} className="selection-row" onClick={() => handleOnClick(option)}>
                                        <span className={control.value === option ? "option-text selected regular" : "option-text regular"}>{option}</span>
                                        {control.value === option && <img src={blueCheckmark} alt="checkmark" />}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </InputLabel>
            {control.errors.length > 0 && <InputError controlName={control.name} errorArray={control.errors} getErrorMessage={form.getErrorMessage} />}

        </div>
    )
}

export default SelectInput