import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { useWindowState } from '../../../Context/AccountContext/useWindowState';

/**
 * useTotalBalance hook
 * @return timeOfDay - The current time of day (morning, afternoon, evening)
 * @return name - The name of the user
 * @return totalBalance - The total balance of the user
 * @return nickname - The nickname of the user if available
 */
export const useTotalBalance = () => {
  const firstName = useAppSelector((state: RootState) => state.userInformation.firstName)
  const nickname = useAppSelector((state: RootState) => state.userInformation.nickname)
  const { windowState } = useWindowState();
  const isAccountInformationLoaded = useAppSelector((state: RootState) => state.accountInformation.isAccountInformationLoaded)
  const totalBalance = useAppSelector((state: RootState) => state.accountInformation.totalBalance)
  let time = new Date().getHours();
  /**
   * @returns timeOfDay - The current time of day (morning, afternoon, evening)
   */
  const getTimeOfDay = () => {
    let timeOfDay = '';
    if (time < 12) {
      timeOfDay = 'morning';
    } else if (time >= 12 && time < 17) {
      timeOfDay = 'afternoon';
    } else {
      timeOfDay = 'evening';
    }
    return timeOfDay;
  };

  return {
    timeOfDay: getTimeOfDay(),
    firstName,
    totalBalance,
    isAccountInformationLoaded,
    windowState,
    nickname,
    time
  };
};
