import { useTaxWithholdings } from '../../../../useHooks/componentHooks/useTaxWithholdings/useTaxWithholdings'
import MobileHeaderBox from '../MobileHeaderBox/MobileHeaderBox'
import { IMobileTaxWithholdingProps } from './interfaces/IMobileTaxWithholdingProps'
import './MobileTaxWithholding.scss'
import greyInfo from '../../../../assets/greyInfo.svg'
import Toggle from '../../../../components/Toggle/Toggle'
import taxDocument from '../../../../assets/taxDocument.svg'
import arrowRight from '../../../../assets/arrowRight.svg'
import warning from '../../../../assets/warning.svg'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'

/**Renders the mobile version of the tax withholding flow found in user profile */
const MobileTaxWithholding = (props: IMobileTaxWithholdingProps) => {
    const { setCurrentTab } = props
    const { history, userInformation, toggle, handleToggleClick, openTooltip, setOpenTooltip, type, message, gotW9 } = useTaxWithholdings()

    return (
        <div className="mobile-tax-withholding-wrap">
            <MobileHeaderBox 
                setCurrentTab={setCurrentTab}
                title="Tax withholding"
            />
            {(message !== "" && type === "ERROR") && <ToastMessageDB type={type} message={message} />}
            {gotW9 !== "Loading" ?
                <>
                <div className="info-card-wrap">
                    {gotW9 === "Success" ?
                        <>
                        <span className="card-title mobileh2">W-9 tax withholding</span>
                        <div className="bottom-border" />
                        <div className="data-row tooltip">
                            <img className="tooltip" src={greyInfo} alt="tooltip" onClick={() => setOpenTooltip(true)}/>
                            <span className="value-text regular">Are you subject to W-9 tax withholding?</span>
                        </div>
                        <div className="data-row full-border">
                            <div className="left-side limit">
                                <span className="label-text small">Are you subject to W-9 tax withholding?</span>
                                <span className="value-text semiboldRegular">{userInformation.w9Status ? "Yes" : "No"}</span>
                            </div>
                            <Toggle 
                                toggle={toggle}
                                setToggle={handleToggleClick}
                            />
                         </div>
                         </>
                        :
                        <div className="api-error-wrap">
                            <img src={warning} alt="failed to retreive data" />
                            <span className="header-text h4">Unable to load data</span>
                            <span className="sub-text regular">Please wait a moment and try again.</span>
                        </div>
                    }
                </div>            
                <div className="info-card-wrap pointer " onClick={() => history.push('/documents/tax')}>
                    <div className="data-row tax-stack">
                        <img className="tax-image" src={taxDocument} alt="tax document" />
                        <div className="center-stack">
                            <span className="value-text boldSmall">Looking for your tax documents?</span>
                            <span className="value-text small">Manage, view and download your tax documents from the document center</span>
                        </div>
                        <img className="arrow-image" src={arrowRight} alt="open arrow" />
                    </div>
                </div>
                </>
                :
                <div className="w9-loading-wrap">
                    <div className="w9-loading-box top" />
                    <div className="w9-loading-box bottom" />
                </div>
            }
            {openTooltip &&
                <div className="tooltip-overlay">
                    <div className="tooltip-wrap">
                        <div className="header-wrap">
                            <span className="header-text semiboldRegular">About W-9 tax withholding</span>
                            <span className="close-text regular" onClick={() => setOpenTooltip(false)}>Close</span>
                        </div>
                        <span className="tooltip-text small">Backup withholding is a type of tax levied at an established tax rate on specific types of income for federal tax purposes. Backup withholding may be mandatory in certain circumstances. Most taxpayers however are exempt from backup withholding.</span>
                    </div>
                </div>
            }            
        </div>
    )
}

export default MobileTaxWithholding