/* eslint-disable @typescript-eslint/no-unused-vars */
import { IControl } from '../Control/IControl.interface';
import { VALIDATION_FUNCTIONS } from '../Validators/ValidatorFunctions';

/**initializes all controls on the form */
const initFormControls = (controlArray: Array<IControl>, defaultErrorMessages = {}) => {
  let controlObject: { [key: string]: IControl } = {};
  controlArray.forEach(e => {
    let newControl = {
      ...e,
      errorMessages: { ...defaultErrorMessages, ...e.errorMessages }
    }
    controlObject[e.name] = newControl;
  });
  return controlObject;
}

export const Form = (formName: string, controlArray: Array<IControl>, options = {}) => {
  const controls: { [key: string]: IControl } = initFormControls(controlArray);

  const gc = (controlName: string) => controls[controlName];

  /**sets the value field of a particular control */
  const setValue = (controlName: string, value: any) => {
    let control = gc(controlName);
    control.value = value;
    control.isDirty = true;

    if (control.errors.length) {
      runValidations(controlName);
    }

    return control;
  }

  /**Sets the isBlurred state on a control when clicked off */
  const setIsBlurred = (controlName: string, value: boolean) => {
    let control = gc(controlName);
    control.isBlurred = value;

    return control;
  }

  /**sets the control isTouched value to true if the input has been touched */
  const setIsTouched = (controlName: string, value: boolean) => {
    let control = gc(controlName);
    control.isTouched = value;

    return control;
  }

  /**runs validations for a single control */
  const runValidations = (controlName: string) => {
    const control = gc(controlName);
    let errors = [];
    if (!control.isRequired && !control.value) {
      control.errors = []
      control.isValid = true
      return control
    }
    for (let validator of control.validations) {
      const IS_ERROR: any = VALIDATION_FUNCTIONS[validator.name](control, validator)
      if (IS_ERROR) {
        errors.push(IS_ERROR);
      }
    }

    control.errors = errors;

    if (control.errors.length && control.isRequired) {
      control.isValid = false;
    }
    else {
      control.isValid = true;
    }

    return control

  }

  /**The function runs all validations for each control */
  const runValidationOnAllControls = (updateIsFormValid = true) => {
    const KEYS = Object.keys(controls);
    KEYS.forEach((e) => runValidations(e))

    if (updateIsFormValid) {
      isFormValid();
    }
  }

  /**Gets the error message assigned to a validation */
  const getErrorMessage = (controlName: string, errorName: string) => {
    return controls[controlName].errorMessages[errorName] || ""
  }

  /**Checks to determine if the form is valid */
  const isFormValid = () => {
    const KEYS = Object.keys(controls);
    for (let control of KEYS) {
      if (!controls[control].isValid) {
        return false;
      }
    }

    return true;
  }

  /**Reset all fields on the form to the initial value */
  const resetForm = () => {
    const VALUES = Object.values(controls)
    for (let i = 0; i < VALUES.length; i = i + 1) {
      setValue(VALUES[i].name, VALUES[i].initialValue)
      VALUES[i].errors = []
    }
  }

  /**Resets the values for a particular control to the initial value */
  const resetControl = (control: IControl) => {
    setValue(control.name, control.initialValue)
  }

  /**Overrides values on a forms controls to a new value */
  const overrideControlValues = (data: any) => {
    const VALUES = Object.values(controls)
    const dataValues = Object.entries(data).forEach(([key, val]) => {
      if (controls[key]) {
        controls[key].isDisabled = true;
        setValue(key, val)
      }
    })
  }

  /**Sets the isDisabled key on all controls in the form to false (this is for editing a prepopulated form) */
  const enableAllInputs = () => {
    const VALUES: any = Object.values(controls)
    Object.entries(controls).forEach(([key, val]) => {
      controls[key].isDisabled = false
    })
  }

  return {
    isFormValid,
    controls,
    formName,
    setValue,
    setIsBlurred,
    setIsTouched,
    runValidations,
    runValidationOnAllControls,
    getErrorMessage,
    resetForm,
    overrideControlValues,
    enableAllInputs,
    resetControl
  }

}