import { useEffect, useRef, useState } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { IConsentAccount } from "../../../api/getAccountInformation/types/getAccountInformation.types"
import { getDocumentID_API, postTermsAndConditions_API } from "../../../api/termsAndConditions"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"

/**A custom use hook for the review terms component */
export const useReviewTerms = (consentRequiredAccounts: Array<any>, setConsentModalStep: React.Dispatch<React.SetStateAction<number>>) => {
    const { windowState } = useWindowState()
    const [neededConsents, setNeededConsents] = useState<number>(consentRequiredAccounts.length)
    const [selectedConsent, setSelectedConsent] = useState<IConsentAccount>(consentRequiredAccounts.length === 1 ? consentRequiredAccounts[0] : {
        id: "",
        term: "",
        newApy: "",
        currentBalance: "",
        renwedOn: "",
        accountType: "",
        lastFour: "",
        completed: false
    })
    const [startReview, setStartReview] = useState<boolean>(consentRequiredAccounts.length === 1 ? true : false)
    const [showCheckmark, setShowCheckmark] = useState<boolean>(false)
    const [consentAccounts, setConsentAccounts] = useState<Array<any>>([])
    const accountObjectsAdjusted = useRef<boolean>(false)
    const [indexUnderReview, setIndexUnderReview] = useState<number | undefined>(consentRequiredAccounts.length === 1 ? 0 : undefined)
    const { setToastMessage, resetToast } = useToastMessage()
    const continueClickedRef = useRef<boolean>(false)
    const fullName = useAppSelector((state: RootState) => state.userInformation.fullName)

    /**Populates the consent required accounts array of IConsentAccount objects*/
    const populateConsentArray = (consentRequiredAccounts: Array<IConsentAccount>) => {
        const array: Array<IConsentAccount> = []
        for(let i = 0; i < consentRequiredAccounts.length; i = i + 1){
            let temp = {
                accountType: consentRequiredAccounts[i].accountType,
                completed: consentRequiredAccounts[i].completed,
                currentBalance: consentRequiredAccounts[i].currentBalance,
                id: consentRequiredAccounts[i].id,
                lastFour: consentRequiredAccounts[i].lastFour,
                newApy: consentRequiredAccounts[i].newApy,
                renewedOn: consentRequiredAccounts[i].renewedOn,
                term: consentRequiredAccounts[i].term
            }
            array.push(temp)
        }

        return array
    }

    /**
     * the useEffect is copying the accounts object and adding a consentCompleted key to it, this way we can track what has been completed. This will 
     * probably change once we have a clear image of how services will handle this feature.
     */
    useEffect(() => {
        if(!accountObjectsAdjusted.current){
            // let tempAccounts: Array<IConsentAccount> = JSON.parse(JSON.stringify(consentRequiredAccounts))
            let tempAccounts: Array<IConsentAccount> = populateConsentArray(consentRequiredAccounts)
            for(let i = 0; i < tempAccounts.length; i = i + 1){
                tempAccounts[i] = {...tempAccounts[i], completed: false}
            }
            setConsentAccounts(tempAccounts)
            accountObjectsAdjusted.current = true
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentAccounts])

    /**
     * the useEffect is monitoring the neededConsents value, if neededConsents reach 0 then we know that all required consents have been completed and we can 
     * proceed to the next step to finalize this process
     */
    useEffect(() => {
        if(neededConsents === 0){
            setTimeout(() => {
                setConsentModalStep(3)
            }, 2000)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [neededConsents])

    /**Handles the onclick event to start a review */
    const handleStartReview = (account: any, index: number) => {
        if(!startReview && !account.consentCompleted){
            setSelectedConsent(account)
            setStartReview(true)
            setIndexUnderReview(index)
        }
    }

    /**handles the Continue button onclick event */
    const handleContinue = async () => {
        if(!continueClickedRef.current){
            continueClickedRef.current = true
            try{
                const response = await getDocumentID_API(selectedConsent.id)
                const docid = response.headers.docid
                try{
                    await postTermsAndConditions_API(selectedConsent.id, docid, fullName)
                    const index = consentAccounts.findIndex((account: any) => account.id === selectedConsent.id)
                    if(index >= 0){
                        let temp = consentAccounts
                        temp[index].consentCompleted = true
                        setConsentAccounts(temp)
                        setNeededConsents(neededConsents - 1)
                        setSelectedConsent({
                            id: "",
                            term: "", 
                            newApy: "", 
                            currentBalance: "", 
                            renewedOn: "", 
                            accountType: "", 
                            lastFour: "", 
                            completed: false        
                        })
                        setShowCheckmark(false)
                        setIndexUnderReview(undefined)
                        setStartReview(false)
                    }
                }
                catch{
                    console.log("UNABLE TO UPDATE ACCOUNT CONSENT")
                    setToastMessage("ERROR", "Unable to save changes. Please try again later")
                    resetToast()    
                }
            }
            catch{
                console.log("UNABLE TO GET THE DOCUMENT")
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
            }
            continueClickedRef.current = false
        }
    }

    return { selectedConsent, startReview, handleStartReview, showCheckmark, setShowCheckmark, windowState, handleContinue, consentAccounts,
             indexUnderReview }
}