import { useEffect, useRef, useState } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { getUserCurrentDate } from "../../../utils/DateUtils"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"
import { ITransferHistory, postNewTransfer_API } from "../../../api/transfers"

/**A custom use hook holding the logic for the Transfer Review component */
export const useTransferReview = (transferDate: string, transferAmount: number, setScheduledTransfers: any, scheduledTransfers: Array<ITransferHistory>) => {
    const { windowState } = useWindowState()
    const [formatedTransferDate, setFormatedTransferDate] = useState<string>("")
    const [amount, setAmount] = useState<string>("")
    const [todaysDate, setTodaysDate] = useState<string>("")
    const [createTransferStatus, setCreateTransferStatus] = useState<"Pass" | "Fail">("Pass")
    useScrollToTop()
    const confirmClickedRef = useRef<boolean>(false)
    const apiStatusRef = useRef<true | false | null>(null)

    /**Reformat the transfer date mm/dd/yyyy*/
    useEffect(() => {
        if(transferDate !== ""){
            const [ year, month, day ] = transferDate.split("-")
            const formatedDate = `${month}/${day}/${year}`
            setFormatedTransferDate(formatedDate)
            setTodaysDate(getUserCurrentDate())
        }
    }, [transferDate])

    /**Reassign the transferAmount to amount variable as string.  Without the swap the Javascript display as currency function will not work*/
    useEffect(() => {
        if(transferAmount && transferAmount > 0){
            setAmount(String(transferAmount))
        }
    }, [transferAmount])

    /**Handles the onClick event for the Confirm button */
    const handleConfirmClick = async (selectedFromAccount: any, selectedToAccount: any, topCardIndex: number, setTopCardIndex: any) => {
        if(!confirmClickedRef.current){
            apiStatusRef.current = true
            confirmClickedRef.current = true
            const getTodaysDate = new Date().toLocaleDateString()
            const [todaysMonth, todaysDate, todaysYear] = getTodaysDate.split("/")
            const [year, month, day] = transferDate.split("-")
            let transferType: "Internal" | "Ext-inbound" | "Ext-outbound" | "" = ""
            let requestedTransferDate: any = ""
            let selectedTransferDate: any = ""
            if(+todaysMonth !== +month || +todaysDate !== +day || +todaysYear !== +year){
                let [hour, minute, second] = new Date().toTimeString().split(":")
                second = second.substring(0,2)
                // requestedTransferDate = new Date(`${year}, ${month}, ${day}`).toISOString()
                requestedTransferDate = new Date(+year, +month-1, +day, +hour, +minute, +second).toISOString()
                selectedTransferDate = requestedTransferDate
            }
            else{
                selectedTransferDate = new Date().toISOString()
            }
            if(selectedFromAccount.accountType === "Internal" && selectedToAccount.accountType === "Internal"){
                transferType = "Internal"
            }
            else if(selectedFromAccount.accountType === "Internal" && selectedToAccount.accountType === "External"){
                transferType = "Ext-outbound"
            }
            else if(selectedFromAccount.accountType === "External" && selectedToAccount.accountType === "Internal"){
                transferType = "Ext-inbound"
            }
            const payload = {
                transferAmount: transferAmount,
                transferType: transferType,
                transferDate: requestedTransferDate,
                fromAccountDetails: {accountId: selectedFromAccount.accountID, accountNickName: selectedFromAccount.title},
                toAccountDetails: {accountId: selectedToAccount.accountID, accountNickName: selectedToAccount.title}
            }
            try{
                const response = await postNewTransfer_API(payload)
                let tempTransferArray = scheduledTransfers
                let tempTransfer: ITransferHistory = {
                    id: response.data.transferId,
                    transferDate: selectedTransferDate,
                    amount: transferAmount,
                    transferFromAccount: selectedFromAccount.title,
                    originLastFour: selectedFromAccount.lastFour,
                    transferDesignationAccount: selectedToAccount.title,
                    designationLastFour: selectedToAccount.lastFour,
                    status: "Scheduled",
                    fromAccountType: selectedFromAccount.type,
                    orderReference: response.data.orderReference,
                    isInitialFund: false,
                    isCancelAllowed: true
                }
                tempTransferArray.unshift(tempTransfer)
                setScheduledTransfers(tempTransferArray)
                setTopCardIndex(topCardIndex + 1)
            }
            catch{
                console.log("ERROR POSTING THE TRANSFER")
                setCreateTransferStatus("Fail")
            }
            apiStatusRef.current = false
            confirmClickedRef.current = false
        }
        return Promise.resolve(true)
    }
    
    return { windowState, formatedTransferDate, handleConfirmClick, amount, todaysDate, createTransferStatus }
}