import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"

/**
 * Get the User name from the Okta Token and sets logged in
 * status to true
 * @return username, logged in status, useHistory, showMenu, setShowMenu, isLoaded boolean
 */
export const useHeader = () => {
    const history = useHistory()
    const userName = useAppSelector((state: RootState) => state.userInformation.fullName)
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)
    const isAccountInformationLoaded = useAppSelector((state: RootState) => state.accountInformation.isAccountInformationLoaded)
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true)
    const [initialClick, setInitialClick] =useState<boolean>(false)
    const [showMenu, setShowMenu] = useState<boolean>(false)

    /**If either the get call for accounts or accountDetails fails than route the user back to the homepage and display an error */
    useEffect(() => {
      if(isAccountInformationLoaded === "Failed" || isAccountDetailsLoaded === "Failed" || gotUserContactInfo === "Failed"){
        const adjustedUrl = window.location.pathname.split('/')[1]
        if(adjustedUrl !== "knowledge-base"){
          history.push("/")
        }
      }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccountInformationLoaded, isAccountDetailsLoaded, gotUserContactInfo])

  return {
    userName,
    isLoggedIn,
    history,
    initialClick,
    setInitialClick,
    showMenu,
    setShowMenu,
    gotUserContactInfo,
    isAccountInformationLoaded
  }
}