import { useState } from 'react'
import { useAmountFilter } from '../../../../useHooks/componentHooks/useAmountFilter/useAmountFilter'
import './AmountFilter.scss'
import { useTransactionHistoryContext } from '../../context/TransactionHistoryContext'

/**Renders the acmount filter, it has two inputs that are Min and Max, used on the Transaction History Page */
const AmountFilter = () => {

    const { amountFilter: { maxAmount, minAmount } } = useTransactionHistoryContext();

    const {
        onChangeMax,
        onChangeMin,
        minAmountInputRef,
        maxAmountInputRef,
        checkForMaxValidation,
        getClasses
    } = useAmountFilter();



    return (
        <div className="amount-filter-wrapper">
            <div className="amount-input-wrap min">
                <span className="label-text small">Minimum amount</span>
                <div className={getClasses()}>
                    {minAmount.ref.get() !== null && <span className="regular">$</span>}
                    <input
                        inputMode='numeric'
                        className="input-box regular"
                        type="number"
                        placeholder="Enter Amount"
                        defaultValue={minAmount.ref.get() as number}
                        ref={minAmountInputRef}
                        onChange={onChangeMin} />
                </div>
            </div>
            <div className="amount-input-wrap max">
                <span className="label-text small">Maximum amount</span>
                <div className={getClasses()}>
                    {maxAmount.ref.get() !== null && <span className="regular">$</span>}
                    <input
                        inputMode='numeric'
                        className="input-box regular"
                        type="number"
                        placeholder="Enter Amount"
                        defaultValue={maxAmount.ref.get() as number}
                        ref={maxAmountInputRef}
                        onChange={onChangeMax}
                        onBlur={() => checkForMaxValidation()} />
                </div>
            </div>
        </div>
    )
}

export default AmountFilter
