import './AccountOverviewDropDown.scss'
import { IAccountOverviewDropDown } from './interfaces/IAccountOverviewDropDownProps'
import editPencil from '../../../../../../assets/editPencil.svg'
import { displayCurrency } from '../../../../../../utils/displayCurrency'
import DisplayAccount from '../../../DisplayAccount/DisplayAccount'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'

/**
 * Renders the drpo down portion of the desktop overview component.  
 * The component renders when the More Account Details button is clicked
 * on the desktop overview component.
 * @param selectedAccount = Detailed Account Information of IAccountInformation
 */
const AccountOverviewDropDown = (props: IAccountOverviewDropDown) => {
    const { selectedAccount, showDropDown, initialClick } = props;
    const history = useHistory()

    return (
        <>
            {(initialClick && initialClick !== undefined) &&
                <div className="drop-down-wrapper">
                    <div className={showDropDown ? "show-details-divider" : "close-details-divider"}>
                        <div className="show-details">
                            <div className="left-side">
                                <div className="label-value-wrapper">
                                    <div className="left">
                                        <span className="details-text regular">Account type</span>
                                        <span className="details-text regular">Account number</span>
                                        <span className="details-text regular">Routing number</span>
                                        <span className="details-text regular">Ownership</span>
                                    </div>
                                    <div className="right">
                                        <span className="values-text semiboldRegular">{selectedAccount.accountType}</span>
                                        <div className="values-text">
                                            <DisplayAccount selectedAccount={selectedAccount} showDropDown={showDropDown} />
                                        </div>
                                        <span className="values-text semiboldRegular">{selectedAccount.routingNumber}</span>
                                        {selectedAccount.owner.length === 1 ?
                                            <div className="owner-values-wrap">
                                                <span className="owner-values-text semiboldRegular">{selectedAccount.owner[0].name}</span>
                                                <NavLink to={{ pathname: `/account/${selectedAccount.id}/settings`, state: { page: "Account details" } }}>
                                                    <img className="owner-edit-ownership" src={editPencil} alt="Edit Icon" />
                                                </NavLink>
                                            </div>
                                            :
                                            <span className="values-text semiboldRegular joint-account" onClick={() => history.push(`/account/${selectedAccount.id}/settings`, { page: "Account details" })}>Joint ({selectedAccount.owner.length})</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="label-value-wrapper right">
                                    <div className="left">
                                        <span className="details-text right regular">Interest paid to date</span>
                                        {selectedAccount.accountType !== "Savings" && <span className="details-text right regular">Interest paid YTD</span>}
                                        <span className="details-text right regular">Interest paid prior year</span>
                                        <span className="details-text right regular">Payable on death</span>
                                    </div>
                                    <div className="right">
                                        <span className="values-text right semiboldRegular">{displayCurrency(selectedAccount.interestPaid as number)}</span>
                                        {selectedAccount.accountType !== "Savings" && <span className="values-text right semiboldRegular">{displayCurrency(selectedAccount.interestPaidYTD as number)}</span>}
                                        <span className="values-text right semiboldRegular">{displayCurrency(selectedAccount.interestPaidPriorYear as number)}</span>
                                        {selectedAccount.beneficiaries.length === 0 ?
                                            <div className="death-wrap" onClick={() => history.push(`/account/${selectedAccount.id}/beneficiary`)}>
                                                <span className="label-text semiboldRegular">No</span>
                                                {(!selectedAccount.accountClosed && !selectedAccount.pendingGraceClosure) && <img className="death-payment-edit" src={editPencil} alt="Edit Icon" />}
                                            </div>
                                            :
                                            <span className="values-text semiboldRegular">Yes</span>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AccountOverviewDropDown