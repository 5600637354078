import { OverviewComponent } from '../../../components/OverviewComponent/OverviewComponent';
import { TransactionDetails } from '../../../components/TransactionDetails/TransactionDetails';
import { useAccountDetails } from '../../../useHooks/pageHooks/useAccountDetails';
import './DesktopAccountDetails.scss';
import DesktopErrorAccountInformation from '../../../components/ErrorAccountInformation/DesktopErrorAccountInformation/DesktopErrorAccountInformation';
import { AccountDetailsNavBar } from '../MobileAccountDetails/components/AccountDetailsNavBar/AccountDetailsNavBar';
import { AccountDetailsCDTransactionTable } from '../MobileAccountDetails/components/AccountDetailsCDTransactionTable/AccountDetailsCDTransactionTable';
import { AccountDetailsSummaryCards } from '../MobileAccountDetails/components/AccountDetailsSummaryCards/AccountDetailsSummaryCards';
import { AccountDetailsSavingsTransactionTable } from '../MobileAccountDetails/components/AccountDetailsSavingsTransactionTable/AccountDetailsSavingsTransactionTable';
import NotificationMessageDB from '../../../shared/digitalbank/Notification/NotificationMessageDB';
import { useAccountNotifications } from '../../../Context/NotificationContext/useNotificationContext';
import { createId } from '../../../Form/Form/utils';

/**
 * Render the Desktop version of the AccountDetails page
 * @param props selectedAccount: IAccountInformation
 */
const DesktopAccountDetails = () => {
  const { selectedAccount, selectedTransaction, displayDetails, setDisplayDetails, history, isAccountInformationLoaded, transactions, dispatch, selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions,
    transferAmount, setTransferAmount, transferDate, setTransferDate, didTransactionsLoad, gotUserContactInfo } = useAccountDetails();
  const { accountNotifications, handleDismiss } = useAccountNotifications(selectedAccount.id)

  const handleOnNotifLinkClick = (event: any, notification: any) => {
    if (notification.notificationUrl) {
      history.push(notification.notificationUrl)
    }
  }

  return (
    <>
      {((isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") && gotUserContactInfo !== "Failed") ?
        <>
          {selectedTransaction.id !== "" && (
            <TransactionDetails
              selectedTransaction={selectedTransaction}
              setDisplayDetails={setDisplayDetails}
              displayDetails={displayDetails}
            />
          )}
          <div className="Desktop-Account-Details-Wrapper">
            <div className="Overview-Wrap">
              <OverviewComponent />
            </div>
            <AccountDetailsNavBar
              selectedAccount={selectedAccount}
              dispatch={dispatch}
              history={history}
              setOpenNavBarMenu={() => { }}
            />

            {/* Account Notifications */}
            <div className='accounts-notification-wrapper'>
              {accountNotifications.notifications.slice(0, accountNotifications.numToDisplay).map(e => <div className='accounts-notification' key={selectedAccount.id + createId()}><NotificationMessageDB data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotifLinkClick} /></div>)}
            </div>


            {/* <AccountDetailsGracePeriod
              history={history}
              selectedAccount={selectedAccount}
            /> */}
            {/* <AccountDetailsBumpRate
              selectedAccount={selectedAccount}
              isAccountDetailsLoaded={isAccountDetailsLoaded}
              handleBannerCancel={handleBannerCancel}
              history={history}
            /> */}

            {selectedAccount.accountType === 'CD' && (<>
              <AccountDetailsCDTransactionTable
                displayDetails={displayDetails}
                setDisplayDetails={setDisplayDetails}
                selectedAccount={selectedAccount}
                transactions={transactions}
                history={history}
                selectedTransaction={selectedTransaction}
                didTransactionsLoad={didTransactionsLoad}
              />
              <AccountDetailsSummaryCards
                selectedAccount={selectedAccount}
              />
            </>)}


            {selectedAccount.accountType === 'Savings' && <AccountDetailsSavingsTransactionTable

              selectedFromAccount={selectedFromAccount}
              setSelectedFromAccount={setSelectedFromAccount}
              selectedToAccount={selectedToAccount}
              setSelectedToAccount={setSelectedToAccount}
              selectionOptions={selectionOptions}
              transferAmount={transferAmount}
              setTransferAmount={setTransferAmount}
              transferDate={transferDate}
              setTransferDate={setTransferDate}
            />}
          </div>
        </>
        :
        <DesktopErrorAccountInformation />
      }
    </>
  );
};

export default DesktopAccountDetails;
