import { useEffect, useState } from "react"
import { getEventPath } from "../../../utils/getEventPath"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"

/**A custom usehook holding the logic for the TransfersDropDownc comonent */
export const useTransfersDropDown = () => {
    const [openDropDown, setOpenDropDown] = useState<boolean>(false)
    const gotExternalAccounts = useAppSelector((state: RootState) => state.userInformation.gotExternalAccounts)

    /**Checks for click outside of dropdown menu box, if ouside then the drop down menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("transfers-dropdown-wrapper")) === -1){
                setOpenDropDown(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { openDropDown, setOpenDropDown, gotExternalAccounts }
}