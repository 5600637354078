import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { ITrustedDevice } from "../../../api/User/interfaces"
import { getEventPath } from "../../../utils/getEventPath"
import { removeTrustedDevice } from "../../../slices/userInformation/userInformationSlice"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop"

/**A custom usehook for the Manage Devices component found in Security Settings of the user profile flow */
export const useManageDevices = () => {
    const { windowState } = useWindowState()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const [changeName, setChangeName] = useState<boolean>(false)
    const [deleteDevice, setDeleteDevice] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const dispatch = useAppDispatch()
    const [selectedDevice, setSelectedDevice] = useState<ITrustedDevice>({
        id: "",
        device: "",
        deviceNickname: "",
        ip: "",
        lastSignIn: "",
        currentlyUsing: false
    })
    useScrollToTop()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("menu-wrap")) === -1){
                setOpenMenu(false)
                document.body.style.overflow = "auto"
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleDeleteModalClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-wrap")) === -1){
                setDeleteDevice(false)
            }
        }

        document.addEventListener("click", handleDeleteModalClickOutside, true)
        return () => document.removeEventListener('click', handleDeleteModalClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Handles the onClick event for the Remove device button */
    const handleRemoveDevice = async () => {
        dispatch(removeTrustedDevice(selectedIndex))
        setDeleteDevice(false)
    }

    /**If the window is mobile then lock the scroll bar when the modal opens */
    useEffect(() => {
        if(openMenu && windowState === "Mobile"){
            document.body.style.overflow = "hidden"
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openMenu])

    return { userInformation, openMenu, setOpenMenu, selectedDevice, setSelectedDevice, changeName, setChangeName, deleteDevice, setDeleteDevice,
             selectedIndex, setSelectedIndex, handleRemoveDevice }
}