import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { useWindowState } from '../../../Context/AccountContext/useWindowState';
import { setSortedBeneficiaries } from '../../../slices/selectedAccountSlice/selectedAccountSlice';
import { getEventPath } from '../../../utils/getEventPath';
import { useScrollToTop } from '../../ScrollToTopHook/useScrollToTop';
import { useToastMessage } from '../../../Context/ToastContext/useToastContext';

/**
 * The custom hook is used for rendering the summary details on the landing page.  That is the names of the
 * beneficiaries, and the Pie chart.
 */
export const useBeneficiarySummary = (
  showAlertMessage: string,
  setShowAlertMessage: any
) => {
  const { windowState } = useWindowState()
  const history = useHistory();
  const [showAlertBox] = useState<boolean>(true);
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  const isBeneficiariesLoaded = useAppSelector((state: RootState) => state.selectedAccount.isBeneficiariesLoaded)
  const dispatch = useAppDispatch();
  useScrollToTop()
  const [openMenu, setOpenMenu] = useState<number | undefined>()
  const { message, type, setMessage } = useToastMessage()
  
  /**useEffect resets the toast message to an empty string after 4 seconds */
  useEffect(() => {
    if(message !== ""){
      setTimeout(() => {
        setMessage("")
      }, 4000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  /**Make sure the beneficiary array is sorted by allocation (highest first) on render */
  useEffect(() => {
    if(selectedAccount.beneficiaries.length > 1){
      dispatch(setSortedBeneficiaries())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount.beneficiaries])

  /**Creates an event listener for the menu box pop up */
  const handleMenuOnClick = (e: any) => {
    const handleClickOutside = (event: any) => {
      const path = getEventPath(event)
      if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("menu-wrap" || "pop-up-menu")) === -1){
        if(windowState !== "Mobile"){
          setOpenMenu(undefined)
        }
      }
    }

    document.addEventListener("click", handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }

  return { selectedAccount, history, showAlertBox, isBeneficiariesLoaded, openMenu, setOpenMenu, 
           handleMenuOnClick, message, type };
};

