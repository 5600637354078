/* eslint-disable jsx-a11y/anchor-is-valid */
import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout'
import { useAccountSettings } from '../../../useHooks/pageHooks/useAccountSettings'
import beneficiarySettings from '../../../assets/beneficiarySettings.svg'
import maturitySettings from '../../../assets/maturitySettings.svg'
import manageInterest from '../../../assets/manageInterest.svg'
import arrowRight from '../../../assets/arrowRight.svg'
import bumpBlueBackground from '../../../assets/bumpBlueBackground.svg'
import './MobileAccountSettings.scss'
import verificationLetter from '../../../assets/verificationLetter.svg'
import { dateLongHand } from '../../../utils/dateLongHand'
import redTrashCan from '../../../assets/redTrashCan.svg'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { NavLink } from 'react-router-dom'

/**
 * This is the mobile version of the account settings page
 */
const MobileAccountSettings = () => {
    const { history, selectedAccount, isAccountDetailsLoaded } = useAccountSettings()
    return (
        <MobileLayout>
            <div className="Mobile-Account-Settings-Wrapper">
                {!selectedAccount.earlyWithdrawalSelected ?
                    <AccountHeader returnMessage={'Account Details'} setDisplayDetails={false} />
                    :
                    <AccountHeader returnMessage={'Account Details'} setDisplayDetails={false} showPill={true} />
                }
                <div className="encapsulation-card">
                    {isAccountDetailsLoaded !== "Loading" ?
                        <>
                            <div className="header h3">Account settings</div>
                            {/* <div className="bottom-border" /> */}
                            {selectedAccount.accountType !== "Savings" &&
                                <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/interest`)}>
                                    <div className="left-side">
                                        <img src={manageInterest} alt="Mange Interest" />
                                        <div className="text-wrap">
                                            <span className="title mobileBoldSmall">Manage interest payments</span>
                                            <span className="text mobileSmall">Change where your interest payments are deposited</span>
                                        </div>
                                    </div>
                                    <img className="Arrow" src={arrowRight} alt="Open" />
                                </div>
                            }
                            {selectedAccount.accountType !== "Savings" &&
                                <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`)}>
                                    <div className="left-side">
                                        <img src={maturitySettings} alt="Mange Interest" />
                                        <div className="text-wrap">
                                            <span className="title mobileBoldSmall">Maturity plan</span>
                                            <span className="text mobileSmall">Choose what happens when your CD matures</span>
                                        </div>
                                    </div>
                                    <img className="Arrow" src={arrowRight} alt="Open" />
                                </div>
                            }
                            <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/beneficiary`)}>
                                <div className="left-side">
                                    <img src={beneficiarySettings} alt="Mange Interest" />
                                    <div className="text-wrap">
                                        <span className="title mobileBoldSmall">Beneficiaries</span>
                                        <span className="text mobileSmall">Add or manage beneficiaries and allocation amounts.</span>
                                    </div>
                                </div>
                                <img className="Arrow" src={arrowRight} alt="Open" />
                            </div>
                            <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/verificationletter`)}>
                                <div className="left-side">
                                    <img src={verificationLetter} alt="Verification Letter" />
                                    <div className="text-wrap">
                                        <span className="title mobileBoldSmall">Account verification letter</span>
                                        <span className="text mobileSmall">Download an account verification letter</span>
                                    </div>
                                </div>
                                <img className="Arrow" src={arrowRight} alt="Open" />
                            </div>
                            {selectedAccount.cdGroup === "BumpupCD" &&
                                <div className="card" onClick={() => history.push(`/account/${selectedAccount.id}/bumprate`)}>
                                    <div className="left-side">
                                        <img src={bumpBlueBackground} alt="Mange Interest" />
                                        <div className="text-wrap">
                                            <div className="title-row">
                                                <span className="title mobileBoldSmall">Bump rate</span>
                                                {selectedAccount.bumpActivated && <span className="activated-pill mobile-x-small-semibold">Activated</span>}
                                            </div>
                                            {!selectedAccount.bumpActivated ?
                                                <>
                                                    {selectedAccount.isBumpAvailable ?
                                                        <span className="text mobileSmall x-small">Your CD is eligible for a one-time rate bump from {selectedAccount.originalApy.toFixed(2)}% APY to {selectedAccount.bumpedRate.toFixed(2)}% APY</span>
                                                        :
                                                        <span className="text mobileSmall x-small">No rate increase available—you already have the best rate!</span>
                                                    }
                                                </>
                                                :
                                                <span className="text mobileSmall x-small">You bumped your rate on {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.bumpedRateDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <img className="Arrow" src={arrowRight} alt="Open" />
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <div className="mobile-settings-loading-state" >

                        </div>
                    }
                </div>
                {selectedAccount.accountType !== "Savings" &&
                    <>
                        {!selectedAccount.earlyWithdrawalSelected ?
                            <div className="early-withdrawal-wrap">
                                <p className="small"><NavLink to={`/account/${selectedAccount.id}/earlywithdrawal`} className="blue-text">Close your CD early.</NavLink>&nbsp;Penalties apply, and your request is subject to approval.</p>
                            </div>
                            :
                            <div className="cancel-early-withdrawal-wrap">
                                <div className="button-wrapper">
                                    <img src={redTrashCan} alt="remove" />
                                    <span className="cancel-text boldLarge" onClick={() => history.push(`/account/${selectedAccount.id}/earlywithdrawal`)}>Cancel Early Withdrawal</span>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </MobileLayout>
    )
}

export default MobileAccountSettings