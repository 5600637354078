import { RadioButton } from '../../../../../components/RadioButton/RadioButton'
import { useTransferFunds } from '../../../../../useHooks/componentHooks/useTransferFunds/useTransferFunds'
import bankBlue from '../../../../../assets/bankBlue.svg'
import blueSettings from '../../../../../assets/blueSettings.svg'
import arrowLeftBlue from '../../../../../assets/arrowLeftBlue.svg'
import './DesktopTransferFunds.scss'
import { IDesktopTransferFundsProps } from './interfaces/IDesktopTransferFundsProps'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import { convertDateTommddyyyy } from '../../../../../utils/DateUtils'
import TransfersDropDown from '../../../../../components/TransfersDropDown/TransfersDropDown'
import MaxAccountsModal from '../../../../UserProfile/components/MaxAccountsModal/MaxAccountsModal'

/**The desktop version of the transfer funds page rendered when transfer is selected on maturity settings */
const DesktopTransferFunds = (props: IDesktopTransferFundsProps) => {
    const { setManageFunds, setShowTermChange, setTransferType, transferAccount, setTransferAccount } = props
    const { selectedAccount, handleRadioOnClick, addFunds, withdrawFunds, amountInputRef, handleAmountOnChange, linkedAccounts,
        history, handleOnContinue, enableContinue, handleCancel, handleOnKeyDown, transferAmount, isAmountErrored, addInputError,
        withdrawInputError, showLimitModal, setShowLimitModal, handleLinkExternalAccount } = useTransferFunds(setShowTermChange, setManageFunds, setTransferType, transferAccount, setTransferAccount)

    return (
        <div className="transfer-funds-page-wrap">
            {showLimitModal &&
                <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
            }
            <div className="transfer-funds-card-wrap">
                <div className="header-wrap">
                    <span className="header-text small">Add or withdraw funds at renewal</span>
                    <span className="sub-header-text h3">Please enter your fund transfer details</span>
                </div>
                <div className="radio-button-wrap">
                    <div className="radio-button-row">
                        <div className={addFunds ? "radio-button-selection selected" : "radio-button-selection"} onClick={() => handleRadioOnClick("Add")}>
                            <RadioButton
                                value={"Add funds"}
                                name="transfer funds"
                                className="radio"
                                onChange={() => handleRadioOnClick("Add")}
                                checked={addFunds}
                                textAlign="Right"
                            ><span>Add funds</span>
                            </RadioButton>
                        </div>
                        <div className={withdrawFunds ? "radio-button-selection selected" : "radio-button-selection"} onClick={() => handleRadioOnClick("Withdraw")}>
                            <RadioButton
                                value={"Withdraw funds"}
                                name="transfer funds"
                                className="radio"
                                onChange={() => handleRadioOnClick("Withdraw")}
                                checked={withdrawFunds}
                                textAlign="Right"
                            ><span>Withdraw funds</span>
                            </RadioButton>
                        </div>
                    </div>
                    {selectedAccount.accountType !== "Savings" && <span className="fund-transfer-text x-small cls_mask">Funds will be transferred on {convertDateTommddyyyy(selectedAccount.maturityDate)}, when your CD renews.</span>}
                </div>
                <div className="transfer-input-wrapper">
                    <label className="label-wrap">
                        <div className="label-text-wrap">
                            <span className="amount-text regular">Amount</span>
                            <span className="require-star">*</span>
                        </div>
                        <div className={isAmountErrored ? "input-box-wrap error" : "input-box-wrap"}>
                            {transferAmount && transferAmount > 0 && <span className="regular">$</span>}
                            <input
                                className="input-box regular cls_mask"
                                type="number"
                                placeholder="Enter Amount"
                                value={amountInputRef.current}
                                onChange={(e: any) => handleAmountOnChange(e)}
                                onKeyDown={(e: any) => handleOnKeyDown(e)}
                            />
                        </div>
                        {withdrawFunds && !withdrawInputError &&
                            <span className="available-balance x-small cls_mask">Available balance to withdraw: {selectedAccount.balance - 100000 <= 0 ? displayCurrency(0) : displayCurrency(selectedAccount.balance - 100000)}</span>
                        }
                        {(addFunds && !addInputError) &&
                            <span className="available-balance x-small cls_mask">You can add up to: {displayCurrency(1000000 - selectedAccount.balance)}</span>
                        }
                        {addInputError && <span className="error-text mobileSemiboldError cls_mask">Entered Value Exceeds {displayCurrency(1000000 - selectedAccount.balance)}</span>}
                        {withdrawInputError && <span className="error-text mobileSemiboldError cls_mask">Entered Value Exceeds: {selectedAccount.balance - 100000 <= 0 ? displayCurrency(0) : displayCurrency(selectedAccount.balance - 100000)}</span>}
                    </label>
                </div>
                <div className="dropdown-wrapper">
                    <TransfersDropDown
                        label={addFunds ? 'Transfer from' : 'Transfer to'}
                        isRequired={false}
                        defaultValue="Select an Account"
                        selectedOption={transferAccount}
                        setSelectedOption={setTransferAccount}
                        selectOptions={linkedAccounts}
                    />
                </div>
                <div className="options-wrap">
                    <div className="option" onClick={() => handleLinkExternalAccount()}>
                        <img src={bankBlue} alt="link external account" />
                        <span className="option-text regular">Link external account</span>
                    </div>
                    <div className="option" onClick={() => history.push("/user/profile/external-accounts")}>
                        <img src={blueSettings} alt="manage external accounts" />
                        <span className="option-text regular">Manage external accounts</span>
                    </div>
                </div>
                <div className="button-wrapper">
                    <div className="left-side" onClick={() => handleCancel()}>
                        <img className="back-arrow" src={arrowLeftBlue} alt="back" />
                        <span className="back-text regular" >Back</span>
                    </div>
                    <div className="right-side">
                        <button className="Button btn-secondary" onClick={() => handleCancel()}>Cancel</button>
                        <button className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableContinue} onClick={() => handleOnContinue()}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopTransferFunds