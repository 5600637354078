import './LinkPicker.scss'
import blueMoneyHouse from '../../../../assets/lightBlueBank.svg'
import instantVerify from '../../../../assets/instantVerify.svg'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { useLinkExternalAccount } from '../../Context/useLinkExternalAccount'
import { useHistory } from 'react-router'

const LinkPicker = () => {
    const history = useHistory()
    const { windowState } = useWindowState()
    const { selectedMethod, setSelectedMethod, setStep } = useLinkExternalAccount()

    return (
        <div className={`picker-page-wrap ${windowState}`}>
            <div className="header-wrap">
                <span className="header-text small">Link external account</span>
                <span className={windowState !== "Mobile" ? "sub-header-text h3" : "sub-header-text h4"}>How would you like to verify your account?</span>
            </div>
            <div className={`picker-wrap ${windowState}`}>
                <div className={selectedMethod === "Instant" ? `card-wrap selected` : `card-wrap`} onClick={() => setSelectedMethod("Instant")}>
                    <img src={instantVerify} alt="use instant verification" />
                    <div className="right-side">
                        <span className="top-text semiboldRegular">Instant verification</span>
                        <span className="bottom-text regular">Use your online banking credentials to link securely to your bank account.</span>
                    </div>
                </div>
                <div className={selectedMethod === "Manual" ? `card-wrap selected` : `card-wrap`} onClick={() => setSelectedMethod("Manual")}>
                    <img src={blueMoneyHouse} alt="use manual verification" />
                    <div className="right-side">
                        <span className="top-text semiboldRegular">Manual verification</span>
                        <span className="bottom-text regular">Confirm the amounts of two small deposits we send to your bank account.</span>
                    </div>
                </div>
            </div>
            <div className={`button-wrap ${windowState}`}>
                {windowState !== "Mobile" && <button className="Button btn-secondary" onClick={() => history.push("/user/profile/external-accounts")}>Cancel</button>}
                <button 
                    className={selectedMethod !== "" ? `Button btn-primary ${windowState}` : `Button btn-primary inactive ${windowState}`} 
                    disabled={selectedMethod === ""}
                    onClick={() => {selectedMethod === "Manual" ? setStep(3) : setStep(5); setSelectedMethod("")}}
                    >
                        Continue
                    </button>
            </div>
        </div>
    )
}

export default LinkPicker