import { IControl } from '../Control/IControl.interface';
import { IValidator } from './IValidator.interface';

export const emailFormatValidator = (length: number): IValidator => ({
  name: 'EMAIL_FORMAT',
  value: length
})

export const EMAIL_FORMAT = (control: IControl, validator: IValidator) => {
  const RE2 = RegExp
  let newValue = control.value;
  const TYPE_OF = typeof newValue;
  if (!['string'].includes(TYPE_OF)) {
    throw new Error(`Email Format Validator on ${control.name} need to be typeof string. value is ${typeof newValue}`)
  }

  if (newValue !== "") {

    //If the regex is true then email is valid.
    const EMAIL_REGEX = new RE2(/^[^\s@]+@[^\s@]+\.[^\s]+$/)
    if (EMAIL_REGEX.test(newValue)) {
      return (false)
    }
  }

  return { ...validator, actual: newValue }
}