import { useEffect, useRef } from "react"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { removeMaturitySettingsFundTransferByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { removeMaturitySettingsFundTransfer } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { getEventPath } from "../../../utils/getEventPath"
import { removeTransfer_API } from "../../../api/transfers"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"

/**
 * A custom use hook for the Cancel Transfer Modal component found in the maturity
 * settings flow.
 */
export const useCancelTransfer = (setOpenCancelFunds: any) => {
    const { windowState } = useWindowState()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const modalRef = useRef<any>()
    const {type, message, resetToast, setToastMessage} = useToastMessage()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if (Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("modal-wrapper")) === -1) {
                document.body.style.overflow = "auto";
                setOpenCancelFunds(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Lock down the scroll on render */
    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [])

    /**Handles the onClick for Yes, Remove Transfer */
    const handleOnYes = async () => {
        try {
            await removeTransfer_API(selectedAccount.maturitySetting.scheduledTransferId as string)
            dispatch(removeMaturitySettingsFundTransfer())
            const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
            if (arrayIndex >= 0) {
                dispatch(removeMaturitySettingsFundTransferByIndex(arrayIndex))
            }
            document.body.style.overflow = "auto";
            setOpenCancelFunds(false)
            setToastMessage("SUCCESS", "Transfer canceled")
        }
        catch {
            console.log("ERROR ATTEMPTING TO CANCEL THE TRANSFER")
            setToastMessage("ERROR", "Unable to save changes. Please try again later.")
            resetToast()
        }
    }

    /**Handler for the no button onClick */
    const handleOnNo = () => {
        document.body.style.overflow = "auto";
        setOpenCancelFunds(false)
    }

    return { selectedAccount, modalRef, handleOnYes, handleOnNo, windowState, type, message }
}