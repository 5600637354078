import doubleLeftArrow from '../../../../assets/doubleArrowLeft.svg'
import doubleRightArrow from '../../../../assets/doubleArrowRight.svg'
import singleLeftArrow from '../../../../assets/singleLeftArrow.svg'
import singleRightArrow from '../../../../assets/singleRightArrow.svg'
import disabledDoubleArrowRight from '../../../../assets/disabledDoubleArrowRight.svg'
import disabledSingleArrowRight from '../../../../assets/disabledSingleArrowRight.svg'
import { useCustomDateFilterContext } from './context/CustomDateFilterContext';

import { MONTHS, useCustomDatePicker } from './useCustomDatePicker';




export const CustomDatePicker = () => {
  const { boxSelection, isDatePickerOpen } = useCustomDateFilterContext();
  const {
    windowState,
    calendarRef,
    handleDateClick,
    handleDoubleLeftArrow,
    handleDoubleRightArrow,
    handleSingleLeftArrow,
    handleSingleRightArrow,
    shouldDayBeEnabled,
    checkForSelectedDate,
    displayMonth,
    currentYear,
    initialYear,
    initialMonth,
    currentMonth,
    dayArray,
    currentDate,
  } = useCustomDatePicker();

  if (!isDatePickerOpen) {

    return null;
  }

  return <div className={windowState !== "Mobile" ? boxSelection === "From" ? "custom-calendar-box" : "custom-calendar-box to" : "custom-calendar-box mobile"} ref={calendarRef}>
    <div className="top-row">
      <div className="left-side">
        <img className="images" src={doubleLeftArrow} alt="go back one year" onClick={() => handleDoubleLeftArrow()} />
        <img className="images" src={singleLeftArrow} alt="go back one month" onClick={() => handleSingleLeftArrow()} />
      </div>
      <span className="month-year-display semibold">{displayMonth} {currentYear.current}</span>
      <div className="right-side">
        {(initialYear.current === currentYear.current && initialMonth.current === currentMonth.current) ?
          <img className="images" src={disabledSingleArrowRight} alt="go forward one month" />
          :
          <img className="images" src={singleRightArrow} alt="go forward one month" onClick={() => handleSingleRightArrow()} />
        }
        {(currentYear.current <= initialYear.current - 1 && currentMonth.current <= initialMonth.current) ?
          <img className="images" src={doubleRightArrow} alt="go forward one year" onClick={() => handleDoubleRightArrow()} />
          :
          <img className="images" src={disabledDoubleArrowRight} alt="go forward one year" />
        }
      </div>
    </div>
    <div className={windowState !== "Mobile" ? "day-row" : "day-row mobile"}>
      <span className={windowState !== "Mobile" ? "day-text mobileboldextraSmall" : "day-text mobileboldextraSmall mobile"}>Mon</span>
      <span className={windowState !== "Mobile" ? "day-text mobileboldextraSmall" : "day-text mobileboldextraSmall mobile"}>Tue</span>
      <span className={windowState !== "Mobile" ? "day-text mobileboldextraSmall" : "day-text mobileboldextraSmall mobile"}>Wed</span>
      <span className={windowState !== "Mobile" ? "day-text mobileboldextraSmall" : "day-text mobileboldextraSmall mobile"}>Thu</span>
      <span className={windowState !== "Mobile" ? "day-text mobileboldextraSmall" : "day-text mobileboldextraSmall mobile"}>Fri</span>
      <span className={windowState !== "Mobile" ? "day-text mobileboldextraSmall" : "day-text mobileboldextraSmall mobile"}>Sat</span>
      <span className={windowState !== "Mobile" ? "day-text mobileboldextraSmall" : "day-text mobileboldextraSmall mobile"}>Sun</span>
    </div>
    <div className="map-dates">
      {dayArray.map((day: { day: number, outside: boolean }, index: number) => {
        return (
          <div key={index} className={windowState !== "Mobile" ? "return-wrapper" : "return-wrapper mobile"}>
            {checkForSelectedDate(day) ?
              <div className={windowState !== "Mobile" ? "day-wrapper selected" : "day-wrapper selected mobile"} >
                <span
                  className="day-text regular selected"
                  onClick={() => { if (!day.outside) { handleDateClick(day) } }}>
                  {day.day}
                </span>
              </div>
              :
              <>
                <div className={(currentDate.current === day.day && !day.outside && displayMonth === MONTHS[initialMonth.current].month && currentYear.current === initialYear.current) ? (windowState !== "Mobile" ? "day-wrapper current" : "day-wrapper current mobile") : (windowState !== "Mobile" ? "day-wrapper" : "day-wrapper mobile")}>
                  <span className={day.outside || !shouldDayBeEnabled(day.day) ? "day-text regular disabled" : "day-text regular"} onClick={() => { if (!day.outside) { handleDateClick(day) } }}>{day.day}</span>
                </div>
                {(currentDate.current === day.day && !day.outside && displayMonth === MONTHS[initialMonth.current].month && currentYear.current === initialYear.current) && <span className="today-text x-x-small">Today</span>}
              </>
            }
          </div>
        )
      })}
    </div>

  </div>
}