import { useEffect, useState } from "react"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { IProduct } from "../../../api/getAccountInformation"

/**A custom use hook for the change CD found in the Grace Period flow */
export const useGraceChangeCD = (chosenTerm: IProduct, setChosenTerm: React.Dispatch<React.SetStateAction<IProduct>>) => {
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation)
    const jumboCDs = accountInformation.CDProductList
    const bumpCDs = accountInformation.BumpProductList
    const [enableContinue, setEnableContinue] = useState<boolean>(false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    /**handler function for the cancel button onClick event */
    const handleCancel = () => {
        setChosenTerm({
            code: "",
            name: "",
            accountType: "",
            group: "",
            subType: "",
            term: "",
            apy: "",
            preferedRate: false
        })
    }

    /**The onChange handler for the radio button selections */
    const onChangeHandler = (cd: IProduct) => {
        setChosenTerm(cd)
        setEnableContinue(true)
    }

    /**Check to see if the chosen term is defined on render to enable the continue button */
    useEffect(() => {
        if(chosenTerm.name !== ""){
            setEnableContinue(true)
        }
        else{
            setEnableContinue(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])    

    return { jumboCDs, bumpCDs, enableContinue, setEnableContinue, handleCancel, onChangeHandler, showTooltip, setShowTooltip }
}