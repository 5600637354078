import { displayCurrency } from '../../../../../utils/displayCurrency'
import './DesktopEarlyWithdrawalLanding.scss'
import information from '../../../../../assets/information.svg'
import { useEarlyWithdrawalLanding } from '../../../../../useHooks/componentHooks/useEarlyWithdrawalLanding/useEarlyWithdrawalLanding'
import { IDesktopEarlyWIthdrawalLandingProps } from './interfaces/IDesktopEarlyWithdrawalLandingProps'
import blueHeadset from '../../../../../assets/blueHeadset.svg'
import EarlyTooltip from '../../EarlyTooltip/EarlyTooltip'

/**Desktop component for early withdrawal landing page component */
const DesktopEarlyWithdrawalLanding = (props: IDesktopEarlyWIthdrawalLandingProps) => {
    const { selectedAccount, history, isSelectedAccountLoaded, showTooltip, setShowTooltip } = useEarlyWithdrawalLanding()
    if (!isSelectedAccountLoaded) {
        return null;
    }
    return (
        <div className="desktop-withdrawal-landing">
            <div className="withdrawal-landing-page-wrap">
                <div className="card-wrapper">
                    <div className="header-wrap">
                        <span className="header-text h2">Early withdrawal</span>
                        <span className="sub-header-text regular">Request early closure and withdrawal of your CD's funds. This request is subject to review and approval, and penalties apply. It may take up to 10 business days to process your early withdrawal request. </span>
                    </div>
                    <div className="top-box">
                        <div className="header-wrap">
                            <span className="title-text h4">Early withdrawal penalty calculator</span>
                            {/* <img className="tool-image" src={information} alt="tool tip" /> */}
                        </div>
                        <div className="middle-wrap">
                            <div className="top">
                                <span className="row-text regular">Current balance</span>
                                <span className="row-value semiboldRegular">{displayCurrency(selectedAccount.balance)}</span>
                            </div>
                            <div className="bottom">
                                <span className="row-text regular">Interest accrued</span>
                                <span className="row-value semiboldRegular">+{displayCurrency(selectedAccount.interestPaid)}</span>
                            </div>
                        </div>
                        <div className="bottom-wrap">
                            <div className="top">
                                <div className="left-side">
                                    <span className="row-text regular">Penalty</span>
                                    <img data-testid="early-tooltip" className="tool-image" src={information} alt="tool tip" onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)} />
                                    {showTooltip &&
                                        <div className="tooltip-wrap">
                                            <EarlyTooltip />
                                        </div>
                                    }
                                </div>
                                <span className="balance-red semiboldRegular">-{displayCurrency(+selectedAccount.earlyWithdrawalPenalty)}</span>
                            </div>
                            <div className="bottom">
                                <span className="row-text regular">Balance at close</span>
                                <span className="row-value semiboldRegular">{displayCurrency(selectedAccount.balance - +selectedAccount.earlyWithdrawalPenalty)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-box">
                        <div className="top">
                            <span className="disclaim-text boldRegular">Total interest you would lose</span>
                            <span className="disclaim-text-value boldRegular">{displayCurrency(+selectedAccount.earlyWithdrawalPenalty)}</span>
                        </div>
                        <div className="maturity-route-link-wrap">
                            <span className="normal-text">Changed your mind? Manage your</span>
                            <span data-testid="maturity-link" className="link-text" onClick={() => history.push(`/account/${selectedAccount.id}/maturity`)}>&nbsp;maturity plan</span>
                            <span className="normal-text">&nbsp;instead.</span>
                        </div>
                    </div>
                    <div className="contact-information-wrap">
                        <img className="image" src={blueHeadset} alt="blue headset" />
                        <span className="help-text regular">If you want to proceed, please call us at&nbsp;
                            <a className="blue-text" href={"tel:8776281610"}>877-628-1610</a>
                            <span>&nbsp;to request early withdrawal.</span>
                        </span>
                    </div>
                    {/* <div className="buttom-wrap">
                        <button className="early-button boldLarge" onClick={() => setConfirmEarlyWithdrawal(true)}>Request CD Early Withdrawal</button>
                        <button className="cancel-button boldLarge" onClick={() => history.push(`/user/${selectedAccount.id}/settings`)}>Cancel</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default DesktopEarlyWithdrawalLanding