import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { setAccountInformationByIndex, updateAccountInterestSettingsByIndex } from "../../slices/accountInformation/accountInformationSlice"
import { getAccountDetailsAsync, getInterestSettings, setIsInterestSettingsLoaded } from "../../slices/selectedAccountSlice/selectedAccountSlice"
import { populateAccountDetails } from "../../utils/populateAccountDetails"
import { IAccountInformation, ISelectedInterestAccount } from "../../api/getAccountInformation"
import { IExternalAccounts } from "../../api/User/interfaces"

/**A custom use hook for the interest settings page */
export const useInterestSettings = () => {
    const { windowState } = useWindowState()
    const [changeClicked, setChangeClicked] = useState<boolean>(false)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [accountToTransfer, setAccountToTransfer] = useState<ISelectedInterestAccount | IExternalAccounts>(selectedAccount.selectedInterestAccount)

    /**Populate the accoutToTrasnfer key once selected account is loaded */
    useEffect(() => {
        if(selectedAccount.id !== ""){
            setAccountToTransfer(selectedAccount.selectedInterestAccount)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    /**Repopulate the selectedAccount on a page refresh */
    useEffect(() => {
        if(selectedAccount.id === "" && accountInformation[0].id !== ""){
            const adjustedUrl = window.location.pathname.split('/')[2]
            const index = accountInformation.findIndex((account: any) => adjustedUrl === account.id)
            if(index >= 0){
                const account = accountInformation[index]
                repopulateAccountDetails(index, account)
            }
            else{
                history.push("/")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, accountInformation])

    /**Repopulates the account details on a page refresh */
    const repopulateAccountDetails = async (index: number, account: any) => {
        const payload = {arrayIndex: index, account: account}
        const response: any = await dispatch(getAccountDetailsAsync(payload))
        try{
            let tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
            const payload = {arrayIndex: index, account: tempAccount}
            dispatch(setAccountInformationByIndex(payload))            
        }
        catch{
            console.error()
        }
    }

    /**Get interest settings on page load and a populated selected account */
    useEffect(() => {
        if(selectedAccount.id !== "" && !selectedAccount.gotInterestSettings){
            getInterestSettingsAPI()
        }
        else if(selectedAccount.id !== "" && selectedAccount.gotInterestSettings){
            dispatch(setIsInterestSettingsLoaded("Success"))
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    /**Calls to get interest settings */
    const getInterestSettingsAPI = async () => {
        const arrayIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
        try{
            const response = await dispatch(getInterestSettings(selectedAccount.id))
            const payload = {
                arrayIndex: arrayIndex,
                account: {
                    id: response.payload.transferAccountId,
                    accountTitle: response.payload.acctNickName,
                    accountNumber: response.payload.accountLastFourDigit,
                    accountType: response.payload.accountType          
                }
            }
            dispatch(updateAccountInterestSettingsByIndex(payload))
        }
        catch{
            console.log("FAILED TO GET INTEREST SETTINGS")
        }
    }

    return { windowState, selectedAccount, changeClicked, setChangeClicked, accountToTransfer, setAccountToTransfer }
}