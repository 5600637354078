import { createContext, useContext, useEffect, useState } from "react";
import { ILinkExternalAccountContext } from "./interfaces/ILinkExternalAccountContext";
import { usePostYodleeFlow } from './useHooks/usePostYodleeFlow/usePostYodleeFlow.hook';
import { IExternalAccounts } from "../../../api/User/interfaces";
import { deleteYodleeToken_API } from "../../../api/User/yodleeServices";
import { useToastMessage } from "../../../Context/ToastContext/useToastContext";
import { IEDPErrorInterface, edpErrorReporting_API } from "../../../api/edpErrorReporting";

/**Context with custom hook for the link external account page flow */
export const LinkExternalAccountContext = createContext<ILinkExternalAccountContext | null>(null)

export const LinkExternalAccountContextProvider = (props: any) => {
    const [step, setStep] = useState<number>(1)
    const [accountType, setAccountType] = useState<"Checking" | "Savings" | "">("")
    const [routingNumber, setRoutingNumber] = useState<string>("")
    const [accountNumber, setAccountNumber] = useState<string>("")
    const [testAccountNumber, setTestAccountNumber] = useState<string>("")
    const [nickname, setNickname] = useState<string>("")
    const [showVerified, setShowVerified] = useState<boolean>(false)
    const [enableButton, setEnableButton] = useState<boolean>(false)
    const [selectedMethod, setSelectedMethod] = useState<"Instant" | "Manual" | "">("")
    const [providerAccountId, setProviderAccountId] = useState('');
    const [newExternalAccount, setNewExternalAccount] = useState<IExternalAccounts>({
        id: "",
        accountNickName: "",
        status: "",
        type: ""
    })
    const [blockedRoutingError, setBlockedRoutingError] = useState<boolean>(false)
    const [isYodleeFastLinkLoaded, setIsYodleeFastLinkLoaded] = useState<boolean>(false)
    const [yodleeToken, setYodleeToken] = useState<string>("")

    const postYodleeFlow = usePostYodleeFlow(setStep, setYodleeToken, providerAccountId)
    const { clearToast } = useToastMessage()

    /**Destory the yodlee token if the user exits the link external accounts flow */
    useEffect(() => {
        const deleteYodleeToken = () => {
            clearToast()
            callDelete()
        }

        return () => deleteYodleeToken()
    }, [])

    const callDelete = async () => {
        try{
            if(localStorage.getItem("token")){
                localStorage.removeItem("token")
                await deleteYodleeToken_API()
            }
        }
        catch(error: any){
            let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
            let edpErrorObject: IEDPErrorInterface = {
                category: "External_Account",
                errorType: "API_error",
                errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""},{message:"DELETE YODLEE TOKEN"}],
                timeStamp: new Date().toISOString(),
                additionalDetails: {
                    externalAccounts: [{
                        errorCode: errorReturn.status || "",
                        verificationMethod: "YODLEE"
                    }]        
                }
            }
            try{
                edpErrorReporting_API(edpErrorObject)
            }
            catch{}
        }
    }

    return (
        <LinkExternalAccountContext.Provider value={{
            step,
            setStep,
            accountType,
            setAccountType,
            routingNumber,
            setRoutingNumber,
            accountNumber,
            setAccountNumber,
            testAccountNumber,
            setTestAccountNumber,
            nickname,
            setNickname,
            showVerified,
            setShowVerified,
            enableButton,
            setEnableButton,
            selectedMethod,
            setSelectedMethod,
            newExternalAccount,
            setNewExternalAccount,
            postYodleeFlow,
            providerAccountId,
            setProviderAccountId,
            blockedRoutingError,
            setBlockedRoutingError,
            isYodleeFastLinkLoaded, 
            setIsYodleeFastLinkLoaded,
            yodleeToken, 
            setYodleeToken
        }}
        >
            {props.children}
        </LinkExternalAccountContext.Provider>
    )
}

/**A custom use hook holding the logic for the link external account context */
export const useLinkExternalAccount = () => {
    const context = useContext(LinkExternalAccountContext)

    if (!context) {
        throw new Error(`Need to use useLinkExternalAccount inside of LinkExternalAccountContextProvider`);
    }

    return {
        step: context.step,
        setStep: context.setStep,
        accountType: context.accountType,
        setAccountType: context.setAccountType,
        routingNumber: context.routingNumber,
        setRoutingNumber: context.setRoutingNumber,
        accountNumber: context.accountNumber,
        setAccountNumber: context.setAccountNumber,
        testAccountNumber: context.testAccountNumber,
        setTestAccountNumber: context.setTestAccountNumber,
        nickname: context.nickname,
        setNickname: context.setNickname,
        showVerified: context.showVerified,
        setShowVerified: context.setShowVerified,
        enableButton: context.enableButton,
        setEnableButton: context.setEnableButton,
        selectedMethod: context.selectedMethod,
        setSelectedMethod: context.setSelectedMethod,
        newExternalAccount: context.newExternalAccount,
        setNewExternalAccount: context.setNewExternalAccount,
        providerAccountId: context.providerAccountId,
        setProviderAccountId: context.setProviderAccountId,
        postYodleeFlow: context.postYodleeFlow,
        blockedRoutingError: context.blockedRoutingError,
        setBlockedRoutingError: context.setBlockedRoutingError,
        isYodleeFastLinkLoaded: context.isYodleeFastLinkLoaded,
        setIsYodleeFastLinkLoaded: context.setIsYodleeFastLinkLoaded,
        yodleeToken: context.yodleeToken,
        setYodleeToken: context.setYodleeToken
    }
}