import './MobileDocumentsFilter.scss'
import mobileTransactionFilter from '../../../../../assets/mobileTransactionFilter.svg'
import { IMobileDocumentCenterFilterProps } from './interfaces/IMobileDocumentCenterFilterProps'
import { useMobileDocumentCenterFilter } from '../../../../../useHooks/componentHooks/useMobileDocumentCenterFilter/useMobileDocumentCenterFilter'
import arrowRight from '../../../../../assets/arrowRight.svg'
import blueCheckmark from '../../../../../assets/blueCheckmark.svg'
import { IAccountInformation } from '../../../../../api/getAccountInformation'

/**
 * The comopnent displays the mobile version of the documents filter located in the Documents Center
 */
const MobileDocumentCenterFilter = (props: IMobileDocumentCenterFilterProps) => {
    const { selectedAccount, accountInformation, dateSelections, selectedDate, setSelectedDate, selectedPage, handleSave } = props
    const { openFilter, setOpenFilter, accountsSelected, setAccountsSelected, dateSelected, setDateSelected, numOfFilters, onSave,
            chosenAccount, setChosenAccount } = useMobileDocumentCenterFilter(selectedDate, setSelectedDate)

    return(
        <div className="mobile-document-center-filter">
            <div className="filter-wrapper" onClick={() => setOpenFilter(true)}>
                <img className="filter-image" src={mobileTransactionFilter} alt="filter" />
                <span className="filter-text mobileRegular">Filters</span>
                <span className="filter-number mobileSemiboldRegular">{numOfFilters}</span>
            </div>
            {openFilter &&
                <>
                <div className="filter-backdrop" />
                <div className="mobile-filter">
                    {(!accountsSelected && !dateSelected) &&
                    <div className="no-selection">
                        <div className="filter-header-sort">
                            <span className="sort-text semiboldRegular">Filters</span>
                            <span className="cancel-text regular" onClick={() => {setOpenFilter(false); document.body.style.overflow = "auto"}}>Cancel</span>
                        </div>
                        <div className="filter-row" onClick={() => setAccountsSelected(true)}>
                            <div className="left-side">
                                <span className="filter-text mobileRegular">Account</span>
                                <span className="filter-selection mobileSemiboldRegular">{selectedAccount.accountTitle} - {selectedAccount.accountType} {selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length -4, selectedAccount.accountNumber.length)} </span>
                            </div>
                            <img className="arrow-icon" src={arrowRight} alt="open" />
                        </div>
                        {selectedPage === "Statements" &&
                            <div className="filter-row" onClick={() => setDateSelected(true)}>
                                <div className="left-side">
                                    <span className="filter-text mobileRegular">Time period</span>
                                    <span className="filter-selection mobileSemiboldRegular">{selectedDate}</span>
                                </div>
                                <img className="arrow-icon" src={arrowRight} alt="open" />
                            </div>
                        }
                    </div>
                    }
                    {accountsSelected &&
                        <div className="filter-selected">
                            <div className="filter-header">
                                <span className="back-text regular" onClick={() => setAccountsSelected(false)}>Back</span>
                                <span className="account-text semiboldRegular">Account</span>
                                <span className="save-text regular" onClick={() => {setAccountsSelected(false); setOpenFilter(false); handleSave(chosenAccount.id); onSave(chosenAccount.id)}}>Save</span>
                            </div>
                            {accountInformation.map((account: IAccountInformation, index: number) => {
                                return(
                                    <div key={account.id} className="row-display" onClick={() => setChosenAccount(account)}>
                                        <span className={account.id === chosenAccount.id ? "account-data mobileRegular blue" : "account-data mobileRegular"}>{account.accountTitle} - {account.accountType} {account.accountNumber.slice(account.accountNumber.length - 4, account.accountNumber.length)}</span>
                                        {chosenAccount.id === account.id && <img className="checkmark-image" src={blueCheckmark} alt="checkmark" />}
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {dateSelected &&
                        <div className="filter-selected">
                            <div className="filter-header">
                                <span className="back-text regular" onClick={() => setDateSelected(false)}>Back</span>
                                <span className="account-text semiboldRegular">Time period</span>
                                <span className="save-text regular" onClick={() => {setAccountsSelected(false); setDateSelected(false); setOpenFilter(false); handleSave(chosenAccount.id); onSave(chosenAccount.id)}}>Save</span>
                            </div>
                            {dateSelections?.current.map((date: string, index: number) => {
                                return(
                                    <div key={date} className="row-display" onClick={() => {if(setSelectedDate)setSelectedDate(date as string)}}>
                                        <span className={date === selectedDate ? "account-data mobileRegular blue" : "account-data mobileRegular"}>{date}</span>
                                        {date === selectedDate && <img src={blueCheckmark} className="checkmark-image" alt="checkmark" />}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                </>
            }
        </div>
    )
}

export default MobileDocumentCenterFilter