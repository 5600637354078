import axios from 'axios';
import { bffBaseUrl, token } from '../../utils/globalVariables';
import { IGetUserInformationResponse } from './interfaces';

/**
 * Mock user info
 */
export const user: IGetUserInformationResponse = {
  data: {
    id: '123456',
    customerId: 'JS_SP_UAT-4',
    firstName: 'Manish',
    lastName: 'Sharma',
    contactInformation: {
      phones: [
        { id: '123456', type: 'Mobile', number: '7028463218' },
        { id: '123456', type: 'Alternate', number: '7024561234' }
      ],
      emails: [
        {
          id: '123456',
          type: 'primary',
          emailAddress: 'chris.keller@creditone.come',
          isPrimary: true
        },
        {
          id: '123456',
          type: 'secondary',
          emailAddress: 'chris.keller@credittwo.com',
          isPrimary: false
        }
      ],
      addresses: [
        {
          id: '123456',
          addressType: 'home',
          addressLine1: '1234 street',
          city: 'Las Vegas',
          state: 'NV',
          zipCode: '89131',
          country: 'US',
          isMailing: true,
          isResidential: true
        },
        {
          id: '111111',
          addressType: 'home',
          addressLine1: '11111 street',
          city: 'Las Vegas',
          state: 'NV',
          zipCode: '89131',
          country: 'US',
          isMailing: false,
          isResidential: false
        }
      ]
    },
    taxInformation: { data: {} },
    settings: {
      securitySettings: { data: {} },
      notificationSettings: { data: {} }
    },
    linkedExternalAccounts: [
      {
        accountTitle: 'Bank of America Gold Checking',
        accountType: 'External',
        isVerified: true,
        accountNumber: '4561231234',
        institution: 'Bank of America'
      },
      {
        accountTitle: 'Bank of America Savings',
        accountType: 'External',
        isVerified: false,
        accountNumber: '45612317483',
        institution: 'Bank of America'
      }
    ],
    userName: "thomas.jefferson",
    w9Status: true,
    smsEnrolled: "Yes",
    externalAccounts: [],
    gotExternalAccounts: false,
    customerSince: '2023-01-30T16:39:25.870+00:00',
    emailEnrolled: true,
    trustedDevices: [
      {
        id: "1",
        device: "Apple-macOS-desktop",
        deviceNickname: "Jim home 1",
        ip: "99.54.142.128",
        lastSignIn: "04-27-2022 11:00AM GMT-7",
        currentlyUsing: true
      },
      {
        id: "2",
        device: "Microsoft-desktop",
        deviceNickname: "Jim Work Desktop",
        ip: "83.45.345.245",
        lastSignIn: "04-27-2022 11:00AM GMT-7",
        currentlyUsing: false
      },
      {
        id: "3",
        device: "Apple-macOS-desktop",
        deviceNickname: "Jim home 2",
        ip: "55.75.024.000",
        lastSignIn: "04-16-2022 11:00AM GMT-7",
        currentlyUsing: false
      },
      {
        id: "4",
        device: "Microsoft-desktop",
        deviceNickname: "Work Desktop",
        ip: "85.57.254.668",
        lastSignIn: "04-14-2022 11:00AM GMT-7",
        currentlyUsing: false
      },
      {
        id: "5",
        device: "Apple-macOS-desktop",
        deviceNickname: "Jim Work",
        ip: "12.14.452.158",
        lastSignIn: "04-01-2022 11:00AM GMT-7",
        currentlyUsing: false
      },
      {
        id: "6",
        device: "Apple-macOS-desktop",
        deviceNickname: "unknown",
        ip: "11.45.127.788",
        lastSignIn: "03-27-2022 11:00AM GMT-7",
        currentlyUsing: false
      },
    ]
  }
};

/**
 * Get API call for User Information
 */
export const getUserInfo_API = () => {
  return axios.get(`${bffBaseUrl}/customers/contact`);
};

/**API call to get customer external accounts */
export const getExternalAccounts_API = async () => {
  return axios.get(`${bffBaseUrl}/customers/externalaccounts`, {
    headers: {
      Authorization: 'Bearer ' + token,
      apiVersion: "2.0.0"
    }
  })
}

/**
 * API call to get the customers trusted devices
 */
export const getTrustedDevices_API = () => {
  return axios.get(`${bffBaseUrl}/devices`)
}
