// import Input from '../../../../../components/Input'
import { useAmountFilter } from '../../../../../useHooks/componentHooks/useAmountFilter/useAmountFilter'
import AmountFilter from '../../../components/AmountFilter/AmountFilter'
import { IMobileAmountFilerProps } from './interfaces/IMobileAmountFIlterProps'
import './MobileAmountFilter.scss'

/**Renders the mobile amount filter found on mobile transaction history page */
const MobileAmountFilter = (props: IMobileAmountFilerProps) => {
    const { setAmountSelected } = props
    const { handleOnMobileSave, onMobileBack } = useAmountFilter(setAmountSelected);

    return (
        <div className="mobile-amount-filter-wrap">
            <div className="header-row">
                <span className="option-text regular" onClick={() => onMobileBack()}>Back</span>
                <span className="header-text semiboldRegular">Amount</span>
                <span className="option-text regular" onClick={() => { handleOnMobileSave(); }}>Save</span>
            </div>
            <div className="bottom-border" />
            <div style={{ margin: 'auto' }}>
                <AmountFilter />
            </div>
        </div>
    )
}

export default MobileAmountFilter