import { useMobileDateRangeSelection } from '../../../../../useHooks/componentHooks/useMobileDateRangeSelection/useMobileDateRangeSelection'
import { IMobileDateRangeSelectionProps } from './interfaces/IMobileDateRangeSelectionProps'
import './MobileDateRangeSelection.scss'
import blueCheckmark from '../../../../../assets/blueCheckmark.svg'
import CustomDateFilter from '../../../components/CustomDateFilter/CustomDateFilter'
import { TDateSelection } from '../../../context/TransactionHistory.types'
import { dateSelections } from '../../../context/TransactionHistoryContext'

/**Renders the Date Range Selection for the mobile transaction page filters */
const MobileDateRangeSelection = (props: IMobileDateRangeSelectionProps) => {
    const { setDateRangeSelected } = props
    const { chosenDate, openDatePicker, handleCustomSave, handleDateSelection } = useMobileDateRangeSelection(setDateRangeSelected)

    return (
        <div className="mobile-date-rage-wrap">
            {!openDatePicker ?
                <>
                    <div className="header-row">
                        <span className="option-text regular" onClick={() => setDateRangeSelected(false)}>Back</span>
                        <span className="header-text semiboldRegular">Date range</span>
                        <span className="option-text regular" onClick={() => handleCustomSave()}>Save</span>
                    </div>
                    <div className="bottom-border" />
                    {dateSelections.current.map((selection: TDateSelection, index: number) => {
                        return (
                            <div key={index}>
                                <div className="row-wrapper" onClick={() => handleDateSelection(selection)}>
                                    <span className={selection === chosenDate ? "selection-text regular selected" : "selection-text regular"}>{selection}</span>
                                    {chosenDate === selection && <img src={blueCheckmark} alt="checkmark" />}
                                </div>
                                {index !== dateSelections.current.length - 1 && <div className="bottom-border" />}
                            </div>
                        )
                    })}
                </>
                :
                <div className="date-picker-header">
                    <div className="header-row">
                        <div className="header-wrap">
                            <span className="option-text regular" onClick={() => setDateRangeSelected(false)}>Back</span>
                            <span className="header-text semiboldRegular">Custom date range</span>
                            <span className="option-text regular" onClick={() => handleCustomSave()}>Save</span>
                        </div>
                    </div>
                    <div className="bottom-border" />
                    <CustomDateFilter />
                </div>
            }
        </div>
    )
}

export default MobileDateRangeSelection