import { useEffect, useState } from "react"
import { IBeneficiary } from "../../../api/getBeneficiaries"
import { IPieObject } from "../../../components/PieChart/interfaces/IPieChartProps"
import { COLOR_ARRAY } from '../../../utils/globalVariables'

/**
 * Hook used for the Pie Chart component.  The hook builds and returns the data object needed to
 * populate the Pie Chart.
 */
export const usePieChart = (beneficiaries: Array<IBeneficiary>) => {
    const [data, setData] = useState<IPieObject>()

    /**
     * Used to build the data object needed to populate the Pie Chart
     */
    useEffect(() => {
        let tempData: Array<number> = beneficiaries.map((element: any) => {
            return element.percentage
          })

        let tempColor: Array<string> = []
        
        
        let tempLabels: string[] = beneficiaries.map((e: any) => {
          return e.name
        });

        for(let i = 0; i < beneficiaries.length; i = i + 1){
          tempColor.push(COLOR_ARRAY[i])
        }

        let tempDataSet: IPieObject = {
          labels: tempLabels,
          datasets: [{
            data: tempData, 
            backgroundColor: tempColor, 
            borderWidth: 0,
          }]
        }
        
        setData(tempDataSet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaries])

    return { data }
}