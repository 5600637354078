import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { setUpdatedBeneficiaryArray } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { useHistory } from 'react-router'
import { IBeneficiary } from '../../../api/getBeneficiaries'

/**
 * The custom use hook contains the logic for the UnsavedChanges modal.  It is used when a user attempts to exit out 
 * of the beneficiary allocations page without saving their changes.
 */
export const useUnsavedChanges = (unsavedChanges: boolean) => {
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const dispatch = useAppDispatch()
    const history = useHistory();

    /**
     * This function will handle history as it returns back to the beneficiary main page.
     */
    const handleContinue = (event: any) => {
       /** Since there is an extra pushState in useBeneficiaryEdit to account for user clicking the browser back button,
        * we will history.goBack() here to get rid of that extra history entry.
        */
        history.goBack();
    }

    /**
     * The function is used when the cancel button is pressed.  It will unlock the scroll wheel and return the user 
     * to the beneficiary allocation page.
     */
    const handleCancel = () => {
        document.body.style.overflow = "auto"
    }

    /**
     * Lock the scroll bar on render
     */
    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [])

    return {handleContinue, handleCancel}
}