import { useAllFilters } from '../../../../useHooks/componentHooks/useAllFilters/useAllFilters'
import AmountFilter from '../AmountFilter/AmountFilter'
import CustomDateFilter from '../CustomDateFilter/CustomDateFilter'
import './DesktopAllFilters.scss'
import greyLeftArrow from '../../../../assets/greyLeftArrow.svg'
import { useTransactionHistoryContext } from '../../context/TransactionHistoryContext'

/**Renders all of the filters on the desktop transactions history page */
const DesktopAllFilters = () => {

    const { handleApply } = useTransactionHistoryContext();
    const { handleCancel } = useAllFilters()

    return (
        <div className="all-filters-overlay">
            <div className="desktop-all-filters-wrap">
                <div className="header-wrap">
                    <img className="back-arrow" src={greyLeftArrow} alt="back arrow" onClick={() => {
                        handleCancel()
                        document.body.style.overflow = "auto";
                    }} />
                    <span className="header-text semiboldRegular">Advanced filters</span>
                </div>
                <div className="filter-row">
                    <div className="left-side">
                        <div className="custom-date-filter">
                            <span className="custom-date-text mobileSemiboldSmall">Custom date range</span>
                            <div className="custom-date-wrap">
                                <CustomDateFilter />
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="amount">
                            <span className="amount-text mobileSemiboldSmall">Amount</span>
                            <AmountFilter />
                        </div>
                    </div>
                </div>
                <div className="button-row">
                    <button className="clear-all-button mobileButtonBold" onClick={() => handleCancel()}>Cancel</button>
                    <button className="apply-button mobileButtonBold" onClick={() => handleApply()}>Apply</button>
                </div>
            </div>
        </div>
    )
}

export default DesktopAllFilters