import { useSecuritySettings } from '../../../../useHooks/componentHooks/useSecuritySettings/useSecuritySettings'
import MobileHeaderBox from '../MobileHeaderBox/MobileHeaderBox'
import { IMobileSecuritySettingsProps } from './interfaces/IMobileSecuritySettingsProps'
import './MobileSecuritySettings.scss'
import blueEditPencil from '../../../../assets/blueEditPencil.svg'
import arrowRight from '../../../../assets/arrowRight.svg'
import ChangePassword from '../ChangePassword/ChangePassword'
import MobileManageDevices from '../../MobileManageDevices/MobileManageDevices'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import warning from '../../../../assets/warning.svg'

/**renders the mobile version of the Security Settings page found in the user profile section */
const MobileSecuritySettings = (props: IMobileSecuritySettingsProps) => {
    const { setCurrentTab } = props
    const { userInformation, handlePasswordClick, message, type, changePassword, setChangePassword, manageDevices, setManageDevices } = useSecuritySettings()
    const gotUserContactInfo = useAppSelector((state: RootState) => state.userInformation.gotUserContactInfo)
    const gotTrustedDevices = useAppSelector((state: RootState) => state.userInformation.gotTrustedDevices)

    return (
        <div className="mobile-security-settings-wrap">
            {(!changePassword && !manageDevices) ?
                <>
                    <MobileHeaderBox
                        setCurrentTab={setCurrentTab}
                        title="Security settings"
                    />
                    {message !== "" && <ToastMessageDB type={type} message={message} />}
                    {gotUserContactInfo !== "Loading" ?
                        <div className="info-card-wrap">
                            <span className="card-title mobileBoldRegular">Username and password </span>
                            <span className="card-sub-title small">You’ll use these to sign in to your online account.</span>
                            <div className="bottom-border" />
                            <div className="data-row">
                                <div className="left-side">
                                    <span className="label-text mobileBoldSmall">Username</span>
                                    <span className="value-text regular cls_mask">{userInformation.userName}</span>
                                </div>
                            </div>
                            <div className="bottom-border" />
                            <div className="data-row">
                                <div className="left-side">
                                    <span className="label-text mobileBoldSmall">Password</span>
                                    <span className="value-text regular">*************</span>
                                </div>
                                <img className="edit-image" src={blueEditPencil} alt="edit pencil" onClick={() => handlePasswordClick()} data-testid='change password' />
                            </div>
                        </div>
                        :
                        <div className="loading-box" />
                    }
                    {gotTrustedDevices !== "Loading" ?
                        <div className="info-card-wrap">
                            {gotTrustedDevices === "Success" ?
                                <>
                                    <span className="card-title mobileBoldRegular">Sign-in history</span>
                                    <span className="card-sub-title small">View the devices used to access your account and the date they signed in.</span>
                                    <div className="bottom-border" />
                                    <div className="data-row cursor" onClick={() => setManageDevices(true)}>
                                        <div className="left-side">
                                            <span className="value-text regular">View history</span>
                                        </div>
                                        <img src={arrowRight} alt="open arrow" />
                                    </div>
                                </>
                                :
                                <div className="failed-devices-wrap">
                                    <img className="failed-image" src={warning} alt="failed to load trusted devices data" />
                                    <span className="message-title h4" >Unable to load data</span>
                                    <span className="message-text regular">Please wait a moment and try again.</span>
                                </div>
                            }
                        </div>
                        :
                        <div className="loading-box small" />
                    }
                </>
                :
                <>
                    {changePassword &&
                        <ChangePassword
                            setChangePassword={setChangePassword}
                        />
                    }
                    {manageDevices &&
                        <MobileManageDevices
                            setManageDevices={setManageDevices}
                        />
                    }
                </>
            }
        </div>
    )
}

export default MobileSecuritySettings