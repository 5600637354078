import './FilterBox.scss'
// import unselectedCalendar from '../../../../../assets/unselectedCalendar.svg'
// import selectedCalendar from '../../../../../assets/selectedCalendar.svg'
import downArrow from '../../../../../assets/downArrow.svg'
import blueCheckmark from '../../../../../assets/blueCheckmark.svg'
import customCalendar from '../../../../../assets/customCalendar.svg'
// import disabledCalendar from '../../../../../assets/disabledCalendar.svg'
// import disabledDownArrow from '../../../../../assets/disabledDownArrow.svg'
import { IFilterBoxProps } from './interfaces/IFilterBoxProps'
import { useDesktopDateSelector } from '../../../../../useHooks/componentHooks/useDesktopDateSelector/useDesktopDateSelector'

const FilterBox = (props: IFilterBoxProps) => {
    const { filterSelections, selectedFilter, setSelectedFilter, handleFilterBoxClick } = props
    const { isMenuOpen, setIsMenuOpen, filterBoxRef } = useDesktopDateSelector()

    return(
        <div className="desktop-date-selector-wrap" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <div className={isMenuOpen ? "filter-selection-box open" : "filter-selection-box closed"}>
                <div className="left-side">
                    <span className="date-text selected regular" >{selectedFilter}</span>
                </div>
                <img src={downArrow} alt="open menu" />
            </div>         
            {isMenuOpen &&
            <div className="drop-down-menu" ref={filterBoxRef}>
                {filterSelections.current.map((date: string, index: number) => {
                    return(
                        <div key={index}>
                        <div className="row-wrapper" onClick={() => {setSelectedFilter(date); setIsMenuOpen(!isMenuOpen); if(handleFilterBoxClick){handleFilterBoxClick(date)}}}>
                            <div className="left">
                            {date === "Custom date range" && <img src={customCalendar} alt="calendar" />}
                            <span className={selectedFilter !== date ? "date-text selected regular" : "date-text selected-blue regular"}>{date}</span>
                            </div>
                            {selectedFilter === date && <img className="checkmark-image" src={blueCheckmark} alt="checkmark" />}
                        </div>
                        {index !== filterSelections.current.length - 1 && <div className="bottom-border" />}
                        </div>
                    )
                })}
            </div>
            }
        </div>
    )
}

export default FilterBox