import './OwnerConfirmation.scss'
import { IOwnerConfirmationProps } from './interfaces/IOwnerConfirmationProps'
import greenCheckmark from '../../../../assets/greenCheckmark.svg'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'

/**Renders the add-owner confirmation page which is the final step of the add co-owner flow */
const OwnerConfirmation = (props: IOwnerConfirmationProps) => {
    const { form, dispatchForm, setFormStep, setAddOwner } = props
    const { windowState } = useWindowState()

    /**Handles the onClick event for Add another */
    const handleAddAnother = () => {
        form.resetForm()
        dispatchForm()    
        setFormStep(1)
    }

    /**Handles the onClick event for Return to Account Ownership */
    const handleReturn = () => {
        form.resetForm()
        dispatchForm()   
        setFormStep(1) 
        setAddOwner(false)
    }

    return (
        <div className="owner-confirmation-wrap">
            <img className="image" src={greenCheckmark} alt="green circle with white checkmark" />
            <span className="header-text h4">Co-owner request has been successfully submitted</span>
            <span className="message-text small">Please allow up to 7 business days for this change to reflect in your account.</span>
            {windowState !== "Mobile" ?
                <div className="button-wrap">
                    <button className="Button btn-secondary" onClick={() => handleAddAnother()}>Add another</button>
                    <button className="Button btn-primary" onClick={() => handleReturn()}>Return to Account Ownership</button>
                </div>
                :
                <div className="button-wrap mobile">
                    <button className="Button btn-primary mobile" onClick={() => handleReturn()}>Return to Account Ownership</button>
                    <button className="Button btn-secondary mobile" onClick={() => handleAddAnother()}>Add another</button>
                </div>
            }
        </div>
    )
}

export default OwnerConfirmation