import { IAccountBoxProps } from './interfaces/IAccountBoxProps';
import './AccountBox.scss';
import arrowDown from '../../assets/arrowDown.svg';
import React from 'react';
import { useAccountBox } from '../../useHooks/componentHooks/useAccountBox/useAccountBox';
import { displayCurrency } from '../../utils/displayCurrency';
import close from '../../assets/close.svg'
import { Pill } from '../Pill/Pill';
import { convertDateTommddyyyy } from '../../utils/DateUtils';
import bumpIconWhite from '../../assets/bumpIconWhite.svg'
import elipses from '../../assets/elipses.svg'
import pieChart from '../../assets/pieChart.svg'
import greyDocument from '../../assets/greyDocument.svg'
import maturityClock from '../../assets/maturityClock.svg'
import settings from '../../assets/settings.svg'
import NotificationMessageDB from '../../shared/digitalbank/Notification/NotificationMessageDB';
import { useAccountNotifications, useNotifications } from '../../Context/NotificationContext/useNotificationContext';
import { INotificationServiceObject } from '../../shared/common/Notification/interfaces/INotifcationProps.types';

/**
 * Component Box that holds basic account information for both desktop and mobile
 * @param balanceType - Type of balance (Current, Available)
 * @param account IAccountInformation
 */
export const AccountBox = React.memo((props: IAccountBoxProps) => {
  const { setFocus, setFocusIndex, windowState, handleOnClick, openNavMenu, setOpenNavMenu, history, handleRemoveBanner } = useAccountBox()
  const { account, index } = props;
  const { accountNotifications, handleDismiss } = useAccountNotifications(account.id, index)
  const { accountArray } = useNotifications()
  
  const handleOnNotificationLinkClick = (event: any, notification: any) => {
    setFocus("Accounts");
    setFocusIndex(notification.accountId)


    const NOTIFICATION_LINK = notification.notificationUrl.split('/')
    const NOTIFICATION_TYPE = NOTIFICATION_LINK[NOTIFICATION_LINK.length - 1];

    switch (NOTIFICATION_TYPE) {
      case 'maturity':
        handleMenuAction(event, 'maturity');
        break;
      case 'beneficiaries':
        handleMenuAction(event, 'beneficiaries');
        break;
      case 'statements':
        handleMenuAction(event, 'statements');
        break;
      case 'graceperiod':
        handleMenuAction(event, 'graceperiod');
        break;
      case 'bumprate':
        handleMenuAction(event, 'bumprate', notification.notificationUrl);
        break;

      default:
        break;
    }
  }

  
  const handleMenuAction = (event: any, type: string, notifcationUrl: string = "") => {
    event.preventDefault();
    event.stopPropagation();
    setFocus("Accounts");
    setFocusIndex(account.accountNumber)
    if(type !== 'bumprate'){
      handleOnClick(account, type);
    }
    else{
      handleOnClick(account, type, notifcationUrl)
    }
  }
  return (
    <div
      className={(windowState !== "Mobile") ? "display-account-box" : "display-account-box mobile"}
      onClick={(e: any) => { openNavMenu ? e.preventDefault() : handleMenuAction(e, 'details') }}>
      {windowState !== "Mobile" ?
        <div className="information">
          <div className="account-info">
            <div className="left-side">
              <div className="top-row">
                <div className="account-name-row" >
                  <span className="account-name mobileLarge"><span className="semiboldLarge">{account.accountTitle}</span> - {account.accountType} {account.accountNumber}</span>
                </div>
                {account.accountClosed && <Pill title={`Closed on ${convertDateTommddyyyy(new Date(account.accountClosedDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}`} sx={{ backgroundColor: "var(--softRed)", color: "var(--darkRed)" }} />}
                {account.pendingGraceClosure && <Pill title="Pending closure" sx={{ backgroundColor: "var(--softRed)", color: "var(--darkRed)" }} />}
                <img className="open-arrow" src={arrowDown} alt="select account" />
              </div>
              {account.accountType !== "Savings" &&
                <div className="account-type small">
                  Matures on: {convertDateTommddyyyy(account.maturityDate)}
                </div>
              }
            </div>

            <div className="right-side">
              <div className="left">
                <span className="intrest-text mobileLarge">{account.interestRate.toFixed(2)}%</span>
              </div>
              <div className="right">
                <div className="balance-amount h4">
                  {displayCurrency(account.currentBalance)}
                </div>
                <div className="elipse-wrap">
                  <img className="elipses" src={elipses} alt="navigation menu" onClick={(e: any) => { setOpenNavMenu(true); e.stopPropagation() }} />
                  {openNavMenu &&
                    <div className="nav-menu-wrap">
                      {account.accountType !== "Savings" && (!account.accountClosed && !account.pendingGraceClosure) &&
                        <>
                          <div className="nav-row-wrap" onClick={(e: any) => { handleMenuAction(e, 'maturity') }}>
                            <img src={maturityClock} alt="maturity" />
                            <span className="nav-text regular">Maturity</span>
                          </div>
                          <div className="bottom-border" />
                        </>
                      }
                      {(!account.accountClosed && !account.pendingGraceClosure) &&
                        <>
                          <div className="nav-row-wrap" onClick={(e: any) => { handleMenuAction(e, 'beneficiary') }}>
                            <img src={pieChart} alt="beneficiaries" />
                            <span className="nav-text regular">Beneficiaries</span>
                          </div>
                          <div className="bottom-border" />
                        </>
                      }
                      <div className="nav-row-wrap" onClick={(e: any) => { handleMenuAction(e, 'statements') }}>
                        <img src={greyDocument} alt="statements" />
                        <span className="nav-text regular">Statements</span>
                      </div>
                      {(!account.accountClosed && !account.pendingGraceClosure ) &&
                        <>
                          <div className="bottom-border" />
                          <div className="nav-row-wrap" onClick={(e: any) => { handleMenuAction(e, 'settings') }}>
                            <img src={settings} alt="settings" />
                            <span className="nav-text regular">Settings</span>
                          </div>
                        </>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>









          {/* ACCOUNT NOTIFICATIONS */}
          {accountArray.map((e: INotificationServiceObject) => {
            return (
              <>
              {e.accountId === account.id &&
                <NotificationMessageDB
                  key={e.notificationId}
                  onLinkClick={handleOnNotificationLinkClick}
                  data={e}
                  onDismiss={handleDismiss} 
                />
              }
              </>
            )
          })}
          {/* {accountNotifications.notifications.slice(0, accountNotifications.numToDisplay).map((e: any) =>
            <NotificationMessageDB
              key={e.notificationId}
              onLinkClick={handleOnNotificationLinkClick}
              data={e}
              onDismiss={handleDismiss} />)} */}





          {/* {(account.isBumpAvailable && account.isBannerAvailable && !account.accountClosed) &&
            <div className="bump-banner-wrapper" onClick={(e: any) => { e.stopPropagation(); history.push(`/account/${account.id}/bumprate`) }}>
              <div className="left-side">
                <img src={bumpIconWhite} alt="upward trending arrow" />
                <span className="text-message regular"><strong>Great news</strong>—your CD is eligible for a rate increase!<span className="link-text"> Bump Your Rate</span></span>
              </div>
              <div className="right-side">
                <img className="cancel semiboldRegular" src={close} alt="cancel" onClick={(e: any) => { e.stopPropagation(); handleRemoveBanner(account.id) }} />
              </div>
            </div>
          } */}
        </div>
        :
        <div className="information mobile">
          <div className="account-info">
            <div className="left-side mobile">
              <div className="account-name semiboldRegular" >{account.accountTitle}</div>
              <div className="account-type mobile x-small">
                {account.accountType} {account.accountNumber}
              </div>
            </div>
            <div className="right-side mobile">
              <div className="left mobile">
                <div className="balance-amount boldLarge">
                  {displayCurrency(account.currentBalance)}
                </div>
                {/* <div className="balance-type small">{balanceType} Balance</div> */}
              </div>
              <img className="open-arrow" src={arrowDown} alt="select account" />
            </div>
          </div>
          <div className="closed-pill-wrap mobile">
            {account.accountClosed && <Pill title={`Closed on ${convertDateTommddyyyy(account.accountClosedDate)}`} sx={{ backgroundColor: "var(--softRed)", color: "var(--darkRed)" }} />}
            {account.pendingGraceClosure && <Pill title="Pending closure" sx={{ backgroundColor: "var(--softRed)", color: "var(--darkRed)" }} />}
          </div>
          <div className="bottom-border-mobile" />
          <div className="bottom-row-wrap">
            {account.accountType !== "Savings" &&
              <div className="label-value">
                <span className="label-text x-small">Matures on:</span>
                <span className="value-text small">{convertDateTommddyyyy(account.maturityDate)}</span>
              </div>
            }
            <div className="label-value">
              <span className="label-text x-small">APY</span>
              <span className="value-text small">{account.interestRate.toFixed(2)}%</span>
            </div>
            <img src={elipses} alt="navigation menu" onClick={(e: any) => { setOpenNavMenu(true); e.stopPropagation(); document.body.style.overflow = "hidden" }} />
          </div>
          {accountArray.map((e: INotificationServiceObject) => {
            return (
              <>
              {e.accountId === account.id &&
                <NotificationMessageDB
                  key={e.notificationId}
                  onLinkClick={handleOnNotificationLinkClick}
                  data={e}
                  onDismiss={handleDismiss} 
                />
              }
              </>
            )
          })}
          {/* {accountNotifications.notifications.slice(0, accountNotifications.numToDisplay).map((e: any) => <div key={e.notificationId} className="account-notification-mobile"><NotificationMessageDB key={e.notificationId} data={e} onDismiss={handleDismiss} onLinkClick={handleOnNotificationLinkClick}/></div>)} */}
          {/* {(account.isBumpAvailable && account.isBannerAvailable && !account.accountClosed) &&
            <div className="bump-banner-wrapper mobile" onClick={(e: any) => { e.stopPropagation(); history.push(`/account/${account.id}/bumprate`) }}>
              <img src={bumpIconWhite} alt="upward trending arrow" />
              <div className="left-side mobile">
                <span className="text-message regular"> <strong>Great News</strong>—your CD is eligible for a rate increase!<span className="link-text"> Bump Your Rate</span></span>
              </div>
              <img className="activation-arrow" src={close} alt="cancel" onClick={(e: any) => { e.stopPropagation(); handleRemoveBanner(account.id) }} />
            </div>
          } */}

          {openNavMenu &&
            <div className="nav-menu-wrap-mobile" onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); setOpenNavMenu(false); document.body.style.overflow = "auto" }} >
              <div className="nav-menu-mobile">
                <div className="header-wrap-mobile">
                  <span className="title-text semibold">{account.accountTitle}</span>
                  <span className="close-text regular" onClick={() => { setOpenNavMenu(false); document.body.style.overflow = "auto" }}>Close</span>
                </div>
                {account.accountType !== "Savings" && (!account.accountClosed && !account.pendingGraceClosure) &&
                  <div className="nav-row-mobile" onClick={(e: any) => { e.stopPropagation(); handleOnClick(account, "maturity"); setFocus("Accounts"); setFocusIndex(account.accountNumber) }}>
                    <img src={maturityClock} alt="maturity" />
                    <span className="row-text regular" >Maturity</span>
                  </div>
                }
                {(!account.accountClosed && !account.pendingGraceClosure) &&
                  <div className="nav-row-mobile" onClick={(e: any) => { e.stopPropagation(); handleOnClick(account, "beneficiary"); setFocus("Accounts"); setFocusIndex(account.accountNumber) }}>
                    <img src={pieChart} alt="beneficiaries" />
                    <span className="row-text regular">Beneficiaries</span>
                  </div>
                }
                <div className="nav-row-mobile" onClick={(e: any) => { e.stopPropagation(); handleOnClick(account, "statements"); setFocus("Accounts"); setFocusIndex(account.accountNumber) }}>
                  <img src={greyDocument} alt="statements" />
                  <span className="row-text regular">Statements</span>
                </div>
                {(!account.accountClosed && !account.pendingGraceClosure) &&
                  <div className="nav-row-mobile" onClick={(e: any) => { e.stopPropagation(); handleOnClick(account, "settings"); setFocus("Accounts"); setFocusIndex(account.accountNumber) }}>
                    <img src={settings} alt="settings" />
                    <span className="row-text regular">Settings</span>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  );

});
