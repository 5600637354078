import { useRef } from "react";
import { useYodleeFastLink } from "../../useYodleeFastLink/useYodleeFastLink";
import { IYodleeFastLinkProps } from "../../Interfaces/IYodleeFastLinkProps";
import { useLinkExternalAccount } from "../../../../Pages/LinkExternalAccount/Context/useLinkExternalAccount";
import './YodleeFastLink.scss'
import { useWindowState } from "../../../../Context/AccountContext/useWindowState";

/**
 * The `YodleeFastLink` component
 * @param props - The FastLink props
 * @returns
 */
export const YodleeFastLink = (props: IYodleeFastLinkProps) => {
    const { isYodleeFastLinkLoaded } = useLinkExternalAccount()
    const { windowState } = useWindowState()
   
    const { isLoaded } = useYodleeFastLink(props)
   
    return (
      <>
        <div className={isLoaded ? "fast-link-container cls_mask" : "yodlee-fastlink-loading-box"} id="container-fastlink"></div>
        {!isLoaded && 
          <div className={`yodlee-launch-loader-wrap ${windowState}`}>
            <div className={`yodlee-launch-loader middle-box ${windowState}`} />
            <div className={`yodlee-launch-loader bottom-box ${windowState}`} />
          </div>
        }
      </>
    )
  }
