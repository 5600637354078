import { useHistory } from "react-router-dom"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useInterestSettings } from "../../useHooks/pageHooks/useInterestSettings"
import DesktopInterestSettings from "./DesktopInterestSettings/DesktopInterestSettings"
import MobileInterestSettings from "./MobileInterestSettings/MobileInterestSettings"

/**This component is a wrapper that determines which mode should be rendered, that is: Desktop, Hybrid, or Mobile */
const InterestSettingsWrapper = () => {
    const { windowState, selectedAccount, changeClicked, setChangeClicked, accountToTransfer, setAccountToTransfer } = useInterestSettings()
    const history = useHistory();

    if(selectedAccount.accountClosed || selectedAccount.pendingGraceClosure || selectedAccount.accountType === "Savings") {
        history.push(`/accounts/${selectedAccount.id}`);
    }

    return(
        <div>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopInterestSettings    
                            selectedAccount={selectedAccount} 
                            changeClicked={changeClicked} 
                            setChangeClicked={setChangeClicked}
                            accountToTransfer={accountToTransfer}
                            setAccountToTransfer={setAccountToTransfer}
                        />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopInterestSettings    
                            selectedAccount={selectedAccount} 
                            changeClicked={changeClicked} 
                            setChangeClicked={setChangeClicked}
                            accountToTransfer={accountToTransfer}
                            setAccountToTransfer={setAccountToTransfer}
                        />
                    </HybridLayout>
                }
                </>
                :
                <MobileInterestSettings     
                    selectedAccount={selectedAccount}
                    changeClicked={changeClicked} 
                    setChangeClicked={setChangeClicked}
                    accountToTransfer={accountToTransfer}
                    setAccountToTransfer={setAccountToTransfer}
                />
            }

        </div>
    )
}

export default InterestSettingsWrapper