import axios, { AxiosResponse } from 'axios';
import { ResponseStatus } from './models/response-status';
import { VerifiedAccountRequest } from './models/verified-account-request';
import { VerifiedAccountResponse } from './models/verified-account-response';
import { LINKING_STATUS, VerifiedAccounts } from './models/verified-accounts';
import { bffBaseUrl } from '../utils/globalVariables';


export interface IVerifiedAccount {
  /** The last 4 digits of the account number */
  accountNumberLastFourDigit: string;
  /** The type of account  */
  accountType: string;
  /** The routing number of the account */
  routingNumber: string;
  /** The balance of the account */
  balance: string;
  /** The name of the bank */
  bankName: string;
  /** If the account is already linked */
  isAlreadyExist: boolean;
  /** The Yodlee `accountId` */
  externalAccountId: string;
  /** The nickname of the account */
  accountNickName: string;
  status: LINKING_STATUS;
}

/**
 * The headers for `/yodlee/verifiedAccounts/`
 */
export interface IGetYodleeVerifiedAccountsHeaders {
  factorId: string;
  stepUpToken: string
  mfaToken: string
  apiVersion: string
}

/**
 * The transformed response for `/yodlee/verifiedAccounts/`
 */
export interface IYodleeVerifiedAccountResponse {
  status: ResponseStatus,
  message: string,
  externalAccounts: Array<IVerifiedAccount>;
}


export const getYodleeVerifiedAccounts_API = async (body: VerifiedAccountRequest, headers: IGetYodleeVerifiedAccountsHeaders) => {
  try {
    const RESPONSE = await axios.post(`${bffBaseUrl}/yodlee/verifiedAccounts`, body, { headers: headers })
    RESPONSE.data = transformResponseData(RESPONSE.data);
    return Promise.resolve(RESPONSE as AxiosResponse<IYodleeVerifiedAccountResponse>);
  }
  catch (err) {
    return Promise.reject(err);
  }
}

const transformResponseData = (data: VerifiedAccountResponse): IYodleeVerifiedAccountResponse => {
  const NEW_RESPONSE: IYodleeVerifiedAccountResponse = {
    status: data.status as ResponseStatus,
    message: data.message as string,
    externalAccounts: transformVerifiedAccounts(data.externalAccounts || [])
  }

  return NEW_RESPONSE;
}

const transformVerifiedAccounts = (accounts: Array<VerifiedAccounts>) => {
  const ACCOUNTS: Array<IVerifiedAccount> = [];

  for (let i = 0; i < accounts.length; i++) {
    const CURR = accounts[i];

    if(CURR.status as any === "Fraud"){
      CURR.status = LINKING_STATUS.FRAUD
    }

    const ACCOUNT: IVerifiedAccount = {
      accountNumberLastFourDigit: CURR.accountNumberLastFourDigit as string,
      accountType: CURR.accountType as string,
      routingNumber: CURR.routingNumber as string,
      balance: CURR.balance as string,
      bankName: CURR.bankName as string,
      isAlreadyExist: CURR.isAlreadyExist as boolean,
      externalAccountId: CURR.externalAccountId as string,
      accountNickName: CURR.accountNickName as string,
      status: CURR.status,
    }

    ACCOUNTS.push(ACCOUNT);
  }

  return ACCOUNTS;
}