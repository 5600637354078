import { ITransferDetailsPanelProps } from './interfaces/ITransferDetailsPanelProps'
import './TransferDetailsPanel.scss'
import greyLeftArrow from '../../../../assets/greyLeftArrow.svg'
import { useTransferDetailsPanel } from '../../../../useHooks/componentHooks/useTransferDetailsPanel/useTransferDetailsPanel'
import { displayCurrency } from '../../../../utils/displayCurrency'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import redTrashCan from '../../../../assets/redTrashCan.svg'
import arrowLeft from '../../../../assets/arrowLeft.svg'


/**Rendes the transfer details side panel */
const TransferDetailsPanel = (props: ITransferDetailsPanelProps) => {
    const { selectedTransfer, setSelectedTransfer, setOpenTransferDetails, setOpenModal } = props
    const { handleOnBack, windowState } = useTransferDetailsPanel(setOpenTransferDetails, setSelectedTransfer)

    if (windowState === 'Mobile') {
        return <TransferDetailsPanelMobile
            selectedTransfer={selectedTransfer}
            setOpenTransferDetails={setOpenTransferDetails}
            setOpenModal={setOpenModal}
            handleOnBack={handleOnBack}
        />
    }

    return <TransferDetailsPanelDesktop
        selectedTransfer={selectedTransfer}
        setOpenTransferDetails={setOpenTransferDetails}
        setOpenModal={setOpenModal}
        handleOnBack={handleOnBack}
    />
}


const TransferDetailsPanelDesktop = (props: any) => {
    const { selectedTransfer, setOpenTransferDetails, setOpenModal, handleOnBack } = props

    return <div className="transfer-details-panel-wrap">
        <div className="side-panel-wrap">
            <div className="side-header-wrap desktop">
                <img className="close-image" src={greyLeftArrow} alt="left arrow" onClick={() => handleOnBack()} />
                <span className="header-text desktop h4">Transfer details</span>
            </div>
            <div className="amount-wrap">
                <span className="label-text regular">Amount</span>
                <div className="amount-pill-wrap">
                    <span className="amount-value h2">{displayCurrency(+selectedTransfer.amount)}</span>
                    {selectedTransfer.status !== "" && <span className="status-pill smallBoldButton">{selectedTransfer.status}</span>}
                </div>
            </div>
            <div className='data-wrap'>
                <div className="data-row-wrap">
                    <span className="label boldRegular">Transfer ID</span>
                    <span className="value regular">{selectedTransfer.orderReference}</span>
                </div>
                <div className="bottom-border" />
                <div className="data-row-wrap">
                    <span className="label boldRegular">Transfer date</span>
                    <span className="value regular">{convertDateTommddyyyy(new Date(selectedTransfer.transferDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
                </div>
                <div className="bottom-border" />
                <div className="data-row-wrap">
                    <span className="label boldRegular">Transfer from</span>
                    <span className="value regular">{selectedTransfer.transferFromAccount} {selectedTransfer.originLastFour}</span>
                </div>
                <div className="bottom-border" />
                <div className="data-row-wrap">
                    <span className="label boldRegular">Transfer to</span>
                    <span className="value regular">{selectedTransfer.transferDesignationAccount} {selectedTransfer.designationLastFour}</span>
                </div>
                {(selectedTransfer.status !== "" && !selectedTransfer.isInitialFund && selectedTransfer.isCancelAllowed) &&
                    <>
                        <div className="bottom-border" />
                        <div data-testid="cancel transfer wrapper" className="cancel-wrap" onClick={(e: any) => {
                            setOpenModal(true);
                            setOpenTransferDetails(false);
                        }}>
                            <img className="cancel-image" src={redTrashCan} alt="cancel transfer" />
                            <span className="cancel-transfer-text regular">Cancel transfer</span>
                        </div>
                    </>
                }
            </div>
        </div>
    </div>
}

const TransferDetailsPanelMobile = (props: any) => {
    const { selectedTransfer, setOpenTransferDetails, setOpenModal, handleOnBack } = props;

    return <div className="transfer-details-panel-wrap mobile">
        <div className="header-wrap">
            <img className="close-image" src={arrowLeft} alt="left arrow" onClick={() => handleOnBack()} />
            <span className="header-text regular" onClick={() => handleOnBack()}>Transfers</span>
        </div>
        <div className="side-panel-wrap mobile">
            <div className="side-header-wrap mobile">
                <span className="header-text h4">Transfer details</span>
            </div>
            <div className="amount-wrap">
                <span className="label-text regular">Amount</span>
                <div className="amount-pill-wrap">
                    <span className="amount-value h2">{displayCurrency(+selectedTransfer.amount)}</span>
                    {selectedTransfer.status !== "" && <span className="status-pill mobile-h6">{selectedTransfer.status}</span>}
                </div>
            </div>
            <div className='data-wrap'>
                <div className="data-row-wrap mobile">
                    <span className="label mobileBoldSmall">Transfer ID</span>
                    <span className="value regular">{selectedTransfer.orderReference}</span>
                </div>
                <div className="bottom-border" />
                <div className="data-row-wrap mobile">
                    <span className="label mobileBoldSmall">Transfer date</span>
                    <span className="value regular">{convertDateTommddyyyy(new Date(selectedTransfer.transferDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
                </div>
                <div className="bottom-border" />
                <div className="data-row-wrap mobile">
                    <span className="label mobileBoldSmall">Transfer from</span>
                    <span className="value regular">{selectedTransfer.transferFromAccount} {selectedTransfer.originLastFour}</span>
                </div>
                <div className="bottom-border" />
                <div className="data-row-wrap mobile">
                    <span className="label mobileBoldSmall">Transfer to</span>
                    <span className="value regular">{selectedTransfer.transferDesignationAccount} {selectedTransfer.designationLastFour}</span>
                </div>
                {(selectedTransfer.status !== "" && !selectedTransfer.isInitialFund && selectedTransfer.isCancelAllowed) &&
                    <>
                        <div className="bottom-border" />
                        <div data-testid="cancel transfer wrapper" className="cancel-wrap" onClick={(e: any) => {
                            setOpenModal(true);
                            setOpenTransferDetails(false);
                        }}>
                            <img className="cancel-image" src={redTrashCan} alt="cancel transfer" onClick={() => { setOpenModal(true); setOpenTransferDetails(false) }} />
                            <span className="cancel-transfer-text regular" onClick={() => { setOpenModal(true); setOpenTransferDetails(false) }}>Cancel transfer</span>
                        </div>
                    </>
                }
            </div>
        </div>
    </div>
}



export default TransferDetailsPanel