import RXInput from '../../../../components/RXInput/RXInput'
import './AddOwnerForm.scss'
import { IAddOwnerFormProps } from './interfaces/IAddOwnerFormProps'
import { FormProvider } from "../../../../Form/useForm/useForm";
import { useAddOwnerForm } from "../../../../useHooks/componentHooks/useAddOwnerForm/useAddOwnerForm"
import EligibilityModal from '../EligibilityModal/EligibilityModal';
import { useLoquateOnForm, AddressInputWrapper } from '../../../../shared/LoquateAutoComplete/LoquateAutoComplete';
import { useBriteVerifyOnForm } from '../../../../shared/BriteVerify/BriteVerify';

/**Renders the first page which is the form for adding a co-owner */
const AddOwnerForm = (props: IAddOwnerFormProps) => {
    const { dispatchForm, form, setAddOwner, setFormStep, usingSuggestion, setUsingSuggestion } = props
    const { windowState, formFilled, showEligibilityModal, handleContinue, handleCancel } = useAddOwnerForm(form, dispatchForm, setFormStep)
    const { onLoquateSelection, isAddressValid } = useLoquateOnForm(form, dispatchForm)
    const { isEmailValid } = useBriteVerifyOnForm(form, dispatchForm)

    const handleValidation = async () => {
        form.setValue("address1", form.controls.address1.value.trim())
        form.setValue("address2", form.controls.address2.value.trim())
        form.setValue("city", form.controls.city.value.trim())
        try {
            await isAddressValid();
            await isEmailValid();
            handleContinue();
        }
        catch (err) {

        }

    }

    return (
        <FormProvider form={form} dispatch={dispatchForm}>
            {windowState !== "Mobile" ?
                <div className="add-owner-form-wrapper">
                    <span className="header-text h3">Co-owner information</span>
                    <div className="header-sub-text-wrap">
                        <span className="header-sub-text regular">To proceed with adding a co-owner, the co-owner must be present to complete the required information and separately agree to all related disclosures.</span>
                    </div>
                    <span className="text regular">Fields marked with an <span className="red-text">*</span> are required</span>
                    <div className="first-group">
                        <span className="title-text boldLarge">Personal information</span>
                        <div className="input-row">
                            <RXInput control={form.controls.prefix} className="quarter" />
                            <RXInput control={form.controls.firstName} className="third" />
                            <RXInput control={form.controls.middleName} className="quarter" />
                            <RXInput control={form.controls.lastName} className="third" />
                        </div>
                        <div className="input-row top-margin">
                            <RXInput control={form.controls.dateOfBirth} className="quarter" />
                        </div>
                    </div>
                    <div className="group">
                        <span className="title-text boldLarge">Residency status in the United States</span>
                        <div className="input-row">
                            <RXInput control={form.controls.citizenship} className="third" />
                            <RXInput control={form.controls.ssn} className="third" />
                        </div>
                    </div>
                    <div className="group">
                        <span className="title-text boldLarge">Address</span>
                        {usingSuggestion && <span className={`required-text small ${form.controls.address1.errors.length && 'error'} `}>Search for an address&nbsp;<span className="red-star">*</span></span>}
                        <AddressInputWrapper
                            onlyResidental
                            onSelection={onLoquateSelection}
                            control={form.controls.address1}
                            form={form}
                            dispatchForm={dispatchForm}
                            usingSuggestion={usingSuggestion}
                            setUsingSuggestion={setUsingSuggestion}
                        />
                    </div>
                    <span className={`title-text boldLarge input-row top-margin ${!usingSuggestion && 'margin'}`}>Contact information</span>
                    <div className="input-row">
                        <RXInput control={form.controls.email} className="forty" />
                        <RXInput control={form.controls.phoneNumber} className="third" />
                    </div>
                    <div className="border" />
                    <div className="button-wrapper">
                        <button className="Button btn-secondary" onClick={() => { handleCancel(); setAddOwner(false) }}>Cancel</button>
                        <button className={formFilled ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!formFilled} onClick={() => handleValidation()}>Continue</button>
                    </div>
                </div>
                :
                <div className="add-owner-form-wrapper">
                    <span className="header-text h4">Co-owner information</span>
                    <div className="header-sub-text-wrap mobile">
                        <span className="header-sub-text mobile regular">To proceed with adding a co-owner, the co-owner must be present to complete the required information and separately agree to all related disclosures.</span>
                    </div>
                    <span className="text regular">Fields marked with an <span className="red-text">*</span> are required</span>
                    <span className="title-text mobile boldRegular">Personal information</span>
                    <RXInput control={form.controls.prefix} className="full" />
                    <RXInput control={form.controls.firstName} className="full" />
                    <RXInput control={form.controls.middleName} className="full" />
                    <RXInput control={form.controls.lastName} className="full" />
                    <RXInput control={form.controls.dateOfBirth} className="full" />
                    <div className="group mobile">
                        <span className="title-text boldLarge">Residency status in the United States</span>
                        <RXInput control={form.controls.citizenship} className="full" />
                        <RXInput control={form.controls.ssn} className="full" />
                    </div>
                    <div className="group mobile">
                        <span className="title-text boldLarge">Address</span>
                        {usingSuggestion && <span className={`required-text small ${form.controls.address1.errors.length && 'error'} `}>Search for an address&nbsp;<span className="red-star">*</span></span>}
                        <AddressInputWrapper
                            onlyResidental
                            onSelection={onLoquateSelection}
                            control={form.controls.address1}
                            form={form}
                            dispatchForm={dispatchForm}
                            usingSuggestion={usingSuggestion}
                            setUsingSuggestion={setUsingSuggestion}
                        />
                    </div>
                    <div className="group mobile last">
                        <span className="title-text boldLarge">Contact information</span>
                        <RXInput control={form.controls.email} className="full" />
                        <RXInput control={form.controls.phoneNumber} className="full" />
                    </div>
                    <button className={formFilled ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!formFilled} onClick={() => handleValidation()}>Continue</button>
                </div>
            }
            {showEligibilityModal &&
                <EligibilityModal
                    errorMessage='You are ineligible to open an account. You must be a U.S. citizen or permanent resident of the U.S. to open a new account.'
                    setAddOwner={setAddOwner}
                    setFormStep={setFormStep}
                    form={form}
                    dispatchForm={dispatchForm}
                />
            }

        </FormProvider>
    )
}

export default AddOwnerForm