import { IControl } from "../Control/IControl.interface";
import { IValidator } from "./IValidator.interface";

export const maxDateValidator = (date: string):IValidator => ({
    name: "MAX_DATE",
    value: date
})

export const MAX_DATE = (control: IControl, validator: IValidator) => {
    let newValue = control.value;
    let [ yearValue, monthValue, dayValue ] = control.value.split('-')
    let [ validatorMonth, validatorDay, validatorYear ] = validator.value.split('/')
    let failedValidation = false

    validatorYear = validatorYear.substring(0,4)

    if(+yearValue > +validatorYear){
        failedValidation = true
    }
    else if((+monthValue > +validatorMonth && +yearValue >= +validatorYear)){
        failedValidation = true
    }
    else if((+dayValue > +validatorDay && +monthValue >= +validatorMonth && +yearValue >= +validatorYear)){
        failedValidation = true
    }

    if(failedValidation){
        return {...validator, newValue}
    }
    
    return false
}