/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { getAccountDetails_API, getAccountNumber_API, IAccountAgreements, IAccountNotices, IEarlyWithdrawal, IGracePeriodFundTransfer, IFundTransfer, IOwner, ISelectedInterestAccount, ITaxDocuments, populateSelectedAccountDetails, getInterestSettings_API, getAccountNotices_API, getAccountStatements_API, getAccountAgreements_API } from '../../api/getAccountInformation';
import { getBeneficiaries_API, getBeneficiaryDetails_API, IBeneficiary, patchBeneficiaryAllocations_API, patchBeneficiaryDetails_API, postNewBeneficiaryOrg_API, postNewBeneficiaryPerson_API } from '../../api/getBeneficiaries';
import { IStatements } from '../../api/getStatements';
import { IInterestSettings } from '../../api/putInterestSettings';
import { IMaturitySetting } from '../../api/putMaturitySettings';
import { RootState } from "../../app/store";
import { ITransactionHistoryData } from '../transactions/transactionsSlice';
import { StatementsCard } from '../../components/StatementsCard/StatementsCard';
import { Testing } from '../../testUtils/utils/TestingLogger/TestingLogger';
import { convertDateTommddyyyy } from '../../utils/DateUtils';
import { populateAccountAgreements, populateAccountNotices, populateStatements } from '../../utils/populateStatements';
import { IGraceTermRenewal } from '../../api/getAccountInformation/types/getAccountInformation.types';

//*Creating the interface for the selected account object*/
export interface ISelectedAccount {
  selectedAccount: {
    id: string
    accountType: string
    accountNumber: string
    accountTitle: string
    description: string
    balance: number
    interestRate: number
    term: string
    maturityDate: string
    routingNumber: string
    owner: Array<IOwner>
    interestPaid: number
    interestPaidYTD: number;
    interestPaidPriorYear: number;
    beneficiaries: Array<IBeneficiary>;
    maturitySetting: IMaturitySetting;
    selectedInterestAccount: ISelectedInterestAccount;
    interestAccount: IInterestSettings;
    accountStatements: Array<IStatements>;
    taxDocuments: Array<ITaxDocuments>;
    accountAgreements: Array<IAccountAgreements>;
    accountNotices: Array<IAccountNotices>;
    statements: Array<IStatements>;
    isBeneficiariesLoaded: boolean;
    isTransactionsLoaded: boolean
    gotBeneficiaryDetails: boolean
    creationDate: any
    bumpActivated: boolean
    bannerDismissed: boolean
    bumpedRateDate: any
    bumpedRate: number
    fundTransfer?: IFundTransfer
    earlyWithdrawal: IEarlyWithdrawal
    earlyWithdrawalSelected: boolean
    accountClosed: boolean
    accountClosedDate: any
    isGracePeriod: boolean
    gracePeriodFundTransfer: IGracePeriodFundTransfer,
    graceEndDate: any
    graceStartDate: any
    transactions: Array<any>
    isDeleted: boolean
    isBannerAvailable: boolean
    originalApy: number
    totalCDBalance: number,
    totalSavingsBalance: number,
    totalBalance: number,
    cdGroup: string,
    isBumpAvailable: boolean
    gotInterestSettings: boolean
    gotAccountStatements: boolean
    gotAccountNotices: boolean
    graceTermRenewal: IGraceTermRenewal
    currentBalance: number
    gotAccountAgreements: boolean
    earlyWithdrawalPenalty: string,
    loyaltyRate: boolean,
    pendingGraceClosure: boolean
  },
  isAccountInformationLoaded: "Loading" | "Success" | "Failed",
  isBeneficiariesLoaded: "Loading" | "Success" | "Failed",
  startBeneficiaryFlow: boolean,
  userName: string,
  w9Status: boolean,
  smsEnrolled: boolean,
  isAccountDetailsLoaded: "Loading" | "Success" | "Failed",
  openOTPModal: boolean
  OTPPassed: boolean
  isInterestSettingsLoaded: "Loading" | "Success" | "Failed"
  gotStatements: "Loading" | "Success" | "Failed"
  gotNotices: "Loading" | "Success" | "Failed"
  gotAgreements: "Loading" | "Success" | "Failed"
}

//*Initializing the selected account object*/
const initialState: ISelectedAccount = {
  selectedAccount: {
    id: "",
    accountType: "",
    accountNumber: "",
    accountTitle: "",
    description: "",
    balance: 0,
    interestRate: 0,
    term: "",
    maturityDate: "",
    routingNumber: "",
    owner: [],
    interestPaid: 0,
    interestPaidYTD: 0,
    interestPaidPriorYear: 0,
    beneficiaries: [],
    maturitySetting: { option: "", termLength: "" },
    selectedInterestAccount: { accountTitle: "", accountNumber: "", accountType: "", id: "" },
    interestAccount: { accountNumber: "" },
    accountStatements: [],
    taxDocuments: [],
    accountAgreements: [],
    accountNotices: [],
    statements: [],
    isBeneficiariesLoaded: false,
    isTransactionsLoaded: false,
    gotBeneficiaryDetails: false,
    graceEndDate: "",
    graceStartDate: "",
    creationDate: "",
    bumpActivated: false,
    bannerDismissed: false,
    bumpedRateDate: "",
    bumpedRate: 0,
    fundTransfer: { id: "", amount: 0, accountDescription: "", accountNumber: "" },
    earlyWithdrawal: {
      transferAccount: "",
      transferAmount: 0,
      transferDate: "",
      transferAccountTitle: "",
      optionalAnswer: ""
    },
    earlyWithdrawalSelected: false,
    accountClosed: false,
    accountClosedDate: "",
    isGracePeriod: false,
    gracePeriodFundTransfer: { transferAccount: "", transferAmount: 0, transferType: "", transferAccountId: "", transferId: "" },
    transactions: [],
    isDeleted: false,
    isBannerAvailable: false,
    originalApy: 0,
    totalCDBalance: 0,
    totalSavingsBalance: 0,
    totalBalance: 0,
    cdGroup: "",
    isBumpAvailable: false,
    gotInterestSettings: false,
    gotAccountStatements: false,
    gotAccountNotices: false,
    graceTermRenewal: { cdGroup: "", cdTerm: "", apy: "", productCode: "" },
    currentBalance: 0,
    gotAccountAgreements: false,
    earlyWithdrawalPenalty: "",
    loyaltyRate: false,
    pendingGraceClosure: false
  },
  isAccountInformationLoaded: "Loading",
  isBeneficiariesLoaded: "Loading",
  startBeneficiaryFlow: false,
  userName: "thomas.jefferson",
  w9Status: true,
  smsEnrolled: true,
  isAccountDetailsLoaded: "Loading",
  openOTPModal: false,
  OTPPassed: false,
  isInterestSettingsLoaded: "Loading",
  gotStatements: "Loading",
  gotNotices: "Loading",
  gotAgreements: "Loading"
}

//API call to Get Account Details
export const getAccountDetailsAsync = createAsyncThunk(
  "accountInformation/getAccountDetails_API",
  async (payload: { arrayIndex: number, account: any }) => {
    const response = await getAccountDetails_API(payload.account.id)


    return { response: response.data, account: payload.account, arrayIndex: payload.arrayIndex }
  }
)

//API Call to GET Account Beneficiaries
export const getAccountBeneficiariesAsync = createAsyncThunk(
  "accountInformation/getBeneficiaries_API",
  async (accountId: string) => {
    const response = await getBeneficiaries_API(accountId)
    return response.data
  }
)

//API Call to Get individual beneficiary details
export const getIndividualBeneficiaryDetailsAsync = createAsyncThunk(
  "accountInformation/getIndividualBeneficiaryDetails_API",
  async (apiData: { accountNumber: string, beneficiaryId: string, type: string }) => {
    const response = await getBeneficiaryDetails_API(apiData.accountNumber, apiData.beneficiaryId, apiData.type)
    response.data.type = apiData.type
    return response.data
  }
)

//API Call to Get the Account Number for the selected account, the account number is not store in redux state
export const getAccountNumberAsync = createAsyncThunk(
  "accountInformation/getAccountNumber_API",
  async (accountNumber: string) => {
    const response = await getAccountNumber_API(accountNumber)

    return response.data
  }
)

//API Post Call to Add a New Beneficiary Person
export const postNewBeneficiaryPersonAsync = createAsyncThunk(
  "accountInformation/postNewBeneficiary_API",
  async (apiPayload: any) => {
    const response = await postNewBeneficiaryPerson_API(apiPayload.accountId, apiPayload.beneficiary)

    return response.data
  }
)

//API Post Call to Add a New Beneficiary Organization
export const postNewBeneficiaryOrgAsync = createAsyncThunk(
  "accountInformation/postNewBeneficiaryOrg_API",
  async (apiPayload: any) => {
    const response = await postNewBeneficiaryOrg_API(apiPayload.accountId, apiPayload.beneficiary)

    return response.data
  }
)

//API Patch Call to Update the Allocations on all beneficiaries
export const patchBeneficiaryAllocations = createAsyncThunk(
  "accountInformation/patchBeneficiaryAllocations_API",
  async (apiPayload: any) => {
    const response = await patchBeneficiaryAllocations_API(apiPayload.accountId, apiPayload.beneficiaries)

    return response.data
  }
)

//API Patch Call to Update a Person type Beneficiary Details
export const patchBeneficiaryDetails = createAsyncThunk(
  "accountInformation/patchBeneficiaryPersonDetails_API",
  async (apiPayload: any) => {
    const response = await patchBeneficiaryDetails_API(apiPayload.accountId, apiPayload.beneficiary)

    return response?.data
  }
)

//API Get Call to get interest settings
export const getInterestSettings = createAsyncThunk(
  "selectedAccount/getInterestSettings_API",
  async (accountId: string) => {
    const response = await getInterestSettings_API(accountId)

    return response.data
  }
)

/**API Call to get a users account statements */
export const getAccountStatementsAsync = createAsyncThunk(
  "selectedAccount/getStatements_API",
  async (apiData: any) => {
    const response = await getAccountStatements_API(apiData.selectedAccountId, apiData.dateFilter)

    return { response: response.data, id: apiData.selectedAccountId }
  }
)

/**API Call to get a users account notices and other documents */
export const getAccountNoticesAsync = createAsyncThunk(
  "accountInformation/getNotices_API",
  async (accountId: string) => {
    const response = await getAccountNotices_API(accountId)

    return response.data
  }
)

/**API Call to get a users account agreements */
export const getAccountAgreementsAsync = createAsyncThunk(
  "accountInformation/getAgreements_API",
  async (accountId: string) => {
    const response = await getAccountAgreements_API(accountId)

    return response.data
  }
)

//*The selected account object slice*/
//*This takes the passed in account from the side navigation and account box components on the account-overview page*/
export const selectedAccountSlice = createSlice({
  name: 'selectedAccount',
  initialState,
  reducers: {
    //action.payload is of type IAccountInformation
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload
    },
    //sorts the beneficiary array by percentage
    setSortedBeneficiaries: (state) => {
      const SORT_BENE = state.selectedAccount.beneficiaries.sort((a: IBeneficiary, b: IBeneficiary) => {
        if (a.percentage > b.percentage) {
          return -1;
        }

        if (a.percentage < b.percentage) {
          return 1;
        }

        return 0;
      })
      state.selectedAccount.beneficiaries = SORT_BENE;

    },
    //action.payload is the array index
    removeBeneficiary: (state, action) => {
      // state.selectedAccount.beneficiaries.splice(action.payload, 1)
      state.selectedAccount.beneficiaries[action.payload].isDeleted = true
    },
    removeBeneficiaryFromArray: (state, action) => {
      const BENEFICIARIES = state.selectedAccount.beneficiaries;
      BENEFICIARIES.splice(action.payload, 1);
      state.selectedAccount.beneficiaries = BENEFICIARIES;
    },
    //blocks the API Get request from being recalled
    setIsBeneficiariesLoaded: (state) => {
      state.selectedAccount.isBeneficiariesLoaded = true //! This ne will be removed when put / post api's exoist
    },
    //sets the account that the interest payment should be transfered to
    setInterestAccountToTransfer: (state, action) => {
      let tempInterestAccount: ISelectedInterestAccount = { id: "", accountTitle: "", accountNumber: "", accountType: "" }
      if (action.payload.accountType && (action.payload.accountType === "CD" || action.payload.accountType === "Savings")) {
        tempInterestAccount = {
          id: action.payload.id,
          accountTitle: action.payload.accountTitle,
          accountNumber: action.payload.accountNumber,
          accountType: action.payload.accountType
        }
      }
      else {
        tempInterestAccount = {
          id: action.payload.id,
          accountTitle: action.payload.accountNickName,
          accountNumber: action.payload.accountNickName.slice(action.payload.accountNickName.length - 4, action.payload.accountNickName.length),
          accountType: "External"
        }
      }
      state.selectedAccount.selectedInterestAccount = tempInterestAccount
    },
    //updates the beneficiary array on the selected account, action is an array of beneficiaries
    setUpdatedBeneficiaryArray: (state, action) => {
      state.selectedAccount.beneficiaries = action.payload
      state.selectedAccount.isBeneficiariesLoaded = true
    },
    //adds a beneficiary to the beneficiary array, the action payload is a beneficiary
    addBeneficiaryToArray: (state, action) => {
      state.selectedAccount.beneficiaries = [...state.selectedAccount.beneficiaries, action.payload]
      //defualt to 100 if there is just one
      // if (state.selectedAccount.beneficiaries.length === 1) {
      //   state.selectedAccount.beneficiaries[0].percentage = 100
      // }
      state.startBeneficiaryFlow = false
    },
    //sets whether to open the add beneficiary flow when entering beneficiaries, action payload is a boolean
    setStartBeneficiaryFlow: (state, action) => {
      state.startBeneficiaryFlow = action.payload
    },
    //updates a particular beneficiareis information attached to an account.  action payload is : {arrayIndex: number, beneficiary: IBeneficiary}
    setUpdatedBeneficiaryByIndex: (state, action) => {
      state.selectedAccount.beneficiaries[action.payload.arrayIndex] = action.payload.beneficiary
    },
    //updates the account nickname
    updateAccountNickname: (state, action) => {
      state.selectedAccount.accountTitle = action.payload
    },
    //set the isTransactionsLoaded to true after the API for transactions completes on the transactions slice
    updateIsTransactionsLoaded: (state, action) => {
      state.selectedAccount.isTransactionsLoaded = action.payload
    },
    //updates gotBeneficiaryDetails key so the API will not be called when it is not neccessary
    updateGotBeneficiaryDetails: (state, action) => {
      state.selectedAccount.gotBeneficiaryDetails = action.payload
    },
    //Sets the update Bump Rate banner on the account details page to true so the banner will no longer appear
    updateBumpRateBannerDisplay: (state) => {
      state.selectedAccount.bannerDismissed = true
    },
    //activates the bump rate on a bump rate CD.  action.payload is bumpedRate
    activateBumpRate: (state, action) => {
      state.selectedAccount.interestRate = action.payload.bumpAPY
      state.selectedAccount.isBannerAvailable = false
      state.selectedAccount.bumpActivated = true
      state.selectedAccount.bumpedRateDate = action.payload.date
    },
    /**Updates the future maturity selection on the selected account for renewals, action is type renewal, term length, and changePlan with new CD info */
    updateMaturitySettings: (state, action) => {
      state.selectedAccount.maturitySetting.option = action.payload.type
      state.selectedAccount.maturitySetting.termLength = action.payload.term
      state.selectedAccount.maturitySetting.changeProduct = action.payload.changePlan
      state.selectedAccount.maturitySetting.transferAmount = action.payload.transferAmount
      state.selectedAccount.maturitySetting.changeProductCode = action.payload.changePlanCode
    },
    /**Updates the maturity settings when "Close" has been selected */
    updateMaturitySettingsOnClose: (state, action) => {
      state.selectedAccount.maturitySetting.option = "Close"
      state.selectedAccount.maturitySetting.closedAccountName = action.payload.title
      state.selectedAccount.maturitySetting.closedAccountId = action.payload.id
      state.selectedAccount.maturitySetting.transferAccountType = action.payload.accountType
    },
    /**Updates the maturity settings when Transfer Funds is selected, action is transferAccount, transferAmount: number, and transferType: "Add" | "Withdraw" */
    updateMaturitySettingsOnTransferFunds: (state, action) => {
      state.selectedAccount.maturitySetting.transferAccount = action.payload.transferAccount.title
      state.selectedAccount.maturitySetting.transferId = action.payload.transferAccount.id
      state.selectedAccount.maturitySetting.transferAmount = action.payload.transferAmount
      state.selectedAccount.maturitySetting.transferType = action.payload.transferType
      state.selectedAccount.maturitySetting.transferAccountType = action.payload.transferAccount.accountType
    },
    /**When a transfer is scheduled in maturity the ID is added here */
    addScheduledMaturityTransferId: (state, action) => {
      state.selectedAccount.maturitySetting.scheduledTransferId = action.payload
    },
    /**Removes a maturity fund transfer */
    removeMaturitySettingsFundTransfer: (state) => {
      state.selectedAccount.maturitySetting.transferId = ""
      state.selectedAccount.maturitySetting.transferAccount = ""
      state.selectedAccount.maturitySetting.transferAmount = 0
      state.selectedAccount.maturitySetting.transferType = ""
    },
    /**Sets the early withdrawal data */
    setEarlyWithdrawalData: (state, action) => {
      state.selectedAccount.earlyWithdrawal.transferAccountTitle = action.payload.accountTitle
      state.selectedAccount.earlyWithdrawal.transferAccount = action.payload.accountLastFour
      state.selectedAccount.earlyWithdrawal.transferAmount = action.payload.transferAmount
      state.selectedAccount.earlyWithdrawal.transferDate = action.payload.transferDate
      state.selectedAccount.earlyWithdrawalSelected = true
    },
    /**sets the answer to the early withdrawal optional question */
    setEarlyWithdrawalOptionalAnswer: (state, action) => {
      state.selectedAccount.earlyWithdrawal.optionalAnswer = action.payload
    },
    /**Cancels the early withdrawal request */
    cancelEarlyWithdrawal: (state) => {
      state.selectedAccount.earlyWithdrawal.transferAccountTitle = ""
      state.selectedAccount.earlyWithdrawal.transferAccount = ""
      state.selectedAccount.earlyWithdrawal.transferAmount = 0
      state.selectedAccount.earlyWithdrawal.transferDate = ""
      state.selectedAccount.earlyWithdrawal.optionalAnswer = ""
      state.selectedAccount.earlyWithdrawalSelected = false
    },
    /**Updates the selected term change found in grace period flow */
    updateGraceTermChange: (state, action) => {
      state.selectedAccount.graceTermRenewal = action.payload.renewal
      state.selectedAccount.accountType = action.payload.accountType
      state.selectedAccount.description = action.payload.description
      state.selectedAccount.accountTitle = action.payload.description
      state.selectedAccount.term = action.payload.term
      state.selectedAccount.interestRate = action.payload.interestRate
      state.selectedAccount.maturityDate = action.payload.maturityDate
      state.selectedAccount.cdGroup = action.payload.cdGroup
      state.selectedAccount.maturitySetting.option = action.payload.maturitySettings.option
      state.selectedAccount.maturitySetting.termLength = action.payload.maturitySettings.termLength
      state.selectedAccount.maturitySetting.changeProduct = action.payload.maturitySettings.changeProduct
      // state.selectedAccount.maturitySetting. = action.payload.maturitySettings
    },
    /**Updates the maturity settings when Transfer Funds is selected, action is transferAccount, transferAmount: number, and transferType: "Add" | "Withdraw" */
    updateGraceSettingsOnTransferFunds: (state, action) => {
      state.selectedAccount.gracePeriodFundTransfer.transferAccountId = action.payload.transferAccountId
      state.selectedAccount.gracePeriodFundTransfer.transferAccount = action.payload.transferAccount
      state.selectedAccount.gracePeriodFundTransfer.transferAmount = action.payload.transferAmount
      state.selectedAccount.gracePeriodFundTransfer.transferType = action.payload.transferType
    },
    /**Updates the grace period fund transfer id */
    updateGraceFundTransferId: (state, action) => {
      state.selectedAccount.gracePeriodFundTransfer.transferId = action.payload
    },
    /**Removes a request fund transfer from grace period */
    removeGraceFundTransfer: (state) => {
      state.selectedAccount.gracePeriodFundTransfer.transferAccountId = ""
      state.selectedAccount.gracePeriodFundTransfer.transferId = ""
      state.selectedAccount.gracePeriodFundTransfer.transferAccount = ""
      state.selectedAccount.gracePeriodFundTransfer.transferAmount = 0
      state.selectedAccount.gracePeriodFundTransfer.transferType = ""
    },
    /**Closes an account */
    closeSelectedAccount: (state) => {
      state.selectedAccount.pendingGraceClosure = true
      // state.selectedAccount.accountClosed = true
      //! This date may need to be set to GMT using the ISO function, need to double check this with business when we put grace period closure back in.
      // state.selectedAccount.accountClosedDate = convertDateTommddyyyy(new Date())
    },
    /**Add transactions to selectedAccount */
    setSelectedAccountTransactions: (state, action) => {
      state.selectedAccount.transactions = action.payload
    },
    /**Removes the bump rate banner */
    removeBumpRateBanner: (state, action) => {
      state.selectedAccount.isBannerAvailable = action.payload
    },
    /**sets the OTP menu status true= open, false= close */
    setOTPMenuStatus: (state, action) => {
      state.openOTPModal = action.payload
    },
    /**Sets if the OTP check passed or failed, true means passed, false means failed */
    setOTPPassed: (state, action) => {
      state.OTPPassed = action.payload
    },
    /**Updates gotStatements when necessary, ie: when a previous account call has failed, but we have the current account data already retreived */
    setGotAccountStatements: (state, action) => {
      state.gotStatements = action.payload
    },
    /**updates gotNotices when necessary, ie: when a previous account call has failed, but we have the current account data already retreived */
    setGotAccountNotices: (state, action) => {
      state.gotNotices = action.payload
    },
    /**Updates the transfer account name */
    updateTransferAccountName: (state, action) => {
      state.selectedAccount.maturitySetting.transferAccount = action.payload
    },
    /**updates gotAgreements when necessary, ie: when a previous account call failed but we have the current account already retreived */
    setGotAccountAgreements: (state, action) => {
      state.gotAgreements = action.payload
    },
    /**Updates isInterestedSettigngsLoaded to "Success" under the scenario that we already have retreived interest settings */
    setIsInterestSettingsLoaded: (state, action) => {
      state.isInterestSettingsLoaded = action.payload
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAccountBeneficiariesAsync.pending, (state) => {
        state.isBeneficiariesLoaded = 'Loading';
      })
      .addCase(getAccountBeneficiariesAsync.fulfilled, (state, action: any) => {
        state.selectedAccount.isBeneficiariesLoaded = true
        state.isBeneficiariesLoaded = 'Success';
        state.selectedAccount.beneficiaries = action.payload.beneficiaries
      })
      .addCase(getAccountBeneficiariesAsync.rejected, (state) => {
        state.isBeneficiariesLoaded = 'Failed';
        console.error()
      })
      .addCase(getIndividualBeneficiaryDetailsAsync.pending, (state) => { })
      .addCase(getIndividualBeneficiaryDetailsAsync.fulfilled, (state, action) => { })
      .addCase(getIndividualBeneficiaryDetailsAsync.rejected, (state) => { })
      .addCase(getAccountNumberAsync.pending, (state) => { })
      .addCase(getAccountNumberAsync.fulfilled, (state, action) => { })
      .addCase(getAccountNumberAsync.rejected, (state) => { })
      .addCase(getAccountDetailsAsync.pending, (state) => {
        state.isAccountDetailsLoaded = "Loading"
      })
      .addCase(getAccountDetailsAsync.fulfilled, (state, action) => {
        const tempAccount = populateSelectedAccountDetails(
          action.payload.response,
          action.payload.account,
          state.selectedAccount.isBeneficiariesLoaded ? current(state.selectedAccount.beneficiaries) : [],
          state.selectedAccount.gotAccountStatements ? current(state.selectedAccount.accountStatements) : []
        )
        state.selectedAccount = tempAccount
        state.isAccountDetailsLoaded = "Success"
        // const payload = {arrayIndex: action.payload.arrayIndex, account: tempAccount}
      })
      .addCase(getAccountDetailsAsync.rejected, (state) => {
        state.isAccountDetailsLoaded = "Failed"
      })
      .addCase(postNewBeneficiaryPersonAsync.pending, (state) => { })
      .addCase(postNewBeneficiaryPersonAsync.fulfilled, (state, action) => { })
      .addCase(postNewBeneficiaryPersonAsync.rejected, (state) => { })
      .addCase(postNewBeneficiaryOrgAsync.pending, (state) => { })
      .addCase(postNewBeneficiaryOrgAsync.fulfilled, (state, action) => { })
      .addCase(postNewBeneficiaryOrgAsync.rejected, (state) => { })
      .addCase(patchBeneficiaryAllocations.pending, (state) => { })
      .addCase(patchBeneficiaryAllocations.fulfilled, (state, action) => { })
      .addCase(patchBeneficiaryAllocations.rejected, (state) => { })
      .addCase(patchBeneficiaryDetails.pending, (state) => { })
      .addCase(patchBeneficiaryDetails.fulfilled, (state, action) => { })
      .addCase(patchBeneficiaryDetails.rejected, (state) => { })
      .addCase(getInterestSettings.pending, (state) => {
        state.isInterestSettingsLoaded = "Loading"
      })
      .addCase(getInterestSettings.fulfilled, (state, action) => {
        const tempInterestAccount: ISelectedInterestAccount = {
          id: action.payload.transferAccountId,
          accountTitle: action.payload.acctNickName,
          accountNumber: action.payload.accountLastFourDigit,
          accountType: action.payload.accountType
        }
        state.selectedAccount.selectedInterestAccount = tempInterestAccount
        state.selectedAccount.gotInterestSettings = true
        state.isInterestSettingsLoaded = "Success"
      })
      .addCase(getInterestSettings.rejected, (state) => {
        state.selectedAccount.gotInterestSettings = false
        state.isInterestSettingsLoaded = "Failed"
      })
      .addCase(getAccountStatementsAsync.pending, (state) => {
        state.gotStatements = "Loading"
      })
      .addCase(getAccountStatementsAsync.fulfilled, (state, action) => {
        state.selectedAccount.gotAccountStatements = true
        state.gotStatements = "Success"
        let tempStatements = populateStatements(action.payload.response.documentIds)
        state.selectedAccount.accountStatements = tempStatements
      })
      .addCase(getAccountStatementsAsync.rejected, (state) => {
        state.gotStatements = "Failed"
      })
      .addCase(getAccountNoticesAsync.pending, (state) => {
        state.gotNotices = "Loading"
      })
      .addCase(getAccountNoticesAsync.fulfilled, (state, action) => {
        state.gotNotices = "Success"
        state.selectedAccount.accountNotices = populateAccountNotices(action.payload.documentIds)
        state.selectedAccount.gotAccountNotices = true
      })
      .addCase(getAccountNoticesAsync.rejected, (state) => {
        state.gotNotices = "Failed"
      })
      .addCase(getAccountAgreementsAsync.pending, (state) => {
        state.gotAgreements = "Loading"
      })
      .addCase(getAccountAgreementsAsync.fulfilled, (state, action) => {
        state.gotAgreements = "Success"
        state.selectedAccount.accountAgreements = populateAccountAgreements(action.payload.tncDocNames)
        state.selectedAccount.gotAccountAgreements = true
      })
      .addCase(getAccountAgreementsAsync.rejected, (state) => {
        state.gotAgreements = "Failed"
      })
  },
});

export const selectedAccount = (state: RootState) => state.selectedAccount
export const { setSelectedAccount, setSortedBeneficiaries, removeBeneficiary, setIsBeneficiariesLoaded, setInterestAccountToTransfer, closeSelectedAccount,
  setUpdatedBeneficiaryArray, addBeneficiaryToArray, setStartBeneficiaryFlow, setUpdatedBeneficiaryByIndex, removeGraceFundTransfer,
  updateAccountNickname, updateIsTransactionsLoaded, updateGotBeneficiaryDetails, updateBumpRateBannerDisplay, activateBumpRate,
  updateMaturitySettings, updateMaturitySettingsOnClose, updateMaturitySettingsOnTransferFunds, removeMaturitySettingsFundTransfer,
  setEarlyWithdrawalData, cancelEarlyWithdrawal, setEarlyWithdrawalOptionalAnswer, updateGraceTermChange, updateGraceSettingsOnTransferFunds,
  setSelectedAccountTransactions, removeBumpRateBanner, addScheduledMaturityTransferId, setOTPMenuStatus, setOTPPassed, setGotAccountStatements, setGotAccountNotices, removeBeneficiaryFromArray,
  updateTransferAccountName, setGotAccountAgreements, updateGraceFundTransferId, setIsInterestSettingsLoaded } = selectedAccountSlice.actions;

export default selectedAccountSlice.reducer;

const delay = () => {
  setTimeout(() => {
    return "Success"
  }, 10)
}