import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { deleteYodleeToken_API } from '../../../../api/User/yodleeServices'
import { IEDPErrorInterface, edpErrorReporting_API } from '../../../../api/edpErrorReporting'
import YodleeFastLinkWrapper from '../../../../shared/YodleeFastLink/YodleeFastLinkWrapper'
import { useLinkExternalAccount } from '../../Context/useLinkExternalAccount'
import { PostYodleeFlowChart } from '../PostYodleeFlow/PostYodleeFlowChart/PostYodleeFlowChart.component'
import './YodleeWrapperPage.scss'

const YodleeWrapperPage = () => {
    const { windowState } = useWindowState()
    const { setStep, providerAccountId, postYodleeFlow, setYodleeToken } = useLinkExternalAccount()

    /**Handle the manual verification onClick event */
    const handleManualVerificationClick = async () => {
        // try{
        //     await deleteYodleeToken_API()
        //     localStorage.removeItem("token")
        //     setYodleeToken("")
        // }
        // catch(error: any){
        //     let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
        //     let edpErrorObject: IEDPErrorInterface = {
        //         category: "External_Account",
        //         errorType: "API_error",
        //         errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""}],
        //         timeStamp: new Date().toISOString(),
        //         additionalDetails: {}
        //     }
        //     try{
        //         edpErrorReporting_API(edpErrorObject)
        //     }
        //     catch{}
        // }
        setStep(3)
    }

    return (
        <div className="yodlee-page-wrap">
            <div className={`header-wrap ${windowState}`}>
                <span className={windowState !== "Mobile" ? "header-text regular" : "header-text small"}>Link external account</span>
                <span className={windowState !== "Mobile" ? "sub-header-text h3" : "sub-header-text h4"}>Instant verification</span>
            </div>
            <div className="yodlee-fast-link-wrapper">
                {providerAccountId ? 
                     <PostYodleeFlowChart />
                     :
                     <YodleeFastLinkWrapper />
                }
                {/* {postYodleeFlow.providerAccountId ? 
                    <YodleeFastLinkWrapper />
                    :
                    <PostYodleeFlowChart />
                } */}
            </div>
            <div className={`static-footer ${windowState}`}>
                <span className="footer-text boldRegular">Having trouble verifying this way?</span>
                <span className="footer-link regular" onClick={() => handleManualVerificationClick()}>Use manual verification instead.</span>
            </div>
        </div>
    )
}

export default YodleeWrapperPage