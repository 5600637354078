import { useUpdateMaturityPlan } from '../../../../useHooks/componentHooks/useUpateMaturityPlan/useUpdateMaturityPlan'
import { IUpdateMaturityPlanProps } from './interfaces/IUpdateMaturityPlanProps'
import './UpdateMaturityPlan.scss'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import { displayCurrency } from '../../../../utils/displayCurrency'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import accountBadge from '../../../../assets/accountBadge.svg'
import chartArrowRight from '../../../../assets/chartArrowRight.svg'
import chartArrowDown from '../../../../assets/chartArrowDown.svg'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders the update maturity plan screen found as the last part of the maturity settings flow */
const UpdateMaturityPlan = (props: IUpdateMaturityPlanProps) => {
    const { changePlanOption, setPlanChange, setShowTermChange, chosenTerm, setChangePlanOption, setTermChange, termChange, setManageFunds, transferType, setTransferType } = props
    const { selectedAccount, handleSubmit, windowState, handleCancel } = useUpdateMaturityPlan(chosenTerm, setPlanChange, setShowTermChange, setChangePlanOption, changePlanOption, setTermChange, setManageFunds, setTransferType)

    let transferAccountType = ""
    transferAccountType = transferAccountType + selectedAccount.maturitySetting.transferAccountType?.charAt(0).toUpperCase() + selectedAccount.maturitySetting.transferAccountType?.slice(1)

    return (
        <>

            {windowState === "Mobile" &&
                <div className="mobile-update-header-wrap">
                    <div className="left-side">
                        <img className="back-arrow" src={arrowLeftBlue} alt="back button" />
                        <span className="header-text regular" onClick={() => setShowTermChange(false)}>Back</span>
                    </div>
                    <span className="header-text regular" onClick={() => handleCancel()}>Cancel</span>
                </div>
            }
            <div className={windowState !== "Mobile" ? "update-maturity-page-wrap desktop" : "update-maturity-page-wrap"}>
                <div className={windowState !== "Mobile" ? "update-maturity-wrapper" : "update-maturity-wrapper update-mobile"}>
                    <div className="header-wrap">
                        {changePlanOption === "Renew" && <span className="header-text small">Renew your CD at maturity</span>}
                        {changePlanOption === "Close" && <span className="header-text small">Close your CD at maturity</span>}
                        {changePlanOption === "" && <span className="header-text small">Add or withdraw funds at renewal</span>}
                        <span className={windowState !== "Mobile" ? "sub-header-text h3" : "sub-header-text h4"} >Review & confirm changes</span>
                    </div>
                    <div className="data-wrap">
                        {(changePlanOption === "Renew" || termChange) && <span className={windowState !== "Mobile" ? "selected-option h4" : "selected-option boldLarge"}>Renew CD at maturity</span>}
                        {changePlanOption === "Close" && <span className={windowState !== "Mobile" ? "selected-option h4" : "selected-option boldLarge"}>Action at maturity</span>}
                        {changePlanOption === "" &&
                            <>
                                {transferType !== "Withdraw" ?
                                    <span className="selected-option h4">Add funds at renewal</span>
                                    :
                                    <span className="selected-option h4">Withdraw funds at renewal</span>
                                }
                            </>
                        }
                        {changePlanOption !== "" ?
                            <>
                                <div className="section-info-wrap">
                                    <div className="label-value-stack">
                                        <span className="header-text boldSmall">Maturity date</span>
                                        {selectedAccount.accountType !== "Savings" && <span className="data-text regular cls_mask">{convertDateTommddyyyy(selectedAccount.maturityDate)}</span>}
                                    </div>
                                    {(changePlanOption === "Renew" || termChange) ?
                                        <>
                                            <div className="label-value-stack">
                                                <span className="header-text boldSmall">Term</span>
                                                <span className="data-text regular cls_mask">{chosenTerm.group === "JumboCD" ? "Jumbo CD" : "Bumpup CD"} {chosenTerm.name.toLowerCase()}</span>
                                            </div>
                                            <div className="label-value-stack">
                                                <span className="header-text boldSmall">New APY</span>
                                                <span className="data-text regular">Determined at renewal</span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {changePlanOption === "Close" &&
                                                <>
                                                    <div className="label-value-stack">
                                                        <span className="header-text boldSmall">Action at maturity</span>
                                                        <span className="data-text regular">Close CD</span>
                                                    </div>
                                                    <div className="label-value-stack">
                                                        <span className="header-text boldSmall">Transfer balance to</span>
                                                        <span className="data-text regular cls_mask">{selectedAccount.maturitySetting?.closedAccountName}</span>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </>
                            :
                            <div className="section-info-wrap">
                                <div className={windowState !== "Mobile" ? "box-row" : "box-row stack"}>
                                    <div className={windowState !== "Mobile" ? "box-wrap" : "box-wrap full"}>
                                        <span className="header-text boldSmall">Transfer from</span>
                                        <div className="bottom-row">
                                            <img src={accountBadge} alt="badge" />
                                            <div className="account-data-stack">
                                                <span className="account-text regular cls_mask">{transferType !== "Withdraw" ? selectedAccount.maturitySetting.transferAccount : selectedAccount.accountTitle}</span>
                                                <span className="account-type small cls_mask">{transferType !== "Withdraw" ? selectedAccount.maturitySetting.transferAccountType : "CD"} </span>
                                            </div>
                                        </div>
                                    </div>
                                    {windowState !== "Mobile" ?
                                        <img src={chartArrowRight} alt="right facing arrow" />
                                        :
                                        <img src={chartArrowDown} alt="downward facing arrow" />
                                    }
                                    <div className={windowState !== "Mobile" ? "box-wrap" : "box-wrap full"}>
                                        <span className="header-text boldSmall">Transfer to</span>
                                        <div className="bottom-row">
                                            <img src={accountBadge} alt="badge" />
                                            <div className="account-data-stack">
                                                <span className="account-text regular cls_mask">{transferType !== "Withdraw" ? selectedAccount.accountTitle : selectedAccount.maturitySetting.transferAccount}</span>
                                                <span className="account-type small cls_mask">{transferType !== "Withdraw" ? "CD" : selectedAccount.maturitySetting.transferAccountType}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="label-value-stack margin">
                                    <span className="header-text boldSmall">Amount</span>
                                    <span className="data-text regular cls_mask">{selectedAccount.maturitySetting.transferAmount ? displayCurrency(+selectedAccount.maturitySetting.transferAmount) : 0}</span>
                                </div>
                                <div className="label-value-stack margin">
                                    <span className="header-text boldSmall">Transfer date</span>
                                    {selectedAccount.accountType !== "Savings" && <span className="data-text regular cls_mask">{convertDateTommddyyyy(selectedAccount.maturityDate)}</span>}
                                </div>
                                {/* <span className="data-text semiboldRegular">{transferType !== "Withdraw" ? selectedAccount.maturitySetting.transferAccount : selectedAccount.accountTitle}</span> */}
                                {/* <span className="header-text boldSmall">Transfer to</span> */}
                                {/* <span className="data-text regular">{transferType !== "Withdraw" ? selectedAccount.accountTitle : selectedAccount.maturitySetting.transferAccount}</span> */}
                            </div>
                        }
                    </div>
                    {windowState !== "Mobile" && <div className="bottom-border" />}
                    {windowState !== "Mobile" ?
                        <div className="button-wrap">
                            <div className="left-side" onClick={() => setShowTermChange(false)}>
                                <img className="back-arrow" src={arrowLeftBlue} alt="back" />
                                <span className="back-text regular">Back</span>
                            </div>
                            <div className="right-side">
                                <button className="Button btn-secondary" onClick={() => handleCancel()}>Cancel</button>
                                <Button 
                                    title="Confirm changes"
                                    className="Button btn-primary"
                                    id={TIMER_IDS.MATURITY_CONFIRM}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                        :
                        <div className="mobile-button-wrap">
                            <Button 
                                title="Confirm changes"
                                className="Button btn-primary"
                                id={TIMER_IDS.MATURITY_CONFIRM}
                                onClick={handleSubmit}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default UpdateMaturityPlan