import { useCloseAccount } from '../../../../useHooks/componentHooks/useCloseAccount/useCloseAccount'
import './CloseAccount.scss'
import { ICloseAccountProps } from './interfaces/ICloseAccountProps'
import bluePlus from '../../../../assets/bluePlus.svg'
import bankBlue from '../../../../assets/bankBlue.svg'
import blueSettings from '../../../../assets/blueSettings.svg'
import { useHistory } from 'react-router-dom'
// import { SelectDropdown } from '../../../../components/SelectDropdown/SelectDropdown'
import OpenSavingsAccount from '../../../EarlyWithdrawal/components/OpenSavingsAccount/OpenSavingsAccount'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
// import { Button } from '../../../../components/Button/Button'
import TransfersDropDown from '../../../../components/TransfersDropDown/TransfersDropDown'
import { getSAMLURL } from '../../../../shared/config/getSAMLUrl'
import { useOpenNewAccount } from '../../../../Context/OpenNewAccountContext/useOpenNewAccountContext'

/**Renders the Close Account page found in Grace Period */
const CloseAccount = (props: ICloseAccountProps) => {
    const history = useHistory()
    const { setCloseAccount, setShowTermChange, transferAccount, setTransferAccount, hasSavings } = props
    const { linkedAccounts, foundSavings, setFoundSavings, handleContinue, windowState, savingsPageClosed, setSavingsPageClosed, enableSave,
        showLimitModal, setShowLimitModal, handleLinkExternalAccount, openSavings, setOpenSavings } = useCloseAccount(transferAccount, setTransferAccount, hasSavings)
    const { checkExternalAccounts } = useOpenNewAccount()

    return (
        <>
            {savingsPageClosed ?
                <>
                    {windowState !== "Mobile" ?
                        <div className="desktop-grace-close-account-wrap">
                            <div className="desktop-launch-withdrawal-wrap">
                                <div className="header-wrap">
                                    <span className="header-text regular">Close your CD</span>
                                    <span className="sub-header-text h4">Where would you like to transfer your balance?</span>
                                </div>
                                <span className="disclaimer-text regular">The transfer of your CD’s balance to the account below will be initiated immediately.</span>
                                <div className="dropdown-wrapper">
                                    <TransfersDropDown
                                        label={'Transfer balance to:'}
                                        isRequired={false}
                                        defaultValue="Select an Account"
                                        selectedOption={transferAccount}
                                        setSelectedOption={setTransferAccount}
                                        selectOptions={linkedAccounts}
                                        testId='grace-close-account'
                                    />
                                </div>
                                <div className="navigation-wrapper">
                                    {!hasSavings &&
                                        <div className="nav-option" onClick={checkExternalAccounts}>
                                            <img src={bluePlus} alt="open savings account" />
                                            <span className="nav-text">Open a Savings Account </span>
                                        </div>
                                    }
                                    <div className="nav-option" onClick={() => handleLinkExternalAccount()}>
                                        <img src={bankBlue} alt="link external account" />
                                        <span className="nav-text">Link external account</span>
                                    </div>
                                    <div className="nav-option" onClick={() => history.push("/user/profile/external-accounts")}>
                                        <img src={blueSettings} alt="manger external accounts" />
                                        <span className="nav-text">Manage external accounts</span>
                                    </div>
                                </div>
                                <div className="button-wrapper">
                                    <div className="left">
                                        <img src={arrowLeftBlue} alt="back arrow" />
                                        <span className="back-text regular" onClick={() => setCloseAccount(false)}>Back</span>
                                    </div>
                                    <div className="right">
                                        <button className="Button btn-secondary" onClick={() => setCloseAccount(false)}>Cancel</button>
                                        <button className={enableSave ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableSave} onClick={() => { setShowTermChange(true); handleContinue() }}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="mobile-grace-close-account-wrap">
                            <div className="header-buttons">
                                <div className="left-side">
                                    <img src={arrowLeftBlue} alt="back button" />
                                    <span className="back-text regular" onClick={() => setCloseAccount(false)}>Back</span>
                                </div>
                                <span className="cancel-text regular" onClick={() => setCloseAccount(false)}>Cancel</span>
                            </div>
                            <div className="card-wrap">
                                <div className="card-header-wrap">
                                    <span className="title-text small">Close your CD</span>
                                    <span className="sub-title-text boldRegular">Where would you like to transfer your balance to?</span>
                                </div>
                                <span className="info-text regular">The transfer of your CD’s balance to the account below will be initiated immediately.</span>
                                <div className="mobile-dropdown-wrapper">
                                    <TransfersDropDown
                                        label={'Transfer balance to:'}
                                        isRequired={false}
                                        defaultValue="Select an Account"
                                        selectedOption={transferAccount}
                                        setSelectedOption={setTransferAccount}
                                        selectOptions={linkedAccounts}
                                        testId='grace-close-account'
                                    />
                                </div>
                                <div className="mobile-navigation-wrapper">
                                    {!hasSavings &&
                                        <div className="mobile-nav-option" onClick={checkExternalAccounts}>
                                            <img src={bluePlus} alt="open savings account" />
                                            <span className="nav-text small">Open a Savings Account </span>
                                        </div>
                                    }
                                    <div className="mobile-nav-option" onClick={() => handleLinkExternalAccount()}>
                                        <img src={bankBlue} alt="link external account" />
                                        <span className="nav-text small">Link external account</span>
                                    </div>
                                    <div className="mobile-nav-option" onClick={() => history.push("/user/profile/external-accounts")}>
                                        <img src={blueSettings} alt="manger external accounts" />
                                        <span className="nav-text small">Manage external accounts</span>
                                    </div>
                                </div>
                            </div>
                            <div className="button-wrap">
                                <button className={enableSave ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableSave} onClick={() => { setShowTermChange(true); handleContinue() }}>Continue</button>
                            </div>
                        </div>
                    }
                </>
                :
                <OpenSavingsAccount
                    setConfirmEarlyWithdrawal={setCloseAccount}
                    setFoundSavings={setSavingsPageClosed}
                    setCloseAccount={setCloseAccount}
                />
            }
        </>
    )
}

export default CloseAccount