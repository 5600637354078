/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { removeBumpBanner_API } from "../../../api/updateBumpRate"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { removeBumpRateBannerByIndex, setAccountInformationByIndex, updateAccountStatementsByIndex, updateBeneficiaryArrayByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { getAccountBeneficiariesAsync, getAccountDetailsAsync, getAccountStatementsAsync, removeBumpRateBanner } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { getEventPath } from "../../../utils/getEventPath"
import { populateAccountDetails } from "../../../utils/populateAccountDetails"
import { useIsMounted } from '../../useIsMounted/useIsMounted'
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { populateStatements } from "../../../utils/populateStatements"

/**
 * custom use hook for the AccountBox component.  Controls the selectedAccount context state and adjusts
 * the focus and focusIndex state for the side navigation component.
 */
export const useAccountBox = () => {
    const history = useHistory()
    const { windowState } = useWindowState()
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [ focus, setFocus ] = useState<"Accounts" | "Transfers" | "Documents">("Accounts")
    const [ focusIndex, setFocusIndex ] = useState<string>("")
    const dispatch = useAppDispatch();
    const [ openNavMenu, setOpenNavMenu ] = useState<boolean>(false)
    const isMounted = useIsMounted();
    const {resetToast, setToastMessage} = useToastMessage()
    const selectedClickedRef = useRef<boolean>(false)

    /**
     * Sets the focusIndex and focus states for the side navigation component
     */
        //  useEffect(() => {
        //     const adjustedUrl = window.location.pathname.split('/')[2]
        //     setFocusIndex(adjustedUrl)
        //     if(focusIndex !== ""){
        //         setFocus("Accounts")
        //     }

        // }, [selectedAccount, focusIndex])


    const setAccountDetails = async (account: any) => {
        let tempAccount: any
        const index = accountInformation.findIndex((accountInfo: IAccountInformation) => accountInfo.id === account.id)
        const payload = {arrayIndex: index, account: accountInformation[index]}
        const apiData = {selectedAccountId: account.id, dateFilter: 'All'}

        await dispatch(getAccountDetailsAsync(payload))
                .then((response: any) =>{
                    document.body.style.overflow = "auto";
                    tempAccount = populateAccountDetails(accountInformation[index], response.payload.response)
                    const payload = {arrayIndex: index, account: tempAccount}
                    dispatch(setAccountInformationByIndex(payload))
                    selectedClickedRef.current = false
                })
                .catch(() => {
                    console.error()
                    selectedClickedRef.current = false
                })
    }

    /**Handles the onClick event for the account boxes */
    const handleOnClick = async (account: any, location: string, notificationUrl: string = "") => {

        const index = accountInformation.findIndex((accountInfo: IAccountInformation) => accountInfo.id === account.id)

        const apiData = {selectedAccountId: account.id, dateFilter: 'All'}

        if(!selectedClickedRef.current){
            selectedClickedRef.current = true
            if(location === "details"){
                await setAccountDetails(account)
                history.push(`/accounts/${account.id}`)
            }
            else{            
                switch(location){
                    case "maturity":
                        await setAccountDetails(account)
                        history.push(`/account/${account.id}/maturity`, {page: "Accounts"})
                        break
                    case "beneficiary":
                        await setAccountDetails(account)
                        await dispatch(getAccountBeneficiariesAsync(account.id))
                        .then((response: any) => {
                            const payload = {arrayIndex: index, beneficiaryArray: response.payload.beneficiaries}
                            dispatch(updateBeneficiaryArrayByIndex(payload))    
                            history.push(`/account/${account.id}/beneficiary`, {page: "Accounts"})
                            selectedClickedRef.current = false
                        })
                        .catch((error: any) => {console.error();selectedClickedRef.current = false})
                        break
                    case "statements":
                        await setAccountDetails(account)
                        await dispatch(getAccountStatementsAsync(apiData))
                        .then((response: any) => {
                            const payload = {arrayIndex: index, statements: populateStatements(response.payload.response.documentIds)}
                            dispatch(updateAccountStatementsByIndex(payload))    
                            history.push(`/account/${account.id}/statements`, {page: "Accounts"})
                            selectedClickedRef.current = false
                        })
                        .catch((error: any) => {console.error(); selectedClickedRef.current = false})
                        break
                    case "settings":
                        await setAccountDetails(account)
                        history.push(`/user/${account.id}/settings`, {page: "Accounts"})
                        break
                    case "graceperiod":
                        await setAccountDetails(account)
                        history.push(`/account/${account.id}/graceperiod`, {page: "Accounts"})
                        break
                    case "bumprate":
                        await setAccountDetails(account)
                        history.push(notificationUrl)
                }
            }
        }
    }

    /**Checks for click outside of nav menu box, if ouside then the nav menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(openNavMenu && Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("nav-menu-wrap")) === -1){
                // event.stopPropagation()
                document.body.style.overflow = "auto";
                setTimeout(() => {
                    if(isMounted()) {
                        setOpenNavMenu(false)
                    }
                }, 1)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openNavMenu])   

    /**updates the accountInformation array with the account details when the selected account changes */
    useEffect(() => {
        const index = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
        if(index >= 0 && !accountInformation[index].gotAccountDetails && selectedAccount.term !== ""){
            let tempAccount = JSON.parse(JSON.stringify(selectedAccount))
            tempAccount.gotAccountDetails = true
            const payload = {arrayIndex: index, account: tempAccount}
            dispatch(setAccountInformationByIndex(payload))
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]) 
    
    /**Handles the api call to remove the bump rate banner when the onClick event occurs on the X button */
    const handleRemoveBanner = async (accountId: string) => {
        try{
            await removeBumpBanner_API(accountId)
            dispatch(removeBumpRateBanner(false))
            const index = accountInformation.findIndex((account: IAccountInformation) => account.id === accountId)
            if(index >= 0){
                const payload = {arrayIndex: index, show: false}
                dispatch(removeBumpRateBannerByIndex(payload))
            }
            setToastMessage("SUCCESS", "Banner removed.")
            resetToast()
        }
        catch{
            console.log("REMOVE BANNER SERVICE FAILED")
            setToastMessage("ERROR", "Unable to save changes. Please try again later.")
            resetToast()
        }
    }

    return { setFocus, setFocusIndex, windowState, dispatch, selectedAccount, handleOnClick, openNavMenu, setOpenNavMenu, history, handleRemoveBanner }
}