import './DesktopDocumentAccountStatements.scss'
import { IDesktopDocumentAccountStatementProps } from './interfaces/IDesktopDocumentAccountStatementProps'
import blueDocument from '../../../assets/blueDocument.svg'
import { useDocumentAccountStatements } from '../../../useHooks/componentHooks/useDocumentAccountStatements/useDocumentAccountStatements'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import emptyStatements from '../../../assets/emptyStatements.svg'
import warning from '../../../assets/warning.svg'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'

/** Displays the desktop version of the Documents Accounts Statements page */
const DesktopDocumentAccountStatements = (props: IDesktopDocumentAccountStatementProps) => {
    const { selectedDate, filteredAccounts } = props
    const { gotStatements, handleStatementDownload, type, message } = useDocumentAccountStatements(selectedDate)

    return (
        <div className="desktop-document-accounts-wrapper">
            <div className="statements-card-wrapper">
                {gotStatements !== "Failed" ?
                    <>
                        {gotStatements !== "Loading" ?
                            <>
                                {filteredAccounts.length > 0 ?
                                    <>
                                        {filteredAccounts.map((statement: any, index: number) => {
                                            return (
                                                <div key={index} className="statement-row">
                                                    <div className="left-side">
                                                        <img className="document-image" src={blueDocument} alt="document" onClick={() => handleStatementDownload(statement.id)} />
                                                        <span className="row-text regular" onClick={() => handleStatementDownload(statement.id)}>Statement {statement.date} - PDF</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                    :
                                    <div className="message-wrapper">
                                        <img className="message-image" src={emptyStatements} alt="no statements" />
                                        <span className="header-text h4">No statements to display</span>
                                        {/* <span className="sub-text regular">No Statements to display here yet. Future Statements will appear here</span> */}
                                    </div>
                                }
                            </>
                            :
                            <div className="loading-box" />
                        }
                    </>
                    :
                    <div className="message-wrapper">
                        <img className="message-image" src={warning} alt="unable to load data" />
                        <span className="header-text h4">Unable to load data</span>
                        <span className="sub-text regular">Please wait a moment and try again.</span>
                    </div>
                }
            </div>
            {message !== "" && <ToastMessageDB type={type} message={message} />}
        </div>
    )
}

export default DesktopDocumentAccountStatements