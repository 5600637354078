import { useEffect, useRef, useState } from "react";
import { IAccountInformation } from "../../../api/getAccountInformation";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useWindowState } from "../../../Context/AccountContext/useWindowState";
import { updateAccountNickNameByIndex } from "../../../slices/accountInformation/accountInformationSlice";
import { updateAccountNickname } from "../../../slices/selectedAccountSlice/selectedAccountSlice";
import { getEventPath } from "../../../utils/getEventPath";
import { useToastMessage } from "../../../Context/ToastContext/useToastContext";
import { patchAccountNickName_API } from "../../../api/patchAccountNickName";

 /**
  * Used to hold States and logic for the EditAccountName component.
 */ 
export const useEditAccountName = (selectedAccount: IAccountInformation, setEditDescription: any) => {  
    const [nickname, setNickname] = useState<string>(selectedAccount.accountTitle)
    const dispatch = useAppDispatch()
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const prevSelectedAccount = useRef<IAccountInformation>(selectedAccount)
    const { windowState } = useWindowState()
    const {type, message, resetToast, setToastMessage} = useToastMessage()
    const submitClickedRef = useRef<boolean>(false)

    /**
     * Update the nickname with the user entered data
     */
    const onChangeHandler = (e: any) => {
        setNickname(e.target.value)
    }
    /**
     * Update the accountTitle to the nickname. 
     */
    const submit = async () => {
        if(!submitClickedRef.current){
            submitClickedRef.current = true
            document.body.style.overflow = "auto"
            if(nickname !== "" && nickname.trim().length){
                // const apiData = {accountNumber: selectedAccount.id, newNickName: nickname}
                try{
                    // await dispatch(patchAccountNickName(apiData))
                    await patchAccountNickName_API(selectedAccount.id, nickname)
                    dispatch(updateAccountNickname(nickname))
                    const index = accountInformation.findIndex((account: any) => account.id === selectedAccount.id)
                    const payload = {arrayIndex: index, newName: nickname}
                    dispatch(updateAccountNickNameByIndex(payload))
                    setToastMessage("SUCCESS", "Nickname updated")
                    //resetToast()
                    setEditDescription(false)
                }
                catch{
                    setToastMessage("ERROR", "Unable to save changes. Please try again later.")
                    resetToast()
                    if(windowState === "Mobile"){
                        setEditDescription(false)
                    }
                }
            }
            submitClickedRef.current = false
        }

    }

    // const resetEditDescription = () => {
    //     setTimeout(() => {
    //         setEditDescription(false)
    //     }, 4000)
    // }

    /**handles the onClick event for the reset button */
    const handleResetClick = async () => {
        document.body.style.overflow = "auto"
        // const apiData = {accountNumber: selectedAccount.id, newNickName: selectedAccount.description}
        try{
            // dispatch(patchAccountNickName(apiData))
            await patchAccountNickName_API(selectedAccount.id, selectedAccount.description)
            dispatch(updateAccountNickname(selectedAccount.description))
            const index = accountInformation.findIndex((account: any) => account.id === selectedAccount.id)
            const payload = {arrayIndex: index, newName: selectedAccount.description}
            dispatch(updateAccountNickNameByIndex(payload))
            setNickname(selectedAccount.description)
            setToastMessage("SUCCESS", "Nickname updated")
            // resetToast()
            setEditDescription(false)
        }
        catch{
            //throw new Error("Failed to reset account nickname to the account description name.")
            setToastMessage("ERROR", "Unable to save changes. Please try again later.")
            resetToast()
            if(windowState === "Mobile"){
                setEditDescription(false)
            }
        }
    }

    /**
     * Checks to see if the selectedAccount has changed during the update nickname process.
     * If it has the update nickname process will be canceled.
     */
    useEffect(() => {
        if(prevSelectedAccount.current.accountNumber !== selectedAccount.accountNumber){
            setEditDescription(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    /**Closes the pop up menu when a click is detected outside of the menu, only used with mobile flow */
    useEffect(() => {
        if(windowState === "Mobile"){
            const handleClickOutside = (event: any) => {
                const path = getEventPath(event)
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("nickname-menu-wrap")) === -1){
                    document.body.style.overflow = "auto"
                    setEditDescription(false)
                }
            }
    
            document.addEventListener("click", handleClickOutside, true)
            return () => document.removeEventListener('click', handleClickOutside, true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**If we are in mobile flow lock the scroll bar on render */
    useEffect(() => {
        if(windowState === "Mobile"){
            document.body.style.overflow = "hidden"
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return { onChangeHandler, nickname, submit, handleResetClick, type, message }
}; 

