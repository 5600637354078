import { useDesktopDocumentCenterHeader } from '../../../../../useHooks/componentHooks/useDesktopDocumentCenterHeader/useDesktopDocumentCenterHeader'
import './DesktopDocumentCenterHeader.scss'
import { IDesktopDocumentCenterProps } from './interfaces/IDesktopDocumentCenterHeaderProps'

/**This component displays the header for the desktop version of the Document Center */
const DesktopDocumentCenterHeader = (props: IDesktopDocumentCenterProps) => {
    const { selectedPage, setSelectedPage, availablePages } = props
    const { focusIndex, setFocusIndex } = useDesktopDocumentCenterHeader(selectedPage)

    return(
        <div className="desktop-document-center-header">
            <div className="display-pages">
                {availablePages.current.map((page:string, index: number) => {
                    return(
                        <div key={index} className={focusIndex === index ? "show-page active" : "show-page"} title={page} onClick={() => {setSelectedPage(page); setFocusIndex(index)}}>
                            <span>{page}</span>
                            {focusIndex === index && <div className="selected-border" />}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DesktopDocumentCenterHeader