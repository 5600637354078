import { ITaxDocuments, downloadTaxDocument } from '../../../api/getAccountInformation'
import './DesktopDocumentTaxDocuments.scss'
import { IDesktopDocumentTaxDocuments } from './interfaces/IDesktopDocumentTaxDocuments'
import blueDocument from '../../../assets/blueDocument.svg'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import emptyStatements from '../../../assets/emptyStatements.svg'
import warning from '../../../assets/warning.svg'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { downloadDocuments } from '../../../utils/downloadDocuments'
import { useRef } from 'react'

/**THe component renders the Tax Documents for the desktop version of the Documents Center */
const DesktopDocumentTaxDocuments = (props: IDesktopDocumentTaxDocuments) => {
    const { selectedAccount } = props
    const gotTaxDocuments = useAppSelector((state: RootState) => state.accountInformation.gotTaxDocuments)
    const { message, type, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

     /**handle the onClick event and downloads the account statement document */
     const handleTaxDocumentDownload = async (document: any ) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadTaxDocument(document.id)
                downloadDocuments(response.data, "", "Tax", document.date)
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()
            }
            downloadClickedRef.current = false
        }
    }

    return(
        <>
        <div className="desktop-tax-documents-wrapper">
            {gotTaxDocuments !== "Loading" ?
                <>
                {gotTaxDocuments === "Success" ?
                    <>
                    {selectedAccount.taxDocuments.length > 0 ?
                        <>
                        <div className="table-headers">
                            <p className="document semibold">Document Name</p>
                            <p className="date semibold">Date</p>
                        </div>
                        <div className="display-rows">
                            {selectedAccount.taxDocuments.map((document: ITaxDocuments, index: number) => {
                                return(
                                    <div key={index} className="display-row">
                                        <div className="left-side">
                                            <img className="document-image" src={blueDocument} alt="document" onClick={() => handleTaxDocumentDownload(document)}/>
                                            <span className="document regular" onClick={() => handleTaxDocumentDownload(document)}>{document.title}</span>
                                        </div>
                                        <div className="display-date regular">{document.date}</div>
                                    </div>
                                )
                            })}
                        </div>
                        </>
                        :
                        <div className="message-wrapper">
                            <img className="message-image" src={emptyStatements} alt="no tax documents" />
                            <span className="header-text h4">No tax documents</span>
                            <span className="sub-text regular">Future tax documents will be listed here</span>
                        </div>
                    }
                    </>
                    :
                    <div className="message-wrapper">
                        <img className="message-image" src={warning} alt="unable to load data" />
                        <span className="header-text h4">Unable to load data</span>
                        <span className="sub-text regular">Please wait a moment and try again.</span>
                    </div>
                }
                </>
                :
                <div className="loading-box" />
            }
            {message !== "" && <ToastMessageDB type={type} message={message} />}
        </div>
        </>
    )
}

export default DesktopDocumentTaxDocuments