import { useBeneficiaryEditProfile } from '../../../../useHooks/componentHooks/useBeneficiaryEditProfile/useBeneficiaryEditProfile'
import DesktopEditCharity from './components/DesktopEditCharity/DesktopEditCharity'
import DesktopEditPerson from './components/DesktopEditPerson/DesktopEditPerson'
import DesktopEditTrust from './components/DesktopEditTrust/DesktopEditTrust'
import './DesktopBeneficiaryEditProfile.scss'
import { IDesktopBeneficiaryEditProfileProps } from './interfaces/IDesktopBeneficiaryEditProfileProps'

/**
 * This component renders the edit beneficiary profile page for the desktop version.  
 * That is the name, address, and so on.
 * @param props setEditProfile: boolean state to close the edit profile page, 
 *              setEditBeneficiary: boolean state for the edit allocation page
 */
const DesktopBeneficiaryEditProfile = (props: IDesktopBeneficiaryEditProfileProps) => {
    const { setEditProfile, setEditBeneficiary, selectedBeneficiary, setDeleteBeneficiary, form, dispatchForm } = props
    const { editPerson, setEditPerson } = useBeneficiaryEditProfile()

    return (
        <div>
            {selectedBeneficiary.type === "Person" &&
                <DesktopEditPerson 
                    editPerson={editPerson}
                    setEditPerson={setEditPerson}
                    setEditProfile={setEditProfile}
                    setEditBeneficiary={setEditBeneficiary}
                    selectedBeneficiary={selectedBeneficiary}
                    setDeleteBeneficiary={setDeleteBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}
                />
            }
            {(selectedBeneficiary.type === "Charity" || selectedBeneficiary.type === "Non Profit") &&
                <DesktopEditCharity 
                    editPerson={editPerson}
                    setEditPerson={setEditPerson}
                    setEditProfile={setEditProfile}
                    setEditBeneficiary={setEditBeneficiary}
                    selectedBeneficiary={selectedBeneficiary}
                    setDeleteBeneficiary={setDeleteBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}
                />
            }
            {selectedBeneficiary.type === "Trust" &&
                <DesktopEditTrust 
                    editPerson={editPerson}
                    setEditPerson={setEditPerson}
                    setEditProfile={setEditProfile}
                    setEditBeneficiary={setEditBeneficiary}
                    selectedBeneficiary={selectedBeneficiary}
                    setDeleteBeneficiary={setDeleteBeneficiary}
                    form={form}
                    dispatchForm={dispatchForm}
                />
            }
        </div>
    )
}

export default DesktopBeneficiaryEditProfile