import { useRef, useState } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { postFactorSelection_API } from "../../../api/User/otpServices"
import { IFactor } from "../useOTPModal/useOTPModal"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"

/**A custom component for the Verify Identity component found in the OTP modal stepper */
export const useVerifyIdentity = (setStep: React.Dispatch<React.SetStateAction<number>>) => {
    const { windowState } = useWindowState()
    const postFactorClicked = useRef<boolean>(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const { setToastMessage, resetToast, message, type } = useToastMessage()

    /**Handle selection click */
    const handleVerifySelection = async (factor: IFactor) => {
        if(!postFactorClicked.current){
            postFactorClicked.current = true
            setShowLoader(true)
            try{
                await postFactorSelection_API(factor.id)
                setStep(2)
            }
            catch{
                console.log("Failed to post the factor selection")
                setToastMessage("ERROR", "Please try again later")
                resetToast()
            }
            postFactorClicked.current = false
            setShowLoader(false)
        }
    }

    return { handleVerifySelection, windowState, postFactorClicked, showLoader, message, type }
}