import { useReviewTerms } from '../../../../useHooks/componentHooks/useReviewTerms/useReviewTerms'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import { displayCurrency } from '../../../../utils/displayCurrency'
import Checkbox from '../../../Checkbox/Checkbox'
import './ReviewTerms.scss'
import { IReviewTermsProps } from './interfaces/IReviewTermsProps'
import greenCheck from '../../../../assets/greenCheck.svg'
import { Button, TIMER_IDS } from '../../../Button/Button'

/**Renders step 2 of the consent required modal, which is Review terms & conditions */
const ReviewTerms = (props: IReviewTermsProps) => {
    const { setConsentModalStep, consentRequiredAccounts } = props
    const { selectedConsent, startReview, handleStartReview, showCheckmark, setShowCheckmark, windowState, handleContinue, consentAccounts,
        indexUnderReview } = useReviewTerms(consentRequiredAccounts, setConsentModalStep)

    return (
        <div className="review-terms-wrap">
            <span className="header h3">Review terms & conditions</span>
            <span className={windowState !== "Mobile" ? "sub-header regular" : "sub-header small"}>To continue to your accounts, please review and accept the deposit agreement.</span>
            <div className="consents-wrap">
                {consentAccounts.map((account: any, index: number) => {
                    return (
                        <div key={index} className={windowState !== "Mobile" ? "account-stack" : "account-stack mobile"}>
                            <div className="row-wrapper" onClick={() => handleStartReview(account, index)}>
                                {windowState !== "Mobile" ?
                                    <span className="title semiboldRegular">{account.term} <span className="account regular">- {account.accountType !== "Savings" ? "CD" : "Savings"} {account.lastFour}</span></span>
                                    :
                                    <div className="title-stack">
                                        <span className="title mobile semiboldRegular">{account.term}</span>
                                        <span className="account x-small">{account.accountType !== "Savings" ? "CD" : "Savings"} {account.lastFour}</span>
                                    </div>
                                }
                                {account.consentCompleted ?
                                    <div className="completed-wrap">
                                        <img src={greenCheck} alt="green checkmark" />
                                        <span className="green-text semiboldRegular">Completed</span>
                                    </div>
                                    :
                                    <>
                                        {account.id !== selectedConsent.id ?
                                            <span className={(startReview && index !== indexUnderReview) ? "blue-text semiboldRegular disabled" : "blue-text semiboldRegular"}>Start review</span>
                                            :
                                            <span className="reviewing-text semiboldRegular">Reviewing ...</span>
                                        }
                                    </>
                                }

                            </div>
                            {(account.id === selectedConsent.id && !account.consentCompleted && (indexUnderReview === index)) &&
                                <>
                                    <div className={windowState !== "Mobile" ? "consent-details" : "consent-details mobile"}>
                                        <div className="data-stack">
                                            <div className="label-value-wrap">
                                                <span className="label boldSmall">Term</span>
                                                <span className="value regular">{account.term}</span>
                                            </div>
                                            <div className="label-value-wrap">
                                                <span className="label boldSmall">New APY</span>
                                                <span className="value regular">{account.newApy.toFixed(2)}%</span>
                                            </div>
                                        </div>
                                        <div className="data-stack">
                                            <div className="label-value-wrap">
                                                <span className="label boldSmall">Current balance</span>
                                                <span className="value regular">{displayCurrency(+account.currentBalance)}</span>
                                            </div>
                                            <div className="label-value-wrap">
                                                <span className="label boldSmall">Renewed on</span>
                                                <span className="value regular">{convertDateTommddyyyy(account.renewedOn)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="consent-checkbox-wrap">
                                        <Checkbox
                                            showCheckmark={showCheckmark}
                                            setShowCheckmark={setShowCheckmark}
                                        />
                                        <span className="agree-text regular">I have reviewed and agree to the&nbsp;
                                            <a className="link-text" target="_blank" rel="noopener noreferrer" href="https://www.creditonebank.com/content/dam/creditonebank/corporate-transactional/pdf/Deposit_Agreement_Disclosures.pdf">Deposit Agreement (PDF)</a>
                                        </span>
                                    </div>
                                    <div className="button-wrap">
                                        {windowState !== "Mobile" ?
                                            <Button 
                                                title='Continue'
                                                isDisabled={!showCheckmark}
                                                className={showCheckmark ? "Button btn-primary" : "Button btn-primary inactive"}
                                                id={TIMER_IDS.TERMS_AND_CONDITIONS}
                                                onClick={handleContinue}
                                            />
                                            :
                                            <Button 
                                                title='Continue'
                                                isDisabled={!showCheckmark}
                                                className={showCheckmark ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"}
                                                id={TIMER_IDS.TERMS_AND_CONDITIONS}
                                                onClick={handleContinue}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ReviewTerms