/**Services related to updating bump rate */

import axios from "axios"
import { bffBaseUrl, token } from "../utils/globalVariables"

/**A call up update whether the bump rate banner should be displayed */
export const removeBumpBanner_API = async (accountId: string) => {
    return axios.patch(`${bffBaseUrl}/accounts/${accountId}/bumpupbanner`,
        {
            isBannerAvailable: false
        },
        {
            headers: {
                Authorization: `Bearer ` + token
            }    
        }
    )
}

/**A patch call to bumpuup a CD account */
export const bumpUpCD_API = async (accountId: string) => {
    return axios.patch(`${bffBaseUrl}/accounts/${accountId}/bumpupenable`,
        {
            bumpup: true
        },
        {
            headers: {
                Authorization: `Bearer ` + token
            }    
        }
    )
}