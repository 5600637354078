import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'

import blueExport from '../../../assets/blueExport.svg'
import './DesktopTransactionHistory.scss'
import { TransactionTable } from '../../../components/TransactionTable/TransactionTable'
import DesktopAllFilters from '../components/DesktopAllFilters/DesktopAllFilters'
import { PaginationComponent } from '../../../components/PaginationComponent/PaginationComponent'
import mobileTransactionFilter from '../../../assets/mobileTransactionFilter.svg'
import greyCancelX from '../../../assets/greyCancelX.svg'
import advancedFiltersBlue from '../../../assets/advancedFiltersBlue.svg'
import { ITransactionHistoryProps } from './interfaces/ITransactionHistoryProps'
import emptyTransactions from '../../../assets/emptyTransactions.svg'
import { transactionTypes, useTransactionHistoryContext } from '../context/TransactionHistoryContext'
import { TransactionTypeDropdown } from '../Desktop/TransactionTypeDropdown/TransactionTypeDropdown'
import { DatePeriodDropdown } from '../Desktop/DatePeriodDropdown/DatePeriodDropdown'
import warning from "../../../assets/warning.svg"
import CurrentBalanceHeader from '../../../shared/digitalbank/CurrentBalanceHeader/CurrentBalanceHeader'
import { useRef } from 'react'

/**Renders the desktop version of the transaction history page */
const DesktopTransactionHistory = (props: ITransactionHistoryProps) => {
    const { fromSavings } = props
    const {
        selectedTransaction, displayDetails, setDisplayDetails,
        startIndex, setStartIndex, endIndex, setEndIndex, filteredTransactions, setFilteredTransactions,
        handleTransactionColumnSort, dateSort, amountSort, numberOfItemsToShow, setNumberOfItemsToShow,
        showCustomFilter, transactionsLoaded, selectedAccount, transactions, handleExportToCSV, setIsAllFiltersSelected, isAllFiltersSelected, handleRemoveFilter, customFilterArray } = useTransactionHistoryContext()

    // const onTransactionsRef = useRef<boolean>(false)
    // const adjustedUrl = window.location.pathname.split('/')[3]

    // if(adjustedUrl && adjustedUrl === "transactions"){
    //     onTransactionsRef.current = true
    // }
    // else{
    //     onTransactionsRef.current = false
    // }
    // console.log("ADJUSTED URL IS :", adjustedUrl)

    return (
        <div className="Landing-Wrapper">
            {!fromSavings &&
                <div className="card-wrapper account-header">
                    <AccountHeader returnMessage="Account Details" setDisplayDetails={false} page={'transaction-history'} isOnTransactions={true} />
                </div>
            }

            <div className={fromSavings ? "transaction-page-wrap savings" : "transaction-page-wrap"}>
                <div className={selectedAccount.isTransactionsLoaded ? "transactions-card-wrap" : "transactions-card-wrap empty"}>
                    {(transactions && transactions.length > 0 && selectedAccount.isTransactionsLoaded) &&
                        <>
                            {!fromSavings ?
                                <span className="table-header h4">Transactions</span>
                                :
                                <div className="header-row-wrap">
                                    <span className="table-header h4">Transactions</span>
                                    {transactionsLoaded === "Success" &&
                                        <div className="right-side" onClick={() => handleExportToCSV()}>
                                            <span className="export-text regular" >Export to CSV</span>
                                            <img className="export-symbol" src={blueExport} alt="export" />
                                        </div>
                                    }
                                </div>
                            }
                            {transactionsLoaded === "Success" &&
                                <div className="filters-row">
                                    <div className="left-side">
                                        <DatePeriodDropdown />
                                        <TransactionTypeDropdown />
                                    </div>
                                    {customFilterArray.current.length ?
                                        <div className="advanced-filter-wrap selected" onClick={() => setIsAllFiltersSelected(true)}>
                                            <img src={advancedFiltersBlue} alt="advanced filters" />
                                            <span className="advanced-filters-text selected semiboldRegular">{customFilterArray.current.length ? customFilterArray.current.length : ""} Advanced filters</span>
                                        </div>
                                        :
                                        <div className="advanced-filter-wrap" onClick={() => setIsAllFiltersSelected(true)}>
                                            <img src={mobileTransactionFilter} alt="advanced filters" />
                                            <span className="advanced-filters-text regular">{customFilterArray.current.length ? customFilterArray.current.length : ""} Advanced filters</span>
                                        </div>
                                    }
                                </div>
                            }
                            <div className="advanced-filters-row">
                                {(showCustomFilter && customFilterArray.current.length > 0) &&
                                    <div className="selected-filters-row">
                                        <span className="filtered-by-text regular">Your transactions for:</span>
                                        {customFilterArray.current.map((filter: any, index: number) => {
                                            return (
                                                <div key={index} className="filter-wrap">
                                                    <span className="filter-text small">{filter.filter}</span>
                                                    <img className="cancel-image" src={greyCancelX} alt="cancel" onClick={() => handleRemoveFilter(filter.filter, filter.filterType, index)} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {isAllFiltersSelected &&
                        <DesktopAllFilters />
                    }
                    <div className={filteredTransactions.length > 0 ? 'transaction-table-wrap' : 'transaction-table-wrap empty'}>
                        {transactionsLoaded !== "Failed" &&
                            <>
                                {filteredTransactions.length > 0 ?
                                    <TransactionTable
                                        transactions={filteredTransactions}
                                        startIndex={startIndex}
                                        endIndex={endIndex}
                                        selectedTransaction={selectedTransaction}
                                        displayDetails={displayDetails}
                                        setDisplayDetails={setDisplayDetails}
                                        isSortable={true}
                                        setFilteredTransactions={setFilteredTransactions}
                                        handleTransactionColumnSort={handleTransactionColumnSort}
                                        dateSort={dateSort}
                                        amountSort={amountSort}
                                    />
                                    :
                                    <>
                                    {selectedAccount.isTransactionsLoaded && transactionsLoaded === "Success" &&
                                        <div className="empty-state-wrap">
                                            <img className="empty-image" src={emptyTransactions} alt="empty transactions state" />
                                            <span className="text h4">No transactions to display</span>
                                            <span className="text regular">No transactions to display here yet. Future transactions will appear here</span>
                                        </div>
                                    }
                                    </>
                                }
                            </>                        
                        }
                        {transactionsLoaded === "Failed" && 
                            <div className="Transactions-History-API-Error">
                                <div className="API-Error-Message-Container">
                                    <img className="Image" src={warning} alt="Error Message" />
                                    <span className="Top-Text bold-x-large">Unable to load data</span>
                                    <span className="Bottom-Text regular">Unable to load data. Please wait a moment and try again.</span>
                                </div>
                            </div> 
                        }
                    </div>
                    {transactionsLoaded === "Success" && filteredTransactions.length > 0 &&
                        <div className={filteredTransactions?.length > 10 ? "bottom-row-wrap" : "bottom-row-wrap no-pagination"}>
                            {filteredTransactions?.length > 10 &&
                                <div className="pagination-wrapper">
                                    <PaginationComponent
                                        type="Desktop"
                                        itemType=""
                                        totalItems={filteredTransactions.length}
                                        setStartIndex={setStartIndex}
                                        setEndIndex={setEndIndex}
                                        numberOfItemsToShow={numberOfItemsToShow}
                                        setNumberOfItemsToShow={setNumberOfItemsToShow}
                                    />
                                </div>
                            }
                            {!fromSavings ?
                                <div className="right-side" >
                                    <span className="export-text regular" onClick={() => handleExportToCSV()}>Download to CSV</span>
                                    <img className="export-symbol" src={blueExport} alt="export" onClick={() => handleExportToCSV()} />
                                </div>
                                :
                                <div />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DesktopTransactionHistory;