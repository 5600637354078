import { RootState } from "../../../app/store";
import { useAppSelector } from "../../../app/hooks";
import { useWindowState } from "../../../Context/AccountContext/useWindowState";
import { useState, useEffect, useRef } from "react";

 /**
 * Gets and returns the selected account information which is the basic account information, and 
 * the account details of the selected account.
 */ 
export const useOverviewComponent = () => {  
    const { windowState } = useWindowState()
    const onTransactionDetails = useAppSelector((state: RootState) => state.transactions.onTransactionDetails)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const prevSelectedAccount = useRef<any>(useAppSelector((state: RootState) => state.selectedAccount.selectedAccount))
    const [showDropDown, setShowDropDown] = useState<boolean>(false)
    const [initialClick, setInitialClick] = useState<boolean | undefined>(undefined)
    const [show, setShow] = useState<boolean>(false)

    /**CLoses the drop down menu when the selected account is changed */
    useEffect(() => {
        if(prevSelectedAccount.current.id !== selectedAccount.id && windowState !== "Mobile"){
            setShowDropDown(false)
            setShow(false)
            prevSelectedAccount.current = selectedAccount
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])


    return { selectedAccount, onTransactionDetails, windowState, showDropDown, setShowDropDown, initialClick, setInitialClick, show, setShow }
}; 
