import { useTransferHistory } from '../../../../../useHooks/componentHooks/useTransferHistory/useTransferHistory'
import { ITransferHistoryProps } from '../interfaces/ITransferHistoryProps'
import './DesktopTransferHistory.scss'
import downSortBlue from '../../../../../assets/downSortBlue.svg'
import sort from '../../../../../assets/sort.svg'
import upSortBlue from '../../../../../assets/upSortBlue.svg'
import { ITransferHistory } from '../../../../../api/transfers'
import arrowRight from '../../../../../assets/arrowRight.svg'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import { convertDateTommddyyyy } from '../../../../../utils/DateUtils'
import { PaginationComponent } from '../../../../../components/PaginationComponent/PaginationComponent'
import rightArrow from '../../../../../assets/rightArrow.svg'
import emptyTransfers from '../../../../../assets/emptyTransfers.svg'
import TransferDetailsPanel from '../../TransferDetailsPanel/TransferDetailsPanel'
// import redTrashCan from '../../../../../assets/redTrashCan.svg'
import redTrashCan from '../../../../../assets/Delete.png'
import CancelTransferModal from '../../CancelTransferModal/CancelTransferModal'
// import { useWindowState } from '../../../../../Context/AccountContext/useWindowState'

/**Renders the desktop version of the Transfer History table found in the Transfers tab */
const DesktopTransferHistory = (props: ITransferHistoryProps) => {
    const { transferHistory, setTransferHistory, selectedTransfer, setSelectedTransfer, title, totalTransfers, handleClose, selectedTransferRef, transferErrored, setTransferErrored } = props
    const { startIndex, setStartIndex, endIndex, setEndIndex, numberOfItemsToShow, setNumberOfItemsToShow, sortTransferHistory, dateSort, amountSort,
        openModal, setOpenModal, openTransferDetails, setOpenTransferDetails, handleCloseModal } = useTransferHistory(transferHistory, setTransferHistory, totalTransfers, selectedTransfer)
    
        return (
        <div className="transfer-history-card-wrap">
            <span className="title-text h2">{title}</span>
            {transferHistory.length > 0 ?
                <div className="table-wrap">
                    <div className="headers-wrap">
                        <div className="date-header">
                            <span className="header-text boldSmall">Date</span>
                            {(dateSort === "Default") && <img className="sort-icon" src={sort} alt="Default Sort" onClick={() => { sortTransferHistory("Date", "Down") }} />}
                            {(dateSort === "Up") && <img className="sort-icon" src={downSortBlue} alt="Descending Sort" onClick={() => { sortTransferHistory("Date", "Down") }} />}
                            {(dateSort === "Down") && <img className="sort-icon" src={upSortBlue} alt="Ascending Sort" onClick={() => { sortTransferHistory("Date", "Up") }} />}
                        </div>
                        <div className="from-header">
                            <span className="header-text boldSmall">From</span>
                        </div>
                        <div className="to-header">
                            <span className="header-text boldSmall">To</span>
                        </div>
                        <div className="amount-header">
                            {(amountSort === "Default") && <img className="sort-icon" src={sort} alt="Default Sort" onClick={() => { sortTransferHistory("Amount", "Down") }} />}
                            {(amountSort === "Up") && <img className="sort-icon" src={downSortBlue} alt="Descending Sort" onClick={() => { sortTransferHistory("Amount", "Down") }} />}
                            {(amountSort === "Down") && <img className="sort-icon" src={upSortBlue} alt="Ascending Sort" onClick={() => { sortTransferHistory("Amount", "Up") }} />}
                            <span className="header-text boldSmall">Amount</span>
                        </div>
                    </div>
                    {title !== "Scheduled transfers" ?
                        <>
                            {transferHistory.slice(startIndex, endIndex).map((transfer: ITransferHistory, index: number) => {
                                return (
                                    <div key={index} className={selectedTransfer.id === transfer.id ? "transfer-row-wrap green" : "transfer-row-wrap"} onClick={() => { setSelectedTransfer(transfer); setOpenTransferDetails(true) }}>
                                        <TransferMap
                                            transfer={transfer}
                                            setOpenModal={setOpenModal}
                                            setSelectedTransfer={setSelectedTransfer}
                                        />
                                    </div>
                                )
                            })}
                        </>
                        :
                        <>
                            {transferHistory.map((transfer: ITransferHistory, index: number) => {
                                return (
                                    <div
                                        data-testid={`transfer row ${index}`}
                                        key={index}
                                        className="transfer-row-wrap"
                                        onClick={() => { setSelectedTransfer(transfer); setOpenTransferDetails(true) }}>
                                        <TransferMap
                                            transfer={transfer}
                                            setOpenModal={setOpenModal}
                                            setSelectedTransfer={setSelectedTransfer}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    }
                    {totalTransfers > 10 && title !== "Scheduled transfers" &&
                        <div className="pagination-wrapper">
                            <PaginationComponent
                                type="Desktop"
                                itemType=""
                                totalItems={totalTransfers}
                                setStartIndex={setStartIndex}
                                setEndIndex={setEndIndex}
                                numberOfItemsToShow={numberOfItemsToShow}
                                setNumberOfItemsToShow={setNumberOfItemsToShow}
                            />
                        </div>
                    }
                    {openModal &&
                        <CancelTransferModal
                            transferHistory={transferHistory}
                            setTransferHistory={setTransferHistory}
                            selectedTransfer={selectedTransfer}
                            screen="Desktop"
                            setOpenModal={setOpenModal}
                            transferErrored={transferErrored}
                            setTransferErrored={setTransferErrored}
                            handleCloseModal={handleCloseModal}
                            handleClose={handleClose}
                        />
                    }
                </div>
                :
                <div className="empty-state-wrap">
                    <div className="bottom-border-line" />
                    <img src={emptyTransfers} alt="no transfers" />
                    <div className="text-wrap">
                        <span className="text h4">No transfers yet</span>
                        <span className="text regular">Once you make a transfer, details will be shown here.</span>
                    </div>
                </div>
            }
            {openTransferDetails &&
                <TransferDetailsPanel
                    selectedTransfer={selectedTransfer}
                    setSelectedTransfer={setSelectedTransfer}
                    setOpenTransferDetails={setOpenTransferDetails}
                    setOpenModal={setOpenModal}
                />
            }
        </div>
    )
}

export default DesktopTransferHistory

const TransferMap = (props: any) => {

    const { transfer, setOpenModal, setSelectedTransfer } = props;
    return <><span className="date-header regular">{convertDateTommddyyyy(new Date(transfer.transferDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
        <span className="from-header regular">{transfer.transferFromAccount} {transfer.originLastFour}</span>
        <div className="to-header">
            <img src={rightArrow} alt="right arrow" />
            <span className="to-text regular">{transfer.transferDesignationAccount} {transfer.designationLastFour}</span>
        </div>
        <div className="amount-header regular value">
            <span className="current-column">{displayCurrency(+transfer.amount)}</span>
            {(transfer.status === "Scheduled" && !transfer.isInitialFund && transfer.isCancelAllowed) && <img src={redTrashCan} alt="red trash can" onClick={(e: any) => { setOpenModal(true); setSelectedTransfer(transfer); e.stopPropagation() }} />}
            <img src={arrowRight} alt="right facing chevron" />
        </div>
    </>
}