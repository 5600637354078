import { useEffect } from 'react'
import './EligibilityModal.scss'
import { IEligibilityModalProps } from './interfaces/IEligibilityModalProps'
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'

/**
 * Renders the eligibility modal under two criteria during the add co-owner flow. It will show that the new 
 * applicant is not eligible to be added.
 * If the applicant is not a US citizen or resident
 * If the applicant has connections to foreign government leaders
 * */
const EligibilityModal = (props: IEligibilityModalProps) => {
    const { errorMessage, setAddOwner, setFormStep, form, dispatchForm } = props
    const { windowState } = useWindowState()

    /**Locks the scroll bar on mount, unlocks the scroll bar on unmount */
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {document.body.style.overflow = "auto"}
    }, [])

    /**Handles the onClick event for the 'Close' button */
    const handleClose = () => {
        form.resetForm()
        dispatchForm()    
        setAddOwner(false)
        setFormStep(1)
    }

    return (
        <div className="eligibiilty-modal-overlay">
            <div className={windowState !== "Mobile" ? "modal-content" : "modal-content mobile"}>
                <img className="image" src={redExclamationCircle} alt="exclamation mark in red circle" />
                <span className="title-text h4">You may not proceed with your application</span>
                <span className="body-text regular">{errorMessage}</span>
                <button className="Button btn-primary" onClick={() => handleClose()}>Close</button>
            </div>
        </div>
    )
}

export default EligibilityModal