import axios, { AxiosPromise } from "axios"
import { bffBaseUrl, token } from "../utils/globalVariables"
import { UpdateAccountNickNameResponse } from './models'

/**An API call to update the nickname for a particular account */
export const patchAccountNickName_API = (accountNumber: string, newNickName: string): AxiosPromise<UpdateAccountNickNameResponse> => {
    return axios.patch(`${bffBaseUrl}/accounts/${accountNumber}`,
        {
            accountNickName: newNickName,
        },
        {
            headers: {
                Authorization: `Bearer ` + token,
                'Content-Type': 'application/json'
            }
        }
    )
}