import { useUnsavedChanges } from '../../../../../../useHooks/componentHooks/useUnsavedChanges/useUnsavedChanges'
import './DesktopUnsavedChanges.scss'
import { IDesktopUnsavedChangesProps } from './interfaces/IDesktopUnsavedChangesProps'
import warningCircle from '../../../../../../assets/warningCircle.svg'
import { useEffect } from 'react'


/**
 * This component renders the Unsaved Changes Modal Desktop Version.  The component appears
 * when a user attempts to exit out of the beneficiary allocation screen before saving the changes.
 */
const DesktopUnsavedChanges = (props: IDesktopUnsavedChangesProps) => {
    const { unsavedChanges, setUnsavedChanges, setEditBeneficiary, onReset } = props
    const { handleContinue, handleCancel } = useUnsavedChanges(unsavedChanges);


    return (
        <div className="desktop-unsaved-changes-wrapper">
            <div className="modal-wrapper">
                <img className="warning-icon" src={warningCircle} alt="Warning Icon" />
                <span className="top-text bold-x-large" >Unsaved Changes</span>
                <span className="bottom-text regular" >You have unsaved changes that will be lost if you decide to continue. Are you sure you want to continue?</span>
                <div className="button-wrapper">
                    <button className="button-one boldButtonLarge" onClick={() => { setUnsavedChanges(false); handleCancel() }}>Cancel</button>
                    <button className="button-two boldButtonLarge" onClick={(e) => { setUnsavedChanges(false); setEditBeneficiary(false); onReset((e)); handleContinue(e); }} >Continue</button>
                </div>
            </div>
        </div>
    )
}

export default DesktopUnsavedChanges