
// this function needs the date to come in as month day year. 

const appendZeroToDay = (date: string, splitBy: string | '-' | '/') => {
    let newDate = date
    let [Month, Day, Year] = newDate.split(splitBy)
    if(parseInt(Day) < 10 && !Day.includes('0')){
      Day = '0' + Day
    }
    const finalDate = Month + splitBy + Day + splitBy + Year
    return finalDate
}

export default appendZeroToDay