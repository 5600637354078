import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { updateUserGreeting } from "../../../slices/userInformation/userInformationSlice"
import { getEventPath } from "../../../utils/getEventPath"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { patchUserNickname_API } from "../../../api/User/userNickname"
import { useHistory } from "react-router-dom"

/**A custom usehook for the mobile profile landing page ie: the page displayed when click on profile from the mobile nav bar */
export const useMobileProfileLanding = () => {
    const history = useHistory()
    const { windowState } = useWindowState()
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const [newNickname, setNewNickname] = useState<string>(userInformation.nickname)
    const [editNickname, setEditNickname] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const { setToastMessage, resetToast } = useToastMessage()
    const updateClickedRef = useRef<boolean>(false)

    /**On change handler to update the nickname */
    const onChangeNickname = (e: any) => {
        setNewNickname(e.target.value)
    }

    /**Call to update the nick name on the submit button click*/
    const handleUpdateNickname = async () => {
        const tempNickname = newNickname.trim()
        if(tempNickname.length > 0 && !updateClickedRef.current){
            updateClickedRef.current = true
            try{
                await patchUserNickname_API(tempNickname)
                dispatch(updateUserGreeting(tempNickname))
                setEditNickname(false)
                setToastMessage("SUCCESS", "Updated nickname")
                resetToast()
            }
            catch{
                console.log("UNABLE TO UPDATE THE USER NICKNAME")
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
            }
            updateClickedRef.current = false
        }
    }

    /**Handles the onClick event for the cancel button press */
    const handleCancelOnClick = () => {
        setNewNickname(userInformation.nickname)
        setEditNickname(false)
    }

    /**Close the edit menu box if  */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if(windowState === "Mobile"){
                const path = getEventPath(event)
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("edit-box")) === -1){
                    setEditNickname(false)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**gets the selected tab and pushes the correct url to history */
    const pushSelectedTab = (tab: "Contact Information" | "Security Settings" | "Tax Withholdings" | "Manage External Accounts" | "Notification Settings") => {
        switch(tab){
            case "Contact Information": {
                history.push('/user/profile/contact')
                break
            }
            case "Security Settings": {
                history.push('/user/profile/security')
                break
            }
            case "Tax Withholdings": {
                history.push('/user/profile/tax')
                break
            }
            case "Manage External Accounts": {
                history.push('/user/profile/external-accounts')
                break
            }
            case "Notification Settings": {
                history.push('/user/profile/notifications')
            }
        }
    }
    
    return { newNickname, handleUpdateNickname, userInformation, onChangeNickname, editNickname, setEditNickname, handleCancelOnClick, pushSelectedTab }
}