import { IRadioButtonProps } from './interfaces/IRadioButtonProps';
import './RadioButton.scss';
/**
 * Radio button
 * @param label label for radio button
 * @param value the value of the button
 * @param name the name of the group the button belongs to
 * @param checked (optional) whether or not it should be checked
 * @param onChange (optional) an on change event
 * @param className (optional) className to be added to radio button
 * @param inputRef (optional) ref to pass into input
 */
export const RadioButton = (props: IRadioButtonProps) => {
  const {
    value,
    name,
    className,
    inputRef,
    onChange,
    checked,
    type,
    disabled,
    children,
    onClick,
    textAlign = "Right"
  } = props;

  return (
    <div
      className={
        type !== 'Mobile'
          ? checked
            ? `radio-button radio-active ${className}`
            : `radio-button ${className}`
          : checked
          ? `mobile-radio radio-button radio-active ${className}`
          : `mobile-radio radio-button ${className}`
      }
    >
      <label className="label regular" onClick={(e: any) => {if(onClick){onClick(e)}}}>
        {textAlign === "Left" &&
          <span className={type !== 'Mobile' ? 'label-text' : 'label-text mobile'}>
            {children}
          </span>      
        }
        <input
          type="radio"
          value={value}
          name={name}
          className={disabled ? 'radio-circle disabled' : 'radio-circle'}
          ref={inputRef}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        {textAlign === "Right" &&
          <span className={type !== 'Mobile' ? 'label-text' : 'label-text mobile'}>
            {children}
          </span>
        }
      </label>
    </div>
  );
};
