import { IControl } from "../Form/Control/IControl.interface";
import { IFormContext, TControlDispatch } from "../useForm/useForm";

/**A custom usehook for the DateInput component */
export const useDateInput = (form: IFormContext, control: IControl, dispatchControlChanges: TControlDispatch) => {

    /**Handles the onChange event for the Date Input component */
    const onChangeHandle = (e: any) => {
        form.setValue(control.name, e.target.value)
        form.runValidations(control.name)
        dispatchControlChanges()
    }

    /**Handles the onBlur event of the Date Input */
    const handleOnBlur = (e: any) => {
        form.setValue(control.name, e.target.value)
        form.runValidations(control.name)
        dispatchControlChanges()
    }

    /**takes the control validation value and creates the correct max date format to be used with the date input */
    const getMaxDate = () => {
        for(let i = 0; i < control.validations.length; i = i + 1){
            if(control.validations[i].name === "MAX_DATE"){
                let [ month, day, year ] = control.validations[i].value.split("/")
                if(month.length < 2){
                    month = `0${month}`
                }
                if(day.length < 2){
                    day = `0${day}`
                }
                year = year.substring(0,4)
                return `${year}-${month}-${day}`
            }
        }
    }

    /**takes the control validation value and creates the correct max date format to be used with the date input */
    const getMinDate = () => {
        for(let i = 0; i < control.validations.length; i = i + 1){
            if(control.validations[i].name === "MIN_DATE"){
                let [ month, day, year ] = control.validations[i].value.split("/")
                if(month.length < 2){
                    month = `0${month}`
                }
                if(day.length < 2){
                    day = `0${day}`
                }
                year = year.substring(0,4)
                return `${year}-${month}-${day}`
            }
        }
    }    

    return { handleOnBlur, onChangeHandle, getMaxDate, getMinDate }
}