import './EnterBankDetails.scss'
import information from '../../../../assets/informationIconLight.svg'
import { RadioButton } from '../../../../components/RadioButton/RadioButton'
import sampleCheck from '../../../../assets/sampleCheck.svg'
import { useEnterBankDetails } from '../../../../useHooks/componentHooks/useEnterBankDetails/useEnterBankDetails'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import { useLinkExternalAccount } from '../../Context/useLinkExternalAccount'
import { useHistory } from 'react-router-dom'
import MobileAlertBox from '../../../../components/MobileAlertBox/MobileAlertBox'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'
import greenCheckmark from '../../../../assets/greenCheckmark.svg'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'

/**Renders the enter bank details component which is the first step toward linking a new external account */
const EnterBankDetails = () => {
    const history = useHistory()
    const { windowState, showTooltipOne, setShowTooltipOne, showTooltipTwo, setShowTooltipTwo, handleRoutingNumber, handleAccountNumber, handleTestAccountNumber, 
            handleAccountNickname, handleRoutingKeyDown, routingError, handleRoutingOnBlur, handleAccountOnBlur, accountNumberError, handleAccountKeyDown, handleTestAccountOnBlur, 
            handleContinue, showDuplicateError, type, message, lastFourRef, accountNameRef, showFraud, handleRoutingPaste } = useEnterBankDetails()
    const { accountType, setAccountType, routingNumber, accountNumber, testAccountNumber, nickname, showVerified, enableButton, blockedRoutingError } = useLinkExternalAccount()

    return (
        <div className="enter-bank-details-wrap">
            {!showVerified ?
                <>
                {message !== "" && <ToastMessageDB type={type} message={message} />}
                <span className={windowState !== "Mobile" ? "title-text regular" : "title-text small"}>Link external account</span>
                <span className={windowState !== "Mobile" ? "header-text h3" : "header-text mobile h4"}>Enter bank account details</span>
                <span className="standard-text regular">Enter your account information in the fields below. Fields marked with an&nbsp;<span className="red-text">*</span>&nbsp;are required.</span>
                {showDuplicateError &&
                    <div className="duplicate-error-wrapper">
                        <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                        <span className="error-text regular">This account has already been linked. Please use a different account to continue.</span>
                    </div>            
                }
                {showFraud &&
                    <div className="duplicate-error-wrapper">
                        <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                        <span className="error-text regular">The bank account is invalid. Please use a different account to continue.</span>
                    </div>            
                }
                {blockedRoutingError &&
                    <div className="duplicate-error-wrapper">
                        <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                        <span className="error-text regular">That financial institution is currently not supported. Please use a different account.</span>
                    </div>            
                }
                <div className="account-wrapper">
                    <div className="account-label-row">
                        <span className="label-text small">Account type&nbsp;<span className="red-text">*</span></span>
                        {windowState !== "Mobile" ?
                            <img data-testid="account-tooltip" className="information-icon" src={information} alt="information circle" onMouseOver={() => setShowTooltipOne(true)} onMouseOut={() => setShowTooltipOne(false)}/>
                            :
                            <img data-testid="account-tooltip" className="information-icon" src={information} alt="information circle" onClick={(e: any) => {setShowTooltipOne(true); e.preventDefault()}}/>
                        }
                        {showTooltipOne &&
                        <>
                            {windowState !== "Mobile" ?
                                <div className="tooltip-wrap">
                                    <Tooltip 
                                        anchorPoint="top-left"
                                        bodyMessage="If your account allows you to write checks, select 'Checking.' Otherwise, select 'Savings."
                                        headerMessage="Which type of account?"
                                        width="240px"
                                    />
                                </div>
                                :
                                <MobileAlertBox 
                                    setStateAction={setShowTooltipOne}
                                    headerMessage="What's my account type?"
                                    bodyMessage="If your account allows you to write checks, select 'Checking.' Otherwise, select 'Savings.'"
                                />
                            }
                        </>
                        }
                    </div>
                    <div className={`button-row ${windowState}`}>
                        <div className={accountType === "Checking" ? `button-wrap selected ${windowState}` : `button-wrap ${windowState}`} onClick={() => setAccountType("Checking")}>
                            <RadioButton
                                value={"Checking"}
                                name="account type"
                                className="radio"
                                onChange={() => setAccountType("Checking")}
                                checked={accountType === "Checking" ? true : false}
                                textAlign="Right"
                            >{"Checking" as any}</RadioButton>
                        </div>
                        <div className={accountType === "Savings" ? `button-wrap selected ${windowState}` : `button-wrap ${windowState}`} onClick={() => setAccountType("Savings")}>
                            <RadioButton
                                value={"Savings"}
                                name="account type"
                                className="radio"
                                onChange={() => setAccountType("Savings")}
                                checked={accountType === "Savings" ? true : false}
                                textAlign="Right"
                            >{"Savings" as any}</RadioButton>
                        </div>
                    </div>
                </div>
                <div className={windowState !== "Mobile" ? "account-info-row" : "account-info-row mobile"}>
                    <div className={`input-wrap ${windowState}`}>
                        <div className="account-label-row">
                            <span className={!routingError ? "label-text small" : "label-text small error"}>Routing number&nbsp;<span className="red-text">*</span></span>
                            {windowState !== "Mobile" ? 
                                <img data-testid="routing-tooltip" className="information-icon" src={information} alt="information circle" onMouseOver={() => setShowTooltipTwo(true)} onMouseOut={() => setShowTooltipTwo(false)}/>
                                :
                                <img data-testid="routing-tooltip" className="information-icon" src={information} alt="information circle" onClick={() => setShowTooltipTwo(true)} />
                            }
                            {showTooltipTwo &&
                            <>
                                {windowState !== "Mobile" ?
                                    <div className="tooltip-wrap two">
                                        <Tooltip 
                                            anchorPoint="top-left"
                                            bodyMessage="Your routing number is the 9-digit left-most number at the bottom of your checks. You may also find it by signing into your bank's website, or by contacting your bank directly."
                                            headerMessage="Where is my routing number?"
                                            width="240px"
                                        />
                                    </div>
                                    :
                                    <MobileAlertBox 
                                        setStateAction={setShowTooltipTwo}
                                        headerMessage="Where is my routing number?"
                                        bodyMessage="Your routing number is the 9-digit left-most number at the bottom of your checks. You may also find it by signing into your bank's website, or by contacting your bank directly."
                                    />
                                }
                            </>
                            }
                        </div>
                        <input 
                            data-testid="routing-input"
                            className={(!routingError && !blockedRoutingError) ? "input-box regular " : "input-box regular error"} 
                            type="text"
                            value={routingNumber}
                            onChange={(e: any) => handleRoutingNumber(e)}
                            onKeyDown={(e: any) => handleRoutingKeyDown(e)}
                            onBlur={() => handleRoutingOnBlur()}
                            maxLength={9}
                            onPaste={(e: any) => handleRoutingPaste(e)}
                        />
                        {routingError &&
                            <span className="error-text smallBoldButton">Enter a valid 9-digit routing number</span>
                        }
                        {/* {blockedRoutingError &&
                            <span className="error-text smallBoldButton">Unable to use this routing number</span>
                        } */}
                    </div>
                    <label className={`input-wrap ${windowState}`} >
                        <div className="account-label-row">
                            <span className="label-text small">Account number&nbsp;<span className="red-text">*</span></span>
                        </div>
                        <input 
                            data-testid="account-input"
                            className="input-box regular cls_mask" 
                            type="number"
                            value={accountNumber}
                            onChange={(e: any) => handleAccountNumber(e)}
                            onKeyDown={(e: any) => handleAccountKeyDown(e)}    
                            onBlur={() => handleAccountOnBlur()}
                            onPaste={() => {}}
                        />
                    </label>
                    <label className={`input-wrap ${windowState}`}>
                        <div className="account-label-row">
                            <span className={!accountNumberError ? "label-text small" : "label-text small error"}>Re-enter account number&nbsp;<span className="red-text">*</span></span>
                        </div>
                        <input 
                            data-testid="account-re-enter-input"
                            className={!accountNumberError ? "input-box regular cls_mask" : "input-box regular error cls_mask"} 
                            type="number"
                            value={testAccountNumber}
                            onChange={(e: any) => handleTestAccountNumber(e)}
                            onKeyDown={(e: any) => handleAccountKeyDown(e)}
                            onBlur={() => handleTestAccountOnBlur()}
                            onPaste={(e: any) => e.preventDefault()}
                        />
                        {accountNumberError &&
                            <span className="error-text smallBoldButton">Account numbers must match</span>
                        }
                    </label>
                </div>
                <img className={`sample-check ${windowState}`} src={sampleCheck} alt="sample check" />
                <span className="additional-text boldLarge">Additional information</span>
                <label className={`input-wrap ${windowState} small`}>Account nickname (optional)
                    <input 
                        data-testid="nickname-input"
                        className="input-box regular" 
                        type="text" 
                        value={nickname}
                        onChange={(e: any) => handleAccountNickname(e)}
                    />
                </label>
                {windowState !== "Mobile" ?
                    <>
                    <div className="bottom-border" />
                    <div className="nav-buttons-wrap">
                        <button className="Button btn-secondary" onClick={() => history.push("/user/profile/external-accounts")}>Cancel</button>
                        {/* <button className={enableButton ? "Button btn-primary" : "Button btn-primary inactive"} disabled={!enableButton} onClick={() => handleContinue()}>Continue</button> */}
                        <Button
                            title="Continue"
                            isDisabled={!enableButton}
                            className={enableButton ? "Button btn-primary" : "Button btn-primary inactive"}
                            id={TIMER_IDS.MICROTRANSACTION_VERIFY}
                            onClick={() => handleContinue()}
                        />
                    </div>
                    </>
                    :
                    // <button className={enableButton ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"} disabled={!enableButton} onClick={() => handleContinue()}>Continue</button>
                    <Button 
                        title="Continue"
                        isDisabled={!enableButton}
                        className={enableButton ? "Button btn-primary mobile" : "Button btn-primary inactive mobile"}
                        id={TIMER_IDS.MICROTRANSACTION_VERIFY}
                        onClick={() => handleContinue()}
                    />
                }
                </>
                :
                <div className="validate-success-wrap">
                    <img className="success-image" src={greenCheckmark} alt="green circle with white checkmark" />
                    <span className="success-title bold-x-large">Thanks for verifying your account!</span>
                    <span className="success-text regular">Your account&nbsp;
                        <span className="semiboldRegular">{accountNameRef.current} {lastFourRef.current}</span>
                        &nbsp;has been linked and is ready to use.
                    </span>
                    <div className="success-button-wrapper">
                        <button className="Button btn-primary" onClick={() => {history.push("/user/profile/external-accounts") }}>Return to external accounts</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default EnterBankDetails