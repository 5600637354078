import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useDocumentsPage } from "../../useHooks/pageHooks/useDocumentsPage"
import DesktopDocumentsLandingPage from "./DesktopDocumentsPage/DesktopDocumentsLandingPage"
import MobileDocumentsLandingPage from "./MobileDocumentsPage/MobileDocumentsLandingPage"

/**
 * This component is a wrapper for the Documents page.  It will determine which version
 * to display: mobile/desktop/hybrid
 */
const DocumentsPageWrapper = () => {
    const { windowState, selectedPage, setSelectedPage, availablePages, dateSelections, selectedDate, setSelectedDate,
            selectedAccount, handleSave, filteredAccounts } = useDocumentsPage()

    return(
        <div>
            {windowState !== "Mobile" ?
                <>
                {windowState === "Desktop" ?
                    <DesktopLayout>
                        <DesktopDocumentsLandingPage 
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                            availablePages={availablePages}
                            dateSelections={dateSelections}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            selectedAccount={selectedAccount}
                            filteredAccounts={filteredAccounts}
                        />
                    </DesktopLayout>
                    :
                    <HybridLayout>
                        <DesktopDocumentsLandingPage 
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                            availablePages={availablePages}
                            dateSelections={dateSelections}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            selectedAccount={selectedAccount}
                            filteredAccounts={filteredAccounts}
                        />
                    </HybridLayout>
                }
                </>
                :
                <MobileDocumentsLandingPage 
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    availablePages={availablePages}   
                    selectedAccount={selectedAccount}   
                    dateSelections={dateSelections}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    handleSave={handleSave}
                    filteredAccounts={filteredAccounts}
                />
            }
        </div>
    )
}

export default DocumentsPageWrapper