/* eslint-disable @typescript-eslint/no-unused-vars */
import './DesktopTransactionTable.scss';
import chevronRight from '../../../../assets/chevronRight.svg';
import { Pill } from '../../../Pill/Pill';
import { convertDateTommddyyyy, isUTCFormat } from '../../../../utils/DateUtils';
import { ITransactionHistoryData } from '../../../../api/getTransactionHistory';
import { IDesktopTransactionTableProps } from './interfaces/IDesktopTransactionTableProps';
import { TransactionDetails } from '../../../TransactionDetails/TransactionDetails';
import { displayCurrency } from '../../../../utils/displayCurrency';
import warning from '../../../../assets/warning.svg'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import sort from '../../../../assets/sort.svg'
import { setSelectedTransaction } from '../../../../slices/selectedTransaction/selectedTransactionSlice';
import upSortBlue from '../../../../assets/upSortBlue.svg'
import downSortBlue from '../../../../assets/downSortBlue.svg'
import appendZeroToDay from '../../../../utils/appendZeroToDay';

/**
 * Desktop Transaction table for Overview Page
 * @param transactionHistory - history to display
 * @param startIndex - (optional) start index of transaction array for display
 * @param endIndex - (optional) end index of transaction array for display
 */
export const DesktopTransactionTable = (
  props: IDesktopTransactionTableProps
) => {
  const {
    transactionHistory,
    startIndex,
    endIndex,
    setDisplayDetails,
    displayDetails,
    selectedTransaction,
    handleTransactionColumnSort,
    isSortable,
    dateSort,
    amountSort
  } = props;
  const transactionsLoaded = useAppSelector((state: RootState) => state.transactions.transactionsLoaded)
  const dispatch = useAppDispatch()

  return (
    <div>
      {transactionsLoaded === "Success" ?
        <>
          {selectedTransaction.id !== "" && (
            <TransactionDetails
              selectedTransaction={selectedTransaction}
              displayDetails={displayDetails}
              setDisplayDetails={setDisplayDetails}
            />
          )}
          <div className="DesktopTransactionPreview">
            <table className="Table">
              <thead>
                <tr className="Title-Row">
                  <th className="Title semibold">Date
                    {(isSortable && dateSort === "Default") && <img className="sort-icon" src={sort} data-testid="Default Sort" alt="sort" onClick={() => { if (handleTransactionColumnSort) { handleTransactionColumnSort("Date", "Up") } }} />}
                    {(isSortable && dateSort === "Up") && <img className="sort-icon" src={downSortBlue} data-testid="Descending Sort" alt="sort" onClick={() => { if (handleTransactionColumnSort) { handleTransactionColumnSort("Date", "Down") } }} />}
                    {(isSortable && dateSort === "Down") && <img className="sort-icon" src={upSortBlue} data-testid="Ascending Sort" alt="sort" onClick={() => { if (handleTransactionColumnSort) { handleTransactionColumnSort("Date", "Up") } }} />}
                  </th>
                  <th className="Title semibold">Description</th>
                  <th className="Title Right semibold">
                    {(isSortable && amountSort === "Default") && <img className="sort-icon" src={sort} data-testid="Default Sort" alt="sort" onClick={() => { if (handleTransactionColumnSort) { handleTransactionColumnSort("Amount", "Up") } }} />}
                    {(isSortable && amountSort === "Up") && <img className="sort-icon" src={downSortBlue} data-testid="Descending Sort" alt="sort" onClick={() => { if (handleTransactionColumnSort) { handleTransactionColumnSort("Amount", "Down") } }} />}
                    {(isSortable && amountSort === "Down") && <img className="sort-icon" src={upSortBlue} data-testid="Ascending Sort" alt="sort" onClick={() => { if (handleTransactionColumnSort) { handleTransactionColumnSort("Amount", "Up") } }} />}
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {(transactionHistory && transactionHistory.length > 0) && transactionHistory
                  .slice(startIndex, endIndex)
                  .map((e: ITransactionHistoryData, index: number) => {
                    return (
                      <tr
                        key={e.id + e.currentBalance}
                        className="Data"
                        onClick={() => {
                          setDisplayDetails(true);
                          dispatch(setSelectedTransaction({ transaction: e, index: index }))
                        }}
                      >
                        <td className="Date regular">
                          {appendZeroToDay((isUTCFormat(e?.effectiveDate) ? convertDateTommddyyyy(new Date(e?.effectiveDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})) : convertDateTommddyyyy(e?.effectiveDate)), ' ')}
                        </td>
                        <td className="Description regular">{e?.description}</td>
                        <td className="Amount-Wrapper regular">
                          <>
                            <div className="Status">
                              {e?.isAmountHold && <Pill title="On Hold" />}
                            </div>
                            <div className={e.type === "Debit" ? "Amount debit" : "Amount"}>
                              {e.type === "Debit" ? <span className="debit"> {displayCurrency(e?.amount)}</span> : displayCurrency(e?.amount)}
                            </div>
                          </>
                        </td>
                        <td className="Arrow">
                          <img data-testid="details-chevron" className="arrow-image" src={chevronRight} alt="Arrow Down" />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
        :
        <div className="transaction-loading" />
      }
    </div>
  );
};
