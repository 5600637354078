import './NotificationsModal.scss'
import { INotificationsModalProps } from './interfaces/INotificationsModalProps'
import verifyMobile from '../../../../../assets/verifyMobile.svg'
import { useWindowState } from '../../../../../Context/AccountContext/useWindowState'
import { useEffect } from 'react'
import { getEventPath } from '../../../../../utils/getEventPath'

/**Renders a modal when the notification settings within user profile have changed */
const NotificationsModal = (props: INotificationsModalProps) => {
    const { setShowModal, toggleStatus } = props
    const { windowState } = useWindowState()

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("notification-modal-card")) === -1){
                document.body.style.overflow = "auto";
                setShowModal(false)
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**locks the scroll bar on mount */
    useEffect(() => {
        document.body.style.overflow = "hidden";
    }, [])

    return (
        <div className="notification-modal-overlay">
            <div className={`notification-modal-card ${windowState}`}>
                <img className="image" src={verifyMobile} alt="mobile phone" />
                <span className="header-text h4">Text notifications</span>
                {toggleStatus ?
                    <span className="sub-text regular">Opting in to receive SMS texts authorizes Credit One Bank to send SMS text notifications regarding your Credit One Bank accounts using the mobile phone number you provided. The frequency of messages may vary based upon which notifications you choose to receive. If you wish to not receive these text notifications you signed up for, text STOP to opt-out. You can also text HELP for more help. Message and data rates may apply.</span>
                    :
                    <span className="sub-text regular">Opting out to receive SMS texts authorizes Credit One Bank to send SMS text notifications regarding your Credit One Bank accounts using the mobile phone number you provided. The frequency of messages may vary based upon which notifications you choose to receive. If you wish to not receive these text notifications you signed up for, text STOP to opt-out. You can also text HELP for more help. Message and data rates may apply.</span>
                }
                <button className={windowState !== "Mobile" ? "Button btn-primary" : "Button btn-primary mobile"} onClick={() => {setShowModal(false); document.body.style.overflow = "auto"}}>Got it</button>
            </div>
        </div>
    )
}

export default NotificationsModal