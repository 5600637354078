import { downloadAccountNotice } from '../../../api/getAccountInformation'
import './DesktopDocumentAccountNotices.scss'
import { IDesktopDocumentAccountNoticesProps } from './interface/IDesktopDocumentAccountNoticesProps'
import blueDocument from '../../../assets/blueDocument.svg'
// import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import emptyStatements from '../../../assets/emptyStatements.svg'
import warning from '../../../assets/warning.svg'
import { useToastMessage } from '../../../Context/ToastContext/useToastContext'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'
import { downloadDocuments } from '../../../utils/downloadDocuments'
import { useRef } from 'react'

/**
 * The component displays the desktop Document Center version of the Account Notices & Other Documents page
 */
const DesktopDocumentAccountNotices = (props: IDesktopDocumentAccountNoticesProps) => {
    const { selectedAccount } = props
    const gotNotices = useAppSelector((state: RootState) => state.selectedAccount.gotNotices)
    const { message, type, setToastMessage, resetToast } = useToastMessage()
    const downloadClickedRef = useRef<boolean>(false)

     /**handle the onClick event and downloads the account statement document */
     const handleStatementDownload = async (id: string) => {
        if(!downloadClickedRef.current){
            downloadClickedRef.current = true
            try{
                const response = await downloadAccountNotice(selectedAccount.id, id)
                downloadDocuments(response.data, selectedAccount.accountNumber, "Others", "")
            }
            catch{
                console.log("FAILED TO DOWNLOAD THE ACCOUNT STATEMENT")
                setToastMessage("ERROR", "Unable to download document. Please try again later")
                resetToast()
            }
            downloadClickedRef.current = false
        }
    }

    return(
        <div className="desktop-documents-account-notices">
            {gotNotices !== "Loading" ?
                <>
                {gotNotices === "Success" ?
                    <>
                    {message !== "" && <ToastMessageDB type={type} message={message} /> }
                    {selectedAccount.accountNotices.length > 0 ?
                        <div className="display-rows">
                            {selectedAccount.accountNotices.map((document: any, index: number) => {
                                return(
                                    <div key={index} className="display-row">
                                        <div className="left-side" onClick={() => handleStatementDownload(document.id)}>
                                            <img className="document-image" src={blueDocument} alt="document" />
                                            <span className="document regular">{document.title} {document.date} - PDF</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="message-wrapper">
                            <img className="message-image" src={emptyStatements} alt="no notices" />
                            <span className="header-text h4">No notices to display</span>
                            <span className="sub-text regular">Future notices & other documents will appear here</span>
                        </div>
                    }
                    </>
                    :
                    <div className="message-wrapper">
                        <img className="message-image" src={warning} alt="unable to retreive notices" />
                        <span className="header-text h4">Unable to load data</span>
                        <span className="sub-text regular">Please wait a moment and try again.</span>
                    </div>
                }
                </>
                :
                <div className="loading-box" />
            }
        </div>
    )
}

export default DesktopDocumentAccountNotices