import { INotificationMessageDBProps } from "./interfaces/INotificationMessageDBProps";
import "./styles/NotificationMessageDB.scss"
import NotificationMessage from "../../common/Notification/NotificationMessage";

/**
 * A notification message in the style of digital bank.
 * @param props INotificationMessageDBProps which consists notifications api data object and various classNames for styling.
 */
const NotificationMessageDB = (props: INotificationMessageDBProps) => {
  const { data,
    iconClassName = "notification-icon",
    wrapperClassName = "notification-wrapper",
    messageWrapperClassName = "message-wrapper",
    subHeaderTextClassName = "sub-header-text regular",
    headerTextClassName = "notification-header-text boldLarge",
    linkTextClassName = "notification-link regular",
    onDismiss = (notificationId: string) => { },
    onLinkClick = (event: any, notification: any) => { }
  } = props
  return (
    <NotificationMessage
      data={data}
      onLinkClick={onLinkClick}
      onDismiss={onDismiss}
      iconClassName={iconClassName}
      wrapperClassName={wrapperClassName}
      messageWrapperClassName={messageWrapperClassName}
      subHeaderTextClassName={subHeaderTextClassName}
      headerTextClassName={headerTextClassName}
      linkTextClassName={linkTextClassName}
    />
  )
}
export default NotificationMessageDB;