import { useHistory } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { useEarlyWithdrawal } from "../../useHooks/pageHooks/useEarlyWithdrawal"
import DesktopEarlyWithdrawal from "./DesktopEarlyWithdrawal/DesktopEarlyWithdrawal"
import MobileEarlyWithdrawal from "./MobileEarlyWIthdrawal/MobileEarlyWithdrawal"

/**A wrapper component to determine which early withdrawal path should be taken, Desktop, Hybrid, Mobile */
const EarlyWithdrawalWrapper = () => {
    const { windowState, confirmEarlyWithdrawal, setConfirmEarlyWithdrawal, confirmRequest, setConfirmRequest, transferAccount,
        setTransferAccount, openQuestionPage, setOpenQuestionPage } = useEarlyWithdrawal()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const history = useHistory()

    if(selectedAccount.id !== "" && (selectedAccount.accountClosed || selectedAccount.pendingGraceClosure)){
        history.push(`/accounts/${selectedAccount.id}`)
    }

    return (
        <>
            {windowState !== "Mobile" ?
                <>
                    {windowState === "Desktop" ?
                        <DesktopLayout>
                            <DesktopEarlyWithdrawal
                                confirmEarlyWithdrawal={confirmEarlyWithdrawal}
                                setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                                confirmRequest={confirmRequest}
                                setConfirmRequest={setConfirmRequest}
                                transferAccount={transferAccount}
                                setTransferAccount={setTransferAccount}
                                openQuestionPage={openQuestionPage}
                                setOpenQuestionPage={setOpenQuestionPage}
                            />
                        </DesktopLayout>
                        :
                        <HybridLayout>
                            <DesktopEarlyWithdrawal
                                confirmEarlyWithdrawal={confirmEarlyWithdrawal}
                                setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                                confirmRequest={confirmRequest}
                                setConfirmRequest={setConfirmRequest}
                                transferAccount={transferAccount}
                                setTransferAccount={setTransferAccount}
                                openQuestionPage={openQuestionPage}
                                setOpenQuestionPage={setOpenQuestionPage}
                            />
                        </HybridLayout>
                    }
                </>
                :
                <MobileEarlyWithdrawal
                    confirmEarlyWithdrawal={confirmEarlyWithdrawal}
                    setConfirmEarlyWithdrawal={setConfirmEarlyWithdrawal}
                    confirmRequest={confirmRequest}
                    setConfirmRequest={setConfirmRequest}
                    transferAccount={transferAccount}
                    setTransferAccount={setTransferAccount}
                    openQuestionPage={openQuestionPage}
                    setOpenQuestionPage={setOpenQuestionPage}
                />
            }
        </>
    )
}

export default EarlyWithdrawalWrapper