import { IOpenSavingsPageProps } from './interface/IOpenSavingsPageProps'
import './OpenSavingsPage.scss'
import openSavings from '../../../../assets/openSavings.svg'
import blueCheckmark from '../../../../assets/blueCheckmark.svg'
import { useTransferAtMaturity } from '../../../../useHooks/componentHooks/useTransferAtMaturity/useTransferAtMaturity'
import { getSAMLURL } from '../../../../shared/config/getSAMLUrl'
import { useOpenNewAccount } from '../../../../Context/OpenNewAccountContext/useOpenNewAccountContext'

/**
 * Renders the page to prompt the user to open a savings account.  This appears when the user elects to close
 * a CD at maturity, but does not currently have a savings account.
 */
const OpenSavingsPage = (props: IOpenSavingsPageProps) => {
    const { setFoundSavings, setOpenSavings, setChangePlanOption, setPlanChange, setShowTermChange, transferAccount, setTransferAccount } = props
    const { windowState, handleCancel } = useTransferAtMaturity(setPlanChange, setShowTermChange, setChangePlanOption, transferAccount, setTransferAccount)
    const { checkExternalAccounts } = useOpenNewAccount()

    return (
        <>
        {windowState === "Mobile" &&
            <div className="mobile-open-savings-header-wrap">
                <span className="header-text small" onClick={() => handleCancel()}>Cancel</span>
            </div>
        }
        <div className={windowState !== "Mobile" ? "savings-page-wrapper desktop" : "savings-page-wrapper"}>
            <div className={windowState !== "Mobile" ? "open-savings-page-wrapper" : "open-savings-page-wrapper mobile"}>
                {windowState === "Mobile" &&
                    <div className="savings-header-wrapper">
                        <span className="header-text small">Maturity Settings</span>
                        <span className="sub-header-text boldRegular">Close CD at Maturity</span>
                    </div>
                }
                <div className="open-savings-wrapper">
                    <div className="message-wrapper">
                        <img className="image"  src={openSavings} alt="open savings account" />
                        <span className="header-text boldLarge">Would you like to open a savings account?</span>
                        <div className="point-wrapper">
                            <img src={blueCheckmark} alt="checkmark" />
                            <span className="point-text regular">Competitive rates plus a loyalty increase.</span>
                        </div>
                        <div className="point-wrapper">
                            <img src={blueCheckmark} alt="checkmark" />
                            <span className="point-text regular">Easy access to your funds online or in our mobile app.</span>
                        </div>
                        <div className="point-wrapper">
                            <img src={blueCheckmark} alt="checkmark" />
                            <span className="point-text regular">Open an account in minutes.</span>
                        </div>
                        <div className="button-wrapper">
                            <button className="open-savings-button boldButtonLarge" onClick={() => {setOpenSavings(true); checkExternalAccounts()}}>Open a savings account</button>
                            <button className="no-thanks-button mobileRegular" onClick={() => setFoundSavings(true)}>No thanks</button>
                        </div>
                    </div>
                </div>
                {windowState !== "Mobile" &&
                    <div className="cancel-button-wrap">
                        <button className="cancel-button boldLarge" onClick={() => handleCancel()}>Cancel</button>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default OpenSavingsPage