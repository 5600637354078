import { useWindowState } from "../../Context/AccountContext/useWindowState"
import { DesktopLayout } from "../../layouts/DesktopLayout/DesktopLayout"
import HybridLayout from "../../layouts/HybridLayout/HybridLayout"
import { MobileLayout } from "../../layouts/MobileLayout/MobileLayout"
import DesktopTransactionHistory from "./DesktopTransactionHistory/DesktopTransactionHistory"
import MobileTransactionHistory from "./MobileTransactionHistory/MobileTransactionHistory"
import { TransactionHistoryProvider } from './context/TransactionHistoryContext'

/**
 * The wrapper page for the transaction history component.
 * The wrapper will determine which display state we should be in 
 */
const TransactionHistoryWrapper = () => {
    const { windowState } = useWindowState()

    return (
        <>
            <TransactionHistoryProvider>

                {windowState !== "Mobile" ?
                    <>
                        {windowState === "Desktop" ?
                            <DesktopLayout>
                                <DesktopTransactionHistory fromSavings={false} />
                            </DesktopLayout>
                            :
                            <HybridLayout>
                                <DesktopTransactionHistory fromSavings={false} />
                            </HybridLayout>
                        }
                    </>
                    :
                    <MobileLayout>
                        <MobileTransactionHistory
                            fromSavings={false}
                        />
                    </MobileLayout>
                }
            </TransactionHistoryProvider>
        </>
    )
}


export default TransactionHistoryWrapper




