/**All service calls related to Yodlee */

import axios from "axios"
import { bffBaseUrl } from "../../utils/globalVariables"

//deposit.creditone.com
///savings-bff/digitalbank/savings
//https://deposit-sit.creditone.com/
/**Get the Yodlee token */
export const getYodleeToken_API = () => {
    return axios.get(`${bffBaseUrl}/yodlee/token`,
    // return axios.get(`https://deposit.creditone.com/savings-bff/digitalbank/savings/yodlee/token`,
        {
            headers: {
                // stepUpToken: localStorage.getItem("validateToken"),
                // mfaToken: localStorage.getItem("timerToken"),
                apiVersion: "2.0.0"
            }
        }
    )
}

/**Deletes teh Yodlee token */
export const deleteYodleeToken_API = () => {
    return axios.delete(`${bffBaseUrl}/yodlee/token`,
        {
            headers: {
                apiVersion: "2.0.0"
            }
        }
    )
}