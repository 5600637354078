/**Displays the date in a long hand version, ie: date of 09/01/2001 comes in and becomes September 1, 2001 */
export const dateLongHand = (date: any) => {
    const [month, day, year] = date.split(" ")
    switch (month) {
        case "Jan":
            return `January ${day} ${year}`
        case "Feb":
            return `February ${day} ${year}`
        case "Mar":
            return `March ${day} ${year}`
        case "Apr":
            return `April ${day} ${year}`
        case "May":
            return `May ${day} ${year}`
        case "Jun":
            return `June ${day} ${year}`
        case "Jul":
            return `July ${day} ${year}`
        case "Aug":
            return `August ${day} ${year}`
        case "Sep":
            return `September ${day} ${year}`
        case "Oct":
            return `October ${day} ${year}`
        case "Nov":
            return `November ${day} ${year}`
        case "Dec":
            return `December ${day} ${year}`
    }
}