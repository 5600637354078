import './AccountOverviewTop.scss'
import editPencil from '../../../../../../assets/editPencil.svg'
import { IAccountOverviewTopProps } from './interfaces/IAccountOverviewTopProps'
import { useOverviewTopComponent } from '../../../../../../useHooks/componentHooks/useOverviewComponent/useOverviewTopComponent'
import { updateOnTransactionDetails } from '../../../../../../slices/transactions/transactionsSlice'
import { Pill } from '../../../../../Pill/Pill'
import { EditAccountName } from '../../../../../EditAccountName/EditAccountName'
import arrowLeft from '../../../../../../assets/arrowLeft.svg'
import { convertDateTommddyyyy } from '../../../../../../utils/DateUtils'
import loyaltyCrown from '../../../../../../assets/loyaltyCrown.svg'
import MobileAlertBox from '../../../../../MobileAlertBox/MobileAlertBox'

/**
 * Renders the top portion of the Mobile versions Overview Component.
 * @param props selectedAccount = IAccountInformation
 */
const AccountOverviewTop = (props: IAccountOverviewTopProps) => {
    const { selectedAccount } = props;
    const { history, onTransactionDetails, dispatch, setEditDescription, editDescription, isAccountDetailsLoaded, showLoyaltyTooltip, setShowLoyaltyTooltip } = useOverviewTopComponent(selectedAccount)

    return (
        <div className="mobile-account-overview-top">
            {isAccountDetailsLoaded !== "Loading" ?
                <>
                {/* <div className="return" onClick={() => {!onTransactionDetails ? history.push("/account-overview") : dispatch(updateOnTransactionDetails(false))}} /> */}
                <div className="product-account-name">
                    {selectedAccount &&
                    <div>
                        <div className="back-to-accounts">
                            <img  data-testid="route-icon" className="arrow-icon" src={arrowLeft} alt="back" onClick={() => history.push('/')}/>
                            <span data-testid="route-text" className="accounts-text regular" onClick={() => history.push('/')}>Accounts</span>
                        </div>
                        <div className="product-description">
                            <span className="description semiboldLarge">{selectedAccount.accountTitle}</span>
                            <img data-testid="nickname-pencil" className="edit-icon" src={editPencil} alt="Edit Icon" onClick={() => setEditDescription(!editDescription)}/>
                        </div>
                        {editDescription && 
                            <EditAccountName selectedAccount={selectedAccount} setEditDescription={setEditDescription} />
                        }
                        <div>
                            <div className="acocount-type-number-wrap">
                                <span className="account-type-number semiboldRegular">{selectedAccount.accountType} &nbsp;
                                    {selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length-4, selectedAccount.accountNumber.length)}
                                </span>
                                {selectedAccount.loyaltyRate && <img data-testid="loyalty-icon" className="loyalty-icon" src={loyaltyCrown} alt="loyalty rate applied" onClick={() => setShowLoyaltyTooltip(true)}/>}
                            </div>
                            <div className="pill-wrap">
                                {(selectedAccount.earlyWithdrawalSelected || selectedAccount.pendingGraceClosure) && <Pill title="Pending closure" sx={{backgroundColor: "var(--softRed)", color: "var(--darkRed)"}}/>}                    
                                {selectedAccount.accountClosed && <Pill title={`Closed on ${convertDateTommddyyyy(selectedAccount.accountClosedDate)}`} sx={{backgroundColor: "var(--softRed)", color: "var(--darkRed)"}}/>}                    
                            </div>
                        </div>
                        {showLoyaltyTooltip &&
                            <MobileAlertBox 
                                setStateAction={setShowLoyaltyTooltip}
                                headerMessage="You earned the Loyalty Rate!"
                                bodyMessage={`We increased your rate to ${selectedAccount.interestRate}%.`}
                            />
                        }
                    </div>
                    }
                </div>
                </>
                :
                <div className="mobile-top-loading">
                    <div className="loading-box-1 top" />
                    <div className="loading-box-2" />
                    <div className="loading-box-1" />
                </div>
            }
        </div>
    )
}

export default AccountOverviewTop