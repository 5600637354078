import { useWindowState } from '../../../Context/AccountContext/useWindowState'
import './LoadingStateAccountBox.scss'

/**The component simply creates a box for the empty loading state shown on the account overview page */
const LoadingStateAccountBox = () => {
    const { windowState } = useWindowState()

    return(
        <>
        {windowState !== "Mobile" ?
            <div className="desktop-loading-state-account-box">
                <div className="top-box" />
                <div className="bottom-box"/>
            </div>
            :
            <div className="mobile-loading-state-account-box">
                <div className="top-box" />
                <div className="bottom-box"/>
            </div>
        }
        </>
    )
}

export default LoadingStateAccountBox