import './MobileManageDevices.scss'
import { IMobileManageDevicesProps } from './interfaces/IMobileManageDevicesProps'
import arrowLeftBlue from '../../../assets/arrowLeftBlue.svg'
import { useManageDevices } from '../../../useHooks/componentHooks/useManageDevices/useManageDevices'
import { ITrustedDevice } from '../../../api/User/interfaces'
import { convertDateWithTime } from '../../../utils/DateUtils'

/**Renders the mobile version of manage devices found in Security Settings of User Profile */
const MobileManageDevices = (props: IMobileManageDevicesProps) => {
    const { setManageDevices } = props
    const { userInformation, openMenu, setOpenMenu, selectedDevice, setSelectedDevice, changeName, setChangeName, deleteDevice, setDeleteDevice,
        setSelectedIndex, handleRemoveDevice } = useManageDevices()


    return (
        <div className="mobile-manage-devices-wrap">
            <div className="devices-mobile-header">
                <img src={arrowLeftBlue} alt="back arrow" className="header-image" onClick={() => setManageDevices(false)} />
                <span className="blue-text regular" onClick={() => setManageDevices(false)}>Security settings</span>
            </div>
            <div className="data-card-wrapper">
                {/* {!changeName ? */}
                <>
                    <span className="header-text h3">Sign-in history</span>
                    {userInformation.trustedDevices.map((device: ITrustedDevice, index: number) => {
                        return (
                            <div className="device-wrap" key={device.id} /* onClick={() => { setSelectedDevice(device); setSelectedIndex(index) }} */>
                                {/* device.currentlyUsing && <span className="current-device mobile-h6">Current device</span> */}
                                {/* <div className="device-row-wrap">
                                    <span className="device-name semiboldRegular">{device.deviceNickname}</span>
                                    <img className="elipses" src={elipses} alt="elipses" onClick={() => setOpenMenu(true)}/>
                                </div> */}
                                <span className="device small">{device.device}</span>
                                <span className="sub-text small cls_mask">{convertDateWithTime(device.lastSignIn)} • {device.ip}</span>
                            </div>
                        )
                    })}
                    {/* deleteDevice &&
                            <div className="delete-modal-wrap">
                                <div className="modal-wrap">
                                    <img src={deletedDevice} alt="delete device" />
                                    <span className="title-text h4">Remove {selectedDevice.deviceNickname} as a trusted device ?</span>
                                    <span className="modal-text regular">This action cannot be undone.</span>
                                    <div className="button-wrap">
                                        <button className="Button btn-primary" onClick={() => handleRemoveDevice()}>Remove device</button>
                                        <button className="Button btn-secondary" onClick={() => setDeleteDevice(false)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        */ }
                </>
                {/*  : */}
                {/* <ChangeDeviceName
                        setManageDevices={setChangeName}
                        selectedDevice={selectedDevice}
                    /> */}
                {/* } */}
            </div>
            {/* openMenu &&
                <div className="mobile-device-menu-wrap">
                    <div className="menu-wrap">
                        <div className="header-wrap">
                            <span className="header-text semiboldRegular">{selectedDevice.deviceNickname}</span>
                            <span className="cancel-text regular" onClick={() => { setOpenMenu(false); document.body.style.overflow = "auto" }}>Cancel</span>
                        </div>
                        <div className="menu-row-wrap border" onClick={() => { setChangeName(true); setOpenMenu(false); document.body.style.overflow = "auto" }}>
                            <img src={bluePencil} alt="edit pencil" />
                            <span className="blue-text regular">Change device name</span>
                        </div>
                        <div className="menu-row-wrap" onClick={() => { setDeleteDevice(true); setOpenMenu(false); document.body.style.overflow = "auto" }}>
                            <img src={redTrashCan} alt="edit pencil" />
                            <span className="red-text regular">Delete</span>
                        </div>
                    </div>
                </div>
            */ }
        </div>
    )
}

export default MobileManageDevices