import { useVerificationCode } from '../../../../../../useHooks/componentHooks/useVerificationCode/useVerificationCode'
import './VerificationCode.scss'
import { IVerificationCodeProps } from './interfaces/IVerificationCodeProps'

/**Renders the 6-digit verification code box found in the OTP stepper */
const VerificationCode = (props: IVerificationCodeProps) => {
    const { setInputCode, status, clearInputs, setClearInputs, windowState } = props
    const { input1Ref, input2Ref, input3Ref, input4Ref, input5Ref, input6Ref, input1, input2, input3, input4, input5, input6,
        handleOne, handleTwo, handleThree, handleFour, handleFive, handleSix, handleKeyDown, handleOnBlur, handleOnPaste } = useVerificationCode(setInputCode, clearInputs, setClearInputs)

    /**
     * Returns the input's classname
     */
    const INPUT_CLASSNAME = (() => {
        let className = 'input-char h3'
        if (status === 'Fail') {
            className += ' fail';
        }

        if (windowState === 'Mobile') {
            className += ' mobile';
        }

        return className;
    })()

    return <div className="verification-code-box-wrapper">
        <input
            data-testid="otp-code-input"
            className={INPUT_CLASSNAME}
            type="text"
            ref={input1Ref}
            value={input1}
            onChange={(e: any) => handleOne(e)}
            maxLength={1}
            onKeyDown={(e: any) => handleKeyDown(e, 1)}
            onBlur={() => handleOnBlur()}
            onPaste={(e: any) => handleOnPaste(e)}
            inputMode='numeric'
        />
        <input
            data-testid="otp-code-input"
            className={INPUT_CLASSNAME}
            type="text"
            ref={input2Ref}
            value={input2}
            onChange={(e: any) => handleTwo(e)}
            maxLength={1}
            onKeyDown={(e: any) => handleKeyDown(e, 2)}
            onBlur={() => handleOnBlur()}
            onPaste={(e: any) => handleOnPaste(e)}
            inputMode='numeric'
        />
        <input
            data-testid="otp-code-input"
            className={INPUT_CLASSNAME}
            type="text"
            ref={input3Ref}
            value={input3}
            onChange={(e: any) => handleThree(e)}
            maxLength={1}
            onKeyDown={(e: any) => handleKeyDown(e, 3)}
            onBlur={() => handleOnBlur()}
            onPaste={(e: any) => handleOnPaste(e)}
            inputMode='numeric'
        />
        <input
            data-testid="otp-code-input"
            className={INPUT_CLASSNAME}
            type="text"
            ref={input4Ref}
            value={input4}
            onChange={(e: any) => handleFour(e)}
            maxLength={1}
            onKeyDown={(e: any) => handleKeyDown(e, 4)}
            onBlur={() => handleOnBlur()}
            onPaste={(e: any) => handleOnPaste(e)}
            inputMode='numeric'
        />
        <input
            data-testid="otp-code-input"
            className={INPUT_CLASSNAME}
            type="text"
            ref={input5Ref}
            value={input5}
            onChange={(e: any) => handleFive(e)}
            maxLength={1}
            onKeyDown={(e: any) => handleKeyDown(e, 5)}
            onBlur={() => handleOnBlur()}
            onPaste={(e: any) => handleOnPaste(e)}
            inputMode='numeric'
        />
        <input
            data-testid="otp-code-input"
            className={INPUT_CLASSNAME}
            type="text"
            ref={input6Ref}
            value={input6}
            onChange={(e: any) => handleSix(e)}
            maxLength={1}
            onKeyDown={(e: any) => handleKeyDown(e, 6)}
            onBlur={() => handleOnBlur()}
            onPaste={(e: any) => handleOnPaste(e)}
            inputMode='numeric'
        />
    </div>
}

export default VerificationCode