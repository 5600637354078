import { useEffect, useRef, useState } from "react"
import { useTransactionHistoryContext } from '../../../Pages/TransactionHistory/context/TransactionHistoryContext'

/**A custom hook for the mobile transaction filter */
export const useMobileTransactionFilter = (setMobileFilterOpen: any) => {
    const [dateRangeSelected, setDateRangeSelected] = useState<boolean>(false)
    const [transactionTypeSelected, setTransactionTypeSelected] = useState<boolean>(false)
    const [amountSelected, setAmountSelected] = useState<boolean>(false)
    const { amountFilter, typeFilter, dateFilter, handleApply, } = useTransactionHistoryContext();
    const shouldResetRef = useRef(true);

    const _handleApply = () => {
        shouldResetRef.current = false;
        handleApply();
    }

    useEffect(() => {
        return () => {
            if (shouldResetRef.current) {
                typeFilter.transactionType.setStateValueToRef();
                dateFilter.customDate.setStateValueToRef();
                dateFilter.fromDate.setStateValueToRef();
                dateFilter.toDate.setStateValueToRef();
                dateFilter.dateRange.setStateValueToRef();
                amountFilter.maxAmount.setStateValueToRef();
                amountFilter.minAmount.setStateValueToRef();
            }
        }
    }, [])

    const handleCancel = () => {
        shouldResetRef.current = false;
        setMobileFilterOpen(false);
        typeFilter.transactionType.setRefValueToState();
        dateFilter.customDate.setRefValueToState();
        dateFilter.fromDate.setRefValueToState();
        dateFilter.toDate.setRefValueToState();
        dateFilter.dateRange.setRefValueToState();
        amountFilter.maxAmount.setRefValueToState();
        amountFilter.minAmount.setRefValueToState();
    }

    const handleReset = () => {
        shouldResetRef.current = false;

        typeFilter.transactionType.reset();
        dateFilter.customDate.reset();
        dateFilter.fromDate.reset();
        dateFilter.toDate.reset();
        dateFilter.dateRange.reset();
        amountFilter.maxAmount.reset();
        amountFilter.minAmount.reset();
        handleApply();
    }


    return {
        dateRangeSelected,
        setDateRangeSelected,
        transactionTypeSelected,
        setTransactionTypeSelected,
        amountSelected,
        setAmountSelected,
        handleCancel,
        handleReset,
        handleApply: _handleApply
    }
}