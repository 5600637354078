import { useWindowState } from '../../Context/AccountContext/useWindowState';
import { DesktopTransactionDetails } from './components/DesktopTransactionDetails/DesktopTransactionDetails';
import { MobileTransactionDetails } from './components/MobileTransactionDetails/MobileTransactionDetails';
import { ITransactionDetailsProps } from './interfaces/ITransactionDetailsProps';
/**
 * Transaction Details panel
 * @param selectedTransaction - transaction that the user clicks on
 * @param setDisplayDetails - sets boolean to determin if details should be displayed
 * @param displayDetails - boolean to determin if details should be displayed 
*/
export const TransactionDetails = (props: ITransactionDetailsProps) => {
  const {selectedTransaction, setDisplayDetails, displayDetails} = props;
  const { windowState } = useWindowState();

  return (
    <div className="TransactionDetails">
      {windowState === "Desktop" || windowState === "Hybrid" ? (
        <DesktopTransactionDetails
          selectedTransaction={selectedTransaction}
          setDisplayDetails={setDisplayDetails}
          displayDetails={displayDetails}
        />
      ) : (
        <MobileTransactionDetails
          selectedTransaction={selectedTransaction}
          setDisplayDetails={setDisplayDetails}
        />
      )}
    </div>
  );
};
