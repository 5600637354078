import './MaxAddressModal.scss'
import redCircleExclamation from '../../../../assets/redCircleExclamation.svg'
import { useMaxAddressModal } from '../../../../useHooks/componentHooks/useMaxAddressModal/useMaxAddressModal'
import { IMaxAddressModalProps } from './interfaces/IMaxAddressModalProps'

/**Renders the maximum address reached modal found in user profile add address flow */
const MaxAddressModal = (props: IMaxAddressModalProps) => {
    const { setShowMaxAddressModal } = props
    const { windowState } = useMaxAddressModal(setShowMaxAddressModal)

    return (
        <div className="max-address-overlay">
            {windowState !== "Mobile" ?
                <div className="modal-card-wrap">
                    <img className="modal-image" src={redCircleExclamation} alt="red circle with exclamation mark" />
                    <span className="modal-title h4">Address limit reached</span>
                    <span className="modal-text regular">To add another address, first remove one of your existing addresses.</span>
                    <button className="Button btn-primary" onClick={() => setShowMaxAddressModal(false)}>Got it</button>
                </div>
                :
                <div className="modal-card-wrap mobile">
                    <img className="modal-image" src={redCircleExclamation} alt="red circle with exclamation mark" />
                    <span className="modal-title h4">Address limit reached</span>
                    <span className="modal-text regular">To add another address, first remove one of your existing addresses.</span>
                    <button className="Button btn-primary mobile" onClick={() => setShowMaxAddressModal(false)}>Got it</button>
                </div>
            }
        </div>
    )
}

export default MaxAddressModal