import { useWindowState } from '../../Context/AccountContext/useWindowState';
import DesktopSideNavigation from './components/desktopSideNavigation/DesktopSideNavigation';
import MobileSideNavigation from './components/mobileSideNavigation/MobileSideNavigation';

/** 
 * SideNavigation is a wrapper for the side navigation bar.  If the total pixel width count is 800 or greater
 * than the DesktopSideNavigation component will be rendered, if it is less than 800 that the MobileSideNavigation
 * component will be rendered.  useWindowResize is the hook used to get the screen size.
 */ 
export const SideNavigation = () => {  
  const { windowState } = useWindowState()

  return ( 
    <div > 
      {windowState === "Desktop" ? <DesktopSideNavigation /> : <MobileSideNavigation />}
    </div> 
  ); 
}; 
