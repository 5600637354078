import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import './DesktopBumpRate.scss'
import bumpRateImage from '../../../assets/bumpRateImage.svg'
import { IDesktopBumpRateProps } from './interfaces/IDesktopBumpRateProps'
import greyChartUp from '../../../assets/greyChartUp.svg'
import blueElipseArrowRight from '../../../assets/blueElipseArrowRight.svg'
import BumpRateModal from '../components/BumpRateModal/BumpRateModal'
import checkmarkGreenCircle from '../../../assets/checkmarkGreenCircle.svg'
import bumpRateDown from '../../../assets/bumpRateDown.svg'
import { dateLongHand } from '../../../utils/dateLongHand'
import noBumpCircle from '../../../assets/noBumpCircle.svg'
import { convertDateTommddyyyy } from '../../../utils/DateUtils'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { useRef, useEffect } from 'react'
import { useNotifications } from '../../../Context/NotificationContext/useNotificationContext'
import { INotificationServiceObject } from '../../../shared/common/Notification/interfaces/INotifcationProps.types'

// const useBumpRateNotificationDelete = (selectedAccount: any) => {
//     const { notifications, handleDismiss } = useNotifications();
//     const notificationsToDelete = useRef<Array<INotificationServiceObject>>([])

//     useEffect(() => {
//         doDelete();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [notifications])

//     useEffect(() => {
//         deleteNotifications();

//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [selectedAccount.isBumpAvailable, selectedAccount.bumpActivated])

//     const deleteNotifications = () => {
//         notificationsToDelete.current = notifications.filter(e => e.accountId === selectedAccount.id && e.notificationType === 'BumpUpRate')
//         doDelete();
//     }

//     const doDelete = () => {
//         if (notificationsToDelete.current.length) {
//             handleDismiss(notificationsToDelete.current[0].notificationId);
//             notificationsToDelete.current.splice(0, 1);
//         }
//     }

//     return { deleteNotifications };
// }

/**Renders the desktop version of bump rate page */
const DesktopBumpRate = (props: IDesktopBumpRateProps) => {
    const { selectedAccount, bumpActivated, setBumpActivated, setBumpConfirmed } = props
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)
    // useBumpRateNotificationDelete(selectedAccount)
    
    return (
        <div className="desktop-bump-rate-wrap">
            <div className="account-header-wrap">
                <AccountHeader returnMessage="Account Settings" setDisplayDetails={(false)} />
            </div>
            <div className="bump-rate-page-wrap">
                {isAccountDetailsLoaded !== "Loading" ?
                    <div className="bump-rate-card-wrap">
                        <div className="header-wrap">
                            <span className="header-text h2">Bump rate</span>
                            <span className="sub-header-text regular">Take advantage of rising market rates with a one-time rate increase</span>
                        </div>
                        {selectedAccount.isBumpAvailable || selectedAccount.bumpActivated ?
                            <>
                                <div className="info-wrap">
                                    {selectedAccount.bumpActivated ?
                                        <>
                                            <img src={checkmarkGreenCircle} alt="green circle checkmark" />
                                            <span className="info-header-text h4">Congratulations on your new, higher rate!</span>
                                            <span className="info-header-subtext regular">You activated your bump rate on {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.bumpedRateDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}</span>
                                        </>
                                        :
                                        <>
                                            <img src={bumpRateImage} alt="bump rate" />
                                            <span className="info-header-text h4">A rate increase is available</span>
                                            <span className="info-header-subtext regular">Rates have gone up, and your CD is eligible for an increase of {((selectedAccount.bumpedRate as number) - (selectedAccount.originalApy as number)).toFixed(2)}%! Select ‘Activate bump rate’ to increase your rate today.</span>
                                        </>
                                    }
                                </div>
                                <div className="apy-info-wrap">
                                    <div className="left-side">
                                        {selectedAccount.bumpActivated ?
                                            <>
                                                <span className="current-apy semiboldXLarge">{selectedAccount.originalApy?.toFixed(2)}%</span>
                                                <span className="current-apy-text small">Your Old APY</span>
                                            </>
                                            :
                                            <>
                                                <span className="current-apy semiboldCustom">{selectedAccount.interestRate.toFixed(2)}%</span>
                                                <span className="current-apy-text smallCustom">Your Current APY</span>
                                            </>
                                        }
                                    </div>
                                    <div className="right-side selected">
                                        <div className="top">
                                            {selectedAccount.bumpActivated ?
                                                <span className="apy-text mobileh3">{selectedAccount.interestRate?.toFixed(2)}%</span>
                                                :
                                                <span className="apy-text mobileh3">{selectedAccount.bumpedRate?.toFixed(2)}%</span>
                                            }
                                            <img src={greyChartUp} alt="chart up icon" />
                                        </div>
                                        <span className="new-apy-text semibold">New APY</span>
                                    </div>
                                    <img className="right-arrow" src={blueElipseArrowRight} alt="pointer to higher rate" />
                                </div>
                                {!selectedAccount.bumpActivated &&
                                    <div className="button-wrap">
                                        <button className='Button btn-primary' onClick={() => setBumpActivated(true)}>Activate bump rate</button>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <div className="info-wrap">
                                    <img src={noBumpCircle} alt="no bump" />
                                    <span className="info-header-text h4">You already have the best rate!</span>
                                    <span className="info-header-subtext regular">If a better rate becomes available, we'll let you know.</span>
                                </div>
                                <div className="apy-info-wrap">
                                    <div className="left-side selected">
                                        <span className="current-apy mobileh3">{selectedAccount.interestRate.toFixed(2)}%</span>
                                        <span className="current-apy-text semibold">Your Current APY</span>
                                    </div>
                                    <div className="right-side ">
                                        <div className="top">
                                            <span className="apy-text semiboldXLarge">{selectedAccount.bumpedRate?.toFixed(2)}%</span>
                                            <img src={bumpRateDown} alt="chart up icon" />
                                        </div>
                                        <span className="new-apy-text small">Current Market APY</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    :
                    <div className="bump-page-loading-state" />
                }
            </div>
            {bumpActivated &&
                <BumpRateModal
                    setBumpActivated={setBumpActivated}
                    setBumpConfirmed={setBumpConfirmed}
                    bumpAPY={selectedAccount.bumpedRate as number}
                />
            }
        </div>
    )
}

export default DesktopBumpRate